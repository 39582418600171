import { Component, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadWheelService } from './components/load-wheel/load-wheel.service';
import { NotificationApiService } from './core/services/notification-api.service';
import { EntityName } from './core/models/enumerations/entity-name';
import { StringUtils } from './core/utils/string-utils';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        translate: TranslateService,
        notificationApiService: NotificationApiService,
        loadWheelService: LoadWheelService,
        viewContainerRef: ViewContainerRef

    ) {
        window.name = StringUtils.safeWindowName(EntityName.DASHBOARD);
        translate.setDefaultLang('en');
        translate.use('en');
        notificationApiService.activateNotifications();
        loadWheelService.appViewContainerRef = viewContainerRef;
    }
}

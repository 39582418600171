import { Directive, ElementRef, AfterViewInit } from '@angular/core';
@Directive({
  selector: '[ignoreHeaders]'
})

export class IgnoreHeadersDirective implements AfterViewInit {

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setTabIndexForHeaders();
  }

  private setTabIndexForHeaders() {
    if (this.el.nativeElement) {
      const headerCells = this.el.nativeElement.querySelectorAll('th');
      headerCells.forEach((cell: HTMLElement) => {
        cell.setAttribute('tabindex', '-1');
        cell.querySelectorAll('*').forEach((child: HTMLElement) => child.setAttribute('tabindex', '-1'));
      });
    }
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoAreaComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.AREA;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'AreaId', columnHeader: 'relatedInfo.area.areaId', routerEntityName: EntityName.AREA, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'AreaName', columnHeader: 'relatedInfo.area.areaName', widthPercentage: 25});
        columnsInfo.push({ entityPropName: 'AreaStatus', columnHeader: 'relatedInfo.area.areaStatus', widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'State', columnHeader: 'relatedInfo.area.state', widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'Country', columnHeader: 'relatedInfo.area.country', widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});

        return columnsInfo;
    }

}

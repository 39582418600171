<div class="header-container">
    <div class="border-round-left-md
                border-round-right-md
                w-full mt-5 lg:mt-1 xl:mt-1"
                [ngClass]="isDash ? '': 'header-entity'">

    <div>
        <app-top-nav></app-top-nav>
    </div>
    <div>
        <div>
            <div class="p-2 pb-1 h-auto
                        border-round-left-md
                        border-round-right-md
                        header-content
                        text-sm shadow-8">

                <div class="w-full flex justify-content-between" *ngIf="!headerEntity">
                    <h1 class="text-base white-space-nowrap">{{title}}&nbsp;{{entityId}}</h1>
                    <h1 id="vsmLink" class="text-base white-space-nowrap text-red-600" *ngIf="!headerEntity && serverNotificationCount() > 0" (click)="showNotifications()">View {{serverNotificationCount()}} important system message{{serverNotificationCount() > 1 ? "s" : ""}}</h1>
                </div>
                
                <div class="w-full flex justify-content-between -mt-2 gap-3" *ngIf="headerEntity">
                    <div class="flex flex-column flex-auto overflow-hidden">
                        <h1 class="text-lg mt-1 white-space-nowrap overflow-hidden text-overflow-ellipsis" >{{title}}&nbsp;{{entityId}}<span *ngIf="entityName !== ''">&nbsp;-&nbsp;</span>{{entityName}}</h1>
                        <ul class="pl-0 mb-0 list-none">
                            <li>
                                <a (click)="goToLocation()" *ngIf="showMapItLink" class="text-xs basic-link">
                                    <img class="pr-1 w-1rem -mb-1"  src="assets/icons/gmap_icon.png" alt="" *ngIf="showMapItLink">{{'map_it'| translate}}
                                </a>
                                <span> &nbsp; </span>
                                <a (click)="downloadPDF()" *ngIf="showPDFLink" class="text-xs basic-link">
                                    <img class="pr-1 w-1rem -mb-1" src="assets/icons/pdf.png" alt=""  *ngIf="showPDFLink">{{'pdf'| translate}}
                                </a>
                                <span> &nbsp; </span>
                                <a (click)="clientView()" *ngIf="showClientView" class="text-xs basic-link">
                                    <img class="pr-1 w-1rem -mb-1" src="assets/icons/cli_view.png" alt="" *ngIf="showClientView">{{'client_view'| translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="flex flex-column mt-1 p-0 col-fixed">
                        <div>
                            <button 
                                *ngIf="tKeyExitRecordButtonTitle !== null && tKeyExitRecordButtonTitle !== ''"
                                class="btn-record-exit border-round-left-md border-round-right-md border-none text-xs mr-1 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4 p-1 px-4 h-auto"
                                (click)="exitRecord()" 
                                [disabled]="isExitButtonDisabled()">{{ tKeyExitRecordButtonTitle| translate }}</button>
                            <button 
                                *ngIf="tKeySaveButtonTitle !== null && tKeySaveButtonTitle !== ''"
                                class="btn-record-save border-round-left-md border-round-right-md border-none text-xs p-1 px-4 h-auto"
                                (click)="saveEntity()" 
                                [disabled]="isSaveButtonDisabled()">{{ tKeySaveButtonTitle | translate }}</button>
                        </div>      
                        <div class="text-center" *ngIf="warningMsgLink.length > 0">
                            <label class="text-red-600 text-xs underline cursor-pointer" (click)="showWarningMsg()">{{ warningMsgLink }}</label>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column w-full text-center mt-1 mb-1">
                    <div class="flex flex-column" >
                        <div *ngIf="headerService.cautionMessage !== ''" class="w-11 mb-1" >
                            <label class="text-xs font-bold text-red-600">{{headerService.cautionMessage}}</label>
                        </div>
                        <div *ngIf="lockError?.length > 0" class="mb-1 white-space-normal">
                            <label class="text-xs font-bold text-red-600">{{lockMessageStart}}</label>
                            <a *ngIf="lockUserLink!==''" (click)="openUserModal(lockUserLink)" class="font-bold text-blue-600 cursor-pointer text-xs">{{lockUserLink}}</a>
                            <label *ngIf="lockMessageEnd!==''" class="text-xs font-bold text-red-600">{{lockMessageEnd}}</label>
                            <button class="btn-record-reload border-round-left-md border-round-right-md border-none text-xs w-8rem text-center ml-4" (click)="editRecord()">{{'common.editRecord'| translate}}</button>
                        </div>
                        <div  *ngIf="headerEntity && headerService.changeForm" class="flex flex-column justify-content-center mb-1">
                            <div>
                                <label class="text-xs text-red-600 font-bold">{{isNewEntity ? changedMessageNewEnity : changedMessage}}</label>
                                <button *ngIf="!isNewEntity"
                                         class="btn-record-reload border-round-left-md border-round-right-md border-none text-xs w-4rem text-center ml-4" (click)="discardChanges()">{{'discard'| translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
</div>

<div class="container-modal">

    <div class="empty" *ngIf="clientNotificationList.length + serverNotificationList.length === 0"> 
        <div>{{'common.emptyNotification' |translate}}</div>
    </div>

    <div class="table" *ngIf="serverNotificationList.length > 0">
        <h4>System Messages:</h4>
       
        <p-table [value]="serverNotificationList">
            <ng-template pTemplate="body" let-notif>
                <tr class="nText">
                    <td [attr.colspan]="'2'">{{notif.message}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="table" *ngIf="clientNotificationList.length > 0">
        <div class="button-cont" *ngIf="clientNotificationList.length > 0">
            <button (click)="clearClientNotifications()" class="btn-custom">{{'clear'| translate}}</button>
        </div>

        <h4>Application Events:</h4>
       
        <p-table [value]="clientNotificationList">
            <ng-template pTemplate="body" let-notif>
                <tr class="nText">
                    <td [ngClass]="notif.date !== null ? 'message' : ''" [attr.colspan]="notif.date !== null ? '1' : '2'">{{notif.message}}</td>
                    <td *ngIf="notif.date !== null" class="date">{{notif.date | date: 'dd-MMM-yyyy h:mm:ss'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

export enum  EntityPaths {
    COMPANY = 'company',
    SITE = 'site',
    ASSET_PIPELINE = "pipeline",
    ASSET_TRANSMISSION_LINE = "transmissionLine",
    PLANT = "plant",
    PROJECT = "project",
    PROJECT_CONFIRMED = "confirmedProject",
    PROJECT_UNCONFIRMED = "unconfirmedProject",
    AREA = "area",
    TANK = "tank",
    UNIT = "unit",
    TURBINE = "drive",
    MEQUIPMENT = "miningEquipment",
    OEQUIPMENT = "otherEquipment",
    BOILER = "boiler",
    EQUIPMENT = "equipment",
    OFFLINE_EVENT = 'offlineEvent',
    UNIT_LTSA = 'unitLtsa',
    PLANT_LTSA = 'plantLtsa',
    PIPELINE_LTSA = 'pipelineLtsa'
}
<p-table *ngIf="useSlottedBehavior"
      id="acContactsSlottedTable"
      #acContactsSlottedTable
      focusable
      [focusManager]="slottedFocusManager"
      [value]="acContactsSlotted"
      [columns]="slottedCols"
      [rows]="rowsPerPage"
      (onPage)="onPageChange($event)"
      [showCurrentPageReport]="true"
      styleClass="p-datatable-striped p-datatable-gridlines"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [paginator]="true"
      selectionMode="single"
      [(selection)]="contactComponent.additionalInfo"
      [resizableColumns]="true"
      [reorderableColumns]="!hideSort"
      [tabindex]="baseIndex"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      (onEditInit)="onEditInit($event)"
      (onEditComplete)="onEditComplete($event)"
      responsiveLayout="scroll">

    <ng-template pTemplate="header" let-columns>
      <tr *ngIf="!hideSort">
          <th *ngFor="let col of columns"
              [style]="col.style"
              pReorderableColumn
              pResizableColumn>
              {{col.header}}
          </th>
      </tr>
      <tr *ngIf="hideSort">
          <th  *ngFor="let col of columns"
              pResizableColumn>
              {{col.header}}
          </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
          <col *ngFor="let col of columns" [style]="col.style">
      </colgroup>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr  [pSelectableRow]="rowData" (click)="onRowclick(rowData)" (dblclick)="dgContactsPool_doubleClick(rowData)"
          (keydown)="changeSelectionOnArrowKey($event, acContactsSlotted)">
            <td *ngFor="let col of columns"
              [ngStyle]="{'color':rowData.contactDisplay.contactOptedOutBool ? 'opted-out-color' : ''}"
              [pEditableColumn]="rowData"
              [pEditableColumnField]="col.field"
              [pEditableColumnRowIndex]="index"
              class="{{col.field}} px-2"
              [style.white-space]="col.wSpace || 'normal'">

                  <div *ngIf="!col.editable" [ngClass]="rowData.contactDisplay?.contactOptedOutBool ? 'opted-out-color' : ''">
                      {{ (col.field=='contactTitle') ? functionalTitleById(rowData[col.field]) : rowData[col.field] }}
                  </div>

                  <div *ngIf="col.field=='contactId'">
                      <a href="javascript:void(0)" (click)="dgContactsPool_doubleClick(rowData)" (keydown.enter)="dgContactsPool_doubleClick(rowData)">{{rowData[col.field]}}</a>
                  </div>

                  <p-cellEditor *ngIf="col.editable">
                      <ng-template pTemplate="input">
                          <div *ngIf="col.field == 'contactStatus'">
                              <p-dropdown
                                  class="form__container-fields-layout--selects"
                                  [options]="selectContactStatus"
                                  [(ngModel)]="rowData[col.field]"
                                  [filter]="true"
                                  [autofocus]="true"
                                  showClear="true"
                                  [placeholder]="'common.selectOne' | translate"
                                  (onChange)="onChangeContactStatusInSlotted(rowData)"
                                  [appendTo]="sharedService.appRightSideElement"
                                  [disabled]="disabledButton">
                              </p-dropdown>
                          </div>
                          <div *ngIf="col.field == 'contactQcDate'" >
                              <p-calendar
                                    clearButtonStyleClass="calendarNoClear"
                                    [showButtonBar]="true"
                                    [readonlyInput]="true"
                                    [appendTo]="sharedService.appRightSideElement"
                                    [(ngModel)]="rowData['editableContactQcDate']"
                                    inputStyleClass="calendar-inputtext"
                                    [showIcon]="true"
                                    inputId="icon"
                                    dateFormat="dd-M-yy"
                                    [maxDate]="contactComponent.maxDateValue"
                                    [disabled]="disabledButton">
                              </p-calendar>
                          </div>
                          <div class="mx-1 text-center" *ngIf="col.field == 'contactOnsiteBool'">
                              <p-checkbox [(ngModel)]="rowData[col.field]" binary="true" inputId="binary" (onChange)="onChangeContactOnsite(rowData)" [disabled]="disabledButton" [tabindex]="-1"></p-checkbox>
                          </div>
                          <div class="mx-1 text-center" *ngIf="col.field == 'contactSaleFlagBool'">
                              <p-checkbox [(ngModel)]="rowData[col.field]" binary="true" inputId="binary" [disabled]="disabledButton" [tabindex]="-1"></p-checkbox>
                          </div>
                      </ng-template>

                      <ng-template pTemplate="output">
                          <div [ngClass]="rowData.contactDisplay?.contactOptedOutBool ? 'opted-out-color': ''">
                              <div *ngIf="col.field=='contactStatus'">
                                  {{rowData[col.field]}}
                              </div>
                              <div *ngIf="col.field == 'contactQcDate'">
                                  {{ rowData[col.field] | date: 'dd-MMM-yyyy' }}
                              </div>
                              <div class="mx-1 text-center" *ngIf="col.field == 'contactOnsiteBool'">
                                  <p-checkbox [(ngModel)]="rowData[col.field]" binary="true" inputId="binary" (onChange)="onChangeContactOnsite(rowData)" [disabled]="disabledButton" [tabindex]="-1"></p-checkbox>
                              </div>
                              <div class="mx-1 text-center" *ngIf="col.field == 'contactSaleFlagBool'">
                                  <p-checkbox [(ngModel)]="rowData[col.field]" binary="true" inputId="binary" (onChange)="onChangeContactSell(rowData)" [disabled]="disabledButton" [tabindex]="-1"></p-checkbox>
                              </div>
                          </div>
                      </ng-template>
                  </p-cellEditor>
                  <div *ngIf="col.field == 'rollbackButton'" class="text-center">
                    <button (click)="moveToPool(rowData)" pTooltip="{{ 'contact.contactGrid.moveToPool' | translate }}" [disabled]="disabledButton" class="btn-rollback">
                      <img src="assets/icons/down-red-arrow.png" class="w-1rem" alt="Arrow">
                    </button>
                  </div>


              </td>
          </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
          <tr>
              <td [attr.colspan]="columns.length + 1">
                  <div class="not-result-msg text-center">
                      <span>{{'contact.common.addContactComments' | translate}}</span>
                  </div>
              </td>
          </tr>
      </ng-template>
</p-table>


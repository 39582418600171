import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[location]'
})
export class LocationDirective implements OnInit{

  private previousValue: any = '';
  constructor(private elementRef: ElementRef,
              private ngControl: NgControl) {
   }

  ngOnInit(): void {
    if(this.ngControl.control && this.ngControl.control.valueChanges){
      this.ngControl.control.valueChanges.subscribe(() => {
        const input = this.elementRef.nativeElement as HTMLInputElement;
        if (! /^((\-?[0-9]{0,12}(\.?|(\.[0-9]{1,11})?)))$/.test(input.value)) {
          input.value = this.previousValue;
          input['ng-reflect-model'] = this.previousValue
        }else{
          this.previousValue = input.value;
        }
      });
    }
  }

}
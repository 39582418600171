import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { AuthService } from 'src/app/auth/auth.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { ProjectSectionService } from 'src/app/project-section/project-section.service';

@Component({
    selector: 'app-unconfirmed-project',
    templateUrl: './unconfirmed-project.component.html',
    styleUrls: ['./unconfirmed-project.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UnConfirmedProjectComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.PROJECT_UNCONFIRMED;
    private hasUnconfirmedProjectScreen: boolean = false;   

    constructor(
        private attachmentService: AttachmentService,
        private authService: AuthService,
        private projectService: ProjectSectionService,
        sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit() {
        this.initBase();
        this.authService.hasUserScreen(EntityName.PROJECT_UNCONFIRMED).subscribe(b => {
           this.hasUnconfirmedProjectScreen = b;
           if(this.hasUnconfirmedProjectScreen && this.callerIsPlant) {
               this.addRecordDestinationPage = 'unconfirmedProject';
           }
        });
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        if (this.callerIsTransmissionLine || this.callerIsPlant || this.callerIsOfflineEvent) {
            columnsInfo.push({ entityPropName: 'status', columnHeader: 'relatedInfo.project.status', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'projectId', columnHeader: 'relatedInfo.project.projectId', routerEntityName: EntityName.PROJECT_UNCONFIRMED, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'projectName', columnHeader: 'relatedInfo.project.projectName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'projectTiv', columnHeader: 'relatedInfo.project.tiv', formatThousands: true, widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'kickoffDate', columnHeader: 'relatedInfo.project.kickoffDate', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'pecTiming', columnHeader: 'relatedInfo.project.pecTiming', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'completionDate', columnHeader: 'relatedInfo.project.completionDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('cAttachment', 5, this.attachmentButtonOnClick));
        }

        return columnsInfo;
    }

    attachmentButtonOnClick(columnEntityInfo: ColumnEntityInfo, implementedComponent: UnConfirmedProjectComponent, rowData: any) {
        const projectId = rowData.projectId;
        implementedComponent.attachmentService.projectIternalAttachmentById(projectId).subscribe(resp => {
            implementedComponent.attachmentService.handlerAttachmentsByCount(resp);
        });
    }

    newRecord(implementedComponent: UnConfirmedProjectComponent) {
        implementedComponent.projectService.newProject('unconfirmedProject', implementedComponent.getCallerEntityId(), EntityName.PROJECT_UNCONFIRMED);
    }

}

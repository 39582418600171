import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { Address } from '../../core/models/address';
import { BaseComponent } from '../../base.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { Subject, Subscription } from 'rxjs';
import { AssetsPathType } from 'src/app/core/models/enumerations/assets-path-type';
import { AssetsPath } from 'src/app/core/models/assets-path';
import { AddressType } from 'src/app/core/models/enumerations/address-type';
import { OffshoreAddress } from 'src/app/core/models/offshoreAddress';
import { SearchPlantComponent } from '../modals/search-plant/search-plant.component';
import { MainSearchEvent } from 'src/app/core/models/event';
import { throttleTime } from 'rxjs/operators';
import { PlantSearch } from 'src/app/core/models/search/plant-search';
import { MessageResponse, MessageType } from '../messages/message-handler/message-handler.component';
import { Constants } from 'src/app/core/models/constants';
import { AssetsPipesLateralsDisplay } from 'src/app/core/models/asset/pipeline/display/assets-pipes-laterals-display';
import { TranslateService } from '@ngx-translate/core';
import { ValidationCommonService } from 'src/app/core/services/validation-common.service';
import { AssetsType } from 'src/app/core/models/enumerations/assets-type';
import { SharedService } from 'src/app/core/services/shared.service';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { AssetsConnectionDisplay } from 'src/app/core/models/asset/base/assets-connections-display';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-assets-path',
    templateUrl: './assets-path.component.html',
    styleUrls: ['./assets-path.component.scss']
})

export class AssetsPathComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() source: AssetsType;
    @Input() oppositePathId: number;
    @Input() assetPathType: AssetsPathType = AssetsPathType.ORIGINATION;
    @Input() changedFieldsAssetsPath?: Map<string, string> = null;
    @Input() laterals?: Array<AssetsPipesLateralsDisplay> = [];
    @Input() connections?: Array<AssetsConnectionDisplay> = [];
    @Input() baseIndex: number;
    @Input() requiredDiameter: boolean = true;
    @Output() assetsPathChanges: EventEmitter<AssetsPath> = new EventEmitter();
    @ViewChild('inputPlantId', { static: false }) inputPlantId: ElementRef;

    formGroupPath: FormGroup;
    fieldChangeAddress: Object;
    colors: any = {};

    offshore: boolean = false;
    AssetsPathType = AssetsPathType;
    AssetsType = AssetsType;
    originalPlantId: number;

    private fieldsChangePathSub: Subscription;
    private searchPlantById = new Subject<MainSearchEvent>();
    private disableFormSub: Subscription;

    constructor(public fb: FormBuilder,
        private dialogService: DialogService,
        private plantService: PlantSectionService,
        private messageHandler: MessageHandlerService,
        private translate: TranslateService,
        private validationService: ValidationCommonService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        private sharedService: SharedService
    ) { super(recordLockingFlow, preferencesService) }

    ngOnInit() {
        this.initSearchEvent();
        this.buildForm();
        this.initFormReMap();
        this.fieldsChangePathSub = this.fieldsChange$.subscribe(property => {
            this.fieldChangeAddress = property;
            if (Object.keys(property).length > 0) {
                this.assetsPathChanges.emit(this.createFromForm());
            }
        });
    }

    ngOnDestroy() {
        this.fieldsChangePathSub.unsubscribe();
    }

    initSearchEvent() {
        const searchMainDebounced = this.searchPlantById.pipe(throttleTime(200));
        searchMainDebounced.subscribe((event: MainSearchEvent) => {
            this.checkConnectionsAndSearchPlant();
        });
    }

    initFormReMap() {
        this.watchChangeForm(this.formGroupPath, [], this.changedFieldsAssetsPath);
    }

    checkConnectionsAndSearchPlant() {
        const selectedPlantId = Number(this.formGroupPath.controls.plantId.value);

        if (selectedPlantId !== this.originalPlantId) {
            const continueCallback = (): void => {
                if (selectedPlantId != null && selectedPlantId !== 0) {
                    if (!this.plantIsConnection(selectedPlantId)) {
                        this.originalPlantId = selectedPlantId;
                        this.getPlantById(selectedPlantId);
                    } else {
                        this.messageHandler.show(this.translate.instant('pipeline.path.plantIsConnection'), MessageType.INFO);
                        this.clearForm();
                    }
                } else {
                    this.clearForm();
                }
            };

            const cancelCallback = (): void => {
                this.formGroupPath.controls.plantId.setValue(this.originalPlantId);
            }

            this.validationService.validateAssociatedLaterals(this.originalPlantId, this.laterals, continueCallback, cancelCallback);
        }
    }

    getPlantById(id: number) {
        if (id === this.oppositePathId) {
            this.messageHandler.show(this.translate.instant('asset.invalidAssetPlant') + this.translate.instant('asset.invalidOrigDest'), MessageType.INFO);
            this.clearForm();
        } else {
            this.plantService.getPlantById(id).subscribe(
                (plant: PlantSearch) => {
                    if (plant) {
                        this.setValueControlsFromPlantSearch(plant);
                        this.assetsPathChanges.emit(this.createFromForm());
                    } else {
                        this.plantNotFound();
                    }
                },
                (error: any) => {
                    this.plantNotFound();
                }
            );
        }
    }

    private plantIsConnection(selectedId: number) {
        return this.connections.filter(i => String(i.getConnectionId()) === String(selectedId)).length > 0;
    }

    private plantNotFound() {
        this.formGroupPath.controls.plantId.setValue('');
        const confirmOk = (resp: MessageResponse): void => {
            this.clearForm();
            this.inputPlantId.nativeElement.focus();
        };
        const entityNotExistsMsg = Constants.replace(Constants.MESSAGE_ENTITY_NOT_EXISTS, ['Plant']);
        this.messageHandler.show(entityNotExistsMsg, MessageType.INFO, confirmOk);
    }

    setValueControlsFromPlantSearch(plant: PlantSearch) {
        this.originalPlantId = Number(plant.plantId);
        this.formGroupPath.controls.plantId.setValue(this.originalPlantId);
        this.formGroupPath.controls.plantName.setValue(plant.plantName);
        this.offshore = String(plant.plantOffshore) === '1';
        this.forceFieldChange('plantId');

        if (this.offshore) {
            this.formGroupPath.controls.countryName.setValue(plant.plantOffshoreCtryDesigName);
            this.formGroupPath.controls.stateName.setValue(plant.plantOffshoreStateDesigName);
            this.formGroupPath.controls.originOffshoreBlockV1.setValue(plant.plantOffshoreBlockV1);
            this.formGroupPath.controls.originOffshoreBlockV2.setValue(plant.plantOffshoreBlockV2);
            this.formGroupPath.controls.originOffshoreWaterbodyId.setValue(plant.plantOffshoreWaterBodyId);
            this.formGroupPath.controls.waterBodyName.setValue(plant.plantWaterBody)
            this.formGroupPath.controls.originOffshoreFieldName.setValue(plant.plantFieldName);
            this.formGroupPath.controls.areaName.setValue(plant.plantArea);
            this.formGroupPath.controls.sId.setValue(plant.plantOffshoreStateDesignation);
        } else {
            this.formGroupPath.controls.countryId.setValue(plant.plantPhysCountry);
            this.formGroupPath.controls.countryName.setValue(plant.plantPhysCountryName);
            this.formGroupPath.controls.zipCode.setValue(plant.plantPhysPostalCode);
            this.formGroupPath.controls.address1.setValue(plant.plantPhysAddressV1);
            this.formGroupPath.controls.address2.setValue(plant.plantPhysAddressV2);
            this.formGroupPath.controls.stateAbbrev.setValue(plant.plantPhysState);
            this.formGroupPath.controls.stateName.setValue(plant.plantPhysStateName);
            this.formGroupPath.controls.cityName.setValue(plant.plantPhysCity);
            this.formGroupPath.controls.pecZoneId.setValue(plant.pecZoneSimplex)
            this.formGroupPath.controls.countyName.setValue(plant.plantCountyName);
            this.formGroupPath.controls.cId.setValue(plant.plantPhysCountryId);
            this.formGroupPath.controls.sId.setValue(plant.plantPhysStateId);
            this.formGroupPath.controls.ciId.setValue(plant.plantPhysCityId);
        }

        this.formGroupPath.controls.latitude.setValue(plant.plantLatitude);
        this.formGroupPath.controls.longitude.setValue(plant.plantLongitude);
    }

    setValueControls(path: AssetsPath, fromClearForm: boolean = false) {
        this.originalPlantId = path.plantId;
        this.formGroupPath.controls.plantId.setValue(path.plantId);
        this.formGroupPath.controls.plantName.setValue(path.plantName);
        this.offshore = path.offshore;

        if (this.offshore) {
            this.formGroupPath.controls.countryName.setValue(path.offshoreAddress.origOffshoreCtryDesigName);
            this.formGroupPath.controls.stateName.setValue(path.offshoreAddress.origOffshoreStateDesigName);
            this.formGroupPath.controls.originOffshoreBlockV1.setValue(path.offshoreAddress.originOffshoreBlockV1);
            this.formGroupPath.controls.originOffshoreBlockV2.setValue(path.offshoreAddress.originOffshoreBlockV2);
            this.formGroupPath.controls.originOffshoreWaterbodyId.setValue(path.offshoreAddress.originOffshoreWaterbodyId);
            this.formGroupPath.controls.waterBodyName.setValue(path.offshoreAddress.waterBodyName);
            this.formGroupPath.controls.originOffshoreFieldName.setValue(path.offshoreAddress.originOffshoreFieldName);
            this.formGroupPath.controls.areaName.setValue(path.offshoreAddress.areaName);
            this.formGroupPath.controls.sId.setValue(path.offshoreAddress.origOffshoreStateDesignation);
        } else {
            this.formGroupPath.controls.countryId.setValue(path.onshoreAddress.countryId);
            this.formGroupPath.controls.countryName.setValue(path.onshoreAddress.countryName);
            this.formGroupPath.controls.zipCode.setValue(path.onshoreAddress.zipCode);
            this.formGroupPath.controls.address1.setValue(path.onshoreAddress.address1);
            this.formGroupPath.controls.address2.setValue(path.onshoreAddress.address2);
            this.formGroupPath.controls.stateAbbrev.setValue(path.onshoreAddress.stateAbbrev);
            this.formGroupPath.controls.stateName.setValue(path.onshoreAddress.stateName);
            this.formGroupPath.controls.cityName.setValue(path.onshoreAddress.cityName);
            this.formGroupPath.controls.pecZoneId.setValue(path.onshoreAddress.pecZoneId);
            this.formGroupPath.controls.countyName.setValue(path.onshoreAddress.countyName);
            this.formGroupPath.controls.cId.setValue(path.onshoreAddress.c_id);
            this.formGroupPath.controls.sId.setValue(path.onshoreAddress.s_id);
            this.formGroupPath.controls.ciId.setValue(path.onshoreAddress.ci_id);
            this.formGroupPath.controls.coId.setValue(path.onshoreAddress.co_id);
        }

        this.formGroupPath.controls.latitude.setValue(path.latitude);
        this.formGroupPath.controls.longitude.setValue(path.longitude);

        if (!fromClearForm) {
            if (this.assetPathType === AssetsPathType.ORIGINATION) {
                this.formGroupPath.controls.diameterOut.setValue(path.diameter);
            } else {
                this.formGroupPath.controls.diameterIn.setValue(path.diameter);
            }
        }
    }

    createFromForm(): AssetsPath {
        let onshoreAddress: Address = null;
        let offshoreAddress: OffshoreAddress = null;

        if (this.offshore) {
            offshoreAddress = new OffshoreAddress(null,
                this.formGroupPath.get(['countryName'])!.value,
                this.formGroupPath.get(['stateName'])!.value,
                this.formGroupPath.get(['originOffshoreBlockV1'])!.value,
                this.formGroupPath.get(['originOffshoreBlockV2'])!.value,
                this.formGroupPath.get(['waterBodyName'])!.value,
                this.formGroupPath.get(['originOffshoreFieldName'])!.value,
                this.formGroupPath.get(['areaName'])!.value,
                this.formGroupPath.get(['originOffshoreWaterbodyId'])!.value,
                this.formGroupPath.get(['fieldId'])!.value,
                this.formGroupPath.get(['areaId'])!.value,
                this.formGroupPath.get(['sId'])!.value
            );
        } else {
            onshoreAddress = new Address(
                null,
                AddressType.PHYSICAL,
                this.formGroupPath.get(['countryId'])!.value,
                this.formGroupPath.get(['countryName'])!.value,
                this.formGroupPath.get(['zipCode'])!.value,
                this.formGroupPath.get(['address1'])!.value,
                this.formGroupPath.get(['address2'])!.value,
                this.formGroupPath.get(['stateAbbrev'])!.value,
                this.formGroupPath.get(['stateName'])!.value,
                this.formGroupPath.get(['cityName'])!.value,
                this.formGroupPath.get(['pecZoneId'])!.value,
                this.formGroupPath.get(['countyName'])!.value,
                this.formGroupPath.get(['cId'])!.value,
                this.formGroupPath.get(['sId'])!.value,
                this.formGroupPath.get(['ciId'])!.value,
                this.formGroupPath.get(['coId'])!.value
            );
        }

        const diameterFieldName = (this.assetPathType === AssetsPathType.ORIGINATION) ? 'diameterOut' : 'diameterIn';
        return new AssetsPath(
            this.assetPathType,
            this.formGroupPath.get(['plantId'])!.value,
            this.formGroupPath.get(['plantName'])!.value,
            this.offshore,
            onshoreAddress,
            offshoreAddress,
            this.formGroupPath.get(['latitude'])!.value,
            this.formGroupPath.get(['longitude'])!.value,
            this.formGroupPath.get([diameterFieldName])!.value);
    }

    private buildForm() {
        this.formGroupPath = this.fb.group({
            plantId: ['', Validators.required],
            plantName: [{ value: '', disabled: true }, Validators.required],
            countryId: [{ value: '', disabled: true }, Validators.required],
            countryName: [{ value: '', disabled: true }, Validators.required],
            zipCode: [{ value: '', disabled: true }, Validators.required],
            address1: [{ value: '', disabled: true }, Validators.required],
            address2: [{ value: '', disabled: true }],
            stateAbbrev: [{ value: '', disabled: true }, Validators.required],
            stateName: [{ value: '', disabled: true }, Validators.required],
            cityName: [{ value: '', disabled: true }, Validators.required],
            pecZoneId: [{ value: '', disabled: true }],
            countyName: [{ value: '', disabled: true }],
            cId: [''],
            sId: [''],
            ciId: [''],
            coId: [''],
            originOffshoreBlockV1: [{ value: '', disabled: true }, Validators.required],
            originOffshoreBlockV2: [{ value: '', disabled: true }],
            waterBodyName: [{ value: '', disabled: true }],
            originOffshoreFieldName: [{ value: '', disabled: true }, Validators.required],
            areaName: [{ value: '', disabled: true }],
            originOffshoreWaterbodyId: [{ value: '', disabled: true }],
            areaId: [''],
            fieldId: [''],
            latitude: [{ value: '', disabled: true }],
            longitude: [{ value: '', disabled: true }],
            diameterIn: ['', Validators.required],
            diameterOut: ['', Validators.required]
        });

        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => {
                if (disable) {
                    this.formGroupPath.disable();
                } else {
                    this.formGroupPath.controls.plantId.enable();
                    this.formGroupPath.controls.diameterIn.enable();
                    this.formGroupPath.controls.diameterOut.enable();
                }
            });
    }

    plantIdOnTab() {
        this.searchPlantById.next(new MainSearchEvent('tab'));
    }

    plantIdOnEnter() {
        const plantId = this.formGroupPath.controls.plantId.value;
        if ((plantId == '' || plantId == null)) {
            this.openSearchModal();
        } else {
           this.searchPlantById.next(new MainSearchEvent('enter'));
        }
    }

    plantIdOnBlur() {
        this.searchPlantById.next(new MainSearchEvent('blur'));
    }

    openSearchModal() {
        const modalRef = this.dialogService.open(SearchPlantComponent, {
            header: 'Plant Search',
            width: '90rem',
            data: {},
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((plantSearch: PlantSearch) => {
            if (plantSearch && plantSearch.plantId) {
                this.formGroupPath.controls.plantId.setValue(plantSearch.plantId);
                this.checkConnectionsAndSearchPlant();
            }
        });
    }

    clearForm() {
        this.offshore = false;
        let onshore: Address = null;
        let offshore: OffshoreAddress = null;

        if (this.offshore) {
            offshore = new OffshoreAddress(null, null, '', '', '', '', '', '', null, 0, 0, 0);
        } else {
            onshore = new Address(null, 1, null, '', '', '', '', '', '', '', '', '', 0, 0, 0, 0);
        }

        let assetsPath: AssetsPath = new AssetsPath(1, null, '', this.offshore, onshore, offshore, null, null, 0);
        this.setValueControls(assetsPath, true);
    }

    resetForm() {
        this.formGroupPath.reset();
        this.fieldChangeAddress = null;
        this.resetChangedFields();
    }

}
<div class="container-modal">
    <div class="container-info-modal">
        <div class="g-row main-content">
            <div>
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'internalAttachment.attachmentsViewClick'| translate}}</strong></span>
            </div>
        </div>
    </div>
    <div class="container-table-modal">
        <p-table [value]="attachments" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <strong>{{'internalAttachment.attachmentsListTitle'| translate}}</strong>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-attach>
                <tr>
                    <td>
                        <span class="attach-link" (click)="downloadAttachment(attach)">{{attach.filename}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
    <br>
</div>

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * This provides a persistent name-value pair storage mechanism that is shared application wide (all tabs/windows within the same-origin)
 * Similar to how the "session" works on the server, but this one is client side
 */

export const enum SessionAttribute {
    PersonFullName = 'personFullName',
    PersonId = 'personId',
    MaxSeenVersion = 'maxVersion',
    NotificationsUpdated = 'notificationUpdate',
    RecentAccess = 'recentAccess',
    ServerAuthToken = 'token',
    ServerAuthTokenVersion = 'tokenVersion',
    ServerDate = 'serverDate',
    ServerSessionId = 'sessionId',
    UnreadNotifications = 'unreadNotifications',
    UpdateAvailable = 'showUpdateLink',
    UpdateRequested = 'doUpdate',
    Username = 'userName',
    WebUrl = 'websiteURL',
}

@Injectable({providedIn: 'root'})
export class SessionService {

   private static readonly STORAGE_KEY: string = "session";
   private static readonly JUNK = 'QQGZYHYSCWMFHSWTOFXGOEMIZCYKSMMG';  // A "random" string that will never actually be used as a session attribute

   private readonly attributeSubject$: Subject<ChangedSessionAttribute> = new Subject<ChangedSessionAttribute>();
   readonly attributeChange$: Observable<ChangedSessionAttribute> = this.attributeSubject$.asObservable();

   constructor()
   {
      /**
       * We bind to storage events so that we can detect & announce changes from other windows
       */
      window.onstorage = (event: any) => {
         if(event.key === SessionService.STORAGE_KEY)
         {
            let oldSession: Map<string, any> = new Map<string, any>(JSON.parse(event.oldValue));
            let newSession: Map<string, any> = new Map<string, any>(JSON.parse(event.newValue));
            const keys = [...new Set([...oldSession.keys(), ...newSession.keys()])];
            keys.forEach((name) => { 
               let oldValue = oldSession.get(name);
               let newValue = newSession.get(name);
               if(oldValue !== newValue)
               {
                  this.notifyOfChange(new ChangedSessionAttribute(name, oldValue, newValue, true));
               } 
            });
         }
      };
   }
   
   private notifyOfChange(csa: ChangedSessionAttribute): void
   {
      let otherWindow = csa.otherWindow ? "(other window)" : "";
      console.debug(`SessionService: Notifying: ${csa.name}: ${csa.oldValue} => ${csa.newValue} ${otherWindow}`);
      this.attributeSubject$.next(csa);
   }

   private loadFromStorage(): any
   {
      return JSON.parse(localStorage.getItem(SessionService.STORAGE_KEY));
   }
   
   private saveToStorage(m: Map<string, any>): void
   {
      localStorage.setItem(SessionService.STORAGE_KEY, JSON.stringify([...m]));
   }    
   
   public clearSession(): void
   {
      localStorage.removeItem(SessionService.STORAGE_KEY);
      this.notifyOfChange(new ChangedSessionAttribute(SessionService.JUNK));
   }
   
   public getAttribute(name: SessionAttribute): any
   {
      const session = this.loadFromStorage();
      if(session === null || Object.keys(session).length == 0)
      {
         return null;
      }
      
      const m:Map<string, any> = new Map<string, any>(session);
      return m.get(name);
   }

   public setAttribute(name: SessionAttribute, newValue: any): void
   {
      const m:Map<string, any> = new Map<string, any>(this.loadFromStorage());
      let oldValue = m.get(name);
      m.set(name, newValue);
      this.saveToStorage(m);
      this.notifyOfChange(new ChangedSessionAttribute(name, oldValue, newValue));
   }
   
   public removeAttribute(name: SessionAttribute): void
   {
      const m:Map<string, any> = new Map<string, any>(this.loadFromStorage());
      let oldValue = m.get(name);
      if(oldValue !== undefined)
      {
         m.delete(name);
         this.saveToStorage(m);
         this.notifyOfChange(new ChangedSessionAttribute(name, oldValue));
      }
   }

   // Some commonly requested attributes

   getUserName(): string {
      return this.getAttribute(SessionAttribute.Username);
   }
   
   getPersonId(): number {
      return this.getAttribute(SessionAttribute.PersonId);
   }
   
   getPersonFullName(): string {
      return this.getAttribute(SessionAttribute.PersonFullName);
   }
   
   getSessionId(): string {
      return this.getAttribute(SessionAttribute.ServerSessionId);
   }
   
   getWebsiteUrl(): string {
      return this.getAttribute(SessionAttribute.WebUrl);
   }

}

export class ChangedSessionAttribute {
    readonly name: string;
    readonly oldValue: any;
    readonly newValue: any;
    readonly otherWindow: boolean;
    
    constructor(name: string, oldValue?: any, newValue?: any, otherWindow?: boolean)
    {
       this.name = name;
       this.oldValue = oldValue ?? null;
       this.newValue = newValue ?? null;
       this.otherWindow = otherWindow ?? false;
    }
}


export class UserDetail {

    public static readonly EMPTY: UserDetail = UserDetail.BuildUserDetailData({});

    public title: string;
    public countryName: string;
    public stateName: string;
    public firstName: string;
    public lastName: string;
    public emailAddress: string;
    public phoneNumber: string;
    public imTool: string;
    public imUsername: string
    public subscriptionStatus: number;

    public constructor(
        title: string,
        countryName: string,
        stateName: string,
        firstName: string,
        lastName: string,
        emailAddress: string,
        phoneNumber: string,
        imtool: string,
        imUserame: string,
        subscriptionStatus: number
    ) {
        this.title = title;
        this.countryName = countryName;
        this.stateName = stateName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.emailAddress = emailAddress;
        this.phoneNumber = phoneNumber;
        this.imTool = imtool;
        this.imUsername = imUserame;
        this.subscriptionStatus = subscriptionStatus;
    }

    static BuildUserDetailData(entity: any): UserDetail {
        const {
            TITLE,
            COUNTRY_NAME,
            STATE_NAME,
            FIRSTNAME,
            LASTNAME,
            EMAILADDRESS,
            PHONENUMBER,
            IMTOOL,
            IMUSERNAME,
            SUBSCRIPTIONSTATUS
        } = entity;

        return new UserDetail(
            TITLE,
            COUNTRY_NAME,
            STATE_NAME,
            FIRSTNAME,
            LASTNAME,
            EMAILADDRESS,
            PHONENUMBER,
            IMTOOL,
            IMUSERNAME,
            SUBSCRIPTIONSTATUS
        );
    }
}
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from "src/app/primeng.module";
import { HttpLoaderFactory } from "../app.module";
import { TitleQuerySearchComponent } from "../components/modals/title-query-search/title-query-search.component";
import { EntityCommonModule } from "../entity-common.module";
import { ContactDetailsModalComponent } from "./contact-details-modal/contact-details-modal.component";
import { ContactRelatedComponent } from "./contact-details-modal/contact-related/contact-related.component";
import { FormContactDetailsComponent } from "./contact-details-modal/form-contact-details/form-contact-details.component";

@NgModule({
    declarations: [
        ContactDetailsModalComponent,
        ContactRelatedComponent,
        TitleQuerySearchComponent,
        FormContactDetailsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PrimeNgModule,
        FormsModule,
        EntityCommonModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            }
        }),
    ],
    exports: [
        ContactDetailsModalComponent,
        ContactRelatedComponent,
        TitleQuerySearchComponent,
        FormContactDetailsComponent,
    ]
})

export class ContactDetailsModule { }

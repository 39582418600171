import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { DateUtil } from 'src/app/core/utils/date-util';
import { ProjectSectionService } from 'src/app/project-section/project-section.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-confirmed-project',
    templateUrl: './confirmed-project.component.html',
    styleUrls: ['./confirmed-project.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmedProjectComponent extends RelatedInfoBase implements OnInit, OnChanges {
    entityTableKey = EntityName.PROJECT_CONFIRMED;
    currentActiveProjects: string = '';
    verifiedProjActivityCheckboDisabled: boolean = false;
    private hasConfirmedProjectScreen: boolean = false;

    lastPrevLabel: string;
    verifiedUser: string = '';
    verifiedDate: string = '';

    @Input() noProjActivityVerified?: boolean;
    @Input() noProjActivityDate?: string;
    @Input() noProjActivityLastDate?: string;
    @Input() noProjActivityLastUser?: string;
    @Input() noProjActivityPrevDate?: string;
    @Input() noProjActivityPrevUser?: string;
    @Input() noProjActivityUser?: string;

    constructor(
        private authService: AuthService,
        private attachmentService: AttachmentService,
        private projectService: ProjectSectionService,
        sharedService: SharedService
    ) {super(sharedService);}

    ngOnInit() {
        this.initBase();
        this.initProjectActivities();
        this.showLastOrPrevLabels();
        this.authService.hasUserScreen(EntityName.PROJECT_CONFIRMED).subscribe(b => {
            this.hasConfirmedProjectScreen = b;
            if(this.hasConfirmedProjectScreen && this.callerIsPlant) {
                this.addRecordDestinationPage = 'confirmedProject';
            }           
        });
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        if (this.callerIsCompany) {
            columnsInfo.push({ entityPropName: 'status', columnHeader: 'relatedInfo.project.status', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'projectId', columnHeader: 'relatedInfo.project.projectId', routerEntityName: EntityName.PROJECT_CONFIRMED, widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'projectName', columnHeader: 'relatedInfo.project.projectName', widthPercentage: 24});
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push(this.createAttachmentColumn('cAttachment', 10, this.attachmentButtonOnClick));
        } else if (this.callerIsPipeline || this.callerIsProject || this.callerIsTransmissionLine || this.callerIsPlant || this.callerIsOfflineEvent) {
            columnsInfo.push({ entityPropName: 'status', columnHeader: 'relatedInfo.project.status', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'projectId', columnHeader: 'relatedInfo.project.projectId', routerEntityName: EntityName.PROJECT_CONFIRMED, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'projectName', columnHeader: 'relatedInfo.project.projectName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'projectTiv', columnHeader: 'relatedInfo.project.tiv', formatThousands: true, widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right', editableType: ColumnEntityInfoEditableType.numberField, customSortCompare: this.customSortFormattedNumber.bind(this)});
            columnsInfo.push({ entityPropName: 'kickoffDate', columnHeader: 'relatedInfo.project.kickoffDate', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'pecTiming', columnHeader: 'relatedInfo.project.pecTiming', widthPercentage: 12});
            columnsInfo.push({ entityPropName: 'completionDate', columnHeader: 'relatedInfo.project.completionDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 11});
            columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'releaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push(this.createAttachmentColumn('cAttachment', 5, this.attachmentButtonOnClick));
        }

        return columnsInfo;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initProjectActivities();

        this.verifiedProjActivityCheckboDisabled = this.currentActiveProjects.toUpperCase() == 'YES';

        this.showLastOrPrevLabels();
        this.verifiedProjectActivity();
    }

    initProjectActivities() {
        if (this.rows.length > 0) {
            this.currentActiveProjects = this.hasCurrentActiveProjects() ? 'Yes' : 'Unknown';

            if (this.currentActiveProjects === 'Yes') {
                this.noProjActivityVerified = false;
            } else if (this.noProjActivityVerified) {
                this.currentActiveProjects = 'No';
            }
        } else if (this.noProjActivityVerified) {
            this.currentActiveProjects = 'No';
        } else {
            this.currentActiveProjects = 'Unknown';
        }
    }

    hasCurrentActiveProjects() {
        return this.rows.filter(p => p.status === 'Active' && DateUtil.validateAfterFixedDate(p.kickoffDate, '200301')).length > 0 ? true : false;
    }

    verifiedProjectActivity() {
        if (this.lastPrevLabel === 'Last:') {
            this.lastPrevLabel = 'Prev:';
            this.verifiedUser = this.noProjActivityPrevUser;
            this.verifiedDate = this.noProjActivityPrevDate;
        } else {
            this.lastPrevLabel = 'Last:';

            if (this.noProjActivityDate !== null && (this.noProjActivityLastDate == null || this.noProjActivityPrevDate == null)) {
                this.verifiedUser = this.noProjActivityUser;
                this.verifiedDate = String(this.noProjActivityDate);
            } else {
                this.verifiedUser = this.noProjActivityLastUser;
                this.verifiedDate = this.noProjActivityLastDate;
            }

            if(this.verifiedUser == null) {
                this.lastPrevLabel = '';
            }
        }
    }


    showLastOrPrevLabels() {
        this.noProjActivityLastUser == null ? this.lastPrevLabel = '' : this.lastPrevLabel = 'Last:';
    }

    attachmentButtonOnClick(columnEntityInfo: ColumnEntityInfo, implementedComponent: ConfirmedProjectComponent, rowData: any) {
        const projectId = rowData.projectId;
        implementedComponent.attachmentService.projectIternalAttachmentById(projectId).subscribe(resp => {
            implementedComponent.attachmentService.handlerAttachmentsByCount(resp);
        });
    }

    newRecord(implementedComponent: ConfirmedProjectComponent) {
        implementedComponent.projectService.newProject('confirmedProject', implementedComponent.getCallerEntityId(), EntityName.PROJECT_CONFIRMED);
    }

    resetForm() {
        this.noProjActivityVerified = null;
    }
}

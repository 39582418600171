import { Bean } from "../../bean";

export class PlantsProductInfo extends Bean {
    mvId: number;
    plantId: number;
    hsProduct: string;
    productStatus: string;
    productType: string;
    mvOrder: number;
    intdatadepamendstate: string;
    isInsert: boolean;
    lastDate: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity?.mvId ?? null;
        this.plantId = entity?.plantId ?? null;
        this.hsProduct = entity?.hsProduct ?? null;
        this.productStatus = entity?.productStatus ?? null;
        this.productType = entity?.productType ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.intdatadepamendstate = entity?.intdatadepamendstate ?? null;
        this.isInsert = entity?.isInsert ?? null;
        this.lastDate = entity?.lastDate ?? null;
    }

}

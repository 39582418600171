<div class="main-section" id="mainSection">
    
    <app-look-up-modal
        [entityIdPropName]="entityIdPropName"
        [searchedEntityIdPropName]="'fuelTypesId'"
        [entityDisplayPropName]="'fuelTypeConcat'"
        [tKeyLabelEntityId]="'fuelType.fuelTypeId'"
        [tKeyLabelEntityName]="'description'"
        [entityName]="'fuelType.fuelType' | translate"
        [entityNamePlural]="'fuelType.fuelTypes' | translate"
        [searchOnModalOpen]="true"
        [idFieldType] = "IdFieldType.entityIdOnlyNumbers"
        [entitiesArray]="entitiesArray"
        [isFuelTypeEntity]="true"
        
        [searchEntityDelegate]="searchEntityDelegate"
        
        [implementedComponent]="this">
    </app-look-up-modal>
    
</div>
<div class="main-section" id="mainSection">
    
    <app-look-up-modal
        [entityIdPropName]="'plantProductId'"
        [searchedEntityIdPropName]="'plantProductId'"
        [displayValueFunc]="displayValueFunc"
        [showSearchPanel]="false"
        [entityName]="'common.product' | translate"
        [entityNamePlural]="'common.products' | translate"
        [searchOnModalOpen]="true"
        [idFieldType] = "IdFieldType.entityIdOnlyNumbers"
        [filterByDeleted]="true"
        [entitiesArray]="entitiesArray"
        
        [searchEntityDelegate]="searchEntityDelegate"
        
        [implementedComponent]="this">
    </app-look-up-modal>
    
</div>
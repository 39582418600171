import { PipelinesLTSATypes } from "../save/pipeline-ltsa-types";

export class PipelinesLTSATypesDisplay extends PipelinesLTSATypes {
    serviceAgreementTypeName: string;

    constructor(entity: any) {
        super(entity);
        this.serviceAgreementTypeName = entity?.serviceAgreementTypeName ?? null;
    }

    static CreateInstance(entity: any): PipelinesLTSATypesDisplay {
        return new PipelinesLTSATypesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PipelinesLTSATypesDisplay> {
        return entity.map(item => PipelinesLTSATypesDisplay.CreateInstance(item));
    }
}

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
// Rxjs
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { MapitEntity } from 'src/app/core/models/common';
import { Constants } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { EventType } from 'src/app/core/models/enumerations/event-type';
import { EntityEvent } from 'src/app/core/models/event';
import { WebsiteService } from 'src/app/core/services/website.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { environment } from 'src/environments/environment';

interface Header {
    title: string,
    headerEntity: boolean,
    entityType: EntityName,
    headerRestriction: HeaderRestriction,
    tKeyExitRecordButtonTitle: string,
    tKeySaveButtonTitle: string,
    saveButtonDisabled: () => boolean
}
interface HeaderRestriction {
    showClientView?: boolean,
    showPDFLink?: boolean,
    updateTitleOnChangeRelease?: boolean
}
interface Release {
    entityId: number,
    releaseUser: string,
    releaseDate: string,
    lastUpdateStatus: string,
    lockId: number,
    lockError: string,
    lockSameUser: boolean,
    lockSameSession: boolean,
    intDataDepAmendState: number,
    updateType: number,
    qcDate: string,
    lastDate: string,
    intDataDepMigState: number,
    intDataDepMigError: string,
    entityName: string,
    entityHasSystem: boolean,
    entitySystemField: string,
    entityBaseUrl: string,
    entityMapParams: string,
    mapitEntity: MapitEntity,
    isNewEntity: boolean,
    parentEntityId: number,
    parentEntityType: EntityName
}
@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private readonly HeaderSubject$ = new BehaviorSubject<Header>(null);
    readonly headerChanged$ = this.HeaderSubject$.asObservable();
    private readonly ReleaseSubject$ = new BehaviorSubject<Release>(null);
    readonly releaseChange$ = this.ReleaseSubject$.asObservable();
    private readonly discardSubject$ = new BehaviorSubject<boolean>(null);
    readonly discardEvent$ = this.discardSubject$.asObservable();
    private readonly headerEventSubject$ = new BehaviorSubject<EntityEvent>(null);
    readonly headerEvent$ = this.headerEventSubject$.asObservable();
    PLURAL_ENTITY_TITLES: Array<string> = [
        EntityName.DRIVEN_EQUIPMENT_TYPE,
        EntityName.EQUIPMENT_TYPE,
        EntityName.KV_RATING,
        EntityName.HS_PRODUCT
    ];

    changeForm: boolean;
    cautionMessage: string;
    entityView: boolean;
    isReload: boolean = false;
    entityType: string = '';
    headerRestriction: HeaderRestriction = null;

    constructor(private titleService: Title,
        private website: WebsiteService,
        private entityUtilsService: EntityUtilsService) {
    }

    initHeaderSubjects() {
        this.HeaderSubject$.next(null);
        this.ReleaseSubject$.next(null);
        this.headerEventSubject$.next(null);
    }

    changeHeader(title: string, headerEntity: boolean,
        entityType: EntityName, headerRestriction: HeaderRestriction,
        tKeyExitRecordButtonTitle: string = null, tKeySaveButtonTitle: string = null,
        saveButtonDisabled: () => boolean = null): void {
        this.headerRestriction = headerRestriction;
        this.entityView = headerEntity;
        const headerChange: Header = {
            title,
            headerEntity,
            entityType,
            headerRestriction,
            tKeyExitRecordButtonTitle,
            tKeySaveButtonTitle,
            saveButtonDisabled
        }
        this.HeaderSubject$.next(headerChange);
        if (this.entityType !== entityType || this.entityType === EntityName.DASHBOARD) {
            let entityTitle = entityType ? this.getEntityTitle(entityType) : Constants.APP_TITLE;
            this.titleService.setTitle(entityTitle);
            window.name = StringUtils.safeWindowName(entityTitle);
        }
        this.entityType = entityType;
    }

    changeRelease(entityId: number,
        releaseUser: string,
        releaseDate: string,
        lastUpdateStatus: string,
        lockId: number,
        lockError: string,
        lockSameUser: boolean,
        lockSameSession: boolean,
        intDataDepAmendState: number,
        updateType: number,
        qcDate: string,
        lastDate: string,
        intDataDepMigState: number,
        intDataDepMigError: string,
        entityName: string,
        entityHasSystem: boolean,
        entitySystemField: string,
        entityBaseUrl: string,
        entityMapParams: any,
        mapitEntity: MapitEntity,
        isNewEntity: boolean,
        parentEntityId: number,
        parentEntityType: EntityName): void {
        this.ReleaseSubject$.next(null);
        const releaseChange: Release = {
            entityId,
            releaseUser,
            releaseDate,
            lastUpdateStatus,
            lockId,
            lockError,
            lockSameUser,
            lockSameSession,
            intDataDepAmendState,
            updateType,
            qcDate,
            lastDate,
            intDataDepMigState,
            intDataDepMigError,
            entityName,
            entityHasSystem,
            entitySystemField,
            entityBaseUrl,
            entityMapParams,
            mapitEntity,
            isNewEntity,
            parentEntityId,
            parentEntityType
        }

        this.ReleaseSubject$.next(releaseChange);
        if(this.headerRestriction?.updateTitleOnChangeRelease ?? true) {
            let entityTitle = this.getEntityTitle(this.entityType);
            window.name = StringUtils.safeWindowName(entityTitle + entityId);
            this.titleService.setTitle(entityTitle + ' ' + entityId);
        } 
    }

    clearReleaseSection() {
        this.ReleaseSubject$.next(null);
    }

    discardChangesEvent() {
        this.discardSubject$.next(true);
    }

    sendClearEvent() {
        let entityTitle = this.getEntityTitle(this.entityType);
        this.titleService.setTitle(entityTitle);
        window.name = StringUtils.safeWindowName(entityTitle);
        this.headerEventSubject$.next(new EntityEvent(EventType.CLEAR_DATA, null));
    }

    openClientView(entityName: string, entityId: string) {
        let clientViewUrl = this.entityUtilsService.getClientViewUrl(entityName, entityId);

        this.website.goToWebsite(clientViewUrl + "&setFilter=", 'view' + entityName + entityId);
    }

    windowOpenMapIt(params: any, isSystem: boolean, systemField: string,
        entityBaseUrl: string, entityType: string, entityId: number) {
        const mapItUrl = environment.googleMapBase
            + (entityBaseUrl !== null ? '/' + entityBaseUrl : '')
            + (isSystem
                ? (systemField === Constants.PIPELINE_SYSTEM
                    ? environment.googleMapEdps
                    : environment.googleMapEdts)
                : environment.googleMapEd);

        const rParams = { ...params, systemField: true };
        if (rParams.entityName) {
            rParams.entityName = rParams.entityName.replace(/'/g, "\\'");
        }
        this.website.goToWebsiteUsingPOST(mapItUrl, 'map' + entityType + entityId, rParams);
    }

    getEntityTitle(entityName: string): string {
        return this.PLURAL_ENTITY_TITLES.includes(entityName) ? `${entityName}s` : entityName;
    }

}

import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {Constants} from 'src/app/core/models/constants';
import {SharedService} from 'src/app/core/services/shared.service';
import { TableUtils } from 'src/app/core/utils/table-utils';
import {MessageEvent, MessageResponse, MessageType} from '../../messages/message-handler/message-handler.component';
import {MessageHandlerService} from '../../messages/message-handler/message-handler.service';

@Component({
    selector: 'app-title-query-search',
    templateUrl: './title-query-search.component.html',
    styleUrls: ['./title-query-search.component.scss']
})
export class TitleQuerySearchComponent implements OnInit {
    @ViewChild('titleDesc', { static: false }) titleDesc: ElementRef;
    @ViewChildren('tr') private rows: QueryList<ElementRef>
    titles: Array<any>;
    titleDescription: string = null;
    selection:any;

    constructor(
        public sharedService: SharedService,
        public messageHandlerService: MessageHandlerService,
        public activeModal: DynamicDialogRef) {
    }

    ngOnInit(): void {
    this.titles = [];
    }

    loadTitles() {
        this.sharedService.getTitles(null, this.titleDescription).subscribe((resp: Array<any>) => {
            if(resp.length > 0) {
                this.titles = resp;
                this.focusFirstElement();
            } else {
                const confirmOk = (resp: MessageResponse): void => {
                    if (resp.event === MessageEvent.OK) {
                        this.titleDesc.nativeElement.focus();
                    }
                }
                this.messageHandlerService.show(Constants.MESSAGE_GENERIC_NOT_EXISTS, MessageType.INFO, confirmOk);
            }
        });
    }

    search() {
        this.loadTitles();
    }
    
    clear() {
        this.titleDescription = ('');
        this.titles = [];
    }

    onRowSelect(event: any) {
        this.selection = event.data;
    }

    addRowSingleClick(){
        this.addRow(this.selection);
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selection", event, entityArray, hasHeader);
    }

    addRow(rowData: any) {
        this.activeModal.close(rowData);
    }
    
    closeModal(){
        this.activeModal.close()
    }

    private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 0);
    }
}

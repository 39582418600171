import { Bean } from "./models/bean";
import { OperationType } from "./models/enumerations/operation-type";
import { StringUtils } from "./utils/string-utils";

export class MVTOperations {

    public static changePosition(ac: Array<any>, dragIndex: number, dropIndex: number): void {
        if(dropIndex === dragIndex) {
            return;
        }

        ac[dragIndex].operation = ac[dragIndex].operation === OperationType.IDLE ? 
                OperationType.UPDATE : ac[dragIndex].operation;
        ac[dropIndex].operation = ac[dropIndex].operation === OperationType.IDLE ? 
                OperationType.UPDATE : ac[dropIndex].operation;

        const mvOrderDragIndex = ac[dragIndex].mvOrder;
        const mvOrderDropIndex = ac[dropIndex].mvOrder;
        ac[dragIndex].mvOrder = mvOrderDropIndex;
        ac[dropIndex].mvOrder = mvOrderDragIndex;
        ac[dragIndex].forceBeanChange('mvOrder');
        ac[dropIndex].forceBeanChange('mvOrder');

        MVTOperations.sortByMvOrder(ac);
        MVTOperations.arrangeMvOrder(ac);
    }

    public static arrangeMvOrder(ac: Array<any>, orderByAsc: boolean = true): void {
        const entitiesArray: Array<any> = [...ac];
        MVTOperations.sortByMvOrder(entitiesArray);
        let notDeletedIndex = 0
        let notDeletedLength = MVTOperations.filterByDeleted(entitiesArray).length;
        entitiesArray.forEach((element, index) => {
            const relativeMvOrder = orderByAsc ? (notDeletedIndex + 1) : (notDeletedLength - notDeletedIndex);
            if(element.operation !== OperationType.DELETE) {
                ++notDeletedIndex;
                if(element.mvOrder !== relativeMvOrder) {
                    if(element.operation !== OperationType.INSERT){
                        element.setOperation(OperationType.UPDATE);
                    }
                    element.mvOrder = relativeMvOrder;
                    element.forceBeanChange('mvOrder');
                }
            }
        });
        //move deleted to last
        let lastMvOrder = MVTOperations.getLastMvOrder(entitiesArray);
        entitiesArray.forEach((element, index) => {
            if(element.operation === OperationType.DELETE) {
                element.mvOrder = ++lastMvOrder;
                element.forceBeanChange('mvOrder');
            }
        });
    }

    public static filterByDeleted(ac: Array<any>): Array<any> {
        return ac?.filter(p => p.getOperation() !== OperationType.DELETE);
    }

    public static filterEmptyRows(ac: Array<any>, entityIdPropName: string): Array<any> {
        return ac?.filter(p => !StringUtils.isEmpty(p[entityIdPropName]));
    }

    public static sortByMvOrder(ac: Array<any>, order: string = 'asc'): Array<any> {
        return this.sortArray(ac, order, "mvOrder");
    }

    public static sortArray(ac: Array<any>, order: string = 'asc', entityPropName: string): Array<any> {
        const response = ac.sort((a, b) => Number(a[entityPropName]) === Number(b[entityPropName]) ? 0 : (Number(a[entityPropName]) > Number(b[entityPropName]) ? 1 : -1));
        if(order === 'desc') {
            response.reverse();
        }
        return response;
    }

    public static sortArrayByFields(ac: Array<any>, order: string = 'asc', fieldsToOrder: Array<any>): Array<any> {
        const response = ac.sort((a, b) => {
          for (const fieldObj of fieldsToOrder) {
            const field = fieldObj.name;
            const aValue = a[field];
            const bValue = b[field];
      
            if (aValue === bValue) {
              continue;
            } else if (fieldObj.numeric) {
              return Number(aValue) > Number(bValue) ? 1 : -1;
            } else {
              return aValue > bValue ? 1 : -1;
            }
          }
      
          return 0;
        });
      
        if (order === 'desc') {
          response.reverse();
        }
      
        return response;
      }
      
      public static sortAllByMvOrder(acData: Array<any>, callFunction: Function | null = null): void {
        let objIndex: number = 1;
      
        for (let i: number = 0; i < acData.length; i++) {
          if (acData[i].operation !== OperationType.TEMPORAL) {
            if (acData[i] instanceof Bean) {
              // Forcing change the mvOrder property.
              if (acData[i].mvOrder !== objIndex) {
                acData[i].forceBeanChange("mvOrder", acData[i].mvOrder, objIndex);
              }
      
              acData[i].mvOrder = objIndex++;
            }
          }
        }
      
        if (callFunction !== null) {
          callFunction();
        }
      }
        

    public static sortBySaleFlag(ac: Array<any>): Array<any> {
        return ac.sort((a, b) => b.notesSaleFlag.localeCompare(a.notesSaleFlag) || Number(b.mvOrder) - Number(a.mvOrder));
    }

    public static getLastMvOrder(ac: Array<any>, includeTemporals: boolean = true): number {
        let lastMvOrder = 0;
        for (let item of ac) {
            if (!includeTemporals && item.getOperation() === OperationType.TEMPORAL) {
                continue;
            }

            if (item.mvOrder >= lastMvOrder && item.getOperation() !== OperationType.DELETE) {
                lastMvOrder = item.mvOrder;
            }
        }
        return lastMvOrder;
    }

    public static removeItem(ac: Array<any>,
                             propertyNameToEval1: string,
                             propertyValueToRemove1: string,
                             propertyNameToEval2: string = null,
                             propertyValueToRemove2: string = null,
                             arrange: boolean = true): void {
        let removeIndex = -1;


        for (let curIndex = 0; curIndex < ac.length; curIndex++) {
            const item = ac[curIndex];

            if (item[propertyNameToEval1] == propertyValueToRemove1 && item.operation != OperationType.DELETE) {
                if (propertyNameToEval2 != null) {
                    if (item[propertyNameToEval2] == propertyValueToRemove2) {
                        removeIndex = curIndex;
                        break;
                    }
                } else {
                    removeIndex = curIndex;
                    break;
                }
            }
        }

        if (removeIndex != -1) {
            MVTOperations.remove(removeIndex, ac, arrange);
        }
    }

    public static remove(removeIndex: number, ac: Array<any>, arrange: boolean = true): boolean {
        var physicalRemove: boolean = false;

        if (removeIndex != -1) {
            var item: any = ac[removeIndex];

            if (item.operation === OperationType.INSERT || item.operation == OperationType.TEMPORAL) {
                physicalRemove = true;
            } else {
                item.forceUpdateBasicFields();
                item.setOperation(OperationType.DELETE);
            }
            if (physicalRemove) {
                ac.splice(removeIndex, 1);
            }
            if (arrange) {
                MVTOperations.arrangeMvOrder(ac);
            }
        }

        return physicalRemove;
    }

    public static removeAll(list: Array<any>): void {
        let existingRows  = [];
        //Existing saved rows should be retained
        list.forEach(item => {
            if(item.operation !== OperationType.INSERT && item.operation !== OperationType.TEMPORAL){
                existingRows.push(item);
                item.forceUpdateBasicFields();
                item.setOperation(OperationType.DELETE);
            }
        });
        list.length = 0;
        list.push(...existingRows);
    }

    public static filterByTemporalRow(ac: Array<any>): Array<any> {
        return ac?.filter(p => p.getOperation() !== OperationType.TEMPORAL);
    }

    public static removeTemporalRow(ac: Array<any>): void {
        ac.forEach((element, index )=> {
            if(element.getOperation() === OperationType.TEMPORAL){
                ac.splice(index, 1);
                return;
            }
        });
    }

    public static arrangeMvOrderFlexVersion(currentIndex: number, isInsert: boolean, ac: Array<any>): void {
        const fromMvOrder = ac[currentIndex].mvOrder;

        for (let i = 0; i < ac.length; i++) {
            const item = ac[i];
            if(currentIndex === i && item.mvOrder >= fromMvOrder && item.operation !== OperationType.DELETE) {
                if(isInsert) {
                    item.mvOrder++;
                } else {
                    item.mvOrder--;
                }
            }
        }

        let lastMvOrder = MVTOperations.getLastMvOrder(ac) + 1;

        for (let i = 0; i < ac.length; i++) {
            const item = ac[i];
            if(item.operation === OperationType.DELETE) {
                item.mvOrder = lastMvOrder++;
            }
        }
    }

    public static deleteTemporalMvts(mvtList: Array<Array<Bean>>) : void{
        if(mvtList && mvtList !== null){
            mvtList.forEach((mvt: Array<Bean>) => {
                if(mvt && mvt !== null){
                    this.removeTemporalRow(mvt);
                }
            });
        }
       
    }

    static deleteEmptyRows(mvtList: Array<Bean>, entityIdPropName: string): void {
        let emptyRows = this.getEmptyRows(mvtList, entityIdPropName);
        if (emptyRows.length > 0) {
            emptyRows.forEach(mvtItem => {
                MVTOperations.removeItem(mvtList, 'mvOrder', String(mvtItem["mvOrder"]));
            });
        }
    }

    static getEmptyRows(mvtList: Array<Bean>, entityIdPropName: string):Array<Bean> {
        let emptyRows = mvtList.filter(mvtItem =>
            mvtItem.getOperation() !== OperationType.DELETE
            && StringUtils.isEmpty(mvtItem[entityIdPropName]));
        return emptyRows;
    }

}

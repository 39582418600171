import { Injectable } from '@angular/core';
// Rxjs
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';

export interface IMenu {
    name: string;
    changeVisibility?: IMenuChange;
    changeVisibilities?: Array<IMenuChange>;
    menuItems?: Array<MenuItem>;
}

export interface IMenuChange {
    label: string;
    visible: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AsideService {
    private readonly MenuSubject$ = new Subject<IMenu>();
    readonly menuChanged$ = this.MenuSubject$.asObservable();

    private readonly MenuInitSubject$ = new Subject<boolean>();
    readonly menuInit$ = this.MenuInitSubject$.asObservable();

    constructor() {}

    changeMenu(menu: IMenu) {
        this.MenuSubject$.next(menu);
    }

    changeMenuState() {
        this.MenuInitSubject$.next(true);
    }

}

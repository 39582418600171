import {
    A,
    BACKSPACE,
    DELETE,
    Z,
} from '@angular/cdk/keycodes';
import {
    Directive,
    ElementRef,
    HostListener,
    Renderer2,
    Self,
    forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[appUppercase]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UppercaseDirective),
            multi: true,
        },
    ],
})
export class UppercaseDirective implements ControlValueAccessor {
    lastValue: string;
    /** implements ControlValueAccessorInterface */
    _onChange: (_: any) => void;

    /** implements ControlValueAccessorInterface */
    _touched: () => void;

    constructor(@Self() private _el: ElementRef, private _renderer: Renderer2) { }

    /** Handle keyboard events */
    @HostListener('keyup', ['$event'])
    onKeyDown(keyboardEvent: KeyboardEvent) {
        const keyCode = keyboardEvent.keyCode;
        const key = keyboardEvent.key;
        if ((keyCode >= A && keyCode <= Z) || keyCode === BACKSPACE || keyCode === DELETE || !isNaN(parseInt(key))) {
            const value = String(this._el.nativeElement.value).toUpperCase();
            this._renderer.setProperty(this._el.nativeElement, 'value', value);
            this._onChange(value);
            keyboardEvent.preventDefault();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur() {
        this._touched();
    }

    @HostListener('input', ['$event']) onInput($event) {

        const { target } = $event;
        const start = target.selectionStart;
        const end = target.selectionEnd;

        target.value = target.value.toUpperCase();
        target.setSelectionRange(start, end);
        $event.preventDefault();

        this.handleValueChanged(target.value);
    }

    private handleValueChanged(newValue: string): void {
        if (!this.lastValue || (newValue.length > 0 && this.lastValue !== newValue)) {
            this.lastValue = this._el.nativeElement.value = newValue;
            this._onChange(newValue);
        }
    }

    /** Implementation for ControlValueAccessor interface */
    writeValue(value: any): void {
        this._renderer.setProperty(this._el.nativeElement, 'value', value);
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnTouched(fn: () => void): void {
        this._touched = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
    }
}


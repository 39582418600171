import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address } from 'src/app/core/models';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { OffshoreAddress } from 'src/app/core/models/offshoreAddress';
import { LockSameUserMessageService, LockSameUserResult } from 'src/app/core/services/lock-same-user-message.service';
import { EntityPaths } from 'src/app/core/utils/entityPaths';

@Component({
  selector: 'app-address-import-modal',
  templateUrl: './address-import-modal.component.html',
  styleUrls: ['./address-import-modal.component.scss']
})
export class AddressImportModalComponent implements OnInit {
  items: Array<any> = [];
  selectedItem: any = null;
  entityAddress:any = null;

  entityId: number = null;
  entityType: string = null;
  rightTitle: string = null
  leftTitle: string = null
  pageNumber: number = null;
  isPhysical: boolean = true;
  isOffshore: boolean = false;
  isLock: boolean = false;
  showCarousel: boolean = false;
  type: string = '';

  constructor(private modal: DynamicDialogRef,
              private translate: TranslateService,
              private config: DynamicDialogConfig,
              private lockSameUserMessageService: LockSameUserMessageService) {

    this.entityAddress = config.data.target;
    this.entityId = config.data.entityId;
    this.entityType = config.data.entityType;
    this.isPhysical = config.data.isPhysical;
    this.isOffshore = config.data.isOffshore;
    this.isLock = config.data.isLock;
    this.rightTitle = this.entityType === EntityName.SITE  ?
    this.translate.instant('addressComparation.site'):
    this.translate.instant('addressComparation.plant'),
    this.leftTitle = this.entityType === EntityName.SITE  ?
    this.translate.instant('addressComparation.plant'):
    this.translate.instant('addressComparation.site'),
    this.pageNumber = 1;
    this.type = this.isPhysical ?
                  this.translate.instant('addressComparation.physical') :
                  this.translate.instant('addressComparation.mailing') ;
    if(this.entityType === EntityName.SITE ){
      this.buildAddressesFromPlants(config.data.addressImportData);
    }else if(this.entityType === EntityName.PLANT){
      this.buildAddressFromSite(config.data.addressImportData);
    }

    this.showCarousel = this.items.length > 1;
    if(this.items && this.items.length > 0){
      this.loadItem(0);
    }
  }

  ngOnInit(): void {
  }

  buildAddressFromSite(site: any){
    this.items = [];
    if(this.isPhysical){
      if(this.isOffshore){
        this.items.push(OffshoreAddress.BuildOffshoreAddressFromSiteResult(site))
      }else{
        this.items.push(Address.BuildPhysicalAddressFromSiteResult(site))
      }
    }else{
      this.items.push(Address.BuildMailingAddressFromSiteResult(site))
    }
  }


  buildAddressesFromPlants(plants: Array<any>){
    this.items = [];
    if(plants && plants !== null && plants.length > 0){
      if(this.isPhysical){
        if(this.isOffshore){
          plants.forEach(plant =>{
            if(OffshoreAddress.hasSitePhysicalOffshoreDiff(this.entityAddress, plant)){
              this.items.push(OffshoreAddress.BuildOffshoreAddress(plant))
            }
          });
        }else{
          plants.forEach(plant =>{
            if(Address.hasSitePhysicalOnshoreDiff(this.entityAddress, plant)){
              this.items.push(Address.BuildPhysicalAddressFromPlant(plant))
            }
          });
        }
      }else{
        plants.forEach(plant =>{
          if(Address.hasSiteMailingDiff(this.entityAddress, plant)){
            this.items.push(Address.BuildMailingAddressFromPlant(plant))
          }
        });
      }
    }
  }

  openEntity(entityId: string): void{
    const entityType = this.entityType === EntityName.SITE ? EntityName.PLANT : EntityName.SITE;
    const entityID = entityId;
    let entityPath = EntityPaths[Object.keys(EntityName).find(key => EntityName[key] === entityType)];
    this.lockSameUserMessageService.validateEntityIsLockedByUserAndOpenEntityTab(entityType, entityID, location.pathname + `#/ED/${entityPath}/${entityID}`, `${entityType + entityID}`);
  }

  onPage(event: any): void{
    this.pageNumber = event.page+1;
    this.loadItem(event.page);
  }

  loadItem(index: number){
    this.selectedItem = this.items[index];
  }

  importAddress():void{
    this.modal.close(this.selectedItem);
  }

  close(): void{
    this.modal.close();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './core/guards/auth.guard';


const ROUTES: Routes = [
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
    },
    {
        path: 'ED',
        canLoad: [AuthGuard],
        canActivate: [AuthGuard],
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    },
    { path: '**', redirectTo: 'auth' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(ROUTES, { useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'})
    ]
})

export class AppRoutingModule { }

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchEntityModalService {

    private savedParams: Map<string, HttpParams> = new Map<string, HttpParams>();
    
    constructor() {}

    saveParams(key: string, params: HttpParams): void {
        this.savedParams.set(key, params);
    }

    getSavedParams(key: string): HttpParams {
        return this.savedParams.get(key) ?? new HttpParams();
    }

    clearSavedParams(key: string): void {
        this.savedParams.delete(key);
    }

}

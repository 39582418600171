import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
/// Rxjs
import { map } from 'rxjs/operators';
import { AttachmentItem, AttachmentsModalComponent } from "src/app/components/modals/attachments/attachments-modal";
// Environment
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    constructor(
        private http: HttpClient,
        private dialogService: DialogService
    ) {}

    uploadFile(entityId: string, entityName: string, file: any) {
        return this.http.post(`${environment.apiUrl}attachment/upload/${entityName}/${entityId}`, file)
            .pipe(
                map(res => {
                    return res;
                })
            );
    }

    downloadFile(entityName: string, entityId: string, filename: string) {
        return this.http.get(`${environment.apiUrl}attachment/download/${entityName}/${entityId}/${btoa(filename)}`, {responseType: 'blob'})
            .pipe(
                map((res: any) => {
                    return {
                        filename: filename,
                        data: res
                    }
                })
            ).subscribe(res => {
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.')
            });
    }

    plantIternalAttachmentById(plantId: number) {
        return this.http.get<any>(`${environment.apiUrl}plant/attachment/${plantId}`)
            .pipe(
                map((data: any) => {
                    let plantAttachments = [];
                    if (data.response && data.response.length > 0) {
                        plantAttachments = data.response;
                    }
                    return plantAttachments;
                })
            );
    }

    projectIternalAttachmentById(projectId: number) {
        return this.http.get<any>(`${environment.apiUrl}project/attachment/${projectId}`)
            .pipe(
                map((data: any) => {
                    let projectAttachments = [];
                    if (data.response && data.response.length > 0) {
                        projectAttachments = data.response;
                    }
                    return projectAttachments;
                })
            );
    }

    assetIternalAttachmentById(assetId: number) {
        return this.http.get<any>(`${environment.apiUrl}asset/attachment/${assetId}`)
            .pipe(
                map((data: any) => {
                    let assetAttachments = [];
                    if (data.response && data.response.length > 0) {
                        assetAttachments = data.response;
                    }
                    return assetAttachments;
                })
            );
    }

    handlerAttachmentsByCount(attachmentsArray: any) {
        const items: Array<AttachmentItem> = [];
        for (let i = 0; i < attachmentsArray.length; i++) {
            const urlArray = String(attachmentsArray[i].URL).split('/');
            items.push(new AttachmentItem(urlArray[1], urlArray[2], urlArray[3]));
        }

        if (items.length === 1) {
            const item = items[0];
            this.downloadFile(item.entityName, item.entityId, item.filename);
        } else if (items.length > 1) {
            this.openViewAttachments(items);
        }
    }

    openViewAttachments(items: Array<AttachmentItem>) {
        const attachmentData = {
            attachments: items
        }
        const modalRef = this.dialogService.open(AttachmentsModalComponent, {
            header: 'View Attachments',
            width: '30rem',
            data: attachmentData,
            draggable: true,
            keepInViewport: true
        });
    }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { AuthAction, AuthService } from "src/app/auth/auth.service";
import { environment } from "src/environments/environment";
import { ContactsDisplay } from "../models/contact/display/contacts-display";
import { EntityName } from "../models/enumerations/entity-name";
import { ContactEventType } from "../models/enumerations/event-type";
import { LockMode } from "../models/enumerations/lock-mode";
import { ContactEvent } from "../models/event";
import { ContactService } from "../services/contact.service";
import { CellContainerService } from "../services/cell-container.service";
import { CellItem } from "./cell-item";
import { LockItem } from "./lock-item";
import { RecordLockingService } from "./record-locking.service";

@Injectable({
    providedIn: 'root'
})
export class RecordLockingFlow {
    lastSaveEntityID: number;
    lastSaveEntitySvtID: number;
    lastSaveEntityUpdateTime: string;

    cell: Array<CellItem> = [];
    cgll: Array<LockItem> = [];
    globalLockList: Array<LockItem> = [];
    private lockTime: Subscription = null;

    private sharedDataSubject = new BehaviorSubject<any>(null);
    sharedData$: Observable<any> = this.sharedDataSubject.asObservable();
    cellContainerData: { 
        cell: Array<CellItem>,
        cgll: Array<LockItem>  
    };
    listCgll: any;
    listCell: any;


    constructor(private recordLockingService: RecordLockingService,
                  private http: HttpClient,
                  private authService: AuthService,
                  private contactService: ContactService,
                  private cellContainerService:CellContainerService) {
        this.authService.loginChange$.subscribe((action: AuthAction) => {
            if (action === AuthAction.LOGOUT) {
                this.endLockRefreshTime();
            }
        });
    }

    setLockItem(entityName: string, entityId: number, lockId: number, lockMode: string = null): void {
        const mainLockItem = this.getLockItem(entityName, String(entityId));

        if (!mainLockItem) {
            this.globalLockList.push(new LockItem(entityName, String(entityId), String(lockId), lockMode));
        } else {
            mainLockItem.lockId = String(lockId);
        }
    }

    setContactLockItem(entityName: string, entityId: string, contactId: string, contactLockId: string): void {
        const mainLockItem = this.getLockItem(entityName, entityId);
        let lockItemContactCRLL = this.getLockItemContactFromCRLL(contactId);

        if (lockItemContactCRLL && lockItemContactCRLL !== null) {
            lockItemContactCRLL.lockId = contactLockId;
            lockItemContactCRLL.entityEditedLockId = mainLockItem.lockId;
        } else {
            lockItemContactCRLL = new LockItem(EntityName.CONTACT, contactId, contactLockId, null, mainLockItem.lockId);
            let storedData: RecordLockingFlow["cellContainerData"] = this.cellContainerService.getStoredData();
            if(storedData?.cgll.length>0){
                this.cgll = storedData.cgll;
                this.cgll.push(lockItemContactCRLL);
            }else{
                this.cgll.push(lockItemContactCRLL);
            }

            this.cellContainerData = {
                'cell':this.cell,
                'cgll':this.cgll
            };
            this.cellContainerService.setData(this.cellContainerData);
           
        }

        if (mainLockItem != null) {
            mainLockItem.addContactLockItem(lockItemContactCRLL);
        }
    }

    setEntityIdToCellItem(contactId: string, entityName: string, entityId: string): void {
        this.setCellItem(entityName, contactId, null, this.getCellEntityId(entityName, entityId));
    }

    getLockID(entityName: string, entityId: string): number {
        let result = 0;
        const mainLockItem = this.getLockItem(entityName, entityId);
        if (mainLockItem) {
            result = Number(mainLockItem.lockId);
        }
        return result;
    }

    clearLockFlow(entityName: string, entityId: string, isQcUpdateOrAmmend: boolean = true, returnFunction: () => void = null) {
        this.clearEntityLockFlow(entityName, entityId, isQcUpdateOrAmmend, returnFunction);
    }

    clearLockFlowOnUnload(entityName: string, entityId: string) {
        this.clearEntityLockFlow(entityName, entityId, true, null, true);
    }

    clearContactLockFlow(entityName: string, entityId: string) {
        this.clearEntityLockFlow(entityName, entityId, false);
    }

    private clearEntityLockFlow(entityName: string, entityId: string, isQcUpdateOrAmmend: boolean = false, returnFunction?: () => void, isUnload?: boolean) {
        const locksIdToClear: Array<Number> = [];
        const mainLockItem = this.getLockItem(entityName, entityId);
        if (mainLockItem) {
            if (isQcUpdateOrAmmend) {
                locksIdToClear.push(Number(mainLockItem.lockId));
                this.globalLockList = this.globalLockList.filter(p => p.lockId !== mainLockItem.lockId);
            }

            const contactLockIdsToClear = this.getContactLocksItemsToClear(mainLockItem);
            contactLockIdsToClear.forEach(item => locksIdToClear.push(Number(item.lockId)));

            
            if (locksIdToClear.length === 0) {
                if(returnFunction){
                    returnFunction();
                }
            } else {
                if(isUnload)
                {
                    this.recordLockingService.clearLockDuringUnload(locksIdToClear);
                }
                else
                {
                    this.recordLockingService.clearLock(locksIdToClear).subscribe(returnFunction);
                }
                
            }
            this.clearAssociatedCell(mainLockItem);

            mainLockItem.clearContactList();
            this.clearCGLL(contactLockIdsToClear);
        } else {
            if (returnFunction) {
                returnFunction();
            }
        }
    }

    clearAssociatedCell(lockItem: LockItem): void {
        const entityIdForContact = this.getCellEntityId(lockItem.entityName, lockItem.entityId);

        lockItem.contactsLock.forEach(item => {
            const cellItem = this.getCellItemByContactId(item.entityId);

            if (cellItem != null && (cellItem.entityId === null || cellItem.entityId === entityIdForContact)) {
                this.cell = this.cell.filter(p => p !== cellItem);
                
                let storedData: RecordLockingFlow["cellContainerData"] = this.cellContainerService.getStoredData();
                this.cellContainerData.cell = storedData.cell.filter(p => p.contactId !== cellItem.contactId);
                this.cellContainerData.cgll = storedData.cgll;
                this.cellContainerService.setData(this.cellContainerData);
            }
        });

        this.clearEntityIDOrLockIdNull(entityIdForContact);
    }

    getLockItem(entityName: string, entityId: string) {
        return this.globalLockList.find(p => p.entityName === entityName && p.entityId == entityId);
    }

    getContactLocksItemsToClear(lockItem: LockItem): Array<LockItem> {
        const contactsLocksToClear: Array<LockItem> = [];
        lockItem.contactsLock.forEach(item => {
            contactsLocksToClear.push(item);
        });

        return contactsLocksToClear;
    }

    getCellEntityId(entityName: string, entityId: string) {
        return entityName + ' ' + entityId;
    }

    getCellItemByContactId(contactId: string): CellItem {
        let cell = this.cell.find(p => p.contactId === contactId);
        return cell ? cell : null;
    }

    clearCGLL(locksItemsToClear: Array<LockItem>): void {
        const tempItem: Array<LockItem> = [];
        for (const itemToClear of locksItemsToClear) {
            for (const cgllItem of this.cgll) {
                if (cgllItem.entityId === itemToClear.entityId
                    && cgllItem.entityName === itemToClear.entityName
                    && cgllItem.lockId === itemToClear.lockId) {
                    tempItem.push(cgllItem);
                    this.cellContainerData.cgll = this.cellContainerData.cgll.filter(p => p.lockId !== cgllItem.lockId);
                    this.cellContainerService.setData(this.cellContainerData);
                    break;
                }
            }
        }
        tempItem.forEach(item => this.cgll.splice(this.cgll.findIndex(p => p === item), 1));
        
    }

    clearEntityIDOrLockIdNull(entityIdForContact: string): void {
        const cellNulls: Array<CellItem> = [];
        this.cell.forEach(item => {
            if (item.entityId === null || (item.entityId === entityIdForContact && item.lockId === null)) {
                cellNulls.push(item);
            }
        });

        cellNulls.forEach(itemNull => {
            this.cell.splice(this.cell.findIndex(p => p === itemNull));
        });
    }

    getCellItem(contactId: string, entityId: string): CellItem {
        return this.cell.find(p => (entityId === null && p.contactId === contactId) || (p.entityId === entityId && p.contactId === contactId));
    }

    loadContactRecordFlow(contactId: string,
        entityName: string,
        entityId: string,
        contactsDisplays: Array<ContactsDisplay>,
        onlyForRead,
        returnFunction: (event: ContactEvent) => void = null) {
        const cellItem = this.getCellItem(contactId, null);

        // Is contact in CELL
        if (cellItem && cellItem !== null) {
            if (cellItem.lockId === null) {
                cellItem.lockId = this.getContactLockID(contactId);
            }

            // Is entityId null?
            if (cellItem.entityId === null) {
                this.getContactDetailsById(entityName, entityId, contactId, cellItem.lockId, false, returnFunction);
            } else {
                // Does entityId match?
                if (cellItem.entityId === this.getCellEntityId(entityName, entityId)) {
                    let contactDisplay: ContactsDisplay = contactsDisplays.find(p => p.contactId === Number(contactId));
                    contactDisplay.lockMode = LockMode.EDIT;
                    returnFunction(new ContactEvent(ContactEventType.NONE, contactDisplay));
                } else {
                    this.getContactDetailsById(entityName, entityId, contactId, cellItem.lockId, true, returnFunction);
                }
            }
        } else {
            // Load Contact Record.
            this.getContactDetailsById(entityName, entityId, contactId, null, onlyForRead, returnFunction);
        }
    }

    getContactDetailsById(mainEntityName: string, mainEntityId: string, contactId: string, lockId: string, onlyForRead: boolean = false, returnFunction: (event: ContactEvent) => void = null): void {
        let eventType = ContactEventType.LOAD_FROM_FLOW;
        let entityId = mainEntityId;
        let entityName = mainEntityName;
        if (lockId != null) {
            if (onlyForRead) {
                eventType = ContactEventType.LOAD_RECORD_WITH_LOCKID_READONLY;
            } else {
                eventType = ContactEventType.LOAD_RECORD_WITH_LOCKID;
            }
        } else {
            lockId = this.getContactLockID(contactId);
        }

        this.contactService.getEntityDetailsById(contactId, Number(lockId), onlyForRead).subscribe((contactDisplay) => {
            if (eventType === ContactEventType.LOAD_RECORD_WITH_LOCKID_READONLY) {
                contactDisplay.lockMode = LockMode.READ;
            }

            if (eventType === ContactEventType.LOAD_RECORD_WITH_LOCKID) {
                contactDisplay.lockMode = LockMode.EDIT;
            }

            if (eventType === ContactEventType.LOAD_FROM_FLOW) {
                if (contactDisplay.lockMode === LockMode.EDIT) {
                    this.setCellItem(String(contactDisplay.contactId), 
                                            String(contactDisplay.lockId), entityId, entityName);
                }
            }

            returnFunction(new ContactEvent(eventType, contactDisplay));
        });
    }

    setCellItem(contactId: string, lockId: string, entityId: string, entityName: string): void {
        let cellItem = this.getCellItem(contactId, null);

        if (cellItem != null) {
            cellItem.entityId = entityId;
        } else {
            cellItem = new CellItem(contactId, lockId, 
                        this.getCellEntityId(entityName, entityId), entityId);
            let storedData: RecordLockingFlow["cellContainerData"] = this.cellContainerService.getStoredData();
            if(storedData?.cell.length>0){
                this.cell = storedData.cell;
                this.cell.push(cellItem);

                this.cgll = storedData.cgll;
            }else{
                this.cell.push(cellItem);
            }

            this.cellContainerData = {
                'cell':this.cell,
                'cgll':this.cgll
            };
            this.cellContainerService.setData(this.cellContainerData);
        }
    }

    getLockItemContactFromCRLL(contactId: string): LockItem {
        return this.cgll.find(p => p.entityId === contactId);
    }

    getContactLockID(contactId: string) {
        let result: string = null;
        const contactLockItem = this.getLockItemContactFromCRLL(contactId);
        if (contactLockItem) {
            result = contactLockItem.lockId;
        }
        return result;
    }

    initLockRefreshTimer(entityName: string, entityId: string, lockId: string): Subscription {
        this.endLockRefreshTime();
        this.lockTime = interval(environment.renewLeaseMinutes * 60 * 1000).subscribe(
            timer => this.lockRefresh(entityName, entityId, lockId)
        );
        return this.lockTime;
    }

    lockRefresh(entityName: string, entityId: string, lockId: string) {
            this.http.post(`${environment.apiUrl}recordlocking/refreshLock`, 
            this.getLockRefreshData(entityName, entityId, lockId))
            .subscribe();
        
    }

    private getLockRefreshData(entityName: string, 
            entityId: string, lockId: string): Array<Object[]>{
        let data: Array<Object[]> = [];
        data.push([entityName,entityId,lockId]);
        this.cell.filter(c => String(c.entityNumberId) === String(entityId)).forEach(c => {
            data.push([EntityName.CONTACT,c.contactId,c.lockId]);
        });
        return data;
    }



    endLockRefreshTime() {
        this.lockTime?.unsubscribe();
    }

}

<div class="w-full border-round-md">
    <div class="message-title-background font-bold text-lg text-white 
                    text-left w-full h-3rem pt-2 pl-3">
        {{"lockedEntity.title" | translate}}
    </div>
    <div class="pt-1 pb-4 pr-3 pl-3 text-white">
        <div class="text-left">
            <p class="p-text" *ngIf="lockedItem.sameSession">
                {{"lockedEntity.legendSameSession" | translate}}
            </p>
            <p class="p-text" *ngIf="!lockedItem.sameSession">
                {{"lockedEntity.legendAnotherSession" | translate}}
            </p>
        </div>
        <div class="grid">
            <div class="col-4 mb-6 xl:mb-3 lg:mb-3">
                <button pButton type="button"  #openAnywayButton tabindex="1"
                    (click)="openAnyway()" class="w-full message-button">
                    {{"lockedEntity.openAnyway" | translate}}
                </button>
            </div>
    
            <div class="col-8 text-sm">
                {{"lockedEntity.openAnywayLegend" | translate}}
            </div>
        </div>
        <div class="grid" *ngIf="!lockedItem.sameSession">
            <div class="col-4 mb-6 xl:mb-3 lg:mb-3">
                <button pButton type="button" (click)="openReadMode()" 
                    class="w-full message-button" tabindex="2">
                    {{"lockedEntity.openReadMode" | translate}}
                </button>
            </div>
    
            <div class="col-8 text-sm">
                {{"lockedEntity.openReadModeLegend" | translate}}
            </div>
        </div>
        <div class="grid">
            <div class="col-4 mb-6 xl:mb-3 lg:mb-3">
                <button pButton type="button" (click)="cancel()" 
                class="w-full message-button" tabindex="3">
                    {{"lockedEntity.cancel" | translate}}
                </button>
            </div>
    
            <div class="col-8 text-sm">
                {{"lockedEntity.cancelLegend" | translate}}
            </div>
        </div>
    </div>
</div>
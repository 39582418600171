import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { SessionService } from 'src/app/core/services/session.service';
import { CellContainerService } from 'src/app/core/services/cell-container.service';
import { WebsiteService } from 'src/app/core/services/website.service';

@Component({
    selector: 'app-nav-user',
    templateUrl: './nav-user.component.html',
    styleUrls: ['./nav-user.component.scss']
})
export class NavUserComponent implements OnInit {

    username: string;
    displayName: string;

    constructor(
        private authService: AuthService,
        private session: SessionService,
        private website: WebsiteService,
        private messageHandlerService: MessageHandlerService,
        private translate: TranslateService,
        private cellContainerService:CellContainerService) {}

    ngOnInit(): void {
        this.username = this.session.getUserName();
        this.displayName = this.session.getPersonFullName();
    }

    logout() {
        this.messageHandlerService.show(this.translate.instant("logout_message"),
            MessageType.LOGOUT_CONFIRMATION,
            (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.OK) {
                    this.cellContainerService.clearData();
                    this.authService.logOut().subscribe();
                }
            }
        );
    }

    goToWebsite(path?: string):void {
        this.website.goToWebsite(path);
    }
}

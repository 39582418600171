import { StringUtils } from "./string-utils";

export class AddressUtils {

    static formatPhysAddressV2(param1: string, param2: string, param3: string, param4: string): string {
        let address: string = "";

        if (!StringUtils.isEmpty(param1)) {
            address += param1;
            if (param2 != null) {
                address += ", ";
            }
        }
        if (!StringUtils.isEmpty(param2)) {
            address += param2;
            if (param3 != null) {
                address += ", ";
            }
        }
        if (!StringUtils.isEmpty(param3)) {
            address += param3;
            if (param4 != null) {
                address += ", ";
            }
        }
        if (!StringUtils.isEmpty(param4)) {
            address += param4;
        }

        return address;
    }

    static formatOffshorePhysAddressV2(fieldName: string, waterBody: string, areaName: string): string {
        let blockV2: string = "";

        if (fieldName != null && fieldName.length > 0) {
            blockV2 += fieldName;
            if ((areaName != null && areaName.length > 0) || (waterBody != null && waterBody.length > 0)) {
                blockV2 += ", ";
            }
        }
        if (areaName != null && areaName.length > 0) {
            blockV2 += areaName;
            if (waterBody != null && waterBody.length > 0) {
                blockV2 += ", ";
            }
        }
        if (waterBody != null) {
            blockV2 += waterBody;
        }

        return blockV2;
    }
}

<div class="main-section" id="mainSection">
    
  <app-look-up-modal        
      [entityIdPropName]="'equipmentTypeId'" 
      [searchedEntityIdPropName]="'equipmentTypeName'" 
      [entityDisplayPropName]="'equipmentTypeDesc'"
      [tKeyLabelEntityName]="'equipment.equipmentTypeSearch.name'" 
      [entityName]="'equipmentType' | translate" 
      [entityNamePlural]="'equipment.equipmentTypeSearch.title' | translate" 
      [searchOnModalOpen]="true" 

      [entitiesArray]="entitiesArray"
      
      [searchEntityDelegate]="searchEntityDelegate"
      
      [implementedComponent]="this">
  </app-look-up-modal>
  
</div>
import { Constants } from "./constants";
import { Plants } from '.';
import { SiteResult } from "./site";

export class OffshoreAddress {

    public constructor(
        public entityId: number,
        public origOffshoreCtryDesigName: string,
        public origOffshoreStateDesigName: string,
        public originOffshoreBlockV1: string,
        public originOffshoreBlockV2: string,
        public waterBodyName: string,
        public originOffshoreFieldName: string,
        public areaName: string,

        public originOffshoreWaterbodyId: number,
        public originOffshoreFieldId: number,
        public originOffshoreAreaId: number,
        public origOffshoreStateDesignation: number
    ) { }

    static BuildOffshoreAddress(entity: any): OffshoreAddress {
        return new OffshoreAddress(entity.plantID,
                            entity.offshoreCountryDesigName,
                            entity.offshoreStateDesigName,
                            entity.offshoreBlockV1,
                            entity.offshoreBlockV2,
                            entity.offshoreWaterBodyName || entity.offshoreWaterbodyName,
                            entity.offshoreFieldName,
                            entity.offshoreAreaName,
                            entity.offshoreWaterBodyId || entity.offshoreWaterbodyId ,
                            entity.offshoreFieldId,
                            entity.offshoreAreaId,
                            entity.offshoreStateDesignation
                            );
    }

    static BuildOffshoreAddressFromAsset(entity: any): OffshoreAddress {
        return new OffshoreAddress(null,
                            entity.origOffshoreCtryDesigName,
                            entity.origOffshoreStateDesigName,
                            entity.originOffshoreBlockV1,
                            entity.originOffshoreBlockV2,
                            entity.originOffshoreWaterbodyName,
                            entity.originOffshoreFieldName,
                            entity.originOffshoreAreaName,
                            entity.originOffshoreWaterbodyId,
                            entity.originOffshoreFieldId,
                            entity.originOffshoreAreaId,
                            entity.origOffshoreStateDesignation
                            );
    }

    static BuildOriginOffshoreAddress(entity: any): OffshoreAddress {
        return this.BuildOffshoreAddressFromAsset(entity.assetDisplay);
    }

    static BuildDestinationOffshoreAddress(entity: any): OffshoreAddress {
        return new OffshoreAddress(null,
                            entity.destOffshoreCtryDesignationName,
                            entity.destOffshoreStateDesignationName,
                            entity.destinationOffshoreBlockV1,
                            entity.destinationOffshoreBlockV2,
                            entity.destinationOffshoreWaterbodyName,
                            entity.destinationOffshoreFieldName,
                            entity.destinationOffshoreAreaName,
                            entity.destinationOffshoreWaterbody,
                            entity.destinationOffshoreFieldId,
                            entity.destinationOffshoreAreaId,
                            entity.destOffshoreStateDesignation
                            );
    }

    static BuildOffshoreAddressFromSite(entity: any): OffshoreAddress {
        return new OffshoreAddress(entity.assetID,
                                   entity.origOffshoreCtryDesigName,
                                   entity.origOffshoreStateDesigName,
                                   entity.originOffshoreBlockV1,
                                   entity.originOffshoreBlockV2,
                                   entity.originOffshoreWaterbodyName,
                                   entity.originOffshoreFieldName,
                                   entity.originOffshoreAreaName,
                                   entity.originOffshoreWaterbodyId,
                                   entity.originOffshoreFieldId,
                                   entity.originOffshoreAreaId,
                                   entity.origOffshoreStateDesignation);
    }

    static BuildOffshoreAddressFromSiteResult(site: SiteResult): OffshoreAddress {
        return new OffshoreAddress(site.assetId,
                            site.ctryDesigName,
                            site.stateDesigName,
                            site.offshoreBlockV1,
                            site.offshoreBlockV2,
                            site.waterBody,
                            site.fieldName,
                            site.areaName,
                            site.waterBodyId,
                            site.fieldId,
                            site.areaId,
                            site.stateDesignation
                            );
    }

    static CompareOffshoreAddress(plants: Plants[], siteOffshoreAddress: OffshoreAddress) {
        const soa = siteOffshoreAddress;
        const match = plants.find((element: Plants) =>
        OffshoreAddress.hasSitePhysicalOffshoreDiff(siteOffshoreAddress, element));
        return match ? false : true;
    }

    static hasSitePhysicalOffshoreDiff(address: OffshoreAddress, plant: Plants): boolean{
        return this.hasPhysicalOffshoreDiff(address, new OffshoreAddress(null, plant.offshoreCountryDesigName, 
            plant.offshoreStateDesigName, plant.offshoreBlockV1, plant.offshoreBlockV2, plant.offshoreWaterBodyName, 
            plant.offshoreFieldName, plant.offshoreAreaName, plant.offshoreWaterBodyId, plant.offshoreFieldId, 
            plant.offshoreAreaId, null));
    }

    static hasPhysicalOffshoreDiff(address1: OffshoreAddress, address2: OffshoreAddress): boolean{
        return address1.origOffshoreStateDesigName !== address2.origOffshoreStateDesigName
                || address1.origOffshoreCtryDesigName !== address2.origOffshoreCtryDesigName
                || address1.originOffshoreBlockV1 !== address2.originOffshoreBlockV1
                || address1.originOffshoreBlockV2 !== address2.originOffshoreBlockV2
                || address1.originOffshoreAreaId !== address2.originOffshoreAreaId
                || address1.originOffshoreFieldId !== address2.originOffshoreFieldId
                || address1.originOffshoreWaterbodyId !== address2.originOffshoreWaterbodyId
                || address1.areaName !== address2.areaName
                || address1.originOffshoreFieldName !== address2.originOffshoreFieldName
                || address1.waterBodyName !== address2.waterBodyName;
    }
}

export class CountryWaterBody{
    isSelected: boolean;
    public constructor(
        public cId: number,
        public countryId: string,
        public countryName: string,
        public countryNameConcat: string,
        public sId: number,
        public stateName: string,
        public countryStateConcat: string
    ) {}

	static BuildCountryWaterBody(countries: Array<any>): Array<CountryWaterBody> {
        return countries.map(item => CountryWaterBody.CreateInstance(item));
    }

    static CreateInstance(country: any): CountryWaterBody {
        return new CountryWaterBody(
            country.C_ID,
            country.COUNTRY_ID,
            country.COUNTRY_NAME,
            `${country.COUNTRY_ID} - ${country.COUNTRY_NAME}`,
            country.S_ID,
            country.STATE_NAME,
            `${country.COUNTRY_NAME} - ${country.STATE_NAME}`,
        );
    }
}

export class StateByCountry {
    public constructor(
        public stateName: string,
        public sId: number,
        public stateAbbrev: string,
        public stateNameConcat?: string
    ) {}

    static BuildStateByCountry(states: Array<any>): Array<StateByCountry> {
        return states.map(item => StateByCountry.CreateInstance(item));
    }

    static CreateInstance(entity: any): StateByCountry {
        return new StateByCountry(
            entity.STATE_NAME,
            entity.S_ID,
            entity.STATE_ABBREV,
            `${entity.STATE_ABBREV} - ${entity.STATE_NAME}`,
        );
    }
}

export class OffshoreField{
    public constructor(
        public fieldId: number,
        public fieldName: string,
        public waterbodyId: number
    ) {}

    static BuildSearchOffshoreField(fields: Array<any>): Array<OffshoreField> {
        return fields.map(item => OffshoreField.CreateInstance(item));
    }

    static CreateInstance(entity: any): OffshoreField {
        return new OffshoreField(
            entity.FIELD_ID,
            entity.FIELD_NAME,
            entity.WATERBODY_ID
        );
    }
}
export class OffshoreArea {
    public constructor(
        public originOffshoreAreaId: number,
        public areaName: string,
        public areaAbbrev: string,
        public fieldId: number,
        public waterbodyId: number
    ) {}

    static BuildSearchOffshoreField(areas: Array<any>): Array<OffshoreArea> {
        return areas.map(item => OffshoreArea.CreateInstance(item));
    }

    static CreateInstance(entity: any): OffshoreArea {
        return new OffshoreArea(
            entity.AREA_ID,
            entity.AREA_NAME,
            entity.AREA_ABBREV,
            entity.FIELD_ID,
            entity.WATERBODY_ID
        );
    }
}

export class SearchOffshoreArea {
    public constructor(
        public originOffshoreAreaId: number,
        public areaName: string,
        public areaAbbrev: string,
        public fieldId: number,
        public waterbodyId: number
    ) {}

    static BuildSearchoffshoreAreaList(areas: Array<any>): Array<SearchOffshoreArea> {
        return areas.map(item => SearchOffshoreArea.BuildSearchoffshoreArea(item));
    }

    static BuildSearchoffshoreArea(entity: any): SearchOffshoreArea {
        return new SearchOffshoreArea(
            entity.AREA_ID,
            entity.AREA_NAME,
            entity.AREA_ABBREV,
            entity.FIELD_ID,
            entity.WATERBODY_ID
        );
    }
}

export class OffshoreAreaById {
    constructor(
        public originOffshoreAreaId: number,
        public areaAbbrev: string,
        public areaName: string,
        public waterbodyId: number,
    ) {}

    static BuildOffshoreAreaById(areas: Array<any>): Array<OffshoreAreaById> {
        return areas.map(item => OffshoreAreaById.CreateInstance(item));
    }

    static CreateInstance(entity: any): OffshoreAreaById {
        return new OffshoreAreaById(
            entity.AREA_ID,
            entity.AREA_ABBREV,
            entity.AREA_NAME,
            entity.WATERBODY_ID
        );
    }
}

export class WaterBody{
    isSelected: boolean;
    public constructor(
        public waterBodyId: number,
        public waterBodyName: string,
        public waterBodyNameConcat: string
    ){}

	static BuildWaterBody(countries: Array<any>): Array<WaterBody> {
        return countries.map(item => WaterBody.CreateInstance(item));
    }

    

    static CreateInstance(waterBody: any):WaterBody {
        return new WaterBody(
            waterBody.WATERBODY_ID,
            waterBody.WATERBODY_NAME,
            `${waterBody.WATERBODY_ID} - ${waterBody.WATERBODY_NAME}`
        );
    }


}

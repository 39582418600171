import { Bean } from "../../bean";

export class PlantsLTSATypes extends Bean {
    plantLtsaId: number;
    serviceAgreementTypeId: number;
    mvId: number;
    mvOrder: number;
    lastDate: string;

    protected constructor(entity: any) {
        super(entity);
        this.plantLtsaId = entity?.plantLtsaId ?? null;
        this.serviceAgreementTypeId = entity?.serviceAgreementTypeId ?? null;
        this.mvId = entity?.mvId ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.lastDate = entity?.lastDate ?? null;
    }
}

export class LockItem {
    entityName: string;
    entityId: string;
    lockId: string;
    lockMode: string;
    entityEditedLockId: string;
    contactsLock: Array<LockItem>;

    constructor(entityName: string, entityId: string, lockId: string, lockMode: string, entityEditedLockId: string = null) {
        this.entityName = entityName;
        this.entityId = entityId;
        this.lockId = lockId;
        this.lockMode = lockMode;
        this.entityEditedLockId = entityEditedLockId;
        this.contactsLock = new Array<LockItem>();
    }

    addContactLockItem(contactLockItem: LockItem): void {
        const contactItem = this.getContactLockItem(contactLockItem.entityId);

        if (contactItem == null) {
            this.contactsLock.push(contactLockItem);
        } else {
            contactItem.entityEditedLockId = contactLockItem.entityEditedLockId;
            contactItem.lockId = contactLockItem.lockId;
        }
    }

    getContactLockItem(contactId: string): LockItem {
        return this.contactsLock.find(p => p.entityId === contactId);
    }

    clearContactList(): void {
        this.contactsLock.length = 0;
    }
    
}

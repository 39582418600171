<div class="header">
    <h3>{{'internalAttachment.title' | translate }}</h3>
</div>
<div class="cont-img-icon">
    <div class="bg-icon">
        <img *ngIf="classToggled" src="assets/icons/plus.svg" (click)="toggleField()" width="10px">
        <img *ngIf="!classToggled" src="assets/icons/minus.svg" (click)="toggleField()" width="10px">
    </div>
</div>
<div [class.toggled]="classToggled" class="internal-attachment-container">
    <div class="grid flex flex-nowrap">
        <div class="col-6">
            <label class="text-xs font-bold">
                {{ 'internalAttachment.mainSubtitle' | translate }}
                <br>
                {{ 'internalAttachment.secondarySubtitle' | translate }}
            </label>
        </div>
        <form name="attachmentForm" enctype='multipart/form-data'>
        <div class="col-4">
            <div>
                <input type="button" [disabled]="isDisabledButton()" value="{{ 'internalAttachment.attachFile' | translate }}" class="btn-row" onclick="document.getElementById('files').click();" [tabIndex]="baseIndex"/>
                <input id="files" [disabled]="isDisabledButton()" style="visibility:hidden;" type="file" (change)="onChange($event)">
            </div>
        </div>
        </form>
    </div>

    <div *ngFor="let item of files" class="grid attachment-total">
        <div class="col-12 flex -mb-2 px-0">
            <div class="col-5 lg:col-6 xl:col-6 text-sm underline text-blue-600 border-none cursor-pointer pr-0 min-w-max w-15rem">
                <label class="label" *ngIf="item.getOperation() === INSERT" [tabIndex]="baseIndex + 1">{{item.fileName}}</label>
                <label *ngIf="item.getOperation() !== INSERT" (click)="attachmentProcess(item)" (keydown.enter)="attachmentProcess(item)" [tabIndex]="baseIndex + 1">{{item.fileName}}</label>
            </div>
            <div class="col-2 min-w-max px-0 ml-1">
                <label class="label">({{getMegas(item.fileSize, 2)}} MB)</label>
            </div>
            <div *ngIf="item.getOperation() === INSERT && !item.confirmed" class="col lg:col-4 xl:col-4 px-0">
                <button class="border-none"><img src="assets/icons/icon_cancel.png" style="margin-right: 1rem; cursor: pointer; width: 16px; height: 16px;" (click)="delete(item)" alt="Cancel Upload" [tabIndex]="baseIndex + 1"></button>
                <label class="label -ml-2"><i>{{ 'internalAttachment.uploadPending' | translate }}</i></label>
            </div>
            <div *ngIf="item.getOperation() === INSERT && item.confirmed" class="col lg:col-4 xl:col-4 px-0">
                <button class="border-none"><img src="assets/icons/icon_ss_delete.png" style="margin-right: 1rem; cursor: pointer; width: 16px; height: 16px;" (click)="delete(item)" alt="Cancel Upload" [tabIndex]="baseIndex + 1"></button>
                <label class="-ml-2"><i>{{ 'internalAttachment.upload' | translate }}</i></label>
            </div>
            <div *ngIf="item.getOperation() === IDLE" class="col lg:col-4 xl:col-4 px-0">
                <button class="border-none"><img *ngIf="!disabledButton" src="assets/icons/icon_ss_delete.png" style="margin-right: 1rem; cursor: pointer; width: 16px; height: 16px;" (click)="delete(item)" pTooltip="Delete Attachment" [tabIndex]="baseIndex + 1"></button>
            </div>
            <div *ngIf="item.getOperation() === DELETE && !item.confirmed" class="col lg:col-4 xl:col-4 px-0">
                <button class="border-none"><img src="assets/icons/undo_icon.png" style="margin-right: 1rem; cursor: pointer; width: 16px; height: 16px;" (click)="undo(item)" pTooltip="Undo Delete" [tabIndex]="baseIndex + 1"></button>
                <label class="-ml-2"><i>{{ 'internalAttachment.deletePending' | translate }}</i></label>
            </div>
            <div *ngIf="item.getOperation() === DELETE && item.confirmed" class="col lg:col-4 xl:col-4 px-0">
                <button class="border-none"><img src="assets/icons/undo_icon.png" style="margin-right: 1rem; cursor: pointer; width: 16px; height: 16px;" (click)="undo(item)" pTooltip="Undo Delete" [tabIndex]="baseIndex + 1"></button>
                <label class="-ml-2"><i>{{ 'internalAttachment.delete' | translate }}</i></label>
            </div>
        </div>
        
    </div>

    <div class="grid">
        <div class="col-12 text-left text-xs">
            <label>Total: {{ getFilesDesc() }} ({{ getSummaryDesc() }} MB of {{ MAX_SIZE }} MB)</label>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 attachment-bottom">
            <button class="border-none" class="btn-row ml-2" type="button" [disabled]="isCancelPendingDisabled()" (click)="cancelPending()" [tabIndex]="baseIndex + 2">{{
                'internalAttachment.cancelPending' | translate }}</button>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ProductSearchParams } from 'src/app/core/interfaces/iproduct';
import { SharedService } from 'src/app/core/services/shared.service';
import { Product } from 'src/app/core/models/common';
import { Observable } from 'rxjs';
import { IdFieldType } from 'src/app/shared/components/base/look-up-modal/look-up-modal.component';

@Component({
    selector: 'app-product-modal',
    templateUrl: './product-modal.component.html',
    styleUrls: ['./product-modal.component.scss']
})

export class ProductModalComponent implements OnInit {

    entitiesArray:any[];

    entityIdPropName: string;
    checkDuplicatedRows: boolean;
    public get IdFieldType(): typeof IdFieldType {
        return IdFieldType;
    }

    constructor(
        public config: DynamicDialogConfig,
        public sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.entitiesArray = this.config.data.entitiesArray;
        this.entityIdPropName = this.config.data.entityIdPropName;
        this.checkDuplicatedRows = this.config.data.checkDuplicatedRows;
    }

    searchEntityDelegate(implementedComponent: ProductModalComponent, inputEntityId: string, inputEntityName: string):Observable<Product[]> {
        const params: ProductSearchParams = {productID: inputEntityId, productDesc: inputEntityName, unitId: ''};
        return implementedComponent.sharedService.searchHSProducts(params);
    }

    displayValueFunc(implementedComponent: any, rowData: Product): string {
        return rowData.productID + " - " + rowData.productDesc;
    }

}

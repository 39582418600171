import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// Third Party
import { Cacheable, globalCacheBusterNotifier, LocalStorageStrategy } from 'ts-cacheable';
// Rxjs
import { catchError, map } from 'rxjs/operators';
// Environment
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { VersionService } from 'src/app/core/services/version.service';
import { environment } from 'src/environments/environment';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { ApiEndpoint, Constants, RouterUrl } from '../core/models/constants';
import { EntityName } from '../core/models/enumerations/entity-name';
import { SpinnerProcess } from '../core/models/spinner-process';
import { CellContainerService } from '../core/services/cell-container.service';

export const enum AuthAction {
   LOGIN,
   LOGOUT
};

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private errorMessage: string = null;
    private readonly loginSubject$: Subject<AuthAction> = new Subject<AuthAction>();
    readonly loginChange$: Observable<AuthAction> = this.loginSubject$.asObservable();
    private loggingOut: boolean = false;
    
    constructor(private http: HttpClient,
                public router: Router,
                private translate: TranslateService,
                private titleService: Title,
                private loadWheelService: LoadWheelService,
                private session: SessionService,
                private cellContainerService:CellContainerService) {
        
        this.loginChange$.subscribe((action: AuthAction) => {
            if (action === AuthAction.LOGIN || action === AuthAction.LOGOUT) {
                // Clear application-wide caches
                globalCacheBusterNotifier.next();
                console.debug('Global cache clear requested');
            }
            
            if(action === AuthAction.LOGOUT)
            {
                this.loggingOut = false;
                this.router.navigateByUrl(RouterUrl.Login);  // Done logging out, send back to login screen
            }
        });
        
        this.session.attributeChange$.subscribe((csa) => {
            if(csa && csa.name === SessionAttribute.ServerAuthToken && csa.otherWindow)
            {
               if(!csa.newValue && this.router.url.indexOf(RouterUrl.Login) === -1)
               {
                   this.cellContainerService.closeAllDialogs();                 
                   this.router.navigateByUrl(RouterUrl.Login); // Other window logged out, so send this one back to login
               }
               else if(!csa.oldValue && this.router.url.indexOf(RouterUrl.Login) !== -1)
               {
                   this.router.navigateByUrl(RouterUrl.Dashboard); // Other window logged in, so send this one to the dashboard
               }  
            }
        });        
    }

    login(userName: string, password: string, isRememberMeChecked: boolean) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("processing_login"));
        this.errorMessage = null;
        const loginData = {
            username: userName,
            password: password,
            clientVersion: VersionService.getClientVersion(),
        };
        return this.http.post(environment.apiUrl + ApiEndpoint.Login, loginData)
            .pipe(
                map((resp: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    if (isRememberMeChecked) {
                        localStorage.setItem('username', userName);
                    } 
                    if (resp.message === null && Object.keys(resp.response).length > 0) {

                        this.session.setAttribute(SessionAttribute.ServerAuthToken, resp.response.token);
                        this.session.setAttribute(SessionAttribute.ServerAuthTokenVersion, VersionService.getClientVersion());
                        this.session.setAttribute(SessionAttribute.ServerSessionId, resp.response.sessionId);
                        this.session.setAttribute(SessionAttribute.Username, resp.response.userName);
                        this.session.setAttribute(SessionAttribute.PersonId, resp.response.personId);
                        this.session.setAttribute(SessionAttribute.PersonFullName, resp.response.personFullName);
                        this.session.setAttribute(SessionAttribute.WebUrl,resp. response.websiteURL);

                        if (this.isLoggedIn()) {

                            this.router.navigateByUrl(RouterUrl.Dashboard);
                            this.loginSubject$.next(AuthAction.LOGIN);

                        } else {
                            this.errorMessage = this.translate.instant("login_invalid_token");
                        }

                    } else {
                        this.errorMessage = resp.message;
                    }
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })
            );
    }

    logOut(): Observable<void> {
        this.cellContainerService.clearData();
        this.loggingOut = true;
        return this.http.get(environment.apiUrl + ApiEndpoint.Logout)
            .pipe(
                map((resp: any) => {
                    if (resp.response !== null) {
                        this.invalidateSession();
                    }
                }),
                catchError(() => {
                    this.invalidateSession();
                    return EMPTY;
                })
            );
    }
    
    isLoggingOut(): boolean {
        return this.loggingOut;
    }

    isSessionValid(): Observable<boolean> {
        return this.http.get(environment.apiUrl + ApiEndpoint.VerifySession)
            .pipe(
                map((resp: any) => resp.response === "valid"),
                catchError(() => of(false))
            );
    }

    isLoggedIn(): boolean {
       const token:string = this.session.getAttribute(SessionAttribute.ServerAuthToken);
       return token != null && token.length > 0;
    }
    
    private invalidateSession() {
        this.session.clearSession();
        this.titleService.setTitle(Constants.APP_TITLE);
        this.loginSubject$.next(AuthAction.LOGOUT);
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    clearError() {
        this.errorMessage = null;
    }

    @Cacheable({
       storageStrategy: LocalStorageStrategy,
       maxAge: 15 * 60 * 1000
    })
    private getAccessibleRoles(): Observable<Array<any>> {
        return this.http.get<any>(`${environment.apiUrl}registration/accessibleRoles`).pipe(
         map((data: any) => data.response),
         catchError(() => of([]))
      );
    }

    @Cacheable({
       storageStrategy: LocalStorageStrategy,
       maxAge: 2 * 60 * 60 * 1000,
       maxCacheCount: 3  // Limit how many different users we keep track of
    })
    private getAccessibleScreens(userId: number): Observable<Array<any>> {
        return this.http.get<any>(`${environment.apiUrl}registration/accessScreen/${userId}`).pipe(
         map((data: any) => data.response),
         catchError(() => of([]))
      );
    }

    public isUserInRole(role: string): Observable<boolean> {
        return this.getAccessibleRoles().pipe(map(userRoles => userRoles?.some(p => p.ROLENAME === role)));
    }

    public hasUserScreen(screenName: string): Observable<boolean> {
        if(screenName === EntityName.COMPANY || screenName === EntityName.RELEASE || screenName === EntityName.PREFERENCES)
        {
           return of(true);  // Everyone gets these
        } 
        
        return this.getAccessibleScreens(this.session.getPersonId()).pipe(
           map(userScreens => userScreens !== null && (userScreens.length === 0 || userScreens.some(s => s.SCREEN_NAME === screenName)))
        );
    }
    
}
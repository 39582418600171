<div class="header">
    <h3>{{'relatedInfo.plantLtsa.title' | translate}}</h3>
</div>

<div class="cont-img-icon">
    <img *ngIf="!classToggled" src="assets/icons/reload.png" width="15px" height="15px" class="mt-1 mr-1 cursor-pointer" (click)="reload()">
    <div class="bg-icon">
        <img *ngIf="classToggled" src="assets/icons/plus.svg" (click)="toggleField()" width="10px">
        <img *ngIf="!classToggled" src="assets/icons/minus.svg" (click)="toggleField()" width="10px">
    </div>
</div>

<div class="related-info-optional-container" [class.toggled]="classToggled">
    <div class="grid -mt-3 mb-2">
        <!-- Current Active Ltsas label -->
        <div class="col-12 xl:col-6 lg:col-6 md:col-6">
            <label class="font-bold text-xs mt-1 pl-1">{{ 'relatedInfo.plant.currentActiveLtsa' | translate }}: {{currentActiveLtsa}}</label>
        </div>
        <!-- Verified No Current Ltsa -->
        <div class="col-12 text-left xl:text-right xl:col-6 lg:col-6 md:col-6
                    xl:mt-0 lg:mt-0 md:mt-0 -mt-2 flex justify-content-start lg:justify-content-end xl:justify-content-end">
            <p-checkbox
                class="text-xs font-bold"
                [(ngModel)]="noLtsaActivityVerified"
                binary="true"
                [disabled]="isAddButtonDisabled() || verifiedPlantLtsaCheckboDisabled"
                label="{{ 'relatedInfo.plant.verifiedNoCurrentLtsa' | translate }}"
            >
            </p-checkbox>
            <div class="ml-1 text-xs font-bold flex flex-column justify-content-center" *ngIf="noLtsaActivityUser || noLtsaActivityDate">
                <label>{{noLtsaActivityUser}} {{noLtsaActivityDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
            </div>
        </div>
    </div>
</div>
<div class="-mt-3 pt-1" [class.toggled]="classToggled">
    <app-relatedinfo-base
        [implementedComponent]="this"
        i18nBase="relatedInfo.plantLtsa"
        [tableKey]="tableKey"
        [preferences]="preferences"
        [caller]="caller"
        [rows]="rows"
        [showHeader]="false"
        [callerEntityDisplay]="callerEntityDisplay"
        [callerEntityIdPropName]="callerEntityIdPropName"
        [addRecordDestinationPage]="addRecordDestinationPage"
        (reloadFunction)="reload()"
        [baseIndex]="baseIndex"
        [columnsInfo]="getColumnsInfo()">
    </app-relatedinfo-base>
</div>

<div class="border-round-left-md
            border-round-right-md
            bg-black shadow-6
            pt-1 pl-2 pb-1 pr-2 container-top-nav
            xl:-mt-1 lg:-mt-1 -mt-2">
    <nav class="flex justify-content-between top-nav">
        <ul class="flex list-none mb-0 pl-0">
            <li class="inline-block relative no-underline mr-1 hover:bg-blue-900" >
                <img width="25px" class="mr-3" src="assets/icons/computer.png" (click)="goDashboard()">
                <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min pt-1 bg-menu">
                    <div class="pt-2 border-bottom-1 pl-2 border-bottom-solid border-blue-900 text-blue-900 text-base font-bold pt-2 cursor-default">{{'home'| translate}}</div>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goDashboard()">{{'ed_dashboard'| translate}}</li>
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goToWebsite()">{{'industrial_info_home'| translate}}</li>
                </ul>
            </li>

            <li class="inline-block relative no-underline mr-1 hover:bg-blue-900">
                <img width="25px" class="mr-3" [src]="getNotifyImageSrc()" (click)="showNotifications()">
                <ul class="flex flex-column list-none mb-0 w-14rem absolute min-w-min pt-1 bg-menu">
                    <div class="pt-2 border-bottom-1 pl-2 border-bottom-solid border-blue-900 text-blue-900 text-base font-bold pt-2 cursor-default">{{'common.notifications'| translate}}</div>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="showNotifications()">{{'common.showNotification'| translate}} ({{clientNotificationCount + serverNotificationCount}})</li>
                </ul>
            </li>

            <li class="inline-block relative no-underline mr-1 hover:bg-blue-900">
                <img width="25px" class="mr-3" src="assets/icons/loupe.png">
                <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min pt-1 bg-menu">
                    <div class="pt-2 border-bottom-1 pl-2 border-bottom-solid border-blue-900 text-blue-900 text-base font-bold pt-2 cursor-default">{{'common.entityScreens'| translate}}</div>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goCompany()" *ngIf="this.hasCompanyScreen">{{'common.company'| translate}}</li>

                    <li class="text-base text-black pl-2 cursor-default flex justify-content-between" *ngIf="this.hasSiteScreen || this.hasPipelineScreen|| this.hasTLineScreen">
                        <div>{{'common.asset'| translate}}</div>
                        <div class="flex-column justify-content-center">
                            <span class='pi pi-play text-end align-self-end text-blue-600' ></span>
                        </div>
                        <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min -mt-4 dropdown-right">
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goSite()" *ngIf="this.hasSiteScreen">{{'common.site'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goPipeline()" *ngIf="this.hasPipelineScreen">{{'common.pipeline'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goTLine()" *ngIf="this.hasTLineScreen">{{'common.tline'|translate}}</li>
                        </ul>
                    </li>

                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goPlant()" *ngIf="this.hasPlantScreen">{{'common.plant'|translate}}</li>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goArea()" *ngIf="this.hasAreaScreen">{{'common.area'|translate}}</li>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goUnit()" *ngIf="this.hasUnitScreen">{{'common.unit'|translate}}</li>
                    
                    <li class="text-base text-black pl-2 cursor-default flex justify-content-between" *ngIf="this.hasBoilerScreen || this.hasDriveScreen|| this.hasTankScreen || this.hasMEquipmentScreen|| this.hasOEquipmentScreen">
                        <div>{{'common.equipment'| translate}}</div>
                        <div class="flex-column justify-content-center">
                            <span class='pi pi-play text-end align-self-end text-blue-600' ></span>
                        </div>
                        <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min -mt-4 dropdown-right">
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goBoiler()" *ngIf="this.hasBoilerScreen">{{'common.boiler'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goDrive()" *ngIf="this.hasDriveScreen">{{'common.drive'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goTank()" *ngIf="this.hasTankScreen">{{'common.tank'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goMEquipment()" *ngIf="this.hasMEquipmentScreen">{{'common.mEquipment'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goOtherEquipment()" *ngIf="this.hasOEquipmentScreen">{{'common.oEquipment'|translate}}</li>
                        </ul>
                    </li>

                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goOfflineEvent()" *ngIf="this.hasOfflineEventScreen">{{'common.offlineEvent'|translate}}</li>

                    <li class="text-base text-black pl-2 cursor-default flex justify-content-between" *ngIf="this.hasConfirmedProjectScreen || this.hasUnconfirmedProjectScreen">
                        <div>{{'common.project'| translate}}</div>
                        <div class="flex-column justify-content-center">
                            <span class='pi pi-play text-end align-self-end text-blue-600' ></span>
                        </div>
                        <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min -mt-4 dropdown-right">
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goConfirmedProject()" *ngIf="this.hasConfirmedProjectScreen">{{'common.confirmedProject'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goUnconfirmedProject()" *ngIf="this.hasUnconfirmedProjectScreen">{{'common.unconfirmedProject'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goUmbrellaProject()">{{'common.umbrellaProject'|translate}}</li>
                        </ul>
                    </li>

                    <!-- <li class="text-base text-black pl-2 cursor-pointer" (click)="goOfflineEvent()" *ngIf="this.hasOfflineEventScreen">{{'common.ltsa'|translate}}</li> -->
                    <li class="text-base text-black pl-2 cursor-default flex justify-content-between" *ngIf="this.hasPlantLTSAScreen || this.hasUnitLTSAScreen|| this.hasPipelineLTSAScreen">
                        <div>{{'common.ltsa'| translate}}</div>
                        <div class="flex-column justify-content-center">
                            <span class='pi pi-play text-end align-self-end text-blue-600' ></span>
                        </div>
                        <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min -mt-4 dropdown-right">
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goPlantLtsa()" *ngIf="this.hasPlantLTSAScreen">{{'common.plantLtsa'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goUnitLtsa()" *ngIf="this.hasUnitLTSAScreen">{{'common.unitLtsa'|translate}}</li>
                            <li class="text-base text-black pl-2 cursor-pointer" (click)="goPipelineLtsa()" *ngIf="this.hasPipelineLTSAScreen">{{'common.pipelineLtsa'|translate}}</li>
                        </ul>
                    </li>

                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goRegionAlerting()" *ngIf="this.hasRegionAlertingScreen">{{'common.regionAlerting'|translate}}</li>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goRelease()" *ngIf="this.hasReleaseScreen">{{'common.release'|translate}}</li>

                </ul>
            </li>

            <li class="inline-block relative no-underline mr-1 hover:bg-blue-900" >
                <img width="25px" class="mr-3" src="assets/icons/tools.svg">
                <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min pt-1 bg-menu">
                    <div class="pt-2 border-bottom-1 pl-2 border-bottom-solid border-blue-900 text-blue-900 text-base font-bold pt-2 cursor-default">{{'common.utilities'| translate}}</div>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goToWebsite('tools/callerid.jsp')">{{'set_caller_id'| translate}}</li>
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="!this.showUpdateLink && updateCheck()" [class.disabled]="this.showUpdateLink">{{'update_check'| translate}}</li>
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goPreferences()" >{{'common.preferences'|translate}}</li>
                </ul>
            </li>
            <li class="inline-block relative no-underline mr-1 hover:bg-blue-900" >
                <img width="25px" class="mr-3" src="assets/icons/qa.png">
                <ul class="flex flex-column list-none mb-0 w-10rem absolute min-w-min pt-1 bg-menu">
                    <div class="pt-2 border-bottom-1 pl-2 border-bottom-solid border-blue-900 text-blue-900 text-base font-bold pt-2 cursor-default">{{'common.supportServices'| translate}}</div>
                    
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="openGeographyRequest()">{{'geographyRequest.link'| translate}}</li>
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goToWebsite('includes/getIIRSupportQuickRef.jsp')">{{'contact_us'| translate}}</li>
                    <li class="text-base text-black pl-2 cursor-pointer" (click)="goToWebsite('manuals/edguide.jsp')">{{'ed_guide'| translate}}</li>
                </ul>
            </li>

        </ul>
        <div class="upgrade-link" *ngIf="this.showUpdateLink">
            <span (click)="doUpdate()">Newer version available, update now!</span>
        </div>
        <div id="headerDate" class="text-sm">
            <span>{{ currentDate | ordinalDate }}</span>
        </div>
    </nav>
</div>

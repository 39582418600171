<div class="header">
    <h3>{{'relatedInfo.project.title' | translate}}</h3>
</div>

<div class="cont-img-icon">
    <img *ngIf="!classToggled" src="assets/icons/reload.png" width="15px" height="15px" class="mt-1 mr-1 cursor-pointer" (click)="reload()">
    <div class="bg-icon">
        <img *ngIf="classToggled" src="assets/icons/plus.svg" (click)="toggleField()" width="10px">
        <img *ngIf="!classToggled" src="assets/icons/minus.svg" (click)="toggleField()" width="10px">
    </div>
</div>

<div *ngIf="callerIsPlant" class="related-info-optional-container" [class.toggled]="classToggled">
    <div class="grid -mt-3">
        <!-- Current Active Projects label -->
        <div class="col-12 xl:col-6 lg:col-6 md:col-6">
            <label class="font-bold text-xs mt-1 pl-1">{{ 'relatedInfo.project.currentActiveProjects' | translate }}: {{currentActiveProjects}}</label>
        </div>
        <!-- Verified No Current Project Activity -->
        <div class="col-12 text-left xl:text-right xl:col-6 lg:col-6 md:col-6
                    xl:mt-0 lg:mt-0 md:mt-0 -mt-2">
            <p-checkbox
                class="text-xs font-bold"
                [(ngModel)]="noProjActivityVerified"
                binary="true"
                [disabled]="isAddButtonDisabled() || verifiedProjActivityCheckboDisabled"
                label="{{ 'relatedInfo.project.verifiedNoCurrentProjectActivity' | translate }}"
            >
            </p-checkbox>
            <img
                src="assets/icons/icon_info.png"
                width="15px"
                height="15px"
                alt="info"
                data-toggle="tooltip"
                data-placement="right"
                pTooltip="{{'relatedInfo.project.verifiedNoCurrentProjectTooltip'| translate}}"
                class="ml-1">
            <div id="lastPrevDataPanel" class="ml-1 text-xs">
                <label id="verifiedProjectActivity" class="verified-project" (click)="verifiedProjectActivity()">{{lastPrevLabel}}</label>
                <label>&nbsp;{{verifiedUser}}</label>
                <label>&nbsp;{{verifiedDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
            </div>
        </div>
    </div>
</div>
<div class="-mt-3 pt-1" [class.toggled]="classToggled">
    <app-relatedinfo-base
        [implementedComponent]="this"
        i18nBase="relatedInfo.project"
        [tableKey]="tableKey"
        [preferences]="preferences"
        [caller]="caller"
        [rows]="rows"
        [showHeader]="false"
        [callerEntityDisplay]="callerEntityDisplay"
        [callerEntityIdPropName]="callerEntityIdPropName"
        [addRecordDestinationPage]="addRecordDestinationPage"
        [addRecordFunction]="newRecord"
        (reloadFunction)="reload()"
        [columnsInfo]="getColumnsInfo()"
        [baseIndex]="baseIndex">
    </app-relatedinfo-base>
</div>
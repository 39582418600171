import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LockDetails } from 'src/app/core/record-locking/lock-details';
import { RecordLockingService } from 'src/app/core/record-locking/record-locking.service';

@Component({
    selector: 'app-locked-entity-modal',
    templateUrl: './locked-entity-modal.component.html',
    styleUrls: ['./locked-entity-modal.component.scss']
})
export class LockedEntityModalComponent implements OnInit {
    @ViewChild('openAnywayButton') openAnywayButton: ElementRef;
    entityType: string;
    entityId: string;
    lockedItem: LockDetails;

    constructor(private activeModal: DynamicDialogRef,
        private recordLockingService: RecordLockingService,
        private config: DynamicDialogConfig) { }

    ngOnInit(): void {
        this.entityType = this.config.data?.entityType;
        this.entityId = this.config.data?.entityId;
        this.lockedItem = this.config.data?.lockedItem ?? null;
    }

    openAnyway() {
        this.recordLockingService.clearLock([this.lockedItem.lockID]).subscribe(() => {
            this.activeModal.close(LockedEntityModalResult.OPEN_ANYWAY);
        });
    }

    openReadMode() {
        this.activeModal.close(LockedEntityModalResult.OPEN_READ_MODE);
    }
    
    cancel() {
        this.activeModal.close(LockedEntityModalResult.CANCEL);
    }

}

export enum LockedEntityModalResult {
    OPEN_ANYWAY, OPEN_READ_MODE, CANCEL
}

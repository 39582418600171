import { ChangeDetectionStrategy, Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { HsMeasureValue, TankStatusValue } from 'src/app/core/models/constants';
import { Tank } from 'src/app/core/models/common';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { RelatedInfoBaseComponent } from '../base/relatedinfo-base.component';

@Component({
    selector: 'app-tank',
    templateUrl: './tank.component.html',
    styleUrls: ['./tank.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoTankComponent extends RelatedInfoBase implements OnInit, OnChanges {
    entityTableKey =EntityName.TANK;
    @ViewChild('tankBaseComponent', { static: false }) tankBaseComponent: RelatedInfoBaseComponent;
    @Input() showCustomHeader: boolean = false;
    opTanksCount: number = 0;
    totalTanks: number = 0;
    totalBBL: number = 0;
    totalMMSCF: number = 0;
    totalCubicMeters: number = 0;

    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if(this.callerIsUnit){
            columnsInfo.push({ entityPropName: 'TankId', columnHeader: 'relatedInfo.tank.tankId', routerEntityName: EntityName.TANK, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankName', columnHeader: 'relatedInfo.tank.tankName', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankType', columnHeader: 'relatedInfo.tank.tankType', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'Capacity', columnHeader: 'relatedInfo.tank.capacity', formatThousands: true, widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'UnitHS', columnHeader: 'relatedInfo.tank.uom', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankInfo', columnHeader: 'relatedInfo.tank.tankInfo', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.tank.startupDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankStatus', columnHeader: 'relatedInfo.tank.status', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push(this.createDuplicateColumn('tankId', EntityName.TANK, 5));
        } else {
            columnsInfo.push({ entityPropName: 'TankId', columnHeader: 'relatedInfo.tank.tankId', routerEntityName: EntityName.TANK, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankName', columnHeader: 'relatedInfo.tank.tankName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'TankType', columnHeader: 'relatedInfo.tank.tankType', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'Capacity', columnHeader: 'relatedInfo.tank.capacity', widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.tank.startupDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'TankStatus', columnHeader: 'relatedInfo.tank.status', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
        }
        return columnsInfo;
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.rows){
            if(this.rows){
                this.calculateCountValues();
            }
        }
    }
    calculateCountValues(){
        this.opTanksCount = 0;
        this.totalTanks = this.rows.length;
        this.totalBBL = 0;
        this.totalMMSCF = 0;
        this.totalCubicMeters = 0;
        this.rows.forEach((row: Tank) =>{
            if (row.unitHS != undefined) {
                switch (row.unitHS.toUpperCase()){
                    case HsMeasureValue.BBL:
                        this.totalBBL += row.capacity;
                        break;
                    case HsMeasureValue.MMSCF:
                        this.totalMMSCF += row.capacity;
                        break;
                    case HsMeasureValue.CubicMeters:
                        this.totalCubicMeters += row.capacity;
                        break;
                }
            }
            if (row.tankStatusId === TankStatusValue.Operational) {
                this.opTanksCount++;
            }

        });

    }

}

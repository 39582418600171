/**
 * This is the BASE environment.
 * It should define ALL settings, and their respective standard/default values that will form the basis of all other environments.
 * They can be overridden as necessary by the build-type-specific-specific environments  
 */
 
export const baseEnvironment = {
   environmentName: 'base',
   production: false,
   apiUrl: `/api/`,
   pdfUrl: `/IIRLiveCycleProxy/`,
   wikiUrl: "wikilogin.jsp",
   entityType: 'Company',
   googleMapBase: 'mapIt/',
   googleMapEd: 'google_map_ed.jsp',
   pipelineBaseMap: 'pipeline/',
   tlineBaseMap: 'tline/',
   googleMapEdps: 'google_map_edps.jsp',
   googleMapEdts: 'google_map_edts.jsp',
   flowUrl: 'dash/graphs/highchart_results.jsp',
   urlReport: {
      company: 'dash/company_report.jsp',
      pipeline: 'dash/pipeline_report.jsp',
      tline: 'dash/tline_report.jsp',
      plant: 'dash/plant_report.jsp',
      project: 'dash/project_report.jsp',
      area: 'dash/area_report.jsp',
      unit: 'dash/unit_report.jsp',
      drive: 'dash/drive_report.jsp',
      mEquipment: 'dash/mequipment_report.jsp',
      oEquipment: 'dash/equipment_report.jsp',
      boiler: 'dash/boiler_report.jsp',
      offline: 'dash/offlineevent_report.jsp'
   },
   recentlyOpenedKey: 'recently-opened-key-',
   notificationsKey: 'notifications-key-',
   renewLeaseMinutes: 9,
   versionCheckIntervalMinutes: 23,
   serverNotificationCheckIntervalMinutes: 233,
   consoleTelemetry: false,
   sendTelemetry: false,
};

import { ContactsDisplay } from "./contact/display/contacts-display";
import { ContactEventType, EventType } from "./enumerations/event-type";

export class EntityEvent {
    type: EventType;
    data: any;

    constructor(type: EventType, data: any) {
        this.type = type;
        this.data = data;
    }
}

export class ContactEvent {
    type: ContactEventType
    data: ContactsDisplay;

    constructor(type: ContactEventType, data: ContactsDisplay) {
        this.type = type;
        this.data = data;
    }
}

export class MainSearchEvent {
    type: string;

    constructor(type: string) {
        this.type = type;
    }
}

export class MvtSearchEvent<T> {
    event: any;
    col: any;
    row: T;
    openModal: boolean;
    rowIndex: number;

    constructor(event: any, col: any, row: T, openModal: boolean, rowIndex: number) {
        this.event = event;
        this.col = col;
        this.row = row;
        this.openModal = openModal;
        this.rowIndex = rowIndex;
    }
}

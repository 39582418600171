import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MessageEvent, MessageResponse, MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import {AssetsProjectsDisplay} from "../models/asset/base/display/assets-projects-display";
import { AssetsPipesLateralsDisplay } from "../models/asset/pipeline/display/assets-pipes-laterals-display";
import {OperationType} from "../models/enumerations/operation-type";
import { MVTOperations } from "../mvt-operations";
import { StringUtils } from "../utils/string-utils";

@Injectable({
    providedIn: 'root'
})
export class ValidationCommonService {
    public static maxLongValue = BigInt('9223372036854775807');

    constructor(
        private translate: TranslateService,
        private messageHandler: MessageHandlerService) {
    }

    validateAssociatedLaterals(connectionId: number, laterals: Array<AssetsPipesLateralsDisplay>, doYes: any, doNo: any) {
        // If a user performs a right-click delete operation on any of the Pipeline Connection records (from the ‘Pipeline Connections’ data grid), 
        // the user must be warned of any potential laterals that were already chosen for this connection.
        // Note that this confirmation message should appear ONLY if there are any laterals associated to this connection.
        const associatedConnections = this.connectionHasAssociatedPipes(connectionId, laterals);

        if (associatedConnections.length > 0) {
            let msgPipesAssociated = '';
            associatedConnections.forEach(i => {
                msgPipesAssociated += i.lateralId + ' - ' + i.lateralName + '<br>';
            });

            let lateralMsg = this.translate.instant('pipeline.laterals.associatedViaPlant');

            lateralMsg += ' ' + connectionId + '.<br>';
            lateralMsg += msgPipesAssociated + '<br>';
            lateralMsg += this.translate.instant('pipeline.laterals.removeAssociated')

            const confirmCallback = (resp: MessageResponse): void => {
                if (resp.event == MessageEvent.YES) {
                    doYes();
                    this.deleteAssociatedLaterals(associatedConnections, laterals);
                } else if (resp.event == MessageEvent.NO) {
                    doNo();
                }
            };

            this.messageHandler.show(lateralMsg, MessageType.CONFIRM, confirmCallback);
        } else {
            doYes();
        }
    }

    private deleteAssociatedLaterals(associatedConnections: Array<any>, laterals: Array<AssetsPipesLateralsDisplay>): void {
        associatedConnections.forEach(i => {
            MVTOperations.removeItem(laterals,
                "connectionId", i.connectionId.toString(),
                "lateralId", i.lateralId.toString());

        });
    }

    private connectionHasAssociatedPipes(connectionId: number, laterals: Array<AssetsPipesLateralsDisplay>): Array<AssetsPipesLateralsDisplay> {
        return laterals.filter(i => String(i.connectionId) === String(connectionId) && i.getOperation() !== OperationType.DELETE);
    }

    public validateDates(startup: number, shutdown: number, 
        compKick: number, isKickOff: boolean, control: AbstractControl): string {
        let message: string = '';
        if (startup !== 0 && compKick !== 0 &&  startup < compKick) {
            message = (isKickOff ?
                this.translate.instant('dateValidations.startKick') :
                this.translate.instant('dateValidations.startComp'))+'<br>';
        } else if (shutdown !== 0 && compKick !== 0 &&  shutdown <= compKick) {
            message = (isKickOff ?
                this.translate.instant('dateValidations.shutKick'):
                this.translate.instant('dateValidations.shutComp'))+'<br>';
        } else if (shutdown !== 0 && startup !== 0 &&  shutdown <= startup) {
            message = this.translate.instant('dateValidations.shutStart')+'<br>';
        }
        if(message !== '' && control !== null){
            control.setValue('');
        }
        return message;
    }

    public validateAssetsProjects(assetsProjectsDisplay: Array<AssetsProjectsDisplay>): string {
        let message: string = '';
        let filteredProjects: Array<AssetsProjectsDisplay> = MVTOperations.filterByDeleted(assetsProjectsDisplay);
        for (let i = 0; i < filteredProjects.length; i++) {
            let obj: AssetsProjectsDisplay = filteredProjects[i];
            if (obj.getOperation() == OperationType.TEMPORAL) {
                continue;
            }

            if (obj.projectId == null || String(obj.projectId) == "") {
                message += this.translate.instant('asset.projects.projectIdRequired')+'<br>';
                break;
            }
        }
        return message;
    }

    public getOfflineEventDuration(startDate: Date, endDate: Date): number {
        if(startDate && startDate !== null && endDate && endDate !== null){
           return  Math.floor((endDate.getTime()-startDate.getTime())/1000/60/60/24) + 1
        }
        return 0;
    }

    public validateYearMonth(date: string, field: string): string{
        return date.length !== 6 ?
                    this.translate.instant('common.dateYearMonthFormat', {fieldName: field}) :
                        (Number(date.substring(4,6)) <= 12 && date.substring(4,6) !== '00' && date !== '000000' ?  '' :
                            this.translate.instant('common.dateMonthFormat', {fieldName: field}));
    }

    public validateMaxLongValue(value: string | number | bigint, event: any, fieldI18nKey: string):string{
        let msg = ''
        if(!StringUtils.isEmpty(value) && Number(value) > ValidationCommonService.maxLongValue){
            if(fieldI18nKey){
                msg = this.translate.instant(fieldI18nKey)+ ' : ';
            }
            msg += this.translate.instant('common.maxLongValue') + '<br>';
        }
        if(event && msg !== ''){
            const selectOpt = () => {
                event.srcElement.focus();
            } 
            this.messageHandler.show(msg, MessageType.INFO, selectOpt);
        }
        return msg
    }

}

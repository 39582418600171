<div class="_contactDetailsContainer">
    <div class="_contactDetails">
        <strong>{{'first'| translate}}: </strong>{{userDetail.firstName}}<br>
        <strong>{{'last'| translate}}: </strong>{{userDetail.lastName}}<br>
        <strong>{{'job_title'| translate}}: </strong>{{userDetail.title}}<br>
        <strong>{{'email'| translate}}: </strong>
        <span *ngIf="isEnableForMailingTo()"><a href="mailto:{{userDetail.emailAddress}}?subject={{entityName}} ID: {{entityId}}" target="hidden-iframe-for-mailto">{{userDetail.emailAddress}}</a></span>
        <span *ngIf="!isEnableForMailingTo()">{{userDetail.emailAddress}}</span><br>          
        <strong>{{'phone_number'| translate}}: </strong>{{userDetail.phoneNumber}}<br>
        <strong>{{'state'| translate}}: </strong>{{userDetail.stateName}}<br>
        <strong>{{'country'| translate}}: </strong>{{userDetail.countryName}}<br>
        <strong>{{'instant_messenger'| translate}}: </strong>{{userDetail.imTool}}<br>
        <strong>{{'instant_messenger_id'| translate}}: </strong>{{userDetail.imUsername}}<br>
    </div>
</div>
<iframe name="hidden-iframe-for-mailto" style="visibility:hidden;position:absolute;"></iframe>
<div class="content-modal">
    <div class="container-modal">
        <div class="grid mt-2">
            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="grid">
                    <div class="col-2 w-3">
                        <label class="text-xs mt-1 flex justify-content-end">
                            {{'contact.common.contactId'| translate}}:
                        </label>
                    </div>
                    <div class="col-10 px-0 w-5">
                        <input type="text" pInputText class="p-inputtext-sm border border-secondary" [(ngModel)]="contactId" readonly tabindex="-1">
                    </div>
                </div>
                <div class="grid">
                    <div class="col-2 w-3 md:min-w-max">
                        <label class="text-xs mt-1 flex justify-content-end">{{'contact.contactRelated.contactName'| translate}}:</label>
                    </div>
                    <div class="col-10 px-0 w-5">
                        <input type="text" pInputText class="p-inputtext-sm border border-secondary" [(ngModel)]="contactName" readonly tabindex="-1">
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="grid flex md:justify-content-end lg:justify-content-end xl:justify-content-end justify-content-start mr-1">
                    <label class="text-xs mt-1 ml-1 pl-1">{{'contact.contactRelated.infoReturn'| translate}} {{contactId}}
                        {{'contact.contactRelated.infoClose'| translate}}</label>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-10">
                <label *ngIf="!isContactAssociations"
                    class="text-xs mt-1">{{'contact.contactRelated.contactMailingAddresses'|
                    translate}}</label>
                <label *ngIf="isContactAssociations && !statusChange"
                    class="text-xs mt-1">{{'contact.contactRelated.contactFoundEntities'| translate}}</label>
                <label *ngIf="isContactAssociations && statusChange"
                    class="text-xs mt-1 red-label">{{'contact.contactRelated.contactAssociationsInfo'| translate}}</label>
            </div>
            <div class="col-2 text-end">
                <button class="btn-custom" appFocusElement type="button" (click)="reload()">{{'contact.contactRelated.reload'| translate}}</button>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <div class="container-table-modal">
                    <div class="company-table">

                        <p-table ignoreHeaders [value]="contactProvider" styleClass="p-datatable-striped p-datatable-gridlines" [columns]="cols"
                            [customSort]="true" [rows]="rowsPerPage" (onPage)="onPageChange($event)" [showCurrentPageReport]="true"
                            [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" (sortFunction)="customSort($event)"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" scope="col" pReorderableColumn pResizableColumn
                                        pSortableColumn="{{col.field}}">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                                <tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                                    (dblclick)="dgContact_doubleClick(rowData)" pTooltip="{{rowData['tooltip']}}">
                                    <td *ngFor="let col of columns" class="{{col.field}}" 
                                          [style.white-space]="col.wSpace || 'normal'">
                                        <div *ngIf="col.isLink && rowData.available" 
                                            [ngClass]="rowData.disabled ? 'disabledRow' : 'enabledRow'">
                                            <a class="linkEntityId cursor-pointer" [tabIndex]="0"
                                                (click)="entityContactLink(rowData)"
                                                (keydown.enter)="entityContactLink(rowData)">
                                                    {{rowData[col.field]}}
                                            </a>
                                        </div>
                                        <div *ngIf="col.isLink && !rowData.available"
                                        [ngClass]="rowData.disabled ? 'disabledRow' : 'enabledRow'"
                                                pTooltip="{{'common.entityNotAvailable' | translate }}">
                                            {{rowData[col.field]}}
                                        </div>
                                        <div *ngIf="col.isDate"
                                                [ngClass]="rowData.disabled ? 'disabledRow' : 'enabledRow'">
                                            {{rowData[col.field] | date: 'dd-MMM-y'}}
                                        </div>
                                        <div *ngIf="!col.isDate && !col.isLink"
                                                [ngClass]="rowData.disabled ? 'disabledRow' : 'enabledRow'">
                                            {{rowData[col.field]}}</div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length">
                                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 text-start">
                <label class="text-xs mt-1">{{'contact.contactRelated.record'|translate}}:</label>
                <label class="text-xs mt-1 ml-1r">{{contactProvider.length}}</label>
                <label class="text-xs mt-1 ml-8">{{
                        (this.type === ContactRelatedEntities.CONTACT_MAILING_ADDRESS ?
                        'contact.contactRelated.dbClickMailingAddress' : 
                        'contact.contactRelated.clickOnEntityId')|translate}}</label>
            </div>
        </div>

        <div *ngIf="isContactAssociations && contactProvider.length > 0">
            <div class="col-12 text-center">
                <button class="btn-custom" label="Create .csv File"  type="button"
                    (click)="downloadCsv()" #createCsvBtn>{{'contact.contactRelated.createCsvFile'| translate}}</button>
            </div>
        </div>
    </div>
</div>
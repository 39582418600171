import { Constants, ProjectStatusValue } from "./constants";
import { EntityName } from "./enumerations/entity-name";

export class ProbAssociation {
    private constructor(public entity: string,
        public entityID: string,
        public entityStatus: string,
        public entityStatusID: string,
        public entityName: string,
        public probability: string,
        public qcDate: number) {
    }


    static BuildFromProjectResult(entity: Array<any>): Array<ProbAssociation> {
        return entity.map(item => ProbAssociation.CreateInstanceFromProjectResult(item));
    }

    private static CreateInstanceFromProjectResult(item: any): ProbAssociation {
        return new ProbAssociation( item.STATUS_ID != ProjectStatusValue.Unconfirmed && 
                                                item.STATUS_ID != ProjectStatusValue.UnableToConfirm ? 
                                        EntityName.PROJECT_CONFIRMED : EntityName.PROJECT_UNCONFIRMED,
                                    item.PROJECT_ID, 
                                    item.PROJECT_STATUS_DESC, 
                                    '', 
                                    item.PROJECT_NAME, 
                                    item.PROJECT_PROBABILITY_DESC, 
                                    item.QC_DATE );
    }


    static BuildFromPlantResult(entity: Array<any>): Array<ProbAssociation> {
        return entity.map(item => ProbAssociation.CreateInstanceFromPlantResult(item));
    }

    private static CreateInstanceFromPlantResult(item: any): ProbAssociation {
        return new ProbAssociation(EntityName.PLANT,
                                    item.PLANT_ID, 
                                    item.PLANT_STATUS_DESC, 
                                    item.PLANT_STATUS_ID,
                                    item.PLANT_NAME, 
                                    item.PLANT_PROBABILITY_DESC, 
                                    item.QC_DATE );
    }
}
import { PlantsLTSATrades } from "../save/plants-ltsa-trades";

export class PlantsLTSATradesDisplay extends PlantsLTSATrades {
    serviceAgreementTradeName: string;
    
    constructor(entity: any) {
        super(entity);
        this.serviceAgreementTradeName = entity?.serviceAgreementTradeName ?? null;
    }

    static CreateInstance(entity: any): PlantsLTSATradesDisplay {
        return new PlantsLTSATradesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsLTSATradesDisplay> {
        return entity.map(item => PlantsLTSATradesDisplay.CreateInstance(item));
    }
}

import { NgModule } from '@angular/core';
// Moduls
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollTopModule } from 'primeng/scrolltop';
import { CarouselModule } from 'primeng/carousel';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
    imports: [
        AccordionModule,
        ButtonModule,
        RippleModule,
        ToastModule,
        InputTextModule,
        DropdownModule,
        TableModule,
        ListboxModule,
        CheckboxModule,
        RadioButtonModule,
        MessagesModule,
        TooltipModule,
        TabViewModule,
        DialogModule,
        ConfirmDialogModule,
        DialogModule,
        MultiSelectModule,
        ScrollTopModule,
        CarouselModule,
        PaginatorModule
    ],
    exports: [
        ButtonModule,
        RippleModule,
        ToastModule,
        InputTextModule,
        DropdownModule,
        TableModule,
        ListboxModule,
        CheckboxModule,
        RadioButtonModule,
        MessagesModule,
        TooltipModule,
        TabViewModule,
        DialogModule,
        CalendarModule,
        ConfirmDialogModule,
        DialogModule,
        MultiSelectModule,
        ScrollTopModule,
        CarouselModule,
        PaginatorModule
    ],
    providers: []
})
export class PrimeNgModule { }

export enum EventType {
    RELOAD_ENTITY = 0,
    SAVE_ENTITY = 1,
    NEW_ENTITY = 2,
    PRE_RELEASE = 3,
    CLEAR_DATA = 4,
    ENTITY_NOT_FOUND = 5,
    OPEN_ENTITY_FROM_PARENT = 6,
    SAVED_ENTITY_OK = 7
}

export enum ContactEventType {
    NONE = 0,
    LOAD_FROM_FLOW = 1,
    LOAD_FROM_FLOW_NEW = 2,
    LOAD_FROM_FLOW_EDIT = 3,
    LOAD_FROM_VIEW_COMPLETE = 4,
    LOAD_RECORD_WITH_LOCKID = 5,
    LOAD_RECORD_WITH_LOCKID_READONLY = 6
}

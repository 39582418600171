import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
// Modal
import { ContactDetailsModalComponent, ContactModalData, ContactRelatedEntities } from 'src/app/contact-details-section/contact-details-modal/contact-details-modal.component';
import { ContactRelatedComponent } from 'src/app/contact-details-section/contact-details-modal/contact-related/contact-related.component';
import { IContact, IContactMap } from 'src/app/core/interfaces/icontacts';
import { Constants, ContactStatusValue, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { ContactsDisplay } from 'src/app/core/models/contact/display/contacts-display';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { ContactEvent } from 'src/app/core/models/event';
// Model
import { MVTOperations } from 'src/app/core/mvt-operations';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
// Components
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ContactModalComponent } from 'src/app/components/modals/contact-modal/contact-modal.component';
import { RelatedEntitiesComponent } from 'src/app/components/modals/related-entities/related-entities.component';
import { ContactDetailsSectionService } from 'src/app/contact-details-section/contact-details-section.service';
import { Address, Contact } from 'src/app/core/models';
import { AssetsContactInfoDisplay } from 'src/app/core/models/asset/base/display/assets-contact-info-display';
import { FunctionalTitle, Preference } from 'src/app/core/models/common';
import { ContactOperation, ContactsWithException } from 'src/app/core/models/contact';
import { ContactEventType } from 'src/app/core/models/enumerations/event-type';
import { ContactService } from 'src/app/core/services/contact.service';
import { ServerDateService } from 'src/app/core/services/serverdate.service';
import { SessionService } from 'src/app/core/services/session.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { HeaderService } from 'src/app/shared/header/header.service';
import { PoolTableComponent } from './pool-table/pool-table.component';
import { SlottedTableComponent } from './slotted-table/slotted-table.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit, OnChanges {
    @ViewChild('poolTableComponent') poolTableComponent: PoolTableComponent;
    @ViewChild('slottedTableComponent') slottedTableComponent: SlottedTableComponent;

    acContactsSlotted: Array<IContact> = [];
    acContactsPool: Array<IContact> = [];

    cols: any = [] = [];
    slottedCols: any = [] = [];

    @Input() useSlottedBehavior?: boolean = false;

    @Input() entityId: number;
    @Input() entityName: string;
    @Input() baseIndex: number;
    @Input() showAltaPhone: boolean = true;
    contactsDisplays: Array<ContactsDisplay> = [];
    @Input() set contactsDisplaysIn(contactsDisplays: Array<ContactsDisplay>) {
        this.contactsDisplays = contactsDisplays;
        this.resetPaginator();
        this.resetSelectedContact();
        this.resetRecordLocking();
    }
    @Input() createInstance: (obj: any) => IContact;
    @Input() entityContactInfoDisplays: Array<IContact> = [];
    @Input() entityContactMap: IContactMap;
    @Input() companyName: string;
    @Input() allowNewContacts: boolean = true;
    @Input() firstOwnerName: string;
    @Input() hideSort: boolean = false;
    @Output() contactChanges: EventEmitter<Array<IContact>> = new EventEmitter();
    @Input() preferences: Array<Preference>;

    date: Date;
    selectContactStatus: any[] = [];
    additionalInfo: IContact;
    contact: IContact;
    newContact: IContact;
    newCompanyName: string = null;
    disabled: boolean = true;
    functionalTitles: FunctionalTitle[] = [];
    functionalTitlesIndexed: Map<string, string> = new Map<string, string>();
    tmpContactInfoDisplays: Array<IContact> = [];

    editable: boolean = true;
    cgTemporal: IContact;
    isContactSlotted: boolean;
    contactDisplay: ContactsDisplay;
    contactId: string = '';
    contactOptedOut: boolean = false;
    showLinkedInFooter: boolean = false;
    maxDateValue: Date;
    phone: string;
    altPhone: string;
    linkedin: string;
    disabledButton: boolean;
    disableFormSub: Subscription;
    tmpContactStatus = [];

    caChangedFieldsReMap: Map<string, string> = new Map([
        ['zipCode', 'contactPostalCode'],
    ]);

    constructor(
        public dialogService: DialogService,
        public session: SessionService,
        private serverDate: ServerDateService,
        public messageHandlerService: MessageHandlerService,
        public recordLockingFlow: RecordLockingFlow,
        private translate: TranslateService,
        public contactService: ContactService,
        public contactDetailsSectionService: ContactDetailsSectionService,
        public headerService: HeaderService,
        public sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.maxDateValue = this.serverDate.now();
        this.selectContactStatus = ['CURRENT', 'DEAD', 'MOVED', 'RETIRED', 'TRASH']
        this.loadComponent();
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledButton = disable;
            this.disabled = disable;
        });
    }

    setMailingAddress(mailAddress: Address) {
        if (mailAddress != undefined) {
            this.entityContactMap.addressV1 = mailAddress.address1;
            this.entityContactMap.addressV2 = mailAddress.address2;
            this.entityContactMap.city = mailAddress.cityName;
            this.entityContactMap.country = mailAddress.countryId;
            this.entityContactMap.countryName = mailAddress.countryName;
            this.entityContactMap.state = mailAddress.stateAbbrev;
            this.entityContactMap.stateName = mailAddress.stateName;
            this.entityContactMap.zipCode = mailAddress.zipCode;
            this.entityContactMap.countryId = mailAddress.c_id;
            this.entityContactMap.cityId = mailAddress.ci_id;
            this.entityContactMap.stateId = mailAddress.s_id;
        }
    }

    ngOnChanges() {
        if (this.tmpContactInfoDisplays !== this.entityContactInfoDisplays) {
            this.tmpContactInfoDisplays = this.entityContactInfoDisplays;
            this.loadComponent();
        }
    }

    emitContactsChanges() {
        const acContactsPoolAndSlotted = this.acContactsPool.concat(this.acContactsSlotted);
        this.contactChanges.emit(acContactsPoolAndSlotted);
    }

    disabledButtonOrEmptyID() {
        return this.disabledButton || StringUtils.isEmptyInPrimitiveTypes(this.entityId);
    }

    resetPaginator() {
        if(this.poolTableComponent){
            this.poolTableComponent.resetPaginator();
        }
    }

    resetSelectedContact() {
        this.additionalInfo = null;
        this.phone = '';
        this.altPhone = '';
        this.linkedin = '';
    }

    resetRecordLocking(): void {
        this.recordLockingFlow.clearContactLockFlow(this.entityName, String(this.entityId));
    }

    loadComponent() {
        this.acContactsPool = [];
        this.acContactsSlotted = [];

        this.loadFunctionalTitles();
        this.populateContactsPoolGrid();

        if (this.useSlottedBehavior) {
            this.populateContactsSlottedGrid();
            this.arrangeByContactFunction();
        }
    }

    loadFunctionalTitles() {
        this.sharedService.functionalTitles()
            .subscribe((fTitles: FunctionalTitle[]) => {
                this.functionalTitles = [...fTitles];
                this.functionalTitles.forEach((record: FunctionalTitle) => {
                    this.functionalTitlesIndexed[Number(record.titleId)] = record.titleDesc;
                });
            });
    }

    populateContactsPoolGrid() {
        if (this.entityContactInfoDisplays) {
            for (const item of this.entityContactInfoDisplays) {
                if (item.functionalSlot === 1) {
                    continue;
                }

                // Get contacts from list
                let cd: ContactsDisplay = this.contactsDisplays.find(p => p.contactId === item.contactId);
                if (cd == null) {
                    cd = ContactsDisplay.CreateInstance({});
                }

                if (item.contactStatus === null || item.contactStatus === '') {
                    item.contactStatus = 'CURRENT';
                }

                if (item.contactTitle !== null)
                    item.contactTitle = String(Number(item.contactTitle));

                item.contactDisplay = cd;
                this.acContactsPool.push(item);
            }
        }
    }

    populateContactsSlottedGrid() {
        if (this.entityContactInfoDisplays) {
            for (const item of this.entityContactInfoDisplays) {
                if (item.functionalSlot === 1) {
                    // Get contacts from list
                    let cd: ContactsDisplay = this.contactsDisplays.find(p => p.contactId === item.contactId);
                    if (cd == null) {
                        cd = ContactsDisplay.CreateInstance({});
                    }

                    if (item.contactStatus === null || item.contactStatus === '') {
                        item.contactStatus = 'CURRENT';
                    }

                    item.contactDisplay = cd;
                    this.acContactsSlotted.push(item);
                }
            }
        }
    }

    moveToSlotted(contact: IContact) {
        if (this.disabledButton) { return; }
        if (this.isTitleIdValid(contact)) {

            if (this.contactExistsWithTitle(contact)) {
                this.messageHandlerService.show(this.translate.instant("contact.contactGrid.contactTitleAlreadyExists"), MessageType.INFO);
                return;
            }

            const now = this.serverDate.now();
            contact.originalContactQcDate = contact.contactQcDate;
            contact.originalContactQcUser = contact.contactQcUser;
            contact.applyChanges({
                contactStatus: "CURRENT",
                functionalSlot: 1,
                contactSaleFlag: '1',
                contactSaleFlagBool: true,
                editableContactQcDate: now,
                contactQcDate: String(now.getTime()),
                contactQcUser: this.session.getPersonFullName()
            });

            this.acContactsPool = this.removeContactFromList(this.acContactsPool, contact.mvOrder, contact.contactDisplay.contactId);

            this.acContactsSlotted.push(contact);
            this.acContactsSlotted = this.acContactsSlotted.sort((a, b) => a.functionalSlot - b.functionalSlot);

            this.resetPaginator();

            this.arrangeByContactFunction();
            this.arrangePoolMvOrder();
            this.headerService.changeForm = true;
        } else {
            contact.functionalSlot = 0;
            this.messageHandlerService.show(this.translate.instant("contact.contactGrid.contactInvalidTitle"), MessageType.INFO);
        }
    }

    removeContactFromList(tmpContactList: IContact[], mvOrder: number, contactId: number) {
        const differentContactIdAndMvOrder = (iContact: IContact) => iContact.mvOrder !== mvOrder || iContact.contactDisplay.contactId !== contactId;
        return tmpContactList.filter(differentContactIdAndMvOrder);
    }

    functionalTitleById(titleId: string): string {
        return this.functionalTitlesIndexed[Number(titleId)] ?? this.translate.instant('contact.contactGrid.assignFunction');
    }

    moveToPool(contact: IContact) {
        if (this.disabledButton) { return; }
        this.acContactsSlotted = this.removeContactFromList(this.acContactsSlotted, contact.mvOrder, contact.contactDisplay.contactId);

        const nextPoolMvOrder = MVTOperations.getLastMvOrder(this.acContactsPool) + 1;
        contact.applyChanges({
            functionalSlot: 0,
            contactQcDate: contact.originalContactQcDate,
            contactQcUser: contact.originalContactQcUser,
            mvOrder: nextPoolMvOrder
        });

        this.acContactsPool.push(contact);
        this.arrangeByContactFunction();
        this.arrangePoolMvOrder();
        this.headerService.changeForm = true;
    }

    isTitleIdValid(contact: IContact): boolean {
        if (contact.contactTitle != null && contact.contactTitle !== '0') {
            return this.functionalTitles.find(p => p.titleId == contact.contactTitle) != null;
        }

        return false;
    }

    arrangeByContactFunction() {
        let index = 1;
        let rowIndexGridSlot = -1;

        for (const title of this.functionalTitles) {
            for (const contact of this.acContactsSlotted) {
                contact.titleDesc = contact.contactTitle ? contact.titleDesc : this.translate.instant('contact.contactGrid.assignFunction');
                if (Number(title.titleId) === Number(contact.contactTitle) && contact.functionalSlot == 1) {
                    contact.applyChanges({ mvOrder: index++ });
                    break;
                }
            }
        }

        for (const contact of this.acContactsSlotted) {
            if (contact.functionalSlot == 1) {
                rowIndexGridSlot = -1;
                for (const title of this.functionalTitles) {
                    if (Number(title.titleId) == Number(contact.contactTitle)) {
                        rowIndexGridSlot = title.rank;
                        break;
                    }
                }
                if (rowIndexGridSlot == -1) {
                    contact.applyChanges({ mvOrder: index++ });
                }
            }
        }

        MVTOperations.sortByMvOrder(this.acContactsSlotted);
    }

    arrangePoolMvOrder() {
        let nextSlottedMvOrder = MVTOperations.getLastMvOrder(this.acContactsSlotted, false) + 1;
        for (const contact of this.acContactsPool) {
            contact.applyChanges({ mvOrder: nextSlottedMvOrder++ });
        }
    }

    contactExistsWithTitle(contact: IContact): boolean {
        return this.acContactsSlotted.some(p => p.contactTitle == contact.contactTitle);
    }

    showDisabled() { }

    acContactsPoolFiltered(): Array<IContact> {
        if (this.acContactsPool) {
            this.acContactsPool = MVTOperations.filterByDeleted(this.acContactsPool);
            this.acContactsPool = MVTOperations.sortByMvOrder(this.acContactsPool);
        }

        return this.acContactsPool;
    }

    acContactsSlottedFiltered(): Array<IContact> {
        if (this.acContactsSlotted) {
            this.acContactsSlotted = MVTOperations.filterByDeleted(this.acContactsSlotted);
            this.acContactsSlotted = MVTOperations.sortByMvOrder(this.acContactsSlotted);
        }

        return this.acContactsSlotted;
    }

    getColumnHeaderStyle(w1: number, w2: number, w3: number, w4: number, w5: number, w6: number, w7: number, w8: number, w9: number, w10: number, w11: number) {
        return {
            "contactId": `flex-basis: ${w1}%`,
            "contactTitle": `flex-basis: ${w2}%`,
            "firstName": `flex-basis: ${w3}%`,
            "lastName": `flex-basis: ${w4}%`,
            "companyName": `flex-basis: ${w5}%`,
            "contactTitleDesc": `flex-basis: ${w6}%`,
            "contactStatus": `flex-basis: ${w7}%`,
            "contactQcDate": `flex-basis: ${w8}%`,
            "contactOnsiteBool": `flex-basis: ${w9}%`,
            "contactSaleFlagBool": `flex-basis: ${w10}%`,
            "contactActionButton": `flex-basis: ${w11}%`,
        };
    }

    dgContactsPool_doubleClick(iContact: IContact) {
        if (iContact) {
            this.cgTemporal = iContact as IContact;
            const isSlotted = this.cgTemporal.functionalSlot == 1;
            this.handlerContactLoadFlow(isSlotted);
        }
    }

    handlerContactLoadFlow(isSlotted: boolean = false) {
        const loadContactDisplayFromFlow = (event: ContactEvent) => {
            if (event.type === ContactEventType.LOAD_FROM_FLOW) {
                this.cgTemporal.contactDisplay = this.contactsDisplays.find(p => p.contactId === this.cgTemporal.contactId);
                if (event.data.contactId !== null) {
                    this.setContact(event.data);
                } else {
                    this.cgTemporal.contactDisplay.lockError = event.data.lockError;
                    this.cgTemporal.contactDisplay.lockId = event.data.lockId;
                    this.cgTemporal.contactDisplay.lockMode = event.data.lockMode;
                    this.cgTemporal.contactDisplay.lockSameUser = event.data.lockSameUser;
                    this.cgTemporal.contactDisplay.lockSameSession = event.data.lockSameSession;
                }
            }
            this.recordLockingFlow.setContactLockItem(this.entityName,
                String(this.entityId),
                String(this.cgTemporal.contactId),
                this.cgTemporal.contactDisplay.lockId !== null ?
                    String(this.cgTemporal.contactDisplay.lockId) : null);

            this.openContactDetailsView();

            if (!this.disabled && this.cgTemporal.contactDisplay.lockError !== null) {
                this.messageHandlerService.show(this.cgTemporal.contactDisplay.lockError, MessageType.INFO);
            }
        }

        this.isContactSlotted = isSlotted;

        let selectedContact  = this.contactsDisplays.find(p => p.contactId === this.cgTemporal.contactId);
        if(selectedContact.getOperation() !== OperationType.INSERT){
            // load existing contact from record locking
            this.recordLockingFlow.loadContactRecordFlow(String(this.cgTemporal.contactId),
                this.entityName,
                String(this.entityId),
                this.contactsDisplays,
                this.disabled,
            loadContactDisplayFromFlow);
        } else {
            // load temporal contact from contactsDisplay
            loadContactDisplayFromFlow(new ContactEvent(ContactEventType.LOAD_FROM_FLOW, selectedContact));
        }
    }

    onEditInit(event: any) {
        if (event.field === 'contactStatus') {
            this.tmpContactStatus[event.index] = event.data.contactStatus;
        }
    }

    onEditComplete(event: any) {
        if (event.field === 'contactStatus') {
            const lastContactStatus = this.tmpContactStatus[event.index];
            const currentContactStatus = event.data.contactStatus;

            if (currentContactStatus === '' || currentContactStatus === null) {
                event.data.contactStatus = lastContactStatus;
                this.messageHandlerService.show(this.translate.instant("contact.contactGrid.contactStatusRequired"), MessageType.INFO);
                return;
            }

            const isKeyboardEvent = event.originalEvent instanceof KeyboardEvent;
            if (isKeyboardEvent && event.originalEvent.key !== 'Enter') {
                event.data.contactStatus = lastContactStatus;
                return;
            }

            if (lastContactStatus !== currentContactStatus && currentContactStatus !== event.data.originalContactStatus) {
                this.headerService.changeForm = true;
                this.onChangeStatus(event.data);
            }
        }

        if (event.field === 'contactQcDate') {
            event.data.contactQcDate = event.data.editableContactQcDate !== null ?
                event.data.editableContactQcDate.getTime() : null;
        }

        if (event.field === 'contactTitle') {
            if (!event.data.contactTitle) {
                event.data.contactTitle = '0';
                event.data.titleDesc = this.translate.instant('contact.contactGrid.assignFunction');
            }
            if (event.data.contactTitle !== event.data.originalContactTitle) {
                this.headerService.changeForm = true;
            }
        }

        this.checkChangesInBean(event.data, event.field);
    }

    checkChangesInBean(row: IContact, field: string) {
        let changed = false;
        let changedField = null;

        if (field === 'contactSaleFlagBool') {
            changed = row.originalContactSaleFlag !== row.contactSaleFlag;
            changedField = 'contactSaleFlag';
        } else if (field === 'contactOnsiteBool') {
            changed = row.originalContactOnSite !== row.contactOnsite;
            changedField = 'contactOnsite';
        } else if (field === 'contactStatus') {
            changed = row.originalContactStatus !== row.contactStatus;
            changedField = 'contactStatus';
        } else if (field === 'contactQcDate') {
            changed = row.originalContactQcDate !== row.contactQcDate;
            changedField = 'contactQcDate';
        } else if (field === 'contactTitle') {
            changed = row.originalContactTitle !== row.contactTitle;
            changedField = 'contactTitle';
        }

        if (changed) {
            row.setOperation(OperationType.UPDATE);
            row.changedFields[changedField] = 0;

            this.emitContactsChanges();
        }
    }

    openContactDetailsView() {
        if (this.cgTemporal && this.cgTemporal.contactId !== null) {
            MVTOperations.sortByMvOrder(this.cgTemporal.contactDisplay.contactsComments);
        } else {
            this.cgTemporal.contactDisplay.setOperation(OperationType.INSERT);
        }

        const cmd: ContactModalData = {
            entityId: this.entityId,
            entityName: this.entityName,
            saveEntityContactBtnEnabled: true,
            viewContactAssociationsEnabled: false,
            originalTitleId: '',
            contactGeneric: this.cgTemporal,
            contactMap: this.entityContactMap,
            acContactsSlotted: this.acContactsSlotted,
            entityContactInfoDisplays: this.entityContactInfoDisplays
        }

        this.openContactDetails(cmd);
    }

    setContact(contact: ContactsDisplay): void {
        this.contactsDisplays.forEach((p: ContactsDisplay) => {
            if (p.contactId === contact.contactId) {
                p.merge(contact);
            }
        })
    }

    openContactModalView() {
        const cmd: ContactModalData = {
            entityId: this.entityId,
            entityName: this.entityName,
            saveEntityContactBtnEnabled: true,
            viewContactAssociationsEnabled: false,
            originalTitleId: '',
            contactGeneric: this.newContact,
            contactMap: this.entityContactMap,
            acContactsSlotted: this.acContactsSlotted,
            entityContactInfoDisplays: this.entityContactInfoDisplays
        }
        this.openContactDetails(cmd);
    }

    openContactModal() {
        const modalRef =
            this.dialogService.open(ContactModalComponent, {
                header: 'Contact Search',
                width: '90rem',
                data: { companyName: this.truncatedOwnerName(), 
                    allowNewContacts: this.allowNewContacts,
                    entityName: EntityName.COMPANY },
                dismissableMask: true,
                draggable: true,
                keepInViewport: true
            });
        modalRef.onClose.subscribe((contact: ContactsDisplay) => {
            this.addToContactPool(contact);
        });
    }

    truncatedOwnerName(): string {
        return this.firstOwnerName !== null ?
            this.firstOwnerName.substring(0, Math.max(5, Math.ceil(this.firstOwnerName.length / 3) - 1)) : "";
    }

    openRelatedEntities() {
        if (this.contactId.toString() === '' || this.contactId === undefined) return;
        let contactId = Number(this.contactId);
        this.contactService.getContactById(contactId).subscribe((resp: ContactsWithException) => {
            
            this.contactId = '';
            if (resp.contacts === null) {
                if (resp.exception != Constants.EXCEPTION_SESSION_INVALID) {
                    this.searchContactOnGrid(contactId);
                }
                return;
            }
            if (resp.contacts.some(contact => contact.ContactOptedOut)) {
                const doConfirSelectOptOut = (messageResp: MessageResponse): void => {
                    if (messageResp.event === MessageEvent.YES) {
                        this.showRelatedEntitiesModal(resp.contacts);
                    }
                }
                this.messageHandlerService.show(this.translate.instant("contact.project.confirmSelectOptOut"), MessageType.CONFIRM, doConfirSelectOptOut);
            } else {
                this.showRelatedEntitiesModal(resp.contacts);
            }
        });
    }

    searchContactOnGrid(contactId: number): void {
        let contact =  MVTOperations.sortByMvOrder(this.acContactsSlotted).find(contact => contact.contactId === contactId);
        if(!contact){
            contact = MVTOperations.sortByMvOrder(this.acContactsPool).find(contact => contact.contactId === contactId);
        }
        if(contact) {
            this.cloneIContact(contact);
        } else {
            this.messageHandlerService.show(this.translate.instant("contact.contactRelated.contactDoesNotExist"), MessageType.INFO);
        }
    }

    showRelatedEntitiesModal(relatedEntities: Contact[]) {
        const modalRef = this.dialogService.open(RelatedEntitiesComponent, {
            header: 'Related Entities',
            width: '80rem',
            data: {
                contact: relatedEntities
            },
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((contact: Contact) => {
            this.addToContactPool(contact);
        });
    }

    addToContactPool(_contact: any) {
        if (_contact != null) {
            let isNewContact = _contact instanceof ContactsDisplay;
            let addContactAndOpenDetailsModal = false;
            const contactEntities = this.createInstance({});
            const contactDisplay: ContactsDisplay = ContactsDisplay.CreateInstance({});
            const mvOrder = MVTOperations.getLastMvOrder(this.entityContactInfoDisplays) + 1;

            if (_contact instanceof ContactsDisplay) {
                const contact: ContactsDisplay = _contact as ContactsDisplay;
                contactDisplay.contactId = contact.contactId;
                contactDisplay.firstName = contact.firstName;
                contactDisplay.lastName = contact.lastName;
                contactDisplay.setOperation(OperationType.TEMPORAL);

                contactEntities.contactId = contact.contactId;
                contactEntities.mainEntityId = this.entityId;
                contactEntities.setOperation(OperationType.TEMPORAL);
            } else if (_contact instanceof Contact) {
                const contact: Contact = _contact as Contact;
                contactDisplay.contactId = contact.ContactID;
                contactDisplay.firstName = contact.FirstName;
                contactDisplay.lastName = contact.LastName;
                contactDisplay.emailAddress = contact.EmailAddress;
                contactDisplay.linkedInId = contact.LinkedInId;
                contactDisplay.contactOptedOutBool = contact.ContactOptedOut;
                contactDisplay.marketingEmailStatus = contact.EmailStatus;
                contactDisplay.marketingEmailOverrideStatus = contact.EmailOverrideStatus;
                contactDisplay.marketingOverrideDate = contact.OverrideDate;
                contactDisplay.marketingOverridePerson = contact.OverridePerson;
                contactDisplay.lastUser = contact.LastUser;
                contactDisplay.setOperation(OperationType.IDLE);

                contactEntities.contactSaleFlagBool = true;
                contactEntities.contactId = contact.ContactID;
                this.loadEntityId(contactEntities);
                contactEntities.companyId = contact.CompanyID;
                contactEntities.companyName = contact.CompanyName;
                contactEntities.companyStatus = contact.CompanyStatus;
                contactEntities.contactTitleDesc = contact.ActualTitle == null ? "" : contact.ActualTitle;
                if (contactEntities.contactTitleDesc == "") contactEntities.contactTitleDesc = contactEntities.titleDesc;
                contactEntities.mailAddressLine1 = contact.EntityAddressV1 == null ? "" : contact.EntityAddressV1;
                contactEntities.mailAddressLine2 = contact.EntityAddressV2 == null ? "" : contact.EntityAddressV2;

                contactEntities.mailCountry = contact.MailCountry == null ? "" : contact.MailCountry;
                contactEntities.mailState = contact.EntityState == null ? "" : contact.EntityState;
                contactEntities.mailCity = contact.EntityCity == null ? "" : contact.EntityCity;

                contactEntities.contactPhoneCc = contact.EntityPhoneCC;
                contactEntities.contactPhoneNo = contact.EntityPhoneNO == null ? "" : contact.EntityPhoneNO;
                contactEntities.contactPhoneExt = contact.EntityPhoneEXT == null ? "" : contact.EntityPhoneEXT;
                contactEntities.contactPhoneMobileSel = contact.EntityPhoneMobile == '1' ? true : false;

                contactEntities.contactAltPhoneCc = contact.EntityAltPhoneCC;
                contactEntities.contactAltPhoneNo = contact.EntityAltPhoneNO == null ? "" : contact.EntityAltPhoneNO;
                contactEntities.contactAltPhoneExt = contact.EntityAltPhoneEXT == null ? "" : contact.EntityAltPhoneEXT;
                contactEntities.contactAltPhoneMobileSel = contact.EntityAltPhoneMobile == '1' ? true : false;
                contactEntities.mailAddressVerifiedBool = contact.MailAddressVerified == '1' ? true : false;
                contactEntities.mailAddressVerifiedDate = contact.MailAddressVerifiedDate;

                contactEntities.contactFaxCc = contact.FaxCC;
                contactEntities.contactFaxNo = contact.FaxNO == null ? "" : contact.FaxNO;
                contactEntities.contactFaxExt = contact.FaxEXT == null ? "" : contact.FaxEXT;

                contactEntities.mailPostalCode = contact.MailPostalCode == null ? "" : contact.MailPostalCode;
                contactEntities.mailStateName = contact.StateName == null ? "" : contact.StateName;
                contactEntities.mailCountryName = contact.Country_Name == null ? "" : contact.Country_Name;

                contactEntities.contactTitle = contact.ContactTitleId;
                contactEntities.titleDesc = contact.ContactTitleDesc;
                contactEntities.contactFunction = contact.ContactFunction;

                contactEntities.operationInPool = contact.Operation;
                addContactAndOpenDetailsModal = contactEntities.operationInPool === ContactOperation.ADD_AND_VIEW;

                contactEntities.contactStatus = contact.ContactStatus ?? contact.Status;
                if (contact.QcDate != null) {
                    contactEntities.contactQcDate = String(contact.QcDateObj.getTime());
                }

                contactEntities.mailCountryId = contact.MailCountryCID;
                contactEntities.mailStateId = contact.MailStateSID;
                contactEntities.mailCityId = contact.MailCityCIID;

                contactEntities.contactLastUser = contact.LastUser;
                contactEntities.contactLastDate = contact.LastDate;

                contactEntities.setOperation(addContactAndOpenDetailsModal ? OperationType.TEMPORAL : OperationType.INSERT);
            }

            contactEntities.editableContactQcDate = contactEntities.getContactQcDateFormat(this.serverDate.now());
            contactEntities.mainEntityId = this.entityId;
            if (this.companyName || this.newCompanyName) {
                contactEntities.companyName = this.companyName ?? this.newCompanyName;
            }

            contactEntities.mvOrder = mvOrder;
            contactEntities.contactDisplay = contactDisplay;

            this.contactsDisplays.push(contactDisplay);
            this.entityContactInfoDisplays.push(contactEntities);
            this.contactChanges.emit(this.entityContactInfoDisplays);
            this.loadComponent();
            this.headerService.changeForm = true;

            if (isNewContact) {
                if (this.entityContactMap.ownerId) {
                    contactEntities.companyId = this.entityContactMap.ownerId;
                    contactEntities.companyName = this.entityContactMap.ownerName;
                    contactEntities.companyStatus = this.entityContactMap.ownerStatus;
                }

                if (this.entityContactMap.physCountry) {
                    contactEntities.contactDisplay.nationality = this.entityContactMap.physCountry;
                    contactEntities.contactDisplay.nationalityName = this.entityContactMap.physCountryName;
                }

                this.newContact = contactEntities;
                this.openContactModalView();
            } else if (addContactAndOpenDetailsModal) {
                this.cgTemporal = contactEntities;
                this.handlerContactLoadFlow();
            }
            this.navigateLastElement();
            if (!isNewContact) {
                this.selectLastElement();
            }
        }
    }

    private cloneIContact(contact: IContact) {
        const contactEntities = this.createInstance({});
        const contactDisplay: ContactsDisplay = ContactsDisplay.CreateInstance({});
        const mvOrder = MVTOperations.getLastMvOrder(this.entityContactInfoDisplays) + 1;

        contactDisplay.contactId = contact.contactDisplay.contactId;
        contactDisplay.firstName = contact.contactDisplay.firstName;
        contactDisplay.lastName = contact.contactDisplay.lastName;
        contactDisplay.emailAddress = contact.contactDisplay.emailAddress;
        contactDisplay.linkedInId = contact.contactDisplay.linkedInId;
        contactDisplay.contactOptedOutBool = contact.contactDisplay.contactOptedOutBool;
        contactDisplay.marketingEmailStatus = contact.contactDisplay.marketingEmailStatus;
        contactDisplay.marketingEmailOverrideStatus = contact.contactDisplay.marketingEmailOverrideStatus;
        contactDisplay.marketingOverrideDate = contact.contactDisplay.marketingOverrideDate;
        contactDisplay.marketingOverridePerson = contact.contactDisplay.marketingOverridePerson;
        contactDisplay.lastUser = contact.contactDisplay.lastUser;
        contactDisplay.setOperation(contact.contactDisplay.getOperation());

        contactEntities.contactSaleFlagBool = contact.contactSaleFlagBool;
        contactEntities.contactSaleFlag = contact.contactSaleFlag;

        contactEntities.contactOnsite = contact.contactOnsite;
        contactEntities.contactOnsiteBool = contact.contactOnsiteBool;

        contactEntities.contactId = contact.contactId;
        this.loadEntityId(contactEntities);
        contactEntities.companyId = contact.companyId;
        contactEntities.companyName = contact.companyName;
        contactEntities.companyStatus = contact.companyStatus;
        contactEntities.contactTitleDesc = contact.contactTitleDesc;
        contactEntities.mailAddressLine1 = contact.mailAddressLine1;
        contactEntities.mailAddressLine2 = contact.mailAddressLine2;

        contactEntities.mailCountry = contact.mailCountry;
        contactEntities.mailState = contact.mailState;
        contactEntities.mailCity = contact.mailCity;

        contactEntities.contactPhoneCc = contact.contactPhoneCc;
        contactEntities.contactPhoneNo = contact.contactPhoneNo;
        contactEntities.contactPhoneExt = contact.contactPhoneExt;
        contactEntities.contactPhoneMobileSel = contact.contactPhoneMobileSel;

        contactEntities.contactAltPhoneCc = contact.contactAltPhoneCc;
        contactEntities.contactAltPhoneNo = contact.contactAltPhoneNo;
        contactEntities.contactAltPhoneExt = contact.contactAltPhoneExt;
        contactEntities.contactAltPhoneMobileSel = contact.contactAltPhoneMobileSel;
        contactEntities.mailAddressVerifiedBool = contact.mailAddressVerifiedBool;
        contactEntities.mailAddressVerifiedDate = contact.mailAddressVerifiedDate;

        contactEntities.contactFaxCc = contact.contactFaxCc;
        contactEntities.contactFaxNo = contact.contactFaxNo;
        contactEntities.contactFaxExt = contact.contactFaxExt;

        contactEntities.mailPostalCode = contact.mailPostalCode;
        contactEntities.mailStateName = contact.mailStateName;
        contactEntities.mailCountryName = contact.mailCountryName;

        contactEntities.contactTitle = contact.contactTitle;
        contactEntities.titleDesc = contact.titleDesc;
        contactEntities.contactFunction = contact.contactFunction;

        contactEntities.operationInPool = contact.operationInPool;

        contactEntities.contactStatus = contact.contactStatus;
        contactEntities.contactQcDate = contact.contactQcDate;

        contactEntities.mailCountryId = contact.mailCountryId;
        contactEntities.mailStateId = contact.mailStateId;
        contactEntities.mailCityId = contact.mailCityId;

        contactEntities.contactLastUser = contact.contactLastUser;
        contactEntities.contactLastDate = contact.contactLastDate;

        contactEntities.setOperation(OperationType.INSERT);

        contactEntities.editableContactQcDate = contactEntities.getContactQcDateFormat(this.serverDate.now());
        contactEntities.mainEntityId = this.entityId;


        contactEntities.mvOrder = mvOrder;
        contactEntities.contactDisplay = contactDisplay;

        this.contactsDisplays.push(contactDisplay);
        this.entityContactInfoDisplays.push(contactEntities);
        this.contactChanges.emit(this.entityContactInfoDisplays);
        this.loadComponent();
        this.headerService.changeForm = true;


        this.cgTemporal = contactEntities;
        this.navigateLastElement();
        this.selectLastElement();

    }

    private navigateLastElement(): void{
        this.poolTableComponent.navigateLastElement();
    }

    private selectLastElement() {
        this.poolTableComponent.selectLastElement();
    }

    private loadEntityId(contactEntities: IContact) {
        if (contactEntities instanceof AssetsContactInfoDisplay) {
            (contactEntities as AssetsContactInfoDisplay).assetId = this.entityId
        }
    }

    onRowclick(contact: any) {
        this.additionalInfo = contact;
        this.buildPhoneAndAltPhone(this.additionalInfo);
        this.contactDisplay = contact.contactDisplay;
    }

    openContactDetails(contactModalData: ContactModalData) {
        const modalRef = this.dialogService.open(ContactDetailsModalComponent, {
            header: 'Contact Details',
            width: '90rem',
            data: contactModalData,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((saved: boolean) => {
            if (saved) {
                if (this.useSlottedBehavior) {
                    this.updateSavedContactByFunction(contactModalData);
                }
                this.emitContactsChanges();
                if (contactModalData.contactGeneric.contactDisplay.getOperation() === OperationType.INSERT) {
                    this.navigateLastElement();
                }

                if (this.newContact !== null && this.newContact !== undefined) {
                    this.selectLastElement();
                }
                this.newContact = null;
            } else {
                this.removeIContactIfExists();
            }
        });
    }

    updateSavedContactByFunction(contactModalData: ContactModalData) {
        const savedContact = contactModalData.contactGeneric;
        const isSlottedContact = savedContact.functionalSlot == 1;

        if (isSlottedContact) {
            if (savedContact.contactStatus != 'CURRENT' || !savedContact.contactSaleFlagBool || !this.isTitleIdValid(savedContact)) {
                this.moveToPool(savedContact);
            }
        }
    }

    removeIContactIfExists() {
        // In this case, we have a new contact that was not saved
        if (this.newContact && this.newContact.contactId) {
            this.acContactsPool = this.acContactsPool.filter(p => p.contactDisplay.contactId !== this.newContact.contactId && p.getOperation() !== OperationType.TEMPORAL);
            this.emitContactsChanges();
        }

        if (this.cgTemporal && this.cgTemporal.contactId) {
            // In this case, we have a existent contact that was not saved
            const iContactInPool = this.acContactsPool.find(p => p.contactId === this.cgTemporal.contactId && p.getOperation() === OperationType.TEMPORAL);
            if (iContactInPool) {
                this.acContactsPool = this.acContactsPool.filter(p => p.contactId !== this.cgTemporal.contactId);
                this.emitContactsChanges();
            }
        }
    }

    onChangeStatus(contactInfo: IContact): void {
        let contacts = [];
        this.contactDetailsSectionService.getSearchAllContacts(contactInfo.mvId, '', '', '', '', contactInfo.contactId, 0, 'Associations', false, true).subscribe(resp => {
            if (resp) {
                contacts = resp;
                if (contacts.length > 0) {
                    this.showContactAssociationsModal(contactInfo, contacts);
                }
            }
        });
    }

    showContactAssociationsModal(contactInfo: IContact, contactProvider: Array<any>) {
        const data = {
            type: ContactRelatedEntities.CONTACT_ASSOCIATIONS,
            mainEntityId: this.entityId,
            mainEntityName: this.entityName,
            contactId: contactInfo.contactDisplay.contactId,
            contactName: `${contactInfo.firstName} ${contactInfo.lastName}`,
            contactMvOrder: contactInfo.mvOrder,
            contactOptedOut: false,
            currentState: '',
            coverageApplies: false,
            onSite: false,
            statusChange: true,
            selfMvId: contactInfo.mvId,
            contactProvider: contactProvider
        }

        const modalRef = this.dialogService.open(ContactRelatedComponent, {
            header: 'Contact Associations - ' + contactInfo.contactId,
            width: '90rem',
            data: data,
            draggable: true,
            keepInViewport: true
        });

    }

    // Due to the latest update of Primeng, the dropdowns inside the table are not functioning as before.
    // This approach triggers the onEditComplete event for the column, allowing us to close the dropdown.
    forceOnEditComplete(table: 'pool' | 'slotted') {
        if (table === 'pool') {
            this.poolTableComponent.clickToNativeElement();
        } else if (table === 'slotted') {
            this.slottedTableComponent.clickToNativeElement();
        }
    }

    onChangeContactStatusInPool() {
        this.forceOnEditComplete('pool');
    }

    onChangeContactStatusInSlotted(row: IContact): void {
        this.forceOnEditComplete('slotted');

        if (this.useSlottedBehavior
            && row.contactStatus !== 'CURRENT'
            && row.contactStatus !== ''
            && row.contactStatus !== null) {
            this.moveToPool(row);
        }
    }

    onChangeContactOnsite(row: IContact) {
        row.operationInPool = ContactOperation.CHANGED_IN_POOL;
        this.headerService.changeForm = true;
        if (!row.contactOnsiteBool) {
            setTimeout(() => {
                this.messageHandlerService.show(Constants.ON_SITE_SELECTION_REMOVED, MessageType.INFO);
            }, 200);
        }
    }


    onChangeContactSell(row: IContact) {
        if (this.useSlottedBehavior && row.functionalSlot == 1 && !row.contactSaleFlagBool) {
            this.moveToPool(row);
            this.checkChangesInBean(row, 'contactSaleFlagBool');
        }

        this.headerService.changeForm = true;
    }

    buildPhoneAndAltPhone(additionalInfo: IContact) {
        this.phone = additionalInfo.contactPhoneNo;
        if (additionalInfo.contactPhoneExt) {
            this.phone += '-' + additionalInfo.contactPhoneExt;
        }
        if (additionalInfo.contactPhoneMobile && additionalInfo.contactPhoneMobile === '1') {
            this.phone += ' (Mobile)';
        }

        this.altPhone = additionalInfo.contactAltPhoneNo;
        if (additionalInfo.contactAltPhoneExt) {
            this.altPhone += '-' + additionalInfo.contactAltPhoneExt;
        }
        if (additionalInfo.contactAltPhoneMobile && additionalInfo.contactAltPhoneMobile === '1') {
            this.altPhone += ' (Mobile)';
        }
        this.linkedin = additionalInfo.contactDisplay.linkedInId;
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    clearSection() {
        this.acContactsPool = [];
        this.acContactsSlotted = [];
        this.additionalInfo = null;
        this.cgTemporal = null;
        this.contactDisplay = null;
        this.editable = true;
        this.disabledButton = false;
    }

    getDynamicWidth(field: string): string {
        if (field === 'contactQcDate') {
            return '6rem';
        }
        return 'auto';
    }

    validateEmails(saveEntity: () => void, revertSaveEntity: () => void): void {
        let changedContacts = this.entityContactInfoDisplays.filter(contact =>
                                    contact.contactDisplay.emailAddress &&
                                    contact.contactDisplay.emailAddress !== '' &&
                                    (contact.getOperation() === OperationType.INSERT ||
                                    contact.contactDisplay.changedFields['emailAddress']));
        if(changedContacts && changedContacts.length > 0){
            let emails = changedContacts.map(contact => contact.contactDisplay.emailAddress);
            if(emails && emails.length > 0){
                this.contactService.getContactsByEmails(emails).subscribe((data: any) => {
                    let message = ''
                    let dataMap = this.groupContactDataByEmail(data, changedContacts);
                    Object.keys((dataMap)).forEach(email =>{
                        let contactId = changedContacts.find(contact => contact.contactDisplay.emailAddress === email)?.contactId;
                        message += this.translate.instant('contact.common.contactEmailEntityValidation', {contactId: contactId});
                        dataMap[email].forEach(contactId =>{
                            message += '<br>' + contactId;
                        });
                        message += '<br><br>';
                    });
                    if(message === ''){
                        saveEntity();
                    } else {
                        this.messageHandlerService.show(message, MessageType.VALIDATION_ERROR);
                        revertSaveEntity();
                    }
        
                });
            } else {
                saveEntity();
            }

        } else {
            saveEntity();
        }
    }

    private groupContactDataByEmail(data: any, changedContacts: IContact[]): any {
        let dataMap = {};
        data.forEach(contactValue => {
            let email = contactValue.EMAIL_ADDRESS;
            let contactId = changedContacts.find(contact => contact.contactDisplay.emailAddress === email)?.contactId;
            if(contactId !== contactValue.CONTACT_ID){
                if (!dataMap[email]) {
                    dataMap[email] = [];
                }
                dataMap[email].push(contactValue.CONTACT_ID);
            }
        });
        return dataMap;
    }
}

import { DecimalPipe } from '@angular/common';
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective implements OnInit {
    @Input() maxdecimal: string;
    @Input() maxwhole: number;
    @Input() maxValue: number;
    @Input() minValue: number;
    private previousValue: string;

    private static DOT: string = '.';
    private static MAX_WHOLE_LENGTH: number = 15;

    constructor(private ngControl: NgControl) { }

    ngOnInit(): void {
        this.maxdecimal = this.maxdecimal ?? "0";
        this.maxwhole = this.maxwhole ?? OnlyNumbersDirective.MAX_WHOLE_LENGTH;

        this.ngControl.control.valueChanges.subscribe(() => {
            if (this.ngControl.value !== null) {
                let numberValue = this.replace(this.ngControl.value);
                if (this.ngControl.value !== numberValue) {
                    this.ngControl.control.setValue(numberValue);
                }
                this.previousValue = numberValue;
            }

        });
    }

    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        const rValue = this.replace(this.ngControl.value);
        input.value = rValue;
        this.previousValue = rValue;
    }

    replace(value: any) {
        const decimal = parseInt(this.maxdecimal);
        if (value != null && typeof value === 'string') {
            let wholepart = value.length;
            if (decimal > 0) {
                value = value.replace(/[^0-9.]/g, '');

                const dotIndex = value.indexOf(OnlyNumbersDirective.DOT);
                if (dotIndex > -1) {
                    const dParts = value.split(OnlyNumbersDirective.DOT);
                    wholepart = dParts[0].length;
                    if (dParts.length > 2) {
                        value = dParts[0] + OnlyNumbersDirective.DOT + dParts[1];
                    }
                    value = value.substring(0, dotIndex + decimal + 1);
                }
            } else {
                value = value.replace(/[^0-9]/g, '');
            }
            let previousValue = this.previousValue ? this.previousValue : '';
            if (wholepart > this.maxwhole) {
                value = previousValue;
            } else if (this.maxValue && value && value > this.maxValue) {
                value = previousValue;
            } else if (this.minValue && value && value < this.minValue) {
                value = previousValue;
            }
        }

        return value;
    }

}

import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LoadWheelService } from "src/app/components/load-wheel/load-wheel.service";
import { MessageEvent, MessageParametersBuilder, MessageResponse, MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { SpinnerProcess } from "../models/spinner-process";
import { StringUtils } from "./string-utils";
import { EntityName } from "../models/enumerations/entity-name";
import { SessionAttribute, SessionService } from "../services/session.service";
import { environment } from "src/environments/environment";
import { WebsiteService } from "../services/website.service";
import { EntityPaths } from "./entityPaths";
import { LockSameUserMessageService } from "../services/lock-same-user-message.service";
import { Constants } from "../models/constants";
import { Bean } from "../models/bean";
import { DateUtil } from "./date-util";

@Injectable({
    providedIn: 'root'
})
export class EntityUtilsService {

    constructor(
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private translate: TranslateService,
        private session: SessionService,
        private websiteService: WebsiteService,
        public lockSameUserMessageService: LockSameUserMessageService
    ) {}
    
    checkForValueRequired(httpParams: HttpParams, wheel: SpinnerProcess): boolean {
        const hasValues = this.httpParamsHasValues(httpParams);
        if(!hasValues) {
            this.loadWheelService.hideWheelImmediately(wheel);
            this.messageHandlerService.show(this.translate.instant('required_params_search'), MessageType.ERROR)
        }
        return hasValues;
    }

    httpParamsHasValues(httpParams: HttpParams) {
        let hasValues: boolean = false;
        if(httpParams == null) {
            return hasValues;
        }

        let keys = httpParams.keys();
        if(keys !== null) {
            for (let i = 0; i < keys.length; i++) {
                let key: string = keys[i];
                let value = httpParams.get(key);
                if(!StringUtils.isEmptyInPrimitiveTypes(value)) { 
                    hasValues = true;
                    break;
                }
            }
        }
        return hasValues;
    }

    public verifyMaxResult(response: any): void {
        if (response?.message?.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
            throw new Error(response.message);
        }
    }

    downloadPDF(entityType: string, entityId: number) {
        const wId = 'pdf' + entityType + entityId;

        if (entityType === EntityName.PLANT) {
            const onClose = (resp: MessageResponse): void => {
                const showAllAssociatedContacts = resp.event === MessageEvent.ALL_ASSOCIATED_CONTACTS;
                const pdfUrl = this.getPdf(String(entityId), entityType, showAllAssociatedContacts);
                window.open(pdfUrl, wId);
            };

            this.messageHandlerService.open(
                new MessageParametersBuilder()
                    .type(MessageType.CUSTOM_MESSAGE)
                    .message(this.translate.instant('plant.pdfPreference.message'))
                    .footer(this.translate.instant('plant.pdfPreference.note'))
                    .addButton({ label: this.translate.instant('plant.pdfPreference.onlyPlantContacts'), event: MessageEvent.ONLY_PLANT_CONTACTS })
                    .addButton({ label: this.translate.instant('plant.pdfPreference.allAssociatedContacts'), event: MessageEvent.ALL_ASSOCIATED_CONTACTS })
                    .onClose(onClose)
                    .build()
            );
        } else if (entityId > 0) {
            const pdfUrl = this.getPdf(String(entityId), entityType);
            window.open(pdfUrl, wId);
        }
    }

    getPdf(entityId: string, entityType: string, showAllContacts: boolean = false) {
        const token = this.session.getAttribute(SessionAttribute.ServerAuthToken)
        if (entityType === EntityName.COMPANY) {
            return `${environment.pdfUrl}reports/company_pdf.jsp?COMPANY_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.SITE) {
            return `${environment.pdfUrl}reports/site_pdf.jsp?SITE_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.ASSET_PIPELINE) {
            return `${environment.pdfUrl}reports/pipeline_pdf.jsp?PIPELINE_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.ASSET_TRANSMISSION_LINE) {
            return `${environment.pdfUrl}reports/tline_pdf.jsp?TLINE_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.UNIT) {
            return `${environment.pdfUrl}reports/unit_pdf.jsp?UNIT_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.PLANT) {
            return `${environment.pdfUrl}reports/plant_pdf.jsp?PLANT_ID=${entityId}&showContacts=${showAllContacts}&token=${token}`;
        } else if (entityType === EntityName.PROJECT || entityType === EntityName.PROJECT_CONFIRMED || entityType === EntityName.PROJECT_UNCONFIRMED) {
            return `${environment.pdfUrl}reports/project_pdf.jsp?PROJECT_ID=${entityId}&showContacts=${showAllContacts}&token=${token}`;
        } else if (entityType === EntityName.AREA) {
            return `${environment.pdfUrl}reports/area_pdf.jsp?AREA_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.TURBINE) {
            return `${environment.pdfUrl}reports/drive_pdf.jsp?DRIVE_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.OEQUIPMENT) {
            return `${environment.pdfUrl}reports/oequipment_pdf.jsp?OTHER_EQUIPMENT_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.TANK) {
            return `${environment.pdfUrl}reports/tank_pdf.jsp?TANK_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.BOILER) {
            return `${environment.pdfUrl}reports/boiler_pdf.jsp?BOILER_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.MEQUIPMENT) {
            return `${environment.pdfUrl}reports/mequipment_pdf.jsp?MINING_EQUIPMENT_ID=${entityId}&token=${token}`;
        }else if (entityType === EntityName.PLANT_LTSA) {
            return `${environment.pdfUrl}reports/plantltsa_pdf.jsp?PLANT_LTSA_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.UNIT_LTSA) {
            return `${environment.pdfUrl}reports/unitltsa_pdf.jsp?UNITLTSA_ID=${entityId}&token=${token}`;
        } else if (entityType === EntityName.PIPELINE_LTSA) {
            return `${environment.pdfUrl}reports/pipelineltsa_pdf.jsp?PIPELINE_LTSA_ID=${entityId}&token=${token}`;
        }else if (entityType === EntityName.OFFLINE_EVENT) {
            return `${environment.pdfUrl}reports/offlineevent_pdf.jsp?OFFLINE_EVENT_ID=${entityId}&token=${token}`;
        }        
    }

    getClientViewUrl(entityName: string, entityId: string): string {
        let clientViewUrl = "";

        if (entityName === EntityName.COMPANY) {
            clientViewUrl = environment.urlReport.company + '?COMPANY_ID=' + entityId;
        } else if (entityName === EntityName.ASSET_PIPELINE) {
            clientViewUrl = environment.urlReport.pipeline + '?PIPELINE_ID=' + entityId;
        } else if (entityName === EntityName.ASSET_TRANSMISSION_LINE) {
            clientViewUrl = environment.urlReport.tline + '?TLINE_ID=' + entityId;
        } else if (entityName === EntityName.PLANT) {
            clientViewUrl = environment.urlReport.plant + '?PLANT_ID=' + entityId;
        } else if (entityName === EntityName.UNIT) {
            clientViewUrl = environment.urlReport.unit + '?UNIT_ID=' + entityId;
        } else if (entityName === EntityName.PROJECT || entityName === EntityName.PROJECT_CONFIRMED || entityName === EntityName.PROJECT_UNCONFIRMED) {
            clientViewUrl = environment.urlReport.project + '?PROJECT_ID=' + entityId;
        } else if (entityName === EntityName.TURBINE) {
            clientViewUrl = environment.urlReport.drive + '?TURBINE_ID=' + entityId;
        } else if (entityName === EntityName.OEQUIPMENT) {
            clientViewUrl = environment.urlReport.oEquipment + '?EQUIPMENT_ID=' + entityId;
        } else if (entityName === EntityName.MEQUIPMENT) {
            clientViewUrl = environment.urlReport.mEquipment + '?MINING_EQUIPMENT_ID=' + entityId;
        } else if (entityName === EntityName.BOILER) {
            clientViewUrl = environment.urlReport.boiler + '?BOILER_ID=' + entityId;
        } else if (entityName === EntityName.UNIT_LTSA) {
            clientViewUrl = environment.urlReport.unit + '?UNIT_ID=' + entityId + '&unitLTSA_report=true&currentEntitySelected=UNITS';
        } else if (entityName === EntityName.PLANT_LTSA) {
            clientViewUrl = environment.urlReport.plant + '?PLANT_ID=' + entityId + '&plantLTSA_report=true&currentEntitySelected=PLANTS';
        } else if (entityName === EntityName.OFFLINE_EVENT) {
            clientViewUrl = environment.urlReport.offline + '?OFFLINE_EVENT_ID=' + entityId;
        }

        return clientViewUrl;
    }

    getClientViewWebSiteUrl(entityName: string, entityId: string): string {
        let clientViewUrl = this.getClientViewUrl(entityName, entityId);
        return this.websiteService.getSSOURL(clientViewUrl + '&setFilter=');
    }

    onRouterLinkClick?(entityName: string, entityId: string) {
        const entityID = entityId;
        let entityPath = EntityPaths[Object.keys(EntityName).find(key => EntityName[key] === entityName)];
        this.lockSameUserMessageService.validateEntityIsLockedByUserAndOpenEntityTab(entityName, entityID, location.pathname + `#/ED/${entityPath}/${entityID}`, `${entityName + entityID}`);
    }

    validateAndGetRouterName(entityName: string) {
        if(!this.isScreenDeveloped(entityName))
        {
            return null;
        }
        return entityName;
    }

    isScreenDeveloped(entityName: string): boolean {
        return entityName !== EntityName.REGION_ALERTING
    }

    public static getEntityPath(entityName: string): string {
        return entityName.replaceAll(' ', '');
    }
    
    prepareEntityForSave(entity: Bean): Bean {
        for (const key in entity) {
            if (entity.hasOwnProperty(key)) {
                const value = entity[key];

                if (Array.isArray(value) && value.length > 0 && value[0] instanceof Bean) {
                    entity[key] = value.map((item: Bean) => this.prepareEntityForSave(item));
                    continue;
                } else if (value instanceof Bean) {
                    entity[key] = this.prepareEntityForSave(value);
                    continue;
                }

                this.prepareDatesUTC(entity, key, value);
            }
        }

        return entity;
    }

    prepareDatesUTC(entity: Bean, key: string, value: any) {
        if (value instanceof Date && !isNaN(value.getTime())) {
            if (value.getHours() === 0
                    && value.getMinutes() === 0
                    && value.getSeconds() === 0
                    && value.getMilliseconds() === 0) {
                const formattedDate: string = DateUtil.convertDateToString(value, 'dd/MM/yyyy');
                entity[`${key}UTC`] = formattedDate;
            }
        }
    }

}

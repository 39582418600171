import {Injectable} from "@angular/core";
import {Adapter} from "../../adapter";
import { StringUtils } from "../../utils/string-utils";
import { TranslateService } from "@ngx-translate/core";
import { EntityName } from "../enumerations/entity-name";

export class OfflineEventSearch {
    constructor(
        public eventID: string,
        public eventName: string,
        public eventTypeDesc: string,
        public eventStatusDesc: string,
        public offlineEventStatus: string,
        public eventStartDate: string,
        public eventEndDate: string,
        public ownerName: string,
        public unitId: string,
        public unitName: string,
        public plantId: string,
        public plantName: string,
        public industryCode: string,
        public sicCode: string,
        public sicCodeDesc: string,
        public unitType: string,
        public areaName: string,
        public operatorName: string,
        public recordStatus: string,
        public assetId: string,
        public assetName: string,
        public eventComments: Array<string>,
        public primaryFuel: string,
        public powerUsage: string,
        public qcDate: string,
        public eventDuration: string,
        public assetCount: number,
        public unitCount: number,
        public largeEventName: string
    ) {
    }

    sicCodeCompositeName():string {
        return StringUtils.getCompositeIDName(this.sicCode, this.sicCodeDesc);
    }

    static BuildOfflineEvents(entity: Array<any>, translate: TranslateService): Array<OfflineEventSearch> {
        return entity.map(item => OfflineEventSearch.CreateInstance(item, translate));
    }

    public static CreateInstance(offlineEvent: any, translate: TranslateService): OfflineEventSearch {
        let largeEventName = '';
        if(offlineEvent.COUNT_ASSETS > 1){
            largeEventName = translate.instant(Number(offlineEvent.COUNT_ASSETS) === 2 ? 'widgets.common.moreAsset' : 'widgets.common.moreAssets', {entityName: offlineEvent.EVENT_NAME, "count": offlineEvent.COUNT_ASSETS-1});
        } else if(offlineEvent.COUNT_UNITS > 1) {
            largeEventName = translate.instant(Number(offlineEvent.COUNT_UNITS) === 2 ? 'widgets.common.moreUnit' : 'widgets.common.moreUnits', {entityName: offlineEvent.EVENT_NAME, "count": offlineEvent.COUNT_UNITS-1});
        } else {
            largeEventName = offlineEvent.EVENT_NAME;
        }
        return new OfflineEventSearch(
            offlineEvent.OFFLINE_EVENT_ID,
            offlineEvent.EVENT_NAME,
            offlineEvent.OUTAGE_TYPE_DESC,
            offlineEvent.OUTAGE_STATUS_DESC,
            offlineEvent.OFFLINE_EVENT_STATUS_DESC,
            offlineEvent.START_DATE,
            offlineEvent.END_DATE,
            offlineEvent.OWNER_NAME,
            offlineEvent.UNIT_ID,
            offlineEvent.UNIT_NAME,
            offlineEvent.PLANT_ID,
            offlineEvent.PLANT_NAME,
            offlineEvent.INDUSTRY_CODE,
            offlineEvent.SIC_CODE_ID,
            offlineEvent.SIC_CODE_DESC,
            offlineEvent.UNIT_TYPE_NAME,
            offlineEvent.AREA_NAME,
            offlineEvent.OPERATOR_NAME,
            offlineEvent.RECORD_STATUS_DESC,
            offlineEvent.ASSET_ID,
            offlineEvent.ASSET_NAME,
            offlineEvent.COMMENTS_ALL,
            offlineEvent.PRIMARY_FUEL_DESC,
            offlineEvent.POWER_USAGE_DESC,
            offlineEvent.QC_DATE,
            offlineEvent.DURATION,
            offlineEvent.COUNT_ASSETS,
            offlineEvent.COUNT_UNITS,
            largeEventName
        );
    }
}



 @Injectable({
     providedIn: 'root',
 })
 export class OfflineEventSearchAdapter implements Adapter<OfflineEventSearch> {
    adapt(offlineEvent: any): OfflineEventSearch {
        return new OfflineEventSearch(
            offlineEvent.OFFLINE_EVENT_ID,
            offlineEvent.EVENT_NAME,
            offlineEvent.OUTAGE_TYPE_DESC,
            offlineEvent.OUTAGE_STATUS_DESC,
            offlineEvent.OFFLINE_EVENT_STATUS_DESC,
            offlineEvent.START_DATE,
            offlineEvent.END_DATE,
            offlineEvent.OWNER_NAME,
            offlineEvent.UNIT_ID,
            offlineEvent.UNIT_NAME,
            offlineEvent.PLANT_ID,
            offlineEvent.PLANT_NAME,
            offlineEvent.INDUSTRY_CODE,
            offlineEvent.SIC_CODE_ID,
            offlineEvent.SIC_CODE_DESC,
            offlineEvent.UNIT_TYPE,
            offlineEvent.AREA_NAME,
            offlineEvent.OPERATOR_NAME,
            offlineEvent.RECORD_STATUS_DESC,
            offlineEvent.ASSET_ID,
            offlineEvent.ASSET_NAME,
            offlineEvent.COMMENTS_ALL,
            offlineEvent.PRIMARY_FUEL_DESC,
            offlineEvent.POWER_USAGE_DESC,
            offlineEvent.QC_DATE,
            offlineEvent.DURATION,
            offlineEvent.COUNT_ASSETS,
            offlineEvent.COUNT_UNITS,
            ''
        );
     }
 }


<app-relatedinfo-base
    [implementedComponent]="this"
    i18nBase="relatedInfo.equipment"
    [tableKey]="tableKey"
    [preferences]="preferences"
    [caller]="caller"
    [rows]="rows"
    [showHeader]="showHeader"
    [callerEntityDisplay]="callerEntityDisplay"
    [callerEntityIdPropName]="callerEntityIdPropName"
    [addRecordDestinationPage]="addRecordDestinationPage"
    (reloadFunction)="reload()"
    [baseIndex]="baseIndex"
    [columnsInfo]="getColumnsInfo()">
</app-relatedinfo-base>

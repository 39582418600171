import { AreasContactInfo } from "../../area/save/areas-contact-info";
import { Bean } from "../../bean";
import { OfflineEventsPlants } from "./offline-event-plants";
import { OfflineEventsAssets } from "./offline-events-assets";
import { OfflineEventsBoilers } from "./offline-events-boilers";
import { OfflineEventsDrives } from "./offline-events-drives";
import { OfflineEventsNotes } from "./offline-events-notes";
import { OfflineEventsUnits } from "./offline-events-units";

export class OfflineEvents extends Bean {

    offlineEventId: number;
    offlineEventType: string;
    offlineEventStatus: string;
    offlineEventCause: string;
    startDate: string;
    endDate: string;
    lastStartDate: string;
    lastEndDate: string;
    estimatedStartDate: string;
    peakWorkers: string;
    peakWorkersDate: string;
    lastDate: string;
    recordStatus: string;
    lastUser: string;
    lastCompany: string;
    prevUser: string;
    prevDate: string;
    prevCompany: string;
    entryUser: string;
    entryDate: string;
    entryCompany: string;
    qcDate: string;
    qcUser: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;
    dateResolution: string;

    offlineEventsAssets: OfflineEventsAssets[] = [];
    offlineEventsBoilers: OfflineEventsBoilers[] = [];
    offlineEventsDrives: OfflineEventsDrives[] = [];
    offlineEventsNotes: OfflineEventsNotes[] = [];
    offlineEventsPlants: OfflineEventsPlants[] = [];
    offlineEventsUnits: OfflineEventsUnits[] = [];

    constructor(entity?: any) {
        super(entity);

        this.offlineEventId = entity?.offlineEventId ?? null;
        this.offlineEventType = entity?.offlineEventType ?? null;
        this.offlineEventStatus = entity?.offlineEventStatus ?? null;
        this.offlineEventCause = entity?.offlineEventCause ?? null;
        this.startDate = entity?.startDate ?? null;
        this.endDate = entity?.endDate ?? null;
        this.lastStartDate = entity?.lastStartDate ?? null;
        this.lastEndDate = entity?.lastEndDate ?? null;
        this.estimatedStartDate = entity?.estimatedStartDate ?? null;
        this.peakWorkers = entity?.peakWorkers ?? null;
        this.peakWorkersDate = entity?.peakWorkersDate ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.recordStatus = entity?.recordStatus ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.dateResolution = entity?.dateResolution ?? null;

        //TODO
        this.offlineEventsAssets = entity?.areasContactInfo ?? [];
        this.offlineEventsBoilers = entity?.areasContactInfo ?? [];
        this.offlineEventsDrives = entity?.areasContactInfo ?? [];
        this.offlineEventsNotes = entity?.areasContactInfo ?? [];
        this.offlineEventsPlants = entity?.areasContactInfo ?? [];
        this.offlineEventsUnits = entity?.areasContactInfo ?? [];
    }
}


/**
 * Useful way to add timeout capability to a Promise
 * Based on:  https://italonascimento.github.io/applying-a-timeout-to-your-promises 
 */

export const promiseTimeout = function(ms: number, promise: Promise<any>) {

   // Create a promise that rejects in <ms> milliseconds
   let timeout = new Promise((_, reject) => {
      let id = setTimeout(() => {
         clearTimeout(id);
         reject('timeout');
      }, ms)
   });

   // Returns a race between our timeout and the passed in promise
   return Promise.race([promise,timeout]);
}

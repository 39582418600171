import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[compositeId]'
})
export class CompositeIdDirective implements OnInit {

    constructor(private ngControl: NgControl) {}

    ngOnInit() {
        this.ngControl.control.valueChanges.subscribe(() => {
            if (this.ngControl.value !== null) {
                let numberValue = this.replace(this.ngControl.value);
                if (this.ngControl.value !== numberValue) {
                    this.ngControl.control.setValue(numberValue);
                }
            }
        });
    }

    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        input.value = this.replace(this.ngControl.value);
    }

    replace(value: any): string {
        return String(value).replace(/[^0-9\d*]/g, '');
    }

}


import { Injectable } from '@angular/core';
// Rxjs
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

interface SaveData {
    title: string,
    entityId: number,
    qcDate: string,
    lastDate: string,
    amendmentDate: string,
    releaseDate: string,
    amendState: number,
    updateType: number,
    requesReQc: boolean,
    entityName: string,
    contactsSlottedEmpty: boolean,
    entityStatus: string,
    allowMajor: boolean,
    hasMajorUpdateCheckbox: boolean,
    majorUpdateSelected: boolean,
}
interface Message {
  title: string,
  message: string,
  icon: string,
  display: boolean
}
@Injectable()
export class MessagesService {
  private MessageSubject$ = new BehaviorSubject<Message>(null);
  private MessageSubjectDialog$ = new BehaviorSubject<Message>(null);
  private SaveSubjectDialog$ = new BehaviorSubject<SaveData>(null);

  messageChanged$ = this.MessageSubject$.asObservable();
  messageDialogChanged$ = this.MessageSubjectDialog$.asObservable();
  saveDialogChanged$ = this.SaveSubjectDialog$.asObservable();

  constructor() { }

  initSaveSubjectDialog() {
    this.SaveSubjectDialog$.next(null);
  }

  openModalConfirm(title: string, message: string, icon: string) {
    const messageInfo: Message = {
      title,
      message,
      icon,
      display: true
    }
    this.MessageSubjectDialog$.next(messageInfo)
  }

  openMessageInfo(title: string, message: string ): void{
    const messageInfo: Message = {
      title,
      message,
      icon: '',
      display: true
    }
    this.MessageSubject$.next(messageInfo);
  }

  openSaveDialog(title: string,
                 entityId: number,
                 qcDate: string,
                 lastDate: string,
                 amendmentDate: string,
                 releaseDate: string,
                 amendState: number,
                 updateType: number,
                 requesReQc: boolean,
                 entityName: string,
                 entityStatus: string,
                 contactsSlottedEmpty: boolean = false,
                 allowMajor: boolean = false,
                 hasMajorUpdateCheckbox: boolean = false,
                 majorUpdateSelected: boolean = false,
                 ): void {
    const saveData: SaveData = {
      title,
      entityId,
      qcDate,
      lastDate,
      amendmentDate,
      releaseDate,
      amendState,
      updateType,
      requesReQc,
      entityName,
      contactsSlottedEmpty,
      entityStatus,
      allowMajor,
      hasMajorUpdateCheckbox,
      majorUpdateSelected,
    }
    this.SaveSubjectDialog$.next(saveData);
  }

}

<div class="container-modal">
    <p-messages></p-messages>
    <div class="Ex-content-modal">
        <div class="grid pt-1">
            <!-- Clear btn -->
            <div class="col-1 min-w-max">
                <button (click)="clear()" class="btn-custom">{{'plant.searchPlant.plantClear'| translate}}</button>
            </div>
            <!-- Search label + First radiobutton -->
            <div class="col-1 mt-1 flex justify-content-end min-w-max" *ngIf="!restrictedOnOffshore">
                <span class="px-0 font-bold text-xs">{{'plant.searchPlant.plantSearch'| translate}}: </span>
            </div>
            <div class="col-1 min-w-max">
                <p-radioButton *ngIf="!restrictedOnOffshore" name="filter" [(ngModel)]="searchParams.plantOffshore" value="0"
                        label="{{'plant.searchPlant.plantOnshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <!-- Second radiobutton -->
            <div class="col-3 min-w-max" *ngIf="!restrictedOnOffshore">
                <p-radioButton name="filter" [(ngModel)]="searchParams.plantOffshore" value="1"
                    label="{{'plant.searchPlant.plantOffshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-6"></div>
        </div>
        <form class="mt-2">
            <div class="grid">
                <div class="col-12 xl:col-6 xl:mt-2">
                    <!-- Owner Name -->
                    <div class="flex -mt-3 pt-2 align-items-center">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantOwName'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"  
                            name="ownerName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantOwnerId" 
                            #inputOwnerName 
                            pInputText  
                            maxlength="80" 
                            autofocus 
                            appFocusElement
                        />
                        <div class="col-3 px-0"></div>
                    </div>                    
                    <!-- Plant Name -->
                    <div class="flex -mt-3 pt-2 align-items-center">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantName'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"   
                            name="assetName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantName" 
                            #inputPlantName
                            pInputText  
                            maxlength="80"
                        />
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Plant Status -->
                    <div class="flex -mt-3 pt-2 align-items-center">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantStatus'| translate}}:</label>
                        <div class="col-6 px-1">
                            <p-listbox 
                                (keydown)="handleListboxKeydown($event)"
                                (keydown.tab)="inputIndustryCode.focus()"
                                emptyMessage=" " 
                                [listStyle]="{'height':'90px', 'width':'max','font-size': '12px', 'margin-left':'8px'}"
                                [(ngModel)]="searchParams.plantStatus" 
                                [multiple]="true" 
                                (keyup.enter)="search()" 
                                [options]="plantStatuses"
                                name="plantStatus" 
                                optionLabel="Description" 
                                optionValue="StatusID">
                            </p-listbox>
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Industry Code -->
                    <div class="flex -mt-3 pt-2 ">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantIndustryCode'| translate}}:</label>
                        <p-dropdown #inputIndustryCode
                            class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                            [options]="industryCodes" 
                            [filter]="true" 
                            [placeholder]="'common.selectOne' | translate" 
                            showClear="true"
                            filterBy="industryCode,industryDescription" 
                            resetFilterOnHide="true"
                            autofocusFilter="true" 
                            (keyup.enter)="search()" 
                            name="industryCode"
                            [(ngModel)]="searchParams.industryCode"
                            optionLabel="industryDescription" 
                            optionValue="industryCode">
                        </p-dropdown>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- SIC Code -->
                    <div class="flex -mt-3 pt-2 align-items-center">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantSicCode'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6 disabled-sic-code" 
                            type="text" 
                            name="sicCodeToShow" 
                            (keydown.enter)="search()" 
                            [(ngModel)]="searchParams.sicCodeToShow" 
                            pInputText  
                            maxlength="80"
                            readonly/>
                        <div class="col-3 px-0 ml-1">
                            <button 
                                pButton 
                                pRipple 
                                type="button" 
                                icon="pi pi-search"
                                class="icon-field-style pd p-button-info p-button-text pe-auto"
                                (click)="openSICCodeLookup()">
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 xl:col-6 -mt-4 xl:mt-0 pt-2">
                    <!-- Plant Country -->
                    <div class="flex">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantCountry'| translate}}:</label>
                        <p-dropdown  
                            class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                            [options]="physCountries" 
                            [filter]="true"
                            showClear="true" 
                            [placeholder]="'common.selectOne' | translate" 
                            filterBy="countryId,countryNameConcat" 
                            resetFilterOnHide="false"
                            autofocusFilter="true" 
                            (keyup.enter)="search()" 
                            (onChange)="onChangePhysCountry()"
                            name="physCountry" 
                            [(ngModel)]="searchParams.plantCountry"
                            optionLabel="countryNameConcat" 
                            optionValue="countryId">
                        </p-dropdown>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Plant State -->
                    <div class="flex -mt-3 pt-2"  *ngIf="searchParams.plantOffshore === '0'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantState'| translate}}:</label>
                        <p-dropdown  
                            class="form__container-fields-layout--selects col-6 flex px-1 w-4" 
                            [disabled]= "!searchParams.plantCountry ?? true"
                            [options]="physStates" 
                            [filter]="true"
                            showClear="true"
                            [placeholder]="'common.selectOne' | translate" 
                            filterBy="sId,stateConcat" 
                            resetFilterOnHide="true" 
                            autofocusFilter="false"
                            (keyup.enter)="search()" 
                            name="physState" 
                            [(ngModel)]="searchParams.plantState" 
                            optionLabel="stateConcat" 
                            optionValue="sId">
                        </p-dropdown>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Plant City -->
                    <div class="flex -mt-3 pt-2 align-items-center"  *ngIf="searchParams.plantOffshore === '0'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantCity'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"   
                            name="plantCityName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantCity" 
                            pInputText  
                            maxlength="80"
                        />
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Plant Country -->
                    <div class="flex -mt-3 pt-2 align-items-center"  *ngIf="searchParams.plantOffshore === '0'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantCounty'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6" 
                            type="text" 
                            name="plantCountyName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantCounty" 
                            pInputText  
                            maxlength="80"
                        />
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- PEC Zone -->
                    <div class="flex -mt-3 pt-2 align-items-center" *ngIf="searchParams.plantOffshore === '0'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.pecZone'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"  
                            name="pecZone" 
                            (keydown.enter)="onEnterPecZone($event)"
                            [(ngModel)]="searchParams.pecZone" 
                            pInputText  
                            maxlength="80"
                        />
                        <div class="col-3 px-0 ml-1">
                            <button 
                                pButton 
                                pRipple 
                                type="button" 
                                icon="pi pi-search"
                                class="icon-field-style pd p-button-info p-button-text pe-auto" 
                                (click)="openZoneSearchModal()">
                            </button>
                        </div>
                    </div>
                    <!-- Water Body (Offshore) -->
                    <div class="flex -mt-3 pt-2 "  *ngIf="searchParams.plantOffshore === '1'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantWaterBody'| translate}}:</label>
                        <p-dropdown  
                            class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                            [options]="waterBodies" 
                            [filter]="true"
                            showClear="true"
                            [placeholder]="'common.selectOne' | translate"  
                            filterBy="waterBodyId,waterBodyName" 
                            resetFilterOnHide="false" 
                            [disabled]="!searchParams.plantCountry ?? true"
                            autofocusFilter="true" 
                            (keyup.enter)="search()" 
                            (onChange)="onChangePhysWaterBody()"
                            name="plantWaterbodyId" 
                            [(ngModel)]="searchParams.plantWaterBody"
                            optionLabel="waterBodyNameConcat" 
                            optionValue="waterBodyId">
                        </p-dropdown>
                    </div>
                    <!-- Field Name (Offshore) -->
                    <div class="flex -mt-3 pt-2 align-items-center"  *ngIf="searchParams.plantOffshore === '1'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantField'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"   
                            name="plantFieldName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantFieldName" 
                            pInputText  
                            maxlength="80"
                        />
                    </div>
                    <!-- Area (Offshore) -->
                    <div class="flex -mt-3 pt-2 align-items-center"  *ngIf="searchParams.plantOffshore === '1'">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantArea'| translate}}:</label>
                        <input 
                            class="col-6 mx-1 form__input-field-style w-6"  
                            type="text"   
                            name="plantAreaName" 
                            (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantArea" 
                            pInputText  
                            maxlength="80"
                        />
                    </div>
                    <!-- Record Status -->
                    <div class="flex -mt-3 pt-2 ">
                        <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'plant.searchPlant.plantRecStatus'| translate}}:</label>
                        <p-dropdown 
                            class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                            [options]="recordStatuses" 
                            [filter]="true"
                            [placeholder]="'common.selectOne' | translate" 
                            showClear="true" 
                            filterBy="RecordStatusID,RecordStatusDescription" 
                            resetFilterOnHide="true"
                            autofocusFilter="true" 
                            (keyup.enter)="search()" 
                            name="recordStatus"
                            [(ngModel)]="searchParams.plantRecordStatus" 
                            optionLabel="RecordStatusDescription"
                            optionValue="RecordStatusID">
                        </p-dropdown>
                        <div class="col-3 px-0"></div>
                    </div>
                </div>
            </div>
        </form>   
    </div>
    <hr class="hr-modal">
    <!-- Mouse & keyboard user info -->
    <div class="grid main-content pt-1 md:pt-0 lg:pt-0 xl:pt-0">
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'| translate}}</span>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}: </strong>{{'common.searchKeyboardUsers'| translate}}</span>
            </div>
    </div>
    <!-- Data table -->
    <div class="container-table-modal">
        <p-table 
            #searchTable ignoreHeaders
            *ngIf="searchParams.plantOffshore === '0'" 
            [value]="plants" 
            selectionMode="single" 
            [rows]="rowsPerPage"
            (onPage)="onPageChange($event)"
            [showCurrentPageReport]="true" 
            [columns]="colsOnshore" 
            [resizableColumns]="true"
            styleClass="p-datatable-gridlines" 
            [reorderableColumns]="true" 
            (sortFunction)="customSort($event)"
            [customSort]="true" 
            [rowsPerPageOptions]="rowsPerPageOptions" 
            [paginator]="true" 
            [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns tabindex="-1">
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, plants)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}} pl-2" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable">
                            <div *ngIf="!col.isDate">{{rowData[col.field]}}</div>
                            <div *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd-MMM-y'}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-table #searchTable ignoreHeaders *ngIf="searchParams.plantOffshore === '1'" [value]="plants" selectionMode="single" [rows]="rowsPerPage"
            [showCurrentPageReport]="true" [columns]="colsOffshore" [resizableColumns]="true"
            styleClass="p-datatable-gridlines" [reorderableColumns]="true" (sortFunction)="customSort($event)"
            [customSort]="true" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, plants)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}}" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable">
                            <div *ngIf="!col.isDate">{{rowData[col.field]}}</div>
                            <div *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd-MMM-y'}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!-- Design table -->
    <div class="container-info-modal main-content">
        <div class="grid card-modal mt-2">
            <div class="col-12 header-info">
                <span>{{'additional_info'| translate}}:</span>
            </div>
            
            <div class="col-12 lg:col-6 xl:col-6">
                <div class="content-card" *ngIf="searchParams.plantOffshore !== '1'">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.physAddress' | translate}}:</label>
                    <label for="" class="custom-label-card-info"
                    *ngIf="additionalInfo?.physAddress"
                    [attr.changeCard]="additionalInfo?.physAddress?.length > 70 ? true : null"
                    >{{ additionalInfo?.physAddress }}</label>
                </div>
                <div class="content-card" *ngIf="searchParams.plantOffshore === '1'">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.quadrantBlock' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.quadrantBlock }}</label>
                </div>
                <div class="content-card" >
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.mailAddress' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.mailAddress }}</label>
                </div>
                <div class="content-card" >
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.telephone' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.phone }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.faxNumber' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.fax }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.qcDate' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'dd-MMM-y'}}</label>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-6">
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.parentCompany' | translate}}:</label>
                    <label for="" class="custom-label-card-info"
                    >{{ additionalInfo?.parentCompanyName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.operator' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.operatorName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'common.recordStatus' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.recordStatusDesc }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'plant.additionalInfo.noEmployees' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.noEmployees }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short"></label>
                    <label for="" class="custom-label-card-info"></label>
                </div>
            </div>
        </div>

        <div *ngIf="isMainAccess" class="g-row main-content mt-1">
            <div class="col-12 text-right">
                <label class="custom-label">{{'plant.searchPlant.plantInfoSearch'| translate}}
                    <strong> {{'plant.searchPlant.plantNotDuplicate'| translate}}</strong>
                </label>
                <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                    class="btn-custom ml" height="34px;" label="Create New Record"
                    (click)="newRecord()">{{'plant.searchPlant.plantNewRecordButton'| translate}}
                </button>
            </div>
        </div>
    </div>
</div>

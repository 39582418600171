import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { PreferencePrefix, PreferenceSufix } from 'src/app/core/models/constants';

@Component({
    selector: 'app-relatedinfo-base',
    templateUrl: './relatedinfo-base.component.html',
    styleUrls: ['./relatedinfo-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoBaseComponent extends RelatedInfoBase implements OnInit {
    @Input() i18nBase: string;
    title: string;
    notFound: string;
    addButton: string;
    defaultRowsPerPage: number = 5;
    
    constructor(
        private translate: TranslateService,
        public sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();

        this.title = this.translate.instant(`${this.i18nBase}.title`);
        this.notFound = this.translate.instant(`${this.i18nBase}.notFound`);
        this.addButton = this.translate.instant(`${this.i18nBase}.addButton`);

        if(this.addRecordFunction == null) {
            this.addRecordFunction = this.addEntityFunction;
        }
    }
    
}

import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { EntityName } from "../../enumerations/entity-name";
import { OperationType } from "../../enumerations/operation-type";
import { Drives } from "../save/drives";
import { TurbinesFuelDisplay } from "./turbines-fuel-display";

export class DrivesDisplay extends Drives {
    plantId: number;
    areaId: string;
    ownerId: number;
    ownerName: string;
    unitName: string;
    unitType: string;
    unitTypeName: string;
    unitStartupDate: string;
    unitStartUpResolution: string;
    unitShutDownDate: string;
    unitShutDownResolution: string;
    unitStatus: string;
    unitStatusDesc: string;
    unitCategoryId: string;
    plantName: string;
    areaName: string;
    physAddressV1: string;
    physAddressV2: string;
    manufacturerName: string;
    prevManufacturerName: string;
    generatorManufacturerName: string;
    designFirmName: string;
    constructorFirmName: string;
    phoneNo: string;
    phoneNoV1: string;
    aecFirmName: string;
    physCity: string;
    physState: string;
    physCountry: string;
    physCountryName: string;
    physPostalCode: string;
    plantOffshore: boolean;
    offshoreBlockV1: string;
    offshoreWaterbodyName: string;
    offshoreFieldName: string;
    offshoreAreaName: string;
    typeDescription: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    lastUpdateStatus: string;
    manufacturerIdLiveData: number;
    modelLiveData: string;
    genManufacturerIdLiveData: number;
    prevManufacturerIdLiveData: number;
    seriesLiveData: string;
    genModelLiveData: string;

    // MVTs
    turbinesFuelDisplay: Array<TurbinesFuelDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.plantId = entity?.plantId ?? null;
        this.areaId = entity?.areaId ?? null;
        this.ownerId = entity?.ownerId ?? null;
        this.ownerName = entity?.ownerName ?? null;
        this.unitName = entity?.unitName ?? null;
        this.unitType = entity?.unitType ?? null;
        this.unitTypeName = entity?.unitTypeName ?? null;
        this.unitStartupDate = entity?.unitStartupDate ?? null;
        this.unitStartUpResolution = entity?.unitStartUpResolution ?? null;
        this.unitShutDownDate = entity?.unitShutDownDate ?? null;
        this.unitShutDownResolution = entity?.unitShutDownResolution ?? null;
        this.unitStatus = entity?.unitStatus ?? null;
        this.unitStatusDesc = entity?.unitStatusDesc ?? null;
        this.unitCategoryId = entity?.unitCategoryId ?? null;
        this.plantName = entity?.plantName ?? null;
        this.areaName = entity?.areaName ?? null;
        this.physAddressV1 = entity?.physAddressV1 ?? null;
        this.physAddressV2 = entity?.physAddressV2 ?? null;
        this.manufacturerName = entity?.manufacturerName ?? null;
        this.prevManufacturerName = entity?.prevManufacturerName ?? null;
        this.generatorManufacturerName = entity?.generatorManufacturerName ?? null;
        this.designFirmName = entity?.designFirmName ?? null;
        this.constructorFirmName = entity?.constructorFirmName ?? null;
        this.phoneNo = entity?.phoneNo ?? null;
        this.phoneNoV1 = entity?.phoneNoV1 ?? null;
        this.aecFirmName = entity?.aecFirmName ?? null;
        this.physCity = entity?.physCity ?? null;
        this.physState = entity?.physState ?? null;
        this.physCountry = entity?.physCountry ?? null;
        this.physCountryName = entity?.physCountryName ?? null;
        this.physPostalCode = entity?.physPostalCode ?? null;
        this.plantOffshore = entity?.plantOffshore ?? false;
        this.offshoreBlockV1 = entity?.offshoreBlockV1 ?? null;
        this.offshoreWaterbodyName = entity?.offshoreWaterbodyName ?? null;
        this.offshoreFieldName = entity?.offshoreFieldName ?? null;
        this.offshoreAreaName = entity?.offshoreAreaName ?? null;
        this.typeDescription = entity?.typeDescription ?? null;
        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
        this.lastUpdateStatus = entity?.lastUpdateStatus ?? '';
        this.manufacturerIdLiveData = entity?.manufacturerIdLiveData ?? null;
        this.modelLiveData = entity?.modelLiveData ?? null;
        this.genManufacturerIdLiveData = entity?.genManufacturerIdLiveData ?? null;
        this.prevManufacturerIdLiveData = entity?.prevManufacturerIdLiveData ?? null;
        this.seriesLiveData = entity?.seriesLiveData ?? null;
        this.genModelLiveData = entity?.genModelLiveData ?? null;
        this.turbinesFuelDisplay = entity?.turbinesFuelDisplay ?? [];
        
    }

    static BuildDriveDisplay(entity: any): DrivesDisplay {
        return new DrivesDisplay(entity);
    }

    static BuildNewDrive(response: any): DrivesDisplay {
        return new DrivesDisplay({
            turbineId: response[0].TURBINE_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    prepareSaveCollection() {
        this.turbinesFuel = this.turbinesFuelDisplay;
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: this.lastUpdateStatus,
            entityName: EntityName.TURBINE,
            entityId: this.turbineId
        };
    }
    
}

import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { PlantsProductInfoDisplay } from 'src/app/core/models/plant/display/plants-product-info-display';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { IdFieldType } from 'src/app/shared/components/base/look-up-modal/look-up-modal.component';

@Component({
    selector: 'app-product-info-modal',
    templateUrl: './product-info-modal.component.html',
    styleUrls: ['./product-info-modal.component.scss']
})
export class ProductInfoModalComponent implements OnInit {

    entitiesArray:any[];
    products: Array<PlantsProductInfoDisplay>;
    public get IdFieldType(): typeof IdFieldType {
        return IdFieldType;
    }

    constructor(
        public config: DynamicDialogConfig) {
    }

    ngOnInit(): void {
        this.entitiesArray = this.config.data.entitiesArray;
        this.products = MVTOperations.filterByTemporalRow(this.config.data.products);
    }

    searchEntityDelegate(implementedComponent: ProductInfoModalComponent, inputEntityId: string, inputEntityName: string):Observable<PlantsProductInfoDisplay[]> {
        return of(implementedComponent.products);
    }

    displayValueFunc(implementedComponent: any, rowData: PlantsProductInfoDisplay): string {
        let displayText:string = StringUtils.toStringNeverNull(rowData.hsProduct);
        if(!StringUtils.isEmpty(displayText)) {
            displayText += " - ";
        }
        displayText += StringUtils.toStringNeverNull(rowData.productDesc);
        
        return displayText;
    }

}

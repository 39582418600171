import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";
import { AssetsPipesConnections } from "./assets-pipes-connections";
import { AssetsPipesLaterals } from "./assets-pipes-laterals";

export class AssetsPipes extends Bean {
    assetPipesId: number;
    assetId: number;
    pipelineSystemId: number;
    parentId: number;
    diameterIn: number;
    diameterOut: number;
    diameterUom: string;
    destinationPhysAddressV1: string;
    destinationPhysAddressV2: string;
    destinationPhysCity: number;
    destinationPhysState: number;
    destinationPhysPostalCode: string;
    destinationPhysCounty: number;
    destinationPhysCountry: number;
    destinationOffshore: boolean;
    destOffshoreStateDesignation: number;
    destinationLatitude: number;
    destinationLongitude: number;
    destinationAssetId: number;
    destinationAssetTypeId: number;
    destinationOffshoreBlockV1: string;
    destinationOffshoreBlockV2: string;
    destinationOffshoreWaterbody: number;
    destinationOffshoreFieldId: number;
    destinationOffshoreAreaId: number;
    aboveGround: string;
    belowGround: string;
    subsea: string;
    pipelineType: string;
    pipelineLength: number;
    pipelineLengthUom: string;
    interIntraState: string;
    pipelineStatus: string;
    materialUsed: string;
    designPresurePsi: number;
    insulated: string;

    asset: Assets;
    assetsPipesConnections: Array<AssetsPipesConnections>;
    assetsPipesLaterals: Array<AssetsPipesLaterals>;

    constructor(entity: any) {
        super(entity);

        this.assetPipesId = entity?.assetPipesId ?? null;
        this.assetId = entity?.assetId ?? null;
        this.pipelineSystemId = entity?.pipelineSystemId ?? null;
        this.parentId = entity?.parentId ?? null;
        this.diameterIn = entity?.diameterIn ?? null;
        this.diameterOut = entity?.diameterOut ?? null;
        this.diameterUom = entity?.diameterUom ?? null;
        this.destinationPhysAddressV1 = entity?.destinationPhysAddressV1 ?? null;
        this.destinationPhysAddressV2 = entity?.destinationPhysAddressV2 ?? null;
        this.destinationPhysCity = entity?.destinationPhysCity ?? null;
        this.destinationPhysState = entity?.destinationPhysState ?? null;
        this.destinationPhysPostalCode = entity?.destinationPhysPostalCode ?? null;
        this.destinationPhysCounty = entity?.destinationPhysCounty ?? null;
        this.destinationPhysCountry = entity?.destinationPhysCountry ?? null;
        this.destinationOffshore = entity?.destinationOffshore ?? false;
        this.destOffshoreStateDesignation = entity?.destOffshoreStateDesignation ?? null;
        this.destinationLatitude = entity?.destinationLatitude ?? null;
        this.destinationLongitude = entity?.destinationLongitude ?? null;
        this.destinationAssetId = entity?.destinationAssetId ?? null;
        this.destinationAssetTypeId = entity?.destinationAssetTypeId ?? null;
        this.destinationOffshoreBlockV1 = entity?.destinationOffshoreBlockV1 ?? null;
        this.destinationOffshoreBlockV2 = entity?.destinationOffshoreBlockV2 ?? null;
        this.destinationOffshoreWaterbody = entity?.destinationOffshoreWaterbody ?? null;
        this.destinationOffshoreFieldId = entity?.destinationOffshoreFieldId ?? null;
        this.destinationOffshoreAreaId = entity?.destinationOffshoreAreaId ?? null;
        this.aboveGround = entity?.aboveGround ?? null;
        this.belowGround = entity?.belowGround ?? null;
        this.subsea = entity?.subsea ?? null;
        this.pipelineType = entity?.pipelineType ?? null;
        this.pipelineLength = entity?.pipelineLength ?? null;
        this.pipelineLengthUom = entity?.pipelineLengthUom ?? null;
        this.interIntraState = entity?.interIntraState ?? null;
        this.pipelineStatus = entity?.pipelineStatus ?? null;
        this.materialUsed = entity?.materialUsed ?? null;
        this.designPresurePsi = entity?.designPresurePsi ?? null;
        this.insulated = entity?.insulated ?? null;

        this.asset = entity?.asset ?? null;
        this.assetsPipesConnections = entity?.assetsPipesConnections ?? [];
        this.assetsPipesLaterals = entity?.assetsPipesLaterals ?? [];
    }
}

import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-last-prev-data',
    templateUrl: './last-prev-data.component.html',
    styleUrls: ['./last-prev-data.component.css']
})
export class LastPrevDataComponent implements OnInit, OnChanges {

    @Input() lastUser: string;
    @Input() lastDate: string;
    @Input() prevUser: string;
    @Input() prevDate: string;
    @Input() energyProducer: boolean = true;
    @Output() visibleQC: EventEmitter<boolean> = new EventEmitter();

    lastPrevLabel: string;
    shownUser: string = '';
    shownDate: string = '';
    readonly LAST_VALUE: string;
    readonly PREV_VALUE: string;

    constructor(private translate: TranslateService) {
        this.LAST_VALUE = this.translate.instant('common.last');
        this.PREV_VALUE = this.translate.instant('common.prev');
    }

    ngOnInit(): void {
        this.initComponent();
    }

    changeEvent() {
        if (this.lastPrevLabel === this.LAST_VALUE) {
            this.lastPrevLabel = this.PREV_VALUE;
            this.shownUser = this.prevUser;
            this.shownDate = this.prevDate;
            this.visibleQC.emit(false);
        }
        else {
            this.lastPrevLabel = this.LAST_VALUE;;
            this.shownUser = this.lastUser;
            this.shownDate = this.lastDate;
            this.visibleQC.emit(true);
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        this.initComponent();
        this.shownUser = this.lastUser;
        this.shownDate = this.lastDate;

    }

    initComponent() {
        this.lastPrevLabel = this.lastUser === null ? '' : this.LAST_VALUE;
    }

}
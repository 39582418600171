import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-load-wheel',
  templateUrl: './load-wheel.component.html',
  styleUrls: ['./load-wheel.component.css']
})

export class LoadWheelComponent implements OnInit, OnDestroy {
  public showSpinner: boolean = true;
  public showFullScreen: boolean = false;
  public label: string;

  constructor() { 
  }

  ngOnInit(): void {
    if(this.showFullScreen){
      window.addEventListener('keydown', this.stopKeyEvent, true);
    }
  }



  stopKeyEvent = (event) => {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('keydown', this.stopKeyEvent, true);
  }

}

import { Bean } from "../../bean";

export class AreasPhoneInfo extends Bean {
    areaId: number;
    phoneCc: string;
    phoneNo: string;
    phoneExt: string;
    phoneMobile:string;
    mvOrder: number;
    mvId: string;
    lastDate: string;    

    protected constructor(entity?: any) {
        super(entity);

        this.areaId = entity?.areaId ?? null;
        this.phoneCc = entity?.phoneCc ?? null;
        this.phoneNo = entity?.phoneNo ?? null;
        this.phoneExt = entity?.phoneExt ?? null;
        this.phoneMobile = entity?.phoneMobile ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.mvId = entity?.mvId ?? null;
        this.lastDate = entity?.lastDate ?? null;
    }

}
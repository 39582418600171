import {Injectable} from "@angular/core";
import {Adapter} from "../../adapter";

export class CompanySearch {
    constructor(
      public rN: number,
      public companyId: number,
      public companyName: string,
      public secondaryName: string,
      public previousName: string,
      public classification: string,
      public classificationDesc: string,
      public companyStatus: string,
      public companyStatusDesc: string,
      public phoneNo: string,
      public phoneCc: number,
      public phoneExt: string,
      public phoneMobile: string,
      public faxNo: string,
      public faxCc: number,
      public faxExt: string,
      public recordStatus: string,
      public recordStatusDesc: string,
      public mailAddressV1: string,
      public mailAddressV2: string,
      public mailCity: string,
      public mailState: string,
      public physAddressV1: string,
      public physCity: string,
      public physAddressV2: string,
      public physStateSId: number,
      public physState: string,
      public physPostalCode: string,
      public physCountry: string,
      public phone: string,
      public webSite: string,
      public stockSymbol: string,
      public qcDate: string,
      public physCountryCId: number,
      public physCityCiId: number,
      public physCountyCoId: number,
      public countyName: string,
      public latitude: string,
      public longitude: string,
      public mailPostalCode: string,
      public mailCountryCId: number,
      public mailStateSId: number,
      public mailCityCiId: number,
      public fax: string,
      public mailCountry: string,
      public mailCountryName: string,
      public physCountryDesc: string,
      public physStateDesc: string,
      public mailStateDesc: string,
      public pecZoneSimplex: string,
      public completeName?:string
    ) { }
}


 @Injectable({
     providedIn: 'root',
 })
 export class CompanySearchAdapter implements Adapter<CompanySearch> {
     adapt(item: any): CompanySearch {
         return new CompanySearch(
              item.RN,
              item.COMPANY_ID,
              item.COMPANY_NAME,
              item.SECONDARY_NAME,
              item.PREVIOUS_NAME,
              item.CLASSIFICATION,
              item.CLASSIFICATION_DESC,
              item.COMPANY_STATUS,
              item.COMPANY_STATUS_DESC,
              item.PHONE_NO,
              item.PHONE_CC,
              item.PHONE_EXT,
              item.PHONE_MOBILE,
              item.FAX_NO,
              item.FAX_CC,
              item.FAX_EXT,
              item.RECORD_STATUS,
              item.RECORD_STATUS_DESC,
              item.MAIL_ADDRESS_V1,
              item.MAIL_ADDRESS_V2,
              item.MAIL_CITY,
              item.MAIL_STATE,
              item.PHYS_ADDRESS_V1,
              item.PHYS_CITY,
              item.PHYS_ADDRESS_V2,
              item.PHYS_STATE_S_ID,
              item.PHYS_STATE,
              item.PHYS_POSTAL_CODE,
              item.PHYS_COUNTRY,
              item.PHONE,
              item.WEB_SITE,
              item.STOCK_SYMBOL,
              item.QC_DATE,
              item.PHYS_COUNTRY_C_ID,
              item.PHYS_CITY_CI_ID,
              item.PHYS_COUNTY_CO_ID,
              item.COUNTY_NAME,
              item.LATITUDE,
              item.LONGITUDE,
              item.MAIL_POSTAL_CODE,
              item.MAIL_COUNTRY_C_ID,
              item.MAIL_STATE_S_ID,
              item.MAIL_CITY_CI_ID,
              item.FAX,
              item.MAIL_COUNTRY,
              item.MAIL_COUNTRY_NAME ?? item.MAIL_COUNTRY_DESC,
              item.PHYS_COUNTRY_DESC,
              item.PHYS_STATE_DESC,
              item.MAIL_STATE_DESC,
              item.PEC_ZONE_SIMPLEX
             );
     }
 }


<div class="main-content">
    <div class="grid">
        <div class="col-12">
            <label class="font-bold text-xs mt-1">{{'alerts.alertModal.subject'| translate}}:</label>

        </div>
        <div class="col-12 -mt-2">
            <input
            class="form__input-field-style w-full" 
            type="text"
            maxlength="100"
            name="subject" 
            [disabled]="disabledElements"
            [(ngModel)]="subject" 
            pInputText appFocusElement autofocus
        />
        </div>
        <div class="col-12">
            <label class="font-bold text-xs">{{'alerts.alertModal.comment'| translate}}:</label>
        </div>
        <div class="col-12 -mt-2 alert-comment" >
            <textarea [rows]="5" 
                [cols]="30"  
                class="w-full text-xs"
                autoResize="true"
                maxlength="2000"
                pInputTextarea  
                [disabled]="disabledElements"
                [(ngModel)]="comments" >
            </textarea>
        </div>
        <div class="col-12 mt-2">
            <div class="flex justify-content-center">
                <button class="btn-row" 
                [disabled]="disabledElements"
                (click)="saveAlert()">{{'common.save'|translate}}</button>
                <button class="btn-row ml-3" 
                (click)="closeModal()">{{'common.cancel'|translate}}</button>
            </div>
        </div>
    </div>
</div>
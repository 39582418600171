import { DomHandler } from 'primeng/dom';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';

const originalDisableModality = DynamicDialogComponent.prototype.disableModality;

DynamicDialogComponent.prototype.disableModality = () => {
    const bodyHasOverflowHiddenClass = DomHandler.hasClass(document.body, 'p-overflow-hidden');

    originalDisableModality.apply(this, []);

    setTimeout(() => {
        const dialogCount = getDialogCount();
        if(bodyHasOverflowHiddenClass && dialogCount > 0) {
            DomHandler.addClass(document.body, 'p-overflow-hidden');
        } else {
            DomHandler.removeClass(document.body, 'p-overflow-hidden');
        }
    }, 0);
};

const getDialogCount = (): number => {
    const dynamicDialogs = document.querySelectorAll('p-dynamicdialog');
    return dynamicDialogs?.length;
}
export enum Role {
    SUPER_ADMIN = 'superAdmin',
    MANAGE_AEC = 'manageAEC',
    MANAGE_MFG = 'manageMFG',
    MANAGE_SERV_CONTR = 'manageServiceContractor',
    MANAGE_CRUDE_SLATE = 'manageCrudeSlate',
    MANAGE_KV_RATINGS = 'manageKVRating',
    MANAGE_TRANSMISSION_SYSTEM = 'manageTransmissionSystem',
    MANAGE_PIPELINE_SYSTEM = 'managePipelineSystem',
    MANAGE_DRIVEN_EQUIPMENT = 'manageDrivenEquipment',
    MANAGE_EQUIPMENT = 'manageEquipmentTypes',
    MANAGE_HS_PRODUCT = 'manageHSProduct',
    MANAGE_GEOGRAPHY = 'manageGeography',
    MANAGE_PIPELINE_METER = 'managePipelineMeter',
    EDIS_ADMIN = 'edisAdmin',
    EDIS_ACCESS_ADMIN = 'edisAccessAdmin',
    EDIS_ALERT_PLANT = 'edisAlertingPlant',
    EDIS_ALERT_OFFLINE_EVENT = 'edisAlertingOutage',
    EDIS_ALERT_REGION = 'edAlertingRegion',
    EDIS_ALERT_ALL = 'edisAlertingAll',
    INDUSTRY_MANAGER = 'IndustryManager',
    OUTAGE_MANAGER = 'OutageManager',
    RELEASE_RIGHTS = 'releaseRights',
    RESEARCH_EDITORIAL = 'researchEditorial',
    ALLOW_AMENDMENTS = 'allowAmendments',
    UNRESTRICTED_COMPANY = 'unrestrictedCompany',
    UNRESTRICTED_PEC_ACTIVITY = 'unrestrictedPecActivity',
    CLEAR_LOCKS = "clearLocks",
    SPELL_CHECK_ADD_WORD = "spellcheckEditWord",
}

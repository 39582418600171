<div class="px-4 h-24rem">
    <div class="grid">
        <div class="col-12 flex">
            <div class="col-3 flex justify-content-end">
                <label class="text-xs mt-1">{{'waterbody.country'|translate}}:</label>
            </div>
            <div class="col-6 -ml-2">
                <p-dropdown
                    class="form__container-fields-layout--selects"
                    [options]="countriesByWaterBody"
                    [(ngModel)]="countryByWaterBody"
                    name="countryWaterBody"
                    [placeholder]="'common.selectOne' | translate"
                    showClear="true"
                    optionLabel="countryNameConcat"
                    (onChange)="changeCountry(countryByWaterBody)"
                    appFocusElement
                >
                </p-dropdown>
            </div>
            <div class="col-3">
                <button class="btn-row btn-up"
                    (click)="loadWaterBodyByParams(countryByWaterBody ? countryByWaterBody.countryId : '', stateByCountry ? stateByCountry.sId : '', '', waterBodyName)"
                >{{'common.search'|translate}}</button>
            </div>
        </div>
        <div class="col-12 flex -mt-4">
            <div class="col-3 flex justify-content-end">
                <label class="text-xs mt-1">{{'waterbody.state'|translate}}:</label>
            </div>
            <div class="col-6 -ml-2">
                <p-dropdown
                    class="form__container-fields-layout--selects"
                    [options]="statesByCountry"
                    [(ngModel)]="stateByCountry"
                    name="stateWaterBody"
                    [placeholder]="'common.selectOne' | translate"
                    showClear="true"
                    optionLabel="stateNameConcat"
                    [disabled]="selectDisable"
                >
                </p-dropdown>
            </div>
            <div class="col-3">
                <button class="btn-row" (click)="clear()"
                >{{'common.clear'|translate}}</button>
            </div>
        </div>
        <div class="col-12 flex -mt-4">
            <div class="col-3 flex justify-content-end">
                <label class="text-xs font-bold mt-1">{{'waterbody.waterbodyName'|translate}}:</label>
            </div>
            <div class="col-9 -ml-2">
                <input
                    type="text"
                    pInputText
                    class="p-inputtext-sm"
                    [(ngModel)]="waterBodyName"
                    (keyup.enter)="loadWaterBodyByParams(countryByWaterBody ? countryByWaterBody.countryId : '', stateByCountry ? stateByCountry.sId : '', '', waterBodyName)"
                >
            </div>
        </div>
    </div>

    <div class="border-1 border-500">
        <div class="col-12 custom-scrollable-table-modal">
                <p-table
                    [value]="waterBodies"
                    [style]="{'width':'100%'}" [reorderableColumns]="true"
                    [resizableColumns]="true"
                    selectionMode="single"
                    (onRowSelect)="onRowSelect($event)"
                    [(selection)]="this.selection">
                    <ng-template pTemplate="body" let-item>
                        <tr #tr 
                            [pSelectableRow]="item" 
                            (dblclick)="onRowDblclick(item)"
                            (keyup.enter)="onRowDblclick(item)"
                            (keydown)="changeSelectionOnArrowKey($event, waterBodies, false)">
                            <td>{{item.waterBodyId}}</td>
                            <td>{{item.waterBodyName}}</td>
                        </tr>
                    </ng-template>
                </p-table>
        </div>
    </div>
    <div class="text-center mt-2">
        <button class="btn-row" (click)="addRowSingleClick()" tabindex="11">{{'save'| translate}}</button>
        <button class="btn-row ml-2" (click)="closeModal()" tabindex="12">{{'cancel'| translate}}</button>
    </div>
</div>

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "primeng/dynamicdialog";
import { EditableColumn } from "primeng/table";
import { Subject, Subscription } from "rxjs";
import { MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { SearchProjectComponent } from "src/app/components/modals/search-project/search-project.component";
import { FocusableEvent, FocusableEventType } from "src/app/core/directives/focusable-table.directive";
import { AssetsProjectsDisplay } from "src/app/core/models/asset/base/display/assets-projects-display";
import { OperationType } from "src/app/core/models/enumerations/operation-type";
import { ProjectSearch } from "src/app/core/models/search/project-search";
import { MVTOperations } from "src/app/core/mvt-operations";
import { SharedService } from "src/app/core/services/shared.service";
import { TableUtils } from "src/app/core/utils/table-utils";
import { ProjectSectionService } from "src/app/project-section/project-section.service";
import { HeaderService } from "src/app/shared/header/header.service";

@Component({
    selector: 'app-assets-project',
    templateUrl: './assets-project.component.html',
    styleUrls: ['./assets-project.component.scss']
})

export class AssetsProjectComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() projects: Array<AssetsProjectsDisplay>;
    @Input() entityId: number;
    @Input() entityForm: FormGroup;
    @Input() editable: boolean = false;
    @Input() titleKey: string;
    @Input() disabledButton: boolean = false;
    @Input() baseIndex: number;
    @ViewChildren(EditableColumn) private editableColumns: QueryList<EditableColumn>
    @Output() projectChanges: EventEmitter<Array<AssetsProjectsDisplay>> = new EventEmitter();
    cols: any[] = [];
    formGroup: FormGroup;
    projectsToShow: Array<AssetsProjectsDisplay> = [];
    tableEditEnabled: boolean = true;
    disableFormSub: Subscription;
    isEditing: boolean = false;

    projectsFocusManager = new Subject<FocusableEvent>();

    constructor(
        public dialogService: DialogService,
        private messageHandler: MessageHandlerService,
        private translate: TranslateService,
        private headerService: HeaderService,
        private sharedService: SharedService,
        private projectService: ProjectSectionService
    ) { }

    ngOnInit(): void {
        this.cols = [
            { draggable: true, width: '5%' },
            { field: 'projectId', header: this.translate.instant('asset.projects.projectId'), editable: this.editable, width: '10%', isDate: false, isAmount: false },
            { field: 'projectName', header: this.translate.instant('asset.projects.projectName'), editable: false, width: '30%', isDate: false, isAmount: false },
            { field: 'projectTiv', header: this.translate.instant('asset.projects.tiv'), editable: false, width: '15%', isDate: false, isAmount: true },
            { field: 'projectKickoffDate', header: this.translate.instant('asset.projects.kickoffDate'), editable: false, width: '10%', isDate: false, isAmount: false },
            { field: 'projectCompletionDate', header: this.translate.instant('asset.projects.completionDate'), editable: false, width: '10%', isDate: true, isAmount: false },
            { field: 'projectStatus', header: this.translate.instant('asset.projects.status'), editable: false, width: '15%', isDate: false, isAmount: false },
            { field: 'delete', delete: true, width: '5%' }
        ];
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledButton = this.disabledButton || disable;
            this.tableEditEnabled = !disable;
        });
    }

    ngAfterViewInit(): void {
        this.projectsFocusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.editableColumns));
    }

    projectsFiltered(): Array<AssetsProjectsDisplay> {
        if (this.projects) {
            this.projectsToShow = MVTOperations.sortByMvOrder(this.projects, 'asc');
            this.projectsToShow = MVTOperations.filterByDeleted(this.projects);
        }
        return this.projectsToShow;
    }

    deleteProject(row: AssetsProjectsDisplay) {
        this.headerService.changeForm = true;
        MVTOperations.removeItem(this.projects, 'projectId', row.projectId !== null ? String(row.projectId) : null);
        MVTOperations.arrangeMvOrder(this.projects);
        this.projects = MVTOperations.sortByMvOrder(this.projects);
    }

    hasIncompleteRow(): boolean {
        const notDeletedProjects = MVTOperations.filterByDeleted(this.projects) ?? [];
        return notDeletedProjects.filter(el => el.projectId === null || String(el.projectId).trim() === ''
            || el.assetId === null || String(el.assetId).trim() === '').length > 0;
    }

    addRow() {
        const emptyTemporalObject: boolean = this.projects?.filter(p => p.getOperation() === OperationType.TEMPORAL)?.length > 0;
        if (!emptyTemporalObject) {
            const tmpProject = AssetsProjectsDisplay.CreateInstance({
                assetId: this.entityId,
                mvOrder: MVTOperations.getLastMvOrder(this.projects) + 1,
            });
            tmpProject.setOperation(OperationType.TEMPORAL);
            this.projects.push(tmpProject);
            this.projectChanges.emit(this.projects);
            this.projectsFocusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_LAST_ROW));// Set focus to next element here!
        }
    }

    openProjectModal(row: AssetsProjectsDisplay, rowIndex?: number) {
        const modalRef = this.dialogService.open(SearchProjectComponent, {
            header: this.translate.instant('project.searchProject.title'),
            width: '90rem',
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((projectSearch: ProjectSearch) => {
            this.validateAndSetProjectData(projectSearch, row, rowIndex);
        });
    }

    onProjectIdEdition(row: AssetsProjectsDisplay, rowIndex?: number): void {
        if (String(row.projectId) !== String(row.previousProjectId)) {
            if (row.projectId) {
                this.projectService.getProjectById(row.projectId)
                    .subscribe((projectSearch: ProjectSearch) => {
                        if (projectSearch === null) {
                            this.projectNotExists(row, rowIndex);
                        } else {
                            this.validateAndSetProjectData(projectSearch, row, rowIndex);
                        }
                    }, (error: any) => {
                        this.projectNotExists(row, rowIndex);
                    });
            } else {
                this.clearRow(row);
            }
        }
    }

    projectNotExists(row: AssetsProjectsDisplay, rowIndex: number): void {
        this.messageHandler.show(this.translate.instant('project.projectNotExists'), MessageType.INFO, () => this.setFocusToId(rowIndex));
        this.clearRow(row);
    }

    validateAndSetProjectData(projectSearch: ProjectSearch,
        row: AssetsProjectsDisplay,
        rowIndex?: number): void {
        let projectID: number = Number(projectSearch.projectID);
        if (this.projectsToShow.some(el => el.projectId === Number(projectSearch.projectID) && el.mvOrder !== row.mvOrder)) {
            this.messageHandler.show(projectID + ' ' + this.translate.instant('common.alreadyExists'), MessageType.WARNING, () => this.setFocusToId(rowIndex));
            row.projectId = row.previousProjectId;
            return;
        }

        if (projectSearch && projectSearch.projectID) {
            row.setOperation(row.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
            row.projectId = Number(projectSearch.projectID);
            row.previousProjectId = row.projectId;
            row.projectName = projectSearch.projectName;
            row.projectTiv = Number(projectSearch.tiv);
            row.projectKickoffDate = Number(projectSearch.kickoffDate);
            row.projectCompletionDate = projectSearch.completionDate;
            row.projectStatus = projectSearch.projectStatus;
            if (row.changedFields['projectId'] && row.projectId !== row.changedFields['projectId']
                || !row.changedFields['projectId'] && row.initialData['projectId'] != row.projectId) {
                this.headerService.changeForm = true;
            }
            row.changedFields = { 'projectId': row.projectId, ...row.changedFields };
            row.forceBeanChange('projectId');
            this.setFocusToId(rowIndex);
        }
    }

    clearRow(row: AssetsProjectsDisplay): void {
        this.headerService.changeForm = true;
        row.projectId = null;
        row.projectName = null;
        row.projectTiv = null;
        row.projectKickoffDate = null;
        row.projectCompletionDate = null;
        row.projectStatus = null;
        row.previousProjectId = null;
        this.projectsFocusManager.next(new FocusableEvent(FocusableEventType.RESET_FOCUS_LISTENERS));
    }

    onEnter(col: any, row: AssetsProjectsDisplay, rowIndex?: number): void {
        if (col.field === 'projectId') {
            if (row.projectId === null || String(row.projectId).trim() === '') {
                this.clearRow(row);
                this.openProjectModal(row, rowIndex);
                return;
            }
            this.onProjectIdEdition(row, rowIndex);
        }
    }

    onFocusOut(col: any, row: AssetsProjectsDisplay, rowIndex?: number): void {
        if (col.field === 'projectId') {
            this.onProjectIdEdition(row, rowIndex);
        }
    }

    onRowReorder(event: any) {
        this.headerService.changeForm = true;
        MVTOperations.changePosition(this.projects, event.dragIndex, event.dropIndex);
    }

    onEditComplete(event): void {
        this.isEditing = false;
    }

    onEditInit(event): void {
        if (event.field === 'projectId') {
            this.isEditing = true;
        }
    }

    isDisabledButton(): boolean {
        return this.disabledButton ||
            this.isEditing ||
            this.hasIncompleteRow() ||
            !this.tableEditEnabled;
    }

    ngOnDestroy(): void {
        this.disableFormSub.unsubscribe();
    }

    private setFocusToId(rowIndex: number) {
        this.projectsFocusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_BY_INDEX, rowIndex));
    }

    restrictArrowKeyNavigation(event){
        TableUtils.restrictArrowKeyNavigation(event);
    }

}

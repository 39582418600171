<form [formGroup]="formGroupPath">
    <div class="main-section">
        <div class="grid">
            <div class="header -mt-3 ml-2">
                <h3>{{title}}</h3>
            </div>
            <div class="col-12">
                <!-- Plant ID -->
                <div class="grid mt-1">
                    <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.plantId'|translate}}:</label>
                    <div class="col-5 px-1">
                        <input class="form__input-field-style w-full" #inputPlantId type="text" formControlName="plantId"
                            (keydown.enter)="plantIdOnEnter()" (keydown.Tab)="plantIdOnTab()" (blur)="plantIdOnBlur()"
                            pInputText appOnlyNumbers autofocus maxlength="7" [tabIndex]="baseIndex" />
                    </div>
                    <div class="col-3 px-0">
                        <span class="col-1 required-field px-0">*</span>
                        <button pButton pRipple type="button" icon="pi pi-search"
                            class="p-button-text icon-container ml-2"
                            (click)="openSearchModal()"
                            [disabled]="formGroupPath.disabled" [tabIndex]="baseIndex + 1">
                        </button>
                    </div>
                </div>
                <!-- Plant Name -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.plantName'| translate}}:</label>
                    <div class="col-7 px-1">
                        <input class="form__input-field-style w-full" type="text" formControlName="plantName" pInputText
                        [disabled]="true"/>
                    </div>
                    <div class="col-1"></div>
                </div>

                <!-- ======================== ONSHORE SECTION ======================== -->
                <div *ngIf="!offshore">
                    <!-- Country -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.country'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-4" type="text" formControlName="countryId" pInputText
                                appUppercase maxlength="4" [disabled]="true"/>
                            <input class="form__input-field-style w-8" type="text" formControlName="countryName"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Postal Code -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.postalCode'|
                            translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" #postalCodeFocus type="text" formControlName="zipCode"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Address -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.address'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="address1" pInputText
                            [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1"></label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="address2" pInputText
                            [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- State -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.state'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-4" type="text" formControlName="stateAbbrev" pInputText
                                appUppercase maxlength="4" [disabled]="true"/>
                            <input class="form__input-field-style w-8" type="text" formControlName="stateName"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- City -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.city'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="cityName" pInputText
                            [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- County -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.county'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="countyName" pInputText
                                [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                </div>

                <!-- ======================== OFFSHORE SECTION ======================== -->
                <div *ngIf="offshore">
                    <!-- Country -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.country'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="countryName" pInputText
                                [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- State -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.state'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="stateName" pInputText
                                [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Quadrant/Block -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.quadrantBlock'|
                            translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="originOffshoreBlockV1"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Quadrant/Block -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1"></label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="originOffshoreBlockV2"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Water Body -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.waterBody'|
                            translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-4" type="text"
                                formControlName="originOffshoreWaterbodyId" pInputText appUppercase maxlength="4"
                                [disabled]="true"/>
                            <input class="form__input-field-style w-8" type="text" formControlName="waterBodyName"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1">
                            <span class="required-field">*</span>
                        </div>
                    </div>
                    <!-- Field Name -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.fieldName'|
                            translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full " type="text" formControlName="originOffshoreFieldName"
                                pInputText [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                    <!-- Area -->
                    <div class="grid -mt-3 pt-1">
                        <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.area'| translate}}:</label>
                        <div class="col-7 px-1">
                            <input class="form__input-field-style w-full" type="text" formControlName="areaName" pInputText
                                [disabled]="true"/>
                        </div>
                        <div class="col-1"></div>
                    </div>
                </div>
                <!-- Latitude -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.latitude'| translate}}:</label>
                    <div class="col-5 px-1">
                        <input class="form__input-field-style w-full" type="text" formControlName="latitude" pInputText
                            [disabled]="true"/>
                    </div>
                    <div class="col-3"></div>
                </div>
                <!-- Longitude -->
                <div class="grid -mt-3 pt-1">
                    <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.longitude'| translate}}:</label>
                    <div class="col-5 px-1">
                        <input class="form__input-field-style w-full" type="text" formControlName="longitude" pInputText
                            [disabled]="true"/>
                    </div>
                    <div class="col-3"></div>
                </div>

                <div *ngIf="source === AssetsType.PIPELINE">
                    <!-- Diameter Out -->
                    <div *ngIf="assetPathType === AssetsPathType.ORIGINATION">
                        <div class="grid -mt-3 pt-1">
                            <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.diameterOut'| translate}}:</label>
                            <div class="col-5 px-1">
                                <input 
                                class="form__input-field-style w-full" 
                                type="text" 
                                formControlName="diameterOut" 
                                pInputText 
                                maxlength="6" 
                                appOnlyNumbers 
                                [maxwhole]="3"
                                maxdecimal="2" 
                                [tabIndex]="baseIndex + 2"
                                />
                            </div>
                            <div class="col-3">
                                <span *ngIf="requiredDiameter" class="required-field">*</span>
                            </div>
                        </div>
                    </div>
                    <!-- Diameter In -->
                    <div *ngIf="assetPathType === AssetsPathType.DESTINATION">
                        <div class="grid -mt-3 pt-1">
                            <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'asset.path.diameterIn'| translate}}:</label>
                            <div class="col-5 px-1">
                                <input 
                                class="form__input-field-style w-full" 
                                type="text" 
                                formControlName="diameterIn" 
                                pInputText 
                                maxlength="6"
                                maxdecimal="2" 
                                appOnlyNumbers 
                                [maxwhole]="3"
                                [tabIndex]="baseIndex + 2"
                                />
                            </div>
                            <div class="col-3">
                                <span *ngIf="requiredDiameter" class="required-field">*</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";

export class AssetsSites extends Bean {
    assetSiteId: number;
    assetId: number;
    siteStatus: string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailCountry: string;
    mailState: string;
    mailCity: string;
    mailPostalCode: string;

    entryCompany: string;
    entryUser: string;
    entryDate: string;
    lastCompany: string;
    lastUser: string;
    lastDate: string;
    prevCompany: string;
    prevUser: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    liveDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;

    asset: Assets;

    constructor(entity: any) {
        super(entity);

        this.assetSiteId = entity?.assetSiteId ?? null;
        this.assetId = entity?.assetId ?? null;
        this.siteStatus = entity?.siteStatus ?? null;
        this.mailAddressV1 = entity?.mailAddressV1 ?? null;
        this.mailAddressV2 = entity?.mailAddressV2 ?? null;
        this.mailCountry = entity?.mailCountry ?? null;
        this.mailState = entity?.mailState ?? null;
        this.mailCity = entity?.mailCity ?? null;
        this.mailPostalCode = entity?.mailPostalCode ?? null;

        this.entryCompany = entity?.entryCompany ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;

        this.asset = entity?.asset ?? null;
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, KeyValueChangeRecord, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/base.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { HeaderService } from 'src/app/shared/header/header.service';

@Component({
    selector: 'app-generic-entity-selector',
    templateUrl: './generic-entity-selector.component.html',
    styleUrls: ['./generic-entity-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericEntitySelectorComponent implements OnInit, OnDestroy {
    @ViewChild('focusEntityId', { static: false }) focusEntityId: ElementRef;
    @Input() parentEntityDisplay: any
    @Input() parentEntityIdPropName: string;
    @Input() tKeyComponentTitle: string = null;
    @Input() entityName: string = '';
    @Input() entityIdPropName: string;
    @Input() searchedEntityIdPropName: string;
    @Input() entityDescPropName: string;
    @Input() searchedEntityDescPropName: string;
    @Input() requiredField: boolean = false;
    @Input() implementedComponent: BaseComponent;

    @Input() labelClass: string = 'col-3 flex justify-content-end px-0 font-bold text-xs mt-1';
    @Input() inputClass: string = 'col-7 px-1';
    @Input() buttonClass: string = 'col-2 px-0';

    @Input() openSearchModal: (implementedComponent: BaseComponent) => DynamicDialogRef;
    @Input() searchEntityDelegate: (implementedComponent: BaseComponent, entityId: number) => Observable<any>;
    @Input() searchEntityDelegateAsync: (implementedComponent: BaseComponent, entityId: number) => void;
    @Input() onEntitySelected: (implementedComponent: BaseComponent, selectedEntity: any) => void;

    @Input() baseIndex: number;


    @Input() inputEntityId: string = '';
    @Input() inputEntityName: string = '';

    private disabledButton: boolean;
    private disableFormSub: Subscription;

    private originalEntityId: number;
    private inputEntityIdLastValue: string = '';
    private parentEntityDisplayDiffer: KeyValueDiffer<string, any>;

    constructor(
        public sharedService: SharedService,
        public messageHandler: MessageHandlerService,
        public headerService: HeaderService,
        public translate: TranslateService,
        private differs: KeyValueDiffers,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledButton = disable;
        });
        this.parentEntityDisplayDiffer = this.differs.find(this.parentEntityDisplay).create();
    }

    ngDoCheck(): void {
        const changes = this.parentEntityDisplayDiffer.diff(this.parentEntityDisplay);
        if (changes) {
            this.parentEntityDisplayChanged(changes);
        }
    }

    parentEntityDisplayChanged(changes: KeyValueChanges<string, any>) {
        changes.forEachChangedItem((record: KeyValueChangeRecord<string, any>) => {
            if (record.key == this.entityIdPropName) {
                this.inputEntityId = record.currentValue;
                this.inputEntityIdLastValue = record.currentValue;
                this.cdr.detectChanges();
            }
            if (record.key == this.entityDescPropName) {
                this.inputEntityName = record.currentValue;
                this.cdr.detectChanges();
            }
        });
    }

    isDisabledComponent(): boolean {
        return this.disabledButton || (this.searchEntityDelegate == null && this.searchEntityDelegateAsync == null);
    }

    getParentEntityId() {
        if (this.parentEntityDisplay != null && this.parentEntityIdPropName != null) {
            return this.parentEntityDisplay[this.parentEntityIdPropName]
        } else {
            return null;
        }
    }


    entityIdOnEnter(event: any): void {
        const entityId = this.inputEntityId;
        if (this.inputEntityIdLastValue !== entityId || StringUtils.isEmpty(entityId)) {
            this.inputEntityIdLastValue = entityId;
            if (StringUtils.isEmpty(entityId)) {
                this.openSearchModalAndSetEntityData();
            } else {
                this.searchEntityById(Number(entityId));
            }
        }
    }

    entityIdOnBlur(event: any): void {
        const entityId = this.inputEntityId;
        if (this.inputEntityIdLastValue !== entityId) {
            this.inputEntityIdLastValue = entityId;
            this.searchEntityById(Number(entityId));
        }
    }

    private setEntityData(entityId: string, entityName: string) {
        this.parentEntityDisplay[this.entityIdPropName] = entityId;
        this.parentEntityDisplay[this.entityDescPropName] = entityName;

        this.implementedComponent.forceFieldChange(this.entityIdPropName);
        this.headerService.changeForm = true;
        this.originalEntityId = Number(entityId);
    }

    public clearEntity(): void {
        this.parentEntityDisplay[this.entityIdPropName] = '';
        this.parentEntityDisplay[this.entityDescPropName] = '';
        this.inputEntityId = '';
        this.inputEntityName = '';
        this.inputEntityIdLastValue = '';
        this.originalEntityId = 0;

        this.cdr.detectChanges();

        if (this.onEntitySelected && this.onEntitySelected !== null) {
            this.onEntitySelected(this.implementedComponent, null);
        }
    }

    idNotfound() {
        const message = this.translate.instant('common.idNotExists', { entity: this.entityName });
        this.showAlert(message);
    }

    showAlert(message) {
        setTimeout(() => {
            this.messageHandler.showAlertAndReturnFocus(
                message,
                null,
                this.focusEntityId,
                true
            );
        }, 100);
    }

    searchEntityById(entityId: number) {
        if (entityId) {
            if (String(entityId) !== String(this.originalEntityId)) {
                if (this.searchEntityDelegate) {
                    this.searchEntityDelegate(this.implementedComponent, entityId).subscribe({
                        next: (searchedRowData: any) => {
                            if (searchedRowData != null) {
                                this.searchEntityResult(searchedRowData);
                            } else {
                                this.clearEntity();
                                this.idNotfound();
                            }
                        },
                        error: (error: any) => {
                            this.clearEntity();
                            this.idNotfound();
                        }
                    });
                } else {
                    this.searchEntityDelegateAsync(this.implementedComponent, entityId);
                }
            }
        } else {
            this.clearEntity();
        }
    }

    searchEntityResult(searchedEntityData: any) {
        if (searchedEntityData != null) {
            if (searchedEntityData instanceof Array && searchedEntityData.length > 0) {
                searchedEntityData = searchedEntityData[0];
            }

            if (searchedEntityData instanceof Array && searchedEntityData.length == 0) {
                this.clearEntity();
                this.idNotfound();
                return;
            }

            let entityId = searchedEntityData[this.searchedEntityIdPropName];
            let entityName = searchedEntityData[this.searchedEntityDescPropName];
            this.setEntityData(entityId, entityName);

            if (this.onEntitySelected && this.onEntitySelected !== null) {
                this.onEntitySelected(this.implementedComponent, searchedEntityData);
            }
        }
    }

    openSearchModalAndSetEntityData() {
        const modalRef = this.openSearchModal(this.implementedComponent);
        if (modalRef != null) {
            modalRef.onClose.subscribe((searchedEntityData: any) => {
                let entityId = searchedEntityData?.[this.searchedEntityIdPropName];
                if (!StringUtils.isEmpty(entityId)) {
                    this.searchEntityById(entityId);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.disableFormSub?.unsubscribe();
    }

}

import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { EventType } from "../models/enumerations/event-type";
import { EntityEvent } from "../models/event";
import { StringUtils } from "../utils/string-utils";

@Injectable({
    providedIn: 'root'
})
export class EntityCommonService {

    private readonly entityMethodSubject$ = new Subject<EntityEvent>();
    readonly entityMethodEvent$ = this.entityMethodSubject$.asObservable();

    constructor(private titleService: Title) {}

    clearEntity() {
        this.entityMethodSubject$.next(new EntityEvent(null, null));
    }

    sendReloadEvent() {
        this.entityMethodSubject$.next(new EntityEvent(EventType.RELOAD_ENTITY, null));
    }

    sendSaveEvent() {
        this.entityMethodSubject$.next(new EntityEvent(EventType.SAVE_ENTITY, null));
    }

    sendNewRecordEvent(data: any = null) {
        this.entityMethodSubject$.next(new EntityEvent(EventType.NEW_ENTITY, data));
    }

    sendOpenRecordEvent(data: any) {
        this.entityMethodSubject$.next(new EntityEvent(EventType.OPEN_ENTITY_FROM_PARENT, data));
    }

    sendClearEvent(entityType: string) {
        this.titleService.setTitle(entityType);
        window.name = StringUtils.safeWindowName(entityType);
        this.entityMethodSubject$.next(new EntityEvent(EventType.CLEAR_DATA, null));
    }

    sendEntityNotFoundEvent() {
        this.entityMethodSubject$.next(new EntityEvent(EventType.ENTITY_NOT_FOUND, null));
    }

    sendPreReleaseEvent() {
        this.entityMethodSubject$.next(new EntityEvent(EventType.PRE_RELEASE, null));
    }

    sendSavedEntityOkEvent(data: any) {
        this.entityMethodSubject$.next(new EntityEvent(EventType.SAVED_ENTITY_OK, data));
    }
}


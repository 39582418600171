<form [formGroup]="formGroupAddress">
    <div class="main-section">
        <div class="header mb-3" style="margin-left: 5px;" *ngIf="!showInContacts">
            <h3>{{addressTitle}}</h3>
            <a target="_blank" (click)="openModalAddressImport()">
                <img *ngIf="assetId && !matchAddress" src="assets/images/orange-warning-triangle.png" alt="" pTooltip="{{'site.messageEntityPAddressTooltip'|translate}}">
            </a>
        </div>
        <img class="header-icon-copy" src="assets/icons/pi-copy.png" alt=""*ngIf="showChkSameAsPhys" style="float: right;cursor: pointer" pTooltip="Copy Physical to Mailing">
        <div [ngClass]="{'mt-3': !showInContacts}">
            <!-- COUNTRY -->
            <div class="grid">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'country'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        formControlName="origOffshoreCtryDesigName"
                        pInputText
                        class="form__input-field-style w-full"
                        [style.color]="colors['origOffshoreCtryDesigName']"
                        [tabindex]="baseIndex">
                </div>
                <div class="col-2 px-0"></div>
            </div>
            <!-- STATE -->
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'state'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        formControlName="origOffshoreStateDesigName"
                        pInputText
                        class="form__input-field-style w-full"
                        [tabindex]="baseIndex + 1">
                </div>
                <div class="col-2 px-0"></div>
            </div>
            <!-- QUADRANT BLOCK -->
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'quadrantBlock'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        formControlName="originOffshoreBlockV1"
                        pInputText
                        class="form__input-field-style w-full"
                        [tabindex]="baseIndex + 2">
                </div>
                <div class="col-2 px-0">
                    <span *ngIf="!showInContacts" class="required-field">*</span>
                </div>
            </div>
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1"></label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        formControlName="originOffshoreBlockV2"
                        pInputText
                        class="form__input-field-style w-full"
                        [tabindex]="baseIndex + 3">
                </div>
                <div class="col-2 px-0"></div>
            </div>
            <!-- WATER BODY -->
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'waterBody'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        #focusWaterBodyId
                        formControlName="originOffshoreWaterbodyId"
                        pInputText
                        appOnlyNumbers
                        class="form__input-field-style w-4"
                        maxlength="4"
                        (focusout)="waterBodyChangeFocusOut()"
                        (keydown.enter)="waterBodyChangeOnEnter()"
                        (keyup)="cleanFields()"
                        [style.color]="colors['origOffshoreStateDesigName']"
                        [tabindex]="baseIndex + 4">
                    <input
                        type="text"
                        formControlName="waterBodyName"
                        pInputText
                        class="form__input-field-style w-8"
                        [style.color]="colors['origOffshoreStateDesigName']"
                        [tabindex]="baseIndex + 5">
                </div>
                <div class="col-2 px-0">
                    <label class="required-field">*</label>
                    <button
                        type="button"
                        class="p-button-text icon-container"
                        pButton
                        pRipple
                        [disabled]="formGroupAddress.disabled"
                        icon="pi pi-search"
                        (click)="openWaterbodySearchModal()"
                        [tabindex]="baseIndex + 6">
                    </button>
                </div>
            </div>
            <!-- FIELD NAME -->
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'fieldName'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                    type="text"
                    formControlName="originOffshoreFieldName"
                    pInputText
                    class="form__input-field-style w-full"
                    #focusFieldName
                    (keydown.enter)="fieldNameChangeEnterKey()"
                    [style.color]="colors['cityName']"
                    (keyup)="cleanArea()"
                    [tabindex]="baseIndex + 7">
                </div>
                <div class="col-2 px-0">
                    <label class="required-field">*</label>
                    <button
                        type="button"
                        class="p-button-text icon-container"
                        [disabled]="formGroupAddress.disabled"
                        pButton
                        icon="pi pi-search"
                        (click)="openFieldSearchModal()"
                        [tabindex]="baseIndex + 8">
                    </button>
                </div>
            </div>
            <!-- AREA -->
            <div class="grid -mt-3 pt-1">
                <label class="col-4 flex justify-content-end font-bold text-xs px-0 mt-1">{{'common.area'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        formControlName="areaName"
                        pInputText
                        class="form__input-field-style w-full"
                        [tabindex]="baseIndex + 9">
                </div>
                <div class="col-2 px-0"></div>
            </div>
            <div class="grid -mt-3 pt-1" *ngIf="showPlantOffshoreMessage">
                <div class="col-12 text-center">
                    <label class="justify-content-end font-bold text-xs">{{'common.offshoreMessage'| translate}}</label>
                </div>
            </div>

        </div>
    </div>

</form>

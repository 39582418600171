import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class AreaSearch {
    constructor(
        public areaId: string,
        public areaName: string,
        public physState: string,
        public physCountry: string,
        public physStateName: string,
        public physCountryName: string,
        public plantId: string,
        public plantName: string,
        public physCity: string,
        public physPostalCode: string,
        public companyName: string,
        public waterbodyName: string,
        public fieldName: string,
        public physAddressV1: string,
        public mailAddressV1: string,
        public operatorName: string,
        public plantStatus: string,
        public telephone: string,
        public recordStatusDesc: string,
        public faxNumber: string,
        public noEmployees: string,
        public offshore: string,
        public offshoreBlockV1: string,
        public qcDate: string
    ) { }
}


@Injectable({
    providedIn: 'root',
})
export class AreaSearchAdapter implements Adapter<AreaSearch> {
    adapt(item: any): AreaSearch {
        return new AreaSearch(
            item.AREA_ID, 
            item.AREA_NAME, 
            item.PHYS_STATE, 
            item.PHYS_COUNTRY,
            item.PHYS_STATE_NAME  || item.STATE_NAME, 
            item.PHYS_COUNTRY_NAME || item.COUNTRY_NAME,  
            item.PLANT_ID, 
            item.PLANT_NAME, 
            item.PHYS_CITY, 
            item.PHYS_POSTAL_CODE, 
            item.COMPANY_NAME, 
            item.WATERBODY_NAME, 
            item.FIELD_NAME, 
            item.PHYS_ADDRESS_V1, 
            item.MAIL_ADDRESS_V1, 
            item.OPERATOR_NAME, 
            item.PLANT_STATUS, 
            item.TELEPHONE, 
            item.RECORD_STATUS_DESC, 
            item.FAXNUMBER, 
            item.NO_EMPLOYEES, 
            item.OFFSHORE, 
            item.OFFSHORE_BLOCK_V1, 
            item.QC_DATE
        );
    }
}

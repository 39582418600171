<div [ngClass]="showFullScreen ? 'progress-spinner-background-full' : 'progress-spinner-background'">
    <div class="progress-spinner">
        <div class="progress-spinner-image">
            <img class="" src="assets/images/globe_sans_movements.png">
            <div class="progress-spinner-rotative-image">
                <img class="header-image-globe-moves withIe" src="assets/images/globe_movements.png" >
            </div>
            
        </div>
        <div class="progress-spinner-text"><span>{{label}}</span></div>
        
    </div>
        
</div>

<div class="flex flex-column lg:flex-row xl:flex-row main-container-background min-h-screen">
    <div class="flex w-full lg:w-2 xl:w-2">
        <app-aside #aside class="w-full"></app-aside>
    </div>
    <div class="w-full lg:w-10 xl:w-10 z-2 flex-column pr-1" #appRightSideElement
        [ngClass]="aside?.classToggled ? 'hidden lg:flex xl:flex' : 'flex' ">
        <app-header class="z-4" [ngClass]="isDash ? 'relative' : 'sticky top-0' "></app-header>  
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { EquipmentType } from 'src/app/core/models/common';
import { SharedService } from 'src/app/core/services/shared.service';
import { EquipmentSectionService } from 'src/app/equipment-section/equipment-section.service';
import { IdFieldType } from 'src/app/shared/components/base/look-up-modal/look-up-modal.component';

@Component({
  selector: 'app-equipment-types-modal',
  templateUrl: './equipment-types-modal.component.html',
  styleUrls: ['./equipment-types-modal.component.css']
})
export class EquipmentTypesModalComponent implements OnInit {

  entitiesArray:any[];
  searchOnModalOpen: boolean;
  public get IdFieldType(): typeof IdFieldType {
      return IdFieldType;
  }

  constructor(
      public translate: TranslateService,
      public config: DynamicDialogConfig,
      public dialogService: DialogService,
      public sharedService: SharedService,
      public equipmentService: EquipmentSectionService) {
  }

  ngOnInit(): void {
      this.entitiesArray = this.config.data.entitiesArray;
      this.searchOnModalOpen = (this.config.data.searchOnModalOpen && this.config.data.searchOnModalOpen !== null) ? this.config.data.searchOnModalOpen :  false;
  }

  searchEntityDelegate(implementedComponent: EquipmentTypesModalComponent, inputEntityId: string, inputEntityName: string):Observable<EquipmentType[]> {
        return implementedComponent.sharedService.equipmentTypes(inputEntityName, inputEntityId);
  }



}

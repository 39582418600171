import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// ThirdParty
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// Services
// Models
// Components
import { TranslateService } from '@ngx-translate/core';
import { IPlantProduct } from 'src/app/core/interfaces/iplant-product';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { SharedService } from 'src/app/core/services/shared.service';
import { Product, ProductStatus, ProductType } from 'src/app/core/models/common';
import { ProductModalComponent } from '../modals/product-modal/product-modal.component';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { PlantsProductInfoDisplay } from 'src/app/core/models/plant/display/plants-product-info-display';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-plant-product',
    templateUrl: './plant-product.component.html',
    styleUrls: ['./plant-product.component.scss']
})
export class PlantProductComponent implements OnInit {

    @ViewChild('mvtEntityAssociatorComponent', { static: false }) mvtEntityAssociatorComponent: MVTEntityAssociatorComponent;


    @Input() entitiesArray: Array<IPlantProduct> = [];
    @Input() parentEntityId: number;
    @Input() editable: boolean = false;
    @Input() disabledButton: boolean = false;
    @Input() industryCode: number;
    @Input() baseIndex: number;
    @Output() onChanges: EventEmitter<any> = new EventEmitter<any>();

    productStatus: ProductStatus[];
    productType: ProductType[];

    constructor(
        public translate: TranslateService,
        public dialogService: DialogService,
        public sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.loadProductStatus();
        this.loadProductTypes();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ draggable: true, widthPercentage: 5 })
        columnsInfo.push({ isEntityId: true, entityPropName: 'hsProduct', searchedEntityPropName:'productID', columnHeader: 'plant.plantProduct.plantProductId', editableType: ColumnEntityInfoEditableType.numberField, maxLength: 7, widthPercentage: 15 });
        columnsInfo.push({entityPropName:'productDesc', searchedEntityPropName:'productDesc', columnHeader:'plant.plantProduct.description', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage:45});
        columnsInfo.push({entityPropName:'productStatus', columnHeader:'plant.plantProduct.status', editableType: ColumnEntityInfoEditableType.dropdownField, dropdownOptionsArray: this.dropdownOptionsArrayProductStatus, dropdownValue:'StatusId', dropdownLabel: 'StatusDesc', widthPercentage:20});
        columnsInfo.push({entityPropName:'productType', columnHeader:'plant.plantProduct.type', editableType: ColumnEntityInfoEditableType.dropdownField, dropdownOptionsArray: this.dropdownOptionsArrayProductType, dropdownValue:'typeId', dropdownLabel: 'typeDesc', widthPercentage:20});
        columnsInfo.push({ entityPropName:'delete', editableType: ColumnEntityInfoEditableType.deleteField, widthPercentage: 5 });

        return columnsInfo;
    }

    getExtraEntityPropertiesInfo(): ColumnEntityInfo[] {
        let extraEntityPropertiesInfo: ColumnEntityInfo[] = [];
        extraEntityPropertiesInfo.push({ entityPropName: 'hsUnit', searchedEntityPropName: 'unitId' });

        return extraEntityPropertiesInfo;
    }

    openSearchModal(implementedComponent:PlantProductComponent): DynamicDialogRef {
        const modalRef = implementedComponent.dialogService.open(ProductModalComponent, {
            header: implementedComponent.translate.instant('plant.plantProduct.title') + ' Search',
            width: '40rem',
            data: {
                entitiesArray: implementedComponent.entitiesArray,
                entityIdPropName: 'plantProductId',
                checkDuplicatedRows: true,
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        return modalRef;
    }

    createNewEntityInstance(implementedComponent:PlantProductComponent): IPlantProduct{
        const itemAux: IPlantProduct = PlantsProductInfoDisplay.CreateInstance({
            plantId: implementedComponent.parentEntityId,
            mvOrder: MVTOperations.getLastMvOrder(implementedComponent.entitiesArray) + 1,
        });
        return itemAux;
    }

    searchEntityDelegate(implementedComponent:PlantProductComponent, rowData: IPlantProduct): Observable<Product[]> {
        const searchParams = { productID: rowData.plantProductId, productDesc: '', unitId: '' };
        return implementedComponent.sharedService.searchHSProducts(searchParams);
    }

    loadProductStatus() {
        this.sharedService.getProductStatus()
            .subscribe(
                (status: ProductStatus[]) => this.productStatus = [...status]
            );
    }
    loadProductTypes() {
        this.sharedService.getProductTypes()
            .subscribe(
                (type: ProductType[]) => this.productType = [...type]
            );
    }

    dropdownOptionsArrayProductStatus(implementedComponent:PlantProductComponent): Array<any> {
        return implementedComponent.productStatus;
    }

    dropdownOptionsArrayProductType(implementedComponent:PlantProductComponent): Array<any> {
        return implementedComponent.productType;
    }

    onEntityChanges(event: any) {
        this.onChanges.emit(event.entitiesArray);
    }

}


<div class="general-container" id="contacts">
    <div class="main-section">
        <div class="header">
            <h3>{{'common.contacts'|translate}}</h3>
        </div>
        <div class="grid mt-1">
            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                <button class="btn-row w-11rem" (click)="openContactModal()"  type="button" [disabled]="disabledButtonOrEmptyID()" [ngStyle]="{'color':disabledButtonOrEmptyID() ? 'grey' : 'black'}" [tabindex]="baseIndex">{{'contact.contactGrid.queryExistingContact'|translate}}</button>
            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6 lg:pr-6 xl:pr-6">
                <label class=" font-bold text-xs mt-1 px-1">{{'contact.contactGrid.addContact'|translate}}:</label>
                <input type="text"
                    [(ngModel)]="contactId"
                    name="contactId"
                    maxlength="10"
                    class="form__input-field-style w-6rem xl:w-12rem mr-1"
                    pInputText
                    appOnlyNumbers
                    (keyup.enter)="openRelatedEntities();"
                    [tabindex]="baseIndex + 1"
                    [disabled]="disabledButtonOrEmptyID()"/>
                <button class="btn-row add-contact-button"
                    type="button"
                    (click)="openRelatedEntities()"
                    [tabindex]="baseIndex + 2"
                    [disabled]="disabledButtonOrEmptyID()">{{'common.add'|translate}}
                </button>
            </div>
      </div>


      <div class="mt-1 col-8">
          <p class="text-xs text-red-500">{{'contact.contactGrid.contactsInRed'|translate}}</p>
      </div>

      <!-- ====================== SLOTTED CONTACTS GRID ====================== -->
      <app-slotted-table
        #slottedTableComponent
        [contactComponent]="this"
        [acContactsSlotted]="acContactsSlottedFiltered()"
        [useSlottedBehavior]="useSlottedBehavior"
        [disabledButton]="disabledButton"
        [preferences]="preferences"
        [entityName]="entityName"
        [baseIndex]="baseIndex + 3">
      </app-slotted-table>
      <!-- =================================================================== -->

      <div *ngIf="useSlottedBehavior">
          <p class="text-sm mt-3 mb-3">
              The above grid represents functional contacts assigned to the plant.
              Click on the <img src="assets/icons/up-green-arrow.png" class="w-1rem"> arrow to move a contact to the top.
              Click on the <img src="assets/icons/down-red-arrow.png" class="w-1rem"> arrow to move a contact to the contact pool below.
          </p>
      </div>

      <!-- ====================== POOL CONTACTS GRID ====================== -->
      <app-pool-table
        #poolTableComponent
        [contactComponent]="this"
        [acContactsPool]="acContactsPoolFiltered()"
        [useSlottedBehavior]="useSlottedBehavior"
        [preferences]="preferences"
        [entityName]="entityName"
        [baseIndex]="baseIndex + 4">
      </app-pool-table>
      <!-- =================================================================== -->

        <hr class="mt-1">

        <div class="grid flex">
            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6 pb-0 pt-0">
                <div class="grid">
                    <div class="col-2 xl:col-1 text-right px-0">
                        <label class="text-xs">{{'common.email'|translate}}:</label>
                    </div>
                    <div class="col-10 xl:col-11">
                        <label for="" class="text-xs ml-1">{{ additionalInfo?.contactDisplay?.emailAddress }}</label>
                        <app-email-verification
                            *ngIf="additionalInfo"
                            [emailAddress]="additionalInfo?.contactDisplay?.emailAddress"
                            [marketingEmailStatus]="additionalInfo?.contactDisplay?.marketingEmailStatus"
                            [marketingEmailOverrideStatus]="additionalInfo?.contactDisplay?.marketingEmailOverrideStatus"
                            [marketingOverrideDate]="additionalInfo?.contactDisplay?.marketingOverrideDate"
                            [marketingOverridePerson]="additionalInfo?.contactDisplay?.marketingOverridePerson">
                        </app-email-verification>
                    </div>
                </div>
                <div class="grid" *ngIf="showLinkedInFooter">
                    <div class="col-2 xl:col-1 text-right px-0">
                        <label class="text-xs">{{'common.linkedin'|translate}}:</label>
                    </div>
                    <div class="col-10 xl:col-11">
                        <label for="" class="text-xs ml-1">{{ linkedin }}</label>
                    </div>
                </div>
            </div>
            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6 pt-0">
                <div class="grid">
                    <div class="col-2 sm:col-3 md:col-3 lg:col-3 xl:col-3 text-right px-0">
                        <label class="text-xs">{{'common.phone'|translate}}:</label>
                    </div>
                    <div class="col-10 sm:col-9 md:col-9 lg:col-9 xl:col-9">
                        <label for="" class="text-xs ml-1">{{ phone }}</label>
                    </div>
                </div>
                <div class="grid" *ngIf="showAltaPhone">
                    <div class="col-2 sm:col-3 md:col-3 lg:col-3 xl:col-3 text-right px-0">
                        <label class="text-xs">{{'common.altPhone'|translate}}:</label>
                    </div>
                    <div class="col-10 sm:col-9 md:col-9 lg:col-9 xl:col-9">
                        <label for="" class="text-xs ml-1">{{ altPhone }}</label>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

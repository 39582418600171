import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RelatedInfoCompanyComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.COMPANY;
    @Input() entityTableKey: string = EntityName.COMPANY;
    @Input() i18nBase = 'relatedInfo.company'
    constructor(

        public sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if (this.callerIsPlant) {
            columnsInfo.push({ entityPropName: 'CompanyId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'CompanyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 24});
            columnsInfo.push({ entityPropName: 'ClassificationDesc', columnHeader: 'classification', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'OwnerPercentage', columnHeader: 'relatedInfo.company.percentage', widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});

        } else if(this.callerIsProject) {
            columnsInfo.push({ entityPropName: 'CompanyId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'CompanyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 36});
            columnsInfo.push({ entityPropName: 'OwnerPercentage', columnHeader: 'relatedInfo.company.percentage', widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});

        } else if(this.callerIsOEquipment || this.callerIsMiningEquipment) {
            columnsInfo.push({ entityPropName: 'CompanyId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'CompanyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 36});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});

        } else {
            columnsInfo.push({ entityPropName: 'OwnerId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'CompanyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 24});
            columnsInfo.push({ entityPropName: 'ClassificationDesc', columnHeader: 'classification', widthPercentage: 16});
            columnsInfo.push({ entityPropName: 'OwnerPercentage', columnHeader: 'relatedInfo.company.percentage', widthPercentage: 10, headerAlign: 'right', bodyAlign: 'right'});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 17});
        }

        return columnsInfo;
    }

}

import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AttachmentService } from 'src/app/core/services/attachment.service';

export class AttachmentItem {
    entityName: string;
    entityId: string;
    filename: string;

    constructor(name: string, id: string, file: string) {
        this.entityName = name;
        this.entityId = id;
        this.filename = file;
    }
}

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments-modal.html',
    styleUrls: ['./attachments-modal.scss'],
})
export class AttachmentsModalComponent implements OnInit {

    attachments: Array<AttachmentItem> = [];

    constructor(
        private attachmentService: AttachmentService,
        public config: DynamicDialogConfig) {
    }

    ngOnInit(): void {
        this.attachments = this.config.data.attachments;
    }

    downloadAttachment(attachmentItem: AttachmentItem) {
        this.attachmentService.downloadFile(attachmentItem.entityName, attachmentItem.entityId, attachmentItem.filename);
    }

}

import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { interval } from "rxjs";
import { SpinnerProcess } from "src/app/core/models/spinner-process";
import { LoadWheelComponent } from "./load-wheel.component";

@Injectable({ providedIn: 'root' })

export class LoadWheelService {
    private _appViewContainerRef: ViewContainerRef;
    private static readonly INITIAL_DELAY: number = 300;
    private static readonly MIN_VISIBLE_TIME: number = 800;
    private runningProcesses: Array<SpinnerProcess> = [];

    public set appViewContainerRef(value: ViewContainerRef) {
        this._appViewContainerRef = value;
    }

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver) {
    }

    private createWheelInternal(label: string, viewContainerRef: ViewContainerRef, showFullscreen: boolean) {
        let component: any = LoadWheelComponent;
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        viewContainerRef.clear();
        let componentRef: ComponentRef<any> = viewContainerRef.createComponent(componentFactory);
        componentRef.instance.label = label;
        componentRef.instance.showFullScreen = showFullscreen;
        return componentRef;
    }

    private showWheelInternal(label: string, viewContainerRef: ViewContainerRef, parentDomDiv: any = null): ComponentRef<any> {
        let wheelDiv: ComponentRef<any> = null;
        let proceessOnElement = parentDomDiv !== null && parentDomDiv !== undefined;
        wheelDiv = this.createWheelInternal(label, viewContainerRef, !proceessOnElement);
        if (proceessOnElement) {
            parentDomDiv.style.position = "relative"
            parentDomDiv.prepend(wheelDiv.location.nativeElement);
        }
        return wheelDiv;
    }

    hideWheel(process: SpinnerProcess) {
        if (process != null) {
            process.finished = true;
            let hideSubscription = interval(16).subscribe(
                timer => {
                    if (LoadWheelService.MIN_VISIBLE_TIME <
                        (performance.now() - process.startTimestamp - LoadWheelService.INITIAL_DELAY)) {
                        if (hideSubscription != null) {
                            hideSubscription.unsubscribe();
                        }
                        this.hideWheelImmediately(process);
                    }
                }
            );
        }
    }

    hideWheelImmediately(process: SpinnerProcess) {
        if (process != null) {
            process.finished = true;
            if (process.parentDomDiv !== null && process.parentDomDiv !== undefined) {
                process.parentDomDiv.style.position = process.parentPosition;
            }
            if (process.wheelDiv != null) {
                process.wheelDiv.destroy();
            }
            this.checkRunningProcesses(process);
        }
    }

    private checkRunningProcesses(process: SpinnerProcess): void {
        this.runningProcesses = this.runningProcesses.filter(x => x !== process);
        if (this.runningProcesses.length > 0) {
            let nextProcess = this.runningProcesses[0];
            if (nextProcess.finished) {
                this.checkRunningProcesses(nextProcess);
            } else {
                nextProcess.startTimestamp = performance.now() - LoadWheelService.INITIAL_DELAY;
                nextProcess.wheelDiv = this.showWheelInternal(nextProcess.label, nextProcess.viewContainerRef, nextProcess.parentDomDiv);
            }
        }
    }

    showWheel(label: string, viewContainerRef: ViewContainerRef = null, parentDomDiv: any = null): SpinnerProcess {
        viewContainerRef = viewContainerRef != null ? viewContainerRef : this._appViewContainerRef;
        let process: SpinnerProcess = {
            startTimestamp: performance.now(), finished: false, wheelDiv: null,
            parentDomDiv: parentDomDiv, parentPosition: parentDomDiv !== null ? parentDomDiv.style.position : null,
            label: label, viewContainerRef: viewContainerRef
        };
        if (viewContainerRef != null) {
            setTimeout(() => {
                if (!process.finished) {
                    if (this.runningProcesses.length === 0) {
                        process.wheelDiv = this.showWheelInternal(process.label, process.viewContainerRef, process.parentDomDiv);
                    }
                    this.runningProcesses.push(process);
                }
            }, LoadWheelService.INITIAL_DELAY);
        }
        return process;
    }

}



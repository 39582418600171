import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { CompanySearch, Plants } from 'src/app/core/models';
import { CitiesWithMessage, City, Country, County, PecZone, State } from 'src/app/core/models/common';
import { Constants } from 'src/app/core/models/constants';
import { AddressType } from 'src/app/core/models/enumerations/address-type';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { BaseComponent } from '../../base.component';
import { Address } from '../../core/models/address';
import { MessageType } from '../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { AddressImportModalComponent } from '../modals/address-import-modal/address-import-modal.component';
import { LocationSearchComponent, LocationSearchParameters } from '../modals/location-search/location-search.component';
import { AddressService } from './address.service';
import { SiteResult } from 'src/app/core/models/site';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})

export class AddressFormComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
    @Input() addressType: AddressType = AddressType.MAILING;
    @Input() addressTitle: string;
    @Input() showPecZone: boolean = false;
    @Input() showChkSameAsPhys: boolean = false;
    @Input() showGeoOffshore: boolean = false;
    @Input() showInContacts: boolean = false;
    @Input() onSite: boolean = false;
    @Input() changedFieldsReMap?: Map<string, string> = null;
    @Input() changedFieldsAddress?: Map<string, string> = null;
    @Input() changedFieldsPhysAddress?: Map<string, string> = null;
    @Input() operatorId: number;
    @Input() matchAddress: boolean;
    @Input() assetId: number;
    @Input() offshore: boolean;
    @Input() addressImportData: Array<Plants> | SiteResult;
    @Input() entityType: string;
    @Input() newEntity: boolean;
    @Input() inputOperatorId: any;
    @Input() baseIndex: number;
    @Output() addressChanges: EventEmitter<Address> = new EventEmitter();
    @Output() latitude: EventEmitter<string> = new EventEmitter();
    @Output() longitude: EventEmitter<string> = new EventEmitter();
    @Output() validOperator: EventEmitter<boolean> = new EventEmitter();
    textTooltip: string;
    postal_code: string = '';

    private fieldsChangeAddressSub: Subscription;
    private discardEventSubscription: Subscription;
    private fieldsChangeSub: Subscription;
    private disableFormSub: Subscription;
    formGroupAddress: FormGroup;
    fieldChangeAddress: Object;
    address: Address;
    physAddress: Address;
    mailAddress: Address;
    copyCountryId: string;
    colors: any = {};
    physicalAddressOfCompany: AddressFormComponent;
    @ViewChild('focusCountry', { static: false }) focusCountry: ElementRef;
    @ViewChild('focusCity', { static: false }) focusCity: ElementRef;
    @ViewChild('focusState', { static: false }) focusState: ElementRef;
    @ViewChild('postalCodeFocus', { static: false }) postalCodeFocus: ElementRef;
    loadingCity: boolean = false;
    loadingState: boolean = false;
    loadingCountry: boolean = false;
    buttonPressed: boolean = false;
    loadingWrongCountry: boolean = false;
    showRequiredContactFields: boolean = true;
    showingSelectCountryMessage: boolean = false;
    loadingCounty: boolean = false;

    constructor(
        public fb: FormBuilder,
        private dialogService: DialogService,
        private addressService: AddressService,
        private messageHandler: MessageHandlerService,
        private sharedService: SharedService,
        private headerService: HeaderService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        public companySectionService: CompanySectionService,
        public translate: TranslateService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) { super(recordLockingFlow, preferencesService) }

    ngOnInit() {
        this.address = new Address(null, this.addressType, '', '', '', '', '', '', '', '', '', '', 0, 0, 0, 0);
        this.buildForm();
        this.initFormReMap();

        this.fieldsChangeAddressSub = this.fieldsChange$.subscribe(property => {
            this.fieldChangeAddress = property;
            this.addressChanges.emit(this.createFromForm());
        });

        this.discardEventSubscription = this.headerService.discardEvent$.subscribe(discard => {
            if (discard) {
                this.setStateForm(this.formGroupAddress);
                this.initFormReMap();
            }
        });
        this.fielChangeSub();
        this.updateRequiredFieldStatus();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.onSite || changes.showInContacts) {
            this.updateRequiredFieldStatus();
        }
    }

    updateRequiredFieldStatus(): void {
        if (this.formGroupAddress) {
            this.showRequiredContactFields = !this.showInContacts || this.onSite ||
                !this.isNullOrEmpty(this.formGroupAddress.controls.countryId.value);
        }

    }

    switchTooltipMessage() {
        if (this.addressType === AddressType.MAILING) {
            return this.textTooltip = this.translate.instant('site.messageEntityMAddressTooltip')
        } else if (this.isNotMailingAddress()) {
            return this.textTooltip = this.translate.instant('site.messageEntityPAddressTooltip')
        }
    }

    initFormReMap() {
        if (this.addressType == AddressType.PHYSICAL) {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsPhysAddress);
        } else if (this.addressType == AddressType.MAILING && !this.showInContacts) {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsAddress);
        } else {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsReMap);
        }
    }

    ngOnDestroy() {
        this.fieldsChangeAddressSub?.unsubscribe();
        this.fieldsChangeSub?.unsubscribe();
        this.discardEventSubscription?.unsubscribe();
        this.disableFormSub?.unsubscribe();
    }

    fielChangeSub() {
        this.fieldsChangeSub = this.fieldsChange$.subscribe(property => {
            if (Object.keys(property).length > 0) {
                this.headerService.changeForm = true;
            }
        });
    }

    setValueControls(address: Address) {
        this.address = address;
        this.copyCountryId = address.countryId;
        this.addressType = address.addressType;
        this.formGroupAddress.controls.countryId.setValue(address.countryId);
        this.formGroupAddress.controls.countryName.setValue(address.countryName);
        this.formGroupAddress.controls.zipCode.setValue(address.zipCode);
        this.formGroupAddress.controls.address1.setValue(address.address1);
        this.formGroupAddress.controls.address2.setValue(address.address2);
        this.formGroupAddress.controls.stateAbbrev.setValue(address.stateAbbrev);
        this.formGroupAddress.controls.stateName.setValue(address.stateName);
        this.formGroupAddress.controls.cityName.setValue(address.cityName);
        this.formGroupAddress.controls.pecZoneId.setValue(address.pecZoneId);
        this.formGroupAddress.controls.countyName.setValue(address.countyName);
        this.formGroupAddress.controls.cId.setValue(address.c_id);
        this.formGroupAddress.controls.sId.setValue(address.s_id);
        this.formGroupAddress.controls.ciId.setValue(address.ci_id);
        this.formGroupAddress.controls.coId.setValue(address.co_id);
    }

    refreshFieldsByChanges() {
        this.forceFieldChange('countryId');
        this.forceFieldChange('address1');
        this.forceFieldChange('address2');
        this.forceFieldChange('zipCode');
        this.forceFieldChange('cityId');
        this.forceFieldChange('cityName');
        this.forceFieldChange('stateAbbrev');
        this.forceFieldChange('countyName');
        this.forceFieldChange('cId');
        this.forceFieldChange('sId');
        this.forceFieldChange('ciId');
        this.forceFieldChange('coId');
    }

    setControlsAsDirty() {
        this.formGroupAddress.controls.countryId.markAsDirty();
        this.formGroupAddress.controls.countryName.markAsDirty();
        this.formGroupAddress.controls.zipCode.markAsDirty();
        this.formGroupAddress.controls.address1.markAsDirty();
        this.formGroupAddress.controls.address2.markAsDirty();
        this.formGroupAddress.controls.stateAbbrev.markAsDirty();
        this.formGroupAddress.controls.stateName.markAsDirty();
        this.formGroupAddress.controls.cityName.markAsDirty();
        this.formGroupAddress.controls.pecZoneId.markAsDirty();
        this.formGroupAddress.controls.countyName.markAsDirty();
        this.formGroupAddress.controls.cId.markAsDirty();
        this.formGroupAddress.controls.sId.markAsDirty();
        this.formGroupAddress.controls.ciId.markAsDirty();
        this.formGroupAddress.controls.coId.markAsDirty();
    }

    setAddress(address: Address) {
        if (address.addressType == AddressType.PHYSICAL) {
            this.physAddress = address;
        } else if (address.addressType == AddressType.MAILING){
            this.mailAddress = address;
        }
    }

    createFromForm(): Address {
        return new Address(
            null,
            this.addressType,
            this.formGroupAddress.get(['countryId'])!.value,
            this.formGroupAddress.get(['countryName'])!.value,
            this.formGroupAddress.get(['zipCode'])!.value,
            this.formGroupAddress.get(['address1'])!.value,
            this.formGroupAddress.get(['address2'])!.value,
            this.formGroupAddress.get(['stateAbbrev'])!.value,
            this.formGroupAddress.get(['stateName'])!.value,
            this.formGroupAddress.get(['cityName'])!.value,
            this.formGroupAddress.get(['pecZoneId'])!.value,
            this.formGroupAddress.get(['countyName'])!.value,
            this.formGroupAddress.get(['cId'])!.value,
            this.formGroupAddress.get(['sId'])!.value,
            this.formGroupAddress.get(['ciId'])!.value,
            this.formGroupAddress.get(['coId'])!.value
        );
    }

    setFontColor(controlName: string, color: string): void {
        this.colors[`${controlName}`] = color;
    }

    private buildForm() {
        this.formGroupAddress = this.fb.group({
            countryId: [''],
            countryName: [{ value: '', disabled: true }, Validators.required],
            zipCode: [''],
            address1: [''],
            address2: [''],
            stateAbbrev: [''],
            stateName: [{ value: '', disabled: true }],
            cityName: [''],
            pecZoneId: [{ value: '', disabled: true }],
            countyName: [{ value: '', disabled: true }],
            cId: [''],
            sId: [''],
            ciId: [''],
            coId: ['']
        });
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            if (disable) {
                this.formGroupAddress.disable();
            } else {
                this.formGroupAddress.enable();
                this.formGroupAddress.controls.countryName.disable();
                this.formGroupAddress.controls.stateName.disable();
                this.formGroupAddress.controls.countyName.disable();
                this.formGroupAddress.controls.pecZoneId.disable();
            }
        });
    }

    public copyPhysicalAddress(): void {
        if (!this.formGroupAddress.disabled) {
            if (this.physAddress !== undefined) {
                this.physAddress.addressType = AddressType.MAILING;
                this.setValueControls(this.physAddress);
            } else {
                this.setValueControls(new Address(null, AddressType.MAILING, '', '', '', '', '', '', '', '', '', '', 0, 0, 0, 0));
            }
            this.refreshFieldsByChanges();
        }
    }

    isNotMailingAddress() {
        return (this.addressType !== AddressType.MAILING);
    }

    //Search methods without modal
    private searchCountry() {
        let countryId = this.formGroupAddress.get(['countryId'])!.value;
        if (countryId === this.address.countryId && countryId != '') {
            return;
        }
        if (countryId == '') {
            this.openCountrySearchModal();
        } else {
            const applyCoverage = this.entityType !== EntityName.COMPANY;
            this.addressService.searchCountries(countryId, '', applyCoverage)
                .subscribe((countries: Country[]) => {
                    if (countries.length == 1) {
                        this.address.countryId = countries[0].countryId;
                        this.formGroupAddress.controls.cId.setValue(countries[0].cId);
                        this.formGroupAddress.controls.countryId.setValue(countries[0].countryId);
                        this.formGroupAddress.controls.countryName.setValue(countries[0].countryName);
                        this.postalCodeFocus.nativeElement.focus();
                        this.cleanFieldsRelatedWithCountry();
                    } else if (countries.length < 1) {
                        this.clearForm(this.formGroupAddress.get(['countryId'])!.value);
                        this.formGroupAddress.controls.countryId.setValue('');
                        const confirmOk = () => this.focusCountry.nativeElement.focus();
                        this.messageHandler.show(Constants.ERROR_GEOGRAPHY_NOT_AVAILABLE, MessageType.ERROR, confirmOk);
                    } else {
                        this.openCountrySearchModal();
                    }
                });
        }
    }

    private searchState(cleanCity: boolean = true) {
        const countryId = this.formGroupAddress.get(['countryId'])!.value;
        const stateAbbrev = this.formGroupAddress.get(['stateAbbrev'])!.value;
        const stateName = this.formGroupAddress.get(['stateName'])!.value;

        if (countryId === '') {
            this.formGroupAddress.controls.stateAbbrev.setValue('');
            const confirmOk = () => this.focusState.nativeElement.focus();
            this.messageHandler.show(Constants.ERROR_SELECT_COUNTRY_FIRST, MessageType.INFO, confirmOk);
            return;
        }

        if (!this.buttonPressed && stateAbbrev === this.address.stateAbbrev && countryId === this.copyCountryId && stateName != '') {
            return;
        }

        this.addressService.getStateByCountry(countryId, '', false)
            .subscribe((states: State[]) => {
                if (stateAbbrev === '') {
                    this.openStateSearchModal(states);
                    this.buttonPressed = false;
                } else if (states.length > 1) {
                    let statesFound = states.filter(s => s.stateAbbrev === stateAbbrev);
                    if (statesFound.length == 0) {
                        this.formGroupAddress.controls.sId.setValue('');
                        this.formGroupAddress.controls.stateAbbrev.setValue('');
                        this.cleanCityCountyAndPecZone();
                        this.focusState.nativeElement.focus();
                    } else if (statesFound.length == 1) {
                        if (this.buttonPressed) {
                            this.openStateSearchModal(statesFound);
                            this.buttonPressed = false;
                        } else {
                            this.formGroupAddress.controls.stateAbbrev.markAsDirty();
                            this.formGroupAddress.controls.stateName.markAsDirty();
                            this.address.stateAbbrev = statesFound[0].stateAbbrev;
                            this.formGroupAddress.controls.sId.setValue(statesFound[0].sId);
                            this.formGroupAddress.controls.stateAbbrev.setValue(statesFound[0].stateAbbrev);
                            this.formGroupAddress.controls.stateName.setValue(statesFound[0].stateName);
                            if (cleanCity) {
                                this.cleanCityCountyAndPecZone(true);
                            }
                            this.focusCity.nativeElement.focus();
                        }
                    } else if (statesFound.length > 1) {
                        this.openStateSearchModal(statesFound);
                    }
                }
            });
    }

    private searchCity() {
        let countryId = this.formGroupAddress.get(['countryId'])!.value;
        let stateAbbrev = this.formGroupAddress.get(['stateAbbrev'])!.value;
        let cityName = this.formGroupAddress.get(['cityName'])!.value;

        if (this.checkAndShowSelectCountryMessage()) return;

        this.addressService.getCityByCountryState(countryId, stateAbbrev, cityName, false)
            .subscribe((data: CitiesWithMessage) => {
                if (data.cities.length == 1) {
                    this.address.cityName = data.cities[0].cityName;
                    this.formGroupAddress.controls.ciId.setValue(data.cities[0].ciId);
                    this.formGroupAddress.controls.cityName.setValue(data.cities[0].cityName);
                    if (this.isNotMailingAddress()) {
                        this.searchCityCounty();
                    }
                    if (this.formGroupAddress.get(['stateAbbrev'])!.value == '') {
                        this.formGroupAddress.controls.stateAbbrev.setValue(data.cities[0].stateAbbrev);
                        this.searchState(false);
                    }
                    this.loadingCity = false;
                } else if (data.cities.length > 1) {
                    const lowercaseCityName = cityName.toLowerCase();
                    const city = data.cities.find(s => s.cityName.toLowerCase() === lowercaseCityName);
                    const hasMultipleStates = data.cities.some((city: City) => city.stateAbbrev !== data.cities[0].stateAbbrev);

                    if (hasMultipleStates || city === undefined) {
                        this.openCitySearchModal();
                    } else {
                        this.address.cityName = city.cityName;
                        this.formGroupAddress.controls.ciId.setValue(city.ciId);
                        this.formGroupAddress.controls.cityName.setValue(city.cityName);
                        if (this.isNotMailingAddress()) {
                            this.searchCityCounty();
                        }
                        if (this.formGroupAddress.get(['stateAbbrev'])!.value == '') {
                            this.formGroupAddress.controls.stateAbbrev.setValue(city.stateAbbrev);
                            this.searchState(false);
                        }
                    }
                } else {
                    this.address.cityName = '';
                    this.formGroupAddress.controls.ciId.setValue('');
                    this.formGroupAddress.controls.cityName.setValue('');
                    this.formGroupAddress.controls.coId.setValue('');
                    this.formGroupAddress.controls.countyName.setValue('');
                    this.formGroupAddress.controls.pecZoneId.setValue('');
                    this.loadingCity = false;
                }
            });
    }

    private searchCityCounty() {
        let cityId = this.formGroupAddress.get(['ciId'])!.value;
        this.addressService.countyByCityId(cityId)
          .subscribe((counties: County[]) => {
            if (counties.length == 1) {
              this.address.co_id = counties[0].coId;
              this.address.countyName = counties[0].countyName;
              this.formGroupAddress.controls.coId.setValue(counties[0].coId);
              this.formGroupAddress.controls.countyName.setValue(counties[0].countyName);
              this.searchPecZone();
            } else if (counties.length > 1) {
              this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                  this.ngZone.run(() => this.openCountySearchModal());
                }, 150);
              });
            }
          });
      }

    private searchPecZone() {
        let coId = this.formGroupAddress.get(['coId'])!.value;
        let pzId = this.formGroupAddress.get(['pecZoneId'])!.value;
        this.addressService.pecZoneByCountyId(coId)
            .subscribe((peczones: PecZone[]) => {
                if (peczones.length == 1) {
                    this.address.pecZoneId = peczones[0].pecZoneId;
                    this.formGroupAddress.controls.pecZoneId.setValue(peczones[0].pecZoneId);
                    if (pzId != this.address.pecZoneId) {
                        this.forceFieldChange('pecZoneId');
                        this.forceFieldChange('countyName');
                    }
                }
            });
            
        this.focusNextDiv();
    }

    //Modal Open
    public openCountrySearchModal() {
        if (!this.loadingCountry) {
            this.loadingCountry = true;
            let dialogParameters: LocationSearchParameters = {
                type: 'country',
                countryId: this.formGroupAddress.get(['countryId'])!.value,
                stateAbbrev: '',
                stateName: '',
                cityName: '',
                ciId: '',
                entityType: this.entityType
            };
                const modalRef = this.dialogService.open(LocationSearchComponent, {
                header: this.translate.instant('modalHeader.countrySearch'),
                width: '40rem',
                data: dialogParameters,
                dismissableMask: true,
                draggable: true,
                keepInViewport: true
            });
    
            modalRef.onClose.subscribe((response: any) => {
                if (response !== undefined) {
                    if (this.address.countryId != response.countryId) {
                        this.cleanFieldsRelatedWithCountry();
                    }
                    this.address.countryId = response.countryId;
                    this.formGroupAddress.controls.cId.setValue(response.cId);
                    this.formGroupAddress.controls.countryId.setValue(response.countryId);
                    this.formGroupAddress.controls.countryName.setValue(response.countryName);
                    this.postalCodeFocus.nativeElement.focus();
                    this.formGroupAddress.controls.countryId.markAsDirty();
                }
                this.loadingCountry = false;
                this.focusCountry.nativeElement.focus();
            });
        }
    }
    
    public openStateSearchModal(states?: State[]) {
        if (this.formGroupAddress.get(['countryId'])!.value == '') {
            this.messageHandler.show(Constants.ERROR_SELECT_COUNTRY_FIRST, MessageType.ERROR)
            return;
        }

        let dialogParameters: LocationSearchParameters = {
            type: 'state', countryId: this.formGroupAddress.get(['countryId'])!.value,
            countryName: this.formGroupAddress.get(['countryName'])!.value,
            stateAbbrev: this.formGroupAddress.get(['stateAbbrev'])!.value,
            stateName: '', cityName: '', ciId: '',
            states: states
        };

        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: this.translate.instant('modalHeader.selectState'),
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: any) => {
            this.focusState.nativeElement.focus();
            if (response !== undefined) {
                this.address.stateAbbrev = response.stateAbbrev;
                this.formGroupAddress.controls.sId.setValue(response.sId);
                this.formGroupAddress.controls.stateAbbrev.setValue(response.stateAbbrev);
                this.formGroupAddress.controls.stateName.setValue(response.stateName);
                this.formGroupAddress.controls.stateAbbrev.markAsDirty();
                this.formGroupAddress.controls.stateName.markAsDirty();
            }
        });

    }

    private checkAndShowSelectCountryMessage(): boolean {
        const countryId = this.formGroupAddress.get(['countryId'])!.value;
        if (!this.showingSelectCountryMessage && countryId == '') {
            this.showingSelectCountryMessage = true;
            this.messageHandler.show(Constants.ERROR_SELECT_COUNTRY_FIRST, MessageType.ERROR, () => this.showingSelectCountryMessage = false);
        }
        return this.showingSelectCountryMessage;
    }

    openCitySearchModal() {
        if (this.checkAndShowSelectCountryMessage()) return;

        let dialogParameters: LocationSearchParameters = {
            type: 'city', countryId: this.formGroupAddress.get(['countryId'])!.value,
            countryName: this.formGroupAddress.get(['countryName'])!.value,
            stateAbbrev: this.formGroupAddress.get(['stateAbbrev'])!.value, stateName: '',
            cityName: this.formGroupAddress.get(['cityName'])!.value, ciId: ''
        };

        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: this.translate.instant('modalHeader.citySearch'),
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: any) => {
            this.focusCity.nativeElement.focus();
            this.formGroupAddress.controls.cityName.markAsDirty();
            if (response) {
                this.address.cityName = response.cityName;
                this.formGroupAddress.controls.ciId.setValue(response.ciId);
                this.formGroupAddress.controls.cityName.setValue(response.cityName);
                this.forceFieldChange('cityName');
                if (this.isNotMailingAddress()) {
                    this.searchCityCounty();
                }
                if (this.formGroupAddress.get(['stateAbbrev'])!.value == '') {
                    this.formGroupAddress.controls.stateAbbrev.setValue(response.stateAbbrev);
                    this.searchState(false);
                }
            } else {
                this.formGroupAddress.controls.ciId.setValue('');
                this.formGroupAddress.controls.cityName.setValue('');
                this.formGroupAddress.controls.coId.setValue('');
                this.formGroupAddress.controls.countyName.setValue('');
                this.formGroupAddress.controls.pecZoneId.setValue('');
            }
        });
    }

    openCountySearchModal() {
        if (!this.loadingCounty) {
            this.loadingCounty = true;
            let dialogParameters: LocationSearchParameters = {
                type: 'county',
                countryId: '',
                stateAbbrev: '',
                stateName: '',
                cityName: '',
                ciId: this.formGroupAddress.get(['ciId'])!.value,
                countryName: this.formGroupAddress.get(['countryName'])!.value,
            };

            const modalRef = this.dialogService.open(LocationSearchComponent, {
                header: this.translate.instant('modalHeader.chooseCounty'),
                width: '40rem',
                data: dialogParameters,
                dismissableMask: true,
                draggable: true,
                keepInViewport: true
            });

            modalRef.onClose.subscribe((response: any) => {
                if (response !== undefined) {
                    this.formGroupAddress.controls.coId.setValue(response.coId);
                    this.formGroupAddress.controls.countyName.setValue(response.countyName);
                    this.address.co_id = response.coId;
                    this.address.countyName = response.countyName;
                    this.searchPecZone();
                } else {
                    this.formGroupAddress.controls.cityName.setValue('');
                    this.formGroupAddress.controls.cityName.markAsDirty();
                    this.cleanCountyAndPecZone();
                    this.focusCity.nativeElement.focus();
                }
                this.loadingCounty = false;
            });
        }
    }

    countryChangeFocusOut() {
        if (this.formGroupAddress.get(['countryId'])!.value != '' && !this.loadingWrongCountry) {
            this.countryChangeOnEnter();
        }
    }
    stateChangeFocusOut() {
        if (this.formGroupAddress.get(['stateAbbrev'])!.value != '') {
            this.stateChangeOnEnter();
        }
    }

    stateChangeOnEnter() {
        this.loadingState = true;
        this.searchState(true);
        this.loadingState = false;
    }

    stateChange() {
        if (!this.loadingState) {
            this.searchState(true);
        }
        this.loadingState = false;
    }
    stateChangeButton() {
        this.buttonPressed = true;
        this.stateChange();
    }

    countryChangeOnEnter() {
        this.loadingWrongCountry = true;
        this.searchCountry();
        this.loadingWrongCountry = false;
        this.updateRequiredFieldStatus();        
    }

    focusNextDiv() {
        const nextDiv: HTMLElement = this.elementRef.nativeElement.querySelector('#countryDiv');
        if (nextDiv) {
            nextDiv.focus();
        }
    }

    cityChangeFocusOut() {
        if (!this.loadingCity) {
            let cityName = this.formGroupAddress.get(['cityName'])!.value;
            if (cityName !== this.address.cityName && cityName != '') {
                this.searchCity();
            }
        }
        this.loadingCity = false;
    }

    cityChangeEnterKey() {
        this.loadingCity = true;
        this.searchCity();
    }

    clearForm(countryId: string) {
        let address: Address = new Address(null, this.addressType, countryId, '', '', '', '', '', '', '', '', '', 0, 0, 0, 0);
        this.setValueControls(address);
        this.updateRequiredFieldStatus();
    }

    resetForm() {
        this.formGroupAddress.reset();
        this.fieldChangeAddress = null;
        this.resetChangedFields();
    }

    cleanFieldsRelatedWithCountry() {
        this.formGroupAddress.controls.address1.setValue('');
        this.formGroupAddress.controls.address2.setValue('');
        this.formGroupAddress.controls.zipCode.setValue('');
        this.formGroupAddress.controls.sId.setValue('');
        this.formGroupAddress.controls.stateAbbrev.setValue('');
        this.formGroupAddress.controls.stateName.setValue('');
        this.cleanCityCountyAndPecZone(true);
    }

    cleanCityCountyAndPecZone(force: boolean = false) {
        const stateAbbrevEmpty = this.formGroupAddress.get('stateAbbrev').value === '';
        if (stateAbbrevEmpty || force) {
            if (stateAbbrevEmpty) {
                this.formGroupAddress.controls.stateName.setValue('');
            }
            this.address.cityName = '';
            this.formGroupAddress.controls.ciId.setValue('');
            this.formGroupAddress.controls.cityName.setValue('');
            this.formGroupAddress.controls.coId.setValue('');
            this.formGroupAddress.controls.countyName.setValue('');
            this.formGroupAddress.controls.pecZoneId.setValue('');
        }
    }

    cleanCountyAndPecZone() {
        if (this.formGroupAddress.get('cityName').value === '') {
            this.address.cityName = '';
            this.formGroupAddress.controls.ciId.setValue('');
            this.formGroupAddress.controls.coId.setValue('');
            this.formGroupAddress.controls.countyName.setValue('');
            this.formGroupAddress.controls.pecZoneId.setValue('');
        }
    }

    cleanAddress() {
        if (this.formGroupAddress.get(['countryId'])!.value === '') {
            this.clearForm(this.formGroupAddress.get(['countryId'])!.value);
        }
    }

    getCompanyAddress() {
        const doOnSameOperatorError = () => {
            this.inputOperatorId.focus();
        }
        this.companySectionService.getAssociatedCompanyById(this.operatorId, doOnSameOperatorError)
            .subscribe((company: CompanySearch) => {
                this.validOperator.emit(company !== null);
                if(company !== null){
                    this.setValueControls(Address.BuildPhysicalAddress(company));
                    this.latitude.emit(company.latitude);
                    this.longitude.emit(company.longitude);
                    this.refreshFieldsByChanges();
                }
            });
    }

    setAddressData(address: Address) {
        this.setValueControls(address);
        this.refreshFieldsByChanges();
    }

    openModalAddressImport() {
        const modalRef = this.dialogService.open(AddressImportModalComponent, {
            header: this.addressType === AddressType.PHYSICAL ?
                this.translate.instant('addressComparation.physTitle') :
                this.translate.instant('addressComparation.mailTitle'),
            width: '70rem',
            dismissableMask: true,
            data: {
                addressImportData: this.addressImportData,
                target: this.address,
                entityType: this.entityType,
                entityId: this.assetId,
                isPhysical: this.addressType === AddressType.PHYSICAL,
                isOffshore: false,
                isLock: this.formGroupAddress.disabled
            },
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((address: Address) => {
            if (address !== undefined) {
                this.setAddressData(address);
            }
        });
    }

    removeForbiddenChars() {
        this.postal_code = this.postal_code.replace(/[^\w]/gi, '');
    }

    preventForbiddenChars(event: KeyboardEvent) {
        const allowedChars = /[^A-Za-z0-9]/;
        if (allowedChars.test(event.key)) {
            event.preventDefault();
        }
    }

    handleChars(event: ClipboardEvent) {
        const pastedChars = event.clipboardData?.getData('text') ?? '';
        const cleanValue = pastedChars.replace(/[^\w]/gi, '');
        event.preventDefault();
        (event.target as HTMLInputElement).value = cleanValue;
    }
}


<app-base-mvt-table-selector
    #mvtEntityAssociatorComponent
    [entitiesArray]="entitiesArray" 
    [parentEntityId]="parentEntityId"
    [editable]="editable" 
    [disabledButton]="disabledButton"
    (onChanges)="onEntityChanges($event)"

    [entityName]="'plant.greenhouseGas.emissionsYear' | translate"
    [columnsInfo]="getColumnsInfo()"
    [extraEntityPropertiesInfo]="getExtraEntityPropertiesInfo()"
    [componentDescription]="''"
    [onCellEdited]="onCellEdited"
    [showHeader]="false"
    [showAddRowButton]="showAddRowButton"

    [createNewEntityInstance]="createNewEntityInstance"       
    [implementedComponent]="this"
    [baseIndex]="baseIndex">
</app-base-mvt-table-selector>
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CountryWaterBody, StateByCountry, WaterBody } from 'src/app/core/models/offshoreAddress';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';

@Component({
    selector: 'app-waterbody-modal',
    templateUrl: './waterbody-modal.component.html',
    styleUrls: ['./waterbody-modal.component.scss'],
})

export class WaterbodyModalComponent implements OnInit {
    waterBodyId = '';
    waterBodyName: string = '';
    countriesByWaterBody: Array<CountryWaterBody>;
    statesByCountry: Array<StateByCountry>;
    waterBodies: Array<WaterBody> = [];
    showFilterSection: boolean = true;
    type: string = '';
    fullName: string = '';
    selection: any;
    countryByWaterBody: CountryWaterBody;
    stateByCountry: StateByCountry;
    @ViewChildren('tr') private rows: QueryList<ElementRef>;
    selectDisable: boolean;

    constructor(
        private offshoreAddressService: OffshoreAddressService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public messageHandlerService: MessageHandlerService,
        public translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    private loadData() {
        this.selectDisable = true;
        this.countriesByWaterBody = [];
        this.offshoreAddressService
            .getAllCountriesWaterBodyByid()
            .subscribe((result: CountryWaterBody[]) => {
                for (let i = 0; i < result.length; i++) {
                    this.countriesByWaterBody.push(result[i]);
                }
                this.loadStatesDropdown('');
                this.loadWaterBodiesList();
            });
    }

    private loadStatesDropdown(countryId: string) {
        this.statesByCountry = [];
        if (countryId === '') {
            this.selectDisable = true;
        } else {
            this.offshoreAddressService
                .getStatesByCountries(countryId)
                .subscribe((result: StateByCountry[]) => {
                    for (let i = 0; i < result.length; i++) {
                        this.statesByCountry.push(result[i]);
                    }
                    this.selectDisable = false;
                    this.focusFirstElement();
                });
        }
    }

    public loadWaterBodiesList() {
        this.offshoreAddressService
            .getAllWaterBodies()
            .subscribe((waterBodies: WaterBody[]) => {
                this.waterBodies = waterBodies;
                this.focusFirstElement();
            });
    }

    public loadWaterBodyByParams(countryId?: string, stateId?: any, waterBodyId?: string, waterBodyName?: string) {
        stateId = stateId != '0' ? stateId : '';
        countryId = countryId != null ? countryId : '';

        if (countryId === '' && stateId === '' && waterBodyName === '') {
            this.messageHandlerService.show(this.translate.instant('site.errorSearchWaterBody'), MessageType.ERROR)
            return;
        }
        this.offshoreAddressService.searchOffshoreWaterBody(true, countryId, stateId, waterBodyId, waterBodyName)
            .subscribe((waterBodies: WaterBody[]) => {
                this.waterBodies = waterBodies;
                if (waterBodies.length > 0) {
                } else {
                    this.messageHandlerService.show(this.translate.instant('site.waterBodyNotFound'), MessageType.INFO)
                }
            });
    }

    onRowDblclick(selRow: any) {
        this.returnWaterbody(selRow);
    }

    private returnWaterbody(selRow: any) {
        let result = {
            waterBodyId: selRow.waterBodyId,
            waterBodyName: selRow.waterBodyName,
        };
        this.activeModal.close(result);
    }

    onRowSelect(event: any) {
        this.selection = event.data;
    }

    addRowSingleClick() {
        this.onRowDblclick(this.selection);
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selection", event, entityArray, hasHeader);
    }

    closeModal() {
        this.activeModal.close();
    }

    clear() {
        this.countryByWaterBody = null;
        this.stateByCountry = null;
        this.waterBodyName = '';
        this.waterBodies = null;
        this.loadData();
    }

    changeCountry(countryWaterBody: CountryWaterBody) {
        this.statesByCountry = [];
        this.loadStatesDropdown(this.countryByWaterBody ? this.countryByWaterBody.countryId : '');
    }

    private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 150);
    }
    
}

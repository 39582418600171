import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/core/models/constants';
import { AddressType } from 'src/app/core/models/enumerations/address-type';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { BaseComponent } from '../../base.component';
import { CountryWaterBody, OffshoreAddress, OffshoreArea, OffshoreField, WaterBody } from '../../core/models/offshoreAddress';
import { MessageResponse, MessageType } from '../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { AddressImportModalComponent } from '../modals/address-import-modal/address-import-modal.component';
import { CountryStateModalComponent } from '../modals/country-state-modal/country-state-modal.component';
import { LocationSearchComponent, LocationSearchParameters } from '../modals/location-search/location-search.component';
import { WaterbodyModalComponent } from '../modals/waterbody-modal/waterbody-modal.component';
import { OffshoreAddressService } from './offshore-address.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { Plants } from 'src/app/core/models';
import { SiteResult } from 'src/app/core/models/site';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-offshore-address-form',
    templateUrl: './offshore-address-form.component.html',
    styleUrls: ['./offshore-address-form.component.scss']
})

export class OffshoreAddressFormComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() addressType: AddressType = AddressType.MAILING;
    @Input() addressTitle: string;
    @Input() showChkSameAsPhys: boolean = false;
    @Input() showGeoOffshore: boolean = false;
    @Input() showInContacts: boolean;
    @Input() changedFieldsReMap?: Map<string, string> = null;
    @Input() changedFieldsAddress?: Map<string, string> = null;
    @Input() changedFieldsOffAddress?: Map<string, string> = null;
    @Input() matchAddress: boolean;
    @Input() assetId: number;
    @Input() offshore: boolean;
    @Input() addressImportData: Array<Plants> | SiteResult;
    @Input() entityType: string;
    @Input() baseIndex: number;
    @Output() addressChanges: EventEmitter<OffshoreAddress> = new EventEmitter();

    private fieldsChangeAddressSub: Subscription;
    private discardEventSubscription: Subscription;
    private fieldsChangeSub: Subscription;
    private disableFormSub: Subscription;
    formGroupAddress: FormGroup;
    fieldChangeAddress: Object;
    offshoreAddress: OffshoreAddress;
    offAddress: OffshoreAddress;
    copyOrigOffshoreCtryDesigName: string;
    colors: any = {};
    showPlantOffshoreMessage: boolean = false;
    countryAndStateModalOpened: boolean = false;


    @ViewChild('focusFieldName', { static: false }) focusFieldName: ElementRef;
    @ViewChild('focusWaterBodyId', { static: false }) focusWaterBodyId: ElementRef;

    constructor(
        public fb: FormBuilder,
        private dialogService: DialogService,
        private offshoreAddressService: OffshoreAddressService,
        private messageHandler: MessageHandlerService,
        private sharedService: SharedService,
        private headerService: HeaderService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        public translate: TranslateService
    ) { super(recordLockingFlow, preferencesService) }

    ngOnInit() {
        this.offshoreAddress = new OffshoreAddress(null, '', '', '', '', '', '', '', 0, 0, 0, 0);
        this.buildForm();
        this.initFormReMap();

        this.fieldsChangeAddressSub = this.fieldsChange$.subscribe(property => {
            this.fieldChangeAddress = property;
            this.addressChanges.emit(this.createFromForm());
        });

        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => {
                this.formGroupAddress.controls.origOffshoreCtryDesigName.disable();
                this.formGroupAddress.controls.origOffshoreStateDesigName.disable();
                this.formGroupAddress.controls.waterBodyName.disable();
                this.formGroupAddress.controls.areaName.disable();
            });

        this.discardEventSubscription = this.headerService.discardEvent$.subscribe(discard => {
            if (discard) {
                this.setStateForm(this.formGroupAddress);
                this.initFormReMap();
            }
        });
        this.fielChangeSub();
    }

    initFormReMap() {
        if (this.addressType == AddressType.PHYSICAL) {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsOffAddress);
        } else if (this.addressType == AddressType.MAILING && !this.showInContacts) {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsAddress);
        } else {
            this.watchChangeForm(this.formGroupAddress, [], this.changedFieldsReMap);
        }
    }

    ngOnDestroy() {
        this.fieldsChangeAddressSub.unsubscribe();
        this.fieldsChangeSub.unsubscribe();
        this.discardEventSubscription.unsubscribe();
        this.disableFormSub.unsubscribe();
    }

    fielChangeSub() {
        this.fieldsChangeSub = this.fieldsChange$.subscribe(property => {
            if (Object.keys(property).length > 0) {
                this.headerService.changeForm = true;
            }
        });
    }

    setValueControls(offshoreAddress: OffshoreAddress) {
        this.offshoreAddress = offshoreAddress;
        this.copyOrigOffshoreCtryDesigName = offshoreAddress.origOffshoreCtryDesigName;
        this.formGroupAddress.controls.originOffshoreAreaId.setValue(offshoreAddress.originOffshoreAreaId);
        this.formGroupAddress.controls.origOffshoreStateDesignation.setValue(offshoreAddress.origOffshoreStateDesignation);
        this.formGroupAddress.controls.origOffshoreCtryDesigName.setValue(offshoreAddress.origOffshoreCtryDesigName);
        this.formGroupAddress.controls.origOffshoreStateDesigName.setValue(offshoreAddress.origOffshoreStateDesigName);
        this.formGroupAddress.controls.originOffshoreBlockV1.setValue(offshoreAddress.originOffshoreBlockV1);
        this.formGroupAddress.controls.originOffshoreBlockV2.setValue(offshoreAddress.originOffshoreBlockV2);
        this.formGroupAddress.controls.originOffshoreWaterbodyId.setValue(offshoreAddress.originOffshoreWaterbodyId);
        this.formGroupAddress.controls.waterBodyName.setValue(offshoreAddress.waterBodyName);
        this.formGroupAddress.controls.originOffshoreFieldId.setValue(offshoreAddress.originOffshoreFieldId);
        this.formGroupAddress.controls.originOffshoreFieldName.setValue(offshoreAddress.originOffshoreFieldName);
        this.formGroupAddress.controls.areaName.setValue(offshoreAddress.areaName);
        this.showPlantOffshoreMessage =  this.entityType === EntityName.PLANT;
    }

    setOffAddress(offshoreAddress: OffshoreAddress) {
        this.offAddress = offshoreAddress;
    }

    createFromForm(): OffshoreAddress {
        return new OffshoreAddress(null,
            this.formGroupAddress.get(['origOffshoreCtryDesigName'])!.value,
            this.formGroupAddress.get(['origOffshoreStateDesigName'])!.value,
            this.formGroupAddress.get(['originOffshoreBlockV1'])!.value,
            this.formGroupAddress.get(['originOffshoreBlockV2'])!.value,
            this.formGroupAddress.get(['waterBodyName'])!.value,
            this.formGroupAddress.get(['originOffshoreFieldName'])!.value,
            this.formGroupAddress.get(['areaName'])!.value,
            this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value,
            this.formGroupAddress.get(['originOffshoreFieldId'])!.value,
            this.formGroupAddress.get(['originOffshoreAreaId'])!.value,
            this.formGroupAddress.get(['origOffshoreStateDesignation'])!.value,
        );
    }

    setFontColor(controlName: string, color: string): void {
        this.colors[`${controlName}`] = color;
    }

    private buildForm() {
        this.formGroupAddress = this.fb.group({
            origOffshoreCtryDesigName: [''],
            origOffshoreStateDesigName: [''],
            origOffshoreStateDesignation: [''],
            originOffshoreBlockV1: ['', Validators.required],
            originOffshoreBlockV2: [''],
            waterBodyName: [''],
            originOffshoreFieldName: ['', Validators.required],
            areaName: [''],
            originOffshoreWaterbodyId: ['', Validators.required],
            originOffshoreAreaId: [''],
            originOffshoreFieldId: [''],
        });
        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => disable ? this.formGroupAddress.disable() : this.formGroupAddress.enable());
    }

    isNotMailingAddress() {
        return (this.addressType !== AddressType.MAILING);
    }

    clearForm(origOffshoreCtryDesigName: string) {
        let address: OffshoreAddress = new OffshoreAddress(null, origOffshoreCtryDesigName, '', '', '', '', '', '', null, 0, 0, 0);
        this.setValueControls(address);
    }

    resetForm() {
        this.formGroupAddress.reset();
        this.fieldChangeAddress = null;
        this.resetChangedFields();
    }

    openWaterbodySearchModal() {
        const modalRef = this.dialogService.open(WaterbodyModalComponent, {
            header: 'Water Body Search',
            width: '40rem',
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((waterBody: any) => {
            if (waterBody !== undefined) {
                this.openCountryAndStateModal(waterBody);
                this.formGroupAddress.controls.originOffshoreWaterbodyId.setValue(waterBody.waterBodyId);
                this.formGroupAddress.controls.waterBodyName.setValue(waterBody.waterBodyName);
                this.formGroupAddress.controls.originOffshoreWaterbodyId.markAsDirty();
                this.formGroupAddress.controls.waterBodyName.markAsDirty();
            }
        });
    }
    openCountryAndStateModal(waterBody: WaterBody[], countriesByWaterBody?: CountryWaterBody[]) {
        this.countryAndStateModalOpened = true;
        const modalRef = this.dialogService.open(CountryStateModalComponent, {
            header: 'Select Country and State',
            width: '40rem',
            data: { waterBody, countriesByWaterBody },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((response: any) => {
            this.countryAndStateModalOpened = false;
            if (response !== undefined) {
                this.formGroupAddress.controls.origOffshoreCtryDesigName.markAsDirty();
                this.formGroupAddress.controls.origOffshoreStateDesigName.markAsDirty();
                this.formGroupAddress.controls.origOffshoreStateDesignation.markAsDirty();
                this.formGroupAddress.controls.origOffshoreStateDesignation.setValue(response.sId);
                this.formGroupAddress.controls.origOffshoreCtryDesigName.setValue(response.countryName);
                this.formGroupAddress.controls.origOffshoreStateDesigName.setValue(response.stateName);
                this.offshoreAddress.origOffshoreStateDesignation = response.sId;
                this.offshoreAddress.origOffshoreCtryDesigName = response.countryName;
                this.offshoreAddress.originOffshoreWaterbodyId = this.formGroupAddress.controls.originOffshoreWaterbodyId.value;
            } else {
                this.formGroupAddress.controls.origOffshoreCtryDesigName.setValue('');
                this.formGroupAddress.controls.origOffshoreStateDesigName.setValue('');
                this.formGroupAddress.controls.originOffshoreWaterbodyId.setValue('');
                this.formGroupAddress.controls.waterBodyName.setValue('');
                this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                this.formGroupAddress.controls.areaName.setValue('');
                this.focusWaterBodyId.nativeElement.focus();
            }
        });
    }

    openFieldSearchModal() {
        if (this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value == '') {
            const confirmOk = (resp: MessageResponse): void => {
                this.focusFieldName.nativeElement.focus();
            }
            this.messageHandler.show(Constants.ERROR_SELECT_WATERBODY_FIRST, MessageType.ERROR,confirmOk)
            return;
        }
        let dialogParameters: LocationSearchParameters = {
            type: 'field',
            waterBodyId: this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value,
            waterBodyName: this.formGroupAddress.get(['waterBodyName'])!.value,
            fieldName: this.formGroupAddress.get(['originOffshoreFieldName'])!.value
        };
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: 'Select Field Name',
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        let loadAreas: boolean = false;
        modalRef.onClose.subscribe((response: any) => {
            if (response !== undefined) {
                this.formGroupAddress.controls.originOffshoreFieldId.markAsDirty();
                this.formGroupAddress.controls.originOffshoreFieldName.markAsDirty();
                this.offshoreAddress.originOffshoreFieldId = response.fieldId;
                this.formGroupAddress.controls.originOffshoreFieldId.setValue(response.fieldId);
                this.formGroupAddress.controls.originOffshoreFieldName.setValue(response.fieldName);
                loadAreas = true;
            }
        });
        modalRef.onDestroy.subscribe(() => {
            if(loadAreas){
                loadAreas = false;
                this.loadAreas();
            }
        });
    }

    private loadAreas() {
        const waterBodyId = this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value;
        const fieldId = this.formGroupAddress.get(['originOffshoreFieldId'])!.value;
        this.offshoreAddressService.getAreaSearch(waterBodyId, fieldId, false, null, '')
            .subscribe((areas: OffshoreArea[]) => {
                if (areas.length == 1) {
                    this.formGroupAddress.controls.originOffshoreAreaId.markAsDirty();
                    this.formGroupAddress.controls.areaName.markAsDirty();
                    this.offshoreAddress.originOffshoreAreaId = areas[0].originOffshoreAreaId;
                    this.formGroupAddress.controls.originOffshoreAreaId.setValue(areas[0].originOffshoreAreaId);
                    this.formGroupAddress.controls.areaName.setValue(areas[0].areaName);
                } else if (areas.length > 1) {
                    this.openAreaSearchModal(areas);
                }
            });
    }

    openAreaSearchModal(areas: OffshoreArea[]) {
        let dialogParameters: LocationSearchParameters = {
            type: 'area',
            fieldId: this.formGroupAddress.get(['originOffshoreFieldId'])!.value,
            areas: areas
        };
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: 'Select Area Name',
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((response: any) => {
            if (response !== undefined) {
                this.formGroupAddress.controls.originOffshoreAreaId.markAsDirty();
                this.formGroupAddress.controls.areaName.markAsDirty();

                this.offshoreAddress.originOffshoreAreaId = response.originOffshoreAreaId;
                this.formGroupAddress.controls.originOffshoreAreaId.setValue(response.originOffshoreAreaId);
                this.formGroupAddress.controls.areaName.setValue(response.areaName);
            } else {
                this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                this.cleanArea();
                this.focusFieldName.nativeElement.focus();
            }
        });
    }

    cleanArea() {
        if (this.formGroupAddress.get('originOffshoreFieldName').value === '') {
            this.formGroupAddress.controls.originOffshoreFieldId.setValue('');
            this.formGroupAddress.controls.originOffshoreAreaId.setValue('');
            this.formGroupAddress.controls.areaName.setValue('');
        }
    }

    fieldNameChangeEnterKey() {
        this.searchField();
    }

    searchField() {
        const waterBodyId = this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value;
        if (this.isNullOrEmpty(waterBodyId)){
            const confirmOk = (resp: MessageResponse): void => {
                this.focusFieldName.nativeElement.focus();
            }
            this.messageHandler.show(Constants.ERROR_SELECT_WATERBODY_FIRST, MessageType.ERROR,confirmOk)
            return;
        }
        const fieldName = this.formGroupAddress.get(['originOffshoreFieldName'])!.value;

        if (fieldName === '' || fieldName === null) {
            this.openFieldSearchModal();
        } else {
            this.offshoreAddressService.getOffshoreFields(waterBodyId, fieldName, true)
                .subscribe((field: OffshoreField[]) => {
                    if (field.length > 0) {
                        if (field.length == 1) {
                        this.formGroupAddress.controls.originOffshoreFieldId.markAsDirty();
                        this.formGroupAddress.controls.originOffshoreFieldName.markAsDirty();
                        this.offshoreAddress.originOffshoreFieldName = field[0].fieldName;
                        this.formGroupAddress.controls.originOffshoreFieldId.setValue(field[0].fieldId);
                        this.formGroupAddress.controls.originOffshoreFieldName.setValue(field[0].fieldName);
                        this.loadAreas();
                        } else {
                            this.openFieldSearchModal();
                        }
                    } else {
                        this.formGroupAddress.controls.originOffshoreFieldId.setValue('');
                        this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                        this.formGroupAddress.controls.areaName.setValue('');
                    }
                });
        }
    }

    waterBodyChangeOnEnter() {
        this.searchWaterBody();
    }

    waterBodyChangeFocusOut() {
        if (this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value != '' && !this.countryAndStateModalOpened) {
            this.waterBodyChangeOnEnter();
        }
    }

    cleanFields() {
        if (this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value == '') {
            this.formGroupAddress.controls.waterBodyName.setValue('');
            this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
            this.formGroupAddress.controls.areaName.setValue('');
            this.formGroupAddress.controls.origOffshoreCtryDesigName.setValue('');
            this.formGroupAddress.controls.origOffshoreStateDesigName.setValue('');
        }
    }

    private searchWaterBody() {
        const waterBodyId = this.formGroupAddress.get(['originOffshoreWaterbodyId'])!.value;
        const waterBodyName = this.formGroupAddress.get(['waterBodyName'])!.value;

        if (waterBodyId === this.offshoreAddress.originOffshoreWaterbodyId && waterBodyName === this.offshoreAddress.waterBodyName) {
            return;
        }

        if (waterBodyId === '' || waterBodyId === null) {
            this.openWaterbodySearchModal();
        } else {
            this.offshoreAddressService.getWaterBodyById(true, waterBodyId)
                .subscribe((waterBody: WaterBody[]) => {
                    if (waterBody.length > 0) {
                        this.formGroupAddress.controls.originOffshoreWaterbodyId.markAsDirty();
                        this.formGroupAddress.controls.waterBodyName.markAsDirty();
                        this.formGroupAddress.controls.originOffshoreFieldName.markAsDirty();
                        this.formGroupAddress.controls.areaName.markAsDirty();
                        this.offshoreAddress.waterBodyName = waterBody[0].waterBodyName;
                        this.formGroupAddress.controls.waterBodyName.setValue(waterBody[0].waterBodyName);
                        this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                        this.formGroupAddress.controls.areaName.setValue('');
                        this.offshoreAddressService.getCountriesWaterBodyById(true, waterBodyId)
                            .subscribe((countriesByWaterBody: CountryWaterBody[]) => {
                                if (countriesByWaterBody.length > 1) {
                                    this.openCountryAndStateModal(waterBody, countriesByWaterBody);
                                } else if (countriesByWaterBody.length == 0) {
                                    this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                                    this.formGroupAddress.controls.areaName.setValue('');
                                    this.formGroupAddress.controls.originOffshoreWaterbodyId.setValue('');
                                    this.formGroupAddress.controls.waterBodyName.setValue('');
                                } else if (countriesByWaterBody.length == 1) {
                                    this.formGroupAddress.controls.originOffshoreWaterbodyId.markAsDirty();
                                    this.formGroupAddress.controls.waterBodyName.markAsDirty();
                                    this.formGroupAddress.controls.origOffshoreCtryDesigName.markAsDirty();
                                    this.formGroupAddress.controls.origOffshoreStateDesigName.markAsDirty();
                                    this.formGroupAddress.controls.origOffshoreStateDesignation.markAsDirty();
                                    this.offshoreAddress.origOffshoreStateDesignation = countriesByWaterBody[0].sId;
                                    this.formGroupAddress.controls.origOffshoreStateDesignation.setValue(countriesByWaterBody[0].sId);
                                    this.offshoreAddress.origOffshoreCtryDesigName = countriesByWaterBody[0].countryName;
                                    this.formGroupAddress.controls.origOffshoreCtryDesigName.setValue(countriesByWaterBody[0].countryName);
                                    this.formGroupAddress.controls.origOffshoreStateDesigName.setValue(countriesByWaterBody[0].stateName);
                                    this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                                    this.formGroupAddress.controls.areaName.setValue('');
                                    this.focusFieldName.nativeElement.focus();
                                }
                            });
                    } else {
                        this.formGroupAddress.controls.originOffshoreFieldName.setValue('');
                        this.formGroupAddress.controls.areaName.setValue('');
                        this.formGroupAddress.controls.originOffshoreWaterbodyId.setValue('');
                        this.formGroupAddress.controls.waterBodyName.setValue('');
                        this.focusWaterBodyId.nativeElement.focus();
                    }
                });
        }
    }

    refreshFieldsByChanges() {
        this.forceFieldChange('originOffshoreBlockV1');
        this.forceFieldChange('originOffshoreBlockV2');
        this.forceFieldChange('originOffshoreWaterbodyId');
        this.forceFieldChange('originOffshoreFieldName');
        this.forceFieldChange('originOffshoreFieldId');
        this.forceFieldChange('originOffshoreAreaId');
        this.forceFieldChange('origOffshoreStateDesignation');
    }

    openModalAddressImport() {
        const modalRef = this.dialogService.open(AddressImportModalComponent, {
            header: this.addressType === AddressType.PHYSICAL ?
                this.translate.instant('addressComparation.physTitle') :
                this.translate.instant('addressComparation.mailTitle'),
            width: '70rem',
            dismissableMask: true,
            data: {
                addressImportData: this.addressImportData,
                target: this.offshoreAddress,
                entityType: this.entityType,
                entityId: this.assetId,
                isPhysical: this.addressType === AddressType.PHYSICAL,
                isOffshore: true,
                isLock: this.formGroupAddress.disabled
            },
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((address: OffshoreAddress) => {
            if (address !== undefined) {
                this.setValueControls(address);
                this.refreshFieldsByChanges();
            }
        });
    }

}



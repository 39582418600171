import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// Modules

// Components
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PendingRequestsInterceptor } from '../core/interceptor/pending-requests.interceptor';
import { AsideComponent } from './aside/aside.component';
import { NavBrandComponent } from './aside/nav-brand/nav-brand.component';
import { NavGroupItemComponent } from './aside/nav-group-item/nav-group-item.component';
import { NavUserComponent } from './aside/nav-user/nav-user.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { OrdinalDatePipe } from './ordinal-date.pipe';
import { TopNavComponent } from './top-nav/top-nav.component';
import { HttpLoaderFactory } from '../app.module';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        PanelMenuModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            }
        }),
    ],
    declarations: [
        AsideComponent,
        NavBrandComponent,
        NavUserComponent,
        NavGroupItemComponent,
        FooterComponent,
        HeaderComponent,
        TopNavComponent,
        OrdinalDatePipe
    ],
    exports: [
        AsideComponent,
        NavBrandComponent,
        NavUserComponent,
        NavGroupItemComponent,
        FooterComponent,
        HeaderComponent,
        TopNavComponent,
        PanelMenuModule,
        FormsModule
    ],
    providers: [
        DialogService,
        PendingRequestsInterceptor
    ]
})

export class SharedModule { }

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-verified-fax',
    templateUrl: './verified-fax.component.html',
    styleUrls: ['./verified-fax.component.css']
})
export class VerifiedFaxComponent implements OnInit, OnChanges {

    @Input() lastUser: string;
    @Input() lastDate: string;
    @Input() prevUser: string;
    @Input() prevDate: string;

    lastPrevLabel: string;
    verifiedFaxUser: string = '';
    verifiedFaxDate: string = '';
    readonly LAST_VALUE: string;
    readonly PREV_VALUE: string;

    constructor(private translate: TranslateService) {
        this.LAST_VALUE = this.translate.instant('common.last');
        this.PREV_VALUE = this.translate.instant('common.prev');
    }

    ngOnInit(): void {
        this.initComponent();
    }

    verifiedFaxEvent() {
        if (this.lastPrevLabel === this.LAST_VALUE) {
            this.lastPrevLabel = this.PREV_VALUE;
            this.verifiedFaxUser = this.prevUser;
            this.verifiedFaxDate = this.prevDate;
        }
        else {
            this.lastPrevLabel = this.LAST_VALUE;;
            this.verifiedFaxUser = this.lastUser;
            this.verifiedFaxDate = this.lastDate;
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        this.initComponent();
        this.verifiedFaxUser = this.lastUser;
        this.verifiedFaxDate = this.lastDate;

    }

    initComponent() {
        this.lastPrevLabel = this.lastUser === null ? '' : this.LAST_VALUE;
    }

}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe implements PipeTransform {
  transform(value: bigint | string): string {
    return value !== null ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
  }
}

import { Injectable } from '@angular/core';
import { INote } from 'src/app/core/interfaces/inotes';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { MVTOperations } from 'src/app/core/mvt-operations';

@Injectable({
    providedIn: 'root'
})

export class NoteService {
    deleteEmptyComments(notes: Array<INote>): void {
        let emptyNotes = notes.filter(note =>
            note.getOperation() !== OperationType.DELETE
            && (note.comments === null || note.comments === undefined
                || note.comments.trim() === ''));
        if (emptyNotes.length > 0) {
            emptyNotes.forEach(note => {
                MVTOperations.removeItem(notes, 'mvOrder', String(note.mvOrder));
            });
        }
    }

}

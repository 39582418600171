<div class="header" *ngIf="showHeader">
    <h3>{{ title }}</h3>
</div>
<div class="cont-img-icon" *ngIf="showHeader">
    <img *ngIf="!classToggled" src="assets/icons/reload.png" width="15px" height="15px" class="mt-1 mr-1 cursor-pointer"
        (click)="reload()">
    <div class="bg-icon">
        <img *ngIf="classToggled" src="assets/icons/plus.svg" (click)="toggleField()" width="10px">
        <img *ngIf="!classToggled" src="assets/icons/minus.svg" (click)="toggleField()" width="10px">
    </div>
</div>
<div *ngIf="addRecordDestinationPage != null" class="grid col-12 -mt-2" [class.toggled]="classToggled">
    <button
        *ngIf="addRecordDestinationPage != null"
        class="btn-row w-max"
        type="button"
        [disabled]="isAddButtonDisabled()"
        [tabindex]="baseIndex"
        [title]="(callerEntityDisplay === undefined || this.addRecordDestinationPage === 'notyet') ? ('common.entityNotAvailable' | translate) : addButton"
        (click)="addRecordFunction(implementedComponent, addRecordDestinationPage)">
        {{addButton}}
    </button>
</div>
<div class="mt-2" [class.toggled]="classToggled">
    <app-base-mvt-table-selector
        #mvtEntityAssociatorComponent
        [entitiesArray]="rows"
        [parentEntityId]="getCallerEntityId()"
        [editable]="true"
        [tableKey]="tableKey"
        [defaultRowsPerPage]="defaultRowsPerPage"
        [preferences]="preferences"
        [columnsInfo]="columnsInfo"
        [componentDescription]="''"
        [showHeader]="false"
        [showAddRowButton]="false"
        [showPaginator]="true"
        [sortingMode]="'single'"
        [defaultSortingProperty]="''"
        [emptyMessage]="notFound"
        [baseIndex]="baseIndex + 1"

        [implementedComponent]="implementedComponent">
    </app-base-mvt-table-selector>
</div>

import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, Subject, firstValueFrom, of} from "rxjs";
import { catchError, map, throttleTime } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ApiEndpoint } from "../models/constants";
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { MessageType } from "src/app/components/messages/message-handler/message-handler.component";

/**
 * This provides a way to obtain the current date, but more in sync with the server time in case of a client-side clock drift/discrepancy
 */

@Injectable({providedIn: 'root'})
export class ServerDateService {

   private static readonly MIN_UPDATE_INTERVAL_MS:number = 10 * 1000;
   private static readonly CACHELIFETIME_MS:number = 60 * 60 * 1000;
   private static readonly DISCREPANCY_TOLERANCE_MS:number = 10 * 60 * 1000;

   private readonly updateRequestSubject = new Subject<void>();
   
   constructor(private http: HttpClient,   
                private session: SessionService,
                private messageHandlerService: MessageHandlerService,
                ) { }
   
   private saveValue(value?: number): ITimestampInfo
   {
      const now: number = Date.now();
      const sDate: ITimestampInfo = {
         cachedValue: value ?? now,
         cachedAt: now,
      };
      
      this.session.setAttribute(SessionAttribute.ServerDate, sDate);
      return sDate;      
   }   
   
   /**
    * Asynchronously Updates the cached date information with the latest from the server
    */
   private retrieveServerDate(): Observable<void>
   {
      return this.http.get<string>(environment.apiUrl + ApiEndpoint.ServerDate)
         .pipe(
            map((data: any) => {
               // Save the new value ASAP
               const sDate: ITimestampInfo = this.saveValue(data.response);

               // Warn if it's way off
               const discrepancy = Math.abs(sDate.cachedValue - sDate.cachedAt);
               if(discrepancy > ServerDateService.DISCREPANCY_TOLERANCE_MS)
               {
                  this.messageHandlerService.show(`Your system clock appears to be different from the server by ${Math.floor(discrepancy / 1000)} seconds. This may cause unexpected behaviors regarding dates/times within the applicaiton.`, MessageType.WARNING);
               }           
            }),
            catchError((err) => {
               // Save a value ASAP
               this.saveValue();
               console.error(`Could not retrieve server date: (${err.status}) ${err.message}`);
               this.messageHandlerService.show('There was an issue syncing with the server clock, trusting local system time instead.', MessageType.WARNING);
               return EMPTY;
            })            
         );
   }

   /**
    * Use this to load a seed value during application initialization
    * NOTE: This is intended to only be called from app.module.ts 
    */
   public init(): Observable<void> 
   {
      // Enable future updates, safely
      this.updateRequestSubject
         .pipe(throttleTime(ServerDateService.MIN_UPDATE_INTERVAL_MS))
         .subscribe(() => this.retrieveServerDate().subscribe());

      const sDate: ITimestampInfo = this.session.getAttribute(SessionAttribute.ServerDate);
      if(sDate == null)
      {
         // No existing value, so we need to load one
         return this.retrieveServerDate();
      }

      return EMPTY;
   }   

   /**
    * Returns a very close approximation of the server date without having to ask the server every time
    */
   public now(): Date {
      
      // It is vital that we got a value at least once prior to this function being called
      let sDate: ITimestampInfo = this.session.getAttribute(SessionAttribute.ServerDate);
      const hadCachedValue: boolean = typeof sDate != 'undefined' && sDate !== null;
      if(!hadCachedValue)
      {
         console.warn('No cached server date value available, using local clock instead.');
         sDate = this.saveValue();
      }
      
      const now: number = Date.now();
      const cacheAge: number = now - sDate.cachedAt;
      if(!hadCachedValue || cacheAge > ServerDateService.CACHELIFETIME_MS)
      {
         // Value is stale, so request an updated one
         this.updateRequestSubject.next();
      }
      
      return new Date(sDate.cachedValue + cacheAge);
   }
}

// This is the format of the object that is persisted in the session
interface ITimestampInfo {
   cachedValue: number;  // The time value obtained from the server
   cachedAt: number;     // The local time at it was captured
}

import { DecimalPipe } from '@angular/common';
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

const DEFAULT_MAX_DIGITS = 7;

@Directive({
  selector: '[appFormattedWholeNumber]'
})
export class appFormattedWholeNumberDirective implements OnInit {

  private previousValue: any = '';
  @Input() rowData: any;
  @Input() rowField: any;
  @Input() wholeDigits: number;
  constructor(private decimalPipe: DecimalPipe,
              private ngControl: NgControl) { }

  ngOnInit(): void {
    const maxLength: number = this.wholeDigits ?? DEFAULT_MAX_DIGITS;
    this.ngControl.control.valueChanges.subscribe(() => {
      if(this.ngControl.value !== null){
        let unformattedValue = String(this.ngControl.value).replace(/,/g, '').replace(/[^0-9.]/g, '');
        let formattedValue = this.decimalPipe.transform(unformattedValue);
        if(String(this.ngControl.value) !== formattedValue){
            if(unformattedValue.length > maxLength) {
                formattedValue = this.previousValue;
            }

            this.ngControl.control.setValue(formattedValue);
        }
      }

    });
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    this.refactorValue(event);
  }

  private refactorValue(event: InputEvent){
    const input = event.target as HTMLInputElement;
    const rawValue = input.value.replace(/,/g, '');
    const maxLength: number = this.wholeDigits ?? DEFAULT_MAX_DIGITS;

    if (!new RegExp('^[0-9]{0,'+maxLength+'}$').test(rawValue)) {
      input.value = this.previousValue;
      input['ng-reflect-model'] = this.previousValue
      if(this.rowData !== undefined && 
        this.rowField !== undefined && 
            this.rowData[this.rowField] !== undefined){
        this.rowData[this.rowField] = input.value;
    }  
    }else{
      input.value = this.decimalPipe.transform(rawValue, '0.0-0')
      this.previousValue = input.value;
    }
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Third Party
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
// Environment
import { environment } from 'src/environments/environment';
import { UserDetail } from '../models/user-detail';

@Injectable({
   providedIn: 'root'
})

export class RegistrationService {

   constructor(
      private http: HttpClient) { }

   @Cacheable({
      storageStrategy: LocalStorageStrategy
   })
   getOperatingMode(): Observable<string> {
      return this.http.get(`${environment.apiUrl}registration/getOperatingMode`)
         .pipe(
            map((data: any) => data.response)
         );
   }

   @Cacheable({
      storageStrategy: LocalStorageStrategy,
      maxCacheCount: 10  // Limit how many different users we keep track of
   })
   getUserDetailInfo(userName: string): Observable<UserDetail> {
      return this.http.get<UserDetail>(`${environment.apiUrl}registration/user/details/${userName}`).pipe(
         map((data: any) => {
            let userInfo = null;
            if (data.response != null) {
               userInfo = UserDetail.BuildUserDetailData(data.response[0]);
            }
            return userInfo;
         }),
      );
   }

}
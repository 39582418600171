import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PlantsOutputInfoDisplay } from 'src/app/core/models/plant/display/plants-output-info-display';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { ProductInfoModalComponent } from '../modals/product-info-modal/product-info-modal.component';
import { PlantsProductInfoDisplay } from 'src/app/core/models/plant/display/plants-product-info-display';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { MessageType } from '../messages/message-handler/message-handler.component';
import { SessionService } from 'src/app/core/services/session.service';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
import { DatePipe } from '@angular/common';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { SharedService } from 'src/app/core/services/shared.service';
import { ServerDateService } from 'src/app/core/services/serverdate.service';


@Component({
    selector: 'app-output-product',
    templateUrl: './output-product.component.html',
    styleUrls: ['./output-product.component.scss']
})
export class OutputProductComponent implements OnInit {

    @ViewChild('mvtEntityAssociatorComponent', { static: false }) mvtEntityAssociatorComponent: MVTEntityAssociatorComponent;

    @Input() entitiesArray: Array<PlantsOutputInfoDisplay> = [];
    @Input() parentEntityId: number;
    @Input() editable: boolean = false;
    @Input() disabledButton: boolean = false;
    @Input() industryCode: number;
    @Input() baseIndex: number;
    @Output() onChanges: EventEmitter<Array<PlantsOutputInfoDisplay>> = new EventEmitter();

    @Input() plantsProductInfoDisplay: Array<PlantsProductInfoDisplay>;

    constructor(
        private session: SessionService,
        private serverDate: ServerDateService,
        public translate: TranslateService,
        public dialogService: DialogService,
        private messageHandler: MessageHandlerService,
        public datePipe: DatePipe,
        public sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        const now = this.serverDate.now();
        columnsInfo.push({ draggable: true, widthPercentage: 5 })
        columnsInfo.push({ isEntityId: true, entityPropName: 'outputHsProduct', searchedEntityPropName:'hsProduct', columnHeader: 'plant.outputProduct.productID', editableType: ColumnEntityInfoEditableType.numberField, maxLength: 7, widthPercentage: 15 });
        columnsInfo.push({entityPropName:'productDesc', searchedEntityPropName:'productDesc', columnHeader:'plant.outputProduct.productDesc', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage:20});
        columnsInfo.push({entityPropName:'outputUnitMeasure', searchedEntityPropName:'hsUnit', columnHeader:'plant.outputProduct.uom', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage:16});
        columnsInfo.push({entityPropName:'outputDesignCapacity', columnHeader:'plant.outputProduct.design', editableType: ColumnEntityInfoEditableType.numberField, formatThousands: true, maxLength:16, maxDecimals:10, widthPercentage:16, headerAlign: 'right', bodyAlign: 'right'});
        columnsInfo.push({entityPropName:'outputActualCapacity', columnHeader:'plant.outputProduct.actual', editableType: ColumnEntityInfoEditableType.numberField, formatThousands: true, maxLength:16, maxDecimals:10, widthPercentage:16, headerAlign: 'right', bodyAlign: 'right'});
        columnsInfo.push({entityPropName:'verifiedQcDate', columnHeader:'plant.outputProduct.verified', editableType: ColumnEntityInfoEditableType.calendarField, widthPercentage:16, maxValue: now});
        columnsInfo.push({displayValueFunc:this.lastVerifiedQcDate, columnHeader:'plant.outputProduct.lastVerified', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage:16});
        columnsInfo.push({ entityPropName:'delete', editableType: ColumnEntityInfoEditableType.deleteField, widthPercentage: 5 });

        return columnsInfo;
    }

    getExtraEntityPropertiesInfo(): ColumnEntityInfo[] {
        let extraEntityPropertiesInfo: ColumnEntityInfo[] = [];
        extraEntityPropertiesInfo.push({ entityPropName: 'lastVerifiedQcUser' });
        extraEntityPropertiesInfo.push({ entityPropName: 'lastVerifiedQcDate' });
        extraEntityPropertiesInfo.push({ entityPropName: 'capacityQcUser' });
        extraEntityPropertiesInfo.push({ entityPropName: 'capacityQcDate' });

        return extraEntityPropertiesInfo;
    }

    lastVerifiedQcDate(implementedComponent:OutputProductComponent, rowData: PlantsOutputInfoDisplay): string {
        return StringUtils.toStringNeverNull(rowData.lastVerifiedQcUser) + ' ' + StringUtils.toStringNeverNull(implementedComponent.datePipe.transform(rowData.lastVerifiedQcDate, 'dd-MMM-y'));
    }

    openSearchModal(implementedComponent:OutputProductComponent): DynamicDialogRef {
        const modalRef = implementedComponent.dialogService.open(ProductInfoModalComponent, {
            header: implementedComponent.translate.instant('plant.outputProduct.title') + ' Search',
            width: '40rem',
            data: {
                entitiesArray: implementedComponent.entitiesArray,
                products: implementedComponent.plantsProductInfoDisplay
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
        return modalRef;
    }

    createNewEntityInstance(implementedComponent:OutputProductComponent): PlantsOutputInfoDisplay{
        const itemAux: PlantsOutputInfoDisplay = PlantsOutputInfoDisplay.CreateInstance({
            plantId: implementedComponent.parentEntityId,
            mvOrder: MVTOperations.getLastMvOrder(implementedComponent.entitiesArray) + 1,
        });
        return itemAux;
    }

    searchEntityDelegateAsync(implementedComponent: OutputProductComponent, rowData: PlantsOutputInfoDisplay, rowIndex: number): Promise<any> {
        return new Promise<any>((resolve) => {
            const searchParams = { productID: rowData.outputHsProduct, productDesc: '', unitId: '' };
            implementedComponent.sharedService.searchHSProducts(searchParams).subscribe((searchedRowData: any) => {
                const mvtEntityAssociatorComponent = implementedComponent.mvtEntityAssociatorComponent;
                if (!mvtEntityAssociatorComponent.validateEntityExist(rowData, searchedRowData, 'productID', mvtEntityAssociatorComponent.entityIdPropName)) {
                    mvtEntityAssociatorComponent.returnFocus(rowData, implementedComponent.translate.instant('mvtEntityAssociatorComponent.entityDoesNotExist', { entityName: mvtEntityAssociatorComponent.entityName }), rowIndex);
                    mvtEntityAssociatorComponent.clearRow(rowData);
                    resolve(null);
                    return;
                }

                const entityResult: Array<PlantsProductInfoDisplay> = implementedComponent.plantsProductInfoDisplay.filter(s => Number(s.hsProduct) === Number(rowData.outputHsProduct));
                resolve(entityResult);
            });
        });
    }

    onEntityChanges(event: any) {
        this.onChanges.emit(event.entitiesArray);
    }


    onCellEdited(implementedComponent: OutputProductComponent, field: string, rowData: PlantsOutputInfoDisplay): void {
        if (field === 'verifiedQcDate') {
            implementedComponent.changeDate(implementedComponent, rowData);
        }
    }

    changeDate(implementedComponent: OutputProductComponent, row: PlantsOutputInfoDisplay): void {
        if (row.capacityQcUser !== null && row.getOperation() !== OperationType.INSERT) {
            if (row.lastVerifiedQcDate !== null && row.verifiedQcDate < row.lastVerifiedQcDate) {
                row.verifiedQcDate = '';
                implementedComponent.messageHandler.show(implementedComponent.translate.instant('plant.outputProduct.validateVerificationDate'),
                    MessageType.INFO);
            } else if (row.lastVerifiedQcDate !== null && row.verifiedQcDate > row.lastVerifiedQcDate) {
                row.capacityQcUser = implementedComponent.session.getUserName();
                row.capacityQcDate = row.verifiedQcDate;
                row.forceBeanChange('capacityQcUser');
                row.forceBeanChange('capacityQcDate');
            }
        } else {
            row.capacityQcUser = implementedComponent.session.getUserName();
            row.capacityQcDate = row.verifiedQcDate;
            row.forceBeanChange('capacityQcUser');
            row.forceBeanChange('capacityQcDate');
        }

    }

}


import { IAuditEntityData } from 'src/app/core/interfaces/iaudit-entity-data';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { EntityName } from '../../enumerations/entity-name';
import { OperationType } from '../../enumerations/operation-type';
import { EquipmentNotesDisplay } from '../display/equipment-notes-display';
import { Equipment } from '../save/equipments';
import { EquipmentStepUpTransformersDisplay } from './equipment-step-up-transformers-display';

export class EquipmentsDisplay extends Equipment {
    plantId: number;
    plantName: string;
    areaId: number;
    areaName: string;
    ownerId: number;
    ownerName: string;
    unitName: string;
    equipmentVendorName: string;
    manufacturerName: string = null;
    equipmentTypeName: string;

    physAddressV1: string;
    physAddressV2: string;
    physCity: string;
    physState: string;
    physPostalCode: string;
    physCountry: string;
    physCountryName: string;

    plantOffshore: boolean = false;
    offshoreBlockV1: string;
    offshoreWaterbodyName: string;
    offshoreFieldName: string;
    offshoreAreaName: string;

    hsUnit: string;
    hsProductDesc: string;

    lastUserEmailAddress: string = "";
    entryUserEmailAddress: string = "";
    previousUserEmailAddress: string = "";
    qcUserEmailAddress: string = "";
    amendmentUserEmailAddress: string = "";
    releaseUserEmailAddress: string = "";

    equipmentNotesDisplay: EquipmentNotesDisplay[] = [];
    equipmentStepUpTransformersDisplay: EquipmentStepUpTransformersDisplay[] = [];

    constructor(entity?: any) {
        super(entity);

        this.plantId = entity?.plantId ?? null;
        this.plantName = entity?.plantName ?? null;
        this.areaId = entity?.areaId ?? null;
        this.areaName = entity?.areaName ?? null;
        this.ownerId = entity?.ownerId ?? null;
        this.ownerName = entity?.ownerName ?? null;
        this.unitName = entity?.unitName ?? null;
        this.equipmentVendorName = entity?.equipmentVendorName ?? null;
        this.manufacturerName = entity?.manufacturerName ?? null;
        this.equipmentTypeName = entity?.equipmentTypeName ?? null;

        this.physAddressV1 = entity?.physAddressV1 ?? null;
        this.physAddressV2 = entity?.physAddressV2 ?? null;
        this.physCity = entity?.physCity ?? null;
        this.physState = entity?.physState ?? null;
        this.physPostalCode = entity?.physPostalCode ?? null;
        this.physCountry = entity?.physCountry ?? null;
        this.physCountryName = entity?.physCountryName ?? null;

        this.plantOffshore = entity?.plantOffshore ?? false;
        this.offshoreBlockV1 = entity?.offshoreBlockV1 ?? null;
        this.offshoreWaterbodyName = entity?.offshoreWaterbodyName ?? null;
        this.offshoreFieldName = entity?.offshoreFieldName ?? null;
        this.offshoreAreaName = entity?.offshoreAreaName ?? null;

        this.hsUnit = entity?.hsUnit ?? null;
        this.hsProductDesc = entity?.hsProductDesc ?? null;

        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;

        this.equipmentNotesDisplay = entity?.equipmentNotesDisplay ?? [];
        this.equipmentStepUpTransformersDisplay = entity?.equipmentStepUpTransformersDisplay ?? [];

        this.initialData = entity;
    }


    static BuildEquipmentDisplay(entity: any): EquipmentsDisplay {
        return new EquipmentsDisplay(entity);
    }

    static BuildNewEquipment(response: any): EquipmentsDisplay {
        return new EquipmentsDisplay({
            equipmentId: response[0].EQUIPMENT_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.OEQUIPMENT,
            entityId: this.equipmentId
        };
    }

    prepareSaveCollection() {
        this.equipmentNotesDisplay = this.equipmentNotesDisplay;
        this.equipmentStepUpTransformersDisplay = this.equipmentStepUpTransformersDisplay;
    }

    getPhysAddressV1(): string {
        if (this.plantOffshore) {
            return this.offshoreBlockV1;
        } else {
            return this.physAddressV1;
        }
    }

    getPhysAddressV2(): string {
        if (this.plantOffshore) {
            return AddressUtils.formatOffshorePhysAddressV2(
                this.offshoreFieldName,
                this.offshoreWaterbodyName,
                '');
        } else {
            return AddressUtils.formatPhysAddressV2(this.physCity,
                this.physState, this.physPostalCode, this.physCountryName);
        }
    }
    
    applyChangedFields(changeObj: any) {
        this.changedFields = {};

        for(const field in changeObj) {
            if(this.hasOwnProperty(field)) {
                this.changedFields[field] = 0;
            }
        }
    }

}

import { combineLatest, Observable, OperatorFunction, timer } from "rxjs";
import { map } from "rxjs/operators";

/**
 * To be used with pipe(), to delay the result by UP TO a certain amount of time.
 */
export function delayUpTo<T>(delay: number): OperatorFunction<T, T>
{
   return function(source$: Observable<T>): Observable<T>
   {
      return combineLatest([timer(delay), source$]).pipe(map(x => x[1]));
   }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
import { DateUtil } from "../core/utils/date-util";
import { LoadWheelService } from "../components/load-wheel/load-wheel.service";
import { SpinnerProcess } from "../core/models/spinner-process";
import { TranslateService } from "@ngx-translate/core";
import { TelemetryService } from "../core/services/telemetry.service";

@Injectable({
    providedIn: 'root'
})

export class ContactDetailsSectionService {
    acContactAssociations: Array<number> = [];

    constructor(
        private http: HttpClient,
        private loadWheelService: LoadWheelService,
        private translate: TranslateService,
        private telemetry: TelemetryService,
    ) { }

    getSearchAllContacts(selfMvId?, firstName?, lastName?, email?, companyName?, contactID?, plantID?, mode?, applyCoverage?, onSite?) {
        const searchStart: number = performance.now();

        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.contactAssociations"));
        return this.http.get(`${environment.apiUrl}contact/searchAll?firstName=${firstName}&lastName=${lastName}&email=${email}&companyName=${companyName}&contactId=${contactID}&plantID=${plantID}&mode=${mode}&applyCoverage=${applyCoverage}&onSite=${onSite}`)
            .pipe(
                map((data: any) => {
                    const acResponse = [];
                    if (data.response) {
                        for (let i = 0; i < data.response.length; i++) {
                            const itemArray = data.response[i];
                            if (itemArray) {
                                for (let j = 0; j < itemArray.length; j++) {
                                    const item = itemArray[j];
                                    if (Number(item.MV_ID) === Number(selfMvId)) {
                                        continue;
                                    }
                                    item.CONTACT_QC_DATE_OBJ = DateUtil.convertToDate(item.CONTACT_QC_DATE);
                                    acResponse.push(item);
                                }
                            }
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    
                    this.telemetry.entitySearchTime("contact", performance.now() - searchStart);
                    
                    return acResponse;
                }), 
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return [];
                })
            )
    }

    isContactOpen(contactId: number): boolean {
        return this.acContactAssociations.filter(p => p === contactId).length > 0;
    }

}
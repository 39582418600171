import { Bean } from "../../../bean";
import { Assets } from "../../base/save/assets";
import { AssetsTLinesConnections } from "./assets-tlines-connections";

export class AssetsTLines extends Bean {
    assetId: number;
    assetTLinesId: number;
    parentId: number;
    nercRegion: string;
    controlAreaId: string;
    tLineLength: number;
    tLineLengthUom: string;
    kvRating: number;
    destinationAssetId: number;
    destinationAssetTypeId: number;
    overHead: string;
    belowGround: string;
    subsea: string;
    systemType: string;
    tLineStatus: string;
    transmissionSystemId: number;

    asset: Assets;

    assetsTLinesConnections: Array<AssetsTLinesConnections>;

    constructor(entity: any) {
        super(entity);

        this.assetId = entity?.assetId ?? null;
        this.assetTLinesId = entity?.assetTLinesId ?? null;
        this.parentId = entity?.parentId ?? null;
        this.nercRegion = entity?.nercRegion ?? null;
        this.controlAreaId = entity?.controlAreaId ?? null;
        this.tLineLength = entity?.tLineLength ?? null;
        this.tLineLengthUom = entity?.tLineLengthUom ?? null;
        this.kvRating = entity?.kvRating ?? null;
        this.destinationAssetId = entity?.destinationAssetId ?? null;
        this.destinationAssetTypeId = entity?.destinationAssetTypeId ?? null;
        this.overHead = entity?.overHead ?? null;
        this.belowGround = entity?.belowGround ?? null;
        this.subsea = entity?.subsea ?? null;
        this.systemType = entity?.systemType ?? null;
        this.tLineStatus = entity?.tLineStatus ?? null;
        this.transmissionSystemId = entity?.transmissionSystemId ?? null;

        this.asset = entity?.asset ?? null;

        this.assetsTLinesConnections = entity?.assetsTLinesConnections ?? [];
    }
}

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { IContact } from 'src/app/core/interfaces/icontacts';
import { EditableColumn, Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { FocusableEvent, FocusableEventType } from 'src/app/core/directives/focusable-table.directive';
import { ContactComponent } from '../contact.component';
import { TranslateService } from '@ngx-translate/core';
import { Constants, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { Preference } from 'src/app/core/models/common';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-pool-table',
    templateUrl: './pool-table.component.html',
    styleUrls: ['./pool-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('acContactsPoolTable') acContactsPoolTable: Table;
    @ViewChildren('acContactsPoolTR') private acContactsPoolRows: QueryList<ElementRef>;
    @ViewChildren(EditableColumn) private poolEditableColumns: QueryList<EditableColumn>;
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;
    

    @Input() contactComponent: ContactComponent;
    @Input() hideSort: boolean = false;
    @Input() baseIndex: number;
    @Input() acContactsPool: Array<IContact> = [];
    @Input() useSlottedBehavior?: boolean = false;
    rowsPerPage: number = 5;
    tableKey: string;
    @Input() preferences: Array<Preference> = [];
    @Input() entityName: string;

    cols: any = [] = [];
    selectContactStatus: any[] = [];
    poolFocusManager = new Subject<FocusableEvent>();

    constructor(
        public headerService: HeaderService,
        public translate: TranslateService,
        public sharedService: SharedService,
        private preferencesService: PreferencesSectionService
    ) {}

    ngOnInit(): void {
        this.initPoolCols();
        this.selectContactStatus = this.contactComponent.selectContactStatus;
        this.tableKey = this.preferencesService.getPreferenceResourceName(this.entityName, EntityPreferenceSufix.PoolContact);
    }

    ngAfterViewInit() {
        this.poolFocusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.poolEditableColumns));
    }

    onPageChange(event): void {
        if(this.tableKey) {
            this.preferencesService.updatePreferenceOnPageChange(this.tableKey, String(event.rows));
        }
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.preferences && this.tableKey && this.preferences) {
            this.rowsPerPage = this.preferencesService.getPaginatorPreference(this.preferences, this.tableKey, this.rowsPerPage);
        }
    }

    initPoolCols() {
        this.cols = [];
        const editable = this.contactComponent.editable;
        const entityName = this.contactComponent.entityName;
        const getColumnHeaderStyle = this.contactComponent.getColumnHeaderStyle;

        const showCompanyColumn = entityName === EntityName.SITE || entityName === EntityName.AREA || 
            entityName === EntityName.UNIT || entityName === EntityName.PLANT_LTSA || entityName === EntityName.PIPELINE_LTSA;

        let styles = getColumnHeaderStyle(15, 0, 10, 10, 0, 15, 15, 15, 10, 10, 0);
        if (showCompanyColumn) {
            styles = getColumnHeaderStyle(13, 0, 13, 12, 14, 12, 10, 10, 9, 7, 0);
        }

        if (this.useSlottedBehavior) {
            styles = getColumnHeaderStyle(8, 14, 10, 10, 12, 12, 12, 10, 6, 4, 2);
        }

        this.cols.push({field: 'contactId', header: this.translate.instant('contact.common.contactId'), editable: editable, style: styles.contactId});

        if (this.useSlottedBehavior) {
            this.cols.push({field: 'contactTitle', header: this.translate.instant('contact.common.contactFunction'), editable: editable, style: styles.contactTitle});
        }

        this.cols.push({field: 'firstName', header: this.translate.instant('contact.contactGrid.firstName'), editable: false, style: styles.firstName});
        this.cols.push({field: 'lastName', header: this.translate.instant('contact.contactGrid.lastName'), editable: false, style: styles.lastName});
        this.cols.push({field: 'contactTitleDesc', header: this.translate.instant('contact.common.actualTitle'), editable: false, style: styles.contactTitleDesc});

        if (showCompanyColumn || this.useSlottedBehavior) {
            this.cols.push({field: 'companyName', header: this.translate.instant('contact.common.company'), editable: false, style: styles.companyName});
        }

        this.cols.push({field: 'contactStatus', header: this.translate.instant('common.status'), editable: editable, style: styles.contactStatus});
        this.cols.push({field: 'contactQcDate', header: this.translate.instant('common.qcDate'), editable: editable, style: styles.contactQcDate});
        this.cols.push({field: 'contactOnsiteBool', header: this.translate.instant('contact.contactGrid.contactOnSite'), editable: editable, style: styles.contactOnsiteBool});
        this.cols.push({field: 'contactSaleFlagBool', header: this.translate.instant('contact.contactGrid.contactSell'), editable: editable, style: styles.contactSaleFlagBool});

        if (this.useSlottedBehavior) {
            this.cols.push({field: 'addContactButton', header: '', editable: editable, style: styles.contactActionButton});
        }
    }

    onRowclick(contact: any) {
        this.contactComponent.additionalInfo = contact;
        this.contactComponent.buildPhoneAndAltPhone(contact);
        this.contactComponent.contactDisplay = contact.contactDisplay;
    }

    onEditInit(event: any) {
        this.contactComponent.onEditInit(event);
    }

    onEditComplete(event: any) {
        this.contactComponent.onEditComplete(event);
    }

    dgContactsPool_doubleClick(iContact: IContact) {
        this.contactComponent.dgContactsPool_doubleClick(iContact);
    }

    onChangeContactStatusInPool() {
        this.contactComponent.forceOnEditComplete('pool');
    }

    forceOnEditComplete(table: 'pool' | 'slotted') {
        this.contactComponent.forceOnEditComplete(table);
    }

    onChangeContactOnsite(row: IContact) {
        this.contactComponent.onChangeContactOnsite(row);
    }

    onChangeContactSell(row: IContact) {
        this.contactComponent.onChangeContactSell(row);
    }

    moveToSlotted(contact: IContact) {
        this.contactComponent.moveToSlotted(contact);
    }

    functionalTitleById(titleId: string): string {
        if (this.contactComponent.functionalTitlesIndexed[Number(titleId)]) {
            return this.contactComponent.functionalTitlesIndexed[Number(titleId)];
        } else {
            return this.translate.instant('contact.contactGrid.assignFunction');
        }
    }

    functionalShowClear(titleId: string): boolean {
        if (this.contactComponent.functionalTitlesIndexed[Number(titleId)]) {
            return true;
        } else {
            return false;
        }
    }

    selectLastElement() {
        setTimeout(() => {
            let last = this.acContactsPoolRows.last;
            if (last) {
                last.nativeElement.click();
            }
        }, 25);
    }

    resetPaginator() {
        if (this.acContactsPoolTable && this.acContactsPoolTable.first) {
            this.acContactsPoolTable.first = 0;
        }
    }

    navigateLastElement(): void {
        this.acContactsPoolTable.first = Math.floor(
            (this.contactComponent?.acContactsPool && this.acContactsPool.length > 0 ?
                    (this.acContactsPool.length - 1) : 0)
                / this.acContactsPoolTable.rows) * this.acContactsPoolTable.rows;
    }

    clickToNativeElement() {
        this.acContactsPoolTable.el.nativeElement.click();
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        this.contactComponent.changeSelectionOnArrowKey(event, entityArray, hasHeader);
    }

}

import { AreasFaxInfo } from "../save/areas-fax-info";

export class AreasFaxInfoDisplay extends AreasFaxInfo {

  private constructor(entity?: any){
    super(entity)

    this.initialData = entity;
  }


  static CreateInstance(entity: any): AreasFaxInfoDisplay {
    return new AreasFaxInfoDisplay(entity);
  }

  static BuildFromList(entity: Array<any>): Array<AreasFaxInfoDisplay> {
      return entity.map(item => AreasFaxInfoDisplay.CreateInstance(item));
  }
}
import { Areas } from '../save/areas';
import { AreasNotesDisplay } from '../display/areas-notes-display'
import { AreasPhoneInfoDisplay } from '../display/areas-phone-info-display'
import { AreasFaxInfoDisplay } from '../display/areas-fax-info-display'
import { AreasOwnerInfoDisplay } from '../display/areas-owner-info-display';
import { AreasContactInfoDisplay } from '../display/areas-contact-info-display';
import { ContactsDisplay } from "../../contact/display/contacts-display";
import { OperationType } from '../../enumerations/operation-type';
import { IAuditEntityData } from 'src/app/core/interfaces/iaudit-entity-data';
import { AddressUtils } from 'src/app/core/utils/address-utils';
import { EntityName } from '../../enumerations/entity-name';

export class AreasDisplay extends Areas {
         companyName: string;
         operatorName: string;
         plantName: string;
         physAddressV1: string;
         physCity: string;
         physState: string;
         physStateName: string;
         physCountry: string;
         physCountryName: string;
         physPostalCode: string;
         mailAddressV1: string;
         mailAddressV2: string;
         mailCountry: string;
         mailCountryName: string;
         mailState: string;
         mailStateName: string;
         mailCity: string;
         mailPostalCode: string;
         telephoneMask: string;
         phoneCc1: string;
         phoneNo1: string;
         phoneExt1: string;
         phoneMobile1: string;
         phoneCc2: string;
         phoneNo2: string;
         phoneExt2: string;
         phoneMobile2: string;
         faxCc: string;
         faxNo: string;
         faxExt: string;
         defCountryCode: string;

         plantOffshore: boolean;
         offshoreBlockV1: string;
         offshoreWaterbodyName: string;
         offshoreFieldName: string;
         offshoreAreaName: string;

         mailCountryId: number;
         mailStateId: number;
         mailCityId: number;

         lastUserEmailAddress: string;
         entryUserEmailAddress: string;
         previousUserEmailAddress: string;
         qcUserEmailAddress: string;
         releaseUserEmailAddress: string;
         amendmentUserEmailAddress: string;

         areasOwnerInfoDisplay: Array<AreasOwnerInfoDisplay> = [];
         areasContactInfoDisplay: Array<AreasContactInfoDisplay> = [];
         areasNotesDisplay: Array<AreasNotesDisplay> = [];
         areasFaxInfoDisplay: Array<AreasFaxInfoDisplay> = [];
         areasPhoneInfoDisplay: Array<AreasPhoneInfoDisplay> = [];

         contactsDisplays: Array<ContactsDisplay> = [];

    constructor(entity?: any) {
        super(entity);
        this.companyName = entity?.companyName ?? '';
        this.operatorName = entity?.operatorName ?? '';
        this.plantName = entity?.plantName ?? '';
        this.physAddressV1 = entity?.physAddressV1 ?? '';
        this.physCity = entity?.physCity ?? '';
        this.physState = entity?.physState ?? '';
        this.physStateName = entity?.physStateName ?? '';
        this.physCountry = entity?.physCountry ?? '';
        this.physCountryName = entity?.physCountryName ?? '';
        this.physPostalCode = entity?.physPostalCode ?? '';
        this.mailAddressV1 = entity?.mailAddressV1 ?? '';
        this.mailAddressV2 = entity?.mailAddressV2 ?? '';
        this.mailCountry = entity?.mailCountry ?? '';
        this.mailCountryName = entity?.mailCountryName ?? '';
        this.mailState = entity?.mailState ?? '';
        this.mailStateName = entity?.mailStateName ?? '';
        this.mailCity = entity?.mailCity ?? '';
        this.mailPostalCode = entity?.mailPostalCode ?? '';
        this.telephoneMask = entity?.telephoneMask ?? '';
        this.phoneCc1 = entity?.phoneCc1 ?? '';
        this.phoneNo1 = entity?.phoneNo1 ?? '';
        this.phoneExt1 = entity?.phoneExt1 ?? '';
        this.phoneMobile1 = entity?.phoneMobile1 ?? '';
        this.phoneCc2 = entity?.phoneCc2 ?? '';
        this.phoneNo2 = entity?.phoneNo2 ?? '';
        this.phoneExt2 = entity?.phoneExt2 ?? '';
        this.phoneMobile2 = entity?.phoneMobile2 ?? '';
        this.faxCc = entity?.faxCc ?? '';
        this.faxNo = entity?.faxNo ?? '';
        this.faxExt = entity?.faxExt ?? '';
        this.defCountryCode = entity?.defCountryCode ?? '';
        this.plantOffshore = entity?.plantOffshore ?? false;
        this.offshoreBlockV1 = entity?.offshoreBlockV1 ?? '';
        this.offshoreWaterbodyName = entity?.offshoreWaterbodyName ?? '';
        this.offshoreFieldName = entity?.offshoreFieldName ?? '';
        this.offshoreAreaName = entity?.offshoreAreaName ?? '';
        this.mailCountryId = entity?.mailCountryId ?? '';
        this.mailStateId = entity?.mailStateId ?? '';
        this.mailCityId = entity?.mailCityId ?? '';
        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? '';
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? '';
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? '';
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? '';
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? '';

        this.areasOwnerInfoDisplay = entity?.areasOwnerInfoDisplay ?? [];
        this.areasContactInfoDisplay = entity?.areasContactInfoDisplay ?? [];
        this.areasNotesDisplay = entity?.areasNotesDisplay ?? [];
        this.areasFaxInfoDisplay = entity?.areasFaxInfoDisplay ?? [];
        this.areasPhoneInfoDisplay = entity?.areasPhoneInfoDisplay ?? [];
        this.contactsDisplays = entity?.contactsDisplays ?? [];

        this.initialData = entity;
    }

    static BuildAreaDisplay(entity: any): AreasDisplay {
        return new AreasDisplay(entity);
    }

    static BuildNewArea(response: any): AreasDisplay {
        return new AreasDisplay({
            areaId: response[0].AREAS_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT,
            stockExchange: '0'
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.AREA,
            entityId: this.areaId
        };
    }

    prepareSaveCollection() {
        this.setPhoneInfo();
        this.setFaxInfo();

        this.areasContactInfo = this.areasContactInfoDisplay;
        this.areasNotes = this.areasNotesDisplay;
        this.areasFaxInfo = this.areasFaxInfoDisplay;
        this.areasOwnerInfo = this.areasOwnerInfoDisplay;
        this.areasPhoneInfo = this.areasPhoneInfoDisplay;
        this.contacts = this.contactsDisplays;
    }

    setPhoneInfo(): void {
        if(this.fieldWasEdited('phoneCc1') || this.fieldWasEdited('phoneNo1') || this.fieldWasEdited('phoneExt1') || this.fieldWasEdited('phoneMobile1')) {
            let phoneInfo1: AreasPhoneInfoDisplay = this.areasPhoneInfoDisplay.find(phoneInfo => phoneInfo.mvOrder === 1);
            if(phoneInfo1 == null) {
                this.areasPhoneInfoDisplay.push(AreasPhoneInfoDisplay.CreateInstance({
                    operation: OperationType.INSERT,
                    areaId: this.areaId,
                    mvOrder: 1
                }));
                phoneInfo1 = this.areasPhoneInfoDisplay.find(phoneInfo => phoneInfo.mvOrder === 1);
            }
            else
            {
               phoneInfo1.setOperation(OperationType.UPDATE);
            }

            if(this.fieldWasEdited('phoneCc1')) {
                phoneInfo1.phoneCc = this.phoneCc1;
                phoneInfo1.forceBeanChange('phoneCc');
            }
            if(this.fieldWasEdited('phoneNo1')) {
                phoneInfo1.phoneNo = this.phoneNo1;
                phoneInfo1.forceBeanChange('phoneNo');
            }
            if(this.fieldWasEdited('phoneExt1')) {
                phoneInfo1.phoneExt = this.phoneExt1;
                phoneInfo1.forceBeanChange('phoneExt');
            }
            if(this.fieldWasEdited('phoneMobile1')) {
                phoneInfo1.phoneMobile = this.phoneMobile1;
                phoneInfo1.forceBeanChange('phoneMobile');
            }
        }

        if(this.fieldWasEdited('phoneCc2') || this.fieldWasEdited('phoneNo2') || this.fieldWasEdited('phoneExt2') || this.fieldWasEdited('phoneMobile2')) {
            let phoneInfo2: AreasPhoneInfoDisplay = this.areasPhoneInfoDisplay.find(phoneInfo => phoneInfo.mvOrder === 2);
            if(phoneInfo2 == null) {
                this.areasPhoneInfoDisplay.push(AreasPhoneInfoDisplay.CreateInstance({
                    operation: OperationType.INSERT,
                    areaId: this.areaId,
                    mvOrder: 2
                }));
                phoneInfo2 = this.areasPhoneInfoDisplay.find(phoneInfo => phoneInfo.mvOrder === 2);
            }
            else
            {
               phoneInfo2.setOperation(OperationType.UPDATE);
            }

            if(this.fieldWasEdited('phoneCc2')) {
                phoneInfo2.phoneCc = this.phoneCc2;
                phoneInfo2.forceBeanChange('phoneCc');
            }
            if(this.fieldWasEdited('phoneNo2')) {
                phoneInfo2.phoneNo = this.phoneNo2;
                phoneInfo2.forceBeanChange('phoneNo');
            }
            if(this.fieldWasEdited('phoneExt2')) {
                phoneInfo2.phoneExt = this.phoneExt2;
                phoneInfo2.forceBeanChange('phoneExt');
            }
            if(this.fieldWasEdited('phoneMobile2')) {
                phoneInfo2.phoneMobile = this.phoneMobile2;
                phoneInfo2.forceBeanChange('phoneMobile');
            }
        }
    }

    setFaxInfo(): void {
        if(this.fieldWasEdited('faxCc') || this.fieldWasEdited('faxNo') || this.fieldWasEdited('faxExt')) {
            let faxInfo: AreasFaxInfoDisplay = this.areasFaxInfoDisplay.find(faxInfo => faxInfo.mvOrder === 1);
            if(faxInfo == null) {
                this.areasFaxInfoDisplay.push(AreasFaxInfoDisplay.CreateInstance({
                    operation: OperationType.INSERT,
                    areaId: this.areaId,
                    mvOrder: 1
                }));
                faxInfo = this.areasFaxInfoDisplay.find(faxInfo => faxInfo.mvOrder === 1);
            }
            else
            {
               faxInfo.setOperation(OperationType.UPDATE);
            }

            if(this.fieldWasEdited('faxCc')) {
                faxInfo.faxCc = this.faxCc;
                faxInfo.forceBeanChange('faxCc');
            }
            if(this.fieldWasEdited('faxNo')) {
                faxInfo.faxNo = this.faxNo;
                faxInfo.forceBeanChange('faxNo');
            }
            if(this.fieldWasEdited('faxExt')) {
                faxInfo.faxExt = this.faxExt;
                faxInfo.forceBeanChange('faxExt');
            }
        }
    }

    applyChangedFields(changeObj: any) {
        this.changedFields = {};

        for(const field in changeObj) {
            if(this.hasOwnProperty(field)) {
                this.changedFields[field] = 0;
            }
        }
    }

    getPhysAddressV1():string {
        if(this.plantOffshore) {
            return this.offshoreBlockV1;
        } else {
            return this.physAddressV1;
        }
    }

    getPhysAddressV2():string {
        if(this.plantOffshore) {
            return AddressUtils.formatOffshorePhysAddressV2(
                this.offshoreFieldName,
                this.offshoreWaterbodyName,
                '');
        } else {
            return AddressUtils.formatPhysAddressV2(this.physCity,
                this.physState, this.physPostalCode, this.physCountryName);
        }
    }


}


// Models
import { Address } from '../../address';
import { OperationType } from '../../enumerations/operation-type';
import { Companies } from '../save/companies';
import { CompaniesCompanyInfoDisplay } from './companies-company-info-display';
import { CompaniesSICCodesDisplays } from '../display/sic-codes-info-display';
import { CompaniesNotesDisplay } from './companies-notes-display';
import { CompaniesSalesInfoDisplay } from '../display/companies-sales-info-displays';
import { CompaniesCompanyTypesDisplay } from '../display/companies-company-types-display';
import { CompaniesContactInfoDisplay } from './companies-contact-info-display';
import { ContactsDisplay } from '../../contact/display/contacts-display';
import { CompaniesFaxInfoDisplay } from './companies-fax-info-display';
import { CompaniesPhoneInfoDisplay } from './companies-phone-info-display';
import { IAuditEntityData } from 'src/app/core/interfaces/iaudit-entity-data';
import { EntityName } from '../../enumerations/entity-name';
import { CompaniesGreenhouseGasEmissionsDisplay } from '../display/companies-greenhouse-gas-emissions-display';

export class CompaniesDisplay extends Companies {
    readonly amendUser: string;
    readonly defCountryCode: string;
    readonly physCountry: string;
    readonly physCountryDesc: string;
    readonly physAddress: string;
    readonly physState: string;
    readonly physStateDesc: string;
    readonly physCity: string;
    readonly mailCountry: string;
    readonly telephone: string;
    readonly mailCity: string;
    readonly mailState: string;
    readonly mailCountryDesc: string;
    readonly mailStateDesc: string;
    readonly fax: string;
    readonly physicalAddress: Address;
    readonly physicalEmail: Address;

    companiesSalesInfoDisplays: Array<CompaniesSalesInfoDisplay>;
    companiesSICCodesDisplays: Array<CompaniesSICCodesDisplays>;
    companiesCompanyInfoDisplays: Array<CompaniesCompanyInfoDisplay>;
    companiesContactInfoDisplays: Array<CompaniesContactInfoDisplay>;
    companiesNotesDisplays: Array<CompaniesNotesDisplay>;
    companiesFaxInfoDisplays: Array<CompaniesFaxInfoDisplay>;
    companiesPhoneInfoDisplays: Array<CompaniesPhoneInfoDisplay>;
    companiesCompanyTypesDisplays: Array<CompaniesCompanyTypesDisplay>;
    companiesGreenhouseGasEmissionsDisplay: Array<CompaniesGreenhouseGasEmissionsDisplay>;

    contactsDisplays: Array<ContactsDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.amendUser = entity?.amendUser ?? null;
        this.defCountryCode = entity?.defCountryCode ?? null;
        this.physCountry = entity?.physCountry ?? null;
        this.physAddress = entity?.physAddress ?? null;
        this.physState = entity?.physState ?? null;
        this.physCity = entity?.physCity ?? null;
        this.telephone = entity?.telephone ?? null;
        this.mailCountry = entity?.mailCountry ?? null;
        this.mailCity = entity?.mailCity ?? null;
        this.mailState = entity?.mailState ?? null;
        this.mailCountryDesc = entity?.mailCountryDesc ?? null;
        this.mailStateDesc = entity?.mailStateDesc ?? null;
        this.fax = entity?.fax ?? null;
        this.physicalAddress = entity?.physicalAddress ?? null;
        this.physicalEmail = entity?.physicalEmail ?? null;

        this.physicalAddress = Address.BuildPhysicalAddress(entity);
        this.physicalEmail = Address.BuildMailingAddress(entity);

        this.companiesSalesInfoDisplays = entity?.companiesSalesInfoDisplays ?? [];
        this.companiesCompanyInfoDisplays = entity?.companiesCompanyInfoDisplays ?? [];
        this.companiesSICCodesDisplays = entity?.companiesSICCodesDisplays ?? [];
        this.companiesNotesDisplays = entity?.companiesNotesDisplays ?? [];
        this.companiesCompanyTypesDisplays = entity?.companiesCompanyTypesDisplays ?? [];
        this.companiesContactInfoDisplays = entity?.companiesContactInfoDisplays ?? [];
        this.companiesFaxInfoDisplays = entity?.companiesFaxInfoDisplays ?? [];
        this.companiesPhoneInfoDisplays = entity?.companiesPhoneInfoDisplays ?? [];
        this.companiesGreenhouseGasEmissionsDisplay = entity?.companiesGreenhouseGasEmissionsDisplay ?? [];
        
        this.contactsDisplays = entity?.contactsDisplays ?? [];
    }

    static BuildCompanyDisplay(entity: any): CompaniesDisplay {
        return new CompaniesDisplay(entity);
    }

    static BuildNewCompany(response: any): CompaniesDisplay {
        return new CompaniesDisplay({
            companyId: response[0].COMPANY_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT,
            stockExchange: '0'
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.COMPANY,
            entityId: this.companyId
        };
    }

    setPhysicalAddress(address: Address): void {
        this.physPostalCode = address.zipCode;
        this.physAddressV1 = address.address1;
        this.physAddressV2 = address.address2;
        this.pecZoneSimplex = address.pecZoneId;
        this.physCountryId = address.c_id;
        this.physStateId = address.s_id;
        this.physCityId = address.ci_id;
        this.physCountyId = address.co_id;
    }

    setMailingAddress(address: Address): void {
        this.mailPostalCode = address.zipCode;
        this.mailAddressV1 = address.address1;
        this.mailAddressV2 = address.address2;
        this.mailCountryId = address.c_id;
        this.mailStateId = address.s_id;
        this.mailCityId = address.ci_id;
    }

    setDetails(detailsForms: any) {
        this.dunsNumber = detailsForms.dunsNumber;
        this.numEmployees = detailsForms.numEmployees;
        this.yearEstablished = detailsForms.yearEstablished;
    }

    prepareSaveCollection() {
        this.companiesGreenhouseGasEmissions = this.companiesGreenhouseGasEmissionsDisplay;
    }
}


import { Adapter } from '../adapter';
import { Injectable } from '@angular/core';

export class User {
    constructor(
        public FirstName: string,
        public LastName: string,
        public UserName: string,
        public Token: string,
        public RefreshToken: string
    ) {}
}
@Injectable({
    providedIn: 'root',
})
export class UserAdapter implements Adapter<User> {
    adapt(item: any): User {
        return new User(item.FirstName, item.LastName, item.UserName, item.Token, item.RefreshToken);
    }
}

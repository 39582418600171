import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { BaseComponent } from 'src/app/base.component';
import { AddressService } from 'src/app/components/address-form/address.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { LocationSearchComponent, LocationSearchParameters } from 'src/app/components/modals/location-search/location-search.component';
import { UserModalComponent } from 'src/app/components/modals/user-modal/user-modal.component';
import { IContact } from 'src/app/core/interfaces/icontacts';
import { Country } from 'src/app/core/models';
import { Constants } from 'src/app/core/models/constants';
import { ContactsDisplay } from 'src/app/core/models/contact/display/contacts-display';
import { LockMode } from 'src/app/core/models/enumerations/lock-mode';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { Role } from 'src/app/core/models/enumerations/role';
import { ContactEvent } from 'src/app/core/models/event';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { SharedService } from 'src/app/core/services/shared.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-form-contact-details',
    templateUrl: './form-contact-details.component.html',
    styleUrls: ['./form-contact-details.component.scss']
})
export class FormContactDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() contactGeneric: IContact;
    @Input() newContactId: any;
    @Input() locked: boolean;
    @Input() entityName: string;
    @Input() entityId: number;
    @Input() baseIndex: number;
    @Output() updateContactDisplayLocked: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('nationalityNameInput', { static: true }) nationalityNameInput: ElementRef<HTMLInputElement>;
    @ViewChild('firstTabElement', { static: false }) firstTabElement: ElementRef;
    @ViewChild('lastTabElement', { static: false }) lastTabElement: ElementRef;
    contactDisplay: ContactsDisplay;
    displayUserDetails: boolean;
    contactFormGroup: FormGroup;
    salutations: any[] = [];

    fieldChangeContactSection: Object;
    infoComplement: string
    lockError: string;
    lockSameUser: boolean;
    lockMessageStart: string;
    lockMessageEnd: string;
    lockUserLink: string;
    hasRoleIndustryManager: boolean = false;
    originalEmail: string;
    previousNationality: string;
    nationalitySearchModalOpened: boolean = false;

    private fieldsChangeSub: Subscription;
    private disableFormSub: Subscription;

    constructor(
        private dialogService: DialogService,
        private addressService: AddressService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        private authService: AuthService,
        private sharedService: SharedService,
        private messageHandlerService: MessageHandlerService,
        public fb: FormBuilder
    ) { super(recordLockingFlow, preferencesService); }

    ngOnInit(): void {
        this.authService.isUserInRole(Role.INDUSTRY_MANAGER).subscribe(b => this.hasRoleIndustryManager = b);

        this.buildForm();
        this.salutations = [
            { data: 'M', label: 'M - Mr.' },
            { data: 'F', label: 'F - Ms.' }
        ];

        this.init();
        this.watchChangeForm(this.contactFormGroup);
        this.fieldsChangeSub = this.fieldsChange$.subscribe(property => {
            this.fieldChangeContactSection = property;
        });

        this.setLockContactStatus();
    }


    private setLockContactStatus(){
        this.contactDisplay = this.contactGeneric.iCopy.contactDisplay;
        this.lockError = this.contactDisplay?.lockError;
        this.lockSameUser = this.contactDisplay?.lockSameUser;

        if (this.lockError?.length > 0) {
            if (this.lockSameUser) {
                this.lockMessageStart = this.lockError;
                this.lockMessageEnd = '';
                this.lockUserLink = '';
            } else {
                this.lockMessageStart = this.lockError.substring(0, this.lockError.indexOf('(') + 1);
                this.lockMessageEnd = this.lockError.substring(this.lockError.indexOf(')'), this.lockError.length);
                this.lockUserLink = this.lockError.substring(this.lockError.indexOf('(') + 1, this.lockError.indexOf(')'));
            }
        }
    }

    ngOnDestroy(): void {
        this.fieldsChangeSub?.unsubscribe();
        this.disableFormSub?.unsubscribe();
    }

    init() {
        if (this.contactGeneric !== undefined) {
            this.setValueControls();
            this.setValueControlsNew(this.newContactId);
            this.previousNationality = this.contactGeneric.iCopy.contactDisplay.nationality;
        }
    }

    buildForm() {
        this.contactFormGroup = this.fb.group({
            contactId: { value: '', disabled: true },
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: [''],
            nickName: [''],
            jrSrIII: [''],
            linkedInId: [''],
            matronName: [''],
            emailAddress: [''],
            contactOptedOutBool: [false],
            salutation: ['', Validators.required],
            nationality: [''],
            nationalityName: { value: this.contactGeneric.iCopy.contactDisplay.nationalityName, disabled: true },
        });

        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            if (this.locked || disable) {
                this.contactFormGroup.disable();
            } else {
                this.contactFormGroup.enable();
                this.contactFormGroup.controls.contactId.disable();
                this.contactFormGroup.controls.nationalityName.disable();
            }
        });
    }

    setValueControls() {
        this.originalEmail = this.contactGeneric.iCopy.contactDisplay.emailAddress;
        this.contactFormGroup.controls.contactId.setValue(this.contactGeneric.iCopy.contactId);
        this.contactFormGroup.controls.lastName.setValue(this.contactGeneric.iCopy.lastName);
        this.contactFormGroup.controls.firstName.setValue(this.contactGeneric.iCopy.firstName);
        this.contactFormGroup.controls.middleName.setValue(this.contactGeneric.iCopy.contactDisplay.middleName);
        this.contactFormGroup.controls.nickName.setValue(this.contactGeneric.iCopy.contactDisplay.nickName);
        this.contactFormGroup.controls.jrSrIII.setValue(this.contactGeneric.iCopy.contactDisplay.jrSrIII);
        this.contactFormGroup.controls.linkedInId.setValue(this.contactGeneric.iCopy.contactDisplay.linkedInId);
        this.contactFormGroup.controls.matronName.setValue(this.contactGeneric.iCopy.contactDisplay.matronName);
        this.contactFormGroup.controls.emailAddress.setValue(this.contactGeneric.iCopy.contactDisplay.emailAddress);
        this.contactFormGroup.controls.salutation.setValue(this.contactGeneric.iCopy.contactDisplay.salutation);
        this.contactFormGroup.controls.nationality.setValue(this.contactGeneric.iCopy.contactDisplay.nationality);
        this.contactFormGroup.controls.nationalityName.setValue(this.contactGeneric.iCopy.contactDisplay.nationalityName);
        this.contactFormGroup.controls.contactOptedOutBool.setValue(this.contactGeneric.iCopy.contactDisplay.contactOptedOutBool);
    }

    setValueControlsNew(patchValue: any) {
        this.contactFormGroup.controls.contactId.setValue(patchValue);
    }

    createFromForm(): void {
        this.contactGeneric.iCopy.contactDisplay.firstName = this.contactFormGroup.get(['firstName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.lastName = this.contactFormGroup.get(['lastName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.middleName = this.contactFormGroup.get(['middleName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.nickName = this.contactFormGroup.get(['nickName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.jrSrIII = this.contactFormGroup.get(['jrSrIII'])!.value;
        this.contactGeneric.iCopy.contactDisplay.linkedInId = this.contactFormGroup.get(['linkedInId'])!.value;
        this.contactGeneric.iCopy.contactDisplay.matronName = this.contactFormGroup.get(['matronName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.emailAddress = this.contactFormGroup.get(['emailAddress'])!.value;
        this.contactGeneric.iCopy.contactDisplay.salutation = this.contactFormGroup.get(['salutation'])!.value;
        this.contactGeneric.iCopy.contactDisplay.nationality = this.contactFormGroup.get(['nationality'])!.value;
        this.contactGeneric.iCopy.contactDisplay.nationalityName = this.contactFormGroup.get(['nationalityName'])!.value;
        this.contactGeneric.iCopy.contactDisplay.contactOptedOutBool = this.contactFormGroup.get(['contactOptedOutBool'])!.value;

        this.contactGeneric.iCopy.contactDisplay.changedFields = Object.assign({},
            this.contactGeneric.iCopy.contactDisplay.changedFields,
            this.fieldChangeContactSection);
    }

    openNationalitySearchModal() {
        if (!this.nationalitySearchModalOpened) {
            this.nationalitySearchModalOpened = true;
            const dialogParameters: LocationSearchParameters = { type: 'country', countryId: this.contactFormGroup.get(['nationality'])!.value, stateAbbrev: '', stateName: '', cityName: '', ciId: '' };
            const modalRef = this.dialogService.open(LocationSearchComponent, {
                header: 'Country Search',
                width: '40rem',
                data: dialogParameters,
                draggable: true,
                keepInViewport: true
            });

            modalRef.onClose.subscribe((response: any) => {
                this.nationalitySearchModalOpened = false;
                if (response !== undefined) {
                    this.forceFieldChange('nationality');
                    this.contactFormGroup.controls.nationality.setValue(response.countryId);
                    this.contactFormGroup.controls.nationalityName.setValue(response.countryName);
                }
            });
        }
    }

    showUserDetails() {
        this.displayUserDetails = true;
    }

    checkContactOptedOut() {
    }

    onBlurEmailAddress() {
        const email = this.contactFormGroup.get(['emailAddress'])!.value;

        if (email !== this.originalEmail) {
            this.changeStatusesAndRefreshContactDisplay('', '', '', '');
            this.sharedService.getContactEmailStatus(email).subscribe((response: any) => {
                if (response) {
                    this.changeStatusesAndRefreshContactDisplay(response.status, null, null, null);
                }
            });
            this.sharedService.getContactEmailOverrideStatus(email).subscribe((response: any) => {
                if (response) {
                    this.changeStatusesAndRefreshContactDisplay(null, response.overrideStatus,
                        response.overridePerson, response.overrideDate);
                }
            });
        }
        this.originalEmail = email;
    }

    changeStatusesAndRefreshContactDisplay(status: string, overrideStatus: string,
        overridePerson: string, overrideDate: string) {
        if (status !== null) {
            this.contactDisplay.marketingEmailStatus = status;
        }
        if (overrideStatus !== null) {
            this.contactDisplay.marketingEmailOverrideStatus = overrideStatus;
        }
        if (overridePerson !== null) {
            this.contactDisplay.marketingOverridePerson = overridePerson;
        }
        if (overrideDate !== null) {
            this.contactDisplay.marketingOverrideDate = overrideDate;
        }
    }

    nationalityOnBlur() {
        this.searchCountry();
    }

    nationalityOnEnter() {
        const nationality = this.contactFormGroup.get(['nationality'])!.value;
        if (StringUtils.isEmpty(nationality)) {
            this.openNationalitySearchModal();
            return;
        }
        this.searchCountry();
    }

    private searchCountry() {
        const nationality = this.contactFormGroup.get(['nationality'])!.value;
        if (StringUtils.isEmpty(nationality)) {
          this.contactFormGroup.controls.nationalityName.setValue('');
          this.previousNationality = '';
          return;
        }
      
        this.addressService.searchCountries(nationality, '')
          .subscribe((countries: Country[]) => {
            if (countries.length == 1) {
              this.contactFormGroup.controls.nationality.setValue(countries[0].countryId);
              this.contactFormGroup.controls.nationalityName.setValue(countries[0].countryName);
              this.previousNationality = countries[0].countryId;
              if (this.nationalityNameInput) {
                this.nationalityNameInput.nativeElement.focus();
              }
            } else {
              if (countries.length < 1) {
                this.contactFormGroup.controls.nationality.setValue('');
                if (this.nationalityNameInput) {
                  this.nationalityNameInput.nativeElement.focus();
                }
                const entityNotExistsMsg = Constants.replace(Constants.MESSAGE_ENTITY_NOT_EXISTS, ['Nationality']);
                this.messageHandlerService.show(entityNotExistsMsg);
              } else {
                this.openNationalitySearchModal();
              }
            }
          });
      }

    displayLink(str: string): boolean {
        if (str !== '' && str !== null) {
            return true;
        } else {
            return false;
        }
    }

    openUserModal(userModal: string) {
        const modalRef = this.dialogService.open(UserModalComponent, {
            header: `User Details for ${userModal}`,
            width: '30rem',
            data: {
                user: userModal,
                entityName: this.entityName,
                entityId: this.entityId
            },
            draggable: true,
            keepInViewport: true
        });
    }

    navigateToLinkedInProfileUrl() {
        const linkedInId = this.contactFormGroup.get(['linkedInId'])!.value;
        let result = FormContactDetailsComponent.checkLinkedInId(linkedInId);
        if (result) {
            this.messageHandlerService.show(result);
        } else if (linkedInId?.length > 0) {
            const linkedInUrl = Constants.LINKEDIN_BASE_URL + encodeURI(linkedInId);
            window.open(linkedInUrl, '_blank');
        }
    }

    linkedInFocusOut() {
        const elem: AbstractControl = this.contactFormGroup.get(['linkedInId'])!;

        let linkedInId: string = elem.value;
        if(linkedInId === null)
        {
           return;  // Nothing to do
        }

        // Auto cleanup protocol
        if (linkedInId.startsWith('http://') || linkedInId.startsWith('https://')) {
            // Remove unnecessary protocol specification
            linkedInId = linkedInId.substring(linkedInId.indexOf('://') + 3);
            elem.setValue(linkedInId);
        }

        // Auto cleanup host & base path
        if (linkedInId.startsWith('www.linkedin.com/in/')) {
            // Remove unnecessary protocol specification
            linkedInId = linkedInId.substring(linkedInId.indexOf('www.linkedin.com/in/') + 20);
            elem.setValue(linkedInId);
        }

        // Auto cleanup trailing slash
        if (linkedInId.endsWith('/')) {
            linkedInId = linkedInId.slice(0, -1);
            elem.setValue(linkedInId);
        }

        if(FormContactDetailsComponent.checkLinkedInId(linkedInId)) {
            elem.setErrors({'invalid': true});
        }
    }

    // Returns a message about what is wrong with the linked in id, undefined if it's valid
    static checkLinkedInId(linkedInId: string): string {
        let msg: string;
        if(linkedInId && linkedInId !== '')
        {
            if(linkedInId.includes('://')) {
               msg = Constants.MESSAGE_LINKEDIN_PROFILE_URL_INVALID_FORMAT;
            } else if (!Constants.REGEX_VALID_LINKEDIN_ID.test(linkedInId)) {
                msg = Constants.MESSAGE_LINKEDIN_PROFILE_URL_INVALID_CHARACTERS;
            }
        }
        return msg;
    }

    editRecord() {
        if(this.contactDisplay.getOperation() !== OperationType.INSERT){
            const loadContactDisplay = (event: ContactEvent) => {
                if (event.data.contactId !== null) {
                    this.contactGeneric.iCopy.contactDisplay.merge(event.data);
                } else {
                    this.contactDisplay.lockError = event.data.lockError;
                    this.contactDisplay.lockId = event.data.lockId;
                    this.contactDisplay.lockMode = event.data.lockMode;
                    this.contactDisplay.lockSameUser = event.data.lockSameUser;
                    this.contactDisplay.lockSameSession = event.data.lockSameSession;
                }

                this.recordLockingFlow.setContactLockItem(this.entityName,
                    String(this.entityId),
                    String(this.contactDisplay.contactId),
                    this.contactDisplay.lockId !== null ?
                    String(this.contactDisplay.lockId) : null);
                if (this.contactDisplay.lockError !== null) {
                    this.setLockContactStatus();
                    this.messageHandlerService.show(this.contactDisplay.lockError, MessageType.INFO);
                } else {
                    if(this.contactGeneric.iCopy.contactDisplay.lockMode === LockMode.EDIT
                        && this.contactGeneric.iCopy.contactDisplay.lockId 
                            !== this.contactGeneric.contactDisplay.lockId){
                        this.contactGeneric.contactDisplay.lockId = this.contactGeneric.iCopy.contactDisplay.lockId;
                        this.contactGeneric.contactDisplay.lockError = this.contactGeneric.iCopy.contactDisplay.lockError;           
                    }
                    this.init();
                    this.setLockContactStatus();
                    this.updateContactDisplayLocked.next(false);
                    this.contactFormGroup.enable();
                }
            }
            this.recordLockingFlow.loadContactRecordFlow(
                String(this.contactDisplay.contactId),
                this.entityName,
                String(this.entityId),
                [this.contactDisplay],
                false,
                loadContactDisplay);
        }
    }

}


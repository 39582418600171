import { PlantsGreenhouseGasEmissions } from "../save/plants-greenhouse-gas-emissions";

export class PlantsGreenhouseGasEmissionsDisplay extends PlantsGreenhouseGasEmissions {

    constructor(entity?: any) {
        super(entity);
        this.initialData = entity;        
    }

    static CreateInstance(entity: any): PlantsGreenhouseGasEmissionsDisplay {
        return new PlantsGreenhouseGasEmissionsDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsGreenhouseGasEmissionsDisplay> {
        return entity.map(item => PlantsGreenhouseGasEmissionsDisplay.CreateInstance(item));
    }
}
import { PipelinesLTSATrades } from "../save/pipeline-ltsa-trades";

export class PipelinesLTSATradesDisplay extends PipelinesLTSATrades {
    serviceAgreementTradeName: string;
    
    constructor(entity: any) {
        super(entity);
    }

    static CreateInstance(entity: any): PipelinesLTSATradesDisplay {
        return new PipelinesLTSATradesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PipelinesLTSATradesDisplay> {
        return entity.map(item => PipelinesLTSATradesDisplay.CreateInstance(item));
    }
}

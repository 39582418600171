import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoEquipmentComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.OEQUIPMENT;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        if(this.callerIsUnit){
            columnsInfo.push({ entityPropName: 'EquipmentId', columnHeader: 'relatedInfo.equipment.equipmentId', routerEntityName: EntityName.OEQUIPMENT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'EquipmentName', columnHeader: 'relatedInfo.equipment.equipmentName', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'EquipmentType', columnHeader: 'relatedInfo.equipment.equipmentType', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'EquipmentStatus', columnHeader: 'relatedInfo.equipment.equipmentStatus', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push(this.createDuplicateColumn('EquipmentId', EntityName.OEQUIPMENT, 5));
        } else {
            columnsInfo.push({ entityPropName: 'EquipmentId', columnHeader: 'relatedInfo.equipment.equipmentId', routerEntityName: EntityName.OEQUIPMENT, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'EquipmentName', columnHeader: 'relatedInfo.equipment.equipmentName', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'EquipmentType', columnHeader: 'relatedInfo.equipment.equipmentType', widthPercentage: 15});
            columnsInfo.push({ entityPropName: 'UnitType', columnHeader: 'relatedInfo.equipment.unitType', widthPercentage: 20});
            columnsInfo.push({ entityPropName: 'StartupDate', columnHeader: 'relatedInfo.common.startupDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'EquipmentStatus', columnHeader: 'relatedInfo.equipment.equipmentStatus', widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
            columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
        }
        return columnsInfo;
    }

}

import { PlantsLTSATypes } from "../save/plants-ltsa-types";

export class PlantsLTSATypesDisplay extends PlantsLTSATypes {
    serviceAgreementTypeName: string;

    constructor(entity: any) {
        super(entity);
        this.serviceAgreementTypeName = entity?.serviceAgreementTypeName ?? null;
    }

    static CreateInstance(entity: any): PlantsLTSATypesDisplay {
        return new PlantsLTSATypesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsLTSATypesDisplay> {
        return entity.map(item => PlantsLTSATypesDisplay.CreateInstance(item));
    }
}

import {Bean} from "../../bean";

export class CompaniesContactInfo extends Bean {
    companyId: number;
    contactFunction: string;
    contactId: number;
    contactLastCompany: string;
    contactLastDate: string;
    contactLastUser: string;
    contactPhoneCc: number;
    contactPhoneNo: string;
    contactPhoneExt: string;
    contactPhoneMobile: string;
    contactQcDate: string;
    contactQcUser: string;
    contactSaleFlag: string;
    contactStatus: string;
    contactTitleDesc: string;
    contactTitle: string;
    contactOnsite: string;
    mailAddressLine1: string;
    mailAddressLine2: string;
    mailPostalCode: string;
    mvOrder: number;
    mvId: number;
    contactFaxCc: number;
    contactFaxNo: string;
    contactFaxExt: string;
    mailAddressVerified: string;
    mailAddressVerifiedDate: string;
    contactAltPhoneCc: number;
    contactAltPhoneNo: string;
    contactAltPhoneExt: string;
    contactAltPhoneMobile: string;

    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;

    protected constructor(entity?: any) {
        super(entity);

        this.companyId = entity?.companyId ?? null;
        this.contactFunction = entity?.contactFunction ?? null;
        this.contactId = entity?.contactId ?? null;
        this.contactLastCompany = entity?.contactLastCompany ?? null;
        this.contactLastDate = entity?.contactLastDate ?? null;
        this.contactLastUser = entity?.contactLastUser ?? null;
        this.contactPhoneCc = entity?.contactPhoneCc ?? null;
        this.contactPhoneNo = entity?.contactPhoneNo ?? null;
        this.contactPhoneExt = entity?.contactPhoneExt ?? null;
        this.contactPhoneMobile = entity?.contactPhoneMobile ?? null;
        this.contactQcDate = entity?.contactQcDate ?? null;
        this.contactQcUser = entity?.contactQcUser ?? null;
        this.contactSaleFlag = entity?.contactSaleFlag ?? null;
        this.contactStatus = entity?.contactStatus ?? null;
        this.contactTitleDesc = entity?.contactTitleDesc ?? null;
        this.contactTitle = entity?.contactTitle ?? null;
        this.contactOnsite = entity?.contactOnsite ?? null;
        this.mailAddressLine1 = entity?.mailAddressLine1 ?? null;
        this.mailAddressLine2 = entity?.mailAddressLine2 ?? null;
        this.mailPostalCode = entity?.mailPostalCode ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.mvId = entity?.mvId ?? null;
        this.contactFaxCc = entity?.contactFaxCc ?? null;
        this.contactFaxNo = entity?.contactFaxNo ?? null;
        this.contactFaxExt = entity?.contactFaxExt ?? null;
        this.mailAddressVerified = entity?.mailAddressVerified ?? '0';
        this.mailAddressVerifiedDate = entity?.mailAddressVerifiedDate ?? null;
        this.contactAltPhoneCc = entity?.contactAltPhoneCc ?? null;
        this.contactAltPhoneNo = entity?.contactAltPhoneNo ?? null;
        this.contactAltPhoneExt = entity?.contactAltPhoneExt ?? null;
        this.contactAltPhoneMobile = entity?.contactAltPhoneMobile ?? null;
        this.mailCountryId = entity?.mailCountryId ?? null;
        this.mailStateId = entity?.mailStateId ?? null;
        this.mailCityId = entity?.mailCityId ?? null;
    }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { AuthService } from 'src/app/auth/auth.service';
import { ActiveEntityLabels, LTSAStatusDescValues, LTSAStatusValues } from 'src/app/core/models/constants';

@Component({
    selector: 'app-plant-ltsa',
    templateUrl: './plant-ltsa.component.html',
    styleUrls: ['./plant-ltsa.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoPlantLtsaComponent extends RelatedInfoBase implements OnInit, OnChanges {
    entityTableKey =EntityName.PLANT_LTSA;
    currentActiveLtsa: string = '';
    verifiedPlantLtsaCheckboDisabled: boolean = false;
    @Input() noLtsaActivityVerified?: boolean;
    @Input() noLtsaActivityDate?: string;
    @Input() noLtsaActivityUser?: string;

    constructor(
        private authService: AuthService,
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
        this.initPlantLtsa();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'LtsaId', columnHeader: 'relatedInfo.plantLtsa.ltsaId', routerEntityName: EntityName.PLANT_LTSA, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'Contractor', columnHeader: 'relatedInfo.plantLtsa.contractor', widthPercentage: 25});
        columnsInfo.push({ entityPropName: 'ContractLength', columnHeader: 'relatedInfo.plantLtsa.contractLength', widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right'});
        columnsInfo.push({ entityPropName: 'Status', columnHeader: 'relatedInfo.plantLtsa.status', widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'ExpirationDate', columnHeader: 'relatedInfo.plantLtsa.expirationDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 10});

        return columnsInfo;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.rows) {  
            this.initPlantLtsa();
        }
    }

    initPlantLtsa() {
        if (this.rows.length > 0) {
            this.currentActiveLtsa = this.hasCurrentActivePlantLtsa() ? 
                ActiveEntityLabels.YES : ActiveEntityLabels.UNKNOWN;

            if (this.currentActiveLtsa === ActiveEntityLabels.YES) {
                this.noLtsaActivityVerified = false;
            } else if (this.noLtsaActivityVerified) {
                this.currentActiveLtsa = ActiveEntityLabels.NO;
            }
        } else if (this.noLtsaActivityVerified) {
            this.currentActiveLtsa = ActiveEntityLabels.NO;
        } else {
            this.currentActiveLtsa = ActiveEntityLabels.UNKNOWN;
        }
        this.verifiedPlantLtsaCheckboDisabled = this.currentActiveLtsa === ActiveEntityLabels.YES;
    }

    hasCurrentActivePlantLtsa() {
        return this.rows.some(p => p.Status === LTSAStatusDescValues.Active && p.CapitalOrMaintenanceTypesBool);
    }

    resetForm() {
        this.noLtsaActivityVerified = null;
    }

}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-site',
    templateUrl: './site.component.html',
    styleUrls: ['./site.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedInfoSiteComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.SITE;
    entityTableKey =EntityName.SITE;
    constructor(
        public sharedService: SharedService) {
        super(sharedService);
    }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'SiteID', columnHeader: 'relatedInfo.site.siteId', routerEntityName: EntityName.SITE, widthPercentage: 14});
        columnsInfo.push({ entityPropName: 'SiteName', columnHeader: 'relatedInfo.site.siteName', widthPercentage: 30});
        columnsInfo.push({ entityPropName: 'Country', columnHeader: 'relatedInfo.site.country', widthPercentage: 14});
        columnsInfo.push({ entityPropName: 'State', columnHeader: 'relatedInfo.site.state', widthPercentage: 14});
        columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 14});
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 14});

        return columnsInfo;
    }

}

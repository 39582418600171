import {Injectable} from "@angular/core";
import {Adapter} from "../../adapter";

export class ProjectSearch {
    constructor(
        public projectID: string,
        public lastCompany: string,
        public projectName: string,
        public reviewDate: string,
        public duration: string,
        public completionDate: string,
        public projectRecordStatus: string,
        public afeDate: string,
        public rfqDate: string,
        public bidDocDate: string,
        public kickoffDate: string,
        public qcDate: string,
        public companyID: string,
        public ownerName: string,
        public physCity: string,
        public physCountry: string,
        public pecZoneSimplex: string,
        public tiv: string,
        public pecActivity: string,
        public pecTiming: string,
        public waterbodyName: string,
        public fieldName: string,
        public projectStatus: string,
        public projectStatusId: string,
        public enabled: string,
        public recordStatusDesc: string
    ) {}
}


@Injectable({
    providedIn: 'root',
})
export class ProjectSearchAdapter implements Adapter<ProjectSearch> {
    adapt(item: any): ProjectSearch {
        return new ProjectSearch(
            item.PROJECT_ID,
            item.LAST_COMPANY,
            item.PROJECT_NAME,
            item.REVIEW_DATE,
            item.DURATION,
            item.COMPLETION_DATE, 
            item.PROJECT_RECORD_STATUS,
            item.AFE_DATE,
            item.RFQ_DATE,
            item.BID_DOC_DATE,
            item.KICKOFF_DATE,
            item.QC_DATE,
            item.COMPANY_ID, 
            item.OWNER_NAME,
            item.PHYS_CITY, 
            item.PHYS_COUNTRY, 
            item.PEC_ZONE_SIMPLEX, 
            item.TIV || item.PROJECT_TIV,
            item.PEC_ACTIVITY, 
            item.PEC_TIMING, 
            item.WATERBODY_NAME, 
            item.FIELD_NAME, 
            item.PROJECT_STATUS,
            item.PROJECT_STATUS_ID || item.STATUS,
            item.ENABLED,
            item.RECORD_STATUS_DESC
        );
    }
}

<!-- footer -->
<div class="col-12 flex-none lg:flex xl:flex pt-3 pb-3 pr-3 pl-3 justify-content-center text-xs text-gray-300">
    <div class="col-12 md:col-12 lg:col-5 xl:col-4 text-center lg:justify-content-end xl:justify-content-end pb-0">
        <span>&copy; {{year}} {{'shared_components_footer_copyright' | translate}}</span>
    </div>
    <div class="col-12 md:col-12 lg:col-7 xl:col-6 flex-none md:flex lg:flex xl:flex text-center md:justify-content-center lg:justify-content-start xl:justify-content-start pt-0 lg:pt-2 xl:pt-2">
        <div class="col-12 md:col-3 lg:col-4 xl:col-4 text-center md:text-left lg:text-left xl:text-center pb-0 pt-0">
            <span>Environment: {{envName}}</span>
        </div>
        <div class="col-12 md:col-3 lg:col-4 xl:col-4 text-center md:text-center lg:text-left xl:text-center pb-0 pt-0">
            <span>Client: {{clientVersion}}</span>
        </div>
        <div class="col-12 md:col-3 lg:col-4 xl:col-4 text-center md:text-right lg:text-left xl:text-center pt-0">
            <span>Server: {{serverVersion}}</span>
        </div>
    </div>
</div>
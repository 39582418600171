import { Bean } from "../../bean";
import { Contacts } from "../../contact/save/contacts";
import { PlantsActivityInfo } from "./plants-activity-info";
import { PlantsBasins } from "./plants-basins";
import { PlantsCompanyInfo } from "./plants-company-info";
import { PlantsConnections } from "./plants-connections";
import { PlantsContactInfo } from "./plants-contact-info";
import { PlantsElectricalBusRating } from "./plants-electrical-busrating";
import { PlantsFaxInfo } from "./plants-fax-info";
import { PlantsFeedInfo } from "./plants-feed-info";
import { PlantsFuelTypes } from "./plants-fuel-types";
import { PlantsGreenhouseGasEmissions } from "./plants-greenhouse-gas-emissions";
import { PlantsGreenhouseGasFacilities } from "./plants-greenhouse-gas-facilities";
import { PlantsInternalAttachments } from "./plants-internal-attachments";
import { PlantsNotes } from "./plants-notes";
import { PlantsOutputInfo } from "./plants-output-info";
import { PlantsPhoneInfo } from "./plants-phone-info";
import { PlantsPipelineMeters } from "./plants-pipeline-meters";
import { PlantsProductInfo } from "./plants-product-info";
import { PlantsShalePlays } from "./plants-shale-plays";
import { PlantsSicCodes } from "./plants-sic-codes";
import { PlantsSicProduct } from "./plants-sic-product";
import { PlantsSic7Codes } from "./plants-sic7-codes";

export class Plants extends Bean {
    plantId: number;
    plantName: string;
    siteId: number;
    industryCode: string;
    dunsNumber: string;
    ultimateParentId: number;
    operatorId: number;
    faxCc: string;
    faxNo: string;
    faxExt: string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailPostalCode: string;
    physAddressV1: string;
    physAddressV2: string;
    physPostalCode: string;
    latitude: number;
    longitude: number;
    latLonVerified: string;
    nercRegion: string;
    nercSubregion: string;
    controlAreaId: string;
    pecZoneSimplex: string;
    plantSource: string;
    plantStatus: string;
    recordStatus: string;
    plantProbability: string;
    plantType: string;
    noEmployees: string;
    peakEmployees: string;
    unionFacility: string;
    wastewater: string;
    trHwy: number;
    trRail: number;
    trDock: number;
    trPipe: number;
    trAir: number;

    maintStaff: string;
    maintContract: number;
    shiftsDay: number;
    shiftLength: number;
    automotive: string;
    koDate: string;
    startupDate: string;
    shutdownDate: string;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    triId: string;
    epaId: string;
    millId: string;
    heatId: string;
    electConn: string;
    electConsumption: string;
    energyProducerType: string;
    steamProd: string;
    ngConn: string;
    ngConsumption: string;
    ngConsumptionActual: string;
    ngPipeline: string;
    majorUpdate: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    intdatadepamendstate: string;
    amendmentDate: string;
    amendmentUser: string;
    koResolution: string;
    startupDateResolution: string;
    shutdownResolution: string;
    eiaId: string;
    intdatadepmigerror: string;
    isInsert: boolean;

    miningAboveGround: number;
    miningPlacer: number;
    miningOpenPit: number;
    miningQuarry: number;
    miningStrip: number;
    miningAuger: number;
    miningHydraulic: number;
    miningDredging: number;
    miningBorehole: number;
    miningUnderground: number;
    miningDrift: number;
    miningShaft: number;
    miningSlope: number;
    miningLongwall: number;
    miningRoomPillar: number;
    miningCutFill: number;
    miningCaving: number;
    miningStoping: number;
    miningInSituSolution: number;
    miningCoalUseMetallurgical: number;
    miningCoalUseThermal: number;

    offshoreBlockV1: string;
    offshoreBlockV2: string;
    offshoreWaterbodyId: string;
    offshoreFieldId: string;
    offshoreAreaId: string;
    offshoreStateDesignation: string;
    offshorePlatformType: number;
    waterDepth: string;
    waterDepthUom: string;
    distanceFromShore: string;
    distanceFromShoreUom: string;
    railCompanyId: string;

    noProjActivityDate: string;
    noProjActivityUser: string;
    noEnergyProducerDate: string;
    noEnergyProducerUser: string;
    noLtsaActivityDate: string;
    noLtsaActivityUser: string;
    existingSqFtDate: string;
    existingSqFtUser: string;
    existingSqFt: string;

    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;
    physCountryId: number;
    physStateId: number;
    physCityId: number;
    physCountyId: number;
    noFaxVerifiedDate: string;
    noFaxVerifiedUser: string;

    // lists of beans - MVTs
    plantsCompanyInfo: Array<PlantsCompanyInfo>;
    plantsContactInfo: Array<PlantsContactInfo>;
    plantsElectricalBusRating: Array<PlantsElectricalBusRating>;
    plantsNotes: Array<PlantsNotes>;
    plantsFaxInfo: Array<PlantsFaxInfo>;
    plantsFeedInfo: Array<PlantsFeedInfo>;
    plantsFuelTypes: Array<PlantsFuelTypes>;
    plantsShalePlays: Array<PlantsShalePlays>;
    plantsBasins: Array<PlantsBasins>;
    plantsOutputInfo: Array<PlantsOutputInfo>;
    plantsPhoneInfo: Array<PlantsPhoneInfo>;
    plantsProductInfo: Array<PlantsProductInfo>;
    plantsSic7Codes: Array<PlantsSic7Codes>;
    plantsSicCodes: Array<PlantsSicCodes>;
    plantsSicProduct: Array<PlantsSicProduct>;
    plantsConnections: Array<PlantsConnections>;
    plantsPipelineMeters: Array<PlantsPipelineMeters>;
    plantsGreeenhouseGasEmissions: Array<PlantsGreenhouseGasEmissions>;
    plantsGreeenhouseGasFacilities: Array<PlantsGreenhouseGasFacilities>;

    contacts: Array<Contacts>;

    plantsInternalAttachments: Array<PlantsInternalAttachments>;
    plantsActivityInfo: Array<PlantsActivityInfo>;


    protected constructor(entity?: any) {
        super(entity);

        this.plantId = entity?.plantId ?? null;
        this.plantName = entity?.plantName ?? null;
        this.siteId = entity?.siteId ?? null;
        this.industryCode = entity?.industryCode ?? null;
        this.dunsNumber = entity?.dunsNumber ?? null;
        this.ultimateParentId = entity?.ultimateParentId ?? null;
        this.operatorId = entity?.operatorId ?? null;
        this.faxCc = entity?.faxCc ?? null;
        this.faxNo = entity?.faxNo ?? null;
        this.faxExt = entity?.faxExt ?? null;
        this.mailAddressV1 = entity?.mailAddressV1 ?? null;
        this.mailAddressV2 = entity?.mailAddressV2 ?? null;
        this.mailPostalCode = entity?.mailPostalCode ?? null;
        this.physAddressV1 = entity?.physAddressV1 ?? null;
        this.physAddressV2 = entity?.physAddressV2 ?? null;
        this.physPostalCode = entity?.physPostalCode ?? null;
        this.latitude = entity?.latitude ?? null;
        this.longitude = entity?.longitude ?? null;
        this.latLonVerified = entity?.latLonVerified ?? null;
        this.nercRegion = entity?.nercRegion ?? null;
        this.nercSubregion = entity?.nercSubregion ?? null;
        this.controlAreaId = entity?.controlAreaId ?? null;
        this.pecZoneSimplex = entity?.pecZoneSimplex ?? null;
        this.plantSource = entity?.plantSource ?? null;
        this.plantStatus = entity?.plantStatus ?? null;
        this.recordStatus = entity?.recordStatus ?? null;
        this.plantProbability = entity?.plantProbability ?? null;
        this.plantType = entity?.plantType ?? null;
        this.noEmployees = entity?.noEmployees ?? null;
        this.peakEmployees = entity?.peakEmployees ?? null;
        this.unionFacility = entity?.unionFacility ?? null;
        this.wastewater = entity?.wastewater ?? null;
        this.trHwy = entity?.trHwy ?? null;
        this.trRail = entity?.trRail ?? null;
        this.trDock = entity?.trDock ?? null;
        this.trPipe = entity?.trPipe ?? null;
        this.trAir = entity?.trAir ?? null;

        this.maintStaff = entity?.maintStaff ?? null;
        this.maintContract = entity?.maintContract ?? null;
        this.shiftsDay = entity?.shiftsDay ?? null;
        this.shiftLength = entity?.shiftLength ?? null;
        this.automotive = entity?.automotive ?? null;
        this.koDate = entity?.koDate ?? null;
        this.startupDate = entity?.startupDate ?? null;
        this.shutdownDate = entity?.shutdownDate ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.triId = entity?.triId ?? null;
        this.epaId = entity?.epaId ?? null;
        this.millId = entity?.millId ?? null;
        this.heatId = entity?.heatId ?? null;
        this.electConn = entity?.electConn ?? null;
        this.electConsumption = entity?.electConsumption ?? null;
        this.energyProducerType = entity?.energyProducerType ?? null;
        this.steamProd = entity?.steamProd ?? null;
        this.ngConn = entity?.ngConn ?? null;
        this.ngConsumption = entity?.ngConsumption ?? null;
        this.ngConsumptionActual = entity?.ngConsumptionActual ?? null;
        this.ngPipeline = entity?.ngPipeline ?? null;
        this.majorUpdate = entity?.majorUpdate ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.intdatadepamendstate = entity?.intdatadepamendstate ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.koResolution = entity?.koResolution ?? null;
        this.startupDateResolution = entity?.startupDateResolution ?? null;
        this.shutdownResolution = entity?.shutdownResolution ?? null;
        this.eiaId = entity?.eiaId ?? null;
        this.intdatadepmigerror = entity?.intdatadepmigerror ?? null;
        this.isInsert = entity?.isInsert ?? null;

        this.miningAboveGround = entity?.miningAboveGround ?? null;
        this.miningPlacer = entity?.miningPlacer ?? null;
        this.miningOpenPit = entity?.miningOpenPit ?? null;
        this.miningQuarry = entity?.miningQuarry ?? null;
        this.miningStrip = entity?.miningStrip ?? null;
        this.miningAuger = entity?.miningAuger ?? null;
        this.miningHydraulic = entity?.miningHydraulic ?? null;
        this.miningDredging = entity?.miningDredging ?? null;
        this.miningBorehole = entity?.miningBorehole ?? null;
        this.miningUnderground = entity?.miningUnderground ?? null;
        this.miningDrift = entity?.miningDrift ?? null;
        this.miningShaft = entity?.miningShaft ?? null;
        this.miningSlope = entity?.miningSlope ?? null;
        this.miningLongwall = entity?.miningLongwall ?? null;
        this.miningRoomPillar = entity?.miningRoomPillar ?? null;
        this.miningCutFill = entity?.miningCutFill ?? null;
        this.miningCaving = entity?.miningCaving ?? null;
        this.miningStoping = entity?.miningStoping ?? null;
        this.miningInSituSolution = entity?.miningInSituSolution ?? null;
        this.miningCoalUseMetallurgical = entity?.miningCoalUseMetallurgical ?? null;
        this.miningCoalUseThermal = entity?.miningCoalUseThermal ?? null;

        this.offshoreBlockV1 = entity?.offshoreBlockV1 ?? null;
        this.offshoreBlockV2 = entity?.offshoreBlockV2 ?? null;
        this.offshoreWaterbodyId = entity?.offshoreWaterbodyId ?? null;
        this.offshoreFieldId = entity?.offshoreFieldId ?? null;
        this.offshoreAreaId = entity?.offshoreAreaId ?? null;
        this.offshoreStateDesignation = entity?.offshoreStateDesignation ?? null;
        this.offshorePlatformType = entity?.offshorePlatformType ?? null;
        this.waterDepth = entity?.waterDepth ?? null;
        this.waterDepthUom = entity?.waterDepthUom ?? null;
        this.distanceFromShore = entity?.distanceFromShore ?? null;
        this.distanceFromShoreUom = entity?.distanceFromShoreUom ?? null;
        this.railCompanyId = entity?.railCompanyId ?? null;

        this.noProjActivityDate = entity?.noProjActivityDate ?? null;
        this.noProjActivityUser = entity?.noProjActivityUser ?? null;
        this.noEnergyProducerDate = entity?.noEnergyProducerDate ?? null;
        this.noEnergyProducerUser = entity?.noEnergyProducerUser ?? null;
        this.noLtsaActivityDate = entity?.noLtsaActivityDate ?? null;
        this.noLtsaActivityUser = entity?.noLtsaActivityUser ?? null;
        this.existingSqFtDate = entity?.existingSqFtDate ?? null;
        this.existingSqFtUser = entity?.existingSqFtUser ?? null;
        this.existingSqFt = entity?.existingSqFt ?? null;

        this.mailCountryId = entity?.mailCountryId ?? null;
        this.mailStateId = entity?.mailStateId ?? null;
        this.mailCityId = entity?.mailCityId ?? null;
        this.physCountryId = entity?.physCountryId ?? null;
        this.physStateId = entity?.physStateId ?? null;
        this.physCityId = entity?.physCityId ?? null;
        this.physCountyId = entity?.physCountyId ?? null;
        this.noFaxVerifiedDate = entity?.noFaxVerifiedDate ?? null;
        this.noFaxVerifiedUser = entity?.noFaxVerifiedUser ?? null;

        this.plantsCompanyInfo = entity?.plantsCompanyInfo ?? [];
        this.plantsContactInfo = entity?.plantsContactInfo ?? [];
        this.plantsElectricalBusRating = entity?.plantsElectricalBusRating ?? [];
        this.plantsNotes = entity?.plantsNotes ?? [];
        this.plantsFaxInfo = entity?.plantsFaxInfo ?? [];
        this.plantsFeedInfo = entity?.plantsFeedInfo ?? [];
        this.plantsFuelTypes = entity?.plantsFuelTypes ?? [];
        this.plantsShalePlays = entity?.plantsShalePlays ?? [];
        this.plantsBasins = entity?.plantsBasins ?? [];
        this.plantsOutputInfo = entity?.plantsOutputInfo ?? [];
        this.plantsPhoneInfo = entity?.plantsPhoneInfo ?? [];
        this.plantsProductInfo = entity?.plantsProductInfo ?? [];
        this.plantsSic7Codes = entity?.plantsSic7Codes ?? [];
        this.plantsSicCodes = entity?.plantsSicCodes ?? [];
        this.plantsSicProduct = entity?.plantsSicProduct ?? [];
        this.plantsConnections = entity?.plantsConnections ?? [];
        this.plantsPipelineMeters = entity?.plantsPipelineMeters ?? [];
        this.plantsGreeenhouseGasEmissions = entity?.plantsGreeenhouseGasEmissions ?? [];
        this.plantsGreeenhouseGasFacilities = entity?.plantsGreeenhouseGasFacilities ?? [];

        this.contacts = entity?.contacts ?? [];

        this.plantsInternalAttachments = entity?.plantsInternalAttachments ?? [];
        this.plantsActivityInfo = entity?.plantsActivityInfo ?? [];
    }

}

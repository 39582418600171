import { Adapter } from "../adapter";
import { Injectable } from "@angular/core";


export class TankSearch {
    constructor(
        public tankId: string,
        public tankName: string,
        public unitId: string,
        public unitName: string,
        public ownerId: string,
        public ownerName: string,
        public plantId: string,
        public plantName: string,
        public areaId: string,
        public areaName: string,
        public tankType: string,
        public tankTypeDesc: string,
        public sicCode: string,
        public sicCodeLargeDesc: string,
        public capacity: string,
        public product: string,
        public recordStatus: string,
        public recordStatusDesc: string,
        public qcDate: string,
        public tankStatus: string,
        public tankStatusDesc: string

    ) { }
}


@Injectable({
    providedIn: 'root',
})
export class TankSearchAdapter implements Adapter<TankSearch> {
    adapt(item: any): TankSearch {
        return new TankSearch(
            item.TANK_ID,
            item.TANK_NAME,
            item.UNIT_ID,
            item.UNIT_NAME,
            item.OWNER_ID,
            item.OWNER_NAME,
            item.PLANT_ID,
            item.PLANT_NAME,
            item.AREA_ID,
            item.AREA_NAME,
            item.TANK_TYPE_ID,
            item.TANK_TYPE_DESC,
            item.SIC_CODE,
            item.SIC_CODE ? `${item.SIC_CODE} - ${item.SIC_CODE_DESC}` : '',
            item.DESIGN_CAPACITY,
            item.HS_PRODUCTS_ID,
            item.RECORD_STATUS,
            item.RECORD_STATUS_DESC,
            item.QC_DATE,
            item.TANK_STATUS,
            item.TANK_STATUS_DESC
        );
    }
}



export class TypeResult {
    constructor(
        public typeId: number,
        public typeName: string,

    ) { }
}

export class TypeSearch {
    constructor(
        public typeId: number,
        public typeName: string,

    ) { }
}

export class TankType {
    public constructor(
        public typeId: number,
        public shortTypeName: string,
        public typeName: string
    ) { }

    static BuildTankType(fields: Array<any>): Array<TankType> {
        return fields.map(item => TankType.CreateInstance(item));
    }

    static CreateInstance(entity: any): TankType {
        return new TankType(
            entity.TANK_TYPE_ID,
            entity.TANK_TYPE_DESC,
            `${entity.TANK_TYPE_ID} - ${entity.TANK_TYPE_DESC}`,
        );
    }
}

export class TankStatus {
    statusID: string;
    shortDescription: string;
    description: string;
    rank: number;
    rank1: number;
    rank2: number;
    group: string;

    private constructor(
        statusID: string,
        shortDescription: string,
        description: string,
        rank: number,
        rank1: number,
        rank2: number,
        group: string
    ) {
        this.statusID = statusID;
        this.description = description;
        this.shortDescription = shortDescription;
        this.rank = rank;
        this.rank1 = rank1;
        this.rank2 = rank2;
        this.group = group;
    }

    static BuildTankStatus(entity: Array<any>): Array<TankStatus> {
        return entity.map(item => TankStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): TankStatus {
        return new TankStatus(
            record.TANK_STATUS_ID, 
            record.STATUS_DESC, 
            `${record.TANK_STATUS_ID} - ${record.STATUS_DESC}`,
            record.RANK, 
            record.RANK_1, 
            record.RANK_2, 
            record.STATUS_GROUP);
    }
}

<form [formGroup]="contactFormGroup">
    <div id="contactContainer" class="general-container grid">
        <!-- MAIN 1 -->
        <div class="col-12 xl:col-6 flex-order-1 xl:flex-order-0 pt-0">
            <!-- Contact ID -->
            <div class="grid mt-1">
                <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.contactId'| translate}}:</label>
                <div class="col-5 px-1">
                    <input
                        class="form__input-field-style w-6"
                        type="text"
                        formControlName="contactId"
                        pInputText
                        tabindex="-1"
                    />
                </div>
                <div class="col-3"></div>
            </div>
            <!-- Last Surname/Family -->
            <div class="grid">
                <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1 min-w-max">{{'contact.common.lastName'| translate}}:</label>
                <div class="col-6 px-1">
                    <input #firstTabElement
                        class="form__input-field-style w-full"
                        type="text"
                        formControlName="lastName"
                        pInputText appFocusElement
                    />
                </div>
                <div class="col-1 md:col-2 lg:col-2 xl:col-2 px-0">
                    <label type="text" class="required-field" pTooltip="This field is required" tooltipPosition="right">*</label>
                </div>
            </div>
            <!-- First Given -->
            <div class="grid">
                <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.firstName'| translate}}:</label>
                <div class="col-6 px-1">
                    <input
                        class="form__input-field-style w-full"
                        type="text"
                        formControlName="firstName"
                        pInputText
                    />
                </div>
                <div class="col-2 px-0">
                    <label type="text" class="required-field" pTooltip="This field is required" tooltipPosition="right">*</label>
                </div>
            </div>
        </div>
        <!-- MAIN 2 -->
        <div class="col-12 xl:col-6 -mt-3 flex-order-2">
            <!-- Middle y Nickname -->
            <div class="grid">
                <!-- Label Midle -->
                <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.middle'| translate}}:</label>
                <!-- Input Middle + Label nickname + Input nickname -->
                <div class="col-6 px-1">
                    <div class="grid">
                        <div class="col-4 pr-0">
                            <input class="form__input-field-style w-full"
                                formControlName="middleName"
                                maxlength="35"
                                pInputText
                            />
                        </div>
                        <div class="col-4 xl:col-3 text-right pl-0 pr-0">
                            <label class="font-bold text-xs mt-1 px-0">{{'contact.common.nickName'| translate}}:</label>
                        </div>
                        <div class="col-4 xl:col-5">
                            <input class="form__input-field-style w-full"
                                formControlName="nickName"
                                maxlength="16"
                                pInputText
                            />
                        </div>
                    </div>
                </div>
                <div class="col-2">           
                </div>
            </div>
            <!-- Matron y Saludation -->
            <div class="grid -mt-3">
                <label class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">{{'contact.common.matron'| translate}}:</label>
                <div class="col-6 px-1">
                    <div class="grid">
                        <div class="col-4 pr-0">
                            <input class="form__input-field-style w-full"
                            formControlName="matronName"
                            maxlength="16"
                            pInputText
                        />
                        </div>
                        <!-- Salutation label -->
                        <div class="col-4 xl:col-3 text-right pl-0 pr-0">
                            <label class="justify-content-end font-bold text-xs mt-1 px-0">{{'contact.common.salutation'| translate}}:</label>
                        </div>
                        <!-- Salutation dropdown -->
                        <div class="col-4 xl:col-5">
                            <p-dropdown
                                class="form__container-fields-layout--selects"
                                [options]="salutations"
                                [filter]="true"
                                showClear="true"
                                [placeholder]="'common.selectOne' | translate"
                                optionValue="data" 
                                optionLabel="label"  
                                formControlName="salutation"
                            >
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="col-2 px-0">
                    <label type="text" class="required-field" pTooltip="This field is required" tooltipPosition="right">*</label>
                </div>
            </div>
            <!-- Email -->
            <div class="grid -mt-4 pt-1">
                <div class="col-4 flex justify-content-end px-0 font-bold text-xs">
                    <app-email-verification
                        [emailAddress]="contactDisplay?.emailAddress"
                        [marketingEmailStatus]="contactDisplay?.marketingEmailStatus"
                        [marketingEmailOverrideStatus]="contactDisplay?.marketingEmailOverrideStatus"
                        [marketingOverrideDate]="contactDisplay?.marketingOverrideDate"
                        [marketingOverridePerson]="contactDisplay?.marketingOverridePerson"
                        [modalIcon]="true">
                    </app-email-verification>
                    <label class="mt-1 ml-1">{{'common.email'| translate}}:</label>
                </div>
                <div class="col-6 px-1">
                    <input
                        class="form__input-field-style w-full"
                        type="text"
                        formControlName="emailAddress"
                        pInputText
                        oninput="this.value=this.value.trim()"
                        (blur)="onBlurEmailAddress()"
                    />
                </div>
                <div class="col-2">
                </div>
            </div>
            <!-- Check Opt-Out -->
            <div class="grid">
                <div class="col-4 text-right px-0">
                    <!-- TODO: Need to eliminate the use of [disabled] here (see warning in console for more) -->
                    <p-checkbox
                        class="text-xs font-bold labelFirst boxSizeCompensate" 
                        [disabled]="contactFormGroup.disabled || !hasRoleIndustryManager" 
                        formControlName="contactOptedOutBool" 
                        (click)="checkContactOptedOut()" 
                        [binary]="true"
                        label="{{'contact.common.optOut'|translate}}:"
                    >
                    </p-checkbox>
                </div>
                <div class="col-8 text-red-600 text-xs pl-4">
                    <span  
                        *ngIf="contactFormGroup.controls.contactOptedOutBool.value">{{ 'contact.common.optOutWarning' | translate }}
                    </span>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
        <!-- AUDIT DATA -->
        <div class="col-12 xl:col-6 flex-order-0 xl:flex-order-1 flex text-xs pt-0">
            <div class="grid col-12">
                <div class="col-12 flex">           
                    <!-- Last User, Prev User && Entry User labels -->
                    <div class="col-4 ml-1">
                        <label class="flex justify-content-end font-bold min-w-max pt-1">{{'contact.common.contactLastUser'|translate}}:</label>
                        <label class="flex justify-content-end font-bold min-w-max pt-1">{{'contact.common.contactPrevUser'|translate}}:</label>
                        <label class="flex justify-content-end font-bold min-w-max pt-1">{{'contact.common.contactEntryUser' |translate}}:</label>
                    </div>
                    <!-- Users -->
                    <div class="col-3 w-auto px-0">
                        <div class="flex justify-content-start">
                            <label *ngIf="!displayLink(contactGeneric.iCopy.contactDisplay?.lastUserEmailAddress)" for="" class="pt-1">{{ contactGeneric.iCopy.contactDisplay?.lastUser }}</label>
                            <a *ngIf="displayLink(contactGeneric.iCopy.contactDisplay?.lastUserEmailAddress)" (click)="openUserModal(contactGeneric.iCopy.contactDisplay?.lastUser)" class="pt-1 cursor-pointer basic-link label-user">{{ contactGeneric.iCopy.contactDisplay?.lastUser }}</a>
                        </div>
                        <div class="flex justify-content-start">                        
                            <label *ngIf="!displayLink(contactGeneric.iCopy.contactDisplay?.previousUserEmailAddress)" for="" class="pt-1">{{ contactGeneric.iCopy.contactDisplay?.prevUser ?? '&nbsp;' }}</label>
                            <a *ngIf="displayLink(contactGeneric.iCopy.contactDisplay?.previousUserEmailAddress)" (click)="openUserModal(contactGeneric.iCopy.contactDisplay?.prevUser)" class="pt-1 cursor-pointer basic-link label-user">{{ contactGeneric.iCopy.contactDisplay?.prevUser }}</a>
                        </div>
                        <div class="flex justify-content-start"> 
                            <label *ngIf="!displayLink(contactGeneric.iCopy.contactDisplay?.entryUserEmailAddress)" for="" class="pt-1">{{ contactGeneric.iCopy.contactDisplay?.entryUser }}</label>
                            <a *ngIf="displayLink(contactGeneric.iCopy.contactDisplay?.entryUserEmailAddress)" (click)="openUserModal(contactGeneric.iCopy.contactDisplay?.entryUser)" class="pt-1 cursor-pointer basic-link label-user">{{ contactGeneric.iCopy.contactDisplay?.entryUser }}</a>
                        </div>
                    </div>                 
                    <!-- Date -->
                    <div class="col-5 ml-1">
                        <label class="flex justify-content-start pt-1">{{ contactGeneric.iCopy.contactDisplay?.lastDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                        <label class="flex justify-content-start pt-1">{{ ( contactGeneric.iCopy.contactDisplay?.prevDate | date: 'dd-MMM-y HH:mm:ss' ) ?? '&nbsp;' }}</label>
                        <label class="flex justify-content-start pt-1">{{ contactGeneric.iCopy.contactDisplay?.entryDate | date: 'dd-MMM-y HH:mm:ss' }}</label>
                    </div>
                </div>
                <div>
                    <div *ngIf="lockError?.length > 0" class="mb-1 white-space-normal">
                        <label class="text-xs font-bold text-red-500">{{lockMessageStart}}</label>
                        <a *ngIf="lockUserLink!==''" (click)="openUserModal(lockUserLink)" class="font-bold text-blue-500 cursor-pointer text-xs">{{lockUserLink}}</a>
                        <label *ngIf="lockMessageEnd!==''" class="text-xs font-bold text-red-500">{{lockMessageEnd}}</label>
                        <button class="text-xs w-8rem border-solid border-gray-600
                                       border-1 text-center ml-4 border-round-left-md
                                       border-round-right-md btn-record-reload" (click)="editRecord()">{{'common.editRecord'| translate}}</button>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- MAIN 3 -->    
        <div class="col-12 xl:col-6 -mt-3 flex-order-4">
            <!-- Linkedin -->
            <div class="grid">
                <div class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">
                    <label class="">{{'contact.common.urlLinkedin'|translate}}</label>
                </div>
                <div class="col-6 px-1">
                    <input type="text" pInputText class="form__input-field-style w-full" formControlName="linkedInId" (focusout)="linkedInFocusOut()" maxlength="60">
                </div>
                <div class="col-2 px-0">
                    <img src="assets/images/linkedin.png" pButton pRipple class="p-element pd p-button-info p-button-text pe-auto p-button-icon-only m-left"
                        style="width: 25px; height: 25px;"
                        width="25px" height="25px"
                        alt="info" data-toggle="tooltip"
                        data-placement="right" pTooltip="Open LinkedIn profile." (click)="navigateToLinkedInProfileUrl()">
                </div>
            </div>
            <!-- Sr/Jr -->
            <div class="grid">
                <div class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">
                    <label class="font-bold text-xs mt-1">{{'contact.common.srJr'|translate}}:</label>
                </div>
                <div class="col-6 px-1">
                    <input type="text" pInputText class="form__input-field-style w-full" formControlName="jrSrIII">
                </div>
                <div class="col-2 px-0">
                </div>
            </div>
            <!-- Nationality -->
            <div class="grid">
                <div class="col-4 flex justify-content-end px-0 font-bold text-xs mt-1">
                    <label class="font-bold text-xs mt-1">{{'contact.common.nationality'|translate}}:</label>
                </div>
                <div class="col-6 px-1">
                    <input
                        type="text"
                        pInputText
                        class="form__input-field-style w-3"
                        formControlName="nationality"
                        (blur)="nationalityOnBlur()"
                        (keyup.enter)="nationalityOnEnter()"
                        (keypress)="checkNoSymbols($event)"
                        appUppercase
                        maxlength="4">
                    <input
                        type="text" 
                        pInputText 
                        class="form__input-field-style w-9" 
                        formControlName="nationalityName"
                        tabindex="-1"
                        readonly
                        >
                </div>
                <div class="col-2 px-0">
                    <button 
                        pButton 
                        pRipple 
                        type="button" 
                        icon="pi pi-search" 
                        class="pd p-button-info p-button-text pe-auto m-left" 
                        [disabled]="contactFormGroup.disabled"
                        (click)="openNationalitySearchModal()">
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

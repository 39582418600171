export class CellItem {
  contactId: string;
  lockId: string;
  entityId: string; // composite id (type and number)
  entityNumberId: string;

  constructor(contactId: string, lockId: string, entityId: string, entityNumberId: string) {
    this.contactId = contactId;
    this.lockId = lockId;
    this.entityId = entityId;
    this.entityNumberId = entityNumberId;
  }
}

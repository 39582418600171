<div class="content-modal">
    <div class="container-modal">
        
        <div class="mt-2">
            <form [formGroup]="formGroup" class="grid col-12 mt-2 flex">
                <div class="grid col-12 xl:col-5 md:col-5 flex-wrap-reverse">
                    <label class="col-4 font-bold text-xs text-right px-0 mt-1">{{ 'clearLock.selectEntity' | translate }}</label>
                    <div class="align-selects col-6 px-1">
                         <p-dropdown 
                            [options]="entities" 
                            optionLabel="name"
                            showClear="true"
                            [filter]="true"
                            [placeholder]="'common.selectOne' | translate"
                            formControlName="entityName"
                            (onChange)="onChangeEntityName($event)">
                        </p-dropdown>
                    </div>
                </div>
                <div class="grid col-12 xl:col-5 md:col-5 flex-wrap-reverse">
                    <label class="col-4 font-bold text-xs text-right px-0 mt-1">{{ 'clearLock.entityId' | translate }}</label>
                    <div class="align-selects col-6 px-1">
                        <input 
                            #inputEntityId
                            pInputText
                            type="text"
                            class="form__input-field-style ml-1 col-12 px-1" 
                            formControlName="entityId"
                            (keydown.enter)="searchForRecord()"
                            (blur)="entityIdFocusOut($event)"
                            appOnlyNumbers />
                    </div>
                </div>
                <div class="grid col-12 xl:col-2 md:col-2 flex-wrap-reverse text-right fit-content mt-0">
                    <button class="btn-custom" label="Search for Record"  type="button" [disabled]="formGroup.invalid"
                    (click)="searchForRecord()" 
                    >{{ 'clearLock.searchForRecord' | translate }}</button>
                </div>
            </form>
        </div>
        <div class="grid mt-2 mb-5 justify-content-center">
           <!-- Audit component -->
           <div class="col-12 xl:col-8 md:col-8 sm:col-12 flex-order-0 lg:flex-order-1 xl:flex-order-1">
            <app-audit-entity-data [auditEntityBean]="clearLockUtility?.auditEntityBean" [contactEntity]="isContactEntity()"></app-audit-entity-data>
            <div class="mt-1" *ngIf="!validateClearLockbtn()">
                <label class="text-red-600 text-xs">{{messageInRedValidationFirst}}</label>
                <a *ngIf="lockHolderNameLink!==''" (click)="openUserModal(lockHolderNameLink)" class="text-blue-600 text-xs underline cursor-pointer">{{lockHolderNameLink}}</a>
                <label *ngIf="messageInRedValidationLast!==''" class="text-red-600 text-xs">{{messageInRedValidationLast}}</label>
            </div>
            </div>
        </div>
        <!-- btn section  -->
        <div class="grid mt-2 flex justify-content-around flex-wrap">
            <button *ngIf="hasAdminRole" class="btn-custom text-left mb-1" type="button" (click)="openModalViewCellContainer()">{{'clearLock.viewCellContainer' | translate}}</button>
            <button class="btn-custom text-center mb-1" type="button" (click)="clearLock()" [disabled]="validateClearLockbtn()">{{'clearLock.clearLock' | translate}}</button>
            <button class="btn-custom text-right mb-1" type="button" (click)="getRemoveWIPStatus()" [disabled]="validateRemoveWIPStatus()">{{'clearLock.removeWIPStatus' | translate}}</button>
        </div>
    </div>
</div>

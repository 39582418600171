import { OfflineEventsBoilers } from "../save/offline-events-boilers";

export class OfflineEventsBoilersDisplay extends OfflineEventsBoilers {
    
    boilerName: string = null;
    offlineEventCategoryName: string = null;
    manufacturerName: string = null;
    typeDescription: string = null;

    constructor(entity?: any) {
        super(entity);

        this.boilerName = entity?.boilerName ?? null;
        this.offlineEventCategoryName = entity?.offlineEventCategoryName ?? null;
        this.manufacturerName = entity?.manufacturerName ?? null;
        this.typeDescription = entity?.typeDescription ?? null;

        this.initialData = entity;
    }

    static CreateInstance(entity: any): OfflineEventsBoilersDisplay {
        return new OfflineEventsBoilersDisplay(entity);
    }
    
    static BuildFromList(entity: Array<any>): Array<OfflineEventsBoilersDisplay> {
          return entity.map(item => OfflineEventsBoilersDisplay.CreateInstance(item));
    }
}

export enum OperationType {
  TEMPORAL = -1,
  IDLE = 0,
  INSERT = 1,
  UPDATE = 2,
  DELETE = 3,
  SELF_ERROR = 4,
  REQUEST_RE_QC = 5,
  DUPLICATE = 6
}

import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CountryWaterBody } from 'src/app/core/models/offshoreAddress';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';

@Component({
    selector: 'app-country-state-modal',
    templateUrl: './country-state-modal.component.html',
    styleUrls: ['./country-state-modal.component.scss']
})

export class CountryStateModalComponent implements OnInit {
    countriesByWaterBody: Array<CountryWaterBody>;
    selection: any;
    @ViewChildren('tr') private rows: QueryList<ElementRef>;
    waterBody: any = {};

    constructor(
        private offshoreAddressService: OffshoreAddressService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public messageHandlerService: MessageHandlerService
    ) { }

    ngOnInit(): void {
        this.countriesByWaterBody = this.config.data.countriesByWaterBody;
        this.loadList();
    }

    public loadList() {
        if (!this.countriesByWaterBody) {
            this.waterBody = this.config.data.waterBody;
            this.offshoreAddressService
                .getCountriesWaterBodyById(true, this.waterBody.waterBodyId)
                .subscribe((countriesByWaterBody: CountryWaterBody[]) => {
                    this.countriesByWaterBody = countriesByWaterBody;
                    this.focusFirstElement();
                });
        } else {
            this.waterBody.waterBodyName = this.config.data.waterBody[0].waterBodyName;
        }
    }

    onRowDblclick(selRow: any) {
        this.returnWaterbody(selRow);
    }

    private returnWaterbody(selRow: any) {
        let result = {
            cId: selRow.cId,
            countryName: selRow.countryName,
            sId: selRow.sId,
            stateName: selRow.stateName,
        };
        this.activeModal.close(result);
    }

    onRowSelect(event: any) {
        this.selection = event.data;
    }

    addRowSingleClick() {
        this.onRowDblclick(this.selection);
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selection", event, entityArray, hasHeader);
    }

    closeModal() {
        this.activeModal.close();
    }

    private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 0);
    }
    
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DeactivatableEntity } from '../models/deactivatable-entity';
import { Observable, map, of } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

   constructor(private authService: AuthService, private router: Router) { }

   canLoad(): boolean {
      const canModule = this.authService.isLoggedIn();
      if (!canModule) {
         this.router.navigateByUrl('/');
      }
      return canModule;
   }

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
      const hasSession = this.authService.isLoggedIn();
      if (!hasSession) {
         this.router.navigateByUrl('/');
         console.debug("Not logged in, redirecting.")
         return false;
      }

      const screen = route.data.screen;
      const role = route.data.role;
      if (screen) {
         return this.authService.hasUserScreen(screen)
            .pipe(
               map(hasScreen => {
                  if (!hasScreen) {
                     this.router.navigateByUrl('/');
                     console.debug(`Access denied to ${route.url}, missing screen: ${screen}`);
                  }
                  return hasScreen;
               })
            );
       } else if (role) {
         return this.authService.isUserInRole(role)
            .pipe(
               map(hasRole => {
                  if (!hasRole) {
                     this.router.navigateByUrl('/');
                     console.debug(`Access denied to ${route.url}, missing role ${role}`);
                  }
                  return hasRole;
               })
            );
       }
   }

   canActivateChild(): boolean {
      const canActivateChild = this.authService.isLoggedIn();
      if (!canActivateChild) {
         this.router.navigateByUrl('/');
      }
      return canActivateChild;
   }

   canDeactivate(component: DeactivatableEntity): boolean {
      if (this.authService.isLoggingOut() || !this.authService.isLoggedIn()) {
         return true;
      }
      if (component.preventClose()) {
         return false;
      }
      return true;
   }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AsideService } from './aside.service';
import { DialogService } from 'primeng/dynamicdialog';


@Component({
    selector: 'app-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit, OnDestroy {

    classToggled = false;
    groups = [];
    private menuChanged: Subscription;
    isDash: boolean = false;
    widgetFullScreen: boolean;

    constructor(private asideService: AsideService,
                private dialogService: DialogService
    ) { }

    ngOnInit(): void {
        this.menuChanged = this.asideService.menuChanged$.subscribe(menuData => {
            this.isDash = menuData.name === 'home';
        });
    }

    ngOnDestroy(): void {
        this.menuChanged?.unsubscribe();
    }

    toggleField() {
        if(this.dialogService.dialogComponentRefMap.size === 0){
            this.classToggled = !this.classToggled;
        }      
    }

    onScrollOption(): void {
        this.classToggled = false;
    }
}

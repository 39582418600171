import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[idFilter]'
})
export class IdFilterDirective implements OnInit{

    constructor(private ngControl: NgControl) { }

    ngOnInit(): void {
        this.ngControl.control.valueChanges.subscribe(() => {
            if(this.ngControl.value !== null){
                let numberValue = this.replace(this.ngControl.value);
                if(this.ngControl.value !== numberValue){
                    this.ngControl.control.setValue(numberValue);
                }
            }

        });
    }

    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        input.value = this.replace(this.ngControl.value);
    }

    replace(value: any) {
        return String(value).replace(/[^0-9]*/g, '');
    }

}

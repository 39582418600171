import { Bean } from "../../bean";

export class PipelinesLTSASubTypes extends Bean {
    pipelineLtsaId: number;
    serviceAgreementSubTypeId: number;
    mvId: number;
    mvOrder: number;
    lastDate: string;

    protected constructor(entity: any) {
        super(entity);
        this.pipelineLtsaId = entity?.pipelineLtsaId ?? null;
        this.serviceAgreementSubTypeId = entity?.serviceAgreementSubTypeId ?? null;
        this.mvId = entity?.mvId ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.lastDate = entity?.lastDate ?? null;
    }
}

import { AreasPhoneInfo } from "../save/areas-phone-info";

export class AreasPhoneInfoDisplay extends AreasPhoneInfo {

  private constructor(entity?: any){
    super(entity)

    this.initialData = entity;
  }


  static CreateInstance(entity: any): AreasPhoneInfoDisplay {
    return new AreasPhoneInfoDisplay(entity);
  }

  static BuildFromList(entity: Array<any>): Array<AreasPhoneInfoDisplay> {
      return entity.map(item => AreasPhoneInfoDisplay.CreateInstance(item));
  }
}
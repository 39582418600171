import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { News } from 'src/app/core/models/common';
import { WebsiteService } from 'src/app/core/services/website.service';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class RelatedInfoNewsComponent extends RelatedInfoBase implements OnInit {
    entityTableKey =EntityName.NEWS;
    constructor(
        public sharedService: SharedService,
        private website: WebsiteService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'CategoryName', columnHeader: 'relatedInfo.news.category', widthPercentage: 30});
        columnsInfo.push({ entityPropName: 'ArticleSubject', columnHeader: 'relatedInfo.news.newsTitle', getUrlLink: this.getArticleUrlLink, widthPercentage: 55});

        return columnsInfo;
    }

    getArticleUrlLink(implementedComponent: RelatedInfoNewsComponent, rowData: News): string
    {
        return implementedComponent.website.getSSOURL(`news/article.jsp?newsitemID=${rowData.Id}`);
    }

}

import {Bean} from "../../bean";

export class ContactsComments extends Bean {
    mvId: number;
    contactId: number;
    comments: string;
    mvOrder: number;
    createUserId: number;
    createDate: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity?.mvId ?? null;
        this.contactId = entity?.contactId ?? null;
        this.comments = entity?.comments ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.createUserId = entity?.createUserId ?? null;
        this.createDate = entity?.createDate ?? null;
    }
}

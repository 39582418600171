import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IProjectSearchParams } from 'src/app/core/interfaces/search/iproject-search-params';
import { Constants, EntityPreferenceSufix, HexaColor, ProjectStatusValue } from 'src/app/core/models/constants';
import { CountryWaterBody, OffshoreField, WaterBody } from 'src/app/core/models/offshoreAddress';
import { ProjectRecordStatus, ProjectStatus } from 'src/app/core/models/project';
import { ProjectSearch } from 'src/app/core/models/search/project-search';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { SharedService } from 'src/app/core/services/shared.service';
import { Country, State } from '../../../core/models/common';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageEvent, MessageResponse, MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { BaseModalComponent } from '../base-modals.component';
import { PecZoneModalComponent } from '../pec-zone-modal/pec-zone-modal.component';
import { SearchPlantComponent } from '../search-plant/search-plant.component';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { ProjectSectionService } from 'src/app/project-section/project-section.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-project',
    templateUrl: './search-project.component.html',
    styleUrls: ['./search-project.component.css']
})

export class SearchProjectComponent extends BaseModalComponent implements OnInit {
    @ViewChild('projectId') inputProjectId: ElementRef;
    @ViewChild('plantId') inputPlantId: ElementRef;
    @ViewChild('pecZoneInput') pecZoneInput: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    projects: Array<ProjectSearch> = [];
    additionalInfo: ProjectSearch;
    recordStatuses: Array<ProjectRecordStatus> = [];
    projectStatuses: Array<ProjectStatus> = [];
    confirmedProjectStatuses: Array<ProjectStatus> = [];
    unconfirmedProjectStatuses: Array<ProjectStatus> = [];
    physCountries: Array<Country> = [];
    physStates: Array<State> = [];
    waterBodies: Array<WaterBody> = [];
    fields: Array<OffshoreField> = [];
    selected: any;
    colsOnshore: any[] = [];
    colsOffshore: any[] = [];
    changeClass: boolean = false;
    isMainAccess: boolean = false;
    isUnconfirmedScreen: boolean = false;

    searchParams: IProjectSearchParams = {
        projectID: null,
        plantID: null,
        ownerID: null,
        ownerName: '',
        projectName: '',
        pecZone: '',
        country: null,
        state: null,
        offshore: '0',
        fieldName: '',
        waterBody: null,
        recordStatus: null,
        projectStatus: '',
        recordedSearch: false,
    }

    constructor(
        private projectService: ProjectSectionService,
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public preferencesService: PreferencesSectionService,
        public translate: TranslateService,
        public loadWheelService: LoadWheelService) {
        super(activeModal, config, preferencesService);
    }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.colsOnshore = [
            { field: 'projectID', header: this.translate.instant('project.searchProject.table.projectID'), width: '10%' },
            { field: 'projectName', header: this.translate.instant('project.searchProject.table.projectName'), width: '28%' },
            { field: 'ownerName', header: this.translate.instant('project.searchProject.table.owner'), width: '25%' },
            { field: 'physCity', header: this.translate.instant('project.searchProject.table.city'), width: '15%' },
            { field: 'physCountry', header: this.translate.instant('project.searchProject.table.country'), width: '15%' },
            { field: 'projectStatus', header: this.translate.instant('project.searchProject.table.status'), width: '10%' }
        ]

        this.colsOffshore = [
            { field: 'projectID', header: this.translate.instant('project.searchProject.table.projectID'), width: '10%' },
            { field: 'projectName', header: this.translate.instant('project.searchProject.table.projectName'), width: '28%' },
            { field: 'ownerName', header: this.translate.instant('project.searchProject.table.owner'), width: '25%' },
            { field: 'waterbodyName', header: this.translate.instant('project.searchProject.table.waterBody'), width: '15%' },
            { field: 'fieldName', header: this.translate.instant('project.searchProject.table.fieldName'), width: '15%' },
            { field: 'projectStatus', header: this.translate.instant('project.searchProject.table.status'), width: '10%' }
        ]

        this.isUnconfirmedScreen = this.config.data?.isUnconfirmedScreen;
        this.loadList();
        this.clear();
        this.searchProjectByParamsRecording();
    }

    onOffshoreChange() {
        this.clearFields(false);
        this.loadCountries();
    }

    search() {
        this.searchProject(this.searchParams);
    }

    private searchProjectByParamsRecording() {
        this.searchParams = this.projectService.searchParams;
        if (this.searchParams.recordedSearch) {
            if (!this.isEmptyObjectProperty(this.projectService.searchParams)) {
                this.searchProject(this.projectService.searchParams);
            }
        }
        if (this.searchParams.country !== '') {
            this.loadStates(this.searchParams.country);
        }
    }

    private isEmptyObjectProperty(object: IProjectSearchParams): boolean {
        const { offshore, recordedSearch, ...search } = object;
        return Object.values(search).every(x => (x === null || x === '' || x === 0));
    }

    returnFocus = (msgModal: string) => {
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                this.inputProjectId.nativeElement.focus();
            }
        }
        this.messageHandler.show(this.translate.instant(msgModal), MessageType.INFO, selectOpt)
    }

    handleListboxKeydown(event: KeyboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'Tab') {
            this.inputProjectId.nativeElement.focus();
        } else if (event.shiftKey && event.key === 'Tab') {
            this.inputPlantId.nativeElement.focus();
        }
    }
    
    private searchProject(params: IProjectSearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.projects"));
        this.projectService.searchProject(params, wheel)
            .pipe(distinctUntilKeyChanged(params.projectID),
                distinctUntilKeyChanged(params.plantID))
            .subscribe({
                next: (projects: ProjectSearch[]) => {
                    projects = projects.filter((project, index, self) =>
                        index === self.findIndex((p) => (
                            p.projectID === project.projectID && p.projectID === project.projectID
                        ))
                    );
                    if(projects === null) {
                        projects = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                    } else if (projects.length === 0) {
                        this.returnFocus("common.searchNotFound");
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                    } else {
                        this.additionalInfo = projects[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                    }
                    this.projects = projects;
                    this.loadWheelService.hideWheel(wheel);
                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.returnFocus(error.message);
                        this.projects = [];
                        this.additionalInfo = null;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    onRowDblclick(event: any) {
        if(this.selectableProject(event)){
            this.activeModal.close(event);
        }
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onChangePhysCountry() {
        if (this.searchParams.offshore === '0') {
            // Onshore, then we load the states by country.
            if (this.searchParams.country !== '' && this.searchParams.country !== null) {
                this.loadStates(this.searchParams.country);
            } else {
                this.physStates = [];
            }
            this.searchParams.state = null;
        } else {
            // Offshore, then we load the waterBodies by country.
            if (this.searchParams.country !== '' && this.searchParams.country !== null) {
                this.loadWaterBodies(this.searchParams.country);
            } else {
                this.waterBodies = [];
            }
            this.searchParams.waterBody = null;
        }
    }

    onChangePhysWaterBody() {
        if (this.searchParams.waterBody !== '0' && this.searchParams.waterBody !== null) {
            this.loadFields(Number(this.searchParams.waterBody));
        } else {
            this.fields = [];
        }
        this.searchParams.fieldName = '';
    }

    clear() {
        this.clearFields(true);
    }

    private clearFields(clearAll: boolean) {
        this.searchParams.projectID = null;
        this.searchParams.plantID = null;
        this.searchParams.ownerID = null;
        this.searchParams.ownerName = '';
        this.searchParams.projectName = '';
        this.searchParams.pecZone = '';
        this.searchParams.country = null;
        this.searchParams.state = null;
        this.searchParams.fieldName = '';
        this.searchParams.waterBody = null;
        this.searchParams.recordStatus = null;
        this.searchParams.projectStatus = '';

        this.projects = [];
        this.physStates = [];
        this.fields = [];
        this.additionalInfo = null;
        this.changeClass = true;

        if (clearAll) {
            this.searchParams.offshore = '0';
            setTimeout(() => this.inputProjectId.nativeElement.focus(), 1);
        }
    }

    private loadList() {
        this.loadRecordStatus();
        this.loadProjectStatus();
        this.loadCountries();
    }

    private loadProjectStatus() {
        this.sharedService.getProjectStatus()
            .subscribe((statuses: ProjectStatus[]) => {
                this.projectStatuses = [...statuses];
                statuses.forEach(status=>{
                    if(this.isUnconfirmedProjectByStatus(status.statusID)){
                        this.unconfirmedProjectStatuses.push(status);   
                    }else{
                        this.confirmedProjectStatuses.push(status);
                    }
                });
                this.serProjectStatuses();
            });
    }

    private serProjectStatuses(){
        if(this.isUnconfirmedScreen){
            this.projectStatuses = this.unconfirmedProjectStatuses;
        }else{
            this.projectStatuses = this.confirmedProjectStatuses;
        }
    }

    public isUnconfirmedProjectByStatus(status: string): boolean{
        return status === ProjectStatusValue.Unconfirmed ||
        status === ProjectStatusValue.UnableToConfirm
    }

    private loadRecordStatus() {
        this.projectService.getProjectRecordStatus()
            .subscribe((record: ProjectRecordStatus[]) => this.recordStatuses = [...record]);
    }

    private loadCountries() {
        if (this.searchParams.offshore === '0') {
            this.loadOnshoreCountries();
        } else {
            this.loadOffshoreCountries();
        }
    }

    private loadOnshoreCountries() {
        this.addressService.getCountries()
            .subscribe((countries: Country[]) => {
                this.physCountries = [...countries];
            });
    }

    private loadOffshoreCountries() {
        this.offshoreAddressService.getCountriesWaterBodyById(true, '')
            .subscribe((countries: CountryWaterBody[]) => {
                const mappedCountries: Country[] = countries.map(it => {
                    return new Country(
                        it.cId,
                        it.countryId,
                        it.countryName,
                        0,
                        it.countryId + ' - ' + it.countryName);
                });

                this.physCountries = [...mappedCountries];
                this.waterBodies = [];
            });
    }

    private loadStates(countryId: string) {
        this.addressService.getStateByCountry(countryId, '', true)
            .subscribe((states: State[]) => this.physStates = [...states]);
    }

    private loadWaterBodies(countryName: string) {
        this.offshoreAddressService.getWaterBodies(countryName)
            .subscribe((waterBodies: WaterBody[]) => this.waterBodies = [...waterBodies]);
    }

    private loadFields(waterBodyId: number) {
        this.offshoreAddressService.getOffshoreFields(waterBodyId, '', false)
            .subscribe((offshoreField: OffshoreField[]) => this.fields = [...offshoreField]);
    }

    newRecord() {
        // Not implemented yet - will be used to create a new project record.
    }

    getRowStyle(project: ProjectSearch): any {
        return { 'color': this.selectableProject(project) ? '' : HexaColor.lightGray }
    }

    selectableProject(project: ProjectSearch): boolean{
        return !this.isUnconfirmedScreen || 
                (this.isUnconfirmedScreen &&
                    this.isUnconfirmedProjectByStatus(project.projectStatusId)) ||
                (this.isUnconfirmedScreen && 
                    !this.isUnconfirmedProjectByStatus(project.projectStatusId) &&
                    project.qcDate === null)
    }

    getRowTooltip(project: ProjectSearch): string {
        return this.selectableProject(project) ? '' : this.translate.instant('project.confirmedMessage');
    }

    openPlantSearchModal() {
        const modalRef = this.dialogService.open(SearchPlantComponent, {
            header: 'Plant Search',
            width: '90rem',
            data: {},
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((plantSearch: any) => {
            if (plantSearch) {
                this.searchParams.plantID = plantSearch.plantId;
            }
        });
    }

    openZoneSearchModal() {
        const modalRef = this.dialogService.open(PecZoneModalComponent, {
            header: 'PEC Zone Search',
            width: '40rem',
            data: {},
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((zone: any) => {
            if (zone) {
                this.searchParams.pecZone = zone.pecZoneId;
                this.pecZoneInput.nativeElement.focus();
            }
        });
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

}


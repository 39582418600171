import { Bean } from '../../bean';
import { AreasContactInfo } from './areas-contact-info';
import { AreasNotes } from '../save/areas-notes';
import { AreasFaxInfo } from '../save/areas-fax-info';
import { AreasOwnerInfo } from '../save/areas-owner-info';
import { AreasPhoneInfo } from '../save/areas-phone-info';
import { Contacts } from '../../contact/save/contacts';

export class Areas extends Bean {
    areaId: number;
    areaName: string;
    operatorId: number;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    plantId: number;
    areaStatus: string;
    recordStatus: string;
    statusOld: string;
    areaType: string;
    qcUser: string;
    qcDate: string;
    industryCode: string;
    startUpDate: string;
    shutDownDate: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    majorUpdate: string;
    amendmentDate: string;
    amendmentUser: string;

    areasContactInfo: AreasContactInfo[] = [];
    areasNotes: AreasNotes[] = [];
    areasFaxInfo: AreasFaxInfo[] = [];
    areasOwnerInfo: AreasOwnerInfo[] = [];
    areasPhoneInfo: AreasPhoneInfo[] = [];

    contacts: Contacts[] = []; 

    constructor(entity?: any) {
        super(entity);

        this.areaId = entity?.areaId ?? null;
        this.areaName = entity?.areaName ?? '';
        this.operatorId = entity?.operatorId ?? '';
        this.entryUser = entity?.entryUser ?? '';
        this.entryCompany = entity?.entryCompany ?? '';
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? '';
        this.lastCompany = entity?.lastCompany ?? '';
        this.lastDate = entity?.lastDate ?? null;
        this.prevUser = entity?.prevUser ?? '';
        this.prevCompany = entity?.prevCompany ?? '';
        this.prevDate = entity?.prevDate ?? null;
        this.plantId = entity?.plantId ?? '';
        this.areaStatus = entity?.areaStatus ?? null;
        this.recordStatus = entity?.recordStatus ?? null;
        this.statusOld = entity?.statusOld ?? '';
        this.areaType = entity?.areaType ?? null;
        this.qcUser = entity?.qcUser ?? '';
        this.qcDate = entity?.qcDate ?? null;
        this.industryCode = entity?.industryCode ?? '';
        this.startUpDate = entity?.startUpDate ?? null;
        this.shutDownDate = entity?.shutDownDate ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? '';
        this.majorUpdate = entity?.majorUpdate ?? '';
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? '';

        this.areasContactInfo = entity?.areasContactInfo ?? [];
        this.areasNotes = entity?.areasNotes ?? [];
        this.areasFaxInfo = entity?.areasFaxInfo ?? [];
        this.areasOwnerInfo = entity?.areasOwnerInfo ?? [];
        this.areasPhoneInfo = entity?.areasPhoneInfo ?? [];

        this.contacts = entity?.contacts ?? [];
    }
}
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
//Service
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { AddressFormComponent } from 'src/app/components/address-form/address-form.component';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { LocationSearchComponent } from 'src/app/components/modals/location-search/location-search.component';
import { SearchModalComponent } from 'src/app/components/modals/search-modal/search-modal.component';
import { TitleQuerySearchComponent } from 'src/app/components/modals/title-query-search/title-query-search.component';
import { UserModalComponent } from 'src/app/components/modals/user-modal/user-modal.component';
import { NotesComponent } from 'src/app/components/notes/notes.component';
import { NoteService } from 'src/app/components/notes/notes.service';
import { IContact, IContactMap } from 'src/app/core/interfaces/icontacts';
import { INote } from 'src/app/core/interfaces/inotes';
import { Address, CompanySearch } from 'src/app/core/models';
import { Constants } from 'src/app/core/models/constants';
import { ContactOperation } from 'src/app/core/models/contact';
import { ContactsCommentsDisplay } from 'src/app/core/models/contact/display/contacts-comments-display';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { ContactService } from 'src/app/core/services/contact.service';
import { SessionService } from 'src/app/core/services/session.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { ContactDetailsSectionService } from '../contact-details-section.service';
//Components
import { Calendar } from 'primeng/calendar';
import { BaseComponent } from 'src/app/base.component';
import { JobFunction } from 'src/app/core/models/common';
import { ServerDateService } from 'src/app/core/services/serverdate.service';
import { ContactRelatedComponent, ContactRelatedResponse } from './contact-related/contact-related.component';
import { FormContactDetailsComponent } from './form-contact-details/form-contact-details.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { BaseModalComponent } from 'src/app/components/modals/base-modals.component';

export interface ContactModalData {
    entityId: number;
    entityName: string;
    saveEntityContactBtnEnabled: boolean;
    viewContactAssociationsEnabled: boolean;
    originalTitleId: string;
    contactGeneric: IContact;
    contactMap: IContactMap;
    acContactsSlotted: Array<IContact>;
    entityContactInfoDisplays?: Array<IContact>;
    readOnly?: boolean;
}

export enum ContactRelatedEntities {
    CONTACT_ASSOCIATIONS, CONTACT_MAILING_ADDRESS
}

@Component({
    selector: 'app-contact-details-modal',
    templateUrl: './contact-details-modal.component.html',
    styleUrls: ['./contact-details-modal.component.scss']
})
export class ContactDetailsModalComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('contactAddress', { static: false }) contactAddress: AddressFormComponent;
    @ViewChild('contactData', { static: false }) contactData: FormContactDetailsComponent;
    @ViewChild('commentsComponent', { static: false }) commentsComponent: NotesComponent;
    @ViewChild('qcDate', { static: false }) qcDate: Calendar;
    @ViewChild('inputIcon', { read: ElementRef }) calendar: ElementRef;
    @ViewChild('inputTitleQuery', { static: false }) inputTitleQuery: ElementRef;
    @ViewChild('firstTabElement', { static: false }) firstTabElement: ElementRef;
    @Input() baseIndex: number = 10000;

    entityId: number;
    entityName: string;
    entityStatus: string;
    check: boolean = false;
    entityContactFormGroup: FormGroup;
    salutations: any[] = [];
    status: any[] = [
        { data: 'CURRENT', label: 'C - CURRENT' },
        { data: 'DEAD', label: 'D - DEAD' },
        { data: 'MOVED', label: 'M - MOVED' },
        { data: 'RETIRED', label: 'R - RETIRED' },
        { data: 'TRASH', label: 'T - TRASH' },
    ];
    contactGeneric: IContact;
    contactMap: IContactMap;
    contactQcDate: Date;
    originalContactQcDate: Date;
    existingAddressButtonDisabled: boolean = false;
    disabledSaveRecordButton: boolean = false;
    displayBoolean: boolean = false;
    newContactId: any
    acAllContactsProvider: Array<any> = [];
    countryExceptions: Array<any> = [];
    contactsIdByEmail: Array<any> = [];
    acContactsSlotted: Array<IContact>;
    entityContactInfoDisplays: Array<IContact> = [];
    readOnly: boolean = false;
    private fieldsChangeSub: Subscription;
    private fieldChangeEntityContact: Object;
    disableFormSub: Subscription;
    disabledViewContactAssociations: boolean = true;
    showRelatedOnSave: boolean = false;
    maxContactQcDate: Date;
    editableCompany: boolean;
    initialChangeStatus: boolean = false;
    contactDisplayLocked = false;
    onCloseDefaultFunction: any;
    colors: any = {};
    mailAddress: IContactMap;
    jobFunctions: Array<JobFunction>;

    contactAddressChangedFieldsReMap: Map<string, string> = new Map([
        ['zipCode', 'mailPostalCode'],
        ['address1', 'mailAddressLine1'],
        ['address2', 'mailAddressLine2'],
        ['countryId', 'mailCountryId'],
        ['stateAbbrev', 'mailStateId'],
        ['cityName', 'mailCityId']
    ]);
    contactDetailsChangedFieldsReMap: Map<string, string> = new Map([
        ['companyId', 'contactCompanies'],
        ['contactOnsiteBool', 'contactOnsite'],
        ['contactSaleFlagBool', 'contactSaleFlag'],
        ['contactPhoneMobileSel', 'contactPhoneMobile'],
        ['contactAltPhoneMobileSel', 'contactAltPhoneMobile'],
        ['mailAddressVerifiedBool', 'mailAddressVerified']
    ]);

    selectedTabIndex: number = 0;


    constructor(
        public activeModal: DynamicDialogRef,
        public dialogService: DialogService,
        public session: SessionService,
        private serverDate: ServerDateService,
        public contactDetailsSectionService: ContactDetailsSectionService,
        public contactService: ContactService,
        public messageHandlerService: MessageHandlerService,
        public dinamicDialogConfig: DynamicDialogConfig,
        public sharedService: SharedService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        public fb: FormBuilder,
        public noteService: NoteService,
        private companyService: CompanySectionService,
        private headerService: HeaderService,
        private translate: TranslateService) {
        super(recordLockingFlow, preferencesService);
    }

    ngOnInit(): void {
        const cmd = this.dinamicDialogConfig.data;
        this.initialChangeStatus = this.headerService.changeForm;
        this.contactMap = cmd.contactMap;
        this.entityId = cmd.entityId;
        this.entityName = cmd.entityName;
        this.entityStatus = cmd.entityStatus;
        this.contactGeneric = cmd.contactGeneric;
        this.newContactId = cmd.contactGeneric.contactId;
        this.contactDisplayLocked = cmd.contactGeneric.contactDisplay.lockError !== null;
        this.editableCompany = this.entityName === EntityName.SITE ||
						             this.entityName === EntityName.PLANT ||
						             this.entityName === EntityName.AREA ||
                                     this.entityName === EntityName.PLANT_LTSA ||
						             this.entityName === EntityName.PIPELINE_LTSA ||
						             this.entityName === EntityName.UNIT;
        this.acContactsSlotted = cmd.acContactsSlotted;
        this.entityContactInfoDisplays = cmd.entityContactInfoDisplays ? cmd.entityContactInfoDisplays : [];
        this.readOnly = cmd.readOnly;

        this.init();
        this.watchChangeForm(this.entityContactFormGroup, [], this.contactDetailsChangedFieldsReMap);

        this.fieldsChangeSub = this.fieldsChange$.subscribe(property => {
            this.fieldChangeEntityContact = property;
            this.onContactQcDateListener();
        });

        this.onCloseDefaultFunction = this.activeModal.close;
        this.activeModal.close = this.interceptDialogClose.bind(this);
    }

    ngAfterViewInit() {
        BaseModalComponent.setTabActionToCloseButton(this.baseIndex);
        this.setTabActionToTabElements();
        if (this.calendar && this.calendar.nativeElement) {
            const calendarButton = this.calendar.nativeElement.querySelector('.p-datepicker-trigger');
            if (calendarButton) {
                calendarButton.setAttribute('tabindex', '-1');
            }    
        }
    }

    ngOnDestroy(): void {
        if(this.readOnly) {
            return;
        }
        this.disableFormSub.unsubscribe();
    }

    init() {
        this.loadJobFunctions();
        this.buildForm();
        this.sharedService.getCountryExceptions().subscribe(data => this.countryExceptions = data);
        if (this.contactGeneric !== undefined) {
            this.contactGeneric.initICopy(this.serverDate.now());
            this.setValueControls();
            setTimeout(() => { this.initOthersComponents(); }, 200);
            this.disabledViewContactAssociations = this.contactGeneric.contactLastUser === null;
        } else {
            this.contactData.setValueControlsNew(this.newContactId);
            this.entityContactFormGroup.controls.companyName.setValue(this.entityName)
            this.entityContactFormGroup.controls.companyId.setValue(this.entityId)
            this.entityContactFormGroup.controls.companyStatus.setValue(this.entityStatus);
        }
        this.showRelatedOnSave = false;
        this.maxContactQcDate = this.serverDate.now();
    }

    initOthersComponents() {
        this.initContactAddress();
        this.initContactFormComponent();
    }

    initContactAddress() {
        this.contactAddress.setValueControls(Address.BuildContactAddress(this.contactGeneric.iCopy));

        const operationInPoolAdd = this.contactGeneric.iCopy.operationInPool === ContactOperation.ADD;
        const operationInPoolAddAndView = this.contactGeneric.iCopy.operationInPool === ContactOperation.ADD_AND_VIEW;
        const operationInPoolChanged = this.contactGeneric.iCopy.operationInPool === ContactOperation.CHANGED_IN_POOL;
        const contactDisplayIsNew = this.contactGeneric.iCopy.contactDisplay.getOperation() === OperationType.TEMPORAL;

        if (!operationInPoolAdd && !operationInPoolAddAndView) {
            if (operationInPoolChanged) {
                this.setContactAddressForm(true, false, false);
                this.contactGeneric.operationInPool = ContactOperation.NONE;
            } else if (this.contactGeneric.iCopy.contactOnsiteBool) {
                this.setContactAddressForm(false, false, false);
            } else if (contactDisplayIsNew) {
                this.setContactAddressForm(true, true, false);
                this.setDefaultColor('red');
            }
        }

        if(this.readOnly) {
            this.contactAddress.formGroupAddress.disable();
        }
    }

    initContactFormComponent() {
        this.contactData.createFromForm();
        if (this.newContactId == undefined) {
            this.contactData.setValueControls();
        } else {
            this.contactData.setValueControlsNew(this.newContactId);
        }
        this.fieldChangeEntityContact = [];

        if(this.readOnly) {
            this.contactData.contactFormGroup.disable();
        }
    }

    setTabActionToTabElements() {
        const tabElements = document.querySelectorAll('.p-tabview-nav-link');
        if (tabElements) {
            tabElements.forEach((element) => {
                (element as HTMLElement).addEventListener('keydown', (event) => this.focusCloseButton(event, true));
            });
        }
    }

    focusCloseButton(event: Event, reverse: boolean = false): void {
        BaseModalComponent.focusCloseButton(document, event, reverse);
    }

    onContactQcDateListener() {
        if (this.fieldChangeEntityContact?.hasOwnProperty('contactQcDate')) {
            const currentUsername = this.session.getUserName();
            const currentContactQcDate = this.entityContactFormGroup.get(['contactQcDate'])!.value;
            const currentContactQcDateTime = (currentContactQcDate != null) ? currentContactQcDate.getTime() : 0;
            const originalContactQcDateTime = (this.originalContactQcDate != null) ? this.originalContactQcDate.getTime() : 0;

            if (currentContactQcDateTime != originalContactQcDateTime) {
                this.setContactQcDate();
                this.originalContactQcDate = new Date(currentContactQcDate);

                this.contactGeneric.iCopy.contactQcUser = currentUsername;
                this.forceFieldChange('contactQcUser');
            }
        }
    }

    private loadJobFunctions() {
        this.sharedService.getJobFunctions().subscribe((jobs: JobFunction[]) => this.jobFunctions = [...jobs]);
    }

    buildForm() {
        this.entityContactFormGroup = this.fb.group({
            companyId: ['', Validators.required],
            companyName: { value: '', disabled: true },
            companyStatus: { value: '', disabled: true },
            contactStatus: [null, Validators.required],
            contactOnsiteBool: [false],
            contactSaleFlagBool: [false],
            contactFunction: [null],
            contactTitle: ['', Validators.required],
            contactTitleDesc: [''],
            titleDesc: { value: '', disabled: true },
            contactPhoneCc: [''],
            contactPhoneNo: [''],
            contactPhoneExt: [''],
            contactPhoneMobileSel: [false],
            contactAltPhoneCc: [''],
            contactAltPhoneNo: [''],
            contactAltPhoneExt: [''],
            contactAltPhoneMobileSel: [false],
            contactFaxCc: [''],
            contactFaxNo: [''],
            contactFaxExt: [''],
            contactQcDate: new FormControl(this.contactQcDate || '', Validators.required),
            mailAddressVerifiedBool: [false]
        });

        if(this.readOnly) {
            this.entityContactFormGroup.disable();
            this.disabledSaveRecordButton = true;
            this.existingAddressButtonDisabled = true;
        } else {
            this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
                this.disabledSaveRecordButton = disable;
                if (disable) {
                    this.entityContactFormGroup.disable();
                } else {
                    this.entityContactFormGroup.enable();
                    this.entityContactFormGroup.controls.companyName.disable();
                    this.entityContactFormGroup.controls.titleDesc.disable();
                }
            });
        }
    }

    setValueControls() {
        this.entityContactFormGroup.controls.companyId.setValue(this.contactGeneric.iCopy.companyId);
        this.entityContactFormGroup.controls.companyName.setValue(this.contactGeneric.iCopy.companyName);
        this.entityContactFormGroup.controls.companyStatus.setValue(this.contactGeneric.iCopy.companyStatus);
        if(this.entityContactFormGroup.controls.companyId.value && this.entityContactFormGroup.controls.companyStatus.value == null){
          this.searchCompanyById(this.entityContactFormGroup.controls.companyId.value);
        }
        this.entityContactFormGroup.controls.contactStatus.setValue(this.contactGeneric.iCopy.contactStatus);
        this.entityContactFormGroup.controls.contactOnsiteBool.setValue(this.contactGeneric.iCopy.contactOnsiteBool);
        this.entityContactFormGroup.controls.contactSaleFlagBool.setValue(this.contactGeneric.iCopy.contactSaleFlagBool);
        this.entityContactFormGroup.controls.contactFunction.setValue(this.contactGeneric.iCopy.contactFunction !== null ? Number(this.contactGeneric.iCopy.contactFunction): null);
        this.entityContactFormGroup.controls.contactTitle.setValue(this.contactGeneric.iCopy.contactTitle);
        this.entityContactFormGroup.controls.titleDesc.setValue(this.contactGeneric.iCopy.titleDesc);
        this.entityContactFormGroup.controls.contactTitleDesc.setValue(this.contactGeneric.iCopy.contactTitleDesc);

        this.entityContactFormGroup.controls.contactPhoneCc.setValue(this.contactGeneric.iCopy.contactPhoneCc);
        this.entityContactFormGroup.controls.contactPhoneNo.setValue(this.contactGeneric.iCopy.contactPhoneNo);
        this.entityContactFormGroup.controls.contactPhoneExt.setValue(this.contactGeneric.iCopy.contactPhoneExt);
        this.entityContactFormGroup.controls.contactPhoneMobileSel.setValue(this.contactGeneric.iCopy.contactPhoneMobileSel);
        this.entityContactFormGroup.controls.contactAltPhoneCc.setValue(this.contactGeneric.iCopy.contactAltPhoneCc);
        this.entityContactFormGroup.controls.contactAltPhoneNo.setValue(this.contactGeneric.iCopy.contactAltPhoneNo);
        this.entityContactFormGroup.controls.contactAltPhoneExt.setValue(this.contactGeneric.iCopy.contactAltPhoneExt);
        this.entityContactFormGroup.controls.contactAltPhoneMobileSel.setValue(this.contactGeneric.iCopy.contactAltPhoneMobileSel);
        this.entityContactFormGroup.controls.contactFaxCc.setValue(this.contactGeneric.iCopy.contactFaxCc);
        this.entityContactFormGroup.controls.contactFaxNo.setValue(this.contactGeneric.iCopy.contactFaxNo);
        this.entityContactFormGroup.controls.contactFaxExt.setValue(this.contactGeneric.iCopy.contactFaxExt);
        this.entityContactFormGroup.controls.mailAddressVerifiedBool.setValue(this.contactGeneric.iCopy.mailAddressVerifiedBool);
        this.existingAddressButtonDisabled = this.disabledSaveRecordButton ? true : this.contactGeneric.iCopy.contactOnsiteBool;

        this.contactQcDate = null;
        this.originalContactQcDate = null;
        if (this.contactGeneric.iCopy.contactQcDate) {
            this.contactQcDate = new Date(Number(this.contactGeneric.iCopy.contactQcDate));
            this.originalContactQcDate = new Date(this.contactQcDate);
        }

        this.entityContactFormGroup.controls.contactQcDate.setValue(this.contactQcDate);
    }


    private createFromForm(): void {
        this.contactGeneric.iCopy.companyId = this.entityContactFormGroup.get(['companyId'])!.value;
        this.contactGeneric.iCopy.companyName = this.entityContactFormGroup.get(['companyName'])!.value;
        this.contactGeneric.iCopy.companyStatus = this.entityContactFormGroup.get(['companyStatus'])!.value;
        this.contactGeneric.iCopy.contactStatus = this.entityContactFormGroup.get(['contactStatus'])!.value;
        this.contactGeneric.iCopy.contactOnsiteBool = this.entityContactFormGroup.get(['contactOnsiteBool'])!.value;
        this.contactGeneric.iCopy.contactSaleFlagBool = this.entityContactFormGroup.get(['contactSaleFlagBool'])!.value;
        this.contactGeneric.iCopy.contactFunction = this.entityContactFormGroup.get(['contactFunction'])!.value;
        this.contactGeneric.iCopy.contactTitle = this.entityContactFormGroup.get(['contactTitle'])!.value;
        this.contactGeneric.iCopy.contactTitleDesc = this.entityContactFormGroup.get(['contactTitleDesc'])!.value;
        this.contactGeneric.iCopy.titleDesc = this.entityContactFormGroup.get(['titleDesc'])!.value;

        this.contactGeneric.iCopy.contactPhoneCc = this.entityContactFormGroup.get(['contactPhoneCc'])!.value;
        this.contactGeneric.iCopy.contactPhoneNo = this.entityContactFormGroup.get(['contactPhoneNo'])!.value;
        this.contactGeneric.iCopy.contactPhoneExt = this.entityContactFormGroup.get(['contactPhoneExt'])!.value;
        this.contactGeneric.iCopy.contactPhoneMobileSel = this.entityContactFormGroup.get(['contactPhoneMobileSel'])!.value;
        this.contactGeneric.iCopy.contactAltPhoneCc = this.entityContactFormGroup.get(['contactAltPhoneCc'])!.value;
        this.contactGeneric.iCopy.contactAltPhoneNo = this.entityContactFormGroup.get(['contactAltPhoneNo'])!.value;
        this.contactGeneric.iCopy.contactAltPhoneExt = this.entityContactFormGroup.get(['contactAltPhoneExt'])!.value;
        this.contactGeneric.iCopy.contactAltPhoneMobileSel = this.entityContactFormGroup.get(['contactAltPhoneMobileSel'])!.value;
        this.contactGeneric.iCopy.contactFaxCc = this.entityContactFormGroup.get(['contactFaxCc'])!.value;
        this.contactGeneric.iCopy.contactFaxNo = this.entityContactFormGroup.get(['contactFaxNo'])!.value;
        this.contactGeneric.iCopy.contactFaxExt = this.entityContactFormGroup.get(['contactFaxExt'])!.value;
        this.contactGeneric.iCopy.mailAddressVerifiedBool = this.entityContactFormGroup.get(['mailAddressVerifiedBool'])!.value;

        this.contactGeneric.iCopy.setContactAddress(this.contactAddress.createFromForm());
        this.contactGeneric.iCopy.changedFields = Object.assign({}, this.contactGeneric.iCopy.changedFields,
            this.fieldChangeEntityContact,
            this.contactAddress.fieldChangeAddress);
    }

    setContactQcDate() {
        this.contactQcDate = this.entityContactFormGroup.get(['contactQcDate'])!.value;

        if (this.contactQcDate) {
            const now = this.serverDate.now();
            const qcDate = new Date(this.contactQcDate);
            qcDate.setHours(now.getHours());
            qcDate.setMinutes(now.getMinutes());
            qcDate.setSeconds(now.getSeconds());
            this.contactGeneric.iCopy.contactQcDate = String(qcDate.getTime());
        }
    }

    createContactCommentInstance(entity: any): INote {
        const newContactComment = ContactsCommentsDisplay.CreateInstance(entity);
        newContactComment.contactId = entity.entityId;
        return newContactComment;
    }

    contactCommentsChangeListener(contactComments: Array<INote>) {
        this.contactGeneric.iCopy.contactDisplay.contactsCommentsDisplay = <Array<ContactsCommentsDisplay>>contactComments;
    }

    display() {
        this.check == !this.check;
    }

    showDialog() {
        this.displayBoolean == !this.displayBoolean;
    }

    displayLink(str: string) {
        if (str !== '' && str !== null) {
            return true;
        } else {
            return false;
        }
    }

    openUserModal(userModal: string) {
        const modalRef = this.dialogService.open(UserModalComponent, {
            header: `User Details for ${userModal}`,
            width: '30rem',
            data: {
                user: userModal,
                entityName: this.entityName,
                entityStatus: this.entityStatus,
                entityId: this.entityId
            },
            draggable: true,
            keepInViewport: true
        });
    }

    openContactRelatedEntities(type: ContactRelatedEntities) {
        if (this.contactDetailsSectionService.isContactOpen(this.contactGeneric.contactId)) {
            this.messageHandlerService.show(Constants.MESSAGE_CONTAC_ALREADY_OPEN, MessageType.WARNING);
            return;
        }

        this.acAllContactsProvider = [];
        const searchContactId = this.contactGeneric.contactDisplay.contactId;
        const selfMvId = this.contactGeneric.mvId;
        const mode = 'Associations';
        let onSite = false;
        if (type === ContactRelatedEntities.CONTACT_ASSOCIATIONS) {
            onSite = true;
        }

        this.contactDetailsSectionService.getSearchAllContacts(selfMvId, '', '', '', '', searchContactId, 0, mode, false, onSite).subscribe(resp => {
            if (resp) {
                this.acAllContactsProvider = resp;
                const messageNotExists = Constants.MESSAGE_ON_SITE_MAILING_ADDRESSES_NOT_EXISTS;

                if (this.acAllContactsProvider.length > 0) {
                    this.showContactRelatedEntities(type);
                } else {
                    this.messageHandlerService.show(messageNotExists, MessageType.INFO);
                }
            }
        });
    }

    private showContactRelatedEntities(type: ContactRelatedEntities) {
        const showContactAssociations = (type === ContactRelatedEntities.CONTACT_ASSOCIATIONS);
        const hTitle = showContactAssociations ? 'Contact Associations' : 'Contact Mailing Addresses';
        const data = {
            type: type,
            mainEntityId: this.entityId,
            mainEntityName: this.entityName,
            mainEntityStatus: this.entityStatus,
            contactId: this.contactGeneric.contactDisplay.contactId,
            contactName: `${this.contactGeneric.firstName} ${this.contactGeneric.lastName}`,
            contactMvOrder: this.contactGeneric.mvOrder,
            contactOptedOut: false,
            currentState: '',
            coverageApplies: false,
            onSite: showContactAssociations,
            selfMvId: this.contactGeneric.mvId,
            contactProvider: this.acAllContactsProvider
        }

        const modalRef = this.dialogService.open(ContactRelatedComponent, {
            header: hTitle,
            width: '90rem',
            data: data,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: ContactRelatedResponse) => {
            if (response) {
                if (response.type === ContactRelatedEntities.CONTACT_MAILING_ADDRESS) {
                    this.contactAddress.setControlsAsDirty();
                    this.contactAddress.setValueControls(response.address);
                }
            }
        });
    }

    openContactAssociationsModal() {
        this.openContactRelatedEntities(ContactRelatedEntities.CONTACT_ASSOCIATIONS);
    }

    openContactMailingAddresses() {
        this.openContactRelatedEntities(ContactRelatedEntities.CONTACT_MAILING_ADDRESS);
    }

    openTitleQuerySearch() {
        const modalRef = this.dialogService.open(TitleQuerySearchComponent, {
            header: 'Search Title Query',
            width: '40rem',
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: any) => {
            if (response) {
                this.entityContactFormGroup.controls.contactTitle.setValue(response.TITLE_ID);
                this.entityContactFormGroup.controls.titleDesc.setValue(response.TITLE_DESC);
                this.entityContactFormGroup.controls.contactTitleDesc.setValue(response.TITLE_DESC);
                this.inputTitleQuery.nativeElement.focus();
            }
        });
    }

    titleEnter() {
        const contactTitle = this.entityContactFormGroup.get(['contactTitle'])!.value;
        if (contactTitle === '') {
            this.entityContactFormGroup.controls.titleDesc.setValue('');
            this.openTitleQuerySearch();
        } else if (contactTitle !== this.contactGeneric.iCopy.contactTitle) {
            this.searchTitle();
        }
    }

    titleChange() {
        const contactTitle = this.entityContactFormGroup.get(['contactTitle'])!.value;
        if (contactTitle === '') {
            this.entityContactFormGroup.controls.titleDesc.setValue('');
        } else if (contactTitle !== '' && contactTitle !== this.contactGeneric.iCopy.contactTitle) {
            this.searchTitle();
        }
    }

    private searchTitle() {
        const contactTitle = this.entityContactFormGroup.get(['contactTitle'])!.value;
        if (contactTitle === this.contactGeneric.iCopy.contactTitle) {
            return;
        }

        this.sharedService.getTitles(contactTitle, null)
            .subscribe((titles: Array<any>) => {
                if (titles.length == 1) {
                    this.entityContactFormGroup.controls.contactTitle.setValue(titles[0].TITLE_ID);
                    this.entityContactFormGroup.controls.titleDesc.setValue(titles[0].TITLE_DESC);
                    this.entityContactFormGroup.controls.contactTitleDesc.setValue(titles[0].TITLE_DESC);
                    this.forceFieldChange('contactTitle');
                    this.forceFieldChange('contactTitleDesc');
                }
                else {
                    const cleanTitles = (resp: MessageResponse) => {
                        this.inputTitleQuery.nativeElement.focus();
                    }
                    this.entityContactFormGroup.controls.contactTitle.setValue('');
                    this.entityContactFormGroup.controls.titleDesc.setValue('');
                    this.entityContactFormGroup.controls.contactTitleDesc.setValue('');
                    this.messageHandlerService.show(Constants.replace(Constants.MESSAGE_ENTITY_NOT_EXISTS, ['Title Query']), MessageType.INFO, cleanTitles);
                }
            });
    }

    openCountrySearchModal() {
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: 'Country Search',
            width: '40rem',
            draggable: true,
            keepInViewport: true
        });
    }

    openCitySearchModal() {
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: 'City Search',
            width: '40rem',
            draggable: true,
            keepInViewport: true
        });
    }

    openStateSearchModal() {
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: 'State Search',
            width: '40rem',
            draggable: true,
            keepInViewport: true
        });
    }

    validateSave(): boolean {
        this.contactData.createFromForm();
        this.createFromForm();

        let alertTip = '';
        const countryExists = (p: any) => p.COUNTRY_ID === this.contactGeneric.iCopy.mailCountry && p.CONTACT_NAME == '1';
        const requiredCountryName = this.countryExceptions.filter(countryExists).length > 0;

        const iCopy = this.contactGeneric.iCopy;

        if (requiredCountryName) {
            if ((iCopy.firstName == null || iCopy.firstName == '') && (iCopy.lastName === null || iCopy.lastName == '')) {
                alertTip += Constants.replace(Constants.ERROR_FIELD_IS_REQUIRED, ['First Name Or Last Name']);
            }
        } else {
            if (iCopy.firstName == null || iCopy.firstName == '' || iCopy.lastName === null || iCopy.lastName == '') {
                alertTip += Constants.CONTACT_NAME_REQUIRED;
            }
        }

        if (iCopy.companyId == null || String(iCopy.companyId) == '') {
            alertTip += Constants.replace(Constants.ERROR_FIELD_IS_REQUIRED, ['Company ID']);
        }

        alertTip += this.validateTitleQuery();

        if (iCopy.contactDisplay.salutation == null || iCopy.contactDisplay.salutation == '') {
            alertTip += Constants.replace(Constants.ERROR_FIELD_IS_REQUIRED, ['Salutation']);
        }

        if (iCopy.contactDisplay.emailAddress !== null
            && iCopy.contactDisplay.emailAddress !== ''
            && !Constants.REGEXP_EMAIL_VALIDATION_RFC2822.test(iCopy.contactDisplay.emailAddress)) {
            alertTip += Constants.MESSAGE_EMAIL_INVALID;
        }

        if (iCopy.contactStatus == null || iCopy.contactStatus == '') {
            alertTip += this.translate.instant('contact.validations.requiredStatus') + '<br>';
        }

        if (this.entityContactFormGroup.controls.contactOnsiteBool!.value ||
            !this.isNullOrEmpty(iCopy.mailCountry)) {
            if (this.isNullOrEmpty(iCopy.mailCountry)) {
                alertTip += this.translate.instant('contact.validations.requiredMailCountry') + '<br>';
            }

            if (this.isNullOrEmpty(iCopy.mailPostalCode)) {
                alertTip += this.translate.instant('contact.validations.requiredMailPostal') + '<br>';
            }

            if (this.isNullOrEmpty(iCopy.mailAddressLine1)) {
                alertTip += this.translate.instant('contact.validations.requiredMailAddress') + '<br>';
            }

            if (this.isNullOrEmpty(iCopy.mailState)) {
                alertTip += this.translate.instant('contact.validations.requiredMailState') + '<br>';
            }

            if (this.isNullOrEmpty(iCopy.mailCity)) {
                alertTip += this.translate.instant('contact.validations.requiredMailCity') + '<br>';
            }

        }

        if (iCopy.contactPhoneMobileSel) {
            if (iCopy.contactPhoneCc == null || String(iCopy.contactPhoneCc) == '') {
                alertTip += this.translate.instant('contact.validations.requiredPhoneCountry') + '<br>';
            }

            if (iCopy.contactPhoneNo == null || iCopy.contactPhoneNo == '') {
                alertTip += this.translate.instant('contact.validations.requiredPhoneNumber') + '<br>';
            }
        }

        if (iCopy.contactAltPhoneMobileSel) {
            if (iCopy.contactAltPhoneCc == null || String(iCopy.contactAltPhoneCc) == '') {
                alertTip += this.translate.instant('contact.validations.requiredAltPhoneCountry') + '<br>';
            }

            if (iCopy.contactAltPhoneNo == null || iCopy.contactAltPhoneNo == '') {
                alertTip += this.translate.instant('contact.validations.requiredAltPhoneNumber') + '<br>';
            }
        }

        let result = FormContactDetailsComponent.checkLinkedInId(iCopy.contactDisplay.linkedInId);
        if (result)
        {
            if (alertTip.length > 0) {
               alertTip += '\n';
            }
            alertTip += result;
        }

        if (alertTip.length > 0) {
            this.messageHandlerService.show(alertTip);
        } else {
            if (this.contactsIdByEmail.length > 0) {
                for (let i = 0; i < this.contactsIdByEmail.length; i++) {
                    const item = this.contactsIdByEmail[i];
                    if (iCopy.contactDisplay.contactId != item.CONTACT_ID) {
                        if (alertTip.length <= 0) {
                            alertTip += this.translate.instant('contact.common.contactEmailValidation');
                        }
                        alertTip += '<br>' + item.CONTACT_ID;
                    }
                }
                if (alertTip.length > 0) {
                    this.messageHandlerService.show(alertTip);
                }
            }
        }

        return alertTip.length === 0;
    }

    validateTitleQuery(): string {
        let titleQueryValMessage = '';
        const iCopy = this.contactGeneric.iCopy;

        if (iCopy.contactTitle == null || iCopy.contactTitle == '') {
            titleQueryValMessage += Constants.replace(Constants.ERROR_FIELD_IS_REQUIRED, ['Title ID']);
        }

        if (this.entityName === EntityName.PLANT && this.contactGeneric.functionalSlot == 1) {
            const contactsWithSameTitle = this.acContactsSlotted.filter((contact: IContact) => contact.contactTitle == iCopy.contactTitle && contact.mvOrder != iCopy.mvOrder);
            if (contactsWithSameTitle.length > 0) {
                titleQueryValMessage += this.translate.instant('contact.contactGrid.contactTitleAlreadyExists') + '<br>';
            }
        }

        return titleQueryValMessage;
    }

    saveContact() {
        if (this.contactGeneric.iCopy.contactDisplay.getOperation() == OperationType.TEMPORAL) {
            this.contactGeneric.iCopy.contactDisplay.setOperation(OperationType.INSERT);
        } else if (this.contactGeneric.iCopy.contactDisplay.getOperation() === OperationType.IDLE) {
            if(Object.keys(this.contactData.fieldChangeContactSection).length > 0) {
                this.contactGeneric.iCopy.contactDisplay.setOperation(OperationType.UPDATE);
            }
        }

        this.contactGeneric.iMerge(true, false);
    }

    saveEntityContact() {
        if (this.contactGeneric.iCopy.getOperation() === OperationType.TEMPORAL) {
            this.contactGeneric.iCopy.setOperation(OperationType.INSERT);

            if(!this.contactGeneric.iCopy.contactQcDate) {
                this.contactGeneric.iCopy.contactQcDate = String(this.serverDate.now().getTime());
            }
        } else if (this.contactGeneric.iCopy.getOperation() === OperationType.IDLE) {
            this.contactGeneric.iCopy.setOperation(OperationType.UPDATE);
        }

        this.contactGeneric.iMerge(false, true);
        if(Object.keys(this.contactData.fieldChangeContactSection).length > 0) {
            this.contactGeneric.changedFields['operation'] = 1;
        }
    }

    validateAndSave() {
        if (this.validateSave()) {
            this.noteService.deleteEmptyComments(this.contactGeneric.iCopy.contactDisplay.contactsCommentsDisplay);

            if(!this.contactDisplayLocked) {
                this.saveContact();
            }

            this.saveEntityContact();

            this.setContactEntityId(String(this.contactGeneric.contactDisplay.contactId));

            this.openRelatedModal();

            this.headerService.changeForm = this.initialChangeStatus || this.contactWasEdited();
            this.onCloseDefaultFunction.apply(this.activeModal, [true]);
        }
    }

    setContactEntityId(contactId: string) {
        this.recordLockingFlow.setEntityIdToCellItem(contactId, this.entityName, String(this.entityId));
    }

    saveRecordButton() {
        this.saveRecord();
    }

    close() {
        this.onCloseDefaultFunction.apply(this.activeModal, [false]);
    }

    saveRecord() {
        let emailAddress = this.contactData.contactFormGroup.get(['emailAddress'])!.value;
        if(emailAddress){
            emailAddress = emailAddress.trim();
            this.contactData.contactFormGroup.get(['emailAddress'])!.setValue(emailAddress);
        }
        if (emailAddress !== null && emailAddress.length > 0 && emailAddress !== this.contactGeneric.contactDisplay.emailAddress) {
            let contactId = this.contactData.contactFormGroup.get(['contactId'])!.value;
            this.contactsIdByEmail = this.entityContactInfoDisplays.filter((contact, index, self)=>
                                                    contact.contactId !== contactId &&
                                                    contact.contactDisplay.emailAddress &&
                                                    contact.contactDisplay.emailAddress.trim() === emailAddress &&
                                                    index === self.findIndex((cData) => (
                                                        cData.contactId === contact.contactId
                                                    ))
                                            ).map(contact => { return {CONTACT_ID: contact.contactId}});
            if(this.contactsIdByEmail.length === 0){
                this.contactService.getContactByEmail(emailAddress).subscribe((data: any) => {
                    this.contactsIdByEmail = data;
                    this.validateAndSave();
                });
            } else {
                this.validateAndSave();
            }
        } else {
            this.validateAndSave();
        }
    }

    private contactWasEdited(): boolean {
        const changedFields = Object.assign({}, this.fieldChangeEntityContact,
            this.contactData.fieldChangeContactSection,
            this.contactAddress.fieldChangeAddress);
        const sameCommentsList = this.contactGeneric.iCopy.contactDisplay.hasSameComments(this.contactGeneric.contactDisplay);
        const commentsAddedOrUpdated = (p: ContactsCommentsDisplay) => p.getOperation() === OperationType.INSERT || p.getOperation() === OperationType.UPDATE;
        const contactsCommentsEdited = this.contactGeneric.iCopy.contactDisplay?.contactsCommentsDisplay.filter(commentsAddedOrUpdated).length > 0;
        return Object.getOwnPropertyNames(changedFields).length > 0 || (!sameCommentsList && contactsCommentsEdited);

    }

    onAddressVerifiedClicked() {
        const mailAddressVerifiedChecked = this.entityContactFormGroup.controls.mailAddressVerifiedBool!.value;
        if (mailAddressVerifiedChecked) {
            this.contactGeneric.iCopy.mailAddressVerifiedDate = null;
        }
    }

    onClickSiteVerified() {
        this.setContactAddressForm(true, false, true);
        this.contactAddress.updateRequiredFieldStatus();
    }

    setContactAddressForm(forceContactMapOverload: boolean, isNew: boolean, siteVerifiedClicked: boolean) {
        if (!this.disabledSaveRecordButton) {
            if (isNew) {
                this.entityContactFormGroup.get(['contactOnsiteBool'])!.setValue(true);
                this.entityContactFormGroup.get(['contactSaleFlagBool'])!.setValue(true);
            }
            const onSiteChecked = this.entityContactFormGroup.get(['contactOnsiteBool'])!.value;

            if (forceContactMapOverload) {
                this.contactAddress.formGroupAddress.controls.address1.setValue(onSiteChecked ? this.contactMap.addressV1 : '');
                this.contactAddress.formGroupAddress.controls.address2.setValue(onSiteChecked ? this.contactMap.addressV2 : '');
                this.contactAddress.formGroupAddress.controls.cityName.setValue(onSiteChecked ? this.contactMap.city : '');
                this.contactAddress.formGroupAddress.controls.ciId.setValue(onSiteChecked ? this.contactMap.cityId : '');
                this.contactAddress.formGroupAddress.controls.cId.setValue(onSiteChecked ? this.contactMap.countryId : '');
                this.contactAddress.formGroupAddress.controls.countryId.setValue(onSiteChecked ? this.contactMap.country : '');
                this.contactAddress.formGroupAddress.controls.countryName.setValue(onSiteChecked ? this.contactMap.countryName : '');
                this.contactAddress.formGroupAddress.controls.sId.setValue(onSiteChecked ? this.contactMap.stateId : '');
                this.contactAddress.formGroupAddress.controls.stateAbbrev.setValue(onSiteChecked ? this.contactMap.state : '');
                this.contactAddress.formGroupAddress.controls.stateName.setValue(onSiteChecked ? this.contactMap.stateName : '');
                this.contactAddress.formGroupAddress.controls.zipCode.setValue(onSiteChecked ? this.contactMap.zipCode : '');

                if (isNew) {
                    this.entityContactFormGroup.controls.contactPhoneCc.setValue(onSiteChecked ? this.contactMap.phoneCc : '');
                    this.entityContactFormGroup.controls.contactPhoneNo.setValue(onSiteChecked ? this.contactMap.phoneNo : '');
                    this.entityContactFormGroup.controls.contactPhoneExt.setValue(onSiteChecked ? this.contactMap.phoneExt : '');
                    this.entityContactFormGroup.controls.contactPhoneMobileSel.setValue(onSiteChecked ? this.contactMap.phoneMobile : false);
                    this.entityContactFormGroup.controls.contactFaxCc.setValue(onSiteChecked ? this.contactMap.faxCc : '');
                    this.entityContactFormGroup.controls.contactFaxNo.setValue(onSiteChecked ? this.contactMap.faxNo : '');
                    this.entityContactFormGroup.controls.contactFaxExt.setValue(onSiteChecked ? this.contactMap.faxExt : '');
                }
            } else {
                const address1 = this.contactAddress.formGroupAddress.get(['address1'])!.value;
                if (address1 === null || address1 === '') {
                    this.contactAddress.formGroupAddress.controls.address1.setValue(this.contactMap.addressV1);
                    this.contactAddress.setFontColor('address1', 'red');
                }

                const address2 = this.contactAddress.formGroupAddress.get(['address2'])!.value;
                if (address2 === null || address2 === '') {
                    this.contactAddress.formGroupAddress.controls.address2.setValue(this.contactMap.addressV2);
                    this.contactAddress.setFontColor('address2', 'red');
                }

                const city = this.contactAddress.formGroupAddress.get(['cityName'])!.value;
                if (city === null || city === '') {
                    this.contactAddress.formGroupAddress.controls.cityName.setValue(this.contactMap.city);
                    this.contactAddress.formGroupAddress.controls.ciId.setValue(this.contactMap.cityId);
                    this.contactAddress.setFontColor('cityName', 'red');
                }

                const country = this.contactAddress.formGroupAddress.get(['countryId'])!.value;
                if (country === null || country === '') {
                    this.contactAddress.formGroupAddress.controls.countryId.setValue(this.contactMap.country);
                    this.contactAddress.formGroupAddress.controls.countryName.setValue(this.contactMap.countryName);
                    this.contactAddress.formGroupAddress.controls.cId.setValue(this.contactMap.countryId);
                    this.contactAddress.setFontColor('countryId', 'red');
                    this.contactAddress.setFontColor('countryName', 'red');
                }

                const state = this.contactAddress.formGroupAddress.get(['stateAbbrev'])!.value;
                if (state === null || state === '') {
                    this.contactAddress.formGroupAddress.controls.stateAbbrev.setValue(this.contactMap.state);
                    this.contactAddress.formGroupAddress.controls.stateName.setValue(this.contactMap.stateName);
                    this.contactAddress.formGroupAddress.controls.sId.setValue(this.contactMap.stateId);
                    this.contactAddress.setFontColor('stateAbbrev', 'red');
                    this.contactAddress.setFontColor('stateName', 'red');
                }

                const zipCode = this.contactAddress.formGroupAddress.get(['zipCode'])!.value;
                if (zipCode === null || zipCode === '') {
                    this.contactAddress.formGroupAddress.controls.zipCode.setValue(this.contactMap.zipCode);
                    this.contactAddress.setFontColor('zipCode', 'red');
                }
            }

            this.existingAddressButtonDisabled = false;
            if (onSiteChecked) {
                this.existingAddressButtonDisabled = true;
            }

            if (siteVerifiedClicked) {
                this.contactAddress.refreshFieldsByChanges();
            }

            this.contactAddress.address = this.contactAddress.createFromForm();
            this.contactAddress.copyCountryId = this.contactAddress.address.countryId;
        }
    }

    setDefaultColor(color: string) {
        this.contactAddress.setFontColor('address1', color);
        this.contactAddress.setFontColor('address2', color);
        this.contactAddress.setFontColor('cityName', color);
        this.contactAddress.setFontColor('countryId', color);
        this.contactAddress.setFontColor('countryName', color);
        this.contactAddress.setFontColor('stateAbbrev', color);
        this.contactAddress.setFontColor('stateName', color);
        this.contactAddress.setFontColor('zipCode', color);

        this.colors['contactPhoneCc'] = color;
        this.colors['contactPhoneNo'] = color;
        this.colors['contactPhoneExt'] = color;
        this.colors['contactAltPhoneCc'] = color;
        this.colors['contactAltPhoneNo'] = color;
        this.colors['contactAltPhoneExt'] = color;
        this.colors['contactFaxCc'] = color;
        this.colors['contactFaxNo'] = color;
        this.colors['contactFaxExt'] = color;
    }

    onChangeStatus(event: any): void {
        this.showRelatedOnSave = true;
    }

    openRelatedModal(): void {
        if (this.showRelatedOnSave) {
            let contacts = [];
            this.contactDetailsSectionService.getSearchAllContacts(this.contactGeneric.mvId, '', '', '', '',
                this.contactGeneric.contactId, 0, 'Associations', false, true).subscribe(resp => {
                    if (resp) {
                        contacts = resp;
                        if (contacts.length > 0) {
                            this.showContactRelatedEntitiesOnSave(this.contactGeneric, contacts);
                        }
                    }
                });
        }
    }

    private showContactRelatedEntitiesOnSave(contactInfo: IContact, contacts: Array<any>): void {
        const data = {
            type: ContactRelatedEntities.CONTACT_ASSOCIATIONS,
            mainEntityId: this.entityId,
            mainEntityName: this.entityName,
            contactId: contactInfo.contactId,
            contactName: `${contactInfo.firstName} ${contactInfo.lastName}`,
            contactMvOrder: contactInfo.mvOrder,
            contactOptedOut: false,
            currentState: '',
            coverageApplies: false,
            onSite: true,
            selfMvId: contactInfo.mvId,
            contactProvider: contacts,
            statusChange: true
        }
        const modalRef = this.dialogService.open(ContactRelatedComponent, {
            header: 'Contact Associations - ' + contactInfo.contactId,
            width: '90rem',
            data: data,
            draggable: true,
            keepInViewport: true
        });

    }

    processCompanyResult(result: any) {
        return { companyId: result.companyId, companyName: result.companyName };
    }

    openCompanySearchModal() {
        const modalRef = this.dialogService.open(SearchModalComponent, {
            header: 'Company Search',
            width: '90rem',
            data: { contactsDetails: true },
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((companyData: CompanySearch) => {
            if (companyData) {
                this.entityContactFormGroup.controls.companyId.setValue(companyData.companyId);
                this.entityContactFormGroup.controls.companyName.setValue(companyData.companyName);
                this.entityContactFormGroup.controls.companyStatus.setValue(companyData.companyStatus);
                this.forceFieldChange('companyId');
            }

        });
    }

    companyIdOnTab(event) {
        this.searchCompanyById(this.entityContactFormGroup.controls.companyId.value, event.key);
    }

    companyIdOnEnter() {
        this.searchCompanyById(this.entityContactFormGroup.controls.companyId.value);
    }

    companyIdOnBlur() {
        this.searchCompanyById(this.entityContactFormGroup.controls.companyId.value);
    }

    searchCompanyById(companyId: number, keyCodeTab?: string) {
        if(!this.editableCompany) return;
        if (companyId) {
            this.companyService.getCompanyById(companyId).subscribe((companySearch: CompanySearch) => {
                this.entityContactFormGroup.controls.companyName.setValue(companySearch.companyName);
                this.entityContactFormGroup.controls.companyStatus.setValue(companySearch.companyStatus);
            }, (error: any) => {
                if (keyCodeTab !== "Tab") {
                    this.clearCompany();
                    this.messageHandlerService.show(this.translate.instant('common.companyNotExists'), MessageType.INFO)
                }
            });
        } else {
            this.clearCompany();
        }
    }

    clearCompany() {
        this.entityContactFormGroup.controls.companyId.setValue('');
        this.entityContactFormGroup.controls.companyName.setValue('');
        this.entityContactFormGroup.controls.companyStatus.setValue('');
    }

    interceptDialogClose() {
        const contactWasEdited = this.contactWasEdited();
        const confirmFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                this.saveRecord();
            } else if (resp.event === MessageEvent.NO) {
                this.onCloseDefaultFunction.apply(this.activeModal, [false]);
            }
        }
        const contactIsNew = this.contactGeneric.iCopy.contactDisplay.getOperation() === OperationType.TEMPORAL;
        if ((contactIsNew || contactWasEdited) && !this.disabledSaveRecordButton) {
            this.messageHandlerService.show(Constants.CONFIRM_SAVE_RECORD_EDITED, MessageType.CONFIRM_OK_CANCEL, confirmFunction);
        } else {
            this.onCloseDefaultFunction.apply(this.activeModal, [false]);
        }
    }

    switchPanel(event: any) {
        this.selectedTabIndex = event.index;
        if(this.readOnly && this.selectedTabIndex == 1) {
            // Get the contact comments if they are not loaded yet, only when the component has readOnly = true
            if(this.contactGeneric.iCopy.contactDisplay.contactsCommentsDisplay.length == 0) {
                this.contactService.getCommentsByContactId(this.contactGeneric.iCopy.contactDisplay.contactId).subscribe((comments: ContactsCommentsDisplay[]) => {
                    this.contactGeneric.iCopy.contactDisplay.contactsCommentsDisplay = comments;

                    this.commentsComponent.disableCellEditor = true;
                    this.commentsComponent.addRowButtonDisabled = true;
                });
            }
        }
    }

    hasFaxAndAltPhone(): boolean {
        const entityName = this.entityName;
        return !(entityName === EntityName.PLANT_LTSA || entityName === EntityName.PIPELINE_LTSA);
    }

    updateContactDisplayLocked(contactDisplayLocked: boolean){
        this.contactDisplayLocked = contactDisplayLocked;
    }

}

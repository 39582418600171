export enum EntityNameToDropClearLock {
    COMPANY = 'Company',
    PLANT = 'Plant',
    AREA = 'Area',
    UNIT = 'Unit',
    BOILER = 'Boiler',
    DRIVE = 'Drive',
    OFFLINE_EVENT = 'Offline Event',
    PROJECT = 'Project',
    PLANT_LTSA = 'Plant LTSA',
    UNIT_LTSA = 'Unit LTSA',
    PIPELINE_LTSA = 'Pipeline LTSA',
    SITE = 'Site',
    PIPELINE = 'Pipeline',
    TRANSMISSION_LINE = 'Transmission Line',
    CONTACT = 'Contact',
    TANK = 'Tank',
    MEQUIPMENT = 'Mining Equipment',
    OEQUIPMENT = 'Other Equipment',
    }

export enum EntityNameTable {
    COMPANY = 'COMPANIES',
    PLANT = 'PLANTS',
    AREA = 'AREAS',
    UNIT = 'UNITS',
    BOILER = 'BOILERS',
    DRIVE = 'TURBINES',
    OFFLINE_EVENT = 'OFFLINE_EVENTS',
    PROJECT = 'PROJECTS',
    PLANT_LTSA = 'PLANT_LTSA',
    UNIT_LTSA = 'UNIT_LTSA',
    PIPELINE_LTSA = 'PIPELINE_LTSA',
    SITE = 'ASSETS',
    PIPELINE = 'ASSETS',
    TRANSMISSION_LINE = 'ASSETS',
    CONTACT = 'CONTACTS',
    TANK = 'TANKS',
    MEQUIPMENT = 'MINING_EQUIPMENT',
    OEQUIPMENT = 'EQUIPMENT', 
}
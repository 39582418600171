<div id="mainSection">

  <app-base-mvt-table-selector
      #mvtEntityAssociatorComponent
      [entitiesArray]="entitiesArray"
      [parentEntityId]="parentEntityId"
      [editable]="editable"
      [componentDescription]="'levelFuelType.note' | translate"
      [emptyMessage]="'levelFuelType.emptyMessage' | translate"
      [showAddRowButton]="false"
      [disabledButton]="true"
      (onChanges)="onEntityChanges($event)"
      [tKeyComponentTitle]="'plant.fuelTypes.title'"
      [columnsInfo]="getColumnsInfo()"
      [implementedComponent]="this"
      [baseIndex]="-1">
  </app-base-mvt-table-selector>

</div>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SharedService } from 'src/app/core/services/shared.service';
import { RelatedInfoBase } from '../related-info.base';
import { ColumnEntityInfo, ColumnEntityInfoEditableType } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-owner',
    templateUrl: './owner.component.html',
    styleUrls: ['./owner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerComponent extends RelatedInfoBase implements OnInit {
    entityType = EntityName.COMPANY;
    entityTableKey =EntityName.OWNER;
    constructor(

        public sharedService: SharedService
    ) { super(sharedService); }

    ngOnInit(): void {
        this.initBase();
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: 'CompanyId', columnHeader: 'relatedInfo.company.companyId', routerEntityName: EntityName.COMPANY, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'CompanyName', columnHeader: 'relatedInfo.company.companyName', widthPercentage: 40});
        columnsInfo.push({ entityPropName: 'OwnerPercentage', columnHeader: 'relatedInfo.company.percentage', widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right'});
        columnsInfo.push({ entityPropName: 'QcDate', columnHeader: 'relatedInfo.common.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 15});
        columnsInfo.push({ entityPropName: 'ReleaseDate', columnHeader: 'relatedInfo.common.releaseDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField, widthPercentage: 15});

        return columnsInfo;
    }

}

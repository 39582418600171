import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class EquipmentSearch {
    constructor(
        public unitName: string,
        public equipmentId: string,
        public equipmentName: string,
        public ownerName: string,
        public unitId: string,
        public plantId: string,
        public plantName: string,
        public areaName: string,
        public equipmentTypeId: string,
        public equipmentTypeName: string,
        public sicCode: string,
        public sicCodeDesc: string,
        public industryCode: string,
        public operator: string,
        public recordStatus: string,
        public manufacturer:string,
        public equipmentInfo:string,
        public qcDate: string,
        public capacity: string,
        public hsUnit: string,
        public hsProductId: string,
        public equipmentVendor: string,
        public equipmentVendorName: string,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class EquipmentSearchAdapter implements Adapter<EquipmentSearch> {
    adapt(item: any): EquipmentSearch {
        return new EquipmentSearch(
            item.UNIT_NAME,
            item.EQUIPMENT_ID, 
            item.EQUIPMENT_NAME, 
            item.OWNER_NAME, 
            item.UNIT_ID,
            item.PLANT_ID, 
            item.PLANT_NAME,
            item.AREA_NAME,  
            item.EQUIPMENT_TYPE_ID, 
            item.EQUIPMENT_TYPE_DESC, 
            item.SIC_CODE, 
            item.SIC_CODE_DESC, 
            item.PHYS_POSTAL_CODE, 
            item.OPERATOR, 
            item.RECORD_STATUS_DESC,
            item.MANUFACTURER_NAME,
            item.EQUIPMENT_INFO,
            item.QC_DATE,
            item.CAPACITY,
            item.HS_UNIT,
            item.HS_PRODUCT_ID,
            item.EQUIPMENT_VENDOR_ID,
            item.VENDOR_NAME
        );
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { EntityPaths } from "src/app/core/utils/entityPaths";
import { EntityName } from "src/app/core/models/enumerations/entity-name";

@Injectable({
    providedIn: 'root'
})
export class SaveDialogService {

    readonly DEFAULT_UPDATE_TYPE: number = 3;

    constructor(private http: HttpClient) {}

    checkHigherPrecedence(entityId: string, entityName: string, 
            majorUpdate: number, status: string): Observable<any> {
        let entityPath = '';
        let statusParam = '';
        let updateType = this.DEFAULT_UPDATE_TYPE;
        if(entityName === EntityName.SITE
                || entityName == EntityName.ASSET_PIPELINE
                || entityName === EntityName.ASSET_TRANSMISSION_LINE) {
            entityPath = 'asset';
        }else{
            entityPath = EntityPaths[Object.keys(EntityName).find(key => EntityName[key] === entityName)];
        }
        if(entityName === EntityName.PROJECT && status){
            updateType = majorUpdate;
            statusParam = `/${status}`;
        }else if(entityName == EntityName.TURBINE ||
                entityName == EntityName.BOILER ||
                entityName == EntityName.TANK){
            updateType = majorUpdate;
        }
        return this.http.get<any>(`${environment.apiUrl}${entityPath}/checkHigherPrecedence/${entityId}/${updateType}${statusParam}`)
            .pipe(
                map((data: any) => {
                    let response = null;
                    if(data.response != null) {
                        response = data.response[0]
                    }
                    return response;
                })
            );
    }

}

import { Bean } from "../../bean";
import { ContactsComments } from "./contacts-comments";

export class Contacts extends Bean {
    contactId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    nickName: string;
    jrSrIII: string;
    salutation: string;
    nationality: string;
    matronName: string;
    emailAddress: string;
    linkedInId: string;
    prevUser: string;
    prevDate: string;
    lastUser: string;
    lastDate: string;
    entryUser: string;
    entryDate: string;
    releaseDate: string;
    entryCompany: string;
    prevCompany: string;
    lastCompany: string;
    contactOptedOutBool: boolean;

    contactsComments: Array<ContactsComments>


    protected constructor(entity?: any) {
        super(entity);

        this.contactId = entity?.contactId ?? null;
        this.firstName = entity?.firstName ?? null;
        this.middleName = entity?.middleName ?? null;
        this.lastName = entity?.lastName ?? null;
        this.nickName = entity?.nickName ?? null;
        this.jrSrIII = entity?.jrSrIII ?? null;
        this.salutation = entity?.salutation ?? null;
        this.nationality = entity?.nationality ?? null;
        this.matronName = entity?.matronName ?? null;
        this.emailAddress = entity?.emailAddress ?? null;
        this.linkedInId = entity?.linkedInId ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.contactOptedOutBool = entity?.contactOptedOutBool ?? false;

        this.contactsComments = entity?.contactsComments ?? [];
    }
}

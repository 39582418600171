import { Bean } from "../../bean";

export class OfflineEventsBoilers extends Bean {
                           
    mvId: string;
    offlineEventId: number;
    boilerId: string;
    offlineEventCategoryId: string;
    derate: string;
    startDate: string;    
    endDate: string;        
    lastStartDate: string;        
    lastEndDate: string; 
    lastDate: string;
    mvOrder: number;

    constructor(entity?: any) {
        super(entity);

        this.mvId = entity?.mvId ?? null;
        this.offlineEventId = entity?.offlineEventId ?? null;
        this.boilerId = entity?.boilerId ?? null;
        this.offlineEventCategoryId = entity?.offlineEventCategoryId ?? null;
        this.derate = entity?.derate ?? null;
        this.startDate = entity?.startDate ?? null;
        this.endDate = entity?.endDate ?? null;
        this.lastStartDate = entity?.lastStartDate ?? null;
        this.lastEndDate = entity?.lastEndDate ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
    }
}

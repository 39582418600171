<div class="main-section" id="mainSection">
    
    <app-look-up-modal
        [entityIdPropName]="entityIdPropName"
        [searchedEntityIdPropName]="'productID'"
        [displayValueFunc]="displayValueFunc"
        [tKeyLabelEntityId]="'pipeline.productCarried.productId'"
        [tKeyLabelEntityName]="'description'"
        [entityName]="'common.product' | translate"
        [entityNamePlural]="'common.products' | translate"
        [searchOnModalOpen]="false"
        [idFieldType] = "IdFieldType.entityIdOnlyNumbers"
        [checkDuplicatedRows]="checkDuplicatedRows"
        [entitiesArray]="entitiesArray"
        
        [searchEntityDelegate]="searchEntityDelegate"
        
        [implementedComponent]="this">
    </app-look-up-modal>
    
</div>
<div class="px-4 h-21rem">
        <div class="grid mt-1">
            <div class="col-12">
    <div class="grid mt-2 pt-1 pl-2 pb-3">
        <div class="col-2 flex justify-content-end mt-1 ml-2 xl:-ml-2 lg:-ml-2 md:-ml-2 sm:-ml-2">
            <label class="text-xs font-bold">{{'countryState.waterbody'|translate}}:</label>
        </div>
        <div class="col-9 -ml-2">
            <input type="text" pInputText class="p-inputtext-sm border-1 surface-300" readonly [(ngModel)]="waterBody.waterBodyName">
        </div>
    </div>
    </div>
</div>
    <div class="border-1 border-500">
        <div class="col-12 custom-scrollable-table-modal">
            <p-table [value]="countriesByWaterBody" [style]="{'width':'100%'}" [reorderableColumns]="true"
                [resizableColumns]="true" selectionMode="single" (onRowSelect)="onRowSelect($event)"
                [(selection)]="this.selection">
                <ng-template pTemplate="body" let-item>
                    <tr #tr [pSelectableRow]="item" (dblclick)="onRowDblclick(item)" (keyup.enter)="onRowDblclick(item)"
                        (keydown)="changeSelectionOnArrowKey($event, countriesByWaterBody, false)">
                        <td>{{item.countryStateConcat}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="text-center mt-2">
        <button class="btn-row" (click)="addRowSingleClick()">{{'common.save'|translate}}</button>
        <button class="btn-row ml-2" (click)="closeModal()">{{'common.cancel'|translate}}</button>
    </div>
</div>
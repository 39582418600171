import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LockedEntityModalComponent, LockedEntityModalResult } from 'src/app/components/messages/locked-entity-modal/locked-entity-modal.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { EntityName } from '../models/enumerations/entity-name';
import { LockMode } from '../models/enumerations/lock-mode';
import { LockDetails } from '../record-locking/lock-details';
import { LockItem } from '../record-locking/lock-item';
import { RecordLockingFlow } from '../record-locking/record-locking-flow';
import { RecordLockingService } from '../record-locking/record-locking.service';


@Injectable({
    providedIn: 'root'
})
export class LockSameUserMessageService {

    constructor(
        private messageHandlerService: MessageHandlerService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private recordLockingService: RecordLockingService
    ) { }


    public validateEntityIsLockedByUserAndOpenEntityTab(entityType: string, entityId: string, entityPath: string, target: string) {

        this.validateEntityIsLockedByUser(null, entityType, entityId, null, false, true).then((lockSameUserResult: LockSameUserResult) => {
            this.openEntityTab(lockSameUserResult, entityPath, target);
        });
    }

    private openEntityTab(lockSameUserResult: LockSameUserResult, entityPath: string, target: string) {
        if(lockSameUserResult.openEntity) {
            const openedWindow = window.open(entityPath, target);
            if(lockSameUserResult.lockHasBeenCleared()) {
                openedWindow.location.reload();
            }
        }
    }

    public validateEntityIsLockedByUser(recordLockingFlow: RecordLockingFlow, entityType: string, entityId: string, mainLockID: number, quickAccess: boolean = false, avoidSameSessionValidation: boolean = false): Promise<LockSameUserResult> {
        return new Promise<LockSameUserResult>((resolve) => {
            entityType = (entityType === EntityName.PROJECT_UNCONFIRMED || entityType === EntityName.PROJECT_CONFIRMED) ? EntityName.PROJECT : entityType;

            this.recordLockingService.getEntityLockBySameUser(entityType, entityId).subscribe((lockedItem: LockDetails) => {
                if (lockedItem != null) {
                    if(mainLockID > 0 && mainLockID === lockedItem.lockID) {
                        resolve(new LockSameUserResult(null, null, true));
                        return;
                    }
                    if(avoidSameSessionValidation && lockedItem.sameSession) {
                        resolve(new LockSameUserResult(lockedItem, null, true));
                        return;
                    }
                    if(quickAccess && !lockedItem.sameSession && mainLockID <= 0) {
                        resolve(new LockSameUserResult(lockedItem, null, true));
                        return;
                    }
                    
                    this.messageHandlerService.showingSystemMessage = true;
                    const modalRef = this.openLockedEntityModalComponent(entityType, entityId, lockedItem);
    
                    modalRef.onClose.subscribe((selectedOption: LockedEntityModalResult) => {
                        this.messageHandlerService.showingSystemMessage = false;

                        if(selectedOption === LockedEntityModalResult.CANCEL && mainLockID > 0
                            && recordLockingFlow != null) {
                            const lockItem: LockItem = recordLockingFlow.getLockItem(entityType, entityId);
                            if(lockItem != null && lockItem.lockMode === LockMode.READ) {
                                selectedOption = LockedEntityModalResult.OPEN_READ_MODE;
                            }
                        }
                        resolve(new LockSameUserResult(lockedItem, selectedOption));
                    });
                } else {
                    resolve(new LockSameUserResult(null, null, true));
                }
            });
        });
    }

    private openLockedEntityModalComponent(entityType: string, entityId: string, lockedItem: LockDetails): DynamicDialogRef {
        const modalRef = this.dialogService.open(LockedEntityModalComponent, {
            header: this.translate.instant('lockedEntity.title'),
            closable: false,
            showHeader: false,
            width: '40rem',
            data: {
                entityType: entityType,
                entityId: entityId,
                lockedItem: lockedItem
            },
            contentStyle: {
                "background-color": "black",
                "padding": "0",
                "border-radius": "4px"
            },
            baseZIndex: 10000,
            draggable: true,
            keepInViewport: true
        });

        return modalRef;
    }


    public clearContactsLockedsBySameUser(lockSameUserResult: LockSameUserResult, contactsArray: any[]) {
        if(lockSameUserResult.lockHasBeenCleared()) {
            this.recordLockingService.clearContactsLockedsBySameUser(contactsArray);
        }
    }


}

export class LockSameUserResult {

    lockedItem: LockDetails;
    selectedOption: LockedEntityModalResult;
    openEntity: boolean;

    constructor(lockedItem: LockDetails, selectedOption: LockedEntityModalResult, openEntity: boolean = null) {
        this.lockedItem = lockedItem;
        this.selectedOption = selectedOption;
        this.openEntity = openEntity;

        if(this.openEntity == null) {
            this.openEntity = (this.selectedOption === LockedEntityModalResult.OPEN_ANYWAY || this.selectedOption === LockedEntityModalResult.OPEN_READ_MODE);
        }
    }

    public lockHasBeenCleared(): boolean {
        return (this.selectedOption === LockedEntityModalResult.OPEN_ANYWAY);
    }

}
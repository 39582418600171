import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiEndpoint } from "../models/constants";

/**
 * This provides a way to report back application usage information to the backend
 */

@Injectable({ providedIn: 'root' })
export class TelemetryService {

   constructor() { }

   private sendReport(report: object): void {
      if (environment.sendTelemetry) {
         const blob = new Blob([JSON.stringify(report)], {
            type: 'application/json'
         });
         navigator.sendBeacon(environment.apiUrl + ApiEndpoint.TelemetryReport, blob);
      }
      else if(environment.consoleTelemetry)
      {
         console.debug('telemetry report: ', report);         
      }
   }

   public versionUpdate(before: string, after: string): void {
      this.sendReport({
         metric: "versionUpdate",
         before: before,
         after: after,
      });
   }

   public uiError(message: string): void {
      this.sendReport({
         metric: "uiError",
         message: message,
      });
   }

   public apiError(endpoint: string, message: string): void {
      
      const apiUrlPos = endpoint.indexOf(environment.apiUrl);
      if(apiUrlPos !== -1)
      {
         endpoint = endpoint.substring(apiUrlPos + environment.apiUrl.length);
         const qPos = endpoint.indexOf('?');
         if(qPos !== -1)
         {
            endpoint = endpoint.substring(0, qPos);
         }
      }
      
      if(endpoint !== ApiEndpoint.TelemetryReport)  // Don't try to report a failure of the reporting URL, that could cause a loop! 
      {
         this.sendReport({
            metric: "apiError",
            endpoint: endpoint,
            message: message,
         });
      }      
   }

   public entityLoadTime(entity: string, millis: number): void {
      this.sendReport({
         metric: "entityLoadTime",
         entity: entity,
         millis: Math.round(millis),
      });
   }

   public entitySearchTime(entity: string, millis: number): void {
      this.sendReport({
         metric: "entitySearchTime",
         entity: entity,
         millis: Math.round(millis),
      });
   }
   
   public releaseAction(action: string, entity: string, millis: number): void {
      this.sendReport({
         metric: "releaseAction",
         action: action,
         entity: entity,
         millis: Math.round(millis),
      });
   }
   
   public featureUsage(feature: string): void {
      this.sendReport({
         metric: "featureUsage",
         feature: feature,
      });
   }

}
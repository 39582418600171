<div style="background-color: #475d89;" class="pt-3 lg:pt-3 xl:pt-3 
            pl-3 lg:pl-2 xl:pl-2
            pb-3 lg:pb-3 xl:pb-3
            pr-3 lg:pr-3 xl:pr-3  
            border-round-left-md
            border-round-right-md
            text-center lg:text-left xl:text-left">
    <div>
        <span class="text-white text-xs">{{'shared__components_aside_nav_user' | translate}}</span>
        <h5 class="text-white text-base font-bold my-0" title="Username: {{username}}">{{displayName}}</h5>
    </div>
    <div class="pb-1 lg:pb-0 xl:pb-0">
        <span class="white-space-nowrap">
            <a class="text-white text-xs no-underline hover:underline white-space-normal cursor-pointer" (click)="goToWebsite()">{{'industrial_info_home'| translate}}</a>
        </span>
        <span style="cursor: default">&nbsp;|&nbsp;</span>
        <span>
            <a class="text-white text-xs no-underline hover:underline pl-1 white-space-normal cursor-pointer" (click)="logout()">{{'logout'|translate}}</a>
        </span>
    </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Constants, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { EquipmentType, Industry, RecordStatus, SicCode } from '../../../core/models/common';

import { IEquipmentSearchParams } from 'src/app/core/interfaces/search/iequipment-search-params';
import { EquipmentSearch } from 'src/app/core/models/search/equipment-search';
import { EquipmentSectionService } from 'src/app/equipment-section/equipment-section.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { BaseModalComponent } from '../base-modals.component';
import { EquipmentTypesModalComponent } from '../equipment-types-modal/equipment-types-modal.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-equipment',
    templateUrl: './search-equipment.component.html',
    styleUrls: ['./search-equipment.component.scss']
})
export class SearchEquipmentComponent extends BaseModalComponent implements OnInit {
    @ViewChild('inputUnitName') inputUnitName: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    equipments: Array<EquipmentSearch> = [];
    additionalInfo: EquipmentSearch;

    recordStatuses: Array<RecordStatus> = [];
    industryCodes: Array<Industry> = [];
    driveSicCodes: Array<SicCode> = [];

    isMainAccess: boolean = false;

    selected: any;
    cols: any[] = [];
    changeClass: boolean = false;
    changeCard: boolean = false;

    searchParams: IEquipmentSearchParams = {
        equipmentId: '',
        unitName: '',
        ownerName: '',
        unitId: '',
        plantId: '',
        plantName: '',
        equipmentName: '',
        equipmentTypeId: '',
        equipmentTypeName: '',
        sicCode: null,
        industryCode: null,
        recordStatus: null,
        recordedSearch: false
    }

    constructor(
        private equipmentService: EquipmentSectionService,
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        private entityCommonService: EntityCommonService,
        public preferencesService: PreferencesSectionService,
        public loadWheelService: LoadWheelService
    ) { super(activeModal, config, preferencesService); }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.cols = [
            { field: 'equipmentId', header: this.translate.instant('equipment.searchEquipment.equipmentId'), width: '10%', isDate: false },
            { field: 'equipmentName', header: this.translate.instant('equipment.searchEquipment.equipmentName'), width: '20%', isDate: false },
            { field: 'equipmentTypeName', header: this.translate.instant('equipment.searchEquipment.equipmentType'), width: '25%', isDate: false },
            { field: 'plantName', header: this.translate.instant('equipment.searchEquipment.plantName'), width: '25%', isDate: false },
            { field: 'unitName', header: this.translate.instant('equipment.searchEquipment.unitName'), width: '20%', isDate: false },
        ];

        this.loadList();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.clear();

        this.searchEquipmentByParamsRecording();
        if (this.searchParams.unitName != null && this.searchParams.unitName !== '') {
            setTimeout(() => this.inputUnitName.nativeElement.select(), 0);
        }
    }

    search() {
        this.searchEquipment(this.searchParams);
    }

    private searchEquipmentByParamsRecording() {
        this.searchParams = this.equipmentService.searchParams;
        if (this.searchParams.recordedSearch) {
            if (!this.isEmptyObjectProperty(this.equipmentService.searchParams)) {
                this.searchEquipment(this.equipmentService.searchParams);
            }
        }
    }


    private isEmptyObjectProperty(object: IEquipmentSearchParams): boolean {
        const { recordedSearch, ...search } = object;
        return Object.values(search).every((x: Object) => (x === null || x === '' ||
            x === 0 || Object.values(x).length === 0
            || x === '0'));
    }

    private searchEquipment(params: IEquipmentSearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.equipments"));
        this.equipmentService.searchEquipments(params, wheel)
            .subscribe({
                next: (equipments: EquipmentSearch[]) => {
                    if(equipments === null) {
                        equipments = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                    } else if (equipments.length === 0) {
                        this.messageHandler.showAlertAndReturnFocus(
                            this.translate.instant('common.searchNotFound'),
                            undefined,
                            this.inputUnitName,
                            true
                        );
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                    } else {
                        this.additionalInfo = equipments[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                    }
                    this.equipments = equipments;
                    this.loadWheelService.hideWheel(wheel);
                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(
                            error.message,
                            undefined,
                            this.inputUnitName,
                            true
                        );
                        this.equipments = [];
                        this.additionalInfo = null;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    onRowDblclick(event: any) {
        this.activeModal.close(event);
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }


    clear() {
        this.clearFields(true);
    }

    private clearFields(clearAll: boolean) {

        this.searchParams.equipmentId = '';
        this.searchParams.unitName = '';
        this.searchParams.ownerName = '';
        this.searchParams.unitId = '';
        this.searchParams.plantId = '';
        this.searchParams.plantName = '';
        this.searchParams.equipmentName = '';
        this.searchParams.equipmentTypeId = '';
        this.searchParams.equipmentTypeName = '';
        this.searchParams.sicCode = null;
        this.searchParams.industryCode = null;
        this.searchParams.recordStatus = null;

        this.equipments = [];
        this.additionalInfo = null;
        this.changeClass = true;

        if (clearAll) {
            setTimeout(() => this.inputUnitName.nativeElement.focus(), 1);
        }
    }

    private loadList() {
        this.loadRecordStatus();
        this.loadIndustryCode();
        this.loadSicCode();
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => this.recordStatuses = record.filter(item => item.RecordStatusID !== 'P'));
    }

    private loadIndustryCode() {
        this.sharedService.getIndustryCode()
            .subscribe((industry: Industry[]) => this.industryCodes = industry);
    }

    private loadSicCode() {
        this.sharedService.getUnitsSicCodes()
            .subscribe((sicCodes: SicCode[]) => this.driveSicCodes = sicCodes);
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    openEquipmentTypeSearchModal(): DynamicDialogRef {
        const modalRef = this.dialogService.open(EquipmentTypesModalComponent, {
            header: this.translate.instant('equipment.equipmentTypeSearch.search'),
            width: '40rem',
            data: {
                entitiesArray: [],
                searchOnModalOpen: true
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((type: EquipmentType) => {
            if (type && type.equipmentTypeDesc) {
                this.setEquipmentTypeData(type.equipmentTypeDesc);
            }
        });

        return modalRef;
    }

    setEquipmentTypeData(type:string):void{
        this.searchParams.equipmentTypeName = type;
    }


}


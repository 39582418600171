import { Bean } from '../../bean';
import { CompaniesSalesInfo } from './companies-sales-info';
import { CompaniesCompanyInfo} from './companies-company-info';
import { CompaniesSICCodes } from '../save/sic-codes-info';
import { CompaniesNotes} from './companies-notes';
import { CompaniesCompanyTypes} from './companies-company-types';
import { CompaniesPhoneInfo} from './companies-phone-info';
import { CompaniesFaxInfo} from './companies-fax-info';
import { CompaniesContactInfo } from './companies-contact-info';
import { Contacts } from '../../contact/save/contacts';
import { CompaniesGreenhouseGasEmissions } from './companies-greenhouse-gas-emissions';

export abstract class Companies extends Bean {
    companyId: number;
    companyName: string;
    secondaryName: string;
    previousName: string;
    previousUserEmailAddress:string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailPostalCode: string;
    classification: string;
    lastCompany: string;
    lastUser: string;
    lastDate: string;
    lastUserEmailAddress: string;
    prevCompany: string;
    prevUser: string;
    prevDate: string;
    entryCompany: string;
    entryUser: string;
    entryUserEmailAddress: string;
    entryDate: string;
    qcDate: string;
    qcUser: string;
    qcUserEmailAddress: string;
    industryCode: string;
    physAddressV1: string;
    physAddressV2: string;
    physPostalCode: string;
    companyStatus: string;
    recordStatus: string;
    latitude: string;
    longitude: string;
    latLonVerified: string;
    stockExchange: string;
    stockSymbol: string;
    webSite: string;
    pecZoneSimplex: string;
    dunsNumber: string;
    numEmployees: string;
    yearEstablished: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;
    physCountryId: number;
    physStateId: number;
    physCityId: number;
    physCountyId: number;
    noFaxVerifiedUser: string;
    noFaxVerifiedDate: string;

    faxCc:string;
    faxNo:string;
    faxExt:string;
    vnFaxSelected:boolean;
    phoneCc:string;
    phoneNo:string;
    phoneExt:string;
    phoneMobile:string;
    noCompanyFaxVerifiedLastDate:string;
    noCompanyFaxVerifiedLastUser:string;
    noCompanyFaxVerifiedPrevDate:string;
    noCompanyFaxVerifiedPrevUser:string;

    noCurrentEmissionReportedVerified: boolean;
    noCurrentEmissionReportedLastDate: string;
    noCurrentEmissionReportedLastUser: string;
    noCurrentEmissionReportedPrevDate: string;
    noCurrentEmissionReportedPrevUser: string;

    companiesSalesInfo: Array<CompaniesSalesInfo>;
    companiesCompanyInfo: Array<CompaniesCompanyInfo>;
    companiesContactInfo: Array<CompaniesContactInfo>;
    companiesSICCodes: Array<CompaniesSICCodes>
    companiesNotes: Array<CompaniesNotes>;
    companiesPhoneInfo: Array<CompaniesPhoneInfo>
    companesFaxInfo: Array<CompaniesFaxInfo>
    companiesGreenhouseGasEmissions: Array<CompaniesGreenhouseGasEmissions>;

    contacts: Array<Contacts>;

    protected constructor(entity?: any) {
      super(entity);

      this.companyId = entity?.companyId ?? null;
      this.companyName = entity?.companyName ?? null;
      this.secondaryName = entity?.secondaryName ?? null;
      this.previousName = entity?.previousName ?? null;
      this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
      this.mailAddressV1 = entity?.mailAddressV1 ?? null;
      this.mailAddressV2 = entity?.mailAddressV2 ?? null;
      this.mailPostalCode = entity?.mailPostalCode ?? null;
      this.classification = entity?.classification ?? null;
      this.lastCompany = entity?.lastCompany ?? null;
      this.lastUser = entity?.lastUser ?? null;
      this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
      this.lastDate = entity?.lastDate ?? null;
      this.prevCompany = entity?.prevCompany ?? null;
      this.prevUser = entity?.prevUser ?? null;
      this.prevDate = entity?.prevDate ?? null;
      this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
      this.entryCompany = entity?.entryCompany ?? null;
      this.entryUser = entity?.entryUser ?? null;
      this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
      this.entryDate = entity?.entryDate ?? null;
      this.qcDate = entity?.qcDate ?? null;
      this.qcUser = entity?.qcUser ?? null;
      this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
      this.industryCode = entity?.industryCode ?? null;
      this.physAddressV1 = entity?.physAddressV1 ?? null;
      this.physAddressV2 = entity?.physAddressV2 ?? null;
      this.physPostalCode = entity?.physPostalCode ?? null;
      this.companyStatus = entity?.companyStatus ?? null;
      this.recordStatus = entity?.recordStatus ?? null;
      this.latitude = entity?.latitude ?? null;
      this.longitude = entity?.longitude ?? null;
      this.latLonVerified = entity?.latLonVerified ?? null;
      this.stockExchange = entity?.stockExchange ?? '0';
      this.stockSymbol = entity?.stockSymbol ?? null;
      this.webSite = entity?.webSite ?? null;
      this.pecZoneSimplex = entity?.pecZoneSimplex ?? null;
      this.dunsNumber = entity?.dunsNumber ?? null;
      this.numEmployees = entity?.numEmployees ?? null;
      this.yearEstablished = entity?.yearEstablished ?? null;
      this.liveDate = entity?.liveDate ?? null;
      this.releaseDate = entity?.releaseDate ?? null;
      this.releaseUser = entity?.releaseUser ?? null;
      this.amendmentDate = entity?.amendmentDate ?? null;
      this.amendmentUser = entity?.amendmentUser ?? null;
      this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;
      this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
      this.mailCountryId = entity?.mailCountryId ?? null;
      this.mailStateId = entity?.mailStateId ?? null;
      this.mailCityId = entity?.mailCityId ?? null;
      this.physCountryId = entity?.physCountryId ?? null;
      this.physStateId = entity?.physStateId ?? null;
      this.physCityId = entity?.physCityId ?? null;
      this.physCountyId = entity?.physCountyId ?? null;
      this.noFaxVerifiedUser = entity?.noFaxVerifiedUser ?? null;
      this.noFaxVerifiedDate = entity?.noFaxVerifiedDate ?? null;
      this.faxCc = entity?.faxCc ?? null;
      this.faxNo = entity?.faxNo ?? null;
      this.faxExt = entity?.faxExt ?? null;
      this.vnFaxSelected = entity?.vnFaxSelected ?? false;
      this.phoneCc = entity?.phoneCc ?? null;
      this.phoneNo = entity?.phoneNo ?? null;
      this.phoneExt = entity?.phoneExt ?? null;
      this.phoneMobile = entity?.phoneMobile ?? null;
      this.noCompanyFaxVerifiedLastDate = entity?.noCompanyFaxVerifiedLastDate ?? null;
      this.noCompanyFaxVerifiedLastUser = entity?.noCompanyFaxVerifiedLastUser ?? null;
      this.noCompanyFaxVerifiedPrevDate = entity?.noCompanyFaxVerifiedPrevDate ?? null;
      this.noCompanyFaxVerifiedPrevUser = entity?.noCompanyFaxVerifiedPrevUser ?? null;

      this.noCurrentEmissionReportedVerified = entity?.noCurrentEmissionReportedVerified ?? false;
      this.noCurrentEmissionReportedLastDate = entity?.noCurrentEmissionReportedLastDate ?? null;
      this.noCurrentEmissionReportedLastUser = entity?.noCurrentEmissionReportedLastUser ?? null;
      this.noCurrentEmissionReportedPrevDate = entity?.noCurrentEmissionReportedPrevDate ?? null;
      this.noCurrentEmissionReportedPrevUser = entity?.noCurrentEmissionReportedPrevUser ?? null;

      this.companiesSICCodes = entity?.companiesSICCodes ?? [];
      this.companiesCompanyInfo = entity?.companiesCompanyInfo ?? [];
      this.companiesNotes = entity?.companiesNotes ?? [];
      this.companiesSalesInfo = entity?.companiesSalesInfo ?? [];
      this.companiesPhoneInfo = entity?.companiesPhoneInfo ?? [];
      this.companesFaxInfo = entity?.companiesFaxInfo ?? [];
      this.companiesContactInfo = entity?.companiesContactInfo ?? [];
      this.companiesGreenhouseGasEmissions = entity?.companiesGreenhouseGasEmissions ?? [];

      this.contacts = entity?.contacts ?? [];
    }
}

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Constants } from 'src/app/core/models/constants';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css']
})
export class AuthLayoutComponent {

   constructor(private titleService: Title)
   {
      this.titleService.setTitle(Constants.APP_TITLE);               
   }
}

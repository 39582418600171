<div class="header" *ngIf="showCustomHeader">
    <h3>{{'relatedInfo.tank.title' | translate }}</h3>
</div>
<div class="cont-img-icon" *ngIf="showCustomHeader">
    <img *ngIf="!classToggled" src="assets/icons/reload.png" width="15px" height="15px" class="mt-1 mr-1 cursor-pointer"
        (click)="reload()">
    <div class="bg-icon">
        <img *ngIf="classToggled" src="assets/icons/plus.svg" (click)="toggleField()" width="10px">
        <img *ngIf="!classToggled" src="assets/icons/minus.svg" (click)="toggleField()" width="10px">
    </div>
</div>
<div *ngIf="showCustomHeader" class="grid col-12 -mt-2 pb-0" [class.toggled]="classToggled">
    <div class="col-12 flex flex-column pb-0">
        <div class="flex flex-row -ml-3">
            <div class="flex flex-column col-7 lg:col-10 xl:col-10">
                <div class="grid">
                    <label
                        class="col-10 ml-2
                        flex justify-content-start pt-0
                        pl-0 font-bold text-xs mt-1">{{'relatedInfo.tank.operational'|translate:{opTanksCount: opTanksCount, totalTanks: totalTanks} }}
                    </label>
                </div>
                <div *ngIf="addRecordDestinationPage != null" class="flex flex-row">
                    <button class="btn-row w-max" type="button"
                        [disabled]="isAddButtonDisabled()"
                        (click)="tankBaseComponent.addRecordFunction(this, addRecordDestinationPage)"
                        [tabindex]="baseIndex"
                        [title]="(callerEntityDisplay === undefined) ? ('common.entityNotAvailable' | translate) : ('relatedInfo.tank.addTank' | translate)"
                    >{{'relatedInfo.tank.addTank' | translate}}
                    </button>
                </div>
            </div>
            <div class="flex flex-column
                col-5 lg:col-2 xl:col-2 pt-0 pb-0">
                <div class="flex flex-row pt-0 pb-0">
                    <label
                        class="flex justify-content-end pt-0 w-8rem
                            pl-0 font-bold text-xs mt-0">{{'relatedInfo.tank.totalBBL'|translate}}:
                    </label>
                    <label
                        class="ml-1
                            flex justify-content-start pt-0
                            pl-0 text-xs mt-0">{{totalBBL | number}}
                    </label>
                </div>
                <div class="flex flex-row pt-0 pb-0">
                    <label
                        class="flex justify-content-end pt-0 w-8rem
                            pl-0 font-bold text-xs mt-0">{{'relatedInfo.tank.totalMMSCF'|translate}}:
                    </label>
                    <label
                        class="ml-1
                            flex justify-content-start pt-0
                            pl-0 text-xs mt-0">{{totalMMSCF | number}}
                    </label>
                </div>
                <div class="flex flex-row pt-0 pb-0">
                    <label
                        class="flex justify-content-end pt-0 w-8rem
                            pl-0 font-bold text-xs mt-0">{{'relatedInfo.tank.totalCubicMeters'|translate}}:
                    </label>
                    <label
                        class=" ml-1
                            flex justify-content-start pt-0
                            pl-0 text-xs mt-0">{{totalCubicMeters | number}}
                    </label>
                </div>

            </div>
        </div>
    </div>
</div>
<app-relatedinfo-base
    #tankBaseComponent
    [implementedComponent]="this"
    i18nBase="relatedInfo.tank"
    [tableKey]="tableKey"
    [preferences]="preferences"
    [caller]="caller"
    [rows]="rows"
    [classToggled]="classToggled"
    [showHeader]="showHeader"
    [callerEntityDisplay]="callerEntityDisplay"
    [callerEntityIdPropName]="callerEntityIdPropName"
    (reloadFunction)="reload()"
    [baseIndex]="baseIndex + 1"
    [columnsInfo]="getColumnsInfo()">
</app-relatedinfo-base>

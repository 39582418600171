import { StringUtils } from "src/app/core/utils/string-utils";
import {OperationType} from "../../enumerations/operation-type";
import {OfflineEvents} from "../save/offline-event";
import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { EntityName } from "../../enumerations/entity-name";
import { OfflineEventsAssetsDisplay } from "./offline-events-assets-display";
import { OfflineEventsUnitsDisplay } from "./offline-events-units-display";
import { OfflineEventsBoilersDisplay } from "./offline-events-boilers-display";
import { OfflineEventsDrivesDisplay } from "./offline-events-drives-display";
import { OfflineEventsNotesDisplay } from "./offline-events-notes-display";
import { OfflineEventsPlantsDisplay } from "./offline-event-plants-display";
import { MVTOperations } from "src/app/core/mvt-operations";
import { TranslateService } from "@ngx-translate/core";
import { DateUtil } from "src/app/core/utils/date-util";

export class OfflineEventsDisplay extends OfflineEvents {

    commnentsAll: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    releaseUserEmailAddress: string;
    duration: string;

    originalStartDate: string;
    originalEndDate: string;
    originalOfflineEventStatus: string;

    offlineEventsAssetsDisplay: OfflineEventsAssetsDisplay[] = [];
    offlineEventsBoilersDisplay: OfflineEventsBoilersDisplay[] = [];
    offlineEventsDrivesDisplay: OfflineEventsDrivesDisplay[] = [];
    offlineEventsNotesDisplay: OfflineEventsNotesDisplay[] = [];
    offlineEventsPlantsDisplay: OfflineEventsPlantsDisplay[] = [];
    offlineEventsUnitsDisplay: OfflineEventsUnitsDisplay[] = [];

    acOfflineEventsByUnitID: any[] = [];

    offlineEventAlertType: string = "";
    offlineEventAlertDelay: string = "";
    offlineEventAlertComments: string = "";

    alertSubject: string = "";
    acOfflineEventAlerts: any[] = [];

    constructor(entity?: any) {
        super(entity);

        this.commnentsAll = entity?.commnentsAll ?? null;
        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;

        this.originalStartDate = entity?.startDate ?? null;
        this.originalEndDate = entity?.endDate ?? null;
        this.originalOfflineEventStatus = entity?.offlineEventStatus ?? null;

        this.offlineEventsAssetsDisplay = entity?.offlineEventsAssetsDisplay ?? [];
        this.offlineEventsBoilersDisplay = entity?.offlineEventsBoilersDisplay ?? [];
        this.offlineEventsDrivesDisplay = entity?.offlineEventsDrivesDisplay ?? [];
        this.offlineEventsNotesDisplay = entity?.offlineEventsNotesDisplay ?? [];
        this.offlineEventsPlantsDisplay = entity?.offlineEventsPlantsDisplay ?? [];
        this.offlineEventsUnitsDisplay = entity?.offlineEventsUnitsDisplay ?? [];

        this.acOfflineEventsByUnitID = entity?.acOfflineEventsByUnitID ?? [];

        this.offlineEventAlertType = entity?.offlineEventAlertType ?? null;
        this.offlineEventAlertDelay = entity?.offlineEventAlertDelay ?? null;
        this.offlineEventAlertComments = entity?.offlineEventAlertComments ?? null;

        this.alertSubject = entity?.alertSubject ?? null;
        this.acOfflineEventAlerts = entity?.acOfflineEventAlerts ?? [];

        this.initialData = entity;
    }


    public static getEventName(unitName: string, plantName: string, assetName: string, assetSize: number): string
    {
        let eventName: string = "";

        if(!StringUtils.isEmpty(unitName)) {
            eventName = unitName;
        } else if(!StringUtils.isEmpty(plantName)){
            eventName = plantName;
        } else {
            eventName = OfflineEventsDisplay.getAssetName(assetName, assetSize);
        }

        return eventName;
    }

    public static getAssetName(assetName: string, assetSize: number): string
    {
        let name: string = "";
        if(!StringUtils.isEmpty(assetName)){
            name = assetName;
            if(assetSize > 1){
                name += " (+"+(assetSize-1)+" More Assets)";
            }
        }
        return name;
    }

    public static getUnitName(unitName: string, unitSize: number): string
    {
        let name: string = "";
        if(!StringUtils.isEmpty(unitName)){
            name = unitName;
            if(unitSize > 1){
                name += ' '+OfflineEventsDisplay.getUnitCount(unitSize);
            }
        }
        return name;
    }

    public static getUnitCount(unitSize: number): string
    {   
        return unitSize > 0 ? '(+'+(unitSize-1)+' More Units)' : '';
    }

    public static countOfflineEventsForItem(unitsNames: String):number {
        if(unitsNames != null) {
            return unitsNames.split('\n').length + 1;
        }
        return 0;
    }

    static BuildOfflineEventDisplay(entity: any): OfflineEventsDisplay {
        return new OfflineEventsDisplay(entity);
    }

    static BuildNewOfflineEvent(response: any): OfflineEventsDisplay {
        return new OfflineEventsDisplay({
            offlineEventId: response[0].OFFLINE_EVENT_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.OFFLINE_EVENT,
            entityId: this.offlineEventId
        };
    }

    prepareSaveCollection() {

        this.offlineEventsAssets = this.offlineEventsAssetsDisplay;
        this.offlineEventsBoilers = this.offlineEventsBoilersDisplay;
        this.offlineEventsDrives = this.offlineEventsDrivesDisplay;
        this.offlineEventsNotes = this.offlineEventsNotesDisplay;
        this.offlineEventsPlants = this.offlineEventsPlantsDisplay;
        this.offlineEventsUnits = this.offlineEventsUnitsDisplay;
        this.prepareSavePrevStartEndDate();
    }

    public prepareSavePrevStartEndDate(): void {
        if (!DateUtil.areDatesEqual(this.originalStartDate, this.startDate) || !DateUtil.areDatesEqual(this.originalEndDate, this.endDate)) {
            this.lastStartDate = this.originalStartDate ? String(this.originalStartDate) : this.lastStartDate;
            this.changedFields['lastStartDate'] = 0;
            this.lastEndDate = this.originalEndDate ? String(this.originalEndDate) : this.lastEndDate;
            this.changedFields['lastEndDate'] = 0;
        }

        this.offlineEventsAssetsDisplay.forEach(asset => {
            asset.prepareSavePrevStartEndDate();
        });
        this.offlineEventsPlantsDisplay.forEach(plant => {
            plant.prepareSavePrevStartEndDate();
        });
        this.offlineEventsUnitsDisplay.forEach(unit => {
            unit.prepareSavedData();
        });
    }

    applyChangedFields(changeObj: any) {
        this.changedFields = {};

        for(const field in changeObj) {
            if(this.hasOwnProperty(field)) {
                this.changedFields[field] = 0;
            }
        }
    }

    public getFirstOfflineEventsUnitsDisplay(): OfflineEventsUnitsDisplay
    {
        return this.offlineEventsUnitsDisplay.find(unit => unit.mvOrder === 1 && unit.getOperation() !== OperationType.DELETE);
    }

    public getFirstOfflineEventsAssetsDisplay(): OfflineEventsAssetsDisplay
    {
        return this.offlineEventsAssetsDisplay.find(asset => asset.mvOrder === 1 && asset.getOperation() !== OperationType.DELETE);
    }

    public getFirstOfflineEventsPlantsDisplay(): OfflineEventsPlantsDisplay
    {
        return this.offlineEventsPlantsDisplay.find(plant => plant.mvOrder === 1 && plant.getOperation() !== OperationType.DELETE);
    }

    public currentOfflineEventsUnitsDisplay(): Array<OfflineEventsUnitsDisplay>
    {
        return MVTOperations.filterByDeleted(this.offlineEventsUnitsDisplay);
    }

    public currentOfflineEventsAssetsDisplay(): Array<OfflineEventsAssetsDisplay>
    {
        return MVTOperations.filterByDeleted(this.offlineEventsAssetsDisplay);
    }

    public currentOfflineEventsPlantsDisplay(): Array<OfflineEventsPlantsDisplay>
    {
        return  MVTOperations.filterByDeleted(this.offlineEventsPlantsDisplay);
    }

    public getOfflineEventName(showCounters: boolean = false, translate: TranslateService = null): string {

        let eventName: string = '';
        let firstUnit = this.getFirstOfflineEventsUnitsDisplay();
        if(firstUnit && firstUnit.unitName) {
            eventName = firstUnit.unitName;
            if(showCounters) {
                let unitCount: number = MVTOperations.filterByDeleted(this.offlineEventsUnitsDisplay).length;
                if (unitCount > 1) {
                    eventName = translate.instant(unitCount === 2 ? 'widgets.common.moreUnit' : 'widgets.common.moreUnits', { entityName: firstUnit.unitName, "count": unitCount - 1 });
                }
            }
        } else {
            let firstPlant = this.getFirstOfflineEventsPlantsDisplay();
            if(firstPlant && firstPlant.plantName) {
                eventName = firstPlant.plantName;
            } else {
                let firstAsset = this.getFirstOfflineEventsAssetsDisplay();
                if(firstAsset && firstAsset.assetName) {
                    eventName = firstAsset.assetName;
                    if(showCounters) {
                        let assetCount: number = MVTOperations.filterByDeleted(this.offlineEventsAssetsDisplay).length;
                        if (assetCount > 1) {
                            eventName = translate.instant(assetCount === 2 ? 'widgets.common.moreAsset' : 'widgets.common.moreAssets', { entityName: firstAsset.assetName, "count": assetCount - 1 });
                        }
                    }
                } 
            }
        }
        return eventName;
    }
}

 



import { OfflineEventsDrives } from "../save/offline-events-drives";

export class OfflineEventsDrivesDisplay extends OfflineEventsDrives {
    
    driveName: string;
    offlineEventCategoryName: string;
    manufacturerName: string;
    typeDescription: string;

    constructor(entity?: any) {
        super(entity);

        this.driveName = entity?.driveName ?? null;
        this.offlineEventCategoryName = entity?.offlineEventCategoryName ?? null;
        this.manufacturerName = entity?.manufacturerName ?? null;
        this.typeDescription = entity?.typeDescription ?? null;

        this.initialData = entity;
    }

    static CreateInstance(entity: any): OfflineEventsDrivesDisplay {
        return new OfflineEventsDrivesDisplay(entity);
    }
    
    static BuildFromList(entity: Array<any>): Array<OfflineEventsDrivesDisplay> {
          return entity.map(item => OfflineEventsDrivesDisplay.CreateInstance(item));
    }
}

import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class MiningEquipmentSearch {
    constructor(
        public unitName: string,
        public miningEquipmentId: string,
        public miningEquipmentName: string,
        public ownerName: string,
        public unitId: string,
        public plantId: string,
        public plantName: string,
        public areaName: string,
        public miningEquipmentTypeId: string,
        public miningEquipmentTypeName: string,
        public miningEquipmentStatusId: string,
        public miningEquipmentStatusName: string,
        public sicCodeId: string,
        public sicCodeDesc: string,
        public recordStatus: string,
        public recordStatusName: string,
        public qcDate: string,
        public capacity: string,
        public hsProductId: string,
        public miningEquipmentVendor: string,
        public miningEquipmentVendorName: string,
        public model: string,
        public hsUnit: string,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class MiningEquipmentSearchAdapter implements Adapter<MiningEquipmentSearch> {
    adapt(item: any): MiningEquipmentSearch {
        return new MiningEquipmentSearch(
            item.UNIT_NAME,
            item.MINING_EQUIPMENT_ID, 
            item.MINING_EQUIPMENT_NAME, 
            item.OWNER_NAME, 
            item.UNIT_ID,
            item.PLANT_ID, 
            item.PLANT_NAME,
            item.AREA_NAME,  
            item.MINING_EQUIPMENT_TYPE_ID, 
            item.MINING_EQUIPMENT_TYPE_DESC, 
            item.MINING_EQUIPMENT_STATUS, 
            item.MINING_EQUIPMENT_STATUS_DESC, 
            item.SIC_CODE, 
            item.SIC_CODE_DESC, 
            item.RECORD_STATUS, 
            item.RECORD_STATUS_DESC, 
            item.QC_DATE, 
            item.CAPACITY, 
            item.HS_PRODUCT_ID, 
            item.MINING_EQUIPMENT_VENDOR_ID, 
            item.VENDOR_NAME,
            item.MINING_EQUIPMENT_MODEL,
            item.HS_UNIT
        );
    }
}

export class MiningEquipmentStatus {
    statusID: string;
    shortDescription: string;
    description: string;
    rank: number;
    rank1: number;
    rank2: number;
    group: string;

    private constructor(
        statusID: string,
        shortDescription: string,
        description: string,
        rank: number,
        rank1: number,
        rank2: number,
        group: string
    ) {
        this.statusID = statusID;
        this.description = description;
        this.shortDescription = shortDescription;
        this.rank = rank;
        this.rank1 = rank1;
        this.rank2 = rank2;
        this.group = group;
    }

    static BuildMiningEquipmentStatus(entity: Array<any>): Array<MiningEquipmentStatus> {
        return entity.map(item => MiningEquipmentStatus.CreateInstance(item));
    }

    private static CreateInstance(record: any): MiningEquipmentStatus {
        return new MiningEquipmentStatus(
            record.MINING_EQUIPMENT_STATUS_ID, 
            record.STATUS_DESC, 
            `${record.MINING_EQUIPMENT_STATUS_ID} - ${record.STATUS_DESC}`,
            record.RANK, 
            record.RANK_1, 
            record.RANK_2, 
            record.STATUS_GROUP);
    }
}

export class MiningEquipmentType {
    typeId: string;
    typeShortDesc: string;
    typeDesc: string;

    private constructor(
        typeId: string,
        typeShortDesc: string,
        typeDesc: string
    ) {
        this.typeId = typeId;
        this.typeShortDesc = typeShortDesc;
        this.typeDesc = typeDesc;
    }

    static BuildMiningEquipmentType(entity: Array<any>): Array<MiningEquipmentType> {
        return entity.map(item => MiningEquipmentType.CreateInstance(item));
    }

    private static CreateInstance(record: any): MiningEquipmentType {
        return new MiningEquipmentType(
            record.MINING_EQUIPMENT_TYPE_ID, 
            record.MINING_EQUIPMENT_TYPE_DESC, 
            `${record.MINING_EQUIPMENT_TYPE_ID} - ${record.MINING_EQUIPMENT_TYPE_DESC}`);
    }
}




import { LockDetails } from 'src/app/core/record-locking/lock-details';
import { Bean } from '../../bean';

export abstract class ClearLockUtilities extends Bean {
    
    amendmentDate: string;
    amendmentUser: string;
    amendmentUserEmailAddress: string;
    entryDate: string;
    entryUser: string;
    entryUserEmailAddress: string;
    lastUser: string;
    lastDate: string;
    lastUserEmailAddress: string;
    prevUser: string;
    prevDate: string;
    previousUserEmailAddress:string;
    qcDate: string;
    qcUser: string;
    qcUserEmailAddress: string;
    lockDetails:LockDetails;
    entityName: string;
    entityID:number;
    releaseDate:string;
    releaseUser:string;
    releaseUserEmailAddress:string;
    wipRemovable:number;

    protected constructor(entity?: any) {
      super(entity);
      
      this.amendmentDate = entity?.amendmentDate ?? null;
      this.amendmentUser = entity?.amendmentUser ?? null;
      this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
      this.entryUser = entity?.entryUser ?? null;
      this.entryDate = entity?.entryDate ?? null;
      this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
      this.lastUser = entity?.lastUser ?? null;
      this.lastDate = entity?.lastDate ?? null;
      this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
      this.lockDetails = entity?.lockDetails ?? null;
      this.prevUser = entity?.previousUser ?? null;
      this.prevDate = entity?.previousDate ?? null;
      this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
      this.qcDate = entity?.qcDate ?? null;
      this.qcUser = entity?.qcUser ?? null;
      this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
      this.entityName = entity?.lockDetails?.entityType ?? null;
      this.entityID = entity?.lockDetails?.entityID ?? null;
      this.releaseDate = (entity && entity?.releaseDate) ?? null;
      this.releaseUser = (entity && entity?.releaseUser) ?? null;
      this.releaseUserEmailAddress = (entity?.releaseUserEmailAddress) ?? null;
      this.wipRemovable = (entity && entity?.wipRemovable ) ?? null; 
    }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IPlantSearchParams } from 'src/app/core/interfaces/search/iplant-search-params';
import { Constants, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { CountryWaterBody, OffshoreField, WaterBody } from 'src/app/core/models/offshoreAddress';
import { PlantSearch } from 'src/app/core/models/search/plant-search';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { Country, Industry, PlantStatus, RecordStatus, State } from '../../../core/models/common';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { BaseModalComponent } from '../base-modals.component';
import { PecZoneModalComponent } from '../pec-zone-modal/pec-zone-modal.component';
import { SicCodeLookupComponent } from '../sic-code-lookup/sic-code-lookup.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-plant',
    templateUrl: './search-plant.component.html',
    styleUrls: ['./search-plant.component.scss']
})
export class SearchPlantComponent extends BaseModalComponent implements OnInit {
    @ViewChild('inputOwnerName') inputOwnerName: ElementRef;
    @ViewChild('inputPlantName') inputPlantName: ElementRef;
    @ViewChild('inputIndustryCode') inputIndustryCode: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    plants: Array<PlantSearch> = [];
    additionalInfo: PlantSearch;

    recordStatuses: Array<RecordStatus> = [];
    plantStatuses: Array<PlantStatus> = [];
    industryCodes: Array<Industry> = [];
    physCountries: Array<Country> = [];
    physStates: Array<State> = [];
    waterBodies: Array<WaterBody> = [];
    fields: Array<OffshoreField> = [];
    isMainAccess: boolean = false;
    restrictedOnOffshore: boolean = false;
    restrictedOnOffshoreValue: string;


    selected: any;
    enabledNewRecordButton: boolean = false;
    colsOnshore: any[] = [];
    colsOffshore: any[] = [];
    changeClass: boolean = false;
    changeCard: boolean = false;

    searchParams: IPlantSearchParams = {
        plantId: '',
        plantName: '',
        industryCode: null,
        sicCodelist: [],
        sicCode: '',
        sicCodeToShow: '',
        plantOwnerId: '',
        plantState: null,
        plantStatus: '',
        plantOffshore: '0',
        plantWaterBody: null,
        plantFieldName: '',
        plantArea: '',
        pecZone: '',
        plantCity: '',
        plantCounty: '',
        plantCountry: null,
        plantRecordStatus: null,
        recordedSearch: false
    }

    constructor(
        private plantService: PlantSectionService,
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        private entityCommonService: EntityCommonService,
        public preferencesService: PreferencesSectionService,
        public loadWheelService: LoadWheelService
    ) { super(activeModal, config, preferencesService); }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.colsOnshore = [
            { field: 'plantId', header: this.translate.instant('plant.searchPlant.plantTablePlantId'), width: '9%', isDate: false },
            { field: 'plantName', header: this.translate.instant('plant.searchPlant.plantTablePlantName'), width: '18%', isDate: false },
            { field: 'ownerName', header: this.translate.instant('plant.searchPlant.plantTableOwner'), width: '15%', isDate: false },
            { field: 'physAddress', header: this.translate.instant('plant.searchPlant.plantTableAddress'), width: '15%', isDate: false },
            { field: 'plantPhysCity', header: this.translate.instant('plant.searchPlant.plantTableCity'), width: '10%', isDate: false },
            { field: 'plantPhysStateName', header: this.translate.instant('plant.searchPlant.plantTableState'), width: '8%', isDate: false },
            { field: 'plantPhysCountryName', header: this.translate.instant('plant.searchPlant.plantTableCountry'), width: '8%', isDate: false },
            { field: 'sicCode', header: this.translate.instant('plant.searchPlant.plantTableSicCode'), width: '8%', isDate: false },
            { field: 'plantStatusDesc', header: this.translate.instant('plant.searchPlant.plantTableStatus'), width: '9%', isDate: false }
        ]

        this.colsOffshore = [
            { field: 'plantId', header: this.translate.instant('plant.searchPlant.plantTablePlantId'), width: '9%', isDate: false },
            { field: 'plantName', header: this.translate.instant('plant.searchPlant.plantTablePlantName'), width: '18%', isDate: false },
            { field: 'ownerName', header: this.translate.instant('plant.searchPlant.plantTableOwner'), width: '15%', isDate: false },
            { field: 'quadrantBlock', header: this.translate.instant('plant.searchPlant.plantTableQuadrantBlock'), width: '15%', isDate: false },
            { field: 'plantWaterBody', header: this.translate.instant('plant.searchPlant.plantTableWaterBody'), width: '10%', isDate: false },
            { field: 'plantFieldName', header: this.translate.instant('plant.searchPlant.plantTableFieldName'), width: '9%', isDate: false },
            { field: 'plantArea', header: this.translate.instant('plant.searchPlant.plantTableArea'), width: '7%', isDate: false },
            { field: 'sicCode', header: this.translate.instant('plant.searchPlant.plantTableSicCode'), width: '8%', isDate: false },
            { field: 'plantStatusDesc', header: this.translate.instant('plant.searchPlant.plantTableStatus'), width: '9%', isDate: false }
        ]

        this.loadList();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.clear();

        if (this.config.data?.restrictedOnOffshore) {
            this.restrictedOnOffshore = true;
            this.restrictedOnOffshoreValue = this.config.data?.offshore ? '1' : '0';
        }
        this.searchPlantByParamsRecording();
        if (this.searchParams.plantName != null && this.searchParams.plantName !== '') {
            setTimeout(() => this.inputOwnerName.nativeElement.select(), 0);
        }
        this.loadCountries();
    }

    onOffshoreChange() {
        this.clearFields(false);
        this.loadCountries();
        setTimeout(() => this.inputOwnerName.nativeElement.focus(), 1);
    }

    search() {
        this.searchPlant(this.searchParams);
    }

    handleListboxKeydown(event: KeyboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.key === 'Tab') {
            this.inputPlantName.nativeElement.focus();
        } else if (event.shiftKey && event.key === 'Tab') {
            this.inputIndustryCode.nativeElement.focus();
        }
    }

    onEnterPecZone(event: any) {
        if (!StringUtils.isEmpty(event.target.value)) {
            this.search();
        } else {
            this.openZoneSearchModal();
        }
    }

    private searchPlantByParamsRecording() {
        this.searchParams = this.plantService.searchParams;
        if (this.restrictedOnOffshore) {
            this.searchParams.plantOffshore = this.restrictedOnOffshoreValue;
        }
        if (this.searchParams.recordedSearch) {
            if (!this.isEmptyObjectProperty(this.plantService.searchParams)) {
                this.searchPlant(this.plantService.searchParams);
            }
        }
        if (this.searchParams.plantCountry !== '') {
            this.loadStates(this.searchParams.plantCountry);
        }
    }

    private isEmptyObjectProperty(object: IPlantSearchParams): boolean {
        const { plantOffshore, recordedSearch, sicCodeToShow, sicCodelist, ...search } = object;
        return Object.values(search).every((x: Object) => (x === null || x === '' ||
            x === 0 || Object.values(x).length === 0
            || x === '0'));
    }

    private searchPlant(params: IPlantSearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.plants"));
        if (this.restrictedOnOffshore) {
            params.plantOffshore = this.restrictedOnOffshoreValue;
        }
        this.plantService.searchPlant(params, wheel)
            .subscribe({
                next: (plants: PlantSearch[]) => {
                    if(plants === null) {
                        plants = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = false;
                    } else if (plants.length === 0) {
                        this.messageHandler.showAlertAndReturnFocus(
                            this.translate.instant("common.searchNotFound"),
                            undefined,
                            this.inputOwnerName,
                            true
                        );
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = true;
                    } else {
                        this.additionalInfo = plants[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                        this.enabledNewRecordButton = true;
                    }
                    this.plants = plants;
                    this.loadWheelService.hideWheel(wheel);
                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(
                            error.message,
                            undefined,
                            this.inputOwnerName,
                            true
                        );
                        this.plants = [];
                        this.additionalInfo = null;
                        this.enabledNewRecordButton = false;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    onRowDblclick(event: any) {
        if (this.config.data?.ownership) {
            const objOwnership = this.config.data?.createInstance(event);
            this.activeModal.close(objOwnership);
        } else {
            this.activeModal.close(event);
        }
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onChangePhysCountry() {
        if (this.searchParams.plantOffshore === '0') {
            // Onshore, then we load the states by country.
            if (this.searchParams.plantCountry !== '' && this.searchParams.plantCountry !== null) {
                this.loadStates(this.searchParams.plantCountry);
            } else {
                this.physStates = [];
            }
            this.searchParams.plantState = null;
        } else {
            // Offshore, then we load the waterBodies by country.
            if (this.searchParams.plantCountry !== '' && this.searchParams.plantCountry !== null) {
                this.loadWaterBodies(this.searchParams.plantCountry);
            } else {
                this.waterBodies = [];
            }
            this.searchParams.plantWaterBody = null;
        }
    }

    onChangePhysWaterBody() {
        if (this.searchParams.plantWaterBody !== '' && this.searchParams.plantWaterBody !== null) {
            this.loadFields(Number(this.searchParams.plantWaterBody));
        } else {
            this.fields = [];
        }
        this.searchParams.plantFieldName = '';
    }

    clear() {
        this.clearFields(true);
    }

    private clearFields(clearAll: boolean) {
        this.searchParams.plantName = '';
        this.searchParams.plantOwnerId = '';
        this.searchParams.industryCode = null;
        this.searchParams.sicCode = '';
        this.searchParams.sicCodelist = [];
        this.searchParams.sicCodeToShow = this.translate.instant('plant.searchPlant.plantEmptySicCode');
        this.searchParams.plantState = null;
        this.searchParams.plantStatus = '';
        this.searchParams.plantWaterBody = null;
        this.searchParams.plantFieldName = '';
        this.searchParams.plantArea = '';
        this.searchParams.pecZone = '';
        this.searchParams.plantCity = '';
        this.searchParams.plantCounty = '';
        this.searchParams.plantCountry = null;
        this.searchParams.plantRecordStatus = null;
        this.plants = [];
        this.physStates = [];
        this.fields = [];
        this.enabledNewRecordButton = false;
        this.additionalInfo = null;
        this.changeClass = true;

        if (clearAll) {
            setTimeout(() => this.inputOwnerName.nativeElement.focus(), 1);
        }
    }

    private loadList() {
        this.loadRecordStatus();
        this.loadPlantStatus();
        this.loadIndustryCode();
    }

    private loadPlantStatus() {
        this.sharedService.getPlantStatus()
            .subscribe((record: PlantStatus[]) => this.plantStatuses = [...record]);
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => this.recordStatuses = [...record]);
    }

    private loadIndustryCode() {
        this.sharedService.getIndustryCode()
            .subscribe((industry: Industry[]) => this.industryCodes = [...industry]);
    }

    private loadCountries() {
        if (this.searchParams.plantOffshore === '0') {
            this.loadOnshoreCountries();
        } else {
            this.loadOffshoreCountries();
        }
    }

    private loadOnshoreCountries() {
        this.addressService.getCountries()
            .subscribe((countries: Country[]) => {
                this.physCountries = [...countries];
            });
    }

    private loadOffshoreCountries() {
        this.offshoreAddressService.getCountriesWaterBodyById(true, '')
            .subscribe((countries: CountryWaterBody[]) => {
                const mappedCountries: Country[] = countries.map(it => {
                    return new Country(
                        it.cId,
                        it.countryId,
                        it.countryName,
                        0,
                        it.countryId + ' - ' + it.countryName);
                });

                this.physCountries = [...mappedCountries];
            });
    }

    private loadStates(countryId: string) {
        this.addressService.getStateByCountry(countryId, '', true)
            .subscribe((states: State[]) => this.physStates = [...states]);
    }

    private loadWaterBodies(countryName: string) {
        this.offshoreAddressService.getWaterBodies(countryName)
            .subscribe((waterBodies: WaterBody[]) => this.waterBodies = [...waterBodies]);
    }

    private loadFields(waterBodyId: number) {
        this.offshoreAddressService.getOffshoreFields(waterBodyId, '', false)
            .subscribe((offshoreField: OffshoreField[]) => this.fields = [...offshoreField]);
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    openSICCodeLookup() {
        const modalRef = this.dialogService.open(SicCodeLookupComponent, {
            header: this.translate.instant('sicCode.searchSicCodes'),
            width: '40rem',
            data: this.searchParams.sicCodelist,
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((codetableData: any) => {
            if (codetableData !== undefined) {
                this.validateAndSetSICCodeData(codetableData);
            }
        });
    }

    validateAndSetSICCodeData(codetableData: any) {
        this.searchParams.sicCodelist = codetableData;
        let sicCodeIds = '';
        for (let i = 0; i < codetableData.length; i++) {
            sicCodeIds += codetableData[i].sicCode;

            if (i < codetableData.length - 1) {
                sicCodeIds += ', ';
            }
        }

        this.searchParams.sicCode = sicCodeIds;
        this.searchParams.sicCodeToShow = sicCodeIds !== '' ? sicCodeIds :
            this.translate.instant('plant.searchPlant.plantEmptySicCode');
    }

    openZoneSearchModal() {

        const modalRef = this.dialogService.open(PecZoneModalComponent, {
            header: 'PEC Zone Search',
            width: '40rem',
            data: {},
            draggable: true,
            keepInViewport: true
        });
        modalRef.onClose.subscribe((zone: any) => {
            if (zone) {
                this.searchParams.pecZone = zone.pecZoneId;
            }
        });
    }

}


import { Bean } from "../../../bean";

export class AssetsProjects extends Bean {
    assetId: number;
    projectId: number;
    mvId: number;
    mvOrder: number;
    lastDate: string;

    constructor(entity: any) {
        super(entity);

        this.assetId = entity?.assetId ?? null;
        this.projectId = entity?.projectId ?? null;
        this.mvId = entity?.mvId ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.lastDate = entity?.lastDate ?? null;
    }
}

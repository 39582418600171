<div class="container-modal">
    <p-messages></p-messages>
    <div class="EX-content-modal">
        <div class="grid pt-1">
            <div class="col-1 min-w-max">
                <button (click)="clear()" class="btn-custom">{{'area.searchArea.clear'| translate}}</button>
            </div>
            <div class="col-1 mt-1 flex justify-content-end min-w-max" *ngIf="!restrictedOnOffshore">
                <span class="px-0 font-bold text-xs">{{'area.searchArea.search'| translate}}: </span>
            </div>
            <!-- formGroupName="OptionsFilters" -->
            <div class="col-1 min-w-max" *ngIf="!restrictedOnOffshore">
                <p-radioButton name="filter" [(ngModel)]="searchParams.offshore" value="0"
                    label="{{'area.searchArea.onshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-3 min-w-max" *ngIf="!restrictedOnOffshore">
                <p-radioButton name="filter" [(ngModel)]="searchParams.offshore" value="1"
                    label="{{'area.searchArea.offshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-6"></div>
        </div>
        <div class="content-modal" style="margin-top: 15px;">
            <form class="mt-2">
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">

                        <div class="flex -mt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'area.searchArea.areaName'| translate}}:</label>
                            <input class="col-6 mx-1 form__input-field-style w-6" type="text" name="areaName" #inputAreaName (keydown.enter)="search()"
                            [(ngModel)]="searchParams.areaName" pInputText  autofocus appFocusElement maxlength="80" />
                        </div>

                        <div class="flex -mt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'area.searchArea.plantId'| translate}}:</label>
                            <input class="col-6 mx-1 form__input-field-style w-6" type="text" name="plantId" (keydown.enter)="search()"
                            [(ngModel)]="searchParams.plantId" pInputText  appOnlyNumbers maxlength="7" />
                        </div>

                        <div class="flex -mt-2 align-items-center" *ngIf="searchParams.offshore === '0'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'area.searchArea.physCountry'| translate}}:</label>
                            <p-dropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="physCountries"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true"
                                filterBy="countryId,countryNameConcat"
                                resetFilterOnHide="false"
                                autofocusFilter="true"
                                (keyup.enter)="search()"
                                (onChange)="onChangePhysCountry()"
                                name="physCountry"
                                [(ngModel)]="searchParams.physCountry"
                                optionLabel="countryNameConcat"
                                optionValue="countryId">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">

                        <div class="flex -mt-2 align-selects"  *ngIf="searchParams.offshore === '0'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'area.searchArea.physState'| translate}}:</label>
                            <p-dropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [disabled]="searchParams.physCountry === '' || searchParams.physCountry === null"
                                [options]="physStates"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true"
                                [filter]="true"
                                filterBy="sId,stateConcat"
                                resetFilterOnHide="true"
                                autofocusFilter="false"
                                (keyup.enter)="search()"
                                name="physState"
                                [(ngModel)]="searchParams.physState"
                                optionLabel="stateConcat"
                                optionValue="sId">
                            </p-dropdown>
                        </div>

                        <div class="flex -mt-1 align-selects"  *ngIf="searchParams.offshore === '0'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs -mt-1">{{'area.searchArea.physCity'| translate}}:</label>
                            <input class="col-6 mx-1 form__input-field-style w-6"  type="text"   name="physCity" (keydown.enter)="search()"
                            [(ngModel)]="searchParams.physCity" pInputText  maxlength="80" />
                        </div>

                        <div class="flex -mt-2 align-selects"  *ngIf="searchParams.offshore === '1'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'area.searchArea.waterBody'| translate}}:</label>
                            <p-dropdown  
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="waterBodies"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true"
                                filterBy="waterBodyId,waterBodyName"
                                resetFilterOnHide="false"
                                autofocusFilter="true"
                                (keyup.enter)="search()"
                                (onChange)="onChangePhysWaterBody()"
                                name="waterBody"
                                [(ngModel)]="searchParams.waterBody"
                                optionLabel="waterBodyNameConcat"
                                optionValue="waterBodyId">
                            </p-dropdown>
                        </div>

                        <div class="flex -mt-1 align-selects"  *ngIf="searchParams.offshore === '1'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs -mt-1">{{'area.searchArea.fieldName'| translate}}:</label>
                            <input class="col-6 mx-1 form__input-field-style w-6"  type="text"   name="fieldName" (keydown.enter)="search()"
                            [(ngModel)]="searchParams.fieldName" pInputText  maxlength="80"/>
                        </div>

                        <div class="flex -mt-2 align-selects">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'site.searchSite.siteRecordStatus'| translate}}:</label>
                            <p-dropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="recordStatuses"
                                [filter]="true"
                                showClear="true"
                                [placeholder]="'common.selectOne' | translate"
                                filterBy="RecordStatusID,RecordStatusDescription"
                                resetFilterOnHide="true"
                                autofocusFilter="true"
                                (keyup.enter)="search()"
                                name="recordStatus"
                                [(ngModel)]="searchParams.recordStatus"
                                optionLabel="RecordStatusDescription"
                                optionValue="RecordStatusID">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr class="hr-modal">
    <div class="grid main-content pt-1 md:pt-0 lg:pt-0 xl:pt-0">
        <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
            <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
            <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'| translate}}</span>
        </div>
        <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
            <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
            <span><strong>{{'common.keyboardUsers' | translate}}: </strong>{{'common.searchKeyboardUsers'| translate}}</span>
        </div>
    </div>
    <div class="container-table-modal">
        <p-table #searchTable ignoreHeaders *ngIf="searchParams.offshore === '0'" [value]="entitiesArray" selectionMode="single" [rows]="rowsPerPage"
            [showCurrentPageReport]="true" [columns]="colsOnshore" [resizableColumns]="true"
            styleClass="p-datatable-gridlines" [reorderableColumns]="true" (sortFunction)="customSort($event)"
            [customSort]="true" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns tabindex="-1">
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, entitiesArray)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}} pl-2" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable">
                            <div *ngIf="!col.isDate">{{rowData[col.field]}}</div>
                            <div *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd-MMM-y'}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-table #searchTable ignoreHeaders *ngIf="searchParams.offshore === '1'" [value]="entitiesArray" selectionMode="single" [rows]="rowsPerPage"
            [showCurrentPageReport]="true" [columns]="colsOffshore" [resizableColumns]="true"
            styleClass="p-datatable-gridlines" [reorderableColumns]="true" (sortFunction)="customSort($event)"
            [customSort]="true" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, entitiesArray)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}}" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable">
                            <div *ngIf="!col.isDate">{{rowData[col.field]}}</div>
                            <div *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd-MMM-y'}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="container-info-modal main-content">
        <div class="grid card-modal mt-2 ml-0">
                <div class="col-12 header-info">
                    <span>{{'additional_info'| translate}}:</span>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card" *ngIf="searchParams.offshore !== '1'">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.physAddress' | translate}}:</label>
                        <label for="" class="custom-label-card-info"
                        *ngIf="additionalInfo?.physAddressV1"
                        [attr.changeCard]="additionalInfo?.physAddressV1?.length > 70 ? true : null"
                        >{{ additionalInfo?.physAddressV1 }}</label>
                    </div>
                    <div class="content-card" *ngIf="searchParams.offshore === '1'">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.quadrantBlock' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.offshoreBlockV1 }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.mailAddress' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.mailAddressV1 }}</label>
                    </div>
                    <div class="content-card" *ngIf="searchParams.offshore !== '1'">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.country' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.physCountry }}</label>
                    </div>
                    <div class="content-card" *ngIf="searchParams.offshore === '1'">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.waterbody' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.waterbodyName }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.telephone' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.telephone }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.faxNumber' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.faxNumber }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.qcDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'dd-MMM-y'}}</label>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.ultimateOwner' | translate}}:</label>
                        <label for="" class="custom-label-card-info"
                        >{{ additionalInfo?.companyName }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.operator' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.operatorName }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.plantStatus' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.plantStatus }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'common.recordStatus' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.recordStatusDesc }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'area.additionalInfo.noEmployees' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.noEmployees }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short"></label>
                        <label for="" class="custom-label-card-info"></label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isMainAccess" class="grid pt-3">
            <div class="col-12 lg:col-11 xl:col-11 flex justify-content-end">
                <label class="custom-label mt-2 lg:mr-2 xl:-mr-1">{{'area.searchArea.infoSearch'| translate}}
                    <strong> {{'area.searchArea.notDuplicate'| translate}}</strong>
                </label>
            </div>
            <div class="col-12 lg:col-1 xl:col-1 flex justify-content-end">
                <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                    class="col-4 btn-custom h-2rem" label="Create New Record"
                    (click)="newRecord()">{{'area.searchArea.newRecordButton'| translate}}
                </button>
            </div>
        </div>
</div>

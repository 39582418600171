import { Bean } from "../../bean";
import { TurbinesFuel } from "./turbines-fuel";

export class Drives extends Bean {
    turbineId: number;
    name: string;
    type: number;
    unitId: number;
    manufacturerId: number;
    prevManufacturerId: number;
    model: string;
    genManufacturerId: number;
    series: string;
    genModel: string;
    mailPostalCode: string;
    classification: string;
    designFirmId: number;
    constructorId: number;
    startUpDate: string;
    shutDownDate: string;
    designElectricalOutput: number;
    designBtuConsumption: number;
    operatingElectricalOutput: number;
    operatingBtuConsumption: number;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    qcCompany: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    turbineCount: number;
    amendmentDate: string;
    amendmentUser: string;
    startUpResolution: number;
    shutDownResolution: number;
    drivenEquipmentTypeId: number;
    driveCategoryId: number;
    recordStatus: string;
    driveStatus: string;

    // lists of beans - MVTs
    turbinesFuel: Array<TurbinesFuel>;

    protected constructor(entity?: any) {
        super(entity);
        this.turbineId = entity?.turbineId ?? null;
        this.name = entity?.name ?? null;
        this.type = entity?.type ?? null;
        this.unitId = entity?.unitId ?? null;
        this.manufacturerId = entity?.manufacturerId ?? null;
        this.prevManufacturerId = entity?.prevManufacturerId ?? null;
        this.model = entity?.model ?? null;
        this.genManufacturerId = entity?.genManufacturerId ?? null;
        this.series = entity?.series ?? null;
        this.genModel = entity?.genModel ?? null;
        this.mailPostalCode = entity?.mailPostalCode ?? null;
        this.classification = entity?.classification ?? null;
        this.designFirmId = entity?.designFirmId ?? null;
        this.constructorId = entity?.constructorId ?? null;
        this.startUpDate = entity?.startUpDate ?? null;
        this.shutDownDate = entity?.shutDownDate ?? null;
        this.designElectricalOutput = entity?.designElectricalOutput ?? null;
        this.designBtuConsumption = entity?.designBtuConsumption ?? null;
        this.operatingElectricalOutput = entity?.operatingElectricalOutput ?? null;
        this.operatingBtuConsumption = entity?.operatingBtuConsumption ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.qcCompany = entity?.qcCompany ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.turbineCount = entity?.turbineCount ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.startUpResolution = entity?.startUpResolution ?? null;
        this.shutDownResolution = entity?.shutDownResolution ?? null;
        this.drivenEquipmentTypeId = entity?.drivenEquipmentTypeId ?? null;
        this.driveCategoryId = entity?.driveCategoryId ?? null;
        this.recordStatus = entity?.recordStatus ?? null;
        this.driveStatus = entity?.driveStatus ?? null;
        
        this.turbinesFuel = entity?.turbinesFuel ?? [];
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { FuelTypeSearch } from 'src/app/core/models/search/plant-search';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { DriveSectionService } from 'src/app/drive-section/drive-section.service';
import { IdFieldType } from 'src/app/shared/components/base/look-up-modal/look-up-modal.component';

@Component({
    selector: 'app-fueltype-search',
    templateUrl: './fueltype-search.component.html',
    styleUrls: ['./fueltype-search.component.scss']
})
export class FuelTypeSearchComponent implements OnInit {

    @Input() isFuelTypeEntity: boolean;

    entitiesArray:any[];
    entityIdPropName: string = 'fuels';
    public get IdFieldType(): typeof IdFieldType {
        return IdFieldType;
    }

    constructor(
        public config: DynamicDialogConfig,
        private driveService: DriveSectionService) {
    }

    ngOnInit(): void {
        this.entitiesArray = this.config.data.entitiesArray;
        if(this.config.data.entityIdPropName){
            this.entityIdPropName = this.config.data.entityIdPropName;
        }
    }

    searchEntityDelegate(implementedComponent: FuelTypeSearchComponent, inputEntityId: string, inputEntityName: string):Observable<FuelTypeSearch[]> {
        const fuelTypeId: number = StringUtils.isEmpty(inputEntityId) ? null : Number(inputEntityId);
        return implementedComponent.driveService.getFuelTypes(fuelTypeId, StringUtils.trim(inputEntityName));
    }

}

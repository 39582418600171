<div class="grid -mt-3 pt-1">
    <label *ngIf="tKeyComponentTitle != null" [ngClass]="labelClass">{{tKeyComponentTitle| translate}}:</label>
    <div [ngClass]="inputClass">
        <input
            class="form__input-field-style w-4"
            type="text"
            #focusEntityId
            name="inputEntityId"
            [(ngModel)]="inputEntityId"
            (keydown.enter)="entityIdOnEnter($event)"
            (blur)="entityIdOnBlur($event)"
            pInputText
            maxlength="7"
            [disabled]="isDisabledComponent()"
            [tabIndex]="baseIndex"
            appOnlyNumbers
        />
        <input
            class="form__input-field-style w-8"
            type="text"
            name="inputEntityName"
            [(ngModel)]="inputEntityName"
            pInputText
            [disabled]="true"
            [tabIndex]="baseIndex + 1"
        />
    </div>
    <div [ngClass]="buttonClass">
        <span *ngIf="requiredField" class="required-field">*</span>
        <span *ngIf="!requiredField" class="select-none opacity-0">*</span>
        <button
            *ngIf="openSearchModal != null"
            pButton pRipple 
            type="button" 
            icon="pi pi-search"
            class="p-button-text icon-container ml-2"
            (click)="openSearchModalAndSetEntityData()"
            [disabled]="isDisabledComponent()"
            [tabIndex]="baseIndex + 2">
        </button>
    </div>
</div>
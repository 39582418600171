<div class="text-center">
    <h3 class="">{{'addressComparation.notMatchSite'| translate:{type:type, entity1: rightTitle, entity2: leftTitle} }}</h3>
    <span class="-mt-1 text-base text-red-500">{{'addressComparation.note'| translate}}</span>
</div>
<div class="grid">
    <!-- LEFT -->
    <div class="col-12 xl:col-6 w-29rem h-18rem border-solid surface-border border-round-md shadow-6 ml-6 mt-3">
            <div class="w-full addressTitle">
                <h3 class="text-xl pl-2">{{leftTitle}}</h3>
            </div>
            <p-carousel [value]="items" [numVisible]="1" [numScroll]="1" [circular]="false"
                [showIndicators]="false" (onPage)="onPage($event)" [showNavigators]="showCarousel">
                <ng-template let-item pTemplate="item">
                    <div class="grid w-23rem mt-1">
                        <div class="grid col-12 text-sm">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.entityID'| translate :{entity: leftTitle} }}:</div>
                            <div class="col-8 text-sm">{{item.entityId}}</div>
                        </div>

                        <div class="grid col-12 -mt-5"  *ngIf="!isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.country'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.countryName !== entityAddress.countryName ? 'text-red-500' : ''">{{item.countryName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.postalCode'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.zipCode !== entityAddress.zipCode ? 'text-red-500' : ''">{{item.zipCode}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.address'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.address1 !== entityAddress.address1 || item.address2 !== entityAddress.address2 ? 'text-red-500' : ''">
                                {{item.address1}}
                            </div>
                        </div>

                        <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                            <div class="col-4"></div>
                            <div class="col-8 text-sm" [ngClass]="item.address2 !== entityAddress.address2 ? 'text-red-500' : ''">{{item.address2}}</div>
                        </div>

                        <div class="grid col-12 -mt-5"  *ngIf="!isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.state'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.stateName !== entityAddress.stateName ? 'text-red-500' : ''">{{item.stateName}}</div>

                        </div>
                        <div class="grid col-12 -mt-5"  *ngIf="!isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.city'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.cityName !== entityAddress.cityName ? 'text-red-500' : ''">{{item.cityName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isPhysical && !isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.county'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.countyName !== entityAddress.countyName ? 'text-red-500' : ''">{{item.countyName}}</div>
                        </div>


                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.country'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.origOffshoreCtryDesigName !== entityAddress.origOffshoreCtryDesigName ? 'text-red-500' : ''">{{item.origOffshoreCtryDesigName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.state'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.origOffshoreStateDesigName !== entityAddress.origOffshoreStateDesigName ? 'text-red-500' : ''">{{item.origOffshoreStateDesigName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.quadrant'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.originOffshoreBlockV1 !== entityAddress.originOffshoreBlockV1 ? 'text-red-500' : ''">{{item.originOffshoreBlockV1}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.area'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.areaName !== entityAddress.areaName ? 'text-red-500' : ''">{{item.areaName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.fieldName'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.originOffshoreFieldName !== entityAddress.originOffshoreFieldName ? 'text-red-500' : ''">{{item.originOffshoreFieldName}}</div>
                        </div>
                        <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                            <div class="col-4 text-sm font-bold text-right">{{'addressComparation.water'| translate}}:</div>
                            <div class="col-8 text-sm" [ngClass]="item.waterBodyName !== entityAddress.waterBodyName ? 'text-red-500' : ''">{{item.waterBodyName}}</div>
                        </div>
                    </div>
                    <!-- Footer boton y paginator izquierdo -->
                    <div class="grid mt-2">
                        <button class="col-6 w-7rem ml-2 btn-row" (click)="openEntity(item.entityId)">{{'addressComparation.open'| translate}} {{leftTitle}}</button>
                        <label class="col-6 text-right" *ngIf="showCarousel">{{pageNumber}} of {{items.length}}</label>
                    </div>
                </ng-template>
            </p-carousel>
    </div>
    <!-- RIGHT -->
    <div class="col-12 xl:col-6 w-29rem h-18rem border-solid surface-border border-round-md shadow-6 ml-6 mt-3">
        <div class="w-full addressTitle">
            <h3 class="text-xl pl-2">{{rightTitle}}</h3>
        </div>
        <div class="grid w-23rem mt-2 pt-1 ml-6 ng-star-inserted">
            <div class="grid col-12">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.entityID'| translate :{entity: rightTitle} }}:</div>
                <div class="col-8 text-sm">{{entityId}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.country'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.countryName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.postalCode'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.zipCode}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.address'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.address1}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4"></div>
                <div class="col-8 text-sm">{{entityAddress.address2}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.state'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.stateName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="!isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.city'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.cityName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isPhysical && !isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.county'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.countyName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.country'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.origOffshoreCtryDesigName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.state'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.origOffshoreStateDesigName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.quadrant'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.originOffshoreBlockV1}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.area'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.areaName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.fieldName'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.originOffshoreFieldName}}</div>
            </div>
            <div class="grid col-12 -mt-5" *ngIf="isOffshore">
                <div class="col-4 text-sm font-bold text-right">{{'addressComparation.water'| translate}}:</div>
                <div class="col-8 text-sm">{{entityAddress.areaName}}</div>
            </div>
        </div>
    </div>
</div>
<!-- ACTIONS-->
<div class="grid mt-4">
    <div class="col-6 text-right">
        <button class="btn-row w-12rem" (click)="importAddress()" [disabled]="isLock">{{'addressComparation.import'| translate:{entity1: leftTitle, entity2: rightTitle} }}</button>
    </div>

    <div class="col-6">
        <button (click)="close()" class="btn-row" [disabled]="isLock">{{'addressComparation.notChange'| translate}}</button>
    </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { SystemNotification } from 'src/app/core/models/system-notification';
import { EMPTY_SN, NotificationApiService } from 'src/app/core/services/notification-api.service';

@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.scss']
})

export class NotificationModalComponent implements OnInit, OnDestroy {
    serverNotificationList: Array<SystemNotification> = EMPTY_SN;
    clientNotificationList: Array<SystemNotification> = EMPTY_SN;
    private notificationchange: Subscription;

    constructor(
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public translate: TranslateService,
        private notificationService: NotificationApiService) {
    }

    ngOnInit(): void {
        this.notificationchange = this.notificationService.notificationChange$
            .subscribe((notList: Array<SystemNotification>) => { 
                 this.clientNotificationList = notList.filter(n => !n.server);
                 this.serverNotificationList = notList.filter(n => n.server);
            });
    }

    closeModal() {
        this.activeModal.close()
    }

    ngOnDestroy(): void {
        this.notificationchange.unsubscribe();
    }

    clearClientNotifications(): void {
        this.notificationService.clearNotifications(true);
    }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Constants } from 'src/app/core/models/constants';
import { WebsiteService } from 'src/app/core/services/website.service';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnChanges {
    isVisible: boolean = false;
    warningImage: string;
    messageEmailStatus: string;
    allowOverride: boolean;

    @Input() emailAddress: string;
    @Input() marketingEmailStatus: string;
    @Input() marketingEmailOverrideStatus: string;
    @Input() marketingOverrideDate: string;
    @Input() marketingOverridePerson: string;
    @Input() modalIcon: boolean = false;

    constructor(public datePipe: DatePipe, private website: WebsiteService) { }

    ngOnChanges() {
        this.emailVerification({
            marketingEmailStatus: this.marketingEmailStatus,
            marketingEmailOverrideStatus: this.marketingEmailOverrideStatus,
            marketingOverrideDate: this.marketingOverrideDate,
            marketingOverridePerson: this.marketingOverridePerson
        });
    }

    // B = Bad, F = Filtered, G = Good
    emailVerification(statusInfo: any) {
        const marketingEmailStatus = statusInfo.marketingEmailStatus;
        const marketingEmailOverrideStatus = statusInfo.marketingEmailOverrideStatus;

        this.isVisible = (marketingEmailStatus == 'B' || marketingEmailStatus == 'F' || marketingEmailOverrideStatus == 'G') ? true : false;

        if (marketingEmailStatus === 'B' && marketingEmailOverrideStatus === 'G') {
            this.warningImage = 'green-warning-triangle';
            const mOverrideDate = this.datePipe.transform(statusInfo.marketingOverrideDate, 'dd-MMMM-y');
            const mOverridePerson = statusInfo.marketingOverridePerson;
            this.messageEmailStatus = Constants.replace(Constants.MESSAGE_MARKETING_EMAIL_OVERRIDE_STATUS_GOOD, [mOverrideDate, mOverridePerson]);
            this.allowOverride = true;
        } else if (marketingEmailStatus === 'B') {
            this.warningImage = 'invalid-warning';
            this.messageEmailStatus = Constants.MESSAGE_MARKETING_EMAIL_STATUS_BAD;
            this.allowOverride = true;
        } else if (marketingEmailStatus === 'F') {
            this.warningImage = 'red-warning-triangle';
            this.messageEmailStatus = Constants.MESSAGE_MARKETING_EMAIL_STATUS_FILTERED;
            this.allowOverride = false;
        } else {
            this.messageEmailStatus = '';
            this.warningImage = '';
        }
    }

    showOverride(): boolean {
        if(this.allowOverride)
        {
            this.website.goToWebsite(`emailOverride/overrideBadEmail.jsp?fromEd=true&emailAddress=${this.emailAddress}`, "overrideBadEmail");
        }
        
        return false;
    }

    allowOverrideToUri() {
        return this.allowOverride;
    }

}

import { INote } from "src/app/core/interfaces/inotes";
import { ContactsComments } from "../save/contacts-comments";

export class ContactsCommentsDisplay extends ContactsComments implements INote {
    createUserName: string;

    private constructor(entity?: any) {
        super(entity);

        this.createUserName = entity?.createUserName ?? null;
    }

    static CreateInstance(entity: any): ContactsCommentsDisplay {
        return new ContactsCommentsDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<ContactsCommentsDisplay> {
        return entity.map(item => ContactsCommentsDisplay.CreateInstance(item));
    }

    equals(ccd: ContactsCommentsDisplay) {
        return ccd.getOperation() === this.getOperation()
            && ccd.mvOrder === this.mvOrder
            && ccd.mvId === this.mvId
            && ccd.createDate === this.createDate
            && ccd.createUserName === this.createUserName
            && ccd.comments === this.comments;
    }

    get mainEntityId(): number {
        return this.contactId;
    }

    set mainEntityId(value: number) {
        this.contactId = value;
    }

    get notesSaleFlag(): string { return ''; }

    set notesSaleFlag(value: string) {}

    get notesSaleFlagBool(): boolean { return false; }

    set notesSaleFlagBool(value: boolean) {}
}

import { Bean } from "../../../bean";
import { Contacts } from "../../../contact/save/contacts";
import { AssetsCompanyInfo } from "./assets-company-info";
import { AssetsContactInfo } from "./assets-contact-info";
import { AssetsInternalAttachments } from "./assets-internal-attachments";
import { AssetsMaintenanceBudget } from "./assets-maintenance-budget";
import { AssetsNotes } from "./assets-notes";
import { AssetsProducts } from "./assets-products";
import { AssetsProjects } from "./assets-projects";

export abstract class Assets extends Bean {
    assetId: number;
    assetTypeId: number;
    assetName: string;
    operatorId: number;
    originPhysAddressV1: string;
    originPhysAddressV2: string;
    originPhysCity: number;
    originPhysState: number;
    originPhysPostalCode: string;
    originPhysCounty: number;
    originPhysCountry: number;
    originLatitude: number;
    originLongitude: number;
    originLatLonVerified: string;
    origOffshoreStateDesignation: number;
    originAssetId: number;
    originAssetTypeId: number;
    originOffshoreBlockV1: string;
    originOffshoreBlockV2: string;
    originOffshoreFieldName: string;
    originOffshoreWaterbodyId: number;
    originOffshoreFieldId: number;
    originOffshoreAreaId: number;
    originOffshore: boolean;
    recordStatus: string;
    startupDate: string;
    startupDateResolution: number;
    shutdownDate: string;
    shutdownDateResolution: number;
    operationalDate: string;
    operationalDateResolution: number;
    completionDate: string;
    completionDateResolution: number;
    phoneCc: number;
    phoneNo: string;
    phoneExt: string;
    phoneMobile: string;
    faxCc: number;
    faxNo: string;
    faxExt: string;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    liveDate: string;
    releaseDate: string;
    releaseUser: string;
    amendmentDate: string;
    amendmentUser: string;
    initialCapitalInvestment: number;
    initialCapitalCurrency: string;

    assetsContactInfo: Array<AssetsContactInfo>;
    assetsCompanyInfo: Array<AssetsCompanyInfo>;
    assetsProducts: Array<AssetsProducts>;
    assetsNotes: Array<AssetsNotes>;
    assetsInternalAttachments: Array<AssetsInternalAttachments>;
    assetsProjects: Array<AssetsProjects>;
    assetsMaintenanceBudget: Array<AssetsMaintenanceBudget>;
    contacts: Array<Contacts>;

    protected constructor(entity?: any) {
        super(entity);

        this.assetId = entity?.assetId ?? null;
        this.assetTypeId = entity?.assetTypeId ?? null;
        this.assetName = entity?.assetName ?? null;
        this.operatorId = entity?.operatorId ?? null;
        this.originPhysAddressV1 = entity?.originPhysAddressV1 ?? null;
        this.originPhysAddressV2 = entity?.originPhysAddressV2 ?? null;
        this.originPhysCity = entity?.originPhysCity ?? null;
        this.originPhysState = entity?.originPhysState ?? null;
        this.originPhysPostalCode = entity?.originPhysPostalCode ?? null;
        this.originPhysCounty = entity?.originPhysCounty ?? null;
        this.originPhysCountry = entity?.originPhysCountry ?? null;
        this.originLatitude = entity?.originLatitude ?? null;
        this.originLongitude = entity?.originLongitude ?? null;
        this.originLatLonVerified = entity?.originLatLonVerified ?? null;
        this.origOffshoreStateDesignation = entity?.origOffshoreStateDesignation ?? null;
        this.originAssetId = entity?.originAssetId ?? null;
        this.originAssetTypeId = entity?.originAssetTypeId ?? null;
        this.originOffshoreBlockV1 = entity?.originOffshoreBlockV1 ?? null;
        this.originOffshoreBlockV2 = entity?.originOffshoreBlockV2 ?? null;
        this.originOffshoreFieldName = entity?.originOffshoreFieldName ?? null;
        this.originOffshoreWaterbodyId = entity?.originOffshoreWaterbodyId ?? null;
        this.originOffshoreFieldId = entity?.originOffshoreFieldId ?? null;
        this.originOffshoreAreaId = entity?.originOffshoreAreaId ?? null;
        this.originOffshore = entity?.originOffshore ?? false;
        this.recordStatus = entity?.recordStatus ?? null;
        this.startupDate = entity?.startupDate ?? null;
        this.startupDateResolution = entity?.startupDateResolution ?? null;
        this.shutdownDate = entity?.shutdownDate ?? null;
        this.shutdownDateResolution = entity?.shutdownDateResolution ?? null;
        this.operationalDate = entity?.operationalDate ?? null;
        this.operationalDateResolution = entity?.operationalDateResolution ?? null;
        this.completionDate = entity?.completionDate ?? null;
        this.completionDateResolution = entity?.completionDateResolution ?? null;
        this.phoneCc = entity?.phoneCc ?? null;
        this.phoneNo = entity?.phoneNo ?? null;
        this.phoneExt = entity?.phoneExt ?? null;
        this.phoneMobile = entity?.phoneMobile ?? null;
        this.faxCc = entity?.faxCc ?? null;
        this.faxNo = entity?.faxNo ?? null;
        this.faxExt = entity?.faxExt ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.initialCapitalInvestment = entity?.initialCapitalInvestment ?? null;
        this.initialCapitalCurrency = entity?.initialCapitalCurrency ?? null;

        this.assetsContactInfo = entity?.assetsContactInfo ?? [];
        this.assetsCompanyInfo = entity?.assetsCompanyInfo ?? [];
        this.assetsProducts = entity?.assetsProducts ?? [];
        this.assetsNotes = entity?.assetsNotes ?? [];
        this.assetsInternalAttachments = entity?.assetsInternalAttachments ?? [];
        this.assetsProjects = entity?.assetsProjects ?? [];
        this.assetsMaintenanceBudget = entity?.assetsMaintenanceBudget ?? [];

        this.contacts = entity?.contacts ?? [];
    }
}

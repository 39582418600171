/* This is the TEST environment.  It is intended for non-optimized (easily debuggable) builds, deployed to a QA server */

import { baseEnvironment } from './environment.base'

// Start with the base environment, and override values here that are different for this one
const myEnvironment: Partial<typeof baseEnvironment> = {
   environmentName: 'test',
   apiUrl: `/NED/api/`,
   versionCheckIntervalMinutes: 3,
   serverNotificationCheckIntervalMinutes: 13,
   sendTelemetry: true,
};

// Export the effective evironment after applying our overrides
export const environment = {...baseEnvironment, ...myEnvironment};

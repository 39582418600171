import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class SiteSearch {
    constructor(
        public assetId: number,
        public siteName: string,
        public physCity: string,
        public physState: string,
        public physStateName: string,
        public recordStatus: string,
        public physCountry: string,
        public physCountryName: string,
        public companyName: string,
        public waterBody: string,
        public areaName: string,
        public fieldName: string,
        public recordStatusDesc: string,
        public qcDate: string
    ) { }
}


@Injectable({
    providedIn: 'root',
})
export class SiteSearchAdapter implements Adapter<SiteSearch> {
    adapt(item: any): SiteSearch {
        return new SiteSearch(
            item.SITE_ID || item.ASSET_ID,
            item.SITE_NAME || item.ASSET_NAME,
            item.PHYS_CITY || item.ORIGIN_PHYS_CITY,
            item.PHYS_STATE || item.ORIGIN_PHYS_STATE,
            item.PHYS_STATE_NAME || item.ORIGIN_PHYS_STATE_NAME,
            item.RECORD_STATUS,
            item.PHYS_COUNTRY || item.ORIGIN_PHYS_COUNTRY,
            item.PHYS_COUNTRY_NAME || item.ORIGIN_PHYS_COUNTRY_NAME,
            item.COMPANY_NAME,
            item.WATERBODY_NAME || item.ORIGIN_OFFSHORE_WATERBODY_NAME,
            item.AREA_NAME || item.ORIGIN_OFFSHORE_AREA_NAME,
            item.FIELD_NAME || item.ORIGIN_OFFSHORE_FIELD_NAME,
            item.RECORD_STATUS_DESC,
            item.QC_DATE 
        );
    }
}




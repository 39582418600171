import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EditableColumn } from 'primeng/table';
import { Subject, Subscription } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { AlertDelay, AlertDTO, AlertInfo, AlertType, EntityAlertHistory } from 'src/app/core/models/alerts';
import { OfflineEvent, Units } from 'src/app/core/models/common';
import { AlertKindValue, AlertStatusValue, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { SaveType } from 'src/app/core/models/enumerations/save-type';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { AlertService } from 'src/app/core/services/alert.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { UnitService } from 'src/app/core/services/unit.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { HeaderService } from 'src/app/shared/header/header.service';
import { AlertModalComponent } from '../../components/modals/alert-modal/alert-modal.component';
import { FocusableEvent } from 'src/app/core/directives/focusable-table.directive';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { LoadWheelService } from 'src/app/components/load-wheel/load-wheel.service';
import { PlantSectionService } from 'src/app/plant-section/plant-section.service';
import { BaseComponent } from 'src/app/base.component';
import { OfflineEventsDisplay } from 'src/app/core/models/offline-event/display/offline-event-display';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
    disabledButtons: boolean = false;
    alertsFormGroup: FormGroup;
    alertDelays: Array<AlertDelay> = [];
    alertTypes: Array<AlertType> = [];
    alertHistory: Array<EntityAlertHistory>;
    alertInfo: AlertInfo;
    loadedHistory: number;
    @Input() units: Array<Units>;
    offlineEvents: Array<OfflineEvent> = [];
    @Input() entityId: number;
    @Input() alertSubject: string = '';
    @Input() releaseDate: string;
    @Input() baseIndex: number;
    @Input() isOfflineEventAlert: boolean = false;
    @Input() entityName: string;

    @Input() offlineEventDisplay: OfflineEventsDisplay = null;

    tableEditEnabled: boolean = true;
    isEditing: boolean = false;
    cols: any[] = [];
    cellEditingPreviousValue: string;
    disabledSend: boolean = true;
    stateSendAlert: boolean = false;
    @ViewChildren(EditableColumn) private editableColumns: QueryList<EditableColumn>;

    alertsFocusManager = new Subject<FocusableEvent>();

    private discardEventSubscription: Subscription;
    private disableFormSub: Subscription;

    creating: boolean = false;
    createTooltip: string;
    createLabel: string;

    protected ALERT_STATUS_SCHEDULED = AlertStatusValue.Scheduled
    protected ALERT_STATUS_UNSCHEDULED = AlertStatusValue.Unscheduled
    protected ALERT_STATUS_COMPLETED = AlertStatusValue.Completed
    protected ALERT_STATUS_ERROR = AlertStatusValue.Error

    rowsPerPage: number = 10;
    tableKey: string;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService,
        private headerService: HeaderService,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        private messageHandlerService: MessageHandlerService,
        private alertService: AlertService,
        private translate: TranslateService,
        private session: SessionService,
        private dialogService: DialogService,
        private plantSectionService: PlantSectionService,
        private unitService: UnitService,
        public loadWheelService: LoadWheelService
    ) { super(recordLockingFlow, preferencesService) }

    ngOnInit() {
        this.tableKey = this.preferencesService.getPreferenceResourceName(this.entityName, EntityPreferenceSufix.Alerts);
        this.buildForm();
        this.loadSelectableData();
        this.cols = [
            { field: 'subject', header: this.translate.instant('alerts.history.subject'), editable: false, width: '16%' },
            { field: 'kindDesc', header: this.translate.instant('alerts.history.kind'), editable: false, width: '9%' },
            { field: 'type', header: this.translate.instant('alerts.history.type'), editable: false, width: '9%' },
            { field: 'entry', header: this.translate.instant('alerts.history.entered'), editable: false, width: '17%', isDate: true },
            { field: 'lastShceduled', header: this.translate.instant('alerts.history.lastShceduled'), editable: false, width: '17%', isDate: true },
            { field: 'lastSent', header: this.translate.instant('alerts.history.lastSent'), editable: false, width: '17%', isDate: true },
            { field: 'completeStatusDesc', header: this.translate.instant('alerts.history.status'), editable: false, width: '9%' },
            { field: 'action', header: this.translate.instant('alerts.history.action'), editable: false, width: '6%' },
        ];

        this.disableFields();
        this.setCreateLabels();

        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => {
                this.disableFields();
                this.tableEditEnabled = !disable;
                this.disabledButtons = disable;
            });

        this.discardEventSubscription = this.headerService.discardEvent$.subscribe(discard => {
            if (discard) {
                this.setCreateLabels();
                this.loadAlerts(this.entityId, true);
                this.clearFields();
                this.updateSendAlertButton();
                this.disableFields();
            }
        });

    }

    onPageChange(event): void {
        if(this.tableKey) {
            this.preferencesService.updatePreferenceOnPageChange(this.tableKey, String(event.rows));
        }
    }

    private disableFields() {
        this.alertsFormGroup.controls.alertSubject.disable();
        this.alertsFormGroup.controls.alertDelay.disable();
        this.alertsFormGroup.controls.alertType.disable();
        this.alertsFormGroup.controls.alertComments.disable();
        this.alertsFormGroup.controls.acPlantUnitsAlerts.disable();
        this.alertsFormGroup.controls.acUnitOfflineEventsAlerts.disable();
    }

    private enableFields() {
        this.alertsFormGroup.controls.alertSubject.enable();
        this.alertsFormGroup.controls.alertDelay.enable();
        this.alertsFormGroup.controls.alertType.enable();
        this.alertsFormGroup.controls.alertComments.enable();
        this.alertsFormGroup.controls.acPlantUnitsAlerts.enable();
        this.alertsFormGroup.controls.acUnitOfflineEventsAlerts.enable();
    }

    private loadSelectableData(): void {
        this.alertDelays = AlertDelay.getGenericList();
        this.loadAlertTypes();
    }

    private loadAlertTypes(): void {
        if (this.isOfflineEventAlert) {
            this.alertTypes = AlertType.getOfflineGenericList();
        } else {
            this.alertTypes = AlertType.getPlantGenericList();
        }
    }

    createDiscard(): void {
        this.creating ? this.discard() : this.create();
    }

    create(): void {
        this.creating = true;
        this.createTooltip = this.translate.instant('alerts.discardTooltip');
        this.createLabel = this.translate.instant('alerts.discard');
        if (this.isOfflineEventAlert) {
            this.alertsFormGroup.controls.alertSubject.setValue(
                this.alertService.getOfflineEventSubject(this.offlineEventDisplay));
            let plant = this.offlineEventDisplay.getFirstOfflineEventsPlantsDisplay();
            if (plant && plant.plantId) {
                this.loadAlertInfo(Number(plant.plantId));
            }
        } else {
            this.alertsFormGroup.controls.alertSubject.setValue(this.alertSubject);
            this.loadAlertInfo(this.entityId);
        }
        this.enableFields();
        this.updateSendAlertButton();
        this.alertsFormGroup.controls.alertDelay.setValue('0');
    }

    resetView(loadAlerts: boolean): void {
        this.setCreateLabels();
        if (loadAlerts) {
            this.loadAlerts(this.entityId, true);
        }
        this.clearFields();
        this.updateSendAlertButton();
        this.disableFields();
    }

    discard(): void {
        this.creating = false;
        this.resetView(false);
    }

    setCreateLabels(): void {
        this.createTooltip = this.translate.instant('alerts.createTooltip');
        this.createLabel = this.translate.instant('alerts.create');
    }

    send(): void {
        if (this.alertsFormGroup.controls.alertDelay.value === null) {
            this.messageHandlerService.show(this.translate.instant('alerts.requiredDelayBy'), MessageType.VALIDATION_ERROR);
        } else if (this.alertsFormGroup.controls.alertType.value === null) {
            this.messageHandlerService.show(this.translate.instant('alerts.requiredType'), MessageType.VALIDATION_ERROR);
        } else {
            const onConfirm = (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.OK) {
                    this.saveAlertInternal(true);
                }
            }
            this.messageHandlerService.show(this.translate.instant('alerts.savePending'), MessageType.CONFIRM_CANCEL, onConfirm);
        }
    }

    private saveAlertInternal(scheduleNow: boolean) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("alerts.sending"));
        const unitsIdsValues = this.alertsFormGroup.controls.acPlantUnitsAlerts.value;
        const offlineEventsIdsValues = this.alertsFormGroup.controls.acUnitOfflineEventsAlerts.value;
        this.alertService.saveAlert(new AlertDTO(
            this.isOfflineEventAlert ? AlertKindValue.OfflineEvent : AlertKindValue.PlantInfo,
            String(this.entityId),
            this.alertsFormGroup.controls.alertSubject.value,
            this.alertsFormGroup.controls.alertType.value,
            this.alertsFormGroup.controls.alertDelay.value,
            this.alertsFormGroup.controls.alertComments.value,
            this.session.getUserName(),
            unitsIdsValues && unitsIdsValues.length > 0 ? unitsIdsValues : [],
            offlineEventsIdsValues && offlineEventsIdsValues.length > 0 ? offlineEventsIdsValues : [],
            scheduleNow
        )).subscribe({
            next: response => {
                if (response.data !== null) {
                    this.resetView(scheduleNow);
                }
                this.loadWheelService.hideWheel(wheel);
            },
            error: (error) => {
                this.loadWheelService.hideWheel(wheel);
            }
        });
    }

    openAlertModal(row: EntityAlertHistory) {
        if (!this.disabledButtons) {
            const modalRef = this.dialogService.open(AlertModalComponent, {
                width: '60rem',
                data: {
                    id: row.id,
                    subject: row.subject,
                    comments: row.comments,
                    status: row.status,
                    iseScId: row.iseScId,
                    alertKind: this.isOfflineEventAlert ? AlertKindValue.OfflineEvent : AlertKindValue.PlantInfo,
                    entityName: EntityName.PLANT,
                    disabledElements: this.disabledButtons
                },
                dismissableMask: true,
                draggable: true,
                keepInViewport: true
            });
            modalRef.onClose.subscribe((refresh: boolean) => {
                if (refresh) {
                    this.loadAlerts(this.entityId, true);
                }
            });
        }
    }

    private updateSendAlertButton(): void {
        this.disabledSend = !(this.creating &&
            this.releaseDate !== null && !this.stateSendAlert);
    }

    retryAlert(row: EntityAlertHistory): void {
        const onConfirm = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("alerts.retrying"));
                this.alertService.retryAlert(row.iseScId, this.isOfflineEventAlert ? AlertKindValue.OfflineEvent : AlertKindValue.PlantInfo).subscribe({
                    next: response => {
                        if (response) {
                            this.loadAlerts(this.entityId, true);
                        }
                        this.loadWheelService.hideWheel(wheel);
                    },
                    error: (error) => {
                        this.loadWheelService.hideWheel(wheel);
                    }
                });
            }
        }
        this.messageHandlerService.show(this.translate.instant('alerts.history.retryConfirm'), MessageType.CONFIRM, onConfirm);
    }

    deleteAlert(row: EntityAlertHistory): void {
        const onConfirm = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("alerts.deleting"));
                this.alertService.deleteAlert(row.id, this.isOfflineEventAlert ? AlertKindValue.OfflineEvent : AlertKindValue.PlantInfo).subscribe({
                    next: response => {
                        if (response) {
                            this.loadAlerts(this.entityId, true);
                        }
                        this.loadWheelService.hideWheel(wheel);
                    },
                    error: (error) => {
                        this.loadWheelService.hideWheel(wheel);
                    }
                });
            }
        }
        this.messageHandlerService.show(this.translate.instant('alerts.history.deleteConfirm'), MessageType.CONFIRM, onConfirm);
    }

    ngOnDestroy() {
        this.discardEventSubscription.unsubscribe();
        this.disableFormSub.unsubscribe();
        this.alertService.endAlertTimer();
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

    private buildForm() {
        this.alertsFormGroup = this.fb.group({
            alertSubject: [''],
            alertDelay: [null],
            alertType: [null],
            alertComments: [''],
            acPlantUnitsAlerts: [''],
            acUnitOfflineEventsAlerts: ['']
        });
        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => disable ? this.alertsFormGroup.disable() : this.alertsFormGroup.enable());
    }

    resetForm() {
        this.alertsFormGroup.reset();
        this.resetChangedFields();
    }

    getTooltipText(): string {
        return this.alertInfo ?
            '<div style="display: block;">' +
            '<div>' + this.translate.instant('alerts.tooltip') + '</div>' +
            '<div>' +
            '<div style="text-align: right; display: inline-block;">' +
            '<b>' + this.translate.instant('alerts.rto') + ':</b><br/>' +
            '<b>' + this.translate.instant('alerts.nerc') + ':</b><br/>' +
            '<b>' + this.translate.instant('alerts.padd') + ':</b><br/>' +
            '<b>' + this.translate.instant('alerts.gas') + ':</b><br/>' +
            '<b>' + this.translate.instant('alerts.market') + ':</b><br/>' +
            '</div>' +
            '<div style="text-align: left; display: inline-block; padding-left: 7px">' +
            StringUtils.toStringNeverNull(this.alertInfo.rto) + '<br/>' +
            StringUtils.toStringNeverNull(this.alertInfo.nerc) + '<br/>' +
            StringUtils.toStringNeverNull(this.alertInfo.padd) + '<br/>' +
            StringUtils.toStringNeverNull(this.alertInfo.gas) + '<br/>' +
            StringUtils.toStringNeverNull(this.alertInfo.market) + '<br/>' +
            '</div>' +
            '</div>' +
            '</div>'
            : '';
    }

    clearFields() {
        this.alertsFormGroup.controls.alertSubject.setValue('');
        this.alertsFormGroup.controls.alertDelay.setValue(null);
        this.alertsFormGroup.controls.alertType.setValue(null);
        this.alertsFormGroup.controls.alertComments.setValue('');
        this.alertsFormGroup.controls.acPlantUnitsAlerts.setValue('');
        this.alertsFormGroup.controls.acUnitOfflineEventsAlerts.setValue('');
        this.offlineEvents = [];
        this.creating = false;
    }

    clearForm(): void {
        this.clearFields();
        this.alertHistory = [];
        this.loadedHistory = 0;
        this.resetView(false);
        this.alertService.endAlertTimer();
    }

    loadAlerts(entityId: number, showSpinner: boolean) {
        let wheel: SpinnerProcess;
        if (showSpinner) {
            wheel = this.loadWheelService.showWheel(this.translate.instant("alerts.loading"));
        }
        this.alertService.getAlertHistoryInfo(entityId, this.isOfflineEventAlert ? AlertKindValue.OfflineEvent : AlertKindValue.PlantInfo)
            .subscribe({
                next: (alerts: EntityAlertHistory[]) => {
                    this.alertHistory = alerts;
                    this.alertService.initAlertTimer(this.alertHistory, this, entityId);
                    this.loadedHistory = this.entityId;
                    this.stateSendAlert = this.alertHistory.some(his => (his.statusDesc === AlertStatusValue.Queued
                        || his.statusDesc === AlertStatusValue.Scheduled ||
                        his.statusDesc === AlertStatusValue.Unscheduled));
                    this.loadWheelService.hideWheel(wheel);
                },
                error: (error) => {
                    this.stateSendAlert = false;
                    this.loadWheelService.hideWheel(wheel);
                }
            });
    }

    loadAlertInfo(entityId: number) {
        this.plantSectionService.getAlertInfo(entityId)
            .subscribe((info: AlertInfo) => this.alertInfo = info);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.isNullOrEmpty(this.entityId) &&
            this.loadedHistory !== this.entityId) {
            this.loadAlerts(this.entityId, true);
        }
        if(changes.preferences && this.tableKey && this.preferences) {
            this.rowsPerPage = this.preferencesService.getPaginatorPreference(this.preferences, this.tableKey, this.rowsPerPage);
        }
    }

    onUnitChange(): void {
        this.offlineEvents = [];
        if (this.alertsFormGroup.controls.acPlantUnitsAlerts.value !== ''
            && this.alertsFormGroup.controls.acPlantUnitsAlerts.value !== null) {
            this.unitService.offlineEventsByUnitIds(
                this.alertsFormGroup.controls.acPlantUnitsAlerts.value
            ).subscribe(offlineEvents => this.offlineEvents = offlineEvents);
        }
    }

    public validateSaveAlerts(): string {
        if (this.creating && this.alertsFormGroup.controls.alertDelay.value === null) {
            return this.translate.instant('alerts.requiredDelayBy') + '<br>';
        } else if (this.creating && this.alertsFormGroup.controls.alertType.value === null) {
            return this.translate.instant('alerts.requiredType') + '<br>';
        }
        return '';
    }

    public saveAlert(saveType: number): void {
        if ((saveType === SaveType.TYPE_QC || saveType === SaveType.UPDATE)
            && this.creating && this.alertsFormGroup.controls.alertType.value !== null) {
            if (!this.isOfflineEventAlert) {
                this.alertsFormGroup.controls.alertDelay.setValue(
                    (this.alertsFormGroup.controls.alertDelay.value !== null ?
                        Number(this.alertsFormGroup.controls.alertDelay.value) : 0) + 10);
            }
            this.saveAlertInternal(false);
        } else {
            this.resetView(this.entityId != null);
        }

    }

}


import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
// Modal Dialog
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

// Models
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LoadWheelService } from 'src/app/components/load-wheel/load-wheel.service';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { BaseModalComponent } from 'src/app/components/modals/base-modals.component';
import { Constants, EntityPreferenceSufix, PreferencePrefix } from 'src/app/core/models/constants';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { ColumnEntityInfo, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { SearchEntityModalService } from './search-entity-modal.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';

@Component({
    selector: 'app-search-entity-modal',
    templateUrl: './search-entity-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchEntityModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
    @ViewChild('mvtTableSelector') mvtTableSelector: MVTEntityAssociatorComponent;
    @ViewChildren('entityGenericInput') entityGenericInput: QueryList<ElementRef>;

    entityTitle: string = '';
    entityTitlePlural: string = '';
    entitiesArray: Array<any> = [];
    isMainAccess: boolean = false;
    isMultiSelect: boolean = false;
    showNewRecordButton: boolean = true;
    enabledNewRecordButton: boolean = false;
    returnInputElementRef: ElementRef = null;
    returnInputEntityPropName: string = null;
    
    inputsModalFieldContainer: InputModalFieldContainer[];
    columnsInfo: ColumnEntityInfo[] = [];
    mvtTableKey = PreferencePrefix.Search + EntityPreferenceSufix.Entity;

    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected translate: TranslateService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected searchEntityModalService: SearchEntityModalService) {
        super(activeModal, config, preferencesService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.initPaginatorPreferences();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.isMultiSelect = this.config.data?.isMultiSelect !== undefined ? this.config.data?.isMultiSelect : false;
        
        this.loadInputsModalFieldContainer();
        this.loadColumnsInfo();
    }

    ngAfterViewInit() {
        this.clear();
        this.searchByParamsRecording();
        if(this.returnInputEntityPropName) {
            this.returnInputElementRef = this.entityGenericInput.find(el => el.nativeElement.id === this.returnInputEntityPropName);
        }     
    }

    protected loadInputsModalFieldContainer() {
        this.inputsModalFieldContainer = this.getInputModalFieldContainer();
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                inputModalField.searchEntityModalComponent = this;
                inputModalField.inputValue = null;
            });
        });
    }

    protected loadColumnsInfo() {
        this.columnsInfo = this.getColumnsInfo();
    }

    protected searchInputModalField(entityPropName: string): InputModalField {
        let result: InputModalField = null;
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                if(inputModalField.entityPropName === entityPropName) {
                    result = inputModalField;
                }
            });
        });
        return result;
    }

    protected getInputModalFieldContainer(): InputModalFieldContainer[] {return null;};
    protected getColumnsInfo(): ColumnEntityInfo[] {return null;};
    protected getAdditionalInfo(): ColumnEntityInfo[] {return null;};
    protected searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {return null;};


    search(event?: any) {
        this.searchEntities(this.getHttpParamsFromInputsModalField());
    }

    protected getHttpParamsFromInputsModalField(): HttpParams {
        let httpParams: HttpParams = new HttpParams();
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                httpParams = httpParams.set(inputModalField.entityPropName, inputModalField.inputValue != null ? inputModalField.inputValue : '');
            });
        });
        return httpParams;
    }

    protected searchByParamsRecording() {
        let savedParams: HttpParams = this.searchEntityModalService.getSavedParams(this.getClassName());
        this.setHttpParamsInInputValues(savedParams);

        if (this.entityUtilsService.httpParamsHasValues(savedParams)) {
            this.search();
        }
    }

    getClassName(): string {
        return this.constructor.name;
    }

    private setHttpParamsInInputValues(httpParams: HttpParams): void {
        if(!this.entityUtilsService.httpParamsHasValues(httpParams)) {
            return;
        }
        
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                if(httpParams.has(inputModalField.entityPropName) && !StringUtils.isEmptyInPrimitiveTypes(httpParams.get(inputModalField.entityPropName))) {
                    inputModalField.inputValue = httpParams.get(inputModalField.entityPropName);
                    if(inputModalField.editableType ===  InputModalFieldEditableType.dropdownField && !isNaN(inputModalField.inputValue)) {
                        inputModalField.inputValue = Number(inputModalField.inputValue);
                    }
                }
            });
        });
    }

    protected searchEntities(params: HttpParams) {
        this.entitiesArray = [];
        this.enabledNewRecordButton = false;
        this.searchEntityModalService.clearSavedParams(this.getClassName());

        if(!this.entityUtilsService.checkForValueRequired(params, null)){
            return;
        }

        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant('searchEntityModalComponent.searching') + ' ' + this.getEntityTitle());
        this.searchEntitiesDelegate(params, wheel)
            .subscribe({
                next: (searchedEntities: Array<any>) => {
                    if(searchedEntities === null) {
                        searchedEntities = [];
                    } else if (searchedEntities.length === 0) {
                        if(this.returnInputElementRef) {
                            this.messageHandler.showAlertAndReturnFocus(this.translate.instant("common.searchNotFound"), null, 
                                this.returnInputElementRef, false, false);
                        } else {
                            this.messageHandler.show(this.translate.instant("common.searchNotFound"), MessageType.INFO);
                        }
                        
                        this.enabledNewRecordButton = true;
                    } else {
                        this.enabledNewRecordButton = true;
                    }
                    
                    this.entitiesArray = searchedEntities;
                    if(this.entitiesArray.length > 0) {
                        this.focusFirstElement();
                        this.searchEntityModalService.saveParams(this.getClassName(), params);
                    }

                    this.loadWheelService.hideWheel(wheel);
                }, 
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(error.message);
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    override focusFirstElement() {
        setTimeout(() => {
            this.mvtTableSelector.setFocusToFirstRow();
        }, 0);
    }

    clearAll(): void {
        this.searchEntityModalService.clearSavedParams(this.getClassName());
        this.clear();
    }

    private clear(): void {
        this.clearInputsModalField();
        this.setFocusOnFirstInput();
        this.entitiesArray = [];
        this.enabledNewRecordButton = false;
    }

    clearInputsModalField() {
        this.inputsModalFieldContainer.forEach((inputModalFieldContainer: InputModalFieldContainer) => {
            inputModalFieldContainer.inputFieldsArray.forEach((inputModalField: InputModalField) => {
                inputModalField.inputValue = null;
            });
        });
    }

    setFocusOnFirstInput() {
        this.entityGenericInput?.find(el => el.nativeElement.autofocus)?.nativeElement?.focus()
    }

    onKeyDownEnter(rowData: any): void {
        this.onRowDblclick(this, rowData);
    }

    onRowDblclick(implementedComponent: SearchEntityModalComponent, rowData: any) {
        if (implementedComponent.isMainAccess) {
            implementedComponent.activeModal.close(rowData);
        }
    }

    public getSelectedEntities(): Array<any> {
        return (this.mvtTableSelector != null) ? this.mvtTableSelector.getSelectedEntities() : [];
    }

    hasSelectedEntities(): boolean {
        return this.getSelectedEntities().length > 0;
    }

    addSelectedEntities() {
        this.activeModal.close(this.getSelectedEntities());
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    protected getEntityTitle(): string {
        return this.translate.instant(this.entityTitle);
    }

    protected getEntityTitlePlural(): string {
        return this.translate.instant(this.entityTitlePlural);
    }

    protected showInputsFilters(): boolean {
        return this.inputsModalFieldContainer != null && this.inputsModalFieldContainer.length > 0;
    }

    public get inputModalFieldEditableType(): typeof InputModalFieldEditableType {
        return InputModalFieldEditableType;
    }

}

export class InputModalFieldContainer {
    
        cssClases?: string;
        inputFieldsArray: Array<InputModalField> = [];
    
        constructor(entity: InputModalFieldContainer) {
            this.cssClases = entity?.cssClases ?? null;
            this.inputFieldsArray = entity?.inputFieldsArray ?? null;

            if(this.cssClases == null) {
                this.cssClases = 'col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2';
            }
        }
    
        static CreateInstance(entity: InputModalFieldContainer): InputModalFieldContainer {
            return new InputModalFieldContainer(entity);
        }
}

export class InputModalField {

    searchEntityModalComponent?: SearchEntityModalComponent;
    entityPropName?: string;
    displayName?: string;
    editableType?: InputModalFieldEditableType;
    maxLength?: number = 80;
    maxDecimals?: number;
    maxValue?: object;
    dropdownValue?: string;
    dropdownLabel?: string;
    dropdownOptionsArray?: Array<any>;

    inputValue?: any;

    protected constructor(entity: InputModalField) {
        this.searchEntityModalComponent = entity?.searchEntityModalComponent ?? null;
        this.entityPropName = entity?.entityPropName ?? null;
        this.displayName = entity?.displayName ?? null;
        this.editableType = entity?.editableType ?? null;
        this.maxLength = entity?.maxLength ?? 80;
        this.maxDecimals = entity?.maxDecimals ?? null;
        this.maxValue = entity?.maxValue ?? null;
        this.dropdownValue = entity?.dropdownValue ?? null;
        this.dropdownLabel = entity?.dropdownLabel ?? null;
        this.dropdownOptionsArray = entity?.dropdownOptionsArray ?? null;
    }

    getMaxDate?(): Date {
        if(this.maxValue == null || !(this.maxValue instanceof Date)) {
            return null;
        }
        return this.maxValue as Date;
    }

    static CreateInstance(entity: InputModalField): InputModalField {
        return new InputModalField(entity);
    }

}

export enum InputModalFieldEditableType {
    textField,
    numberField,
    calendarField,
    dropdownField,
    checkboxField
}
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SubscriptionStatusValue } from 'src/app/core/models/constants';
import { UserDetail } from 'src/app/core/models/user-detail';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { StringUtils } from '../../../core/utils/string-utils';
import { BaseModalComponent } from '../base-modals.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';


@Component({
    selector: 'app-user-modal',
    templateUrl: './user-modal.component.html',
    styleUrls: ['user-modal.component.css']
})

export class UserModalComponent extends BaseModalComponent implements OnInit {
    userDetail: UserDetail;
    entityName: string;
    entityId: number | string;

    constructor(
        public registrationService: RegistrationService,
        public preferencesService: PreferencesSectionService,
        public config: DynamicDialogConfig,
        public activeModal: DynamicDialogRef
    ) { super(activeModal, config, preferencesService) }

    ngOnInit(): void {
        this.entityName = this.config.data?.entityName;
        this.entityId = this.config.data?.entityId;
        this.loadUserInfo();
    }

    private loadUserInfo(): void {
        this.userDetail = UserDetail.EMPTY;
        this.registrationService.getUserDetailInfo(this.config.data.user)
            .subscribe((userInfo: UserDetail) => {
                if(userInfo !== null)
                {
                   this.userDetail = userInfo;
                }
            });
    }

    isEnableForMailingTo(): boolean {
        return (!StringUtils.isEmptyInPrimitiveTypes(this.userDetail.emailAddress) && this.userDetail.subscriptionStatus == SubscriptionStatusValue.Active);
    }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
// Services
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private session: SessionService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(req.url.startsWith(environment.apiUrl))
      {
         const token = this.session.getAttribute(SessionAttribute.ServerAuthToken)
         if(token !== null)
         {
            req = req.clone({
               setHeaders: {
                  Authorization: `Bearer ${token}`
               }
            });
         }
      }
      return next.handle(req);
    }
}
<div class="container-modal">
    <p-messages></p-messages>
    <div *ngIf="this.showInputsFilters()" class="Ex-content-modal">
        <div class="grid pt-1">
            <div class="col-1 min-w-max">
                <button [tabindex]="baseIndex + 0" 
                    (keydown)="focusCloseButton($event, true)"
                    (click)="clearAll()" 
                    class="btn-custom">{{'clear'| translate}}</button>
            </div>
        </div>
        <div class="mt-3">
            <form>
                <div class="grid">
                    <div *ngFor="let inputModalFieldContainer of inputsModalFieldContainer;  index as inputContainerIndex" [class]="inputModalFieldContainer.cssClases">
                        <div *ngFor="let inputModalField of inputModalFieldContainer.inputFieldsArray; index as inputIndex" class="flex -mt-3 pt-1 align-items-center">
                            <label class="col-5 flex justify-content-end px-0 font-bold text-xs mt-1">{{inputModalField.displayName | translate}}:</label>
                            <div class="col-6 flex px-1">
                                <input
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.numberField"
                                    type="text"
                                    class="form__input-field-style w-full"
                                    pInputText
                                    #entityGenericInput
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    [tabindex]="baseIndex + ((inputContainerIndex + 1) * (inputIndex + 1))"
                                    (keydown.enter)="search()"
                                    [maxlength]="inputModalField.maxLength"
                                    [maxwhole]="inputModalField.maxLength"
                                    appOnlyNumbers [maxdecimal]="inputModalField.maxDecimals?.toString()"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName">
                                <input
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.textField"
                                    type="text"
                                    #entityGenericInput
                                    class="form__input-field-style w-full"
                                    pInputText
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    [tabindex]="baseIndex + ((inputContainerIndex + 1) * (inputIndex + 1))"
                                    (keydown.enter)="search()"
                                    [maxlength]="inputModalField.maxLength"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName">
                                <p-calendar
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.calendarField"
                                    class="form__input-field-style w-full"
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    [tabindex]="baseIndex + ((inputContainerIndex + 1) * (inputIndex + 1))"
                                    (keydown.enter)="search()"
                                    dateFormat="dd-M-yy"
                                    showButtonBar="true"
                                    inputStyleClass="calendar-inputtext"
                                    [maxDate]="inputModalField.getMaxDate()"
                                    [showIcon]="true"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName">
                                </p-calendar>
                                <p-dropdown
                                    *ngIf="inputModalField.editableType == inputModalFieldEditableType.dropdownField"
                                    class="form__container-fields-layout--selects w-full"
                                    [name]="inputModalField.entityPropName"
                                    [(ngModel)]="inputModalField.inputValue"
                                    [tabindex]="baseIndex + ((inputContainerIndex + 1) * (inputIndex + 1))"
                                    (keydown.enter)="search()"
                                    [options]="inputModalField.dropdownOptionsArray"
                                    [optionValue]="inputModalField.dropdownValue"
                                    [optionLabel]="inputModalField.dropdownLabel"
                                    showClear="true"
                                    [filter]="true"
                                    [placeholder]="'common.selectOne' | translate"
                                    [autofocus]="inputContainerIndex === 0 && inputIndex === 0 ? true : false"
                                    [attr.id]="inputModalField.entityPropName">
                                </p-dropdown>
                            </div>
                            <div class="col-1 px-0"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <hr *ngIf="this.showInputsFilters()" class="hr-modal">
    
    <div class="container-info-modal">
        <div class="grid main-content">
            <div class="col-6 text-xs" *ngIf="!isMultiSelect">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <strong>{{'common.mouseUsers'| translate}}: </strong> 
                <label class="custom-label">
                    {{'common.searchMouseUsers'| translate}}
                </label>
            </div>
            <div class="col-6 text-xs" *ngIf="!isMultiSelect">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <strong>{{'common.keyboardUsers' | translate}}: </strong>
                <label class="custom-label">
                    {{'common.searchKeyboardUsers'| translate }}
                </label>
            </div>
            <div class="col-12" *ngIf="isMultiSelect">
                <span class="text-xs">{{ 'searchEntityModalComponent.multiSelectSubtitle'| translate:{entityName: getEntityTitle(), entityNamePlural: getEntityTitlePlural()} }}</span>
            </div>
        </div>
    </div>
    <div class="container-table-modal">

        <app-base-mvt-table-selector
            #mvtTableSelector
            [editable]="true"
            [disabledButton]="true"
            
            [columnsInfo]="this.columnsInfo"
            [entitiesArray]="entitiesArray"
            [tKeyComponentTitle]="''"
            [componentDescription]="''"
            [emptyMessage]="'common.noDataTable' | translate"
            [showAddRowButton]="false"

            [showPaginator]="true"
            [tableKey]="mvtTableKey"
            [useCommonPreference] = "true"
            [defaultRowsPerPage]="rowsPerPage"

            [sortingMode]="'multiple'"
            [selectionMode]="isMultiSelect ? 'multiple no check all' : 'none'"
            [highlightSeleccion]="true"
            [onRowDoubleClick]="onRowDblclick"
            [clearSelectedRowOnPageChange]="false"
            
            [additionalInfoFields]="getAdditionalInfo()"

            [implementedComponent]="this"
            [baseIndex]="baseIndex + 100">
    </app-base-mvt-table-selector>

        
    </div>
    
    <div *ngIf="!isMainAccess && isMultiSelect">
        <div class="grid mt-2">
            <div class="col-6 text-left">
                <label class="text-sm">{{'common.records'| translate}}{{entitiesArray.length}}</label>
            </div>
            <div class="col-6 text-right">
                <button [disabled]="!this.hasSelectedEntities()" class="btn-row" style="width: auto;"
                    [tabindex]="baseIndex + 120"
                    (keydown)="focusCloseButton($event)"
                    (click)="addSelectedEntities()">
                    {{'common.addItems'| translate}}
                  </button>
            </div>
        </div>
    </div>

    <div *ngIf="isMainAccess && showNewRecordButton" class="grid pt-3">
        <div class="col-12 lg:col-11 xl:col-11 flex justify-content-end">
            <label class="custom-label mt-2 lg:mr-2 xl:-mr-1">{{ 'searchEntityModalComponent.infoNewRecord'| translate:{entityName: getEntityTitle()} }}
                <strong> {{'common.notDuplicate'| translate}}</strong>
            </label>
        </div>
        <div class="col-12 lg:col-1 xl:col-1 flex justify-content-end">
            <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}"
                class="col-4 btn-custom h-2rem" label="Create New Record"
                [tabindex]="baseIndex + 120"
                (keydown.Tab)="focusCloseButton($event)"
                (click)="newRecord()">{{'common.newRecordButtonText'| translate}}
            </button>
        </div>
    </div>
    <div *ngIf="!showNewRecordButton" class="h-3rem"></div>

import { Bean } from "../../bean";
import { Contacts } from "../../contact/save/contacts";
import { PlantsLTSAContactInfo } from "./plants-ltsa-contact-info";
import { PlantsLTSANotes } from "./plants-ltsa-notes";
import { PlantsLTSASubTypes } from "./plants-ltsa-sub-types";
import { PlantsLTSATrades } from "./plants-ltsa-trades";
import { PlantsLTSATypes } from "./plants-ltsa-types";

export class PlantsLTSA extends Bean {
    plantLtsaId: number;
    plantId: number;
    ltsaStatusId: string;
    companyId: number;
    personnelCount: number;
    personnelCountHigh: number;
    contractLength: string;
    preferredContractor: string;
    contractExpiration: string;
    contractExpirationResolution: string;
    entryUser: string;
    entryDate: string;
    lastUser: string;
    lastDate: string;
    qcUser: string;
    qcDate: string;
    prevUser: string;
    prevDate: string;
    amendmentDate: string;
    amendmentUser: string;
    liveDate: string;
    author: number;
    entryCompany: string;
    lastCompany: string;
    prevCompany: string;
    releaseDate: string;
    releaseUser: string;
    recordStatus: string;

    plantsLtsaContactInfo: Array<PlantsLTSAContactInfo>;
    plantsLtsaNotes: Array<PlantsLTSANotes>;
    plantsLtsaTypes: Array<PlantsLTSATypes>;
    plantsLtsaSubTypes: Array<PlantsLTSASubTypes>;
    plantsLtsaTrades: Array<PlantsLTSATrades>;
    contacts: Array<Contacts>;

    constructor(entity?: any) {
        super(entity);
        this.plantLtsaId = entity?.plantLtsaId ?? null;
        this.plantId = entity?.plantId ?? null;
        this.ltsaStatusId = entity?.ltsaStatusId ?? null;
        this.personnelCount = entity?.personnelCount ?? null;
        this.personnelCountHigh = entity?.personnelCountHigh ?? null;
        this.companyId = entity?.companyId ?? null;
        this.contractLength = entity?.contractLength ?? null;
        this.contractExpiration = entity?.contractExpiration ?? null;
        this.contractExpirationResolution = entity?.contractExpirationResolution ?? null;
        this.preferredContractor = entity?.preferredContractor ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.author = entity?.author ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.recordStatus = entity?.recordStatus ?? null;

        this.plantsLtsaContactInfo = entity?.plantsLtsaContactInfo ?? [];
        this.plantsLtsaNotes = entity?.plantsLtsaNotes ?? [];
        this.plantsLtsaTypes = entity?.plantsLtsaTypes ?? [];
        this.plantsLtsaSubTypes = entity?.plantsLtsaSubTypes ?? [];
        this.plantsLtsaTrades = entity?.plantsLtsaTrades ?? [];
        this.contacts = entity?.contacts ?? [];
    }


}


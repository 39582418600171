import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthAction, AuthService } from "src/app/auth/auth.service";
import { NotificationModalComponent } from 'src/app/components/modals/notification-modal/notification-modal.component';
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { environment } from "src/environments/environment";
import { SystemNotification } from "../models/system-notification";
import { BehaviorSubject, Observable, catchError, interval, map, of } from 'rxjs';
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { ApiEndpoint } from "../models/constants";
import { HttpClient } from "@angular/common/http";
import { Cacheable, LocalStorageStrategy } from "ts-cacheable";
import { TelemetryService } from "./telemetry.service";

export const EMPTY_SN = new Array<SystemNotification>();

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
   
   private static readonly SN_CHECK_INTERVAL_MS = NotificationApiService.safeSNCI(environment.serverNotificationCheckIntervalMinutes * 60 * 1000);
   
   private readonly notificationSubject$: BehaviorSubject<Array<SystemNotification>>;
   readonly notificationChange$: Observable<Array<SystemNotification>>;
   
   private anyUnreadNotification: boolean = false;

   constructor(private http: HttpClient,
      private authService: AuthService,
      private session: SessionService,
      private dialogService: DialogService,
      private translate: TranslateService,
      private telemetry: TelemetryService,
      public messageHandlerService: MessageHandlerService) {

      this.anyUnreadNotification = this.session.getAttribute(SessionAttribute.UnreadNotifications);

      this.notificationSubject$ = new BehaviorSubject<Array<SystemNotification>>(this.getSavedNotifications());
      this.notificationChange$ = this.notificationSubject$.asObservable();

      this.authService.loginChange$.subscribe((action: AuthAction) => {
         if (action === AuthAction.LOGIN || action === AuthAction.LOGOUT) {
            this.clearNotifications(false);
         }

         if (action === AuthAction.LOGIN) {
            this.updateServerNotifications();
         }
      });

      this.session.attributeChange$.subscribe((csa) => {
         if (csa && csa.name === SessionAttribute.NotificationsUpdated && csa.newValue && csa.otherWindow) {
            // Another window updated the notifications list, propagate that change to this one
            this.notificationSubject$.next(this.getSavedNotifications());
         }

         if(csa && csa.name === SessionAttribute.UnreadNotifications)
         {
            this.anyUnreadNotification = csa.newValue;
         }
      });
      
      interval(NotificationApiService.SN_CHECK_INTERVAL_MS).subscribe(() => {
          if(this.authService.isLoggedIn())
          {
             this.updateServerNotifications();
          }
      });
   }
   
   private static safeSNCI(millis: number)
   {
      // Sanity check to prevent going full ham with the notification checks
      return millis < 30000 ? 30000 : millis;
   }

   public notify(title: string, notification: SystemNotification, doToast: boolean = true) {
      
      if(notification !== null) {
         const notifications: SystemNotification[] = this.getSavedNotifications();
         notifications.unshift(notification);
         this.saveNotifications(notifications);
         this.session.setAttribute(SessionAttribute.UnreadNotifications, true);

         if(doToast)
         {
            this.browserNotification(title, notification.message);
         }
      }

   }
   
   // Use the browser Notifications API to actually show a popup/toast/whatever 
   private browserNotification(title: string = "", message: string): void
   {
      if (("Notification" in window)) {
         if (Notification.permission !== 'denied') {
            const options = { body: message, icon: 'assets/icons/icon-96x96.png' }
            if (Notification.permission === "granted") {
               new Notification(title, options);
            } else {
               Notification.requestPermission(function(permission) {
                  if (permission === "granted") {
                     new Notification(title, options);
                  }
               });
            }
         }
      }
   } 

   public activateNotifications() {
      if (("Notification" in window)
         && (Notification.permission === 'denied' ||
            Notification.permission !== "granted")) {
         Notification.requestPermission();
      }
   }

   private getSavedNotifications(): SystemNotification[] {
      try {
         return JSON.parse(localStorage.getItem(environment.notificationsKey + this.session.getUserName())) ?? EMPTY_SN;
      } catch (ex) {
         console.warn("Cannot parse stored notification data");
         return EMPTY_SN;
      }
   }

   private saveNotifications(notifications: SystemNotification[]): void {
      localStorage.setItem(environment.notificationsKey + this.session.getUserName(), JSON.stringify(notifications));
      this.notificationSubject$.next(notifications);

      // Let other instances know about this
      this.session.setAttribute(SessionAttribute.NotificationsUpdated, true);
      this.session.removeAttribute(SessionAttribute.NotificationsUpdated);
   }

   public clearNotifications(keepServer: boolean): void {
      const remainingNotifications: SystemNotification[] = keepServer ? this.getSavedNotifications().filter(n => n.server) : EMPTY_SN;
      this.saveNotifications(remainingNotifications);
      this.session.setAttribute(SessionAttribute.UnreadNotifications, false); 
   }

   private updateServerNotifications(): void {
      this.fetchServerNotifications().subscribe((serverNotifications: Array<SystemNotification>) => {
         let notifications: SystemNotification[] = this.getSavedNotifications();
         
         const knownServerNotifications = notifications.filter(n => n.server);  // Hang on to a copy of the server messages for later reference
         
         // Replace existing server messges with the newly received ones
         notifications = notifications.filter(n => !n.server);
         notifications.unshift(...serverNotifications);
         this.saveNotifications(notifications);
         
         // Notify user if there were any newly discovered server messages
         const newCount = serverNotifications.filter(n => !knownServerNotifications.some(k => k.message === n.message)).length;
         if(newCount > 0)
         {
            const grammarIsAre = newCount === 1 ? "is" : "are";
            const grammarMsg = newCount === 1 ? "message" : "messages";  
            this.browserNotification("System Message", `There ${grammarIsAre} ${newCount} important system ${grammarMsg}`);
            this.session.setAttribute(SessionAttribute.UnreadNotifications, true); 
         }
      });
   }
   
   public hasUnreadNotification(): boolean {
      return this.anyUnreadNotification;
   }
   
   public showNotifications() {
      const modalRef = this.dialogService.open(NotificationModalComponent, {
         header:  this.translate.instant('common.notificationList'),
         width: '90rem',
         data: {},
         dismissableMask: true,
         draggable: true,
         keepInViewport: true
      });
      modalRef.onClose.subscribe(() => {});
      
      this.session.setAttribute(SessionAttribute.UnreadNotifications, false);
      this.telemetry.featureUsage("showNotifications");
   }


   @Cacheable({
      storageStrategy: LocalStorageStrategy,
      maxAge: (NotificationApiService.SN_CHECK_INTERVAL_MS) - 1000,
      slidingExpiration: false
   })
   private fetchServerNotifications(): Observable<Array<SystemNotification>> {
      return this.http.get<string>(environment.apiUrl + ApiEndpoint.Notifications)
         .pipe(
            map((data: any) => SystemNotification.BuildSystemNotification(data.response)),
            catchError((err) => {
               console.warn(`Could not load notifications from server: (${err.status}) ${err.message}`);
               return of(EMPTY_SN);
            })
         );
   }

}

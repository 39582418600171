import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseComponent } from 'src/app/base.component';
import { Constants } from 'src/app/core/models/constants';
import { EntityNameTable, EntityNameToDropClearLock } from 'src/app/core/models/enumerations/entity-name-clear-lock-drop';
import { LockMode } from 'src/app/core/models/enumerations/lock-mode';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { ClearLockUtilityDisplay } from 'src/app/core/record-locking/clear-lock-utility-display';
import { LockDetails } from 'src/app/core/record-locking/lock-details';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { RecordLockingService } from 'src/app/core/record-locking/record-locking.service';
import { CellContainerService } from 'src/app/core/services/cell-container.service';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageEvent, MessageResponse, MessageType } from '../../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { ViewCellContainerComponent } from '../view-cell-container/view-cell-container.component';
import { TelemetryService } from 'src/app/core/services/telemetry.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Role } from 'src/app/core/models/enumerations/role';

@Component({
  selector: 'app-clear-lock-utility',
  templateUrl: './clear-lock-utility.component.html',
  styleUrl: './clear-lock-utility.component.scss'
})
export class ClearLockUtilityComponent extends BaseComponent implements OnInit {
    @ViewChild('inputEntityId', { static: false }) inputEntityId: ElementRef;

    entities: any[] = [];
    clearLockUtility:any;
    formGroup:FormGroup;
    lockedItem: LockDetails;
    messageInRedValidationFirst: string;
    messageInRedValidationLast: string;
    lockHolderNameLink: any;
    hasAdminRole: boolean = false;

    constructor(
        private dialogService:DialogService,
        private authService: AuthService,
        public translate: TranslateService,
        public fb: FormBuilder,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        private recordLockingService:RecordLockingService,
        private messageHandlerService:MessageHandlerService,
        private loadWheelService:LoadWheelService,
        private telemetry: TelemetryService,
        public datePipe: DatePipe,
        private cellContainerService:CellContainerService  
    ){super(recordLockingFlow, preferencesService);}    

    ngOnInit(): void {
        this.authService.isUserInRole(Role.EDIS_ADMIN).subscribe(b => this.hasAdminRole = b);
        this.buildForm();
        this.loadEntitiesRelease();
        this.telemetry.featureUsage("clearLockUtility");
    }

    private buildForm() {
        this.formGroup = this.fb.group({
            entityId: [null, Validators.required],
            entityName: [null, Validators.required],
        });
    }

    private loadEntitiesRelease() {
        this.entities = Object.values(EntityNameToDropClearLock).map((value, index) => ({
            name: value,
            code: index
        }));
    }

    reLoad():void {
        this.searchForRecord();
    }

    onChangeEntityName(event): void {
        this.formGroup.controls['entityId'].setValue(null);
        this.resetScreen();
    }

    clearLock(): void {
        const lockID = this.clearLockUtility?.lockDetails?.lockID;
        let arrayLockIDs = [];
        arrayLockIDs.push(lockID);
        
        if (this.clearLockUtility?.lockDetails?.entityID) {
            const callbackFunction = (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.YES) {
                    this.recordLockingService.clearLock(arrayLockIDs).subscribe(() => {
                        this.messageHandlerService.show(Constants.MESSAGE_CLEAR_LOCK_OK, MessageType.INFO, (): void => {
                            this.reLoad();
                        });
                        this.telemetry.featureUsage("clearedLock");
                    });
                }
            }
              
            let msg:any = this.getLockErrorMessage(this.clearLockUtility?.lockDetails?.lockHolderName, this.clearLockUtility?.lockDetails?.lockMode, this.clearLockUtility?.lockDetails?.lockTime);
            const confirmMsg = Constants.replace(Constants.MESSAGE_EDIT_LOCK_RECORD, [msg]);
            this.messageHandlerService.show(confirmMsg, MessageType.CONFIRM, callbackFunction);
        }
    }

    openModalViewCellContainer():void {

        const data=null;
        const modalRef = this.dialogService.open(ViewCellContainerComponent, {
            header: this.translate.instant('clearLock.viewCellContainer'),
            width: '60rem',
            data: data,
            draggable: true,
            keepInViewport: true
        });
        this.cellContainerService.registerDialog(modalRef);
    }

    searchForRecord(): void {
        if (this.formGroup.controls['entityId'].value && this.formGroup.controls['entityName'].value) {
            const entityId = (this.formGroup.controls['entityId'].value).toString();
            const entityName = this.formGroup.controls['entityName'].value.name;
            
            this.getClearLockDetails(this.getEntityNameTable(entityName), entityName, entityId);
        }else{
            this.resetScreen();
        }
    }

    entityIdFocusOut(event: any) {
        if (!this.formGroup.controls['entityId'].value) {
            this.resetScreen();
        }
    }

    private getEntityNameTable(entityName: EntityNameToDropClearLock): EntityNameTable {
        const entityNameTableMap: { [key: string]: EntityNameTable } = {
            [EntityNameToDropClearLock.COMPANY]: EntityNameTable.COMPANY,
            [EntityNameToDropClearLock.PLANT]: EntityNameTable.PLANT,
            [EntityNameToDropClearLock.AREA]: EntityNameTable.AREA,
            [EntityNameToDropClearLock.UNIT]: EntityNameTable.UNIT,
            [EntityNameToDropClearLock.BOILER]: EntityNameTable.BOILER,
            [EntityNameToDropClearLock.DRIVE]: EntityNameTable.DRIVE,
            [EntityNameToDropClearLock.OFFLINE_EVENT]: EntityNameTable.OFFLINE_EVENT,
            [EntityNameToDropClearLock.PROJECT]: EntityNameTable.PROJECT,
            [EntityNameToDropClearLock.PLANT_LTSA]: EntityNameTable.PLANT_LTSA,
            [EntityNameToDropClearLock.UNIT_LTSA]: EntityNameTable.UNIT_LTSA,
            [EntityNameToDropClearLock.PIPELINE_LTSA]: EntityNameTable.PIPELINE_LTSA,
            [EntityNameToDropClearLock.SITE]: EntityNameTable.SITE,
            [EntityNameToDropClearLock.PIPELINE]: EntityNameTable.PIPELINE,
            [EntityNameToDropClearLock.TRANSMISSION_LINE]: EntityNameTable.TRANSMISSION_LINE,
            [EntityNameToDropClearLock.CONTACT]: EntityNameTable.CONTACT,
            [EntityNameToDropClearLock.TANK]: EntityNameTable.TANK,
            [EntityNameToDropClearLock.MEQUIPMENT]: EntityNameTable.MEQUIPMENT,
            [EntityNameToDropClearLock.OEQUIPMENT]: EntityNameTable.OEQUIPMENT,
        };

        return entityNameTableMap[entityName];
    }


    private resetScreen():void {
        //clean audit-data
        this.clearLockUtility = ClearLockUtilityDisplay.BuildClearLockUtilityDisplay();
    }

    // Generic clear lock entities 
    private getClearLockDetails(entityTable:string, entityName:string, entityID:string):void {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.clearLockUtility"));
            this.recordLockingService.recordDetailsByEntityID(entityTable, entityName, entityID)
            .subscribe((entity:any)=>{
                if(entity !== null) {
                    this.clearLockUtility = ClearLockUtilityDisplay.BuildNewLockUtilityDisplay(entity);
                } else {
                    this.resetScreen();

                    const entityNotExistsMsg = Constants.replace(Constants.MESSAGE_ENTITY_NOT_EXISTS, [entityName]);
                    this.messageHandlerService.showAlertAndReturnFocus(entityNotExistsMsg, undefined, this.inputEntityId);
                }
                this.loadWheelService.hideWheel(wheel);
            })
    }

    private getModeVerb(mode:String):String {
        return (mode == LockMode.EDIT) ? "editing" : "reading";
    }

    private getFormatDateValue(date:any):any {
        const newDate = new Date(date);
        return this.datePipe.transform(newDate, "dd-MMM-y HH:mm:ss");
    }

    private getlockHolderNameLink():any {
        const text = this.clearLockUtility?.lockDetails?.lockHolderName;
        const initText = text.indexOf('(') + 1;
        const lastText = text.indexOf(')'); 
             
        return text.substring(initText, lastText); 
    }

    private getLockErrorMessage(lockHolderName:String, mode:String, lockTime:Date):String {
		const modeVerb:String = this.getModeVerb(mode);
        const displayValue:any = this.getFormatDateValue(lockTime);
		return lockHolderName + " has this record open for " + modeVerb  + " as of " + displayValue;
	}   

    showWarningMsg():void {
        if(StringUtils.isNumber(this.clearLockUtility?.lockDetails?.lockTime)) {
            const modeVerb:String = this.getModeVerb(this.clearLockUtility?.lockDetails?.lockMode);
            const displayValue = this.getFormatDateValue(this.clearLockUtility?.lockDetails?.lockTime);
            this.messageInRedValidationFirst = (this.clearLockUtility?.lockDetails?.lockHolderName).replace(/\(.*?\)/, "("); 
            this.messageInRedValidationLast =  `) has this record \n` + `open for ` + modeVerb  +` as of ${displayValue}.\n`;

            this.lockHolderNameLink= this.getlockHolderNameLink();           
        }
    }

    public isContactEntity(): boolean {
        return this.formGroup.controls.entityName.value?.name === EntityNameToDropClearLock.CONTACT;
    }
    
    public validateClearLockbtn():boolean {
        if(this.clearLockUtility?.lockDetails?.entityID){
            this.showWarningMsg();
            return false;
        }
        return true;
    }

    public validateRemoveWIPStatus():boolean {
        if(!this.clearLockUtility?.lockDetails?.entityID && this.clearLockUtility?.wipRemovable == 1){
            return false;
        }
        return true;
    }


    openUserModal(userModal: string) {
        const modalRef = this.dialogService.open(UserModalComponent, {
            header: `User Details for ${userModal}`,
            width: '30rem',
            data: {
                user: userModal,
                entityName: this.clearLockUtility.entityName,
                entityId: this.clearLockUtility.entityId
            },
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });
    }

    // section remove WIP status 
    getRemoveWIPStatus():void{
        const entityName = this.formGroup.controls['entityName'].value.name;
        const entityId = (this.formGroup.controls['entityId'].value).toString();
        
        this.genericRemoveWIPStatus(this.getEntityNameTable(entityName), entityName, entityId);
    }

    genericRemoveWIPStatus(entityTable:string, entityName:string, entityID:string):void {
        const callbackFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                this.recordLockingService.removeWorkInProgressState(entityTable, entityName, entityID)
                    .subscribe((response:any)=>{
                        if(response!==false){
                            this.messageHandlerService.show(Constants.MESSAGE_REMOVE_WIP_STATUS, MessageType.INFO, (): void => {
                                this.reLoad();
                            });
                            this.telemetry.featureUsage("removedWIP");
                        }
                    })
            }
        }

        const confirmMsg = Constants.MESSAGE_CONFIRM_REMOVE_WIP_STATUS + entityName + " " + entityID + "?";
        this.messageHandlerService.show(confirmMsg, MessageType.CONFIRM, callbackFunction);
    }

}
        

<div class="container-modal">
    <p-messages></p-messages>
    <div class="Ex-content-modal">
        <div class="col-12 grid pt-1 pb-0">
            <div class="col-1 mt-1 flex justify-content-end min-w-max">
                <span class="px-0 font-bold text-xs">{{'geographyRequest.type'| translate}}: </span>
            </div>
            <div class="col-1 min-w-max pb-0">
                <p-radioButton name="filter" tabindex="1" [(ngModel)]="offshore" value="0" label="{{'common.onshoreCap'| translate}}"
                    (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-3 min-w-max">
                <p-radioButton name="filter" [(ngModel)]="offshore" value="1" label="{{'common.offshoreCap'| translate}}"
                    (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
        </div>
        <div class="col-12 text-xs mt-1 pb-4" *ngIf="offshore === ONSHORE_OPTION">
            <span><strong>{{'geographyRequest.note'| translate}}</strong></span>
        </div>
        <div class="grid mt-2" *ngIf="offshore === ONSHORE_OPTION">
            <div class="grid p-0">
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.country'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style" type="text" name="country"
                        [(ngModel)]="country" pInputText maxlength="80" tabindex="3" />
                    <div class="col-1 px-0 py-0 flex align-items-center"><span class="required-field pt-1">*</span></div>
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.state'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style" type="text" name="state" [(ngModel)]="state"
                        pInputText maxlength="80" tabindex="4" />
                    <div class="col-1 px-0 py-0 flex align-items-center"><span class="required-field pt-1">*</span></div>
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.city'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style" type="text" name="common.city"
                        [(ngModel)]="city" pInputText maxlength="80" tabindex="5" (focusout)="cityOnBlur()"/>
                    <div class="col-1 px-0 py-0 flex align-items-center"><span class="required-field pt-1">*</span></div>
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.county'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style" type="text" name="county" [(ngModel)]="county"
                        pInputText maxlength="80" tabindex="6" />
                    <div class="col-1 px-0 py-0"></div>
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.postalCode'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style" type="text" name="postalCode"
                        [(ngModel)]="postalCode" pInputText maxlength="11" tabindex="7" />
                    <div class="col-1 px-0 py-0"></div>
                </div>
                <div class="flex pt-y align-items-center col-12 lg:col-6 xl:col-6">
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.latitude'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style modal-input" 
                            type="text" 
                            name="latitude"
                            (focusout)="verifyLatitude()"
                            #latitudeInput
                            [disabled]="city === ''"
                            [(ngModel)]="latitude" 
                            pInputText 
                            location
                            maxlength="12" 
                            tabindex="7" />
                    <div class="col-1 px-0 py-0"></div>
                </div>
                <div class="flex py-0 align-items-center col-12 lg:col-6 xl:col-6">
                    <label class="col-3 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.longitude'|
                        translate}}:</label>
                    <input class="col-8 mx-1 form__input-field-style modal-input" 
                            type="text" 
                            name="longitude"
                            (focusout)="verifyLongitude()"
                            #longitudeInput
                            [disabled]="city === ''"
                            [(ngModel)]="longitude" 
                            pInputText 
                            location
                            maxlength="12" 
                            tabindex="8" />
                    <div class="col-1 px-0 py-0"></div>
                </div>
            </div>
        </div>
        <div class="grid mt-2" *ngIf="offshore !== ONSHORE_OPTION">
            <div class="col-12 py-0" >
                <div class="flex pt-0 align-items-center">
                    <label class="col-2 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.country'|
                        translate}}:</label>
                    <input
                        #countryInput
                        class="form__input-field-style col-2 ml-1"
                        type="text"
                        [(ngModel)]="countryId"
                        (focusout)="countryChangeFocusOut()"
                        (keydown.enter)="countryChangeOnEnter()"
                        (keypress)="checkNoSymbols($event)"
                        (keyup)="clearAddressCountryKeyUp()"
                        pInputText
                        appUppercase 
                        maxlength="4"
                        tabindex="3"
                    />
                    <input
                        class="form__input-field-style col-5 modal-input"
                        type="text"
                        pInputText
                        [(ngModel)]="countryName"
                        [disabled]="true"
                    />
                    <div class="col-3 px-0 ml-1 py-0 flex align-items-center">
                        <span class="required-field pt-1">*</span>
                        <button pButton pRipple type="button" icon="pi pi-search"
                            class="icon-field-style pd p-button-info p-button-text pe-auto" tabindex="4"
                            (click)="openCountrySearchModal()">
                        </button>
                        <button (click)="clearOffshoreCountry()" class="btn-custom inline-button" tabindex="4">{{'clear'| translate}}</button>
                    </div>
                </div>
                <div class="flex pt-0 align-items-center">
                    <label class="col-2 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.state'|
                        translate}}:</label>
                    <input
                        #stateInput
                        class="form__input-field-style col-2 ml-1"
                        type="text"
                        appUppercase
                        [(ngModel)]="stateAbbrev"
                        (focusout)="stateChangeFocusOut()"
                        (keydown.enter)="stateChangeOnEnter()"
                        pInputText
                        maxlength="4"
                        tabindex="5"
                    />
                    <input
                        class="form__input-field-style col-5 modal-input"
                        type="text"
                        pInputText
                        [(ngModel)]="stateName"
                        [disabled]="true"
                    />
                    <div class="col-3 px-0 ml-1 py-0 flex align-items-center" >
                        <span class="required-field pt-1">*</span>
                        <button pButton pRipple type="button" icon="pi pi-search"
                            class="icon-field-style pd p-button-info p-button-text pe-auto" tabindex="6"
                            (click)="stateChangeOnButtonClick()">
                        </button>
                        <button (click)="clearRelatedCountryfields()" class="btn-custom inline-button" tabindex="6">{{'clear'| translate}}</button>
                    </div>
                </div>
                <div class="flex pt-0 align-items-center">
                    <label class="col-2 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.waterBody'|
                        translate}}:</label>
                    <input class="col-7 mx-1 form__input-field-style" type="text" name="waterBody"
                        [(ngModel)]="waterBody" pInputText maxlength="80" tabindex="7" #waterBodyInput/>
                    <div class="col-3 px-0 py-0 flex align-items-center"><span class="required-field pt-1">*</span></div>
                </div>
                <div class="flex pt-0 align-items-center">
                    <label class="col-2 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.area'|
                        translate}}:</label>
                    <input class="col-7 mx-1 form__input-field-style" type="text" name="area"
                        [(ngModel)]="area" pInputText maxlength="80" tabindex="8" />
                    <div class="col-3 px-0 py-0"></div>
                </div>
                <div class="flex pt-0 align-items-center">
                    <label class="col-2 flex justify-content-end px-0 font-bold text-xs pt-1">{{'common.fieldName'|
                        translate}}:</label>
                    <input class="col-7 mx-1 form__input-field-style" type="text" name="fieldName"
                        [(ngModel)]="fieldName" pInputText maxlength="80" tabindex="9" />
                    <div class="col-3 px-0 py-0"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="col-12 ">
                <div class="text-xs pb-1">
                    <span><strong>{{'geographyRequest.adittional'| translate}}:</strong></span>
                </div>
                <textarea [rows]="5" class="mt-1 w-full h-12rem text-xs" autoResize="true" [disabled]="false"
                    maxlength="1495" [(ngModel)]="comment" tabindex="10">
                </textarea>
            </div>
            <div class="col-12 font-bold flex justify-content-end pt-0">
                {{ 'geographyRequest.maxCharacters' | translate }}
            </div>
            <div class="col-12 flex justify-content-center">
                <button class="btn-row" (click)="send()" tabindex="11">{{'geographyRequest.send'|
                    translate}}</button>
                <button class="btn-row ml-2" (click)="reset()" tabindex="12">{{'geographyRequest.reset'|
                    translate}}</button>
            </div>

        </div>
    </div>

</div>
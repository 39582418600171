import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { CompanySectionService } from 'src/app/company-section/company-section.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';

@Component({
    selector: 'app-look-up-modal',
    templateUrl: './look-up-modal.component.html',
    styleUrls: ['./look-up-modal.component.scss'],
})

export class LookUpModalComponent implements OnInit {
    @ViewChildren('tr') private rows: QueryList<ElementRef>;
    @ViewChild('focusEntityId', { static: false }) focusEntityId: ElementRef;

    @Input() entityIdPropName: string;
    @Input() searchedEntityIdPropName: string;
    @Input() entityDisplayPropName?: string;
    @Input() tKeyLabelEntityId: string;
    @Input() tKeyLabelEntityName: string;
    @Input() tKeyLabelDropdown: string;
    @Input() dropdownOptions: Array<any>;
    @Input() dropdownValueId: string;
    @Input() dropdownLabel: string;
    @Input() showSearchPanel: boolean = true;
    @Input() entityName: string;
    @Input() entityNamePlural: string;
    @Input() searchOnModalOpen: boolean;
    @Input() idFieldType: IdFieldType = IdFieldType.entityIdOnlyNumbers;
    @Input() checkDuplicatedRows: boolean = true;
    @Input() filterByDeleted: boolean = false;
    @Input() entitiesArray: any[];
    @Input() displayValueFunc?:(implementedComponent: any, rowData: any) => string;

    @Input() searchEntityDelegate: (implementedComponent: any, inputEntityId: string, inputEntityName: string, dropdownSelectedValue?: string) => Observable<any>;

    @Input() implementedComponent: any;

    @Input() inputEntityId: string = '';
    @Input() inputEntityName: string = '';
    @Input() isFuelTypeEntity: boolean;

    selectedRowData: any;
    tableProvider = [];
    dropdownSelectedValue: string = null;

    constructor(
        public companySectionService: CompanySectionService,
        public activeModal: DynamicDialogRef,
        private messageHandler: MessageHandlerService,
        private translate: TranslateService,
        private primengConfig: PrimeNGConfig) {
    }

    ngOnInit(): void {
        this.primengConfig.ripple = false;
        if(this.searchOnModalOpen) {
            this.search(false);
        }
        setTimeout(() => {
            if (this.focusEntityId) {
                this.focusEntityId.nativeElement.focus();
            }
        }, 150);
    }

    search(userSearch: boolean = true) {
        let searchEntityResult: Observable<any> = this.searchEntityDelegate(this.implementedComponent,  this.inputEntityId, this.inputEntityName, this.dropdownSelectedValue)
        if(searchEntityResult && searchEntityResult !== null) {
            searchEntityResult.subscribe((data) => {
                this.searchResult(data, userSearch);
            })
        }
    }

    searchResult(entitiesArrayResult, userSearch: boolean) {
        if (entitiesArrayResult !== null) {
            if(!StringUtils.isEmpty(entitiesArrayResult["exception"]) && !StringUtils.isEmpty(entitiesArrayResult["message"])) {
                this.messageHandler.showAlertAndReturnFocus(entitiesArrayResult["message"]);
                return;
            }
            this.tableProvider = entitiesArrayResult;
            if(!this.isFuelTypeEntity){
                this.focusFirstElement();
            }
            if(userSearch) {
                if (entitiesArrayResult.length === 0) {
                    this.messageHandler.showAlertAndReturnFocus(this.translate.instant('common.searchNotFound'));
                }
            }
        }
    }

    entitiesArrayFiltered(): Array<any> {
        let filteredArray: Array<any> = this.tableProvider;
        if (this.tableProvider && this.filterByDeleted) {
            filteredArray = MVTOperations.filterByDeleted(this.tableProvider);
            filteredArray = MVTOperations.filterEmptyRows(filteredArray, this.entityIdPropName);
        }
        return filteredArray;
    }
    
    getCellDisplayValue?(implementedComponent: any, rowData: any): string {
        let displayValue: string = null;
        if(this.entityDisplayPropName && this.entityDisplayPropName !== null) {
            displayValue = rowData[this.entityDisplayPropName];
        } else if(this.displayValueFunc && this.displayValueFunc !== null) {
            displayValue = this.displayValueFunc(implementedComponent, rowData);
        }

        return displayValue;
    }

    onRowSelect(event: any) {
        this.selectedRowData = event.data;
    }

    addRowSingleClick() {
        this.addRow(this.selectedRowData)
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selectedRowData", event, entityArray, hasHeader);
    }

    addRow(rowData: any) {
        if(this.checkDuplicatedRows) {
            let duplicated = undefined;
            if(this.idFieldType === IdFieldType.entityIdOnlyNumbers) {
                duplicated = MVTOperations.filterByDeleted(this.entitiesArray).find(c => Number(c[this.entityIdPropName]) === Number(rowData[this.searchedEntityIdPropName]));
            } else {
                duplicated = MVTOperations.filterByDeleted(this.entitiesArray).find(c => String(c[this.entityIdPropName]) === String(rowData[this.searchedEntityIdPropName]));
            }
            if (duplicated) {
                this.messageHandler.show(this.translate.instant('lookUpModal.entityAlreadyExist', { entityName: this.entityName }), MessageType.ERROR)
                return;
            }
        }

        this.activeModal.close(rowData);
    }

    closeModal() {
        this.activeModal.close()
    }

    private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                if(!first.nativeElement.classList.contains('p-highlight')){
                    first.nativeElement.click();
                }
            }
        }, 150);
    }

    public get IdFieldType(): typeof IdFieldType {
        return IdFieldType;
    }
}

export enum IdFieldType {
    entityTextId,
    entityIdOnlyNumbers,
    entityCompositeId
}

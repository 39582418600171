import { Constants } from "./constants";

export class PlantStatus {
    public constructor(
        public plantStatusID: number,
        public plantStatusDesc: string
    ) { }

    static BuildPlantStatus(fields: Array<any>): Array<PlantStatus> {
        return fields.map(item => PlantStatus.CreateInstance(item));
    }

    static CreateInstance(entity: any): PlantStatus {
        return new PlantStatus(
            entity.PLANT_STATUS_ID,
            entity.STATUS_DESC
        );
    }
}

export class FeedProduct {
    public constructor(
        public hsProducID: string,
        public productDesc: string,
        public productName: string,
        public hsUnit: string
    ) {}

    static BuildFeedProduct(fields: Array<any>): Array<FeedProduct> {
        return fields.map(item => FeedProduct.CreateInstance(item));
    }

    static CreateInstance(entity: any): FeedProduct {
        return new FeedProduct(
            String(entity.hsProduct),
            `${entity.hsProduct} - ${entity.productDesc}`,
            entity.productDesc,
            entity.hsUnit
        );
    }
}

export class PlantProbability {
    public constructor(
        public probabilityID: number,
        public probabilityDesc: string,
        public probabilityName: string,
        public inactive: boolean
    ) { }

    static BuildPlantProbability(fields: Array<any>): Array<PlantProbability> {
        return fields.map(item => PlantProbability.CreateInstance(item));
    }

    static CreateInstance(entity: any): PlantProbability {
        return new PlantProbability(
            entity.PLANT_PROBABILITY_ID,
            `${entity.PLANT_PROBABILITY_ID} - ${entity.PLANT_PROBABILITY_DESC}`,
            entity.PLANT_PROBABILITY_DESC,
            false
        );
    }
}

export class PlantType {
    public constructor(
        public typeID: string,
        public typeDesc: string,
        public typeName: string,
    ) { }

    static BuildPlantType(fields: Array<any>): Array<PlantType> {
        return fields.map(item => PlantType.CreateInstance(item));
    }

    static CreateInstance(entity: any): PlantType {
        return new PlantType(
            String(entity.PLANT_TYPE_ID),
            `${entity.PLANT_TYPE_ID} - ${entity.PLANT_TYPE_DESC}`,
            entity.PLANT_TYPE_DESC
        );
    }
}

export class PlantSource {
    public constructor(
        public sourceID: string,
        public sourceDesc: string,
        public sourceName: string
    ) { }

    static BuildPlantSource(fields: Array<any>): Array<PlantSource> {
        return fields.map(item => PlantSource.CreateInstance(item));
    }

    static CreateInstance(entity: any): PlantSource {
        return new PlantSource(
            String(entity.PLANT_SOURCE_ID),
            `${entity.PLANT_SOURCE_ID} - ${entity.TYPE_DESC}`,
            entity.TYPE_DESC
        );
    }
}

export class AssetsPlants {
    private PlantID: number
    private PlantName: string
    private QcDate: number
    private ReleaseDate: number
    private PlantOwner: string
    private Association: string
    private PhysCity: string
    private PhysCountry: string
    private PhysState: string
    private CountAttach: number

    private constructor(plantId: number,
        plantName: string,
        qcDate: number,
        releaseDate: number,
        owner: string,
        association: string,
        physCity: string,
        physCountry: string,
        physState: string,
        countAttach: number
    ) {
        this.PlantID = plantId
        this.PlantName = plantName
        this.QcDate = qcDate
        this.ReleaseDate = releaseDate
        this.PlantOwner = owner
        this.Association = association
        this.PhysCity = physCity
        this.PhysCountry = physCountry
        this.PhysState = physState
        this.CountAttach = countAttach
    }

    static BuildAssetsPlants(entity: Array<any>): Array<AssetsPlants> {
        return entity.map(item => AssetsPlants.CreateAssetsInstance(item));
    }

    private static CreateAssetsInstance(plants: any): AssetsPlants {
        const {
            PLANT_ID,
            PLANT_NAME,
            QC_DATE,
            RELEASE_DATE,
            OWNER,
            ASSOCIATION,
            PHYS_CITY,
            PHYS_COUNTRY,
            PHYS_STATE,
            COUNT_ATTACH
        } = plants;

        return new AssetsPlants(
            PLANT_ID,
            PLANT_NAME,
            QC_DATE,
            RELEASE_DATE,
            OWNER,
            ASSOCIATION,
            PHYS_CITY,
            PHYS_COUNTRY,
            PHYS_STATE,
            COUNT_ATTACH
        );
    }
}

export class PlatformType {
    public constructor(
        public typeID: number,
        public typeDesc: string,
        public typeName: string,
    ) { }

    static BuildPlatformType(fields: Array<any>): Array<PlatformType> {
        return fields.map(item => PlatformType.CreateInstance(item));
    }

    static CreateInstance(entity: any): PlatformType {
        return new PlatformType(
            entity.TYPE_ID,
            `${entity.TYPE_ID} - ${entity.TYPE_DESC}`,
            entity.TYPE_DESC
        );
    }
}

export class Plants {
    private PlantID: number;
    private PlantName: string;
    private CAttachment: number;
    private Status: string;
    private State: string;
    private QcDate: number;
    private ReleaseDate: number;
    private Owner: string;
    private PlantOwner: string;
    private KoDate: string;
    private StartupDate: string;
    private PhysState: string;
    private PhysStateId: number;
    private PhysCountry: string;
    private PhysCountryId: number;
    private Association: string;

    private PhysAddressV1: string;
    private PhysAddressV2: string;
    private PhysCity: string;
    private PhysCityId: number;
    private PhysCountryName: string;
    private PhysCountyName: string;
    private PhysCountyId: number;
    private PhysPostalCode: string;
    private PhysStateName: string;

    private OffshoreBlockV1: string;
    private OffshoreBlockV2: string;
    private OffshoreCountryDesigName: string;
    private OffshoreAreaId: number
    private OffshoreFieldId: number;
    private OffshoreWaterbodyId: number;
    private OffshoreAreaName: string;
    private OffshoreFieldName: string;
    private OffshoreStateDesigName: string;
    private OffshoreStateDesignation: number;
    private OffshoreWaterbodyName: string;

    private MailAddressV1: string;
    private MailAddressV2: string;
    private MailCity: string;
    private MailCityId: number;
    private MailCountry: string;
    private MailCountryName: string;
    private MailCountryId: number;
    private MailPostalCode: string;
    private MailState: string;
    private MailStateName: string;
    private MailStateId: number;
    private PecZoneSimplex: string;

    private constructor(
        plantId: number,
        plantName: string,
        cAttachment: number,
        status: string,
        state: string,
        qcDate: number,
        releaseDate: number,
        owner: string,
        plantOwner: string,
        koDate: string,
        startupDate: string,
        physState: string,
        physStateId: number,
        physCountry: string,
        physCountryId: number,
        association: string,

        physAddressv1: string,
        physAddressv2: string,
        physCity: string,
        physCityId: number,
        physCountryname: string,
        physCountyname: string,
        physCountyId: number,
        physPostalcode: string,
        physStatename: string,

        offshoreAreaId: number,
        offshoreAreaName: string,
        offshoreBlockV1: string,
        offshoreBlockV2: string,
        offshoreCountryDesigName: string,
        offshoreFieldId: number,
        offshoreFieldName: string,
        offshoreStateDesigName: string,
        offshoreStateDesignation: number,
        offshoreWaterbodyId: number,
        offshoreWaterbodyName: string,

        mailAddressV1: string,
        mailAddressV2: string,
        mailCity: string,
        mailCityId: number,
        mailCountry: string,
        mailCountryName: string,
        mailCountryId: number,
        mailPostalCode: string,
        mailState: string,
        mailStateName: string,
        mailStateId: number,
        pecZoneSimplex: string

    ) {
        this.PlantID = plantId;
        this.PlantName = plantName;
        this.CAttachment = cAttachment;
        this.Status = status;
        this.State = state;
        this.QcDate = qcDate;
        this.ReleaseDate = releaseDate;
        this.Owner = owner;
        this.PlantOwner = plantOwner;
        this.KoDate = koDate;
        this.StartupDate = startupDate;
        this.PhysState = physState;
        this.PhysStateId = physStateId;
        this.PhysCountry = physCountry;
        this.PhysCountryId = physCountryId;
        this.Association = association;

        this.PhysAddressV1 = physAddressv1;
        this.PhysAddressV2 = physAddressv2;
        this.PhysCity = physCity;
        this.PhysCityId = physCityId;
        this.PhysCountryName = physCountryname;
        this.PhysCountyName = physCountyname;
        this.PhysCountyId = physCountyId;
        this.PhysPostalCode = physPostalcode;
        this.PhysStateName = physStatename;

        this.OffshoreBlockV1 = offshoreBlockV1;
        this.OffshoreBlockV2 = offshoreBlockV2;
        this.OffshoreCountryDesigName = offshoreCountryDesigName;
        this.OffshoreStateDesigName = offshoreStateDesigName;
        this.OffshoreStateDesignation = offshoreStateDesignation;
        this.OffshoreAreaId = offshoreAreaId;
        this.OffshoreFieldId = offshoreFieldId;
        this.OffshoreWaterbodyId = offshoreWaterbodyId;
        this.OffshoreAreaName = offshoreAreaName;
        this.OffshoreFieldName = offshoreFieldName;
        this.OffshoreWaterbodyName = offshoreWaterbodyName;

        this.MailAddressV1 = mailAddressV1;
        this.MailAddressV2 = mailAddressV2;
        this.MailCity = mailCity;
        this.MailCityId = mailCityId;
        this.MailCountry = mailCountry;
        this.MailCountryName = mailCountryName;
        this.MailCountryId = mailCountryId;
        this.MailPostalCode = mailPostalCode;
        this.MailState = mailState;
        this.MailStateName = mailStateName;
        this.MailStateId = mailStateId;
        this.PecZoneSimplex = pecZoneSimplex;

    }

    static BuildPlants(entity: Array<any>): Array<Plants> {
        return entity.map(item => Plants.CreateInstance(item));
    }

    private static CreateInstance(plants: any): Plants {
        const {
            PLANT_ID,
            PLANT_NAME,
            COUNT_ATTACH,
            PLANT_STATUS_DESC,
            STATE_NAME,
            QC_DATE,
            RELEASE_DATE,
            COMPANY_NAME,
            OWNER,
            KO_DATE,
            STARTUP_DATE,
            PHYS_STATE,
            PHYS_STATE_S_ID,
            PHYS_COUNTRY,
            PHYS_COUNTRY_C_ID,
            ASSOCIATION,

            PHYS_ADDRESS_V1,
            PHYS_ADDRESS_V2,
            PHYS_CITY,
            PHYS_CITY_CI_ID,
            PHYS_COUNTRY_NAME,
            PHYS_COUNTY,
            PHYS_COUNTY_CO_ID,
            PHYS_POSTAL_CODE,
            PHYS_STATE_NAME,

            OFFSHORE_AREA_ID,
            OFFSHORE_AREA_NAME,
            OFFSHORE_BLOCK_V1,
            OFFSHORE_BLOCK_V2,
            OFFSHORE_COUNTRY_DESIG_NAME,
            OFFSHORE_FIELD_ID,
            OFFSHORE_FIELD_NAME,
            OFFSHORE_STATE_DESIG_NAME,
            OFFSHORE_STATE_DESIGNATION,
            OFFSHORE_WATERBODY_ID,
            OFFSHORE_WATERBODY_NAME,

            MAIL_ADDRESS_V1,
            MAIL_ADDRESS_V2,
            MAIL_CITY,
            MAIL_CITY_CI_ID,
            MAIL_COUNTRY,
            MAIL_COUNTRY_NAME,
            MAIL_COUNTRY_C_ID,
            MAIL_POSTAL_CODE,
            MAIL_STATE,
            MAIL_STATE_NAME,
            MAIL_STATE_S_ID,
            PEC_ZONE_SIMPLEX
        } = plants;

        return new Plants(
            PLANT_ID,
            PLANT_NAME,
            COUNT_ATTACH,
            PLANT_STATUS_DESC,
            STATE_NAME,
            QC_DATE,
            RELEASE_DATE,
            COMPANY_NAME,
            OWNER,
            KO_DATE,
            STARTUP_DATE,
            PHYS_STATE,
            PHYS_STATE_S_ID,
            PHYS_COUNTRY,
            PHYS_COUNTRY_C_ID,
            ASSOCIATION,

            PHYS_ADDRESS_V1,
            PHYS_ADDRESS_V2,
            PHYS_CITY,
            PHYS_CITY_CI_ID,
            PHYS_COUNTRY_NAME,
            PHYS_COUNTY,
            PHYS_COUNTY_CO_ID,
            PHYS_POSTAL_CODE,
            PHYS_STATE_NAME,

            OFFSHORE_AREA_ID,
            OFFSHORE_AREA_NAME,
            OFFSHORE_BLOCK_V1,
            OFFSHORE_BLOCK_V2,
            OFFSHORE_COUNTRY_DESIG_NAME,
            OFFSHORE_FIELD_ID,
            OFFSHORE_FIELD_NAME,
            OFFSHORE_STATE_DESIG_NAME,
            OFFSHORE_STATE_DESIGNATION,
            OFFSHORE_WATERBODY_ID,
            OFFSHORE_WATERBODY_NAME,

            MAIL_ADDRESS_V1,
            MAIL_ADDRESS_V2,
            MAIL_CITY,
            MAIL_CITY_CI_ID,
            MAIL_COUNTRY,
            MAIL_COUNTRY_NAME,
            MAIL_COUNTRY_C_ID,
            MAIL_POSTAL_CODE,
            MAIL_STATE,
            MAIL_STATE_NAME,
            MAIL_STATE_S_ID,
            PEC_ZONE_SIMPLEX
        );
    }

    get plantID(): number {
        return this.PlantID;
    }

    get physCity(): string {
        return this.PhysCity;
    }

    get physCityId(): number {
        return this.PhysCityId;
    }

    get physAddressV1(): string {
        return this.PhysAddressV1;
    }

    get physAddressV2(): string {
        return this.PhysAddressV2;
    }

    get physCountry(): string {
        return this.PhysCountry;
    }

    get physCountryId(): number {
        return this.PhysCountryId;
    }

    get physCountryName(): string {
        return this.PhysCountryName;
    }

    get physCountyName(): string {
        return this.PhysCountyName;
    }

    get physCountyId(): number {
        return this.PhysCountyId;
    }

    get physPostalCode(): string {
        return this.PhysPostalCode;
    }

    get physState(): string {
        return this.PhysState;
    }

    get physStateId(): number {
        return this.PhysStateId;
    }

    get physStateName(): string {
        return this.PhysStateName;
    }

    get offshoreBlockV1(): string {
        return this.OffshoreBlockV1;
    }
    get offshoreBlockV2(): string {
        return this.OffshoreBlockV2;
    }
    get offshoreCountryDesigName(): string {
        return this.OffshoreCountryDesigName;
    }

    get offshoreStateDesigName(): string {
        return this.OffshoreStateDesigName;
    }

    get offshoreStateDesignation(): number {
        return this.OffshoreStateDesignation;
    }

    get offshoreAreaId(): number {
        return this.OffshoreAreaId;
    }

    get offshoreFieldId(): number {
        return this.OffshoreFieldId;
    }

    get offshoreWaterBodyId(): number {
        return this.OffshoreWaterbodyId;
    }

    get offshoreAreaName(): string {
        return this.OffshoreAreaName;
    }

    get offshoreFieldName(): string {
        return this.OffshoreFieldName;
    }

    get offshoreWaterBodyName(): string {
        return this.OffshoreWaterbodyName;
    }

    get mailCity(): string {
        return this.MailCity;
    }

    get mailCityId(): number {
        return this.MailCityId;
    }

    get mailAddressV1(): string {
        return this.MailAddressV1;
    }

    get mailAddressV2(): string {
        return this.MailAddressV2;
    }

    get mailCountryName(): string {
        return this.MailCountryName;
    }

    get mailPostalCode(): string {
        return this.MailPostalCode;
    }

    get mailStateName(): string {
        return this.MailStateName;
    }

    get mailStateId(): number {
        return this.MailStateId;
    }

    get mailCountry(): string {
        return this.MailCountry;
    }

    get mailCountryId(): number {
        return this.MailCountryId;
    }

    get mailState(): string {
        return this.MailState;
    }

    get pecZoneSimplex(): string {
        return this.PecZoneSimplex;
    }

}

<div class="general-container" id="serviceAgreementTypes">
    <div class="main-section">
        <div class="header">
            <h3>{{'ltsa.serviceAgreementTypes.title'| translate}}</h3>
        </div>

        <form [formGroup]="serviceAgreementTypesForm">

            <!-- Service Agreements sub sections -->
            <div class="grid mt-1">
                <!-- capital -->
                <div class="col-12">
                    <div class="main-section">
                        <div class="grid mt-1">
                            <div class="col-2">
                                <p-checkbox 
                                    class="text-xs font-bold"
                                    name="capital" 
                                    binary="true"
                                    formControlName="capital"
                                    (onChange)="changeType($event, 'capital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.capital'| translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="grid mt-1">
                            <label class="col-2 lg:col-2 xl:col-2
                                            flex justify-content-start pt-1 pr-0
                                            pl-1 font-bold text-xs mt-1 pr-2">
                                            {{'ltsa.serviceAgreementTypes.subTypes'| translate}}</label>
                            <label class="col-2 lg:col-2 xl:col-2
                                            flex justify-content-start pt-1 pr-0
                                            pl-1 font-bold text-xs mt-1 pr-2">
                                            {{'ltsa.serviceAgreementTypes.trades'| translate}}</label>
                        </div>
                        <div class="grid mt-1">
                            <!-- Types Capital-->
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="engineering" 
                                    binary="true"
                                    formControlName="engineering"
                                    (onChange)="changeSubType($event, 'engineering')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.engineering'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="construction" 
                                    binary="true"
                                    formControlName="construction"
                                    (onChange)="changeSubType($event, 'construction')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.construction'| translate}}">
                                </p-checkbox>
                            </div>
                            <!-- Trades Capital-->
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="pipefittersCapital" 
                                    binary="true"
                                    formControlName="pipefittersCapital"
                                    (onChange)="changeTrade($event, 'capital', 'pipefittersCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.pipefitters'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="boilermakersCapital" 
                                    binary="true"
                                    formControlName="boilermakersCapital"
                                    (onChange)="changeTrade($event, 'capital', 'boilermakersCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.boilermakers'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="catalystVendorCapital" 
                                    binary="true"
                                    formControlName="catalystVendorCapital"
                                    (onChange)="changeTrade($event, 'capital', 'catalystVendorCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.catalystVendor'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="weldingCapital" 
                                    binary="true"
                                    formControlName="weldingCapital"
                                    (onChange)="changeTrade($event, 'capital', 'weldingCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.welding'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="mechanicalInstallationCapital" 
                                    binary="true"
                                    formControlName="mechanicalInstallationCapital"
                                    (onChange)="changeTrade($event, 'capital', 'mechanicalInstallationCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.mechanicalInstallation'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="electricalCapital" 
                                    binary="true"
                                    formControlName="electricalCapital"
                                    (onChange)="changeTrade($event, 'capital', 'electricalCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.electrical'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="instrumentationCapital" 
                                    binary="true"
                                    formControlName="instrumentationCapital"
                                    (onChange)="changeTrade($event, 'capital', 'instrumentationCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.instrumentation'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="scaffoldingCapital" 
                                    binary="true"
                                    formControlName="scaffoldingCapital"
                                    (onChange)="changeTrade($event, 'capital', 'scaffoldingCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.scaffolding'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="insulationCapital" 
                                    binary="true"
                                    formControlName="insulationCapital"
                                    (onChange)="changeTrade($event, 'capital', 'insulationCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.insulation'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="paintingCapital" 
                                    binary="true"
                                    formControlName="paintingCapital"
                                    (onChange)="changeTrade($event, 'capital', 'paintingCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.painting'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="inspectionCapital" 
                                    binary="true"
                                    formControlName="inspectionCapital"
                                    (onChange)="changeTrade($event, 'capital', 'inspectionCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.inspection'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="ndeCapital" 
                                    binary="true"
                                    formControlName="ndeCapital"
                                    (onChange)="changeTrade($event, 'capital', 'ndeCapital')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.nde'| translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- maintenance -->
                <div class="col-12">
                    <div class="main-section">
                        <div class="grid mt-1" >
                            <div class="col-2">
                                <p-checkbox
                                        class="text-xs font-bold"
                                        name="maintenance"
                                        binary="true"
                                        formControlName="maintenance"
                                        [tabindex]="baseIndex"
                                        (onChange)="changeType($event, 'maintenance')"
                                        label="{{'ltsa.serviceAgreementTypes.maintenance'| translate}}"
                                >
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="grid mt-1" >
                                <label
                                        class="col-2 lg:col-2 xl:col-2
                                        flex justify-content-start pt-1 pr-0
                                        pl-1 font-bold text-xs mt-1 pr-2">{{'ltsa.serviceAgreementTypes.subTypes'| translate}}</label>
                                <label
                                        class="col-2 lg:col-2 xl:col-2
                                        flex justify-content-start pt-1 pr-0
                                        pl-1 font-bold text-xs mt-1 pr-2">{{'ltsa.serviceAgreementTypes.trades'| translate}}</label>
                        </div>
                        <div class="grid mt-1" >
                            <!-- Types Maintenance-->
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="dailyMaintenance" 
                                    binary="true"
                                    formControlName="dailyMaintenance"
                                    (onChange)="changeSubType($event, 'dailyMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.dailyMaintenance'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="offlineEvents" 
                                    binary="true"
                                    formControlName="offlineEvents"
                                    (onChange)="changeSubType($event, 'offlineEvents')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.offlineEvents'| translate}}">
                                </p-checkbox>

                            </div>
                            <!-- Trades Maintenance-->
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="pipefittersMaintenance" 
                                    binary="true"
                                    formControlName="pipefittersMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'pipefittersMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.pipefitters'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="boilermakersMaintenance" 
                                    binary="true"
                                    formControlName="boilermakersMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'boilermakersMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.boilermakers'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="weldingMaintenance" 
                                    binary="true"
                                    formControlName="weldingMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'weldingMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.welding'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="catalystVendorMaintenance" 
                                    binary="true"
                                    formControlName="catalystVendorMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'catalystVendorMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.catalystVendor'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="mechanicalInstallationMaintenance" 
                                    binary="true"
                                    formControlName="mechanicalInstallationMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'mechanicalInstallationMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.mechanicalInstallation'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="electricalMaintenance" 
                                    binary="true"
                                    formControlName="electricalMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'electricalMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.electrical'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="instrumentationMaintenance" 
                                    binary="true"
                                    formControlName="instrumentationMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'instrumentationMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.instrumentation'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="scaffoldingMaintenance" 
                                    binary="true"
                                    formControlName="scaffoldingMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'scaffoldingMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.scaffolding'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="insulationMaintenance" 
                                    binary="true"
                                    formControlName="insulationMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'insulationMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.insulation'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="paintingMaintenance" 
                                    binary="true"
                                    formControlName="paintingMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'paintingMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.painting'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="inspectionMaintenance" 
                                    binary="true"
                                    formControlName="inspectionMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'inspectionMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.inspection'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="ndeMaintenance" 
                                    binary="true"
                                    formControlName="ndeMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'ndeMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.nde'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="chemicalCleaningMaintenance" 
                                    binary="true"
                                    formControlName="chemicalCleaningMaintenance"
                                    (onChange)="changeTrade($event, 'maintenance', 'chemicalCleaningMaintenance')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.chemicalCleaning'| translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- operations -->
                <div class="col-12">
                    <div class="main-section">
                        <div class="grid mt-1" >
                            <div class="col-2">
                                <p-checkbox
                                        class="text-xs font-bold"
                                        name="operations"
                                        binary="true"
                                        formControlName="operations"
                                        [tabindex]="baseIndex"
                                        (onChange)="changeType($event, 'operations')"
                                        label="{{'ltsa.serviceAgreementTypes.operations'| translate}}"
                                >
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="grid mt-1" >
                                <label
                                        class="col-2 lg:col-2 xl:col-2
                                        flex justify-content-start pt-1 pr-0
                                        pl-1 font-bold text-xs mt-1 pr-2">{{'ltsa.serviceAgreementTypes.subTypes'| translate}}</label>
                        </div>
                        <!-- Types Operations-->
                        <div class="grid mt-1" >
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="dailyOperations" 
                                    binary="true"
                                    formControlName="dailyOperations"
                                    (onChange)="changeSubType($event, 'dailyOperations')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.dailyOperations'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="environmentalServices" 
                                    binary="true"
                                    formControlName="environmentalServices"
                                    (onChange)="changeSubType($event, 'environmentalServices')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.environmentalServices'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="humanResurces" 
                                    binary="true"
                                    formControlName="humanResurces"
                                    (onChange)="changeSubType($event, 'humanResurces')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.humanResurces'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="informationTechnology" 
                                    binary="true"
                                    formControlName="informationTechnology"
                                    (onChange)="changeSubType($event, 'informationTechnology')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.informationTechnology'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="miningContractingServices" 
                                    binary="true"
                                    formControlName="miningContractingServices"
                                    (onChange)="changeSubType($event, 'miningContractingServices')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.miningContractingServices'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="purchasing" 
                                    binary="true"
                                    formControlName="purchasing"
                                    (onChange)="changeSubType($event, 'purchasing')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.purchasing'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="security" 
                                    binary="true"
                                    formControlName="security"
                                    (onChange)="changeSubType($event, 'security')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.security'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="warehousing" 
                                    binary="true"
                                    formControlName="warehousing"
                                    (onChange)="changeSubType($event, 'warehousing')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.warehousing'| translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- rentals -->
                <div class="col-12">
                    <div class="main-section">
                        <div class="grid mt-1" >
                            <div class="col-2">
                                <p-checkbox
                                        class="text-xs font-bold"
                                        name="rentals"
                                        binary="true"
                                        formControlName="rentals"
                                        [tabindex]="baseIndex"
                                        (onChange)="changeType($event, 'rentals')"
                                        label="{{'ltsa.serviceAgreementTypes.rentals'| translate}}"
                                >
                                </p-checkbox>
                            </div>
                        </div>
                        <div class="grid mt-1" >
                                <label
                                        class="col-2 lg:col-2 xl:col-2
                                        flex justify-content-start pt-1 pr-0
                                        pl-1 font-bold text-xs mt-1 pr-2">{{'ltsa.serviceAgreementTypes.subTypes'| translate}}</label>
                        </div>
                        <!-- Types rentals-->
                        <div class="grid mt-1" >
                            <div class="col-2 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="tools" 
                                    binary="true"
                                    formControlName="tools"
                                    (onChange)="changeSubType($event, 'tools')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.tools'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="portableRestrooms" 
                                    binary="true"
                                    formControlName="portableRestrooms"
                                    (onChange)="changeSubType($event, 'portableRestrooms')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.portableRestrooms'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="temporaryPumps" 
                                    binary="true"
                                    formControlName="temporaryPumps"
                                    (onChange)="changeSubType($event, 'temporaryPumps')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.temporaryPumps'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="temporaryPower" 
                                    binary="true"
                                    formControlName="temporaryPower"
                                    (onChange)="changeSubType($event, 'temporaryPower')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.temporaryPower'| translate}}">
                                </p-checkbox>
                            </div>
                            <div class="col-3 flex flex-column">
                                <p-checkbox 
                                    class="text-xs"
                                    name="heavyLifting" 
                                    binary="true"
                                    formControlName="heavyLifting"
                                    (onChange)="changeSubType($event, 'heavyLifting')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.heavyLifting'| translate}}">
                                </p-checkbox>
                                <p-checkbox 
                                    class="text-xs mt-1"
                                    name="generalEquipment" 
                                    binary="true"
                                    formControlName="generalEquipment"
                                    (onChange)="changeSubType($event, 'generalEquipment')"
                                    [tabindex]="baseIndex"
                                    label="{{'ltsa.serviceAgreementTypes.generalEquipment'| translate}}">
                                </p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>

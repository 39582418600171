<div class="title-search">
    <div class="grid mt-1">    
        <div class="col-9">
            <div class="grid ml-3 pl-1 xl:ml-3 xl:pl-1 lg:ml-3 lg:pl-1 md:ml-3 md:pl-1 sm:ml-3 sm:pl-1">
                <div class="col-4 flex justify-content-end mt-1 -ml-5 xl:-ml-7 lg:-ml-7 md:-ml-7 sm:-ml-7">
                    <label class="text-xs">Title Description:</label>
                </div>
                <div class="col-8 -ml-2">
                    <input 
                        #titleDesc
                        type="text" 
                        pInputText 
                        [(ngModel)]="titleDescription" 
                        class="p-inputtext-sm" 
                        (keyup.enter)="search()" 
                        appFocusElement 
                    />
                </div>
            </div>
        </div>
        <div class="col-3 px-0 -ml-5 xl:-ml-8 xl:pl-2 lg:-ml-8 lg:-pl-2 md:-ml-8 md:pl-2 sm:-ml-8 sm:pl-2">
                <button class="btn-row" (click)="search()">{{'search'| translate}}</button>
                <button class="btn-row" style="margin-top: 12px !important;" (click)="clear()">{{'clear'| translate}}</button>
        </div>
    </div>
    <div class="border-1 border-500">
        <div class="col-12 custom-scrollable-table-modal">    
            <p-table #dt2
                [value]="titles"
                [reorderableColumns]="true"
                [resizableColumns]="true"
                selectionMode="single"
                [(selection)]="this.selection"
                (onRowSelect)="onRowSelect($event)"
                [globalFilterFields]="['TITLE_DESC']">
                <ng-template pTemplate="header" dataKey="id">
                    <tr>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr #tr 
                        [pSelectableRow]="item" 
                        (dblclick)="addRow(item)"
                        (keydown.enter)="addRow(item)"                        
                        (keydown)="changeSelectionOnArrowKey($event, titles)">
                        <td>{{item.TITLE_ID}} - {{item.TITLE_DESC}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="grid">
        <div class="col-12 text-center -mb-2 mt-1">
            <button class="btn-row" (click)="addRowSingleClick()">{{'save'| translate}}</button>
            <button class="btn-row ml-2" (click)="closeModal()">{{'cancel'| translate}}</button>
        </div>
    </div>
  </div>

import {Constants} from "./constants";

export class ProjectStatus {
    statusID: string;
    description: string;
    label: string;
    inactive: boolean = false;

    private constructor(statusID: string,
                        description: string,
                        label: string) {
        this.statusID = statusID;
        this.description = description;
        this.label = label;
    }

    static BuildProjectStatus(entity: Array<any>): Array<ProjectStatus> {
        return entity.map(item => ProjectStatus.CreateInstance(item));
    }


    private static CreateInstance(record: any): ProjectStatus {
        return new ProjectStatus(record.STATUS_ID,
            `${record.STATUS_ID} - ${record.STATUS_DESC}`,
            record.STATUS_DESC);
    }
}

export class ProjectStatusReason {
    reasonID: string;
    description: string;
    label: string;

    private constructor(reasonID: string,
                        description: string,
                        label: string) {
        this.reasonID = reasonID;
        this.description = description;
        this.label = label;
    }

    static BuildProjectStatusReason(entity: Array<any>): Array<ProjectStatusReason> {
        return entity.map(item => ProjectStatusReason.CreateInstance(item));
    }


    private static CreateInstance(record: any): ProjectStatusReason {
        return new ProjectStatusReason(record.STATUS_REASON_ID,
            `${record.STATUS_REASON_ID} - ${record.STATUS_REASON_DESC}`,
            record.STATUS_REASON_DESC);
    }
}

export class ProjectRecordStatus {
    public statusID: string;
    public description: string;
    public category: string;
    public inactive: boolean = false;

    private constructor(statusID: string,
                        description: string,
                        category: string) {
        this.statusID = statusID;
        this.description = description;
        this.category = category;
    }

    static BuildProjectRecordStatus(entity: Array<any>): Array<ProjectRecordStatus> {
        return entity.map(item => ProjectRecordStatus.CreateInstance(item));
    }


    private static CreateInstance(record: any): ProjectRecordStatus {
        return new ProjectRecordStatus(record.STATUS_ID, 
            `${record.STATUS_ID} - ${record.STATUS_DESC}`,
            record.CATEGORY);
    }
}

export class ProjectProbability {
    public constructor(
        public probabilityID: number,
        public probabilityDesc: string,
        public probabilityName: string,
        public inactive: boolean
    ) { }

    static BuildProjectProbability(fields: Array<any>): Array<ProjectProbability> {
        return fields.map(item => ProjectProbability.CreateInstance(item));
    }

    static CreateInstance(entity: any): ProjectProbability {
        return new ProjectProbability(
            entity.PLANT_PROBABILITY_ID,
            `${entity.PLANT_PROBABILITY_ID} - ${entity.PLANT_PROBABILITY_DESC}`,
            entity.PLANT_PROBABILITY_DESC,
            false
        );
    }
}

export class ProjectType {
    inactive: boolean = false;
    public constructor(
        public typeID: string,
        public typeDesc: string,
        public typeName: string,
        public classification: string,
        public isParent: boolean
    ) { }

    static BuildProjectType(fields: Array<any>): Array<ProjectType> {
        return ProjectType.sort(fields.map(item => ProjectType.CreateInstance(item)));;
    }

    static sort(fields: Array<ProjectType>): Array<ProjectType> {
        const projectTypes: ProjectType[] = [];
        const clasifications: Set<string> = new Set();
        fields.sort((a,b) => {
            const comparationClassification = a.classification.localeCompare(b.classification);
            if (comparationClassification !== 0) {
                return comparationClassification;
            }
            return parseInt(a.typeID) - parseInt(b.typeID);
        });
        fields.forEach(fields => {
            if (!clasifications.has(fields.classification)) {
                clasifications.add(fields.classification);
                projectTypes.push(ProjectType.BuildClasification(fields.classification));
            }
            projectTypes.push(fields);
        });
        return projectTypes;

    }
    static BuildClasification(clasification: string ): ProjectType {
        return new ProjectType('','--- ' + clasification + ' ---', '','',true);
    }


    static CreateInstance(entity: any): ProjectType {
        return new ProjectType(
            String(entity.TYPE_ID),
            `${entity.TYPE_ID} - ${entity.TYPE_DESC}`,
            entity.TYPE_DESC, entity.CLASSIFICATION,false
        );
    }
}

export class ProjectSource {
    public constructor(
        public sourceID: string,
        public sourceDesc: string,
        public sourceName: string,
    ) { }

    static BuildProjectSource(fields: Array<any>): Array<ProjectSource> {
        return fields.map(item => ProjectSource.CreateInstance(item));
    }


    static CreateInstance(entity: any): ProjectSource {
        return new ProjectSource(
            String(entity.PROJECT_SOURCE_ID),
            `${entity.PROJECT_SOURCE_ID} - ${entity.TYPE_DESC}`,
            entity.TYPE_DESC
        );
    }
}

export class PecActivity {
    public constructor(
        public pecActivity: string,
        public activityDesc: string,
        public pecTiming: string,
    ) { }

    static BuildPecActivity(fields: Array<any>): Array<PecActivity> {
        return fields.map(item => PecActivity.CreateInstance(item));
    }


    static CreateInstance(entity: any): PecActivity {
        return new PecActivity(
            String(entity.PEC_ACTIVITIES_ID),
            entity.ACTIVITY_DESC,
            entity.PEC_TIMING
        );
    }
}

export class Author {
    public constructor(
        public userId: string,
        public userName: string
    ) { }

    static BuildAuthor(fields: Array<any>): Array<Author> {
        return fields.map(item => Author.CreateInstance(item));
    }

    static CreateInstance(entity: any): Author {
        return new Author(
            String(entity.ID),
            `${entity.FIRSTNAME} ${entity.LASTNAME}`
        );
    }
}






import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import {
    CountryWaterBody,
    StateByCountry,
    OffshoreField,
    SearchOffshoreArea,
    OffshoreAreaById,
    WaterBody

} from '../../core/models/offshoreAddress';
// Service
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';
// Component
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';

@Injectable({
    providedIn: 'root'
})
export class OffshoreAddressService {
    constructor(
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService
    ) { }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getAllCountriesWaterBodyByid(): Observable<CountryWaterBody[]> {
       return this.getCountriesWaterBodyById(true, '');
    }

    getCountriesWaterBodyById(applyCoverage: boolean, waterBodyId: string): Observable<CountryWaterBody[]> {
        const params = new HttpParams()
            .append('applyCoverage', applyCoverage)
            .append('waterBodyId', waterBodyId);

        return this.http.get<CountryWaterBody>(`${environment.apiUrl}shared/countriesByWaterBodyId`, {params})
            .pipe(
                map((data: any) => CountryWaterBody.BuildCountryWaterBody(data.response || []))
            );
    }

    getStatesByCountries(countryId: string): Observable<StateByCountry[]> {
        const params = new HttpParams()
            .append('countryId', countryId);

        return this.http.get<StateByCountry>(`${environment.apiUrl}shared/stateByCountryId`, {params})
            .pipe(
                map((data: any) => StateByCountry.BuildStateByCountry(data.response || []))
            );
    }

    searchOffshoreWaterBody(applyCoverage: boolean, countryId: string, stateId: string, waterBodyId: string, waterBodyName: string): Observable<WaterBody[]> {
        const params = new HttpParams()
            .append('applyCoverage', applyCoverage)
            .append('countryId', countryId)
            .append('stateId', stateId)
            .append('waterBodyId', waterBodyId)
            .append('waterBodyName', waterBodyName);

        return this.http.get<WaterBody>(`${environment.apiUrl}shared/searchOffshoreWaterBody`, {params})
            .pipe(
                map((data: any) => WaterBody.BuildWaterBody(data.response || []))
            );
    }

    getWaterBodyById(applyCoverage: boolean, waterBodyId: number): Observable<WaterBody[]> {
        const params = new HttpParams()
            .append('applyCoverage', applyCoverage)
            .append('waterBodyId', waterBodyId);

        return this.http.get<WaterBody>(`${environment.apiUrl}shared/offshoreWaterBodyById`, {params})
            .pipe(
                map((data: any) => WaterBody.BuildWaterBody(data.response || []))
            );
    }

    getOffshoreFields(waterBodyId: number, fieldName: string, applyCoverage: boolean): Observable<OffshoreField[]> {
        const params = new HttpParams()
            .append('waterBodyId', waterBodyId)
            .append('fieldName', fieldName)
            .append('applyCoverage', applyCoverage);

        return this.http.get<OffshoreField>(`${environment.apiUrl}shared/searchOffshoreField`, {params})
            .pipe(
                map((data: any) => OffshoreField.BuildSearchOffshoreField(data.response || []))
            );
    }

    getOffshoreFieldById(applyCoverage: boolean, fieldId: number): Observable<OffshoreField[]> {
        const params = new HttpParams()
            .append('applyCoverage', applyCoverage)
            .append('fieldId', fieldId);

        return this.http.get<OffshoreField>(`${environment.apiUrl}shared/offshoreFieldById`, {params})
            .pipe(
                map((data: any) => OffshoreField.BuildSearchOffshoreField(data.response || []))
            );
    }

    getAreaSearch(waterBodyId: string, fieldId: number, applyCoverage: boolean, areaId: number, areaName: string): Observable<SearchOffshoreArea[]> {
        const params = new HttpParams()
            .append('waterBodyId', waterBodyId)
            .append('fieldId', fieldId)
            .append('applyCoverage', applyCoverage)
            .append('areaId', areaId)
            .append('areaName', areaName);

        return this.http.get<SearchOffshoreArea>(`${environment.apiUrl}shared/searchOffshoreArea`, {params})
            .pipe(
                map((data: any) => SearchOffshoreArea.BuildSearchoffshoreAreaList(data.response || []))
            );
    }

    getOffshoreAreaById(applyCoverage: boolean, areaId: number): Observable<OffshoreAreaById[]> {
        const params = new HttpParams()
            .append('applyCoverage', applyCoverage)
            .append('areaId', areaId);

        return this.http.get<OffshoreAreaById>(`${environment.apiUrl}shared/offshoreAreaById`, {params})
            .pipe(
                map((data: any) => OffshoreAreaById.BuildOffshoreAreaById(data.response || []))
            );
    }

    @Cacheable({
      storageStrategy: LocalStorageStrategy
    })
    getAllWaterBodies(): Observable<WaterBody[]> {
        return this.getWaterBodies(null);
    }

    getWaterBodies(countryName: string): Observable<WaterBody[]> {
        let params = new HttpParams().append(countryName !== null ? 'countryName' : '', countryName);

        return this.http.get<WaterBody>(`${environment.apiUrl}shared/waterbodyByCountryName`, {params})
            .pipe(
                map((data: any) => WaterBody.BuildWaterBody(data.response || []))
            );
    }

}



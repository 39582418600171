import { PipelinesLTSASubTypes } from "../save/pipeline-ltsa-sub-types";

export class PipelinesLTSASubTypesDisplay extends PipelinesLTSASubTypes {
    serviceAgreementSubtypeName: string;

    constructor(entity: any) {
        super(entity);
    }

    static CreateInstance(entity: any): PipelinesLTSASubTypesDisplay {
        return new PipelinesLTSASubTypesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PipelinesLTSASubTypesDisplay> {
        return entity.map(item => PipelinesLTSASubTypesDisplay.CreateInstance(item));
    }
}

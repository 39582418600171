<div class="content-modal">
    <div class="container-modal mb-6">
        <div class="grid col-12 mt-2">
        <div class="col-6">
            <label class="col-4 font-bold text-xs text-right px-0 mt-1">{{'clearLock.cellInfo' | translate}}</label>
            <div>
                <app-base-mvt-table-selector
                    #mvtEntityAssociatorComponent
                    [entitiesArray]="cellContainerData?.cell"
                    [columnsInfo]="getColumnsInfoCELL()"
                    [showHeader]="false"
                    [showAddRowButton]="false"
                    [showPaginator]="true"
                    [sortingMode]="'single'"
                    [emptyMessage]="notFound"
                    [defaultSortingProperty]="''"
                    [componentDescription]="''">
                </app-base-mvt-table-selector>
            </div>
        </div>
        <div class="col-6">
            <label class="col-4 font-bold text-xs text-right px-0 mt-1">{{'clearLock.cgllInfo' | translate}}</label>
            <div>
                <app-base-mvt-table-selector
                    #mvtEntityAssociatorComponent
                    [entitiesArray]="cellContainerData?.cgll"
                    [columnsInfo]="getColumnsInfoCGLL()"
                    [showAddRowButton]="false"
                    [showPaginator]="true"
                    [sortingMode]="'single'"
                    [emptyMessage]="notFound"
                    [defaultSortingProperty]="''"
                    [componentDescription]="''">
                </app-base-mvt-table-selector>
            </div>
        </div>
        </div>
    </div>
</div>

import { Bean } from "../../bean";

export class PlantsGreenhouseGasEmissions extends Bean {

    plantId: number;
    emissionsYear: string;
    co2Amount:string;
    comments:string;
    lastDate: Date;
    lastUser: string;
    mvOrder: number;
    mvId: string;

    constructor(entity?: any) {
        super(entity);

        this.plantId = entity?.plantId ?? null;
        this.emissionsYear = entity?.emissionsYear ?? null;
        this.co2Amount = entity?.co2Amount != null ? String(entity.co2Amount) : null;
        this.comments = entity?.comments ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.mvId = entity?.mvId ?? null;
    }
}




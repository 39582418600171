import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { LoadWheelService } from 'src/app/components/load-wheel/load-wheel.service';
import { AlertStatusValue, Constants } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';

@Component({
    selector: 'app-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss']
})

export class AlertModalComponent implements OnInit {
    subject: string;
    comments: string;
    disabledElements: boolean = false
    entityName: string;
    alertId: number;
    alertKind: string;

    constructor(
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public alertService: AlertService,
        public translate: TranslateService,
        public loadWheelService: LoadWheelService) {
    }

    ngOnInit(): void {
        this.subject = this.config.data?.subject;
        this.comments = this.config.data?.comments;
        this.alertId = this.config.data?.id;
        this.alertKind = this.config.data?.alertKind;
        this.entityName = this.config.data?.entityName;
        this.disabledElements = this.config.data?.disabledElements || 
                                    this.config.data?.status === AlertStatusValue.Completed || 
                                    (this.config.data?.iseScId === null  && this.entityName !== EntityName.REGION_ALERTING);        
    }

    saveAlert(){
        if(this.entityName === EntityName.PLANT || this.entityName === EntityName.REGION_ALERTING){
            this.alertService.updateAlert(this.alertId, this.alertKind, this.subject, this.comments).subscribe(response => {
                this.activeModal.close(true);
            });
        }else{
            this.activeModal.close(true);
        }
    }

    closeModal() {
        this.activeModal.close()
    }

}

<div id="mainSection">
    
    <app-base-mvt-table-selector
        #mvtEntityAssociatorComponent
        [entitiesArray]="entitiesArray" 
        [parentEntityId]="parentEntityId"
        [editable]="editable" 
        [disabledButton]="disabledButton"
        (onChanges)="onEntityChanges($event)"
        
        [tKeyComponentTitle]="'plant.plantProduct.title'" 
        [entityName]="'plant.plantProduct.title' | translate"
        [columnsInfo]="getColumnsInfo()"
        [extraEntityPropertiesInfo]="getExtraEntityPropertiesInfo()"
        
        [openSearchModal]="openSearchModal"
        [createNewEntityInstance]="createNewEntityInstance"
        [searchEntityDelegate]="searchEntityDelegate"
        
        [implementedComponent]="this"
        [baseIndex]="baseIndex">
    </app-base-mvt-table-selector>
    
</div>
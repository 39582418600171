import { AlertKindValue, AlertStatusValue, Constants } from "./constants";
import { EntityName } from "./enumerations/entity-name";

export class EntityAlertHistory {
    public id:number;
    public subject: string;
    public kind: string;
    public kindDesc: string;
    public type: string;
    public lastShceduled: string;
    public lastShceduledUser: string;
    public lastSent: string;
    public lastSentUser: string;
    public entry: string;
    public entryUser: string;
    public status: string;
    public statusDesc: string;
    public completeStatusDesc: string;
    public comments: string;
    public iseScId: number;

    public delay: string;
    public scheduled: string;
    public entityName: string;

    private constructor(id: number, 
                        subject: string, 
                        kind: string,
                        kindDesc: string, 
                        type: string, 
                        lastShceduled: string,
                        lastShceduledUser: string,
                        lastSent: string, 
                        lastSentUser: string, 
                        entry: string, 
                        entryUser: string, 
                        status: string,
                        comments: string, 
                        iseScId: number,
                        delay: string,
                        scheduled: string) {
        this.id = id;
        this.subject = subject;
        this.kind = kind;
        this.kindDesc = kindDesc;
        this.type = type;
        this.lastShceduled = lastShceduled;
        this.lastShceduledUser = lastShceduledUser;
        this.lastSent = lastSent;
        this.lastSentUser = lastSentUser;
        this.entry = entry;
        this.entryUser = entryUser;
        this.status = status;
        this.comments = comments;
        this.iseScId = iseScId;
        this.delay = delay;
        this.scheduled = scheduled;

        if(kind === AlertKindValue.OfflineEvent)
		{
			this.entityName = EntityName.OFFLINE_EVENT;
		}
        else if(kind === AlertKindValue.PlantInfo)
        {
            this.entityName = EntityName.PLANT;
        }
        else if(kind.startsWith('REGION_'))
        {
            this.entityName = EntityName.REGION_ALERTING;
        }
        this.completeStatusDesc = '';
        switch(status){
            case "C":
                this.statusDesc = AlertStatusValue.Completed;
                this.completeStatusDesc = this.statusDesc;
                break;
            case "E":
                this.statusDesc = AlertStatusValue.Error;
                this.completeStatusDesc = this.statusDesc;
                break;
            case "P":
                this.statusDesc = AlertStatusValue.Processing;
                this.completeStatusDesc = this.statusDesc;
                break;
            case "Q":
                this.statusDesc = AlertStatusValue.Queued;
                this.completeStatusDesc = this.statusDesc;
                break;
            case "S":
                this.statusDesc = AlertStatusValue.Scheduled;
                this.completeStatusDesc = AlertStatusValue.Scheduled +
                    (Number(this.delay) > 0 ? "\n(" + this.delay + " min delay)" : '');
                break;
            default:
                this.statusDesc = lastShceduledUser === null ? AlertStatusValue.Unscheduled: AlertStatusValue.Completed;
                this.completeStatusDesc = this.statusDesc;
        }

    }

    static BuildiList(entity: Array<any>): Array<EntityAlertHistory> {
        return entity.map(item => EntityAlertHistory.CreateInstance(item));
    }

    private static CreateInstance(entity: any): EntityAlertHistory {
        return new EntityAlertHistory(
            entity.ALERT_ID,
            entity.ALERT_TITLE,
            entity.KIND,
            entity.KIND_DESC,
            entity.ALERT_TYPE,
            entity.LAST_SCHEDULED_DATE,
            entity.LAST_SCHEDULED_USER,
            entity.LAST_SENT,
            entity.LAST_SENT_USER,
            entity.ENTRY_DATE,
            entity.ENTRY_USER,
            entity.STATUS,
            entity.COMMENTS,
            entity.ISE_SC_ID,
            entity.ALERT_DELAY,
            entity.EVER_SCHEDULED);
    }

}

export class AlertInfo{
    public rto:string;
    public nerc: string;
    public padd: string;
    public gas: string;
    public market: string;

    private constructor(rto: string, nerc: string,
            padd: string, gas: string, market: string) {
        this.rto = rto;
        this.nerc = nerc;
        this.padd = padd;
        this.gas = gas;
        this.market = market;
    }

    static BuildiList(entity: Array<any>): Array<AlertInfo> {
        return entity.map(item => AlertInfo.CreateInstance(item));
    }

    static CreateInstance(entity: any): AlertInfo {
        return new AlertInfo(
            entity.CONTROL_AREA_ID,
            entity.NERC_REGION,
            entity.PADD_REGION,
            entity.GAS_REGION,
            entity.MARKET_REGION);
    }

}

export class AlertDTO {
    public alertKind:string;
    public entityId:string;
    public alertTitles:string;
    public alertType:string;
    public alertDelay:number;
    public comments:string;
    public entryUser:string;
    public unitIds:Array<Number> = null;
    public outageIds:Array<Number> = null;
    public schedule: boolean;

    constructor(alertKind: string, entityId: string,
                alertTitles: string, alertType: string, alertDelay: number,
                comments: string, entryUser: string, unitIds: Array<Number>,
                outageIds: Array<Number>, schedule: boolean) {
        this.alertKind = alertKind;
        this.entityId = entityId;
        this.alertTitles = alertTitles;
        this.alertType = alertType;
        this.alertDelay = alertDelay;
        this.comments = comments;
        this.entryUser = entryUser;
        this.unitIds = unitIds;
        this.outageIds = outageIds;
        this.schedule = schedule;
    }

}

export class AlertDelay{
    public constructor(
        public id: string,
        public desc: string
    ) {}

    static getGenericList() : Array<AlertDelay>  {
        return [
        {id:"0", desc:"No Delay"},
        {id:"5",  desc:"5 Minutes"},
        {id:"15",  desc:"15 Minutes"},
        {id:"30",  desc:"30 Minutes"},
        {id:"45",  desc:"45 Minutes"},
        {id:"60",  desc:"60 Minutes"}
        ];
    }

}

export class AlertType{
    public constructor(
        public id: string,
        public desc: string
    ) {}

    static getPlantGenericList() : Array<AlertType>  {
        return [
            {id:"Plant Info",  desc:"Plant Info"}
        ];
    }

    static getOfflineGenericList() : Array<AlertType>  {
        return [
            {id:"Rescheduled",  desc:"Rescheduled"},
            {id:"Forced",  desc:"Forced"},
            {id:"Derate",  desc:"Derate"},
            {id:"New",  desc:"New"},
            {id:"Planned",  desc:"Planned"},
            {id:"Unplanned",  desc:"Unplanned"},
            {id:"Rate Cut",  desc:"Rate Cut"}
        ];
    }

}



import { Injectable, Renderer2 } from "@angular/core";
import { BehaviorSubject, debounceTime } from "rxjs";
import { HexaColor } from "../models/constants";

export enum HTMLType {
    a = 'a',
    area = 'area',
    button = 'button',
    input = 'input',
    select = 'select',
    textarea = 'textarea',
    scrolltop = 'p-scrolltop',
    th = 'th',
    td = 'td',
    tr = 'tr',
    lu = 'lu',
    li = 'li',
    iframe = 'iframe',
    span = 'span',
    object = 'object',
    ptable = 'p-table',
    ppaginator = 'p-paginator',
    pdropdown = 'p-dropdown'
}

const BASIC_FOCUSABLE_ELEMENTS = [
    HTMLType.a,
    HTMLType.area,
    HTMLType.button,
    HTMLType.input,
    HTMLType.select,
    HTMLType.textarea,
];

const COMPOSITE_FOCUSABLE_ELEMENTS = [
    HTMLType.th,
    HTMLType.tr,
    HTMLType.li
];


@Injectable({
    providedIn: 'root'
})
export class TabIndexService {
    private tabIndexSubject$ = new BehaviorSubject<any>(null);
    tabIndexEvent$ = this.tabIndexSubject$.asObservable();

    constructor() {
        const tabIndexEvents = this.tabIndexEvent$.pipe(debounceTime(500));
        tabIndexEvents.subscribe((data: any) => {
            if (data) {
                let baseElement = data.element;
                const renderer = data.renderer;
                if(baseElement === null) {
                    baseElement = document.getElementsByTagName('body')[0];
                }
                setTimeout(() => {
                    this.setTabindexRecursively(baseElement, renderer);
                    this.setTabIndexToPScrollTop(renderer);

                    // Only for debug propose
                    //this.markTabIndexes(renderer);
                }, 500);
            }
        });
    }

    setTabindexRecursively(element: HTMLElement, renderer: Renderer2) {
        const isFocusableElement = this.isFocusableElement(element);
        const isCompositeFocusableElement = this.isCompositeFocusableElement(element);

        if (isFocusableElement) {
            const tabIndex = element.getAttribute('tabindex');
            if (tabIndex == null || tabIndex == undefined || tabIndex == '0') {
                renderer.setAttribute(element, 'tabindex', '-1');
            }
        }

        if (!isFocusableElement || isCompositeFocusableElement) {
            const children = element.children;
            for (let i = 0; i < children.length; i++) {
                this.setTabindexRecursively(children[i] as HTMLElement, renderer);
            }
        }
    }

    setTabIndexToPScrollTop(renderer: Renderer2) {
        const pScrollTop = document.getElementsByTagName('p-scrolltop')[0];

        if (pScrollTop) {
            const pScrollTopBaseIndex = pScrollTop.getAttribute('baseindex');
            const pScrollTopTabindex = parseInt(pScrollTop.getAttribute('tabindex') ?? pScrollTopBaseIndex);
            const pScrollTopThreshold = parseInt(pScrollTop.getAttribute('ng-reflect-threshold'));

            if (pScrollTopTabindex > 0) {
                renderer.listen(window, 'scroll', () => {
                    const scrollPosition = window.scrollY ?? document.documentElement.scrollTop;
                    if (scrollPosition > pScrollTopThreshold) {
                        const buttonElement = pScrollTop.querySelector('button');
                        if (buttonElement && buttonElement.getAttribute('tabindex') !== pScrollTopBaseIndex) {
                            renderer.setAttribute(buttonElement, 'tabindex', pScrollTopBaseIndex);
                            renderer.setAttribute(pScrollTop, 'tabindex', '-1');
                        }
                    } else {
                        if (pScrollTop.getAttribute('tabindex') !== pScrollTopBaseIndex) {
                            renderer.setAttribute(pScrollTop, 'tabindex', pScrollTopBaseIndex);
                        }
                    }
                });
            }
        }
    }

    disableAll(renderer: Renderer2) {
        this.tabIndexSubject$.next({element: null, renderer});
    }

    disableAllFrom(element: HTMLElement, renderer: Renderer2) {
        this.tabIndexSubject$.next({element, renderer});
    }

    private markTabIndexes(renderer: Renderer2) {
        let elements = document.querySelectorAll('[tabindex="0"]');
        elements.forEach((element: any) => {
            element.style.border = '1px solid red';
        });

        elements = document.querySelectorAll('[tabindex="-1"]');
        elements.forEach((element: any) => {
            element.style.border = '1px solid green';
        });

        const tabIndexCheckedArray = [];
        elements = document.querySelectorAll('[tabindex]');
        elements.forEach((elemento: HTMLElement) => {
            const tabindex = elemento.getAttribute('tabindex');

            if (tabindex && parseInt(tabindex) > 0) {
                const tooltip = renderer.createElement('div');
                renderer.addClass(tooltip, 'tb_tooltip');

                const texto = renderer.createText(tabindex);
                const tagName = elemento.tagName.toLowerCase();
                const role = elemento.getAttribute('role');
                const type = elemento.getAttribute('type');
                const comboboxOrCheckbox = role === 'combobox' || type === 'checkbox';
                const isCalendar = elemento.classList.contains('calendar-inputtext');
                const tabIndexMax = Math.max.apply(null, tabIndexCheckedArray);

                let bgColor = tabIndexMax > tabindex ? 'orange' : 'green';
                if (tabIndexCheckedArray.indexOf(tabindex) === -1) {
                    tabIndexCheckedArray.push(tabindex);
                } else {
                    bgColor = 'red';
                }

                renderer.setStyle(tooltip, 'position', 'absolute');
                renderer.setStyle(tooltip, 'background-color', bgColor);
                renderer.setStyle(tooltip, 'color', HexaColor.white);
                renderer.setStyle(tooltip, 'border', `1px solid ${HexaColor.silver}`);
                renderer.setStyle(tooltip, 'padding', '4px');
                renderer.setStyle(tooltip, 'border-radius', '100px');
                renderer.setStyle(tooltip, 'font-size', '10px');
                renderer.setStyle(tooltip, 'max-width', '30px');
                renderer.setStyle(tooltip, 'text-align', 'center');
                renderer.setStyle(tooltip, 'z-index', '9999');

                const posicion = elemento.getBoundingClientRect();
                const topPosition = comboboxOrCheckbox ? posicion.top - 12 : posicion.top;
                renderer.setStyle(tooltip, 'top', `${topPosition}px`);
                renderer.setStyle(tooltip, 'left', `${posicion.right}px`);

                renderer.appendChild(tooltip, texto);

                let elementToAppend = elemento;
                if (tagName === 'input' || tagName === 'button' || tagName === 'span' || tagName === 'textarea' || tagName === 'checkbox') {
                    elementToAppend = elemento.parentElement;
                    if (comboboxOrCheckbox || isCalendar) {
                        elementToAppend = elementToAppend.parentElement.parentElement;
                    }
                }

                const tooltipDiv = elementToAppend.querySelector('.tb_tooltip');
                if (tooltipDiv) {
                    renderer.removeChild(elementToAppend, tooltipDiv);
                }
                renderer.appendChild(elementToAppend, tooltip);
            }
        });
    }

    private isFocusableElement(element: HTMLElement): boolean {
        const htmlType = HTMLType[element.tagName.toLowerCase()];
        if (htmlType === undefined) {
            return false;
        }

        const focusableElements = [...BASIC_FOCUSABLE_ELEMENTS, ...COMPOSITE_FOCUSABLE_ELEMENTS];
        return focusableElements.includes(htmlType);
    }

    private isCompositeFocusableElement(element: HTMLElement): boolean {
        const htmlType = HTMLType[element.tagName.toLowerCase()];
        if (htmlType === undefined) {
            return false;
        }
        return COMPOSITE_FOCUSABLE_ELEMENTS.includes(htmlType);
    }

}


import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class WebsiteService {

    constructor(private session: SessionService) { }

    getSSOURL(path: string = ""): string {
        const websiteUrl = this.session.getWebsiteUrl();
        const sessionId = this.session.getSessionId();

        if (!path.includes('?')) {
            path += '?';
        }

        path += `&qiSessionId=${sessionId}`;

        return websiteUrl + path;
    }

    goToWebsite(path: string = "", target: string = "_blank"): void {
        window.open(this.getSSOURL(path), target);
    }

    goToWebsiteUsingPOST(path: string, target: string, params: any) {
        const websiteUrl = this.session.getWebsiteUrl();
        const sessionId = this.session.getSessionId();
        params.qiSessionId = sessionId;

        const tForm = document.createElement('form');
        tForm.action = websiteUrl + path;
        tForm.target = target;
        tForm.method = 'POST';
        Object.keys(params).forEach(key => {
            const tInput = document.createElement('input');
            tInput.type = 'hidden';
            tInput.name = key;
            tInput.value = params[key];
            tForm.appendChild(tInput);
        });
        document.body.appendChild(tForm);
        tForm.submit();
    }

}
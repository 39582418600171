import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OfflineEvent } from 'src/app/core/models/common';

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    constructor(
        private http: HttpClient) {}

    offlineEventsByUnitIds(unitIds: Array<number>): Observable<OfflineEvent[]> {
        const params = new HttpParams()
            .append('unitIds', unitIds.join(', '));
        return this.http.get<OfflineEvent>(`${environment.apiUrl}unit/offlineEventsByUnitIds`, { params })
            .pipe(
                map((data: any) =>  OfflineEvent.BuildOfflineEvents(data.response))
            );
    }

   
}

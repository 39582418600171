<div class="container-modal">
    <p-messages></p-messages>
    <div class="Ex-content-modal">
        <div class="grid -mt-1">
            <!-- Clear btn -->
            <div class="col-1 min-w-max">
                <button (click)="clear()" class="btn-custom">{{'common.clear'| translate}}</button>
            </div>
            <!-- Search label -->
            <div class="col-1 mt-1 flex justify-content-end min-w-max">
                <span class="custom-label">{{'common.search'| translate}}: </span>
            </div>

            <!-- formGroupName="OptionsFilters" -->
            <div class="col-1 min-w-max">
                <p-radioButton name="offshore" [(ngModel)]="searchParams.offshore" value="0"
                    label="{{'project.searchProject.onshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-3 min-w-max">
                <p-radioButton name="offshore" [(ngModel)]="searchParams.offshore" value="1"
                    label="{{'project.searchProject.offshore'| translate}}" (onClick)="onOffshoreChange()">
                </p-radioButton>
            </div>
            <div class="col-6"></div>
        </div>
        
            <form class="mt-2">
                <div class="grid">
                        <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">
                       
                        <!-- Project ID -->
                        <div class="flex mb-1 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.projectID'| translate}}:</label>
                            <input 
                                class="col-6 mx-1 form__input-field-style w-6 pl-2"  
                                type="text"   
                                name="projectID" 
                                (keydown.enter)="search()" 
                                #projectId
                                [(ngModel)]="searchParams.projectID" 
                                #ownerName 
                                pInputText  
                                maxlength="80"  
                                autofocus 
                                appOnlyNumbers 
                                appFocusElement 
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        
                        <!-- Project Status -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.status'| translate}}:</label>
                            <div class="col-6 px-1">
                                <p-listbox 
                                    (keydown)="handleListboxKeydown($event)"
                                    (keydown.tab)="plantIdInput.focus()"
                                    emptyMessage=" " 
                                    [listStyle]="{'height':'90px', 'width':'max','font-size': '12px'}"
                                    [(ngModel)]="searchParams.projectStatus" 
                                    [multiple]="true" (keydown.enter)="search()" 
                                    [options]="projectStatuses"
                                    name="projectStatus" 
                                    optionLabel="description" 
                                    optionValue="statusID">
                                </p-listbox>
                            </div>
                            <div class="col-3 px-0"></div>
                        </div>

                        <!-- Plant ID -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.plantID'| translate}}:</label>
                            
                                <input #plantIdInput
                                    class="col-6 mx-1 form__input-field-style w-6"  
                                    type="text"   
                                    name="plantID" (keydown.enter)="search()"
                                    [(ngModel)]="searchParams.plantID" 
                                    pInputText  
                                    maxlength="80" 
                                    appOnlyNumbers  
                                />         
                            <div class="col-3 px-0">
                                <button 
                                    pButton 
                                    pRipple 
                                    type="button" 
                                    icon="pi pi-search"
                                    class="p-button-text icon-container" 
                                    (click)="openPlantSearchModal()">
                                </button>
                            </div>
                        </div>

                        <!-- Owner Co. ID -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.ownerID'| translate}}:</label>
                            <input 
                                class="col-6 mx-1 form__input-field-style w-6"  
                                type="text"   
                                appOnlyNumbers 
                                name="ownerID" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.ownerID" 
                                pInputText  
                                maxlength="80"  
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                    
                    <div class="col-12 lg:col-6 xl:col-6 -mt-3 lg:mt-2 xl:mt-2">
                        <!-- Owner Co. Name -->
                        <div class="flex mb-1 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.ownerName'| translate}}:</label>
                            <input 
                                class="col-6 mx-1 form__input-field-style w-6"  
                                type="text"   
                                name="ownerName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.ownerName" 
                                pInputText  
                                maxlength="80"  
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Project Name -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.projectName'| translate}}:</label>
                            <input 
                                class="col-6 mx-1 form__input-field-style w-6" 
                                type="text"   
                                name="projectName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.projectName" 
                                pInputText  
                                maxlength="80" 
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- PEC Zone -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1" *ngIf="searchParams.offshore === '0'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.pecZone'| translate}}:</label>
                            <input
                                class="col-6 mx-1 form__input-field-style w-6"  
                                type="text"   
                                name="pecZone" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.pecZone" 
                                pInputText  
                                maxlength="80" 
                                #pecZoneInput 
                            />
                            <div class="col-3 px-0">
                                <button 
                                    pButton 
                                    pRipple 
                                    type="button" 
                                    icon="pi pi-search"
                                    class="p-button-text icon-container" 
                                    (click)="openZoneSearchModal()">
                                </button>
                            </div>
                        </div>
                        <!-- Country -->
                        <div class="flex mb-1 align-selects -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.country'| translate}}:</label>
                            <p-dropdown  
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="physCountries" 
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true" 
                                filterBy="countryId,countryNameConcat" 
                                resetFilterOnHide="false"
                                autofocusFilter="true" 
                                (keyup.enter)="search()" 
                                (onChange)="onChangePhysCountry()"
                                name="country" 
                                [(ngModel)]="searchParams.country"
                                optionLabel="countryNameConcat" 
                                optionValue="countryId">
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Waterbody (Offshore) -->
                        <div class="flex mb-1 align-selects -mt-3 pt-1"  *ngIf="searchParams.offshore === '1'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.waterBody'| translate}}:</label>
                            <p-dropdown  
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4" 
                                [disabled]="searchParams.country === '' || searchParams.country === null"
                                [options]="waterBodies" 
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true" 
                                filterBy="waterBodyId,waterBodyName" 
                                resetFilterOnHide="false"
                                autofocusFilter="true" 
                                (keyup.enter)="search()" 
                                (onChange)="onChangePhysWaterBody()"
                                name="waterBody" 
                                [(ngModel)]="searchParams.waterBody"
                                optionLabel="waterBodyNameConcat" 
                                optionValue="waterBodyId">
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Field Name (Offshore) -->
                        <div class="flex mb-1 align-items-center -mt-3 pt-1"  *ngIf="searchParams.offshore === '1'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.fieldName'| translate}}:</label>
                            <input 
                                class="col-6 mx-1 form__input-field-style w-6" 
                                type="text"   
                                name="fieldName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.fieldName" 
                                pInputText  
                                maxlength="80"  
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- State -->
                        <div class="flex mb-1 align-selects -mt-3 pt-1"  *ngIf="searchParams.offshore === '0'">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.state'| translate}}:</label>
                            <p-dropdown  
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="physStates" 
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true" 
                                [disabled]="searchParams.country === '' || searchParams.country === null"
                                filterBy="sId,stateConcat" 
                                resetFilterOnHide="true" 
                                autofocusFilter="false"
                                (keyup.enter)="search()" 
                                name="state" 
                                [(ngModel)]="searchParams.state" 
                                optionLabel="stateConcat" 
                                optionValue="sId">
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Record Status -->
                        <div class="flex mb-1 align-selects -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'project.searchProject.recordStatus'| translate}}:</label>
                            <p-dropdown 
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="recordStatuses" 
                                [filter]="true" 
                                [placeholder]="'common.selectOne' | translate"
                                showClear="true"
                                filterBy="statusID,description" 
                                resetFilterOnHide="true"
                                autofocusFilter="true" 
                                (keyup.enter)="search()" 
                                name="recordStatus"
                                [(ngModel)]="searchParams.recordStatus" 
                                optionLabel="description"
                                optionValue="statusID">
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                    </div>
                </div>
            </form>
        
    </div>
    <hr class="hr-modal">
    <!-- Mouse & keyboard user info -->
    <div class="grid main-content pt-1 md:pt-0 lg:pt-0 xl:pt-0">
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'| translate}}</span>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}: </strong>{{'common.searchKeyboardUsers'| translate}}</span>
            </div>
    </div>
     <!-- Data table -->
    <div class="container-table-modal">
        <p-table 
            #searchTable ignoreHeaders
            *ngIf="searchParams.offshore === '0'" 
            [value]="projects" 
            selectionMode="single" 
            [rows]="rowsPerPage"
            (onPage)="onPageChange($event)"
            [showCurrentPageReport]="true" 
            [columns]="colsOnshore" 
            [resizableColumns]="true"
            styleClass="p-datatable-gridlines" 
            [reorderableColumns]="true" 
            (sortFunction)="customSort($event)"
            [customSort]="true" 
            [rowsPerPageOptions]="rowsPerPageOptions" 
            [paginator]="true" [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns tabindex="-1">
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, projects)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}} pl-2" pTooltip="{{getRowTooltip(rowData)}}"
                        [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable" [ngStyle]="getRowStyle(rowData)">
                            <div>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-table #searchTable ignoreHeaders *ngIf="searchParams.offshore === '1'" [value]="projects" selectionMode="single" [rows]="rowsPerPage"
            [showCurrentPageReport]="true" [columns]="colsOffshore" [resizableColumns]="true"
            styleClass="p-datatable-gridlines" [reorderableColumns]="true" (sortFunction)="customSort($event)"
            [customSort]="true" [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [(selection)]="this.additionalInfo"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
            <ng-template pTemplate="header" let-columns tabindex="-1">
                <tr>
                    <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                        [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" [style]="col.style">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                    (keydown)="changeSelectionOnArrowKey($event, projects)"
                    (click)="onRowSelect(rowData)"
                    (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                    <td *ngFor="let col of columns" class="{{col.field}}" 
                        pTooltip="{{getRowTooltip(rowData)}}" [style.white-space]="col.wSpace || 'normal'">
                        <div *ngIf="!col.editable" [ngStyle]="getRowStyle(rowData)">
                            <div>{{rowData[col.field]}}</div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="container-info-modal main-content">
            <div class="grid card-modal mt-2">
                <div class="col-12 header-info">
                    <span>{{'common.additionalInfo'| translate}}:</span>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.projectID' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.projectID }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.reviewDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.reviewDate | date: 'dd-MMM-y'}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.completionDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.completionDate | date: 'dd-MMM-y'}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.afeDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.afeDate}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.rfqDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.rfqDate}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.status' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.projectStatus }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.timing' | translate}}:</label>
                        <label for="" class="custom-label-card-info">
                            {{ additionalInfo?.pecTiming ? '(' + additionalInfo.pecTiming + ')' : '' }}
                        </label>

                        <label class="custom-label-card-right-short">{{'project.additionalInfo.activity' | translate}}:</label>
                        <label for="" class="custom-label-card-info">
                            {{ additionalInfo?.pecActivity ? '(' + additionalInfo.pecActivity + ')' : '' }}
                        </label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.tiv' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.tiv  | currency | slice:0:-3 }}</label>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.projectName' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.projectName }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.ownerName' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.ownerName }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.pecZone' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.pecZoneSimplex }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.bidDocDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.bidDocDate}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.kickoffDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.kickoffDate}}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.recordStatus' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.recordStatusDesc }}</label>
                    </div>
                    <div class="content-card" >
                        <label class="custom-label-card-right-short">{{'project.additionalInfo.qcDate' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'dd-MMM-y'}}</label>
                    </div>
                </div>
            </div>
    </div>
</div>

<div class="container-modal">
    <p-messages></p-messages>
    <div class="Ex-content-modal">
        <div class="mt-1">
            <button (click)="clear()" class="btn-custom">{{'common.clear'| translate}}</button>
        </div>
        <form class="mt-2">
            <div class="grid">
				<div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">
                    <!-- Unit Name -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.unitName'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"  
                                name="unitName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.unitName" 
                                #inputUnitName 
                                pInputText  
                                maxlength="80" 
                                autofocus 
                                appFocusElement 
                            />
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>            
                    <!-- Owner Name -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.ownerName'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"   
                                name="ownerName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.ownerName" 
                                pInputText  
                                maxlength="80"  
                            />
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Unit ID -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.unitId'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">    
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"  
                                name="unitId" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.unitId" 
                                #inputUnitId 
                                pInputText  
                                maxlength="7" 
                                autofocus 
                                appOnlyNumbers 
                            />
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Plant ID -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.plantId'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">    
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"  
                                name="plantId" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.plantId" 
                                #inputPlantId 
                                pInputText  
                                maxlength="7" 
                                autofocus 
                                appOnlyNumbers 
                            />
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>   
                    <!-- Plant Name   -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.plantName'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"   
                                name="plantName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.plantName" 
                                pInputText  
                                maxlength="80"  
                            />
                        </div>    
                        <div class="col-3 px-0"></div>
                    </div>
                </div>
                <div class="col-12 lg:col-6 xl:col-6 -mt-3 lg:mt-2 xl:mt-2">
                    <!-- Equip Name -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.equipmentName'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"   
                                name="equipmentName" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.equipmentName" 
                                pInputText  
                                maxlength="80"  
                            />
                        </div>
                        <div class="col-3 px-0"></div>
                    </div>
                    <!-- Equip Type -->
                    <div class="flex -mt-3 pt-1 align-items-center">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.equipmentType'| translate}}:</label>
                        <label *ngIf="!isMainAccess" class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'Equipment Type Name'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">    
                            <input 
                                class="form__input-field-style w-full"  
                                type="text"  
                                name="equipmentType" 
                                (keydown.enter)="search()"
                                [(ngModel)]="searchParams.equipmentTypeName" 
                                #inputEquipmentType 
                                pInputText  
                                maxlength="80" 
                                autofocus
                            />
                        </div>
                        <div class="col-3 px-0">
                                <button pButton pRipple type="button" icon="pi pi-search"
                                class="p-button-text icon-container" (click)="openEquipmentTypeSearchModal()">
                                </button>
                        </div>
                    </div>
                    <!-- SIC Code -->
                    <div class="flex -mt-3 pt-1">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.sicCode'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <p-dropdown 
                                    class="form__container-fields-layout--selects w-full"
                                    [options]="driveSicCodes"
                                    [placeholder]="'common.selectOne' | translate" 
                                    showClear="true"
                                    [filter]="true" 
                                    filterBy="sicCode,sicCodeDescription" 
                                    resetFilterOnHide="true"
                                    autofocusFilter="true" 
                                    (keyup.enter)="search()" 
                                    name="sicCode"
                                    [(ngModel)]="searchParams.sicCode" 
                                    optionLabel="sicCodeDescription"
                                    optionValue="sicCode">
                            </p-dropdown>
                        </div>
                        <div class="col-1 lg:col-3 xl:col-3 px-0"></div>
                    </div>
                    <!-- Industry Code -->
                    <div class="flex -mt-3 pt-1">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.industryCode'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <p-dropdown 
                                class="form__container-fields-layout--selects w-full"
                                [options]="industryCodes"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                [filter]="true" 
                                filterBy="industryCode,industryDescription" 
                                resetFilterOnHide="true"
                                autofocusFilter="true" 
                                (keyup.enter)="search()" 
                                name="industryCode"
                                [(ngModel)]="searchParams.industryCode" 
                                optionLabel="industryDescription"
                                optionValue="industryCode">
                            </p-dropdown>
                        </div>
                        <div class="col-1 lg:col-3 xl:col-3 px-0"></div>
                    </div>
                    <!-- Record Status -->
                    <div class="flex -mt-3 pt-1">
                        <label class="col-4 lg:col-3 xl:col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'equipment.searchEquipment.recordStatus'| translate}}:</label>
                        <div class="col-7 lg:col-6 xl:col-6 mx-1 pl-0">
                            <p-dropdown 
                                class="form__container-fields-layout--selects w-full"
                                [options]="recordStatuses"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                [filter]="true" 
                                filterBy="RecordStatusID,RecordStatusDescription" 
                                resetFilterOnHide="true"
                                autofocusFilter="true" 
                                (keyup.enter)="search()" 
                                name="recordStatus"
                                [(ngModel)]="searchParams.recordStatus" 
                                optionLabel="RecordStatusDescription"
                                optionValue="RecordStatusID">
                            </p-dropdown>
                        </div>
                        <div class="col-1 lg:col-3 xl:col-3 px-0"></div>
                    </div>
                </div>
            </div>
        </form>
   
    </div>
    <hr class="hr-modal">
    <div class="grid main-content pt-1 lg:pt-0 xl:pt-0">
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'| translate}}</span>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}: </strong>{{'common.searchKeyboardUsers'| translate}}</span>
            </div>
    </div>
    <!-- Data table -->
    <div class="container-table-modal">
        <div class="company-table">
            <p-table #searchTable ignoreHeaders [value]="equipments" 
                selectionMode="single" [rows]="rowsPerPage"
                (onPage)="onPageChange($event)" 
                [showCurrentPageReport]="true"
                [columns]="cols" 
                [resizableColumns]="true" 
                styleClass="p-datatable-gridlines" 
                [reorderableColumns]="true"
                (sortFunction)="customSort($event)" 
                [customSort]="true" 
                [rowsPerPageOptions]="rowsPerPageOptions" 
                [paginator]="true"
                [(selection)]="this.additionalInfo" 
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header" let-columns tabindex="-1">
                    <tr>
                        <th *ngFor="let col of columns" [style.width]="col.width" pReorderableColumn pResizableColumn
                            [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style]="col.style">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>
                    <tr #tr [pSelectableRow]="rowData" [pReorderableRow]="index"
                        (keydown)="changeSelectionOnArrowKey($event, equipments)" (click)="onRowSelect(rowData)"
                        (keydown.enter)="onRowDblclick(rowData)" (dblclick)="onRowDblclick(rowData)">
                        <td *ngFor="let col of columns" class="{{col.field}} pl-2" [style.white-space]="col.wSpace || 'normal'">
                            <div *ngIf="!col.editable">
                                <div *ngIf="!col.isDate">{{rowData[col.field]}}</div>
                                <div *ngIf="col.isDate">{{ rowData[col.field] | date: 'dd-MMM-y'}}</div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="container-info-modal main-content">
        <div class="grid card-modal mt-2 ml-0">
            <div class="col-12 header-info">
                <span>{{'additional_info'| translate}}:</span>
            </div>
            
            <div class="col-12 lg:col-6 xl:col-6">
                <div class="content-card" >
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.equipmentName' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.equipmentName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.equipmentType' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.equipmentTypeName }}</label>
                </div>
                <div class="content-card" >
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.equipmentInfo' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.equipmentInfo }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.capacity' | translate}}:</label>
                    <label for="" class="custom-label-card-info">
                        {{ additionalInfo?.capacity | bigNumber }} {{ additionalInfo?.capacity != null ? ' ' : '' }}
                        {{ additionalInfo?.hsUnit != null ? additionalInfo?.hsUnit : '' }}
                    </label>
                </div>
                <div class="content-card" >
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.manufacturer' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.manufacturer }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'common.recordStatus' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.recordStatus }}</label>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-6">
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.ownerName' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.ownerName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.plantName' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.plantName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.areaName' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.areaName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.unitName' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.unitName }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'equipment.searchEquipment.unitId' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.unitId }}</label>
                </div>
                <div class="content-card">
                    <label class="custom-label-card-right-short">{{'common.qcDate' | translate}}:</label>
                    <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'd-MMM-y'}}</label>
                </div>
            </div>
        </div>
    </div>
</div>

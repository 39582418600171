import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { CompaniesDisplay, PlantsDisplay } from 'src/app/core/models';
import { CompaniesGreenhouseGasEmissionsDisplay } from 'src/app/core/models/company/display/companies-greenhouse-gas-emissions-display';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { PlantsGreenhouseGasEmissionsDisplay } from 'src/app/core/models/plant/display/plants-greenhouse-gas-emissions-display';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, ColumnIconButton, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';

@Component({
    selector: 'app-greenhouse-gas-emissions',
    templateUrl: './greenhouse-gas-emissions.component.html',
    styleUrls: ['./greenhouse-gas-emissions.component.scss']
})

export class GreenhouseGasEmissionsComponent implements OnInit {

    @ViewChild('mvtEntityAssociatorComponent', { static: false }) mvtEntityAssociatorComponent: MVTEntityAssociatorComponent;

    @Input() entitiesArray: Array<any> = [];
    @Input() parentEntityId: number;
    @Input() editable: boolean = false;
    @Input() disabledButton: boolean = true;
    @Input() baseIndex: number;
    @Input() entityDisplay: any = null;
    @Input() showAddRowButton = true;
    @Output() onChanges: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public translate: TranslateService,
        public dialogService: DialogService,
        public authService: AuthService,
        private messageHandler: MessageHandlerService,
        public datePipe: DatePipe,
    ) { }

    ngOnInit() {
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ draggable: true, widthPercentage: 5 })
        columnsInfo.push({ isEntityId: true, entityPropName: 'emissionsYear', searchedEntityPropName: 'emissionsYear', columnHeader: 'plant.greenhouseGas.emissionsYear', editableType: ColumnEntityInfoEditableType.numberField, maxLength: 4, widthPercentage: 15 });
        columnsInfo.push({ entityPropName: 'co2Amount', columnHeader: 'plant.greenhouseGas.co2MetricTons', editableType: ColumnEntityInfoEditableType.numberField, maxDecimals: 10, formatThousands: true, maxLength: 17, widthPercentage: 15, headerAlign: 'right', bodyAlign: 'right' });
        columnsInfo.push({ entityPropName: 'lastUser', columnHeader: 'plant.greenhouseGas.lastUser', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage: 35 });
        columnsInfo.push({ displayValueFunc: this.lastDateDisplay, columnHeader: 'plant.greenhouseGas.lastDate', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage: 35 });
        columnsInfo.push({ entityPropName: 'comments', columnHeader: 'plant.greenhouseGas.comments', editableType: ColumnEntityInfoEditableType.commentsField, widthPercentage: 10 });
        columnsInfo.push({ entityPropName: 'delete', editableType: ColumnEntityInfoEditableType.deleteField, widthPercentage: 5 });
        return columnsInfo;
    }

    getExtraEntityPropertiesInfo(): ColumnEntityInfo[] {
        let extraEntityPropertiesInfo: ColumnEntityInfo[] = [];
        extraEntityPropertiesInfo.push({ entityPropName: 'lastDate' });

        return extraEntityPropertiesInfo;
    }

    createNewEntityInstance(implementedComponent: GreenhouseGasEmissionsComponent): any {
        if (implementedComponent.entityDisplay instanceof PlantsDisplay) {
            const itemAux: PlantsGreenhouseGasEmissionsDisplay = PlantsGreenhouseGasEmissionsDisplay.CreateInstance({
                plantId: implementedComponent.parentEntityId,
                mvOrder: MVTOperations.getLastMvOrder(implementedComponent.entitiesArray) + 1
            });
            return itemAux;
        } else if (implementedComponent.entityDisplay instanceof CompaniesDisplay) {
            const itemAux: CompaniesGreenhouseGasEmissionsDisplay = CompaniesGreenhouseGasEmissionsDisplay.CreateInstance({
                companyId: implementedComponent.parentEntityId,
                mvOrder: MVTOperations.getLastMvOrder(implementedComponent.entitiesArray) + 1
            });
            return itemAux;
        }
    }
    

    onEntityChanges(event: any) {
        this.onChanges.emit(event.entitiesArray);
    }

    lastDateDisplay(implementedComponent: GreenhouseGasEmissionsComponent, rowData: any): string {
        return StringUtils.toStringNeverNull(implementedComponent.datePipe.transform(rowData.lastDate, 'dd-MMM-y HH:mm:ss'));
    }

    onCellEdited(implementedComponent: GreenhouseGasEmissionsComponent, field: string, rowData: any, rowIndex: number) {
        if ('emissionsYear' === field) {
            implementedComponent.checkEmissionDate(implementedComponent, rowData, rowIndex)
        }
    }
    checkEmissionDate(implementedComponent: GreenhouseGasEmissionsComponent, rowData: any, rowIndex: number) {
        if (!StringUtils.isEmpty(rowData.emissionsYear)) {
            if (parseInt(rowData.emissionsYear) <= 1900) {
                rowData.emissionsYear = '';
                const message: string = implementedComponent.translate.instant('plant.greenhouseGas.emissionsYearGreatearError')
                this.mvtEntityAssociatorComponent.returnFocus(rowData, message, rowIndex, MessageType.ERROR);
            } else {
                rowData.setOperation(rowData.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
            }
        }
    }


}

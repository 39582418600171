import { IAuditEntityData } from "../interfaces/iaudit-entity-data";
import { ClearLockUtilities } from "../models/company/save/clear-lock-utility-info";

export class ClearLockUtilityDisplay extends ClearLockUtilities {

  constructor(entity?: any) {
    super(entity);
    this.amendmentDate = entity?.amendmentDate ?? null;
    this.amendmentUser = entity?.amendmentUser ?? null;
    this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
    this.entryDate = entity?.entryDate ?? null;
    this.entryUser = entity?.entryUser ?? null;
    this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
    this.lastUser = entity?.lastUser ?? null;
    this.lastDate = entity?.lastDate ?? null;
    this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
    this.prevUser = entity?.previousUser ?? null;
    this.prevDate = entity?.previousDate ?? null;
    this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
    this.qcDate = entity?.qcDate ?? null;
    this.qcUser = entity?.qcUser ?? null;
    this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
    this.wipRemovable = (entity && entity?.wipRemovable) ?? null;
    this.entityName= (entity && entity?.lockDetails?.entityType) ?? null;
    this.entityID= (entity && entity?.lockDetails?.entityID) ?? null;
  }

    static BuildNewLockUtilityDisplay(entity: any): ClearLockUtilityDisplay {
        return new ClearLockUtilityDisplay(entity);
    }

    static BuildClearLockUtilityDisplay(): ClearLockUtilityDisplay {
        return new ClearLockUtilityDisplay(null);
    }

    // section auditEntityBean
    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: this.entityName,
            entityId: this.entityID
        };
    }
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[percentage]'
})
export class PercentageDirective {

  private previousValue: any = '';
  @Input() rowData: any;
  @Input() rowField: any;
  constructor(private elementRef: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    if (! /^(100|([0-9]{0,2}(\.?|(\.[0-9]{1,2})?)))$/.test(input.value)) {
        input.value = this.previousValue;
        input['ng-reflect-model'] = this.previousValue
        if(this.rowData !== undefined && 
            this.rowField !== undefined && 
                this.rowData[this.rowField] !== undefined){
            this.rowData[this.rowField] = input.value;
        }  
    }else{
        this.previousValue = input.value;
    }
  }

}
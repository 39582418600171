import { DatePipe, DecimalPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EditableColumn, Table } from 'primeng/table';
import { Observable, Subject, Subscription } from 'rxjs';
import { EditableTable } from 'src/app/components/global/editable-table';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { FocusableEvent, FocusableEventType } from 'src/app/core/directives/focusable-table.directive';
import { Bean } from 'src/app/core/models/bean';
import { Preference } from 'src/app/core/models/common';
import { Constants, HexaColor } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { LockSameUserMessageService } from 'src/app/core/services/lock-same-user-message.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';
import { EntityPaths } from 'src/app/core/utils/entityPaths';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { HeaderService } from 'src/app/shared/header/header.service';

@Component({
    selector: 'app-base-mvt-table-selector',
    templateUrl: './mvt-entity-associator.component.html',
    styleUrls: ['./mvt-entity-associator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MVTEntityAssociatorComponent extends EditableTable implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('dataTable', { static: false }) dataTable: Table;
    @Input() entitiesArray: Array<any> = [];
    @Input() parentEntityId: number;
    @Input() tableKey: string;
    @Input() useCommonPreference: boolean = false;
    @Input() editable: boolean = false;
    @Input() disabledButton: boolean = false;
    @Input() showHeader: boolean = true;
    @Input() showPaginator: boolean = false;
    @Input() defaultRowsPerPage: number = 5;
    @Input() sortingMode: 'single' | 'multiple' = null;
    @Input() showAddRowButton: boolean = true;
    @Input() selectionMode: string = SelectionModeType.NONE;
    @Input() highlightSeleccion: boolean = false;
    @Input() floatAppendTo: string;
    @Input() future: boolean;
    @Input() descriptionIconSrc: string = null;
    @Input() clearSelectedRowOnPageChange: boolean = true;
    @Output() entitiesArrayChange: EventEmitter<Array<any>> = new EventEmitter();
    @Output() onChanges: EventEmitter<any> = new EventEmitter<any>();
    @ViewChildren(EditableColumn) protected editableColumns: QueryList<EditableColumn>;
    cols: Array<ColumnEntityInfo> = [];
    internalAdditionalInfoFields: Array<ColumnEntityInfo>;
    private disableFormSub: Subscription;
    tableEditEnabled: boolean = true;
    entityIdPropName: string;
    primaryColumnName: string;
    searchedEntityIdPropName: string;
    appendTo: any;
    selectedRow: any;
    selectionModeColumnSelected: boolean = false;
    lastSelectedRow: any;

    @Input() tKeyComponentTitle: string = '';
    @Input() entityName: string = '';
    @Input() columnsInfo: ColumnEntityInfo[] = [];
    @Input() additionalInfoFields: ColumnEntityInfo[] = [];
    @Input() extraEntityPropertiesInfo: ColumnEntityInfo[];
    @Input() errorMessageEntityDoesNotExist: string = null;
    @Input() componentDescription: string = null;
    @Input() defaultSortingProperty: string = "mvOrder";
    @Input() defaultSortingOrder: string = "asc";
    @Input() emptyMessage: string = '';
    @Input() baseIndex?: number = 0;

    @Input() footerStrong?: string = '';
    @Input() footer?: string = '';

    @Input() openSearchModal: (implementedComponent: any, row?: any, rowIndex?: number) => DynamicDialogRef;
    @Input() createNewEntityInstance: (implementedComponent: any) => any;
    @Input() searchEntityDelegate: (implementedComponent: any, row: any) => Observable<any>;
    @Input() searchEntityDelegateAsync: (implementedComponent: any, row: any, rowIndex: number) => Promise<any>;
    @Input() onCellEdited: (implementedComponent: any, field: string, rowData: any, rowIndex?: number) => void;
    @Input() changeDateFunction: (implementedComponent: any, field: string, rowData: any, selectedVerifiedQcDate: number) => void = null;
    @Input() onRowDoubleClick: (implementedComponent: any, rowData: any) => void = null;
    @Input() hasEmptyRows: (implementedComponent: any) => boolean;
    @Input() isInternalSelectionEditable: (implementedComponent: any, rowData: any) => boolean = () => {return true};

    @Input() implementedComponent: any;
    @Input() implementedComponentCDR: ChangeDetectorRef;
    @Input() preferences: Array<Preference> = [];
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;


    focusManager = new Subject<FocusableEvent>();

    constructor(
        public dialogService: DialogService,
        public sharedService: SharedService,
        public messageHandler: MessageHandlerService,
        public cdr: ChangeDetectorRef,
        public headerService: HeaderService,
        public translate: TranslateService,
        public datePipe: DatePipe,
        public decimalPipe: DecimalPipe,
        public entityUtilsService: EntityUtilsService,
        private preferencesService: PreferencesSectionService,
        public lockSameUserMessageService: LockSameUserMessageService
    ) { super(); }

    ngOnInit(): void {
        this.declareTableColumns();
        this.disableFormSub = this.sharedService.disableFormChange$.subscribe((disable: boolean) => {
            this.disabledButton = this.disabledButton || disable;
            this.tableEditEnabled = !disable;
        });

        if(this.hasEmptyRows == null) {
            this.hasEmptyRows = this.hasIncompleteRows;
        }
        if(this.additionalInfoFields != null && this.additionalInfoFields.length > 0) {
            this.highlightSeleccion = true;
        }
    }

    ngAfterViewInit(): void {
        this.focusManager.next(new FocusableEvent(FocusableEventType.ADD_CUSTOM_COLUMNS, this.cols));
        this.focusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.editableColumns));

        if(this.sortingMode != null && !StringUtils.isEmpty(this.defaultSortingProperty)) {
            this.dataTable.sortField = this.defaultSortingProperty;
            this.dataTable.sortOrder = (this.defaultSortingOrder == "desc" ? -1 : 1);
            this.dataTable.sortSingle();
        }
    }

    ngOnChanges(changes: SimpleChanges){
        if (changes.entityName && StringUtils.isEmpty(this.emptyMessage)){
            this.emptyMessage = this.entityName && this.entityName.length > 0 &&
                    (/[aeiouAEIOU]/i).test(this.entityName.substring(0,1))
            ? this.translate.instant('mvtEntityAssociatorComponent.addAnRowDescription'
                ,{entityName: this.entityName} )
            : this.translate.instant('mvtEntityAssociatorComponent.addARowDescription'
                ,{entityName: this.entityName} );
        }
        this.appendTo = this.floatAppendTo ? this.floatAppendTo : this.sharedService.appRightSideElement;

        if(changes.parentEntityId && this.implementedComponentCDR != null) {
            this.implementedComponentCDR.detectChanges();
        }

        if(changes.entitiesArray) {
            this.selectedRow = null;
            this.lastSelectedRow = null;
        }

        if(changes.preferences && this.tableKey && this.preferences) {
            this.defaultRowsPerPage = this.preferencesService.getPaginatorPreference(this.preferences, this.tableKey, this.defaultRowsPerPage);
        }
    }

    setEntitiesArray(entitiesArray: any[]): void {
        this.entitiesArray = entitiesArray;
        this.cdr.detectChanges();
    }

    setColumnsInfo(columnsInfo: any[]): void {
        this.columnsInfo = columnsInfo;
        this.declareTableColumns();
        this.cdr.detectChanges();
    }

    private declareTableColumns(): void {
        if (this.columnsInfo != null && this.isSelectionMode()) {
            this.columnsInfo.push({ entityPropName: ColumnEntityInfo.INTERNAL_SELECTION_COLUMN, columnHeader: '', headerAlign: 'center', editableType: ColumnEntityInfoEditableType.checkboxField, isCellEditable: this.isInternalSelectionEditable, widthPercentage: 5 });
        }
        this.cols = [];
        this.cols.push(...this.convertColumnEntityInfoToTableColumns());
        this.convertAdditionalInfoFields();
    }

    convertColumnEntityInfoToTableColumns(): ColumnEntityInfo[] {
        let columns: ColumnEntityInfo[] = [];
        if (this.columnsInfo != null) {
            for (let columnInfo of this.columnsInfo) {
                columnInfo.mvtEntityAssociatorComponent = this;
                columnInfo = ColumnEntityInfo.CreateInstance(columnInfo)
                if (columnInfo.isEntityId) {
                    this.entityIdPropName = columnInfo.entityPropName;
                    this.searchedEntityIdPropName = columnInfo.searchedEntityPropName;

                    if(this.openSearchModal != null) {
                        columnInfo.openSearchModal = this.openSearchModalAndSetEntityData;
                    }
                    if(this.searchEntityDelegate != null || this.searchEntityDelegateAsync != null) {
                        columnInfo.searchById = this.searchEntity;
                    }
                }
                if(columnInfo.isPrimaryColumn) {
                    this.primaryColumnName = columnInfo.entityPropName;
                }
                if(columnInfo.draggable) {
                    if(columnInfo.getColumnBackgroundColor == null) {
                        columnInfo.getColumnBackgroundColor = this.getFirstDraggableBackgroundColor;
                    }
                    if(columnInfo.getColumnTooltip == null) {
                        columnInfo.getColumnTooltip = this.getFirstDraggableTooltip;
                    }
                }

                columns.push(columnInfo);
            }
        }
        return columns;
    }

    convertAdditionalInfoFields() {
        if (this.additionalInfoFields != null && this.additionalInfoFields.length > 0) {
            this.internalAdditionalInfoFields = [];
            for (let additionalInfoField of this.additionalInfoFields) {
                additionalInfoField.mvtEntityAssociatorComponent = this;
                this.internalAdditionalInfoFields.push(ColumnEntityInfo.CreateInstance(additionalInfoField));
            }
        }
    }

    onSelectionModeColumnEdited(event: any) {
        this.getRowsByPage().forEach(row=> {
            if(this.isInternalSelectionEditable(this.implementedComponent, row)){
                row[ColumnEntityInfo.INTERNAL_SELECTION_COLUMN] = this.selectionModeColumnSelected;
            }
        });
    }

    getRowsByPage(): Array<any> {
        return TableUtils.getRowsByPage(this.dataTable);
    }

    isSelectionMode(): boolean {
        return this.selectionMode != null && this.selectionMode !== SelectionModeType.NONE;
    }

    isHeaderCheckboxDisabled(): boolean {
        return !this.getRowsByPage().some(row => this.isInternalSelectionEditable(this.implementedComponent, row));
    }

    setHeaderCheckboxStatus(): void {
        if(this.isSelectionMode()) {
            let enabledRows = this.getRowsByPage().filter(row =>
                this.isInternalSelectionEditable(this.implementedComponent, row))
            this.selectionModeColumnSelected = enabledRows.length > 0 &&
                enabledRows.every(row => row[ColumnEntityInfo.INTERNAL_SELECTION_COLUMN]);
        }
    }

    onPageChange(event): void {
        if(this.tableKey) {
            if(this.useCommonPreference) {
                this.preferencesService.updateCommonPreferences(this.tableKey, StringUtils.toStringNeverNull(event.rows));
            } else {
                this.preferencesService.updatePreferenceOnPageChange(this.tableKey, StringUtils.toStringNeverNull(event.rows));
            }
        }
        if(this.clearSelectedRowOnPageChange) {
            this.selectedRow = null;
        }
        this.setHeaderCheckboxStatus();
    }

    getSelectedEntities(): Array<any> {
        let selectedEntities: Array<any> = [];
        for (let entity of this.entitiesArray) {
            if(entity[ColumnEntityInfo.INTERNAL_SELECTION_COLUMN] === true) {
                selectedEntities.push(entity);
            }
        }
        return selectedEntities;
    }

    getEntityDisplayName(rowData: any): string {
        let propName: string = null;
        const col: ColumnEntityInfo = this.cols.find(col => col.isEntityName);
        if(col != null) {
            propName = col.getCellDisplayValue(rowData);
        }
        return propName;
    }

    openSearchModalAndSetEntityData(mvtEntityAssociatorComponent: MVTEntityAssociatorComponent, row: any, rowIndex: number) {
        const modalRef = mvtEntityAssociatorComponent.openSearchModal(mvtEntityAssociatorComponent.implementedComponent, row, rowIndex);
        let col: ColumnEntityInfo = null;
        if(this instanceof ColumnEntityInfo) {
            col = this;
        }
        if(modalRef != null) {
            modalRef.onClose.subscribe((searchedEntityData: any) => {
                if (searchedEntityData !== undefined && searchedEntityData !== null && !Array.isArray(searchedEntityData)) {
                    if(col && col.getValidationOnChange && col.isEntityId && row[mvtEntityAssociatorComponent.entityIdPropName] !== searchedEntityData[mvtEntityAssociatorComponent.searchedEntityIdPropName]) {
                        col.getValidationOnChange(mvtEntityAssociatorComponent, row[mvtEntityAssociatorComponent.entityIdPropName], rowIndex)
                            .then((validationOnChange: ValidationOnChange) => {
                                if(validationOnChange) {
                                    const onConfirm = (resp: MessageResponse): void => {
                                        if (resp.event === MessageEvent.YES) {
                                            validationOnChange.doOnYes(mvtEntityAssociatorComponent.implementedComponent, row, row[mvtEntityAssociatorComponent.entityIdPropName]);
                                            row[mvtEntityAssociatorComponent.entityIdPropName] = searchedEntityData[mvtEntityAssociatorComponent.searchedEntityIdPropName];
                                            mvtEntityAssociatorComponent.searchEntity(mvtEntityAssociatorComponent, row, rowIndex);
                                        } else {
                                            validationOnChange.doOnCancel(mvtEntityAssociatorComponent.implementedComponent, row, row[mvtEntityAssociatorComponent.entityIdPropName]);
                                            row[mvtEntityAssociatorComponent.entityIdPropName] = mvtEntityAssociatorComponent.cellEditingPreviousValue;
                                        }
                                        mvtEntityAssociatorComponent.setFocusToRow(rowIndex);
                                    };
                                    mvtEntityAssociatorComponent.messageHandler.show(validationOnChange.validationMessage, validationOnChange.messageType ? validationOnChange.messageType : MessageType.CONFIRM, onConfirm);
                                } else {
                                    row[mvtEntityAssociatorComponent.entityIdPropName] = searchedEntityData[mvtEntityAssociatorComponent.searchedEntityIdPropName];
                                    mvtEntityAssociatorComponent.searchEntity(mvtEntityAssociatorComponent, row, rowIndex);
                                }
                        });
                        
                    } else {
                        row[mvtEntityAssociatorComponent.entityIdPropName] = searchedEntityData[mvtEntityAssociatorComponent.searchedEntityIdPropName];
                        mvtEntityAssociatorComponent.searchEntity(mvtEntityAssociatorComponent, row, rowIndex);
                    }
                }
            });
        }
    }

    searchEntity(mvtEntityAssociatorComponent: MVTEntityAssociatorComponent, rowData: any, rowIndex: number, setFocusOnLastRow: boolean = true): Promise<any> {
        return new Promise<any>((resolve) => {
            mvtEntityAssociatorComponent.pendingSearchEntityResult = true;
            if (mvtEntityAssociatorComponent.searchEntityDelegate) {
                mvtEntityAssociatorComponent.searchEntityDelegate(mvtEntityAssociatorComponent.implementedComponent, rowData).subscribe((searchedRowData: any) => {
                    const insertedEntity: any = mvtEntityAssociatorComponent.searchEntityResult(rowData, searchedRowData, rowIndex, setFocusOnLastRow);
                    resolve(insertedEntity);
                });
            } else {
                mvtEntityAssociatorComponent.searchEntityDelegateAsync(mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex).then((searchedRowData: any) => {
                    let insertedEntity: any = mvtEntityAssociatorComponent.searchEntityResult(rowData, searchedRowData, rowIndex, setFocusOnLastRow);
                    if(insertedEntity == null && searchedRowData != null) {
                        if(!(searchedRowData instanceof Array)) {
                            searchedRowData = [searchedRowData];
                        }
                        if(searchedRowData.length > 0) {
                            insertedEntity = searchedRowData[0];
                        }
                    }
                    resolve(insertedEntity);
                });
            }
        });
    }

    getFirstDraggableBackgroundColor(mvtEntityAssociatorComponent: MVTEntityAssociatorComponent, rowData: any, rowIndex: number): string {
        if(rowData != null && rowData.mvOrder === 1) {
            return HexaColor.lightGreen;
        }
        return null;
    }

    getFirstDraggableTooltip(mvtEntityAssociatorComponent: MVTEntityAssociatorComponent, rowData: any): string {
        if(rowData != null && rowData.mvOrder === 1) {
            return mvtEntityAssociatorComponent.translate.instant('mvtEntityAssociatorComponent.primary');
        }
        return null;
    }

    addEntityById(entityId: string, loadInitialEntityProperties: (data: any) => void = null): Promise<any> {
        return new Promise<any>((resolve) => {
            // Find the column with 'searchById' functionality and add a new row to the grid.
            const col: ColumnEntityInfo = this.cols.find(col => col.searchById);
            if(col != null) {
                //Add an empty row in the grid. And set the added row into the rowData variable.
                this.addRow(null, false);
                let lastIndex = this.entitiesArray.length -1;
                let rowData = this.entitiesArray[lastIndex];

                // Set the entity ID and load any initial properties if provided.
                if(this.entityIdPropName != null) {
                    rowData[this.entityIdPropName] = entityId;
                }

                if(loadInitialEntityProperties != null) {
                    loadInitialEntityProperties(rowData);
                }

                // Search for the entity by the ID. If the entity is found, it will be returned.
                const promiseSearchById = col.searchById(this, rowData, lastIndex, false);
                if(promiseSearchById != null) {
                    promiseSearchById.then((entityResult: any) => {
                        resolve(entityResult);
                    });
                } else {
                    resolve(null);
                }
            } else {
                resolve(null);
            }
        });
    }

    onCheckBoxEdited(col: ColumnEntityInfo, rowData: any, rowIndex: number): void {
        if(col.isSelectionModeColumn()){
            this.setHeaderCheckboxStatus();
        }
        this.isEditing = false;
        this.cellWasEdited(col, {data: rowData, index: rowIndex});
    }

    override cellWasEdited(col: ColumnEntityInfo, event: any): void {
        const rowData = event.data;
        const rowIndex = event.index;

        if(col.editableType == ColumnEntityInfoEditableType.dropdownField) {
            col.onDropDownItemSelected(rowData);
        }

        if (col.isEntityId) {
            if(col.searchById == null && col.openSearchModal == null) {
                let entityAlreadyExist: boolean = this.validateEntityAlreadyExist(rowData, rowIndex);
                if(entityAlreadyExist) {
                    return;
                }
            }
        }
        if(col.getValidationOnChange && col.entityPropName === this.primaryColumnName) {
            this.isPendingValidation = true;
            col.getValidationOnChange(this, this.cellEditingPreviousValue, rowIndex)
                .then((validationOnChange: ValidationOnChange) => {
                    if(validationOnChange) {
                        const onConfirm = (resp: MessageResponse): void => {
                            this.isPendingValidation = false;
                            if (resp.event === MessageEvent.YES) {
                                validationOnChange.doOnYes(this.implementedComponent, rowData, this.cellEditingPreviousValue);
                                this.doOnEditedCell(col, rowData, rowIndex);
                            } else {
                                validationOnChange.doOnCancel(this.implementedComponent, rowData, this.cellEditingPreviousValue);
                                rowData[col.entityPropName] = this.cellEditingPreviousValue;
                            }
                            this.setFocusToRow(rowIndex);
                        };
                        this.messageHandler.show(validationOnChange.validationMessage, validationOnChange.messageType ? validationOnChange.messageType : MessageType.CONFIRM, onConfirm);
                    } else {
                        this.isPendingValidation = false;
                        this.doOnEditedCell(col, rowData, rowIndex);
                    }
                });
        } else {
            this.doOnEditedCell(col, rowData, rowIndex);
        }
    }

    doOnEditedCell(col: ColumnEntityInfo, rowData: any, rowIndex: number): void {

        if(rowData && rowData.getOperation){
            rowData.setOperation(rowData.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
        }

        if(StringUtils.isEmpty(rowData[col.entityPropName])) {
            if(col.entityPropName === this.primaryColumnName && this.validatePropName(col.entityPropName)) {
                this.clearRow(rowData);
                if(col.doOnClearEntityPropId){
                    col.doOnClearEntityPropId(this.implementedComponent, rowData, this.cellEditingPreviousValue);
                }
                return;
            }
        }

        if(!col.isSelectionModeColumn()) {
            this.headerService.changeForm = true;
        }

        if(rowData.forceBeanChange != null) {
            rowData.forceBeanChange(col.entityPropName);
        }

        if (col.searchById !== null) {
            col.searchById(this, rowData, rowIndex);
        }

        if (this.onCellEdited && this.onCellEdited !== null) {
            this.onCellEdited(this.implementedComponent, col.entityPropName, rowData, rowIndex);
        }

        this.onChanges.emit({ type: 'update', data: rowData, entitiesArray: this.entitiesArray });

    }

    validatePropName(entityPropName: string) {
        if(entityPropName != 'emissionsYear' && entityPropName != 'heaterName' && entityPropName != 'drumName') {
            return true;
        }
        return false;
    }

    isDisabledButton(): boolean {
        return this.disabledButton || !this.tableEditEnabled ||
            this.isEditing ||
            this.entitiesArray === undefined || this.entitiesArray === null
            || (this.hasEmptyRows != null && this.hasEmptyRows(this.implementedComponent));
    }

    entitiesArrayFiltered(): Array<any> {
        let filteredArray: Array<any> = [];
        if (this.entitiesArray) {
            filteredArray = filteredArray.concat(this.entitiesArray);
            if(this.entitiesArray.length > 0 && this.entitiesArray[0] instanceof Bean) {
                filteredArray = MVTOperations.filterByDeleted(filteredArray);
            }
            filteredArray = this.sortEntitiesArray(filteredArray);
        }
        return filteredArray;
    }

    sortEntitiesArray(entitiesArray: Array<any>): Array<any> {
        if(this.sortingMode == null && !StringUtils.isEmpty(this.defaultSortingProperty)) {
            entitiesArray = MVTOperations.sortArray(entitiesArray, this.defaultSortingOrder, this.defaultSortingProperty);
        }
        return entitiesArray;
    }

    addRow($event, setFocusOnLastRow: Boolean = true) {
        if(this.hasEmptyRows(this.implementedComponent)) {
            return;
        }
        const emptyTemporalObject: boolean = this.entitiesArray.filter(p => p.getOperation() === OperationType.TEMPORAL)?.length > 0;
        if (!emptyTemporalObject) {
            this.headerService.changeForm = true;
            const itemAux: any = this.createNewEntityInstance(this.implementedComponent);

            itemAux.setOperation((!StringUtils.isEmpty(this.entityIdPropName)) ? OperationType.TEMPORAL : OperationType.INSERT);
            this.entitiesArray.push(itemAux);
            this.entitiesArrayChange.emit(this.entitiesArray);
            this.onChanges.emit({ type: 'add', data: itemAux, entitiesArray: this.entitiesArray });
            if(setFocusOnLastRow) {
                this.focusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_LAST_ROW));
            }
        }
    }

    hasIncompleteRows(): boolean {
        if(!StringUtils.isEmpty(this.entityIdPropName)) {
            const filteredArray = MVTOperations.filterByDeleted(this.entitiesArray) ?? [];
            const emptyRows =  filteredArray.filter(element => StringUtils.isEmpty(element[this.entityIdPropName]));
            if(emptyRows.length > 0) {
                return true;
            }
        }
        return false;
    }

    onRowReorder(event: any) {
        this.headerService.changeForm = true;
        MVTOperations.changePosition(this.entitiesArray, event.dragIndex, event.dropIndex);
    }

    hasRowReorder(){
        return this.cols.some(col => col.draggable);
    }

    deleteRow(columnEntityInfo: ColumnEntityInfo, implementedComponent: any, rowData: any, rowIndex: number) {
        const mvtRef: MVTEntityAssociatorComponent = columnEntityInfo.mvtEntityAssociatorComponent;
        mvtRef.headerService.changeForm = true;
        if(!StringUtils.isEmpty(mvtRef.entityIdPropName)) {
            MVTOperations.removeItem(mvtRef.entitiesArray, mvtRef.entityIdPropName, rowData[mvtRef.entityIdPropName] !== null ? String(rowData[mvtRef.entityIdPropName]) : null);
        } else if(rowData.hasOwnProperty('mvOrder') && !StringUtils.isEmptyInPrimitiveTypes(rowData['mvOrder'])) {
            MVTOperations.removeItem(mvtRef.entitiesArray, 'mvOrder', String(rowData['mvOrder']));
        } else {
            MVTOperations.remove(rowIndex, mvtRef.entitiesArrayFiltered());
        }
        mvtRef.entitiesArray = mvtRef.sortEntitiesArray(mvtRef.entitiesArray);
        mvtRef.onChanges.emit({ type: 'delete', data: rowData, entitiesArray: mvtRef.entitiesArray });
    }

    onEnter(col: ColumnEntityInfo, rowData: any, rowIndex: number): void {
        if (col.searchById !== null || col.openSearchModal !== null) {
            if (StringUtils.isEmpty(rowData[col.entityPropName])) {
                col.openSearchModal(this, rowData, rowIndex);
            }
        }
    }

    dgRowDoubleClick(rowData: any) {
        if (this.onRowDoubleClick != null) {
            this.onRowDoubleClick(this.implementedComponent, rowData);
        }
    }

    clearRow(row: any): void {
        this.headerService.changeForm = true;
        if (this.cols) {
            for (let columnInfo of this.cols) {
                if (columnInfo.entityPropName !== null && this.validateColumnName(columnInfo.entityPropName)) {
                    row[columnInfo.entityPropName] = null;
                }
            }
        }
        if (this.extraEntityPropertiesInfo && this.extraEntityPropertiesInfo !== null) {
            for (let extraPropertyInfo of this.extraEntityPropertiesInfo) {
                if (extraPropertyInfo.entityPropName !== null) {
                    row[extraPropertyInfo.entityPropName] = null;
                }
            }
        }
        this.cdr.detectChanges();
        row.initialData = Object.assign({}, row);
        this.onChanges.emit({ type: 'clear', data: row, entitiesArray: this.entitiesArray });
        this.focusManager.next(new FocusableEvent(FocusableEventType.RESET_FOCUS_LISTENERS));
    }

    validateColumnName(entityPropName: string) {
        if (entityPropName !== 'verifiedQcDate' && entityPropName !== 'productStatus' && entityPropName !== 'productType'
            && entityPropName !== 'feedDesignCapacity' && entityPropName !== 'feedActualCapacity'
            && entityPropName !== 'outputDesignCapacity' && entityPropName !== 'outputActualCapacity'
            && entityPropName !== 'installedOutputCapacity' && entityPropName !== 'plannedOutputCapacity') {
            return true;
        }
        return false;
    }

    setFocusToFirstRow() {
        this.setFocusToRow(0);
    }

    setFocusToRow(rowIndex: number) {
        this.focusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_BY_INDEX, rowIndex));
    }

    public returnFocus = (row, msgModal: string, rowIndex: number, messageType: number =  MessageType.INFO) => {
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                this.setFocusToRow(rowIndex);
            }
        }
        this.messageHandler.show(this.translate.instant(msgModal), messageType, selectOpt)
    }

    public returnFocusToField = (msgModal: string, rowIndex: number, fieldName: string, messageType: number =  MessageType.INFO) => {
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                this.focusManager.next(new FocusableEvent(FocusableEventType.FOCUS_TO_BY_INDEX_AND_FIELD, {index: rowIndex, field: fieldName}));
            }
        }
        this.messageHandler.show(this.translate.instant(msgModal), messageType, selectOpt)
    }

    pendingSearchEntityResult: boolean = false;
    searchEntityResult(row: any, searchedEntityData: any, rowIndex: number, setFocusOnLastRow: Boolean = true): any {
        if(!this.pendingSearchEntityResult) {
            return;
        }
        this.pendingSearchEntityResult = false;

        if(searchedEntityData == null) {
            return;
        }

        if(searchedEntityData != null && !(searchedEntityData instanceof Array)) {
            searchedEntityData = [searchedEntityData];
        }
        if (!this.validateEntityExist(row, searchedEntityData, this.searchedEntityIdPropName, this.entityIdPropName)) {
            let errorMsg: string = null;
            if (!StringUtils.isEmpty(this.errorMessageEntityDoesNotExist)) {
                errorMsg = this.errorMessageEntityDoesNotExist;
            } else {
                errorMsg = this.translate.instant('mvtEntityAssociatorComponent.entityDoesNotExist', { entityName: this.entityName });
            }
            this.returnFocus(row, errorMsg, rowIndex);
            this.clearRow(row);
            return;
        }

        let entityAlreadyExist: boolean = this.validateEntityAlreadyExist(row, rowIndex);
        if(!entityAlreadyExist) {
            const entityResult = this.validateAndSetEntityData(searchedEntityData[0], row, rowIndex);
            if(setFocusOnLastRow) {
                this.setFocusToRow(rowIndex);
            }
            return entityResult;
        }
        return null;
    }

    validateEntityAlreadyExist(row: any, rowIndex: number):boolean {
        let entityAlreadyExist: boolean = false;
        let searchedRowDataFilter = MVTOperations.filterByDeleted(this.entitiesArray.filter(sc => sc.mvOrder != row.mvOrder));
        if (this.validateEntityExist(row, searchedRowDataFilter, this.entityIdPropName, this.entityIdPropName)) {
            this.returnFocus(row, this.translate.instant('mvtEntityAssociatorComponent.entityAlreadyExist', { entityName: this.entityName }), rowIndex);
            this.clearRow(row);
            entityAlreadyExist = true;
        }
        return entityAlreadyExist;
    }

    validateEntityExist(row: any, searchedEntityData: any, searchedEntityIdPropName: string, entityIdPropName: string): boolean {
        let entityExist: boolean = false;

        const entityIdIsNumericField = this.isNumericField(entityIdPropName);
        const filteredData: any = searchedEntityData.filter(element => {
            return this.checkDuplicatedRow(row, element, searchedEntityIdPropName, entityIdPropName, entityIdIsNumericField);
        });

        if (filteredData && filteredData !== null && filteredData.length > 0) {
            entityExist = true;
        }

        return entityExist;
    }

    checkDuplicatedRow(row: any, element: any, searchFieldId: string, rowFieldId: string, idIsNumeric: boolean): boolean {
        const elementId = element[searchFieldId];
        const rowId = row[rowFieldId];
        return idIsNumeric ? Number(elementId) === Number(rowId) : StringUtils.equalsIgnoreCase(elementId, rowId);
    }

    changeSelectionOnArrowKey(event: any) {
        if(this.shouldTabOverRows()) {
            TableUtils.changeSelectionOnArrowKey(this, "selectedRow", event, this.getRowsByPage(), true, true);
        }
    }

    changeSelectionToRowIndex(rowIndex: number) {
        TableUtils.changeSelectionToRowIndex(this, this.dataTable.el.nativeElement, "selectedRow", rowIndex, this.getRowsByPage(), true);
        this.cdr.detectChanges();
    }

    shouldTabOverRows() {
        const hasEditableColumns = this.cols.some(col => col.isEditableColumn());
        return !hasEditableColumns;
    }

    isNumericField(field: string): boolean {
        return this.cols.some(x => x.entityPropName === field && x.editableType === this.columnEntityInfoEditableType.numberField);
    }

    validateAndSetEntityData(searchedEntityData: any, row: any, rowIndex?: number): any {
        this.headerService.changeForm = true;
        row.setOperation(row.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
        row[this.entityIdPropName] = searchedEntityData[this.searchedEntityIdPropName];
        if (this.cols) {
            for (let columnInfo of this.cols) {
                if (columnInfo.entityPropName !== null) {
                    if (columnInfo.searchedEntityPropName && columnInfo.searchedEntityPropName !== null) {
                        row[columnInfo.entityPropName] = searchedEntityData[columnInfo.searchedEntityPropName];
                    } else {
                        row[columnInfo.entityPropName] = null;
                    }

                    if (columnInfo.isEditable(row, rowIndex)) {
                        row.forceBeanChange(columnInfo.entityPropName);
                    }
                }
            }
            if (this.extraEntityPropertiesInfo && this.extraEntityPropertiesInfo !== null) {
                for (let extraPropertyInfo of this.extraEntityPropertiesInfo) {
                    if (extraPropertyInfo.entityPropName !== null) {
                        if (extraPropertyInfo.searchedEntityPropName && extraPropertyInfo.searchedEntityPropName !== null) {
                            row[extraPropertyInfo.entityPropName] = searchedEntityData[extraPropertyInfo.searchedEntityPropName];
                        } else {
                            row[extraPropertyInfo.entityPropName] = null;
                        }

                        row.forceBeanChange(extraPropertyInfo.entityPropName);
                    }
                }
            }
        }
        this.cdr.detectChanges();
        row.changedFields[this.entityIdPropName] = 0;
        row.forceBeanChange(this.entityIdPropName);

        // Used to check if the current value is different from the initial or previous value.
        row.initialData[this.entityIdPropName] = row[this.entityIdPropName];
        this.onChanges.emit({ type: 'update', data: row, entitiesArray: this.entitiesArray });
        return row;
    }

    customSort(event: SortEvent) {
        if(this.sortingMode != null) {
            event.data.sort((data1, data2) => {
                let result = 0;
                if (event.multiSortMeta) {
                    result = this.singleOrMultiColumnSort(data1, data2, event.multiSortMeta);
                } else {
                    let metaArray = [{ field: event.field, order: event.order }];
                    result = this.singleOrMultiColumnSort(data1, data2, metaArray);
                }
                return result;
            });
        }
    }

    private singleOrMultiColumnSort(data1: any, data2: any, metaArray: any[]): number {
        for (let meta of metaArray) {
            const col: ColumnEntityInfo = this.cols.find(col => col.entityPropName === meta.field);
            if(col != null) {
                let result = null;
                if(col.customSortCompare != null) {
                    result = col.customSortCompare(col, data1, data2);
                } else {
                    let value1 = null;
                    let value2 = null;
                    if(col.isIconArrayColumn()) {
                        value1 = col.iconButtonArray[0].getSrcIcon(data1, this.implementedComponent);
                        value2 = col.iconButtonArray[0].getSrcIcon(data2, this.implementedComponent);
                    } else {
                        value1 = col.getCellDisplayValue(data1);
                        value2 = col.getCellDisplayValue(data2);
                    }

                    result = this.compareValues(value1, value2);
                }

                if (result !== 0) {
                    return (meta.order * result);
                }
            }
        }
        return 0;
    }

    private compareValues(value1: any, value2: any): number {
        if ((value1 === null || value1 === '') && value2 !== null)
            return 1;
        else if (value1 !== null && (value2 == null || value2 === ''))
            return -1;
        else if ((value1 === null && value2 === null) || value1 === '' && value2 === '')
            return 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            const date1: Date = this.extractDateFromString(value1);
            const date2: Date = this.extractDateFromString(value2);
            if(date1 != null || date2 != null) {
                return (date1 < date2) ? -1 : (date1 > date2) ? 1 : 0;
            }
            return value1.localeCompare(value2);
        }
        else
            return (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    private extractDateFromString(str: string): Date | null {
        const regex = /^(\d{2}[\/-][A-Za-z]{3}[\/-]\d{4}(?:\s\d{2}:\d{2}:\d{2})?)/;
        const match = str.match(regex);
        if (match) {
            const dateString = match[0];
            return new Date(dateString);
        }
        return null;
    }

    public get columnEntityInfoEditableType(): typeof ColumnEntityInfoEditableType {
        return ColumnEntityInfoEditableType;
    }

    changeDate(col: ColumnEntityInfo, rowData: any, event): void {
        if (this.changeDateFunction && this.changeDateFunction !== null
            && event !== null && event !== 0) {
            setTimeout(() => {
                this.changeDateFunction(this.implementedComponent, col.entityPropName,
                    rowData, new Date(event).getTime());
            }, 0);
        }
    }

    initializeDateOnFocus(rowData: any, entityPropName: string) {
        if(!StringUtils.isEmpty(rowData[entityPropName]))
        {
            const newDate = new Date(rowData[entityPropName]);
            rowData[entityPropName] = newDate;
        }
    }

    onLinkEnter(event: Event): void {
        (<HTMLInputElement>(event.target)).click();
    }

    ngOnDestroy(): void {
        this.disableFormSub?.unsubscribe();
    }

    selectRow(rowData: any) {
        setTimeout(() => {
            this.selectedRow = rowData;
            this.lastSelectedRow =  this.selectedRow;
            this.cdr.detectChanges();
        }, 0);
    }

    onSelectionChange(selected: any) {
        if (selected !== null) {
          this.lastSelectedRow = selected;
        }
    }
    disableNavigation(event: any) {
        TableUtils.restrictArrowKeyNavigation(event);
    } 
}

export class ColumnEntityInfo {

    public static readonly INTERNAL_SELECTION_COLUMN: string = 'internalSelectionColumn';

    mvtEntityAssociatorComponent?: MVTEntityAssociatorComponent;
    entityPropName?: string;
    searchedEntityPropName?: string;
    columnHeader?: string;
    columnHeaderIcon?: string;
    editableType?: ColumnEntityInfoEditableType;
    widthPercentage?: number;
    headerAlign?: string;
    bodyAlign?: string;
    maxLength?: number;
    maxDecimals?: number;
    allowedDecimalZeros?: boolean;
    formatThousandsDecimals?: boolean;
    maxValue?: object;
    dropdownValue?: string;
    dropdownLabel?: string;
    dropDownFieldIdPropName?: string;
    draggable?: boolean;
    isEntityId?: boolean;
    isEntityName?: boolean;
    isPrimaryColumn?: boolean
    iconButtonArray?: ColumnIconButton[];
    formatThousands?: boolean;
    regexAccepted?: RegExp;
    toUpperCase?: boolean;
    routerEntityName?: string;
    relatedInfoIndex?: boolean;

    displayValueFunc?: (implementedComponent: any, rowData: any) => string;
    dropdownOptionsArray?: (implementedComponent: any, rowData: any) => Array<any>;

    searchById?: (implementedComponent: any, rowData: any, rowIndex: number, setFocusOnLastRow?: boolean) => Promise<any>;
    openSearchModal?: (implementedComponent: any, row: any, rowIndex: number) => void;
    getColumnTitle?: (implementedComponent: any, rowData: any, rowIndex: number) => string;
    getColumnTooltip?: (implementedComponent: any, rowData: any, rowIndex: number) => string;
    getColumnBackgroundColor?: (implementedComponent: any, rowData: any, rowIndex: number) => string;
    getColumnTextColor?: (implementedComponent: any, rowData: any, rowIndex: number) => string;
    getCellBorder?: (implementedComponent: any, rowData: any, rowIndex: number) => string;
    isCellEditable?:(implementedComponent: any, rowData: any, rowIndex: number) => boolean;
    getUrlLink?:(implementedComponent: any, rowData: any) => string;
    getRouterEntityName?:(implementedComponent: any, rowData: any) => string;
    customSortCompare?:(columnEntityInfo: ColumnEntityInfo, rowData1: any, rowData2: any) => number;
    formatDisplayValue?:(implementedComponent: any, displayValue: string) => string;
    getValidationOnChange?: (mvtEntityAssociatorComponent: MVTEntityAssociatorComponent, valueID: string, rowIndex: number) => Promise<ValidationOnChange> = null;
    isDropDownEnabled?: (rowData: any, index: number) => boolean = () => false;
    doOnClearEntityPropId?: (implementedComponent: any, row: any, prevEntityId: string) => void;

    protected constructor(entity: ColumnEntityInfo) {
        this.mvtEntityAssociatorComponent = entity?.mvtEntityAssociatorComponent ?? null;
        this.entityPropName = entity?.entityPropName ?? null;
        this.searchedEntityPropName = entity?.searchedEntityPropName ?? null;
        this.columnHeader = entity?.columnHeader ?? null;
        this.columnHeaderIcon = entity?.columnHeaderIcon ?? null;
        this.editableType = entity?.editableType ?? null;
        this.widthPercentage = entity?.widthPercentage ?? null;
        this.headerAlign = entity?.headerAlign ?? null;
        this.bodyAlign = entity?.bodyAlign ?? null;
        this.maxLength = entity?.maxLength ?? null;
        this.maxDecimals = entity?.maxDecimals ?? null;
        this.formatThousandsDecimals = entity?.formatThousandsDecimals ?? false;
        this.maxValue = entity?.maxValue ?? null;
        this.dropdownValue = entity?.dropdownValue ?? null;
        this.dropdownLabel = entity?.dropdownLabel ?? null;
        this.dropDownFieldIdPropName = entity?.dropDownFieldIdPropName ?? null;
        this.draggable = entity?.draggable ?? null;
        this.isEntityId = entity?.isEntityId ?? null;
        this.isEntityName = entity?.isEntityName ?? null;
        this.isPrimaryColumn = entity?.isPrimaryColumn ?? null;
        this.iconButtonArray = this.convertColumnIconButtons(entity.iconButtonArray);
        this.formatThousands = entity?.formatThousands ?? null;
        this.regexAccepted = entity?.regexAccepted ?? null;
        this.toUpperCase = entity?.toUpperCase ?? null;
        this.relatedInfoIndex = entity?.relatedInfoIndex ?? false;
        this.allowedDecimalZeros = entity.allowedDecimalZeros ?? true;
        this.routerEntityName = entity?.routerEntityName ?? null;

        if(this.isEntityId) {
            this.isPrimaryColumn = true;
        }
        if (this.editableType === null) {
            this.editableType = ColumnEntityInfoEditableType.nonEditableField;
        }
        if(this.editableType == ColumnEntityInfoEditableType.commentsField) {
            this.createCommentsField();
        }
        if(this.editableType === ColumnEntityInfoEditableType.deleteField) {
            this.createDeleteField();
        }
        if (this.maxLength === null) {
            this.maxLength = 10;
        }
        if(this.maxDecimals === null) {
            this.maxDecimals = 0;
        }
        if(this.formatThousands === null){
            this.formatThousands = false;
        }
        if(this.toUpperCase === null){
            this.toUpperCase = false;
        }

        if(this.editableType === ColumnEntityInfoEditableType.dropdownField
            && StringUtils.isEmpty(this.dropDownFieldIdPropName)) {
            this.dropDownFieldIdPropName = this.entityPropName;
        }

        this.displayValueFunc = entity?.displayValueFunc ?? null;
        this.dropdownOptionsArray = entity?.dropdownOptionsArray ?? null;
        this.searchById = entity?.searchById ?? null;
        this.openSearchModal = entity?.openSearchModal ?? null;
        this.getColumnTitle = entity?.getColumnTitle ?? null;
        this.getColumnTooltip = entity?.getColumnTooltip ?? null;
        this.getColumnBackgroundColor = entity?.getColumnBackgroundColor ?? null;
        this.getColumnTextColor = entity?.getColumnTextColor ?? null;
        this.getCellBorder = entity?.getCellBorder ?? null;
        this.isCellEditable = entity?.isCellEditable ?? null;
        this.getUrlLink = entity?.getUrlLink ?? null;
        this.getRouterEntityName = entity?.getRouterEntityName ?? this.getInternalRouterEntityName;
        this.isSortableColumn = entity?.isSortableColumn ?? this.isSortableColumn;
        this.customSortCompare = entity?.customSortCompare ?? null;
        this.formatDisplayValue = entity?.formatDisplayValue ?? null;
        this.getValidationOnChange = entity?.getValidationOnChange ?? null;
        this.isDropDownEnabled = entity?.isDropDownEnabled ?? null;
        this.doOnClearEntityPropId = entity?.doOnClearEntityPropId ?? null;

        if (this.entityPropName == null && this.displayValueFunc != null) {
            this.entityPropName = this.displayValueFunc.name;
        }
        if (this.entityPropName == null && this.isIconArrayColumn()) {
            this.entityPropName = !StringUtils.isEmptyInPrimitiveTypes(this.columnHeader) ? this.columnHeader : 'iconArrayColumn';
        }
    }

    getCellValue?(rowData: any): string {
        if(this.editableType === ColumnEntityInfoEditableType.dropdownField) {
            return rowData[this.dropDownFieldIdPropName];
        } else {
            return rowData[this.entityPropName];
        }
    }

    convertColumnIconButtons?(iconButtonArray?: ColumnIconButton[]): ColumnIconButton[] {
        let iconButtonArraysResult: ColumnIconButton[] = null;
        if (iconButtonArray) {
            iconButtonArraysResult = [];
            for (let iconButton of iconButtonArray) {
                iconButton.mvtEntityAssociatorComponent = this.mvtEntityAssociatorComponent;
                iconButton.columnEntityInfo = this;
                iconButtonArraysResult.push(ColumnIconButton.CreateInstance(iconButton));
            }
        }
        return iconButtonArraysResult;
    }

    iconButtonsArrayFiltered?(rowData: any, rowIndex: number, implementedComponent: any): Array<ColumnIconButton> {
        let filteredArray: Array<ColumnIconButton> = [];
        if (this.iconButtonArray != null) {
            filteredArray = this.iconButtonArray.filter(iconButton => iconButton.hasImage(rowData, rowIndex, implementedComponent));
        }
        return filteredArray;
    }

    hasUrlLink?(rowData: any): boolean {
        return this.getUrlLink != null && this.getUrlLink(this.mvtEntityAssociatorComponent.implementedComponent, rowData) != null;
    }

    hasDirectEdition?(rowData: any, rowIndex: number):boolean {
        let directEdition: boolean = false;

        if(this.editableType === ColumnEntityInfoEditableType.checkboxField) {
            if(this.isCellEditable != null) {
                directEdition = this.isCellEditable(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
            } else {
                directEdition = true;
            }
        } else if(this.isIconArrayColumn()) {
            directEdition = true;
        }

        return directEdition;
    }

    isEditable?(rowData: any, rowIndex: number): boolean {
        if(!this.mvtEntityAssociatorComponent.editable) {
            return false;
        }
        if(this.editableType != null && this.editableType === ColumnEntityInfoEditableType.deleteField) {
            return true;
        }

        const primaryColumnName = this.mvtEntityAssociatorComponent.primaryColumnName;
        let entityIdValue: string = null;
        if(!StringUtils.isEmpty(primaryColumnName)) {
            entityIdValue = rowData[primaryColumnName];
        }

        let editable: boolean = this.isEditableColumn();
        if (editable 
                && primaryColumnName != null && primaryColumnName != this.entityPropName && StringUtils.isEmpty(entityIdValue)) {
            editable = false;
        }

        if(editable && this.isCellEditable != null) {
            editable = this.isCellEditable(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }

        return editable;
    }

    isEditableColumn?() {
        return (this.editableType != null &&
            this.editableType != ColumnEntityInfoEditableType.nonEditableField &&
                this.editableType != ColumnEntityInfoEditableType.nonEditableDateField);
    }

    isTabFocusableColumn?() {
        return this.isIconArrayColumn() || this.isEntityLinkColumn() || this.isEditableColumn();
    }

    isIconArrayColumn?(): boolean {
        return this.iconButtonArray != null && this.iconButtonArray.length > 0;
    }

    isEntityLinkColumn?() {
        return !StringUtils.isEmpty(this.routerEntityName);
    }

    isSelectionModeColumn?(): boolean {
        return this.entityPropName === ColumnEntityInfo.INTERNAL_SELECTION_COLUMN;
    }

    isSortableColumn?(): boolean {
        if(!this.isSelectionModeColumn() && !this.isIconArrayColumn()) {
            return true;
        }
        return false;
    }

    isFocusableCell?(rowData: any, rowIndex: number): boolean {
        if(this.isEntityLinkColumn()) {
            return true;
        } else if(this.isIconArrayColumn()) {
            return this.isIconButtonEditable(rowData, rowIndex);
        } else {
            return this.isEditable(rowData, rowIndex);
        }
    }

    isIconButtonEditable?(rowData: any, rowIndex: number): boolean {
        if(this.isIconArrayColumn()) {
            if(this.isCellEditable != null && !this.isCellEditable(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex)) {
                return false;
            }
            return (this.iconButtonsArrayFiltered(rowData, rowIndex, this.mvtEntityAssociatorComponent.implementedComponent).length > 0);
        }
        return false;
    }

    columnTitle?(rowData: any, rowIndex: number): string {
        let title: string = '';
        if(this.getColumnTitle != null) {
            title = this.getColumnTitle(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }
        return title;
    }

    columnTooltip?(rowData: any, rowIndex: number): string {
        let tooltipText: string = '';
        if(this.getColumnTooltip != null) {
            tooltipText = this.getColumnTooltip(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }
        return tooltipText;
    }

    columnBackgroundColor?(rowData: any, rowIndex: number): string {
        let color = '';
        if (this.getColumnBackgroundColor != null) {
            color = this.getColumnBackgroundColor(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }

        return color;
    }

    columnTextColor?(rowData: any, rowIndex: number): string {
        let color = '';
        if (this.getColumnTextColor != null) {
            color = this.getColumnTextColor(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }

        return color;
    }

    cellBorderColor?(rowData: any, rowIndex: number): string {
        let color = '';
        if (this.getCellBorder != null) {
            color = this.getCellBorder(this.mvtEntityAssociatorComponent.implementedComponent, rowData, rowIndex);
        }

        return color;
    }

    getThousandsMask?(): string {
        let mask:string = "";
        if(this.formatThousands || this.formatThousandsDecimals){
            mask = "separator."+this.maxDecimals;
        }
        return mask;
    }

    getSeparatorLimit?(): string {
        let separatorLimit = '';
        if(this.maxLength > 0) {
            separatorLimit = '9'.repeat(this.maxLength);
        }
        return separatorLimit;
    }

    width?(): string {
        return this.widthPercentage + '%';
    }


    getCellDisplayValue?(rowData: any): string {
        if(rowData == null) {
          return null;
        }

        if(rowData?.operation === OperationType.DELETE){
          return null;
        }

        let displayValue: string = null;
        if (this.displayValueFunc && this.displayValueFunc !== null) {
            displayValue = this.displayValueFunc(this.mvtEntityAssociatorComponent.implementedComponent, rowData);
        } else if (this.entityPropName && this.entityPropName !== null) {
            if (this.editableType !== ColumnEntityInfoEditableType.calendarField &&
                this.editableType !== ColumnEntityInfoEditableType.nonEditableDateField) {
                displayValue = rowData[this.entityPropName];
            } else {
                let dateValue = rowData[this.entityPropName];
                if(dateValue != null) {
                    if(StringUtils.isNumber(dateValue)) {
                        dateValue = new Date(Number(dateValue));
                    }
                    displayValue = this.mvtEntityAssociatorComponent.datePipe.transform(dateValue, 'dd-MMM-y');
                }
            }
        }

        if(this.formatThousandsDecimals) {
            displayValue = StringUtils.applyNumberThousandsFormat(this.mvtEntityAssociatorComponent.decimalPipe, displayValue, true, this.maxDecimals, this.allowedDecimalZeros);
        } else if(this.formatThousands) {
            displayValue = StringUtils.applyNumberThousandsFormat(this.mvtEntityAssociatorComponent.decimalPipe, displayValue);
        }

        if(this.formatDisplayValue != null) {
            displayValue = this.formatDisplayValue(this.mvtEntityAssociatorComponent.implementedComponent, displayValue);
        }

        return displayValue;
    }

    getInternalRouterEntityName?(implementedComponent: any, rowData: any) {
        return this.routerEntityName;
    }

    getRouterLink?(rowData: any): string {
        let routerLink: string = null;
        if(!StringUtils.isEmpty(this.getRouterEntityName(this.mvtEntityAssociatorComponent.implementedComponent, rowData))) {
            routerLink = StringUtils.toStringNeverNull(this.getRouterEntityName(this.mvtEntityAssociatorComponent.implementedComponent, rowData));
            routerLink = routerLink.charAt(0).toLowerCase() + routerLink.slice(1);
            routerLink = routerLink.replace(' ', '')
            routerLink = '/ED/' + routerLink;
        }
        return routerLink;
    }

    onRouterLinkClick?(rowData: any, entityId: string) {
        const entityType = this.getRouterEntityName(this.mvtEntityAssociatorComponent.implementedComponent, rowData);
        const entityID = entityId;
        let entityPath = EntityPaths[Object.keys(EntityName).find(key => EntityName[key] === entityType)];
        this.mvtEntityAssociatorComponent.lockSameUserMessageService.validateEntityIsLockedByUserAndOpenEntityTab(entityType, entityID, location.pathname + `#/ED/${entityPath}/${entityID}`, `${entityType + entityID}`);
    }

    createDeleteField?() {
        this.editableType = ColumnEntityInfoEditableType.deleteField
        this.bodyAlign = 'center';
        if (this.entityPropName == null) {
            this.entityPropName = 'delete';
        }

        this.iconButtonArray = new Array();
        this.iconButtonArray.push(ColumnIconButton.CreateInstance({
            mvtEntityAssociatorComponent: this.mvtEntityAssociatorComponent,
            columnEntityInfo: this,
            baseEditableType: this.editableType,
            classImage: 'pi pi-trash',
            onClick: this.mvtEntityAssociatorComponent.deleteRow
        }));
        this.iconButtonArray = this.convertColumnIconButtons(this.iconButtonArray);
    }

    createCommentsField?() {
        this.iconButtonArray = new Array();
        this.iconButtonArray.push(ColumnIconButton.CreateInstance({
            mvtEntityAssociatorComponent: this.mvtEntityAssociatorComponent,
            columnEntityInfo: this,
            baseEditableType: this.editableType,
            showIfReadOnly: true,
            getDynamicSrc: this.getDynamicSrcCommentsIcon,
            getDynamicToolTipText: this.getDynamicTooltipText,
            onClick: this.commentsButtonOnClick
        }));
        this.iconButtonArray = this.convertColumnIconButtons(this.iconButtonArray);

        this.editableType = ColumnEntityInfoEditableType.nonEditableField
    }

    getDynamicSrcCommentsIcon?(columnEntityInfo: ColumnEntityInfo, rowData: any) {
        if(StringUtils.isEmpty(rowData[columnEntityInfo.entityPropName])) {
            return "assets/icons/comment_empty.png";
        } else {
            return "assets/icons/comment_populated.png";
        }
    }

    getDynamicTooltipText?(columnEntityInfo: ColumnEntityInfo, rowData: any):string {
        if(StringUtils.isEmpty(rowData[columnEntityInfo.entityPropName])) {
            if(this.mvtEntityAssociatorComponent.tableEditEnabled) {
                return this.mvtEntityAssociatorComponent.translate.instant('mvtEntityAssociatorComponent.clickToAddComment');
            } else {
                return null;
            }
        } else {
            return rowData[columnEntityInfo.entityPropName];
        }
    }

    commentsButtonOnClick?(columnEntityInfo: ColumnEntityInfo, implementedComponent: any, rowData: any) {
        const selectOpt = (resp: MessageResponse): void => {
            if (resp.event == MessageEvent.OK) {
                rowData[columnEntityInfo.entityPropName] = resp.inputValue;
                rowData.forceBeanChange(columnEntityInfo.entityPropName);
                rowData.setOperation(rowData.getOperation() === OperationType.IDLE ? OperationType.UPDATE : OperationType.INSERT);
                columnEntityInfo.mvtEntityAssociatorComponent.headerService.changeForm = true;
            }
        }
        const actualInputValue:string = StringUtils.toStringNeverNull(rowData[columnEntityInfo.entityPropName]);
        columnEntityInfo.mvtEntityAssociatorComponent.messageHandler.showInputDialog(
            "",
            "Comments",
            actualInputValue,
            selectOpt,
            !columnEntityInfo.mvtEntityAssociatorComponent.tableEditEnabled);
    }

    onDropDownItemSelected?(rowData: any): void {
        const dropdownItem = this.dropdownOptionsArray(this.mvtEntityAssociatorComponent.implementedComponent, rowData).find((item: any) => item[this.dropdownValue] === rowData[this.dropDownFieldIdPropName]);

        if(dropdownItem != null) {
            rowData[this.entityPropName] = dropdownItem[this.dropdownLabel];
            rowData.forceBeanChange(this.entityPropName);

            rowData[this.dropDownFieldIdPropName] = dropdownItem[this.dropdownValue];
            rowData.forceBeanChange(this.dropDownFieldIdPropName);
        } else {
            rowData[this.entityPropName] = '';
            rowData.forceBeanChange(this.dropDownFieldIdPropName);
        }
    }

    getSortableColumn?(): string {
        let sortableColumn: string = "";
        if(this.mvtEntityAssociatorComponent.sortingMode != null) {
            sortableColumn = this.entityPropName;
        }
        return sortableColumn;
    }

    static CreateInstance(entity: ColumnEntityInfo): ColumnEntityInfo {
        return new ColumnEntityInfo(entity);
    }

}

export enum ColumnEntityInfoEditableType {
    nonEditableField,
    textField,
    numberField,
    compositeIdField,
    calendarField,
    nonEditableDateField,
    dropdownField,
    commentsField,
    checkboxField,
    deleteField
}

export enum SelectionModeType {
    NONE = "none",
    MULTIPLE_NO_CHECK_ALL = "multiple no check all",
    MULTIPLE = "multiple"
}

export class ColumnIconButton {
    mvtEntityAssociatorComponent?: MVTEntityAssociatorComponent;
    columnEntityInfo?: ColumnEntityInfo;
    baseEditableType?: ColumnEntityInfoEditableType;
    src?: string;
    classImage?: string;
    tooltip?: string;
    showIfReadOnly?: boolean = false;
    onClick?: (columnEntityInfo: ColumnEntityInfo, implementedComponent: any, rowData: any, rowIndex?: number) => void;
    getDynamicSrc?: (columnEntityInfo: ColumnEntityInfo, rowData: any, implementedComponent?: any) => string;
    getDynamicToolTipText?: (columnEntityInfo: ColumnEntityInfo, rowData: any, implementedComponent?: any) => string;

    protected constructor(entity: ColumnIconButton) {
        this.mvtEntityAssociatorComponent = entity?.mvtEntityAssociatorComponent ?? null;
        this.columnEntityInfo = entity?.columnEntityInfo ?? null;
        this.baseEditableType = entity?.baseEditableType ?? null;
        this.src = entity?.src ?? null;
        this.classImage = entity?.classImage ?? null;
        this.tooltip = entity?.tooltip ?? null;
        this.showIfReadOnly = entity?.showIfReadOnly ?? null;

        this.onClick = entity?.onClick ?? null;
        this.getDynamicSrc = entity?.getDynamicSrc ?? null;
        this.getDynamicToolTipText = entity?.getDynamicToolTipText ?? null;
    }

    getSrcIcon?(rowData: any, implementedComponent: any): string {
        if(this.getDynamicSrc && this.getDynamicSrc !== null) {
            return this.getDynamicSrc(this.columnEntityInfo, rowData, implementedComponent);
        }
        return this.src;
    }

    getClassIcon?(rowData: any, implementedComponent: any): string {
        return this.classImage;
    }

    hasImage?(rowData: any, rowIndex: number, implementedComponent: any): boolean {
        if((!this.mvtEntityAssociatorComponent.tableEditEnabled && !this.showIfReadOnly) ||
            (this.columnEntityInfo.isCellEditable != null && !this.columnEntityInfo.isCellEditable(implementedComponent, rowData, rowIndex))) {
            return false;
        }
        return this.hasClassImg() || !StringUtils.isEmpty(this.getSrcIcon(rowData, implementedComponent));
    }

    hasClassImg?(): boolean {
        return !StringUtils.isEmpty(this.classImage);
    }

    getToolTipText?(rowData: any, implementedComponent: any): string {
        let tooltipText: string = this.tooltip;
        if(!StringUtils.isEmpty(tooltipText)) {
            tooltipText = this.mvtEntityAssociatorComponent.translate.instant(tooltipText);
        }
        if(this.getDynamicToolTipText && this.getDynamicToolTipText != null) {
            tooltipText = this.getDynamicToolTipText(this.columnEntityInfo, rowData, implementedComponent);
        }
        return tooltipText;
    }

    onIconClick?(implementedComponent: any, rowData: any, rowIndex: number) {
        if(this.onClick != null) {
            const tableIsEditable = this.mvtEntityAssociatorComponent.tableEditEnabled;

            // When the field type is a comment field, the click action should be allowed even if the table is
            // not editable. This is necessary to display the comments dialog, even if other elements are disabled.
            if(tableIsEditable || this.showIfReadOnly) {
                this.onClick(this.columnEntityInfo, implementedComponent, rowData, rowIndex);
            }
        }
    }

    static CreateInstance(entity: ColumnIconButton): ColumnIconButton {
        return new ColumnIconButton(entity);
    }

}

export interface ValidationOnChange {
    doOnYes: (implementedComponent: any, rowData: any, valueID: string) => void,
    doOnCancel: (implementedComponent: any, rowData: any, valueID: string) => void,
    validationMessage: string,
    messageType?: MessageType
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/base.component';
import { RecordLockingFlow } from 'src/app/core/record-locking/record-locking-flow';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { TranslateService } from '@ngx-translate/core';
import { PlantsLTSATypesDisplay } from 'src/app/core/models/plant-ltsa/display/plants-ltsa-types-display';
import { PlantsLTSASubTypesDisplay } from 'src/app/core/models/plant-ltsa/display/plants-ltsa-sub-types-display';
import { PlantsLTSATradesDisplay } from 'src/app/core/models/plant-ltsa/display/plants-ltsa-trades-display';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { PlantsLTSADisplay } from 'src/app/core/models/plant-ltsa/display/plants-ltsa-display';
import { ServiceAgreementTypes } from 'src/app/core/models/constants';
import { MessageHandlerService } from 'src/app/components/messages/message-handler/message-handler.service';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { PipelinesLTSATypesDisplay } from 'src/app/core/models/pipeline-ltsa/display/pipelines-ltsa-types-display';
import { PipelinesLTSATradesDisplay } from 'src/app/core/models/pipeline-ltsa/display/pipelines-ltsa-trades-display';
import { PipelinesLTSASubTypesDisplay } from 'src/app/core/models/pipeline-ltsa/display/pipelines-ltsa-sub-types-display';
import { PipelinesLTSADisplay } from 'src/app/core/models/pipeline-ltsa/display/pipelines-ltsa-display';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

export interface SACheckBoxKeys {
    formValue: string;
    keyValue: number;
    parentKeyValue?: number;
}
@Component({
    selector: 'app-service-agreement',
    templateUrl: './service-agreement.component.html',
    styleUrls: ['./service-agreement.component.scss']
})
export class ServiceAgreementComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() baseIndex: number;

    typeKeyValues: Array<SACheckBoxKeys> = [
        {formValue: 'maintenance', keyValue: 1},
        {formValue: 'capital', keyValue: 2},
        {formValue: 'operations', keyValue: 3},
        {formValue: 'rentals', keyValue: 4}
    ];

    subtypeKeyValues: Array<SACheckBoxKeys> = [
        {formValue: 'dailyMaintenance', keyValue: 1},
        {formValue: 'offlineEvents', keyValue: 2},
        {formValue: 'engineering', keyValue: 4},
        {formValue: 'construction', keyValue: 3},
        {formValue: 'dailyOperations', keyValue: 5},
        {formValue: 'environmentalServices', keyValue: 6},
        {formValue: 'humanResurces', keyValue: 7},
        {formValue: 'informationTechnology', keyValue: 8},
        {formValue: 'miningContractingServices', keyValue: 9},
        {formValue: 'purchasing', keyValue: 10},
        {formValue: 'security', keyValue: 11},
        {formValue: 'warehousing', keyValue: 12},
        {formValue: 'tools', keyValue: 13},
        {formValue: 'portableRestrooms', keyValue: 14},
        {formValue: 'temporaryPumps', keyValue: 15},
        {formValue: 'temporaryPower', keyValue: 16},
        {formValue: 'heavyLifting', keyValue: 17},
        {formValue: 'generalEquipment', keyValue: 18}
    ];

    tradeKeyValues: Array<SACheckBoxKeys> = [
        {formValue: 'pipefittersCapital', keyValue: 1, parentKeyValue: 2},
        {formValue: 'boilermakersCapital', keyValue: 2, parentKeyValue: 2},
        {formValue: 'weldingCapital', keyValue: 4, parentKeyValue: 2},
        {formValue: 'mechanicalInstallationCapital', keyValue: 5, parentKeyValue: 2},
        {formValue: 'electricalCapital', keyValue: 6, parentKeyValue: 2},
        {formValue: 'instrumentationCapital', keyValue: 7, parentKeyValue: 2},
        {formValue: 'scaffoldingCapital', keyValue: 8, parentKeyValue: 2},
        {formValue: 'insulationCapital', keyValue: 9, parentKeyValue: 2},
        {formValue: 'paintingCapital', keyValue: 10, parentKeyValue: 2},
        {formValue: 'inspectionCapital', keyValue: 11, parentKeyValue: 2},
        {formValue: 'ndeCapital', keyValue: 12, parentKeyValue: 2},
        {formValue: 'chemicalCleaningCapital', keyValue: 13, parentKeyValue: 2},
        {formValue: 'catalystVendorCapital', keyValue: 15, parentKeyValue: 2},

        {formValue: 'pipefittersMaintenance', keyValue: 1, parentKeyValue: 1},
        {formValue: 'boilermakersMaintenance', keyValue: 2, parentKeyValue: 1},
        {formValue: 'weldingMaintenance', keyValue: 4, parentKeyValue: 1},
        {formValue: 'mechanicalInstallationMaintenance', keyValue: 5, parentKeyValue: 1},
        {formValue: 'electricalMaintenance', keyValue: 6, parentKeyValue: 1},
        {formValue: 'instrumentationMaintenance', keyValue: 7, parentKeyValue: 1},
        {formValue: 'scaffoldingMaintenance', keyValue: 8, parentKeyValue: 1},
        {formValue: 'insulationMaintenance', keyValue: 9, parentKeyValue: 1},
        {formValue: 'paintingMaintenance', keyValue: 10, parentKeyValue: 1},
        {formValue: 'inspectionMaintenance', keyValue: 11, parentKeyValue: 1},
        {formValue: 'ndeMaintenance', keyValue: 12, parentKeyValue: 1},
        {formValue: 'chemicalCleaningMaintenance', keyValue: 13, parentKeyValue: 1},
        {formValue: 'catalystVendorMaintenance', keyValue: 15, parentKeyValue: 1}
    ];

    typeSubTypeIndex: any = {
        1: [1, 2],
        2: [3, 4],
        3: [5, 6, 7, 8, 9, 10, 11, 12],
        4: [13, 14, 15, 16, 17, 18]
    };

    private discardEventSubscription: Subscription;
    private fieldsChangeSub: Subscription;
    private disableFormSub: Subscription;
    serviceAgreementTypesForm: FormGroup;
    fieldComponentChange: Object;

    ltsaTypesDisplay: Array<PlantsLTSATypesDisplay | PipelinesLTSATypesDisplay>;
    ltsaSubTypesDisplay: Array<PlantsLTSASubTypesDisplay | PipelinesLTSASubTypesDisplay>;
    ltsaTradesDisplay: Array<PlantsLTSATradesDisplay | PipelinesLTSATradesDisplay>;
    ltsaId: number;
    isPlantLtsa: boolean = true;

    constructor(public fb: FormBuilder,
        public recordLockingFlow: RecordLockingFlow,
        public preferencesService: PreferencesSectionService,
        private headerService: HeaderService,
        private sharedService: SharedService,
        private messageHandlerService: MessageHandlerService,
        private translate: TranslateService) {
        super(recordLockingFlow, preferencesService);
    }

    ngOnInit(): void {
        this.buildForm();
        this.watchChangeForm(this.serviceAgreementTypesForm);

        this.discardEventSubscription = this.headerService.discardEvent$.subscribe(discard => {
            if (discard) {
                this.setStateForm(this.serviceAgreementTypesForm);
                this.watchChangeForm(this.serviceAgreementTypesForm);
            }
        });
        this.fieldChangeSub();
    }

    fieldChangeSub(): void {
        this.fieldsChangeSub = this.fieldsChange$.subscribe(property => {
            this.fieldComponentChange = property;
            if (Object.keys(property).length > 0) {
                this.headerService.changeForm = true;
            }
        });
    }

    buildForm(): void {
        this.serviceAgreementTypesForm = this.fb.group({
            maintenance: [false],
            capital: [false],
            operations: [false],
            rentals: [false],
            dailyMaintenance: [false],
            offlineEvents: [false],
            engineering: [false],
            construction: [false],
            dailyOperations: [false],
            environmentalServices: [false],
            humanResurces: [false],
            informationTechnology: [false],
            miningContractingServices: [false],
            purchasing: [false],
            security: [false],
            warehousing: [false],
            tools: [false],
            portableRestrooms: [false],
            temporaryPumps: [false],
            temporaryPower: [false],
            heavyLifting: [false],
            generalEquipment: [false],

            pipefittersMaintenance: [false],
            boilermakersMaintenance: [false],
            weldingMaintenance: [false],
            mechanicalInstallationMaintenance: [false],
            electricalMaintenance: [false],
            instrumentationMaintenance: [false],
            scaffoldingMaintenance: [false],
            insulationMaintenance: [false],
            paintingMaintenance: [false],
            inspectionMaintenance: [false],
            ndeMaintenance: [false],
            chemicalCleaningMaintenance: [false],
            catalystVendorMaintenance: [false],

            pipefittersCapital: [false],
            boilermakersCapital: [false],
            weldingCapital: [false],
            mechanicalInstallationCapital: [false],
            electricalCapital: [false],
            instrumentationCapital: [false],
            scaffoldingCapital: [false],
            insulationCapital: [false],
            paintingCapital: [false],
            inspectionCapital: [false],
            ndeCapital: [false],
            chemicalCleaningCapital: [false],
            catalystVendorCapital: [false]
        });
        this.disableFormSub = this.sharedService.disableFormChange$
            .subscribe((disable: boolean) => {
                disable ? this.serviceAgreementTypesForm.disable() :
                    this.serviceAgreementTypesForm.enable();
            });
    }

    ngOnDestroy(): void {
        this.discardEventSubscription.unsubscribe();
        this.fieldsChangeSub.unsubscribe();
        this.disableFormSub.unsubscribe();
    }

    setValueControls(ltsaDisplay: PlantsLTSADisplay | PipelinesLTSADisplay): void {
        if(ltsaDisplay instanceof PlantsLTSADisplay) {
            this.ltsaId = ltsaDisplay.plantLtsaId;
            this.ltsaTypesDisplay = ltsaDisplay.plantsLtsaTypesDisplay;
            this.ltsaSubTypesDisplay = ltsaDisplay.plantsLtsaSubTypesDisplay;
            this.ltsaTradesDisplay = ltsaDisplay.plantsLtsaTradesDisplay;
            this.isPlantLtsa = true;
        } else {
            this.ltsaId = ltsaDisplay.pipelineLtsaId;
            this.ltsaTypesDisplay = ltsaDisplay.pipelinesLtsaTypesDisplay;
            this.ltsaSubTypesDisplay = ltsaDisplay.pipelinesLtsaSubTypesDisplay;
            this.ltsaTradesDisplay = ltsaDisplay.pipelinesLtsaTradesDisplay;
            this.isPlantLtsa = false;
        }

        MVTOperations.filterByDeleted(this.ltsaTypesDisplay).forEach(type =>{
            let fieldName = this.typeKeyValues.find(entry => entry.keyValue === type.serviceAgreementTypeId).formValue;
            if(fieldName) {
                this.serviceAgreementTypesForm.controls[fieldName].setValue(true);
            }
        });
        MVTOperations.filterByDeleted(this.ltsaSubTypesDisplay).forEach(subtype =>{
            let fieldName = this.subtypeKeyValues.find(entry => entry.keyValue === subtype.serviceAgreementSubTypeId).formValue;
            if(fieldName) {
                this.serviceAgreementTypesForm.controls[fieldName].setValue(true);
            }
        });
        MVTOperations.filterByDeleted(this.ltsaTradesDisplay).forEach(trade =>{
            let fieldName = this.tradeKeyValues.find(entry => 
                entry.keyValue === trade.serviceAgreementTradeId && entry.parentKeyValue === trade.serviceAgreementTypeId).formValue;
            if(fieldName) {
                this.serviceAgreementTypesForm.controls[fieldName].setValue(true);
            }
        });
        this.setInitialCheckboxStatus();
    }

    changeType(event: any, fieldName: string): void{
        let keyValue = this.typeKeyValues.find(entry => entry.formValue === fieldName)?.keyValue;
        if(keyValue) {
            if(event.checked) {
                this.setRelatedCheckboxStatus(keyValue, event.checked);
                let existingType = this.ltsaTypesDisplay.find(type => type.serviceAgreementTypeId === keyValue);
                 if(existingType){
                    existingType.setOperation(OperationType.UPDATE);
                 }else{
                    let itemNew: PlantsLTSATypesDisplay | PipelinesLTSATypesDisplay = null;
                    if(this.isPlantLtsa) {
                        itemNew = PlantsLTSATypesDisplay.CreateInstance({
                            operation: OperationType.INSERT,
                            mvOrder: MVTOperations.getLastMvOrder(this.ltsaTypesDisplay) + 1,
                            serviceAgreementTypeId: keyValue,
                            plantLtsaId: this.ltsaId
                        });
                    } else {
                        itemNew = PipelinesLTSATypesDisplay.CreateInstance({
                            operation: OperationType.INSERT,
                            mvOrder: MVTOperations.getLastMvOrder(this.ltsaTypesDisplay) + 1,
                            serviceAgreementTypeId: keyValue,
                            pipelineLtsaId: this.ltsaId
                        });
                    }
                    
                    this.ltsaTypesDisplay.push(itemNew);
                 }
            } else {
                this.validateUncheckType(event.checked, keyValue);
                
            }
        }
    }

    validateUncheckType(checked: boolean, keyValue: number): void {
        let relatedSubTypes = MVTOperations.filterByDeleted(this.ltsaSubTypesDisplay)
            .filter(subType => this.typeSubTypeIndex[keyValue]
                .some(subTypeKeyValue => subType.serviceAgreementSubTypeId === subTypeKeyValue));
        let relatedTrades = MVTOperations.filterByDeleted(this.ltsaTradesDisplay)
            .filter(trade => trade.serviceAgreementTypeId === keyValue);
        const confirmFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                relatedSubTypes.forEach(subType => 
                    MVTOperations.removeItem(
                        this.ltsaSubTypesDisplay, 
                        'serviceAgreementSubTypeId', 
                        String(subType.serviceAgreementSubTypeId))
                );
                relatedTrades.forEach(trade => 
                    MVTOperations.removeItem(
                        this.ltsaTradesDisplay, 
                        'serviceAgreementTradeId', 
                        String(trade.serviceAgreementTradeId))
                );
                MVTOperations.removeItem(this.ltsaTypesDisplay, 'serviceAgreementTypeId', String(keyValue));
                this.setRelatedCheckboxStatus(keyValue, checked);
            } else if (resp.event === MessageEvent.NO) {
                this.serviceAgreementTypesForm
                    .controls[this.typeKeyValues.find(typeEntry => 
                        typeEntry.keyValue === keyValue).formValue].setValue(true);
            }
        }
        if((keyValue === ServiceAgreementTypes.Maintenance || keyValue === ServiceAgreementTypes.Capital) &&
                (relatedSubTypes.length > 0 || relatedTrades.length > 0)) {
            
            this.messageHandlerService.show(
                    this.translate.instant('ltsa.validations.removeRelatedSubTypesTrades'), 
                    MessageType.CONFIRM, 
                    confirmFunction);
        } else if ((keyValue === ServiceAgreementTypes.Operations || keyValue === ServiceAgreementTypes.Rentals) &&
                relatedSubTypes.length > 0) {
            this.messageHandlerService.show(
                    this.translate.instant('ltsa.validations.removeRelatedSubTypes'), 
                    MessageType.CONFIRM, 
                    confirmFunction);

        } else {
            MVTOperations.removeItem(this.ltsaTypesDisplay, 'serviceAgreementTypeId', String(keyValue));
            this.setRelatedCheckboxStatus(keyValue, checked);
        }
        
    }

    changeSubType(event: any, fieldName: string): void{
        let keyValue = this.subtypeKeyValues.find(entry => entry.formValue === fieldName)?.keyValue;
        if(keyValue) {
            if(event.checked) {
                let existingSubType = this.ltsaSubTypesDisplay.find(type => type.serviceAgreementSubTypeId === keyValue);
                 if(existingSubType){
                    existingSubType.setOperation(OperationType.UPDATE);
                 }else{
                    let itemNew: PlantsLTSASubTypesDisplay | PipelinesLTSASubTypesDisplay = null;
                    if(this.isPlantLtsa) {
                        itemNew = PlantsLTSASubTypesDisplay.CreateInstance({
                           operation: OperationType.INSERT,
                           mvOrder: MVTOperations.getLastMvOrder(this.ltsaSubTypesDisplay) + 1,
                           serviceAgreementSubTypeId: keyValue,
                           plantLtsaId: this.ltsaId
                        });
                    } else {
                        itemNew = PipelinesLTSASubTypesDisplay.CreateInstance({
                            operation: OperationType.INSERT,
                            mvOrder: MVTOperations.getLastMvOrder(this.ltsaSubTypesDisplay) + 1,
                            serviceAgreementSubTypeId: keyValue,
                            pipelineLtsaId: this.ltsaId
                         });
                    }
                    
                    this.ltsaSubTypesDisplay.push(itemNew);
                 }
            } else {
                MVTOperations.removeItem(this.ltsaSubTypesDisplay, 
                   'serviceAgreementSubTypeId', String(keyValue));
            }
        }
    }

    changeTrade(event: any, typeFieldName: string, fieldName: string): void{
        let typeKeyValue = this.typeKeyValues.find(entry => entry.formValue === typeFieldName)?.keyValue;
        if(typeKeyValue) {
            let keyValue = this.tradeKeyValues.find(entry => entry.formValue === fieldName)?.keyValue;
            if(keyValue) {
                if(event.checked) {
                    let existingTrade = this.ltsaTradesDisplay.find(type => 
                        type.serviceAgreementTypeId === typeKeyValue && type.serviceAgreementTradeId === keyValue);
                     if(existingTrade){
                        existingTrade.setOperation(OperationType.UPDATE);
                     }else{
                        let itemNew: PlantsLTSATradesDisplay | PipelinesLTSATradesDisplay = null;
                        if(this.isPlantLtsa) {
                            itemNew = PlantsLTSATradesDisplay.CreateInstance({
                               operation: OperationType.INSERT,
                               mvOrder: MVTOperations.getLastMvOrder(this.ltsaTradesDisplay) + 1,
                               serviceAgreementTypeId: typeKeyValue,
                               serviceAgreementTradeId: keyValue,
                               plantLtsaId: this.ltsaId
                            });
                        } else {
                            itemNew = PipelinesLTSATradesDisplay.CreateInstance({
                                operation: OperationType.INSERT,
                                mvOrder: MVTOperations.getLastMvOrder(this.ltsaTradesDisplay) + 1,
                                serviceAgreementTypeId: typeKeyValue,
                                serviceAgreementTradeId: keyValue,
                                pipelineLtsaId: this.ltsaId
                             });
                        }
                        this.ltsaTradesDisplay.push(itemNew);
                     }
                } else {
                    MVTOperations.removeItem(this.ltsaTradesDisplay, 
                       'serviceAgreementTypeId', String(typeKeyValue),
                        'serviceAgreementTradeId', String(keyValue));
                }
            }
        }
    }

    setInitialCheckboxStatus(): void {
        if (!this.serviceAgreementTypesForm.disabled) {
            this.typeKeyValues.forEach(typeKey => {
                let enabled = this.serviceAgreementTypesForm.controls[typeKey.formValue].value;
                this.setRelatedCheckboxStatus(typeKey.keyValue, enabled);
            });
        }
    }

    setRelatedCheckboxStatus(typeKeyValue: number, enabled: boolean): void {
        //disable enable all related sub types
        this.typeSubTypeIndex[typeKeyValue].forEach(subTypeId => {
            this.subtypeKeyValues.filter(subTypeKey => subTypeKey.keyValue === subTypeId)
                .forEach(subTypeKey => {
                    this.setCheckboxStatus(subTypeKey.formValue, enabled);
                });
        });
        //disable enable all related trades
        this.tradeKeyValues.filter(tradeKey => tradeKey.parentKeyValue === typeKeyValue)
            .forEach(tradeKey => {
                this.setCheckboxStatus(tradeKey.formValue, enabled);
            });
    }

    setCheckboxStatus(fieldName: string, enabled: boolean): void {
        let checkbox = this.serviceAgreementTypesForm.controls[fieldName];
        if (enabled) {
            checkbox.enable();
        } else {
            checkbox.setValue(false);
            checkbox.disable();
        }
    }

    clearComponent(): void {
        this.ltsaTypesDisplay = [];
        this.ltsaSubTypesDisplay = [];
        this.ltsaTradesDisplay = [];
        this.ltsaId = null;
        this.resetForm();
    }

    resetForm(): void {
        this.serviceAgreementTypesForm.reset({});
        this.fieldComponentChange = null;
        this.resetChangedFields();
    }

}

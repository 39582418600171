import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { PecZone, } from 'src/app/core/models/common';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { MessageResponse, MessageType } from '../../messages/message-handler/message-handler.component';
import { PecZoneSearchParams } from 'src/app/core/interfaces/ipec-zone';
import { TableUtils } from 'src/app/core/utils/table-utils';

@Component({
    selector: 'app-pec-zone-modal',
    templateUrl: './pec-zone-modal.component.html',
    styleUrls: ['./pec-zone-modal.component.scss']
})

export class PecZoneModalComponent implements OnInit {

    params: PecZoneSearchParams;
    pecZones: Array<PecZone>;
    selection: PecZone;
    @ViewChild('pecZoneId') inputPecZoneId: ElementRef;
    @ViewChild('lastElement') lastElement: ElementRef;
    @ViewChildren('tr') private rows: QueryList<ElementRef>;    
    @ViewChildren('tabIndexSetted') tabIndexSetted: QueryList<any>;

    constructor(
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public messageHandlerService: MessageHandlerService,
        public translate: TranslateService,
        public loadWheelService: LoadWheelService) {
    }

    ngOnInit(): void {
        this.params = {pecZoneId: '', pecZoneName: ''};
        this.loadZones(false);
    }

    public loadList() {
        this.loadZones(true);
    }

       private focusFirstElement() {
        setTimeout(() => {
            let first = this.rows.first;
            if (first) {
                first.nativeElement.focus();
                first.nativeElement.click();
            }
        }, 0);
    }

    private loadZones(focusFirst: boolean) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.pecZone"));
        this.sharedService.getPecZoneData(this.params)
            .subscribe({
                next: (pecZones :  PecZone[]) => {
                   this.pecZones = pecZones;
                    if(this.pecZones.length > 0) {
                        if(focusFirst){
                            this.focusFirstElement();
                        }
                    } else {
                        this.returnFocus("common.searchNotFound");
                    }
                    this.loadWheelService.hideWheel(wheel);
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                }
            });
    }

    returnFocus = (msgModal: string) => {
        const selectOpt = (resp: MessageResponse): void => {
            setTimeout(() => this.inputPecZoneId.nativeElement.focus(), 200);
        }
        this.messageHandlerService.show(this.translate.instant(msgModal), MessageType.INFO, selectOpt)
    }

    private returnProduct(selRow: any) {
        let result: PecZone = {
            pecZoneId: selRow.pecZoneId,
            pecZoneName: selRow.pecZoneName
        };
        this.activeModal.close(result);
    }

    onRowSelect(event: any) {
        this.selection = event.data;
    }

    onRowDblclick(selRow: any) {
        this.returnProduct(selRow);
    }

    addRowSingleClick() {
        this.onRowDblclick(this.selection);
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "selection", event, entityArray, hasHeader);
    }

    closeModal() {
        this.activeModal.close()
    }

}

<div class="content-modal">
    <div class="container-modal">
        <div class="row mb-1">
            <div class="col-6">
                <div class="row mb-1">
                    <div class="col-6 lg:col-3 xl:col-3">
                        <label class="custom-label">{{'current_contact_id'| translate}}:</label>
                    </div>
                    <div class="col-6 lg:col-3 xl:col-3">
                        <label class="custom-label">{{ contactID }}</label>
                    </div>
                    <div class="col-0 lg:col-6 xl:col-6">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6">
                <label class="custom-label">{{"related_entities"| translate }}:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="container-table-modal">
                    <div class="company-table">
                        <p-table [value]="contact" [columns]="cols" selectionMode="single"
                            styleClass="p-datatable-gridlines" [rows]="rowsPerPage" [showCurrentPageReport]="true"
                            [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="rowsPerPage"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [resizableColumns]="true" (onPage)="onPageChange($event)"
                            [reorderableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th *ngFor="let col of columns" pReorderableColumn pResizableColumn
                                        pSortableColumn="{{col.field}}">
                                        {{col.header}}
                                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="colgroup" let-columns>
                                <colgroup>
                                    <col *ngFor="let col of columns" [style]="col.style">
                                </colgroup>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr [pSelectableRow]="rowData" (dblclick)="onRowDblclick(rowData)"
                                    (click)="onClickRow(rowData)">
                                    <td *ngFor="let col of columns" 
                                        [ngStyle]="{'color': getColumnColor(rowData)}"
                                        [style.white-space]="col.wSpace || 'normal'"
                                        [title]='getColumnTitle(rowData)'>
                                        <div *ngIf="!col.isDate">
                                            {{rowData[col.field]}}
                                        </div>
                                        <div *ngIf="col.isDate">
                                            {{ rowData[col.field] | date: 'dd-MMM-y'}}
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-columns>
                                <tr>
                                    <td [attr.colspan]="columns.length + 1">
                                        <div class="table-msg mt-1">
                                            <span>{{'add_contact_comments' | translate}}</span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="g-row main-content">
            <div class="col-12 text-start">
                <label class="custom-label">{{'records'|translate}} <span class="ml-1r">{{
                        contact.length}}</span></label>
            </div>
        </div>
        <div class="g-row main-content">
            <div class="col-12 text-start">
                <label class="custom-label">{{"info_related_entities"| translate }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn-custom" [disabled]="disableButton"
                    [ngStyle]="{'color':disableButton ? 'grey' : 'black'}"
                    (click)="addRowSelected()">{{'ok_title_button'| translate}}</button>
            </div>
        </div>
    </div>
</div>

import { IContactMap } from "../interfaces/icontacts";
import { AddressType } from "./enumerations/address-type";
import { Plants } from './plant';
import { SiteResult } from "./site";
import { UnitsDisplay } from "./unit/display/units-display";

export class Address {

    public constructor(
        public entityId: number,
        public addressType: AddressType,
        public countryId: string,
        public countryName: string,
        public zipCode: string,
        public address1: string,
        public address2: string,
        public stateAbbrev: string,
        public stateName: string,
        public cityName: string,
        public pecZoneId: string,
        public countyName: string,
        public c_id:number,
        public s_id:number,
        public ci_id:number,
        public co_id:number,
    ) {}

    static BuildPhysicalAddress(entity: any): Address {
        return new Address(null,
                           AddressType.PHYSICAL,
                           entity.physCountry,
                           entity.physCountryDesc || entity.physCountryName,
                           entity.physPostalCode,
                           entity.physAddressV1,
                           entity.physAddressV2,
                           entity.physState,
                           entity.physStateDesc || entity.physStateName,
                           entity.physCity,
                           entity.pecZoneSimplex,
                           entity.countyName || entity.physCountyName ,
                           entity.physCountryId || entity.physCountryCId,
                           entity.physStateId || entity.physStateSId,
                           entity.physCityId || entity.physCityCiId,
                           entity.physCountyId || entity.physCountyCoId

                           );
    }

    static BuildMailingAddress(entity: any): Address {
        return new Address(null,
                           AddressType.MAILING,
                           entity.mailCountry,
                           entity.mailCountryDesc || entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailState,
                           entity.mailStateDesc || entity.mailStateName,
                           entity.mailCity,
                           '',
                           '',
                           entity.mailCountryId ?? entity.mailCountryCId,
                           entity.mailStateId ?? entity.mailStateSId,
                           entity.mailCityId ?? entity.mailCityCiId,
                           0);
    }

    static BuildContactAddress(entity: any): Address {
        return new Address(null,
                           AddressType.MAILING,
                           entity.mailCountry,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressLine1,
                           entity.mailAddressLine2,
                           entity.mailState,
                           entity.mailStateName,
                           entity.mailCity,
                           '',
                           '',
                           entity.mailCountryId,
                           entity.mailStateId,
                           entity.mailCityId,
                           0);
    }

    static BuildContactFromSearch(entity: any): Address {
        return new Address(null,
                           AddressType.MAILING,
                           entity.COUNTRY,
                           entity.COUNTRY_NAME,
                           entity.POSTAL_CODE,
                           entity.ADDRESS_V1,
                           entity.ADDRESS_V2,
                           entity.STATE,
                           entity.STATE_NAME,
                           entity.CITY,
                           '',
                           '',
                           entity.MAIL_COUNTRY_C_ID,
                           entity.MAIL_STATE_S_ID,
                           entity.MAIL_CITY_CI_ID,
                           0);
    }

    static BuildPhysicalAddressFromAsset(entity: any): Address {
        return new Address(null,
                           AddressType.PHYSICAL,
                           entity.originPhysCountryAbrev,
                           entity.originPhysCountryName,
                           entity.originPhysPostalCode,
                           entity.originPhysAddressV1,
                           entity.originPhysAddressV2,
                           entity.originPhysStateAbrev,
                           entity.originPhysStateName,
                           entity.originPhysCityName,
                           null,
                           entity.originPhysCountyName,
                           entity.originPhysCountry,
                           entity.originPhysState,
                           entity.originPhysCity,
                           entity.originPhysCounty
                           );
    }

    static BuildOriginAddress(entity: any): Address {
        return this.BuildPhysicalAddressFromAsset(entity.assetDisplay);
    }

    static BuildDestinationAddress(entity: any): Address {
        return new Address(null,
                           AddressType.PHYSICAL,
                           entity.destinationPhysCountryAbrev,
                           entity.destinationPhysCountryName,
                           entity.destinationPhysPostalCode,
                           entity.destinationPhysAddressV1,
                           entity.destinationPhysAddressV2,
                           entity.destinationPhysStateAbrev,
                           entity.destinationPhysStateName,
                           entity.destinationPhysCityName,
                           null,
                           entity.destinationPhysCountyName,
                           entity.destinationPhysCountry,
                           entity.destinationPhysState,
                           entity.destinationPhysCity,
                           entity.destinationPhysCounty
                           );
    }

    static BuildMailingAddressFromAsset(entity: any): Address {
        return new Address(null,
                           AddressType.MAILING,
                           entity.mailCountryAbrev,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailStateAbrev,
                           entity.mailStateName,
                           entity.mailCityName,
                           '',
                           '',
                           entity.mailCountry,
                           entity.mailState,
                           entity.mailCity,
                           0);
    }

    static BuildPhysicalAddressFromPlant(entity: Plants): Address {
        return new Address(entity.plantID,
                           AddressType.PHYSICAL,
                           entity.physCountry,
                           entity.physCountryName,
                           entity.physPostalCode,
                           entity.physAddressV1,
                           entity.physAddressV2,
                           entity.physState,
                           entity.physStateName,
                           entity.physCity,
                           entity.pecZoneSimplex,
                           entity.physCountyName,
                           entity.physCountryId,
                           entity.physStateId,
                           entity.physCityId,
                           entity.physCountyId
                           );
    }

    static BuildMailingAddressFromPlant(entity: any): Address {
        return new Address(entity.plantID,
                           AddressType.MAILING,
                           entity.mailCountry,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailState,
                           entity.mailStateName,
                           entity.mailCity,
                           '',
                           '',
                           entity.mailCountryId,
                           entity.mailStateId,
                           entity.mailCityId,
                           0);
    }

    static BuildPhysicalAddressFromSite(entity: any): Address {
        return new Address(entity.assetID,
                           AddressType.PHYSICAL,
                           entity.originPhysCountryAbrev,
                           entity.originPhysCountryName,
                           entity.originPhysPostalCode,
                           entity.originPhysAddressV1,
                           entity.originPhysAddressV2,
                           entity.originPhysStateAbrev,
                           entity.originPhysStateName,
                           entity.originPhysCityName,
                           null,
                           entity.originPhysCountyName,
                           entity.originPhysCountry,
                           entity.originPhysState,
                           entity.originPhysCity,
                           entity.originPhysCounty
            );
    }

    static BuildMailingAddressFromSite(entity: any): Address {
        return new Address(entity.assetID,
                           AddressType.MAILING,
                           entity.mailCountryAbrev,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailStateAbrev,
                           entity.mailStateName,
                           entity.mailCityName,
                           '',
                           '',
                           entity.mailCountry,
                           entity.mailState,
                           entity.mailCity,
                           0);
    }

    static BuildPhysicalAddressFromSiteResult(site: SiteResult): Address {
        return new Address(site.assetId,
                           AddressType.PHYSICAL,
                           site.physCountryAbrev,
                           site.physCountryName,
                           site.physPostalCode,
                           site.physAddressV1,
                           site.physAddressV2,
                           site.physStateAbrev,
                           site.physStateName,
                           site.physCityName,
                           site.pecZoneId,
                           site.physCountyName,
                           site.physCountry,
                           site.physState,
                           site.physCity,
                           site.physCounty
                           );
    }

    static BuildMailingAddressFromSiteResult(site: SiteResult): Address {
        return new Address(site.assetId,
                           AddressType.MAILING,
                           site.mailCountryAbrev,
                           site.mailCountryName,
                           site.mailPostalCode,
                           site.mailAddressV1,
                           site.mailAddressV2,
                           site.mailStateAbrev,
                           site.mailStateName,
                           site.mailCityName,
                           '',
                           '',
                           site.mailCountry,
                           site.mailState,
                           site.mailCity,
                           0);
    }

    static BuildMailingAddressFromArea(entity: any): Address {
        return new Address(entity.areaID,
                           AddressType.MAILING,
                           entity.mailCountry,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailState,
                           entity.mailStateName,
                           entity.mailCity,
                           '',
                           '',
                           entity.mailCountryId,
                           entity.mailStateId,
                           entity.mailCityId,
                           0);
    }

    static BuildMailingAddressFromUnit(entity: UnitsDisplay): Address {
        return new Address(entity.unitId,
                           AddressType.MAILING,
                           entity.mailCountry,
                           entity.mailCountryName,
                           entity.mailPostalCode,
                           entity.mailAddressV1,
                           entity.mailAddressV2,
                           entity.mailState,
                           entity.mailStateName,
                           entity.mailCity,
                           '',
                           '',
                           entity.mailCountryId,
                           entity.mailStateId,
                           entity.mailCityId,
                           0);
    }


    static CompareAddress(plants: Plants[], sitePhysicalAddress: Address) {
        const match = plants.find((element: Plants) =>
        Address.hasSitePhysicalOnshoreDiff(sitePhysicalAddress, element));

        return match ? false : true;
    }

    static CompareMailingAddress(plants: Plants[], sitePhysicalAddress: Address) {
        const match = plants.find((element: Plants) =>
        Address.hasSiteMailingDiff(sitePhysicalAddress, element));
        return match ? false : true;
    }

    static hasSitePhysicalOnshoreDiff(address: Address, plant: Plants): boolean {
        return this.hasPhysicalOnshoreDiff(address, new Address(null, AddressType.PHYSICAL,
            null, plant.physCountryName, plant.physPostalCode, plant.physAddressV1,
            plant.physAddressV2, null, plant.physStateName, plant.physCity,null,
            plant.physCountyName, null, null, null, null));
    }

    static hasPhysicalOnshoreDiff(address1: Address, address2: Address): boolean {
        return address1.address1 !== address2.address1
                || address1.address2 !== address2.address2
                || address1.cityName !== address2.cityName
                || address1.countryName !== address2.countryName
                || address1.countyName !== address2.countyName
                || address1.zipCode !== address2.zipCode
                || address1.stateName !== address2.stateName;
    }

    static hasSiteMailingDiff(address: Address, plant: Plants): boolean {
        return this.hasMailingDiff(address, new Address(null, AddressType.MAILING,
            null, plant.mailCountryName, plant.mailPostalCode, plant.mailAddressV1,
            plant.mailAddressV2, plant.mailState, plant.mailStateName, plant.mailCity,
            null, null, null, null, null, null));
    }

    static hasMailingDiff(address1: Address, address2: Address): boolean {
        return address1.address1 !== address2.address1
                || address1.address2 !== address2.address2
                || address1.cityName !== address2.cityName
                || address1.countryName !== address2.countryName
                || address1.zipCode !== address2.zipCode
                || address1.stateAbbrev !== address2.stateAbbrev
                || address1.stateName !== address2.stateName;
    }

    static getContactMap(mailAddress: Address): IContactMap {
        return {
            faxCc: '',
            faxExt: '',
            faxNo: '',
            phoneCc: '',
            phoneExt: '',
            phoneNo: '',
            phoneMobile: false,
            addressV1: mailAddress.address1,
            addressV2: mailAddress.address2,
            city: mailAddress.cityName,
            country: mailAddress.countryId,
            countryName: mailAddress.countryName,
            state: mailAddress.stateAbbrev,
            stateName: mailAddress.stateName,
            cityId: 0,
            countryId: 0,
            stateId: 0,
            zipCode: mailAddress.zipCode,
            ownerId: null,
            ownerName: null,
            ownerStatus: null,
            physCountry: '',
            physCountryName: ''
        }
    }


}

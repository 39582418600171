import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SharedService } from 'src/app/core/services/shared.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationSearchComponent, LocationSearchParameters } from '../location-search/location-search.component';
import { MessageEvent, MessageResponse, MessageType } from '../../messages/message-handler/message-handler.component';
import { AddressService } from '../../address-form/address.service';
import { Country, State } from 'src/app/core/models';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { StringUtils } from 'src/app/core/utils/string-utils';

@Component({
    selector: 'geography-request-modal',
    templateUrl: './geography-request-modal.component.html',
    styleUrls: ['./geography-request-modal.component.scss']
})

export class GeographyRequestModalComponent implements OnInit {

    public country: string = '';
    public state: string = '';
    public city: string = '';
    public county: string = '';
    public postalCode: string = '';
    public latitude: string = '';
    public longitude: string = '';
    public comment: string = '';
    public offshore: string = '0';

    public countryId: string = '';
    public prevCountryId: string = '';
    public stateAbbrev: string = '';
    public prevStateAbbrev: string = '';
    public waterBody: string = '';
    public area: string = '';
    public fieldName: string = '';

    public stateName: string = '';
    public countryName: string = '';

    public readonly ONSHORE_OPTION = '0';

    @ViewChild('countryInput', { static: false }) countryInput: ElementRef;
    @ViewChild('stateInput', { static: false }) stateInput: ElementRef;
    @ViewChild('waterBodyInput', { static: false }) waterBodyInput: ElementRef;
    @ViewChild('longitudeInput', { static: false }) longitudeInput: ElementRef;
    @ViewChild('latitudeInput', { static: false }) latitudeInput: ElementRef;


    constructor(
        private sharedService: SharedService,
        private activeModal: DynamicDialogRef,
        private messageHandler: MessageHandlerService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private loadWheelService: LoadWheelService,
        private addressService: AddressService) {
    }

    ngOnInit(): void {}

    validateField(): boolean {
        let alertMsg = '';
        if (this.offshore === this.ONSHORE_OPTION) {
            if (StringUtils.isEmptyInPrimitiveTypes(this.country)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredCountry') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.state)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredState') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.city)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredCity') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.latitude) && !StringUtils.isEmptyInPrimitiveTypes(this.longitude)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredLatitude') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.longitude) && !StringUtils.isEmptyInPrimitiveTypes(this.latitude)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredLongitude') + '<br>';
            }
        } else {
            if (StringUtils.isEmptyInPrimitiveTypes(this.countryName)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredCountry') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.stateName)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredState') + '<br>';
            }

            if (StringUtils.isEmptyInPrimitiveTypes(this.waterBody)) {
                alertMsg += this.translate.instant('geographyRequest.validations.requiredWaterBody') + '<br>';
            }
        }

        if (alertMsg !== '') {
            this.messageHandler.show(alertMsg, MessageType.VALIDATION_ERROR);
            return false;
        }
        return true;

    }

    send(): void {
        if (this.validateField()) {
            let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("geographyRequest.sendingEmail"));
            this.sharedService.sendGeographyEmail(this.getAddressType(), this.getEmailContent()).subscribe({
                next: (data: any) => {
                    this.loadWheelService.hideWheel(wheel);
                    const selectOpt = (resp: MessageResponse): void => {
                        if (resp.event == MessageEvent.OK) {
                            this.reset();
                        } else if (resp.event == MessageEvent.CANCEL) {
                            this.activeModal.close(true);
                        }
                    };
                    this.messageHandler.show(this.translate.instant('geographyRequest.sentMessage'),
                                MessageType.CUSTOM_INFO, selectOpt,
                                    this.translate.instant('geographyRequest.makeAnother'),
                                    this.translate.instant('geographyRequest.close'));
                },
                error: (error: any) => {
                    this.loadWheelService.hideWheel(wheel);
                }
            });
        }
    }

    private getAddressType(): string {
        if(this.offshore === this.ONSHORE_OPTION){
            return "Onshore";
        }
        return "Offshore";
    }

    private getEmailContent(): string {
        return this.offshore === this.ONSHORE_OPTION ?
            this.getOnshoreEmailContent() : this.getOffshoreEmailContent();
    }

    private getOnshoreEmailContent(): string {
        return "Country: " + this.country + "\n"
            + "State: " + this.state + "\n"
            + "City: " + this.city + "\n"
            + "County: " + this.county + "\n"
            + "Postal: " + this.postalCode + "\n"
            + "Latitude: " + this.latitude + "\n"
            + "Longitude: " + this.longitude + "\n"
            + "Comments: " + this.comment + "\n";

    }

    private getOffshoreEmailContent(): string {
        return "Country: " + this.countryName + "\n"
            + "State: " + this.stateName + "\n"
            + "Water Body: " + this.waterBody + "\n"
            + "Area: " + this.area + "\n"
            + "Field Name: " + this.fieldName + "\n"
            + "Comments: " + this.comment + "\n";
    }

    cityOnBlur(): void {
        if(this.city === ''){
            this.latitude = '';
            this.longitude = '';
        }
    }

    reset(): void {
        this.clearOnshoreOffshoreAddress();
        this.comment = '';
    }

    clearOnshoreOffshoreAddress (): void {
        this.clearOnshoreAddress();
        this.clearOffshoreCountry();
        this.clearOffshoreInputs();
    }

    clearOnshoreAddress(): void {
        this.country = '';
        this.state = '';
        this.county = '';
        this.city = '';
        this.postalCode = '';
        this.latitude = '';
        this.longitude = '';
    }

    clearOffshoreInputs(){
        this.waterBody = '';
        this.area = '';
        this.fieldName = '';
    }

    clearOffshoreCountry(): void {        
        this.countryId = '';
        this.prevCountryId = '';
        this.countryName = '';
        this.clearRelatedCountryfields();
    }

    clearRelatedCountryfields(): void {
        this.stateAbbrev = '';
        this.prevStateAbbrev = '';
        this.stateName = '';
    }

    clearAddressCountryKeyUp(): void {
        if (this.countryId === '') {
            this.clearOffshoreCountry();
        }
    }

    stateChangeFocusOut(): void {
        if (this.stateAbbrev !== '') {
            this.stateChangeOnEnter();
        } else {
            this.clearRelatedCountryfields();
        }
    }

    stateChangeOnEnter(): void {
        this.searchState(false);
    }

    stateChangeOnButtonClick(): void {
        this.searchState(true);
    }

    private searchState(searchButton: boolean): void {
        if (this.countryId === '') {
            this.stateAbbrev = '';
            this.prevStateAbbrev = '';
            const confirmOk = () => this.stateInput.nativeElement.focus();
            this.messageHandler.show(this.translate.instant('common.countryFirst'),
                MessageType.INFO, confirmOk);
            return;
        }

        if (this.stateAbbrev !== '' && this.stateAbbrev === this.prevStateAbbrev) {
            return;
        }

        this.addressService.getStateByCountry(this.countryId, '', false)
            .subscribe((states: State[]) => {
                if (this.stateAbbrev === '' || searchButton) {
                    this.openStateSearchModal(states);
                } else if (states.length > 1) {
                    let statesFound = states.filter(s => s.stateAbbrev === this.stateAbbrev);
                    if (statesFound.length === 0) {
                        this.stateAbbrev = '';
                        this.prevStateAbbrev = '';
                        this.stateName = '';
                    } else if (statesFound.length === 1) {
                        this.stateAbbrev = statesFound[0].stateAbbrev;
                        this.prevStateAbbrev = statesFound[0].stateAbbrev;
                        this.stateName = statesFound[0].stateName;
                    } else if (statesFound.length > 1) {
                        this.openStateSearchModal();
                    }
                }
            });
    }

    public openStateSearchModal(states?: State[]): void {
        if (this.countryId === '') {
            this.messageHandler.show(this.translate.instant('common.countryFirst'),  MessageType.ERROR)
            return;
        }

        let dialogParameters: LocationSearchParameters = {
            type: 'state', countryId: this.countryId,
            countryName: this.countryName,
            stateAbbrev: this.stateAbbrev,
            stateName: '', cityName: '', ciId: '',
            states: states
        };

        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: this.translate.instant('modalHeader.selectState'),
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: any) => {
            this.stateInput.nativeElement.focus();
            if (response !== undefined) {
                this.stateAbbrev = response.stateAbbrev;
                this.prevStateAbbrev = response.stateAbbrev;
                this.stateName = response.stateName;
            }
        });

    }

    countryChangeFocusOut(): void {
        if (this.countryId !== '') {
            this.countryChangeOnEnter();
        } else {
            this.clearOffshoreCountry();
        }
    }

    countryChangeOnEnter(): void {
        this.searchCountry();
    }

    private searchCountry(): void {
        if (this.countryId !== '' && this.countryId === this.prevCountryId) {
            return;
        }
        if (this.countryId === '') {
            this.openCountrySearchModal();
        } else {
            this.addressService.searchCountries(this.countryId, '', false)
                .subscribe((countries: Country[]) => {
                    if (countries.length === 1) {
                        this.countryId = countries[0].countryId;
                        this.prevCountryId = countries[0].countryId;
                        this.countryName = countries[0].countryName;
                        this.clearRelatedCountryfields();
                    } else if (countries.length < 1) {
                        this.countryId = '';
                        this.prevCountryId = '';
                        this.countryName = '';
                        this.clearRelatedCountryfields();
                        const confirmOk = () => this.countryInput.nativeElement.focus();
                        this.messageHandler.show(this.translate.instant('common.geographyNotAvailable'),
                            MessageType.ERROR, confirmOk);
                    } else {
                        this.openCountrySearchModal();
                    }
                });
        }
    }

    public openCountrySearchModal(): void {
        let dialogParameters: LocationSearchParameters = {
            type: 'country',
            countryId: this.countryId,
            stateAbbrev: '',
            stateName: '',
            cityName: '',
            ciId: '',
            entityType: '',
            dashAccess: true
        };
        const modalRef = this.dialogService.open(LocationSearchComponent, {
            header: this.translate.instant('modalHeader.countrySearch'),
            width: '40rem',
            data: dialogParameters,
            dismissableMask: true,
            draggable: true,
            keepInViewport: true
        });

        modalRef.onClose.subscribe((response: any) => {
            if (response !== undefined) {
                if (this.countryId !== response.countryId) {
                    this.countryId = response.countryId;
                    this.prevCountryId = response.countryId;
                    this.countryName = response.countryName;
                    this.clearRelatedCountryfields();
                }
                this.stateInput.nativeElement.focus();
            } else {
                this.countryInput.nativeElement.focus();
            }
        });
    }

    closeModal(): void {
        this.activeModal.close(false);
    }

    onOffshoreChange(): void {
        this.reset();
    }

    public checkNoSymbols(event:any): boolean {
        return StringUtils.checkNoSymbols(event.key);
    }

    verifyLatitude(): void {
        let latitude: number = Number(this.latitude);
        if(Number.isNaN(latitude)){
            this.latitude = ''; 
        } else {
            if (latitude > 90 || latitude < -90) {
                const selectOpt = (resp: MessageResponse): void => {
                    if (resp.event == MessageEvent.OK) {
                        this.latitudeInput.nativeElement.focus();
                    }
                }
                this.messageHandler.show(this.translate.instant('common.wrongLatitude'),
                    MessageType.INFO, selectOpt);
                this.latitude = '';
            }
        }
    }

    verifyLongitude(): void {
        let longitude: number = Number(this.longitude);
        if(Number.isNaN(longitude)){
            this.longitude = '';
        } else {
            if (longitude > 180 || longitude < -180) {
                const selectOpt = (resp: MessageResponse): void => {
                    if (resp.event == MessageEvent.OK) {
                        this.longitudeInput.nativeElement.focus();
                    }
                }
                this.messageHandler.show(this.translate.instant('common.wrongLongitude'), 
                    MessageType.INFO, selectOpt);
                this.longitude = '';
            }
        }
    }

}

import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appFocusElement]'
})
export class FocusElementDirective implements AfterViewInit {

    constructor(private host: ElementRef) {}

    ngAfterViewInit() {
      setTimeout(() => this.host.nativeElement.focus(), 150);
    }

}


import { DateUtil } from "../utils/date-util";
import { StringUtils } from "../utils/string-utils";

export enum ContactOperation {
    NONE = 0,
    ADD = 1,
    ADD_AND_VIEW = 2,
    CHANGED_IN_POOL = 3
}

export class ContactsWithException {
    contacts: Contact[];
    exception: string;

    constructor(contacts: Contact[], exception: string) {
        this.contacts = contacts;
        this.exception = exception;
    }
}

export class Contact {
    Operation: ContactOperation;
    MvId: number;
    ContactID: number;
    FirstName: string;
    LastName: string;
    ActualTitle: string;
    Status: string;
    QcDate: string;
    QcDateObj: Date;
    OnSite: boolean;
    Sell: string;
    CompanyID:number;
    CompanyName:string;
    CompanyStatus:string;
    EntityRelation:string;
    EmailAddress:string;
    Phone:number;
    AltPhone:number;
    Entity_Name:string;
    Country_Name:string;
    EntityId:number;
    EntityCity:string;
    EntityState:string;
    MailPostalCode:string;
    EmailStatus: string;
    EmailOverrideStatus: string;
    OverrideDate: string;
    OverridePerson: string;

    ContactTitleId: string;
    ContactTitleDesc: string;
    ContactFunction: string;
    EntityAddressV1: string;
    EntityAddressV2: string;
    EntityRecordStatus: string;
    RecordStatus: string;

    EntityPhoneCC: number;
    EntityPhoneNO: string;
    EntityPhoneEXT: string;
    EntityPhoneMobile: string;

    EntityAltPhoneCC: number;
    EntityAltPhoneNO: string;
    EntityAltPhoneEXT: string;
    EntityAltPhoneMobile: string;

    FaxCC: number;
    FaxNO: string;
    FaxEXT: string;

    StateName: string;
    LinkedInId: string;
    CountryName: string;
    MailCountry: string;

    MailCountryCID: number;
    MailStateSID: number;
    MailCityCIID: number;

    LastUser: string;
    LastDate: string;
    MailAddressVerified: string;
    MailAddressVerifiedDate: string;

    ContactStatus: string;
    ContactOptedOut : boolean;

    private constructor(mvId: number,
                        contactID: number,
                        firstName: string,
                        lastName: string,
                        actualTitle: string,
                        status: string,
                        qcDate: string,
                        onSite: boolean,
                        sell: string,
                        companyID:number,
                        companyName:string,
                        companyStatus:string,
                        entityRelation:string,
                        emailAddress: string,
                        entityPhone:number,
                        altPhone:number,
                        entityName:string,
                        countryName:string,
                        entityId:number,
                        entityCity:string,
                        entityState:string,
                        postalCode:string,
                        emailStatus: string,
                        emailOverrideStatus:string,
                        overrideDate: string,
                        overridePerson: string,
                        contactTitleId: string,
                        contactTitleDesc: string,
                        contactFunction: string,
                        entityAddressV1: string,
                        entityAddressV2: string,
                        entityRecordStatus: string,
                        recordStatus: string,
                        mailCountry: string,
                        stateName: string,
                        linkedInId: string,
                        entityPhoneCC: number,
                        entityPhoneNO: string,
                        entityPhoneEXT: string,
                        entityPhoneMobile: string,
                        entityAltPhoneCC: number,
                        entityAltPhoneNO: string,
                        entityAltPhoneEXT: string,
                        entityAltPhoneMobile: string,
                        faxCC: number,
                        faxNO: string,
                        faxEXT: string,
                        mailCountryCID: number,
                        mailStateSID:number,
                        mailCityCIID:number,
                        contactStatus: string,
                        contactOptedOut: boolean,
                        lastUser: string,
                        lastDate: string) {
        this.MvId = mvId;
        this.Operation = ContactOperation.ADD;
        this.ContactID = contactID;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.ActualTitle = actualTitle;
        this.Status = status;
        this.OnSite = onSite;
        this.Sell = sell;
        this.CompanyID=companyID;
        this.CompanyName=companyName;
        this.CompanyStatus = companyStatus;
        this.EntityRelation=entityRelation;
        this.EmailAddress=emailAddress;
        this.Phone=entityPhone;
        this.AltPhone=altPhone;
        this.Entity_Name=entityName;
        this.Country_Name=countryName;
        this.EntityId=entityId;
        this.EntityCity=entityCity;
        this.EntityState=entityState;
        this.MailPostalCode= postalCode;
        this.EmailStatus = emailStatus;
        this.EmailOverrideStatus = emailOverrideStatus;
        this.OverrideDate = overrideDate;
        this.OverridePerson = overridePerson;

        this.ContactTitleId = contactTitleId;
        this.ContactTitleDesc = contactTitleDesc;
        this.ContactFunction = contactFunction;
        this.EntityAddressV1 = entityAddressV1;
        this.EntityAddressV2 = entityAddressV2;
        this.EntityRecordStatus = entityRecordStatus;
        this.RecordStatus = recordStatus;

        this.MailCountry = mailCountry;
        this.StateName = stateName;
        this.LinkedInId = linkedInId;

        this.EntityPhoneCC = entityPhoneCC;
        this.EntityPhoneNO = entityPhoneNO;
        this.EntityPhoneEXT = entityPhoneEXT;
        this.EntityPhoneMobile = entityPhoneMobile;

        this.EntityAltPhoneCC = entityAltPhoneCC;
        this.EntityAltPhoneNO = entityAltPhoneNO;
        this.EntityAltPhoneEXT = entityAltPhoneEXT;
        this.EntityAltPhoneMobile = entityAltPhoneMobile;

        this.FaxCC = faxCC;
        this.FaxNO = faxNO;
        this.FaxEXT = faxEXT;

        this.MailCountryCID = mailCountryCID;
        this.MailStateSID = mailStateSID;
        this.MailCityCIID = mailCityCIID;

        this.ContactStatus = contactStatus;
        this.ContactOptedOut = contactOptedOut;

        this.LastUser = lastUser;

        this.QcDate = qcDate;
        this.QcDateObj = DateUtil.convertToDate(qcDate);
        this.LastDate = lastDate;
    }

    set SellBool(value: boolean) {
        this.Sell = (value ? '1' : '0');
    }
    get SellBool(): boolean {
        return (this.Sell == '1');
    }

    isAddressMissing(): boolean {
        let addressMissing: boolean = false;
        if(StringUtils.isEmpty(this.EntityAddressV1)
                || StringUtils.isEmpty(this.EntityCity)
                || StringUtils.isEmpty(this.EntityState)
                || StringUtils.isEmpty(this.MailPostalCode)){
            addressMissing = true;
        }
        return addressMissing;
    }

    merge(item: any): void {
        this.ContactFunction = item.CONTACT_FUNCTION;
        this.LastUser = item.CONTACT_LAST_USER;
        this.LastDate = item.CONTACT_LAST_DATE;

        this.MailCountry = item.MAIL_COUNTRY;
        this.Country_Name = item.MAIL_COUNTRY_NAME;

        this.EntityAddressV1 = item.MAIL_ADDRESS_LINE1;
        this.EntityAddressV2 = item.MAIL_ADDRESS_LINE2;

        this.StateName = item.MAIL_STATE_NAME;
        this.EntityState = item.MAIL_STATE;
        this.EntityCity = item.MAIL_CITY;

        this.MailCountryCID = item.MAIL_COUNTRY_C_ID;
        this.MailStateSID = item.MAIL_STATE_S_ID;
        this.MailCityCIID = item.MAIL_CITY_CI_ID;

        this.MailPostalCode = item.MAIL_POSTAL_CODE;

        this.MailAddressVerified = item.MAIL_ADDRESS_VERIFIED;
        this.MailAddressVerifiedDate = item.MAIL_ADDRESS_VERIFIED_DATE;
    }

    static BuilContact(entity: Array<any>): Array<Contact> {
        return entity.map(item => Contact.CreateInstance(item));
    }

    static CreateInstance(contact: any): Contact {
        const { MV_ID, CONTACT_ID, FIRST_NAME, LAST_NAME, LAST_DATE,
                ENTITY_ACTUAL_TITLE_DESC, ENTITY_STATUS, ENTITY_QC_DATE,
                ENTITY_ON_SITE, ENTITY_SALE_FLAG,COMPANY_ID,COMPANY_NAME,
                COMPANY_STATUS,ENTITY_RELATION,EMAIL_ADDRESS,ENTITY_PHONE,
                ENTITY_NAME,COUNTRY_NAME,ENTITY_ID,ENTITY_CITY,
                ENTITY_STATE,MAIL_POSTAL_CODE, CONTACT_QC_DATE, ENTITY_TYPE,
                CITY, STATE, POSTAL_CODE, NATIONALITY_NAME, EMAIL_STATUS, EMAIL_OVERRIDE_STATUS,
                OVERRIDE_SET_DATE, OVERRIDE_PERSON, ENTITY_TITLE_DESC, CONTACT_FUNCTION, ENTITY_ADDRESS_V1, ENTITY_ADDRESS_V2,
                TITLE_DESC, ADDRESS_V1, ADDRESS_V2, ENTITY_RECORD_STATUS, RECORD_STATUS, MAIN_PHONE_MOBILE, CONTACT_STATUS, MAIL_COUNTRY_C_ID,
                MAIL_STATE_S_ID, MAIL_CITY_CI_ID, TITLE_ID, COUNTRY, STATE_NAME, LINKEDIN_ID,
                ENTITY_PHONE_CC, ENTITY_PHONE_NO, ENTITY_PHONE_EXT, ENTITY_PHONE_MOBILE,
                ENTITY_ALT_PHONE_CC, ENTITY_ALT_PHONE_NO, ENTITY_ALT_PHONE_EXT, ENTITY_ALT_PHONE_MOBILE,
                FAX_CC, FAX_NO, FAX_EXT, ENTITY_TITLE, CONTACT_OPT_OUT, MAIL_COUNTRY, LAST_USER
         } = contact;

        return new Contact(Number(MV_ID),
                           CONTACT_ID,
                           FIRST_NAME,
                           LAST_NAME,
                           ENTITY_ACTUAL_TITLE_DESC ?? TITLE_DESC,
                           ENTITY_STATUS,
                           ENTITY_QC_DATE ?? CONTACT_QC_DATE,
                           ENTITY_ON_SITE,
                           ENTITY_SALE_FLAG,
                           COMPANY_ID,
                           COMPANY_NAME,
                           COMPANY_STATUS,
                           ENTITY_RELATION ?? ENTITY_TYPE,
                           EMAIL_ADDRESS,
                           ENTITY_PHONE,
                           ENTITY_ALT_PHONE_MOBILE,
                           ENTITY_NAME,
                           COUNTRY_NAME ?? NATIONALITY_NAME,
                           ENTITY_ID,
                           ENTITY_CITY ?? CITY,
                           ENTITY_STATE ?? STATE,
                           MAIL_POSTAL_CODE ?? POSTAL_CODE,
                           EMAIL_STATUS,
                           EMAIL_OVERRIDE_STATUS,
                           OVERRIDE_SET_DATE,
                           OVERRIDE_PERSON,
                           TITLE_ID ?? ENTITY_TITLE,
                           ENTITY_TITLE_DESC ?? TITLE_DESC,
                           CONTACT_FUNCTION,
                           ENTITY_ADDRESS_V1 ?? ADDRESS_V1,
                           ENTITY_ADDRESS_V2 ?? ADDRESS_V2,
                           ENTITY_RECORD_STATUS,
                           RECORD_STATUS,
                           COUNTRY ?? MAIL_COUNTRY,
                           STATE_NAME,
                           LINKEDIN_ID,
                           ENTITY_PHONE_CC != null ? Number(ENTITY_PHONE_CC) : null,
                           ENTITY_PHONE_NO,
                           ENTITY_PHONE_EXT,
                           ENTITY_PHONE_MOBILE,
                           ENTITY_ALT_PHONE_CC != null ? Number(ENTITY_ALT_PHONE_CC) : null,
                           ENTITY_ALT_PHONE_NO,
                           ENTITY_ALT_PHONE_EXT,
                           ENTITY_ALT_PHONE_MOBILE,
                           FAX_CC != null ? Number(FAX_CC) : null,
                           FAX_NO,
                           FAX_EXT,
                           Number(MAIL_COUNTRY_C_ID),
                           Number(MAIL_STATE_S_ID),
                           Number(MAIL_CITY_CI_ID),
                           CONTACT_STATUS,
                           Number(CONTACT_OPT_OUT) === 1,
                           LAST_USER,
                           LAST_DATE
                        );
    }

}

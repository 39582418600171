<div class="container-modal">
    <p-messages></p-messages>
    <div class="Ex-content-modal">
        <!-- Buttons -->
        <div class="mt-1">
            <button (click)="clear()" class="btn-custom">{{'clear'| translate}}</button>
        </div>
            <form class="mt-2">
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-6 lg:mt-2 xl:mt-2">
                        <!-- Company name -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.companyName'| translate}}:</label>
                            <input
                                class="col-6 mx-1 form__input-field-style w-6"
                                type="text"
                                #companyname
                                appFocusElement
                                name="companyname"
                                (keydown.enter)="search()"
                                [(ngModel)]="companySearch.company_name"
                                pInputText
                                maxlength="80"
                                autofocus
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Previous name -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'previous_name'| translate}}:</label>
                            <input
                                class="col-6 mx-1 form__input-field-style w-6"
                                type="text"
                                #companyprevname
                                name="previousname"
                                (keydown.enter)="search()"
                                [(ngModel)]="companySearch.previus_name"
                                pInputText
                                maxlength="80"
                                autofocus
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Industry code -->
                        <div class="flex -mt-3 pt-2">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'industry_code'| translate}}:</label>
                            <p-dropdown #industryCodeDropdown
                                    class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                    [options]="industry"
                                    [placeholder]="'common.selectOne' | translate" 
                                    [filter]="true"
                                    showClear="true"
                                    filterBy="industryCode,industryDescription"
                                    resetFilterOnHide="true"
                                    autofocusFilter="true"
                                    (keyup.enter)="search($event)"
                                    name="industry"
                                    [(ngModel)]="companySearch.industry_code"
                                    optionLabel="industryDescription"
                                    optionValue="industryCode">
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Company Status -->
                        <div class="flex -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'company_status'| translate}}:</label>
                            <p-multiSelect #companyStatus
                                class="container-fields-layout-m-inputs col-6 flex px-1 w-4"
                                [options]="status"
                                [placeholder]="'common.selectOne' | translate" 
                                [filter]="true"
                                filterBy="StatusID,StatusDescription"
                                autofocusFilter="true"
                                showClear="true"
                                placeholder="Select One or More"
                                [maxSelectedLabels]="2"
                                selectedItemsLabel="({0} items selected)"
                                (keyup.enter)="search()"
                                [(ngModel)]="companySearch.company_status"
                                name="StatusDescription"
                                optionLabel="StatusDescription"
                                optionValue="StatusID"
                            >
                            </p-multiSelect>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Classification -->
                        <div class="flex -mt-3 pt-1">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'classification'| translate}}:</label>
                            <p-dropdown #companyClassesDropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="companyClasses"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                filterBy="ClassificationID,ClassificationDescription"
                                resetFilterOnHide="true"
                                autofocusFilter="true"
                                (keyup.enter)="search($event)"
                                name="companyclassification"
                                [(ngModel)]="companySearch.company_classification"
                                optionLabel="ClassificationDescription"
                                optionValue="ClassificationID"
                                [disabled]="disabledClassification"
                            >
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>        
                    </div>
                    
                    <div class="col-12 lg:col-6 xl:col-6 -mt-3 sm:-mt-3 lg:mt-2 xl:mt-2">
                        <!-- Phys country -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'phys_country'| translate}}:</label>
                            <p-dropdown #physCountriesDropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="physCountries"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                filterBy="countryId,countryNameConcat"
                                resetFilterOnHide="false"
                                autofocusFilter="true"
                                (keyup.enter)="search($event)"
                                (onChange)="onChangePhysCountry()"
                                name="physcountry"
                                [(ngModel)]="companySearch.phys_country"
                                optionLabel="countryNameConcat"
                                optionValue="countryId"
                            >
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Phys state -->
                        <div class="flex -mt-3 pt-1 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'phys_state'| translate}}:</label>
                            <p-dropdown #physStatesDropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="physStates"
                                [disabled]="!companySearch.phys_country ?? true"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                filterBy="sId,stateConcat"
                                resetFilterOnHide="true"
                                autofocusFilter="false"
                                (keyup.enter)="search($event)"
                                name="physstate"
                                [(ngModel)]="companySearch.phys_state"
                                optionLabel="stateConcat"
                                optionValue="sId"
                            >
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Phys city -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'phys_city'| translate}}:</label>
                            <input
                                class="col-6 mx-1 form__input-field-style w-6"
                                type="text"
                                (keyup.enter)="search()"
                                name="physcity"
                                [(ngModel)]="companySearch.phys_city"
                                pInputText
                                maxlength="50"
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Phys country -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'phys_county'| translate}}:</label>
                            <input
                                class="col-6 mx-1 form__input-field-style w-6"
                                type="text"
                                (keyup.enter)="search()"
                                name="physcounty"
                                [(ngModel)]="companySearch.phys_county"
                                pInputText
                                maxlength="40"
                            />
                            <div class="col-3 px-0"></div>
                        </div>
                        <!-- Record status -->
                        <div class="flex -mt-3 pt-2 align-items-center">
                            <label class="col-3 flex justify-content-end px-0 font-bold text-xs mt-1">{{'common.recordStatus'| translate}}:</label>
                            <p-dropdown #recordStatusDropdown
                                class="form__container-fields-layout--selects col-6 flex px-1 w-4"
                                [options]="recordStatuses"
                                [filter]="true"
                                [placeholder]="'common.selectOne' | translate" 
                                showClear="true"
                                filterBy="RecordStatusID,RecordStatusDescription"
                                resetFilterOnHide="true"
                                autofocusFilter="true"
                                (keyup.enter)="search($event)"
                                name="recordStatus"
                                [(ngModel)]="companySearch.record_status"
                                optionLabel="RecordStatusDescription"
                                optionValue="RecordStatusID"
                            >
                            </p-dropdown>
                            <div class="col-3 px-0"></div>
                        </div>
    
                    </div>
                </div>
            </form>
    </div>
    <hr class="hr-modal">
    <!-- Mouse & keyboard user info -->
        <div class="grid main-content pt-1 md:pt-0 lg:pt-0 xl:pt-0">
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.mouseUsers'| translate}}:</strong> {{'common.searchMouseUsers'| translate}}</span>
            </div>
            <div class="sm:col-12 md:col-12 lg:col-6 xl:col-6 mt-1 text-xs">
                <img src="assets/icons/icon_info.png" width="15px" height="15px" alt="info">
                <span><strong>{{'common.keyboardUsers' | translate}}:</strong> {{'common.searchKeyboardUsers'| translate}}</span>
            </div>
        </div>
    <!-- Data table -->
    <div class="container-table-modal">
        <div class="company-table">
            <p-table #searchTable ignoreHeaders
                [value]="companies"
                selectionMode="single" 
                [rows]="rowsPerPage"
                (onPage)="onPageChange($event)" 
                [showCurrentPageReport]="true"
                [columns]="cols" 
                [resizableColumns]="true"
                styleClass="p-datatable-gridlines"
                [reorderableColumns]="true"
                (sortFunction)="customSort($event)"
                [customSort]="true"
                [rowsPerPageOptions]="rowsPerPageOptions"
                [paginator]="true" 
                [(selection)]="this.additionalInfo"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                >
                <ng-template pTemplate="header" let-columns tabindex="-1">
                    <tr>
                        <th *ngFor="let col of columns" 
                            [style.width]="col.width"
                            pReorderableColumn
                            pResizableColumn
                            [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" [style]="col.style">
                    </colgroup>
                </ng-template>
                <ng-template 
                    pTemplate="body" 
                    let-columns="columns" 
                    let-index="rowIndex" 
                    let-rowData
                >
                    <tr #tr 
                        [pSelectableRow]="rowData" 
                        [pReorderableRow]="index"
                        (keydown)="changeSelectionOnArrowKey($event, companies)"
                        (click)="onRowSelect(rowData)"
                        (keydown.enter)="onRowDblclick(rowData)" 
                        (dblclick)="onRowDblclick(rowData)"
                    >
                        <td *ngFor="let col of columns" 
                            class="{{col.field}} pl-2" pTooltip="{{getRowTooltip(rowData)}}" [style.white-space]="col.wSpace || 'normal'">
                            <div *ngIf="!col.editable" [ngStyle]="getRowStyle(rowData)">
                                {{rowData[col.field]}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length">
                            <span class="flex justify-content-center w-full">{{'common.noDataTable'| translate}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <!-- Design table -->
    <div class="container-info-modal main-content">
            <div class="grid card-modal mt-2 ml-0">
                <div class="col-12 header-info">
                    <span>{{'additional_info'| translate}}:</span>
                </div>
                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'mailing address' | translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.mailAddressV1 }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'mail_country'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.mailCountryName }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'previous_name'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.previousName }}</label>
                    </div>
                    <div class="content-card">
                      <label class="custom-label-card-right-short">{{'telephone'| translate}}:</label>
                      <label for="" class="custom-label-card-info">{{additionalInfo?.phone | slice:0:3}} {{additionalInfo?.phone | slice:3:additionalInfo?.phone.length}}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'common.qcDate'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.qcDate | date: 'd-MMM-y'}}</label>
                    </div>
                </div>

                <div class="col-12 lg:col-6 xl:col-6">
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'mail_city_state'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.mailCity }} {{additionalInfo?.mailCity && additionalInfo?.mailStateDesc ? ',' : ''}} {{ additionalInfo?.mailStateDesc }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'web_site'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.webSite }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'stock_symbol'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{ additionalInfo?.stockSymbol }}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short">{{'fax_number'| translate}}:</label>
                        <label for="" class="custom-label-card-info">{{additionalInfo?.fax | slice:0:3}} {{additionalInfo?.fax | slice:3:additionalInfo?.fax?.length}}</label>
                    </div>
                    <div class="content-card">
                        <label class="custom-label-card-right-short"></label>
                        <label for="" class="custom-label-card-info"></label>
                    </div>
                </div>
            </div>
        <div *ngIf="isMainAccess" class="grid pt-3">
            <div class="col-12 lg:col-11 xl:col-11 flex justify-content-end">
                <label class="custom-label mt-2 lg:mr-2 xl:-mr-1">{{'company.searchModal.infoSearchCompany'| translate}} 
                <strong>{{'company.searchModal.notADuplicate'| translate}}</strong>
                </label>
            </div>
            <div class="col-12 lg:col-1 xl:col-1 flex justify-content-end">
                <button [disabled]="!enabledNewRecordButton" [ngStyle]="{'color':!enabledNewRecordButton ? 'grey' : 'black'}" 
                      class="col-4 btn-custom h-2rem" label="Create New Record" 
                      (click)="newRecord()">{{'company.searchModal.createNewRecord'| translate}}</button>
            </div>
        </div>
    </div>

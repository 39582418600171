import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
// Third Party
import { Cacheable, LocalStorageStrategy } from "ts-cacheable";
// Rxjs
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Service
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
// Environment
import { environment } from 'src/environments/environment';
// Model
import { TranslateService } from "@ngx-translate/core";
import { LoadWheelService } from "src/app/components/load-wheel/load-wheel.service";
import { MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { CompanyStatusValue, ContactStatusValue, RecordStatusValue } from 'src/app/core/models/constants';
import { TelemetryService } from '../../core/services/telemetry.service';
import { Contact } from "../models";
import { CompaniesContactInfoDisplay } from "../models/company/display/companies-contact-info-display";
import { Constants } from "../models/constants";
import { ContactsWithException } from "../models/contact";
import { ContactsCommentsDisplay } from "../models/contact/display/contacts-comments-display";
import { ContactsDisplay } from "../models/contact/display/contacts-display";
import { EntityName } from "../models/enumerations/entity-name";
import { SpinnerProcess } from "../models/spinner-process";
import { LockSameUserMessageService, LockSameUserResult } from "./lock-same-user-message.service";


@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(
        private messageHandlerService: MessageHandlerService,
        private http: HttpClient,
        private loadWheelService: LoadWheelService,
        private translate: TranslateService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) {}

    getContactDetailsById(contactId: string, lockId: string, onlyForRead: boolean = false): Promise<ContactsDisplay> {
        let mainLockID: number = 0;

        if(lockId !== null) {
            mainLockID = Number(lockId);
        }

        return new Promise<ContactsDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(null, EntityName.CONTACT, contactId, mainLockID).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(contactId, mainLockID, onlyForRead).subscribe((entityDisplay: ContactsDisplay) => {
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    public getEntityDetailsById(entityId: string, mainLockID: number, onlyForRead: boolean): Observable<ContactsDisplay> {

        return this.http.get<ContactsDisplay>(`${environment.apiUrl}contact/${entityId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    if(data.response !== null) {
                        const contactDisplay = ContactsDisplay.CreateInstance(data.response);
                        contactDisplay.contactsCommentsDisplay = ContactsCommentsDisplay.BuildFromList(contactDisplay.contactsCommentsDisplay);
                        return contactDisplay;
                    } else {
                        const entityNotExistsMsg = Constants.replace(Constants.MESSAGE_ENTITY_NOT_EXISTS, [EntityName.CONTACT]);
                        this.messageHandlerService.show(MessageHandlerService.errorMessage(data, entityNotExistsMsg), MessageHandlerService.errorType(data));
                    }
                })
            );
    }

    searchAllContact({ CompanyName, FirstName, LastName, ContactID, Email}, mode: string, applyCoverage: boolean, onSite: boolean, srcElement: any): Observable<Contact[]> {
        const searchStart: number = performance.now();

        if(CompanyName === '' && FirstName === '' && LastName === '' && ContactID === '' && Email === ''){
            this.messageHandlerService.show(this.translate.instant('contact_required_params_search'), MessageType.INFO,
                        () => {if(srcElement && srcElement.focus){srcElement.focus();}});
            return new Observable;
        }
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.contacts"));
        const params = new HttpParams()
                    .append('firstName', FirstName)
                    .append('lastName', LastName)
                    .append('email', Email)
                    .append('companyName', CompanyName)
                    .append('contactId', ContactID)
                    .append('mode', mode)
                    .append('applyCoverage', applyCoverage)
                    .append('onSite', onSite);

        return this.http.get<Contact>(`${environment.apiUrl}contact/searchAll`, { params })
            .pipe(
                map((data: any) => {
                    if(data.response !== null && data.response.length > 0) {
                        let contacts = [];
                        data.response.forEach(element => {
                            if(element){
                                contacts.push(...element);
                            }

                        });
                        this.loadWheelService.hideWheel(wheel);
                        
                        this.telemetry.entitySearchTime("contact", performance.now() - searchStart);

                        return Contact.BuilContact(contacts);
                    }
                }),
                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return throwError(() => error);
                })
            )
    }

    contactNextId(): Observable<ContactsDisplay> {
      return this.http.get<any>(`${environment.apiUrl}contact/nextId`)
          .pipe(
              map((data:any) =>  ContactsDisplay.BuildNewContact(data.response) )
          );
  }


    getContactByEmail(email: string): Observable<any[]> {
        return this.http.get<any>(`${environment.apiUrl}contact/byEmail?email=${email}`)
            .pipe(
                map((data: any) => {
                    let contacts = [];
                    if(data.response !== null && data.response.length > 0) {
                        contacts = data.response;
                    }
                    return contacts;
                })
            );
    }

    getContactsByEmails(emails: Array<string>): Observable<any[]> {
        const params = new HttpParams()
            .append('emails', emails.join(', '));
        return this.http.get<any>(`${environment.apiUrl}contact/contactsByEmails`, { params })
            .pipe(
                map((data: any) => {
                    let contacts = [];
                    if(data.response !== null && data.response.length > 0) {
                        contacts = data.response;
                    }
                    return contacts;
                })
            );
    }

    getContactById(contactId: number): Observable<ContactsWithException> {
        return this.http.get<ContactsWithException>(`${environment.apiUrl}contact/plantAndCompContact/${contactId}`)
        .pipe(
            map((data: any) => {
                let contacts: Contact[] = null;
                const exception: string = data.exception;
                if(data.response !== null && data.response.length > 0) {
                    contacts = Contact.BuilContact(data.response);
                }
                return new ContactsWithException(contacts, exception);
            })
        );
    }

    getCommentsByContactId(contactId: number): Observable<ContactsCommentsDisplay[]> {
        return this.http.get<ContactsCommentsDisplay[]>(`${environment.apiUrl}contact/comments/${contactId}`)
            .pipe(
                map((data: any) => {
                    let comments: ContactsCommentsDisplay[] = [];
                    if(data.response !== null && data.response.length > 0) {
                        comments = ContactsCommentsDisplay.BuildFromList(data.response);
                    }
                    return comments;
                })
            );
    }

    setContactEntityInfo(entityType: string, entityId: string, contactId: string, mvId: string, companiesContactInfoDisplay: CompaniesContactInfoDisplay): Observable<void> {
        const params = new HttpParams()
                    .append('entityType', entityType)
                    .append('entityId', entityId)
                    .append('contactId', contactId)
                    .append('mvId', mvId);

        return this.http.get<any>(`${environment.apiUrl}contact/info`, { params })
            .pipe(
                map((data: any) => {
                    if(data.response !== null && data.response.length > 0) {
                        const contactEntityInfo = data.response[0];

                        // SET Contact Display Information
                        const contactDisplay = companiesContactInfoDisplay.contactDisplay;
                        contactDisplay.middleName = contactEntityInfo.MIDDLE_NAME;
                        contactDisplay.emailAddress = contactEntityInfo.EMAIL_ADDRESS;
                        contactDisplay.marketingEmailStatus = contactEntityInfo.EMAIL_STATUS;
                        contactDisplay.marketingEmailOverrideStatus = contactEntityInfo.EMAIL_OVERRIDE_STATUS;
                        contactDisplay.marketingOverrideDate = contactEntityInfo.OVERRIDE_SET_DATE;
                        contactDisplay.marketingOverridePerson = contactEntityInfo.OVERRIDE_PERSON;
                        contactDisplay.jrSrIII = contactEntityInfo.JR_SR_III;
                        contactDisplay.matronName = contactEntityInfo.MATRON_NAME;
                        contactDisplay.nationality = contactEntityInfo.NATIONALITY;
                        contactDisplay.nationalityName = contactEntityInfo.NATIONALITY_NAME;
                        contactDisplay.nickName = contactEntityInfo.NICKNAME;
                        contactDisplay.salutation = contactEntityInfo.SALUTATION;
                        contactDisplay.linkedInId = contactEntityInfo.LINKEDIN_ID;
                        contactDisplay.entryCompany = contactEntityInfo.ENTRY_COMPANY;
                        contactDisplay.entryDate = contactEntityInfo.ENTRY_DATE;
                        contactDisplay.entryUser = contactEntityInfo.ENTRY_USER;
                        contactDisplay.lastCompany = contactEntityInfo.LAST_COMPANY;
                        contactDisplay.lastDate = contactEntityInfo.LAST_DATE;
                        contactDisplay.lastUser = contactEntityInfo.LAST_USER;
                        contactDisplay.prevCompany = contactEntityInfo.PREV_COMPANY;
                        contactDisplay.prevUser = contactEntityInfo.PREV_USER;
                        contactDisplay.prevDate = contactEntityInfo.PREV_DATE;
                        contactDisplay.releaseDate = contactEntityInfo.RELEASE_DATE;

                        // SET Company Contact Information
                        companiesContactInfoDisplay.contactFunction = contactEntityInfo.CONTACT_FUNCTION;
                        companiesContactInfoDisplay.contactLastDate = contactEntityInfo.CONTACT_LAST_DATE;
                        companiesContactInfoDisplay.contactLastUser = contactEntityInfo.CONTACT_LAST_USER;
                        companiesContactInfoDisplay.contactSaleFlag = contactEntityInfo.CONTACT_SALE_FLAG;
                        companiesContactInfoDisplay.mailAddressVerified = contactEntityInfo.MAIL_ADDRESS_VERIFIED;
                    }
                })
            );
    }

    getContactEntityInfo(entityType: string, entityId: string, contactId: string, mvId: string): Observable<any> {
        const params = new HttpParams()
                    .append('entityType', entityType)
                    .append('entityId', entityId)
                    .append('contactId', contactId)
                    .append('mvId', mvId);
        return this.http.get<any>(`${environment.apiUrl}contact/info`, { params })
            .pipe(
                map((data: any) => {
                    return (data.response !== null && data.response.length > 0) ? data.response[0] : null;
                })
            );
    }

    contactById(contactId: number): Observable<Contact> {
        return this.http.get<Contact>(`${environment.apiUrl}contact/${contactId}`)
            .pipe(
                map((data: any) => {
                    let contact: Contact = null;
                    if(data.response !== null && data.response.length > 0) {
                        contact = Contact.CreateInstance(data.response[0]);
                    }
                    return contact;
                })
            );
    }

    getDisabledRow(row: Contact, entityName: string) {
        const contactStatus = row?.ContactStatus?.toUpperCase() ?? row?.Status?.toUpperCase();
        const companyStatus = row?.CompanyStatus?.toUpperCase();
        const recordStatus = row?.RecordStatus?.toUpperCase();
        const entityRecordStatus = row?.EntityRecordStatus?.toUpperCase();
        const contactAddress1 = row?.EntityAddressV1;
        const contactCity = row?.EntityCity;
        const contactState = row?.EntityState;
        const contactZip = row?.MailPostalCode;
        return contactStatus == ContactStatusValue.Dead
            || contactStatus == ContactStatusValue.Trash
            || (entityName == EntityName.PROJECT
                && (companyStatus == CompanyStatusValue.Closed
                    || companyStatus == CompanyStatusValue.SuspendedUnresolved
                    || recordStatus == RecordStatusValue.Incomplete
                    || recordStatus == RecordStatusValue.Trash
                    || entityRecordStatus == RecordStatusValue.Trash
                    || contactStatus == ContactStatusValue.Moved
                    || contactStatus == ContactStatusValue.Retired
                    || contactAddress1 === undefined || contactAddress1 == null
                    || contactCity === undefined || contactCity == null
                    || contactState === undefined || contactState == null
                    || contactZip === undefined || contactZip == null));
    }

}

<div class="pt-1 pr-0 -mb-4 pl-3 z-4 flex fixed lg:hidden xl:hidden bg-gray-900 w-full" (click)="toggleField()" [ngClass]="classToggled ? 'relative' : 'fixed' ">
    <img src="assets/icons/menu.svg" class="mr-2" width="20px" alt="">
    <span class="text-lg text-white">MENU</span>
</div>
<div class="pt-3 lg:pt-0 xl:pt-0
            pl-3 lg:pl-0 xl:pl-0
            pb-3 lg:pb-0 xl:pb-0
            pr-3 lg:pr-1 xl:pt-1 
            flex-column  left-0
            w-full h-full
            bg-cover lg:bg-contain xl:bg-contain" 
        [ngClass]="classToggled ? ' left-0 z-3 lg:z-2 xl:z-2 absolute lg:relative xl:relative  lg:flex xl:flex' : 
            ( isDash ? 'z-2 hidden lg:flex xl:flex' : 
                ('z-2 hidden lg:flex xl:flex relative lg:fixed xl:fixed w-full lg:w-2 xl:w-2'))">
    <app-nav-brand></app-nav-brand>
    <app-nav-user class="mb-2"></app-nav-user>
    <app-nav-group-item (onScrollOption)="onScrollOption()"></app-nav-group-item>
</div>


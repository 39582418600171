import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/core/services/session.service';
import { IAttachment } from 'src/app/core/interfaces/iattachment';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { MVTOperations } from 'src/app/core/mvt-operations';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { MessageEvent, MessageResponse, MessageType } from '../messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../messages/message-handler/message-handler.service';

@Component({
    selector: 'app-internal-attachment',
    templateUrl: './internal-attachment.component.html',
    styleUrls: ['./internal-attachment.component.scss']
})

export class InternalAttachmentComponent implements OnInit {
    @Input() files: Array<IAttachment>;
    @Input() createInstance: (obj: any) => IAttachment;
    @Input() entityId: number;
    @Input() entityName: string;
    @Input() disabledButton: boolean = false;
    @Input() baseIndex: number;
    @Output() attachmentChanges: EventEmitter<Array<IAttachment>> = new EventEmitter();

    classToggled = false;

    TEMPORAL = OperationType.TEMPORAL;
    INSERT = OperationType.INSERT;
    DELETE = OperationType.DELETE;
    IDLE = OperationType.IDLE;

    MAX_FILES = 5;
    MAX_SIZE = 25;
    MAX_FILENAME_LENGTH = 30;

    constructor(
        private attachmentService: AttachmentService,
        private translate: TranslateService,
        private messageHandler: MessageHandlerService,
        private session: SessionService
    ) { }

    ngOnInit(): void {
    }

    toggleField() {
        this.classToggled = !this.classToggled;
    }

    onChange(event: any) {
        this.attachFile(event);
        // Clear the input
        event.target.value = null;
    }

    attachFile(event: any) {
        const currentFile = event.target.files[0];
        if(this.isUploadFileNameLengthExceeded(currentFile)){
            this.messageHandler.show(this.translate.instant('internalAttachment.uploadFileNameLengthExceeded') + this.MAX_FILENAME_LENGTH + this.translate.instant('internalAttachment.uploadFileNameLengthExceededCharacters') , MessageType.ERROR);
        }else if(this.isUploadSizeLimitExceeded(currentFile)){
            this.messageHandler.show(this.translate.instant('internalAttachment.uploadSizeLimitExceeded'), MessageType.ERROR);
        }else if (this.isUploadExceeded(currentFile)) {
            this.messageHandler.show(this.translate.instant('internalAttachment.uploadExceeded'), MessageType.ERROR);
        }else if (this.isFileNameExists(currentFile)) {
            this.messageHandler.show(this.translate.instant('internalAttachment.uploadFileNameExists', {fileName: currentFile.name}), MessageType.ERROR);
        } else {
            this.addAttachment(currentFile);
        }
    }

    addAttachment(currentFile: File) {
        const itemAux: IAttachment = this.createInstance({
            fileName: currentFile.name,
            fileSize: String(currentFile.size),
            url: `/${this.entityName}/${this.entityId}/${currentFile.name}`,
            createDate: String(Date.now()),
            createUserId: this.session.getPersonId(),
            mvOrder: MVTOperations.getLastMvOrder(this.files) + 1,
        });
        itemAux.mainEntityId = this.entityId;
        itemAux.setOperation(OperationType.INSERT);
        itemAux.fileToUpload = currentFile;

        this.files.push(itemAux);
        this.attachmentChanges.emit(this.files);
    }

    isFileNameExists(item: File): boolean {
        return this.files.some(p=>p.fileName === item.name);
    }

    isUploadExceeded(item: File): boolean {
        const calculatedLength = this.files.filter(p=>p.getOperation() == this.IDLE || p.getOperation() == this.INSERT ).length+1;
        const calculatedSize = this.bytesToMegabytes(this.getSummary() + item.size);
        return calculatedLength > this.MAX_FILES || calculatedSize > this.MAX_SIZE;
    }

    isUploadSizeLimitExceeded(item: File): boolean {
        return this.bytesToMegabytes(item.size) > this.MAX_FILES;
    }

    isUploadFileNameLengthExceeded(item: File): boolean {
        return item.name.length > this.MAX_FILENAME_LENGTH ;
    }

    isCancelPendingDisabled() {
        let existsPendingActions = true;
        if (this.files && this.files.length > 0) {
            existsPendingActions = !(this.files.filter(p => p.getOperation() !== this.IDLE).length > 0);
        }
        return existsPendingActions;
    }

    cancelPending() {
        const confirmCancelFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                this.files = this.files.filter(p => p.mvId != null);
                this.attachmentChanges.emit(this.files);
                for (let file of this.files) {
                    file.setOperation(this.IDLE);
                    file.fileToUpload = null;
                }
            }
        }
        this.messageHandler.show(this.translate.instant('internalAttachment.confirmCancel'), MessageType.CONFIRM, confirmCancelFunction);
    }

    delete(item: IAttachment) {
        const confirmSaveFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                if (item.mvId != null) {
                    item.setOperation(this.DELETE);
                } else {
                    this.files = this.files.filter(p => p.mvOrder !== item.mvOrder || p.mvId !== item.mvId);
                    this.attachmentChanges.emit(this.files);
                }
            }
        };
        this.messageHandler.show(this.translate.instant('internalAttachment.confirmDelete'), MessageType.CONFIRM, confirmSaveFunction);
    }

    undo(item: IAttachment) {
        item.setOperation(this.IDLE);
    }

    bytesToMegabytes(bytes: number): number {
        return bytes / 1024 / 1024;
    }

    getMegas(nroBytes: string, decimal: number) {
        const fixed = decimal >= 0 ? decimal : 2;
        const result = this.bytesToMegabytes(Number(nroBytes));

        return (fixed >= 0) ? result.toFixed(fixed) : result;
    }

    getFilesDesc() {
        const nroFiles = this.files ? this.files.length : 0;
        return nroFiles + ((nroFiles != 1) ? ' Files' : ' File');
    }

    getSummary(): number {
        let summary = 0;
        if (!this.files) {
            return 0;
        }

        for (let file of this.files.filter(p=>p.getOperation() == this.IDLE || p.getOperation() == this.INSERT)) {
            summary += Number(file.fileSize);
        }
        return summary;
    }

    getSummaryDesc() {
        return this.getMegas(String(this.getSummary()), 2);
    }

    attachmentProcess(item: IAttachment) {
        this.attachmentService.downloadFile(this.entityName, String(this.entityId), item.fileName);
    }

    isDisabledButton(): boolean {
        return this.disabledButton;
    }
}

export class Manufacturer {
    manufacturerId: string;
    name: string;
    shortName: string;
    aeroCombTurbine: string;
    htHydraulicTurbine: string;
    hfCombTurbine: string;
    emElectMotor: string;
    generator: string;
    pump: string;
    equipTypes: string;
    stSteamTurbine: string;
    internalCombEngine: string;
    windTurbine: string;
    turboExpander: string;
    boiler: string;
    compressor: string;



    private constructor(
        manufacturerId: string,
        name: string,
        shortName: string,
        aeroCombTurbine: string,
        htHydraulicTurbine: string,
        hfCombTurbine: string,
        emElectMotor: string,
        generator: string,
        pump: string,
        equipTypes: string,
        stSteamTurbine: string,
        internalCombEngine: string,
        windTurbine: string,
        turboExpander: string,
        boiler: string,
        compressor: string
    ) {
        this.manufacturerId = manufacturerId;
        this.name = name;
        this.shortName = shortName;
        this.aeroCombTurbine = aeroCombTurbine;
        this.htHydraulicTurbine = htHydraulicTurbine;
        this.hfCombTurbine = hfCombTurbine;
        this.emElectMotor = emElectMotor;
        this.generator = generator;
        this.pump = pump;
        this.equipTypes = equipTypes;
        this.stSteamTurbine = stSteamTurbine;
        this.internalCombEngine = internalCombEngine;
        this.windTurbine = windTurbine;
        this.turboExpander = turboExpander;
        this.boiler = boiler;
        this.compressor = compressor;
    }

    static BuildManufacturer(entity: Array<any>, acManufacturerEquipTypes: ManufacturerEquipmentType[]): Array<Manufacturer> {
        return entity.map(item => Manufacturer.CreateInstance(item, acManufacturerEquipTypes));
    }
    
    public static CreateInstance(record: any, acManufacturerEquipTypes: ManufacturerEquipmentType[]): Manufacturer {
        let equipTypesArray: Array<string> = []
        acManufacturerEquipTypes.forEach((manEquipType: ManufacturerEquipmentType) => {
            if(manEquipType.manufacturerId === record.MANUFACTURER_ID){
                equipTypesArray.push(manEquipType.description);
            }
        });
        return new Manufacturer(
            record.MANUFACTURER_ID,
            record.MANUFACTURER_NAME,
            record.SHORT_NAME,
            record.MFG_AERO,
            record.MFG_HT,
            record.MFG_HEAVY,
            record.MFG_EM,
            record.MFG_GENERATOR,
            record.MFG_PUMP,
            equipTypesArray.join(', '),
            record.MFG_STEAM,
            record.MFG_IC,
            record.MFG_WT,
            record.MFG_TURBO,
            record.MFG_BOILER,
            record.MFG_COMPRESSOR);
    }
}

export class ManufacturerEquipmentType {
    manufacturerId: string;
    equipmentTypeId: string;
    description: string;

    private constructor(
        manufacturerId: string,
        equipmentTypeId: string,
        description: string
    ) {
        this.manufacturerId = manufacturerId;
        this.equipmentTypeId = equipmentTypeId;
        this.description = description;
    }

    static BuildManufacturerEquipmentType(entity: Array<any>): Array<ManufacturerEquipmentType> {
        return entity.map(item => ManufacturerEquipmentType.CreateInstance(item));
    }
    
    public static CreateInstance(record: any): ManufacturerEquipmentType {
        return new ManufacturerEquipmentType(
            record.MANUFACTURER_ID,
            record.EQUIPMENT_TYPES_ID,
            record.DESCRIPTION);
    }
}

export interface ManufacturerSearchParams {
    name: string;
    shortName: string;
    recordedSearch: boolean;
}




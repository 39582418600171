import { DatePipe, formatDate } from "@angular/common";
import { StringUtils } from "./string-utils";

export class DateUtil {
    static validateAfterFixedDate(aDate: number, fixedDate: string): boolean {
        let isValid = false;
        const strDate = String(aDate);

        if(strDate.length === 6) {
            let year = Number(strDate.substring(0, 4));
            let month = Number(strDate.substring(4, 6));

            const enteredDate = new Date(year, month, 1);

            year = Number(fixedDate.substring(0, 4));
            month = Number(fixedDate.substring(4, 6));

            const fixedDateObj = new Date(year, month, 1);
            isValid = enteredDate.getTime() > fixedDateObj.getTime();
        }

        return isValid;
    }

    static convertToDate(strDate: any): Date {
        if (strDate == null) {
            return null;
        }

        if (typeof strDate === 'number') {
            return new Date(strDate);
        }

        if (typeof strDate === 'string') {
            strDate = strDate.replace(/-/g, '/');
            const dateArray = strDate.split('/');
            if (dateArray.length === 3) {
                const month = Number(dateArray[0]) - 1;
                const day = Number(dateArray[1]);
                const year = Number(dateArray[2]);
                return new Date(year, month, day);
            }
        }

        return null;
    }

    static getYearMonthFromDate(date: Date): string{
        return date.getFullYear() +''+('0'+(date.getMonth()+1)).slice(-2);
    }

    static getMonthFromDate(date: Date): string{
        return ('0'+(date.getMonth()+1)).slice(-2);
    }

    static getYYYYMMDDFromDate(date: Date): number{
        return Number(date.getFullYear()+('0'+(date.getMonth()+1)).slice(-2)+('0'+(date.getDate()+1)).slice(-2));
    }

    static getDateFromYearMonth(yearMonth: number | string, day: string = null): Date{
        return new Date(String(yearMonth).substring(0, 4) + '-' + String(yearMonth).substring(4, 6) 
                    + '-' + (day !== null ? day : '01 12:00:00'));
    }

    static getDateFromYearAndMonth(year: number | string, month: number | string): Date{
        return new Date(year + '-' + month + '-01 12:00:00');
    }

    static getFormatNumberToDate(dateValue: number, formatDate: string =  'dd-MMM-y'): string {
        const datePipe = new DatePipe('en-US');
        return StringUtils.toStringNeverNull(datePipe.transform(dateValue, formatDate));
    }
    
    static displayDateWithUserName(date: number, userName: string): string {
        const formatDate: string = 'dd-MMM-y HH:mm:ss';
        let formattedDate: string = DateUtil.getFormatNumberToDate(date, formatDate);
        
        if (!StringUtils.isEmpty(userName)) {
            formattedDate += " by " + userName;
        }
                
        return formattedDate;
    }

    static validateSQLDateFormatAndConvertToDateFormat(dateString: string): string {
        const dateTimeRegex = /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}:\d{2}$/;
        const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

        if (dateTimeRegex.test(dateString)) {
            const date = new Date(dateString);
            return DateUtil.getFormatNumberToDate(date.getTime(), 'dd-MMM-y HH:mm:ss');
        } else if (dateRegex.test(dateString)) {
            const date = new Date(dateString);
            return DateUtil.getFormatNumberToDate(date.getTime(), 'dd-MMM-y');
        } else {
            return dateString;
        }
    }

    static calculateDaysBetweenDates(startDateStr: string, endDateStr: string): number {

        if (!startDateStr || !endDateStr) {
            return null;
        }
        
        const startDate: Date = new Date(startDateStr);
        const endDate: Date = new Date(endDateStr);
    
        if (!startDate || !endDate) {
            throw new Error("Invalid dates provided");
        }
        DateUtil.setHourMinSecMillis(endDate, 0, 0, 0, 0);
        DateUtil.setHourMinSecMillis(endDate, 24, 0, 0, 0);
        const oneDay = 1000 * 60 * 60 * 24;
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = Math.round(differenceInTime / oneDay);
    
        return differenceInDays;
    }

    static setHourMinSecMillis(date: Date, hour: number, minute: number, second: number, millis: number): Date {
        date.setHours(hour);
        date.setMinutes(minute);
        date.setSeconds(second);
        date.setMilliseconds(millis);
        return date;
    }

    static addDaysToDate(date: Date, days: number): Date {
        const result = new Date(date); 
        result.setDate(result.getDate() + days); 
        return result; 
    } 
    
    static areDatesEqual(dateStr1: string | number | Date, dateStr2: string | number | Date): boolean {
        if(!dateStr1 && !dateStr2) {
            return true;
        } else if(!dateStr2 || !dateStr1) {
            return false;
        }
        return DateUtil.setHourMinSecMillis(new Date(dateStr1), 0, 0, 0, 0).getTime() === DateUtil.setHourMinSecMillis(new Date(dateStr2), 0, 0, 0, 0).getTime() ;
    }  

    static convertDateToString(date: Date, format: string = 'yyyy-MM-dd'): string {
        return formatDate(date, format, 'en-US');
    }
    
}

export class SystemNotification {
    message: string; 
    date: number;
    server: boolean;

    constructor(
        message: string,
        date?: number,
        server?: boolean
    ) {
        this.message = message;
        this.date = date ?? Date.now();
        this.server = server ?? false;
    }

    // This is only intended to be used for the ones loaded from the server
    static BuildSystemNotification(entity: Array<any>): Array<SystemNotification> {
        return entity.map(item => SystemNotification.CreateInstance(item));
    }

    // This is only intended to be used for the ones loaded from the server
    private static CreateInstance(notification: any): SystemNotification {
        return new SystemNotification(notification.NOTIFICATION_TEXT, null, true);
    }
}

export class Firm {
    firmId: string;
    firmName: string;
    shortName: string;


    private constructor(
        firmId: string,
        firmName: string,
        shortName: string
    ) {
        this.firmId = firmId;
        this.firmName = firmName;
        this.shortName = shortName;
    }

    static BuildFirm(entity: Array<any>): Array<Firm> {
        return entity.map(item => Firm.CreateInstance(item));
    }

    public static CreateInstance(record: any): Firm {
        return new Firm(
            record.AEC_FIRM_ID,
            record.AEC_FIRM_NAME,
            record.SHORT_NAME
            );
    }
}

export interface FirmSearchParams {
    name: string;
    shortName: string;
    recordedSearch: boolean;
}




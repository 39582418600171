import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {

    constructor(@Inject('PENDING_REQUESTS_SUBJECT') private pendingRequestsSubject: BehaviorSubject<number>) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.addPendingRequest();

        return next.handle(request).pipe(
            finalize(() => {
                this.removePendingRequest();
            })
        );
    }

    get pendingRequests(): boolean {
        return this.pendingRequestsSubject.value > 0;
    }

    addPendingRequest(): void {
        this.pendingRequestsSubject.next(this.pendingRequestsSubject.value + 1);
    }

    removePendingRequest(): void {
        this.pendingRequestsSubject.next(this.pendingRequestsSubject.value - 1);
    }
}

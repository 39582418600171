import { PlantsLTSASubTypes } from "../save/plants-ltsa-sub-types";

export class PlantsLTSASubTypesDisplay extends PlantsLTSASubTypes {
    serviceAgreementSubtypeName: string;

    constructor(entity: any) {
        super(entity);
        this.serviceAgreementSubtypeName = entity?.serviceAgreementSubtypeName ?? null;
    }

    static CreateInstance(entity: any): PlantsLTSASubTypesDisplay {
        return new PlantsLTSASubTypesDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<PlantsLTSASubTypesDisplay> {
        return entity.map(item => PlantsLTSASubTypesDisplay.CreateInstance(item));
    }


}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ordinalDate'
})

export class OrdinalDatePipe implements PipeTransform {

    transform(value: Date): string {
        if (!value) {
            return '';
        }

        const weekday = value.toLocaleString('en-us', { weekday:'long' });
        const month = value.toLocaleString('default', { month: 'long' });
        const day = value.getDate();
        const dayString = day + this.nth(day);
        const year = value.getFullYear();
        return `${weekday}, ${month} ${dayString}, ${year}`;
    }

    nth(d: number): string {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }
}

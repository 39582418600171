import { ColumnEntityInfo } from "src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component";
import { StringUtils } from "../../core/utils/string-utils";

export abstract class EditableTable {

    protected isEditing: boolean = false;
    protected cellEditingPreviousValue: string = null;
    protected isPendingValidation: boolean = false;

    constructor() {
    }

    public onEditInit(columns: ColumnEntityInfo[], event: any): void {
        const colEntityId:ColumnEntityInfo = columns.find(c => c.isEntityId);
        const entityIdPropName:string = (colEntityId != null) ? colEntityId.entityPropName : null;
        const col:ColumnEntityInfo = columns.find(c => c.entityPropName === event.field);
        if(col !== null && !this.isPendingValidation && col.isEditable(event.data, event.index) && !col.hasDirectEdition(event.data, event.index)) {
            this.isEditing = true;
            this.cellEditingPreviousValue = col.getCellValue(event.data);
        }
    }

    public onEditComplete(columns: ColumnEntityInfo[], event): boolean {
        if(this.isEditing === true) {
            this.isEditing = false;
            const col:ColumnEntityInfo = columns.find(c => c.entityPropName === event.field);
            if(this.verifyCellWasEdited(col, event)) {
                this.cellWasEdited(col, event);
                return true;
            }
        }
        return false;
    }

    public verifyCellWasEdited(col: ColumnEntityInfo, event: any): boolean {
        let cellWasEdited:boolean = false;
        const cellValue = StringUtils.toStringNeverNull(col.getCellValue(event.data)).trim();
        if (cellValue !== StringUtils.toStringNeverNull(this.cellEditingPreviousValue)) {
            cellWasEdited = true;
        }
        return cellWasEdited;
    }

    public abstract cellWasEdited(col: ColumnEntityInfo, event: any):void;

}
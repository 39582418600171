{
  "name": "ned",
  "version": "2.26.12",
  "scripts": {
    "ng": "ng",
    "start": "node start.js",
    "serve": "ng serve",
    "servetest": "ng serve --configuration test",
    "serveprod": "ng serve --configuration production",
    "build": "ng build",
    "buildtest": "ng build --configuration test --base-href=/NED/ui/",
    "buildprod": "ng build --configuration production --base-href=/NED/ui/",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "~17.3.9",
    "@angular/cdk": "~17.3.9",
    "@angular/common": "~17.3.9",
    "@angular/compiler": "~17.3.9",
    "@angular/core": "~17.3.9",
    "@angular/forms": "~17.3.9",
    "@angular/platform-browser": "~17.3.9",
    "@angular/platform-browser-dynamic": "~17.3.9",
    "@angular/router": "~17.3.9",
    "@angular/service-worker": "~17.3.9",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angular-gridster2": "^17.0.0",
    "ngx-mask": "17.0.4",
    "primeflex": "^3.3.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.18.10",
    "rxjs": "~7.8.1",
    "semver": "^7.6.0",
    "ts-cacheable": "^1.0.10",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "~17.3.7",
    "@angular-eslint/builder": "17.4.0",
    "@angular-eslint/eslint-plugin": "17.4.0",
    "@angular-eslint/eslint-plugin-template": "17.4.0",
    "@angular-eslint/schematics": "17.4.0",
    "@angular-eslint/template-parser": "17.4.0",
    "@angular/cli": "~17.3.7",
    "@angular/compiler-cli": "~17.3.9",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "~20.11.19",
    "@types/semver": "^7.5.7",
    "@typescript-eslint/eslint-plugin": "7.4.0",
    "@typescript-eslint/parser": "7.4.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.3.3"
  }
}

import { Bean } from "../../bean";

export class AreasFaxInfo extends Bean {
    areaId: string;
    faxCc: string;
    faxNo: string;
    faxExt: string;
    lastDate: string;
    mvOrder: number;
    mvId: string;
    

    protected constructor(entity?: any) {
        super(entity);

        this.areaId = entity?.areaId ?? null;
        this.faxCc = entity?.faxCc ?? null;
        this.faxNo = entity?.faxNo ?? null;
        this.faxExt = entity?.faxExt ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.mvId = entity?.mvId ?? null;
    }

}

import { CompaniesGreenhouseGasEmissions } from "../save/companies-greenhouse-gas-emissions";

export class CompaniesGreenhouseGasEmissionsDisplay extends CompaniesGreenhouseGasEmissions {

    constructor(entity?: any) {
        super(entity);
        this.initialData = entity;        
    }

    static CreateInstance(entity: any): CompaniesGreenhouseGasEmissionsDisplay {
        return new CompaniesGreenhouseGasEmissionsDisplay(entity);
    }

    static BuildFromList(entity: Array<any>): Array<CompaniesGreenhouseGasEmissionsDisplay> {
        return entity.map(item => CompaniesGreenhouseGasEmissionsDisplay.CreateInstance(item));
    }
}
import { Component } from '@angular/core';
import { VersionService } from 'src/app/core/services/version.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    year: number = new Date().getFullYear();
    serverVersion: string;
    clientVersion: string = VersionService.getClientVersion();
    envName: string = environment.environmentName;

    constructor(private versionService: VersionService) {
       this.serverVersion = this.versionService.getServerVersion();
     }
}

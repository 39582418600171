import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Rxjs
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// Environment
import { environment } from 'src/environments/environment';
// Model
import { MessageType } from '../components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../components/messages/message-handler/message-handler.service';
import { SaveType } from '../core/models/enumerations/save-type';
import { Plants, PlantSearch, PlantSearchAdapter } from '../core/models/index';
import { UserDetail } from '../core/models/user-detail';
// Interface
import { TranslateService } from '@ngx-translate/core';
import { LoadWheelService } from '../components/load-wheel/load-wheel.service';
import { IAreaSearchParams } from '../core/interfaces/search/iarea-search-params';
import { AreasDisplay } from '../core/models/area/display/areas-display';
import { AreaOwners, Boilers, CompanyOperators, Drives, Equipment, Units, UnitsLTSA } from '../core/models/common';
import { EntityName } from '../core/models/enumerations/entity-name';
import { AreaSearch, AreaSearchAdapter } from '../core/models/search/area-search';
import { SpinnerProcess } from '../core/models/spinner-process';
import { RecordLockingFlow } from '../core/record-locking/record-locking-flow';
import { EntityCommonService } from '../core/services/entity-common.service';
import { LockSameUserMessageService, LockSameUserResult } from '../core/services/lock-same-user-message.service';
import { TelemetryService } from '../core/services/telemetry.service';
import { EntityUtilsService } from '../core/utils/entity-utils.service';

@Injectable({
    providedIn: 'root'
})
export class AreaSectionService {

    searchParams: IAreaSearchParams = {
        areaName: '',
        physCity: '',
        physState: null,
        physCountry: null,
        plantId: '',
        offshore: '0',
        fieldName: '',
        waterBody: null,
        recordStatus: null,
        recordedSearch: false
    }

    constructor(
        private areaAdapter: AreaSearchAdapter,
        private plantAdapter: PlantSearchAdapter,
        private recordLockingFlow: RecordLockingFlow,
        private http: HttpClient,
        private messageHandlerService: MessageHandlerService,
        private loadWheelService: LoadWheelService,
        private entityCommonService: EntityCommonService,
        private translate: TranslateService,
        private areaSearchAdapter: AreaSearchAdapter,
        private entityUtilsService: EntityUtilsService,
        private lockSameUserMessageService: LockSameUserMessageService,
        private telemetry: TelemetryService,
    ) { }

    getAreaDetailsById(areaId: string, isReload: boolean = false, quickAccess: boolean = false): Promise<AreasDisplay> {
        let mainLockID: number = 0;

        if (isReload) {
            mainLockID = this.recordLockingFlow.getLockID(EntityName.AREA, areaId);
        }

        return new Promise<AreasDisplay>((resolve) => {
            this.lockSameUserMessageService.validateEntityIsLockedByUser(this.recordLockingFlow, EntityName.AREA, areaId, mainLockID, quickAccess).then((lockSameUserResult: LockSameUserResult) => {
                if(lockSameUserResult.openEntity) {
                    this.getEntityDetailsById(areaId, mainLockID, false).subscribe((entityDisplay: AreasDisplay) => {
                        this.lockSameUserMessageService.clearContactsLockedsBySameUser(lockSameUserResult, entityDisplay?.areasContactInfoDisplay);
                        resolve(entityDisplay);
                    });
                } else {
                    resolve(null);
                }
            });
        });
    }

    private getEntityDetailsById(areaId: string, mainLockID: number, onlyForRead: boolean): Observable<AreasDisplay> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.area"));
        return this.http.get<AreasDisplay>(`${environment.apiUrl}area/${areaId}/${mainLockID}/${onlyForRead}`)
            .pipe(
                map((data: any) => {
                    let areaDisplay = null;
                    if (data.response) {
                        areaDisplay = AreasDisplay.BuildAreaDisplay(data.response);

                        if (areaDisplay.areaId) {
                            if (areaDisplay.hasDiffUserLockError()) {
                                this.messageHandlerService.show(areaDisplay.lockError, MessageType.INFO);
                            }

                            // Set the locking info into lock global list.
                            this.recordLockingFlow.setLockItem(EntityName.AREA, areaDisplay.areaId, areaDisplay.lockId, areaDisplay.lockMode);
                            this.recordLockingFlow.initLockRefreshTimer(EntityName.AREA, areaDisplay.areaId, areaDisplay.lockId);
                        } else {
                            this.messageHandlerService.show(areaDisplay.lockError, MessageType.ERROR);
                        }
                    } else {
                        const errorType = MessageHandlerService.errorType(data);
                        if (errorType === MessageType.INFO) {
                            this.entityCommonService.sendEntityNotFoundEvent();
                        } else if (errorType !== MessageType.NONE && errorType !== MessageType.SESSION_INVALID) {
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), errorType);
                        }
                    }
                    this.loadWheelService.hideWheel(wheel);
                    return areaDisplay;
                }),

                catchError((error) => {
                    this.loadWheelService.hideWheel(wheel);
                    return null;
                })

            );
    }

    getAreaById(areaId: number): Observable<AreaSearch> {
        return this.http.get<AreaSearch>(`${environment.apiUrl}area/${areaId}`)
            .pipe(
                map((data: any) => {
                    return this.areaAdapter.adapt(data.response[0]);
                })
            );
    }

    getPlantById(plantId: number): Observable<PlantSearch> {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("loading.plant"));
        return this.http.get<PlantSearch>(`${environment.apiUrl}plant/${plantId}`)
            .pipe(
                map((data: any) => {
                        this.loadWheelService.hideWheel(wheel);
                        return this.plantAdapter.adapt(data.response[0]);
                })
            );
    }

    saveAreaDetails(areaDisplay: AreasDisplay): Observable<AreasDisplay> {
        return this.http.put<AreasDisplay>(`${environment.apiUrl}area/`, areaDisplay)
            .pipe(
                map((data: any) => {
                    if (data.response === null) {
                        if (data.exception !== null) {
                            this.messageHandlerService.setExceptionData(data);
                            this.messageHandlerService.show(MessageHandlerService.errorMessage(data), MessageType.EXCEPTION);
                        } else if (data.message !== null) {
                            if (areaDisplay.saveType === SaveType.TYPE_QC || areaDisplay.saveType === SaveType.UPDATE) {
                                //When the user attempts to QC a record while the lock was already cleared,
                                //this//the above warning message should still be thrown; however, the record should not reload.
                                if (data.messageType !== MessageType.WARNING.valueOf()) {
                                    this.entityCommonService.sendReloadEvent();
                                }
                            } else {
                                areaDisplay.intDataDepMigError = data.message;
                            }

                        }
                    } else {
                        const isQcUpdateOrAmend = areaDisplay.saveType === SaveType.TYPE_QC ||
                        areaDisplay.saveType === SaveType.AMENDMENT ||
                        areaDisplay.saveType === SaveType.UPDATE;

                        const afterClearFunc = () => this.entityCommonService.sendPreReleaseEvent();
                        this.recordLockingFlow.clearLockFlow(EntityName.AREA, String(areaDisplay.areaId), isQcUpdateOrAmend, afterClearFunc);
                    }
                    return data.response;
                })
            );
    }

    areaNextId(): Observable<AreasDisplay> {
        return this.http.get<any>(`${environment.apiUrl}area/nextId`)
            .pipe(
                map((data: any) => {
                    const areaDisplay = AreasDisplay.BuildNewArea(data.response);
                    if (areaDisplay && areaDisplay.areaId) {
                        // Set the locking info into lock global list.
                        this.recordLockingFlow.setLockItem(EntityName.AREA, areaDisplay.areaId, -1);
                    }
                    return areaDisplay;
                }
                ));
    }

    getUserDetailInfo(userName: string): Observable<UserDetail> {
        return this.http.get<UserDetail>(`${environment.apiUrl}registration/user/details/${userName}`).pipe(
            map((data: any) => {
                const userInfo = UserDetail.BuildUserDetailData(data.response[0]);
                return userInfo;
            }),
        );
    }

    searchArea(params: IAreaSearchParams, wheel: SpinnerProcess): Observable<AreaSearch[]> {
        const searchStart: number = performance.now();

        const httpParams = new HttpParams()
            .append('areaName', params.areaName)
            .append('physCity', params.physCity)
            .append('physState', params.physState !== null ? params.physState : '')
            .append('physCountry', params.physCountry !== null ? params.physCountry : '')
            .append('plantId', params.plantId)
            .append('offshore', params.offshore)
            .append('fieldName', params.fieldName)
            .append('waterBody', params.waterBody !== null ? params.waterBody : '')
            .append('recordStatus', params.recordStatus !== null ? params.recordStatus : '');

        if(!this.entityUtilsService.checkForValueRequired(httpParams, wheel)){
            return of(null);
        }

        return this.http.get<AreaSearch[]>(`${environment.apiUrl}area/search`, { params: httpParams })
            .pipe(
                map((data: any) => {
                    this.entityUtilsService.verifyMaxResult(data);
                    let areas: Array<any> = data.response;

                    this.telemetry.entitySearchTime("area", performance.now() - searchStart);

                    return areas.map(item => this.areaSearchAdapter.adapt(item));
                })
            )
    }

    resetSearchParams(): void {
        this.searchParams = {
            areaName: '',
            physCity: '',
            physState: null,
            physCountry: null,
            plantId: '',
            offshore: '0',
            fieldName: '',
            waterBody: null,
            recordStatus: null,
            recordedSearch: false
        };
    }


    getAreaUnits(areaId: number) {
        return this.http.get<Units>(`${environment.apiUrl}area/units/${areaId}`)
            .pipe(
                map((data: any) => Units.BuildUnits(data.response))
            );
    }

    getAreaPlants(areaId: number) {
        return this.http.get<Plants>(`${environment.apiUrl}area/plants/${areaId}`)
            .pipe(
                map((data: any) => Plants.BuildPlants(data.response))
            );
    }

    getAreaUnitsLTSA(areaId: number) {
        return this.http.get<UnitsLTSA>(`${environment.apiUrl}area/unitLTSAs/${areaId}`)
            .pipe(
                map((data: any) => UnitsLTSA.BuildUnitsLTSA(data.response))
            );
    }

    getAreaBoilers(areaId: number) {
        return this.http.get<Boilers>(`${environment.apiUrl}area/boilers/${areaId}`)
            .pipe(
                map((data: any) => Boilers.BuildBoilers(data.response))
            );
    }

    getAreaDrives(areaId: number) {
        return this.http.get<Drives>(`${environment.apiUrl}area/turbines/${areaId}`)
            .pipe(
                map((data: any) => Drives.BuildDrives(data.response))
            );
    }

    getAreaEquipments(areaId: number) {
        return this.http.get<Equipment>(`${environment.apiUrl}area/equipments/${areaId}`)
            .pipe(
                map((data: any) => Equipment.BuildEquipment(data.response))
            );
    }

    getAreaOwners(areaId: number) {
        return this.http.get<AreaOwners>(`${environment.apiUrl}area/owners/${areaId}`)
            .pipe(
                map((data: any) => AreaOwners.BuildAreaOwners(data.response))
            );
    }

    getAreaOperators(areaId: number) {
        return this.http.get<CompanyOperators>(`${environment.apiUrl}area/operators/${areaId}`)
            .pipe(
                map((data: any) => CompanyOperators.BuildOperators(data.response))
            );
    }

}



import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { StringUtils } from '../utils/string-utils';

@Directive({
    selector: '[stringUtilsDirective]'
})
export class StringUtilsDirective implements OnInit {

    @Input() regexAccepted: RegExp = null;
    @Input() toUpperCase: boolean = false;
    @Input() toLowerCase: boolean = false;

    constructor(private ngControl: NgControl) {}

    ngOnInit() {
        this.ngControl.control.valueChanges.subscribe(() => {
            if (this.ngControl.value !== null) {
                let newValue = this.getFormatedText(this.ngControl.value);
                if (this.ngControl.value !== newValue) {
                    this.ngControl.control.setValue(newValue);
                }
            }
        });
    }

    @HostListener('input', ['$event']) onInput(event: InputEvent) {
        const input = event.target as HTMLInputElement;
        input.value = this.getFormatedText(this.ngControl.value);
    }

    
    getFormatedText(value:string):string {
        let formatedText: string = String(value);
        if(!StringUtils.isEmpty(this.regexAccepted)) {
            formatedText = formatedText.replace(this.regexAccepted, '');            
        }
        if(this.toUpperCase) {
            formatedText = formatedText.toUpperCase();
        }
        if(this.toLowerCase) {
            formatedText = formatedText.toLowerCase();
        }
        return formatedText;
    }



}


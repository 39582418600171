import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, of, throwError } from "rxjs";
import { catchError, map, throttleTime } from "rxjs/operators";
import { MessageEvent, MessageResponse, MessageType } from "src/app/components/messages/message-handler/message-handler.component";
import { MessageHandlerService } from "src/app/components/messages/message-handler/message-handler.service";
import { SessionAttribute, SessionService } from 'src/app/core/services/session.service';
import { environment } from "src/environments/environment";
import { ApiEndpoint, Constants } from "../models/constants";
import { AuthService } from "src/app/auth/auth.service";
import { TelemetryService } from "../services/telemetry.service";

const EMPTY_RESPONSE: HttpResponse<any> = new HttpResponse({ body: {}, status: HttpStatusCode.Ok });

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private invalidSessionHandlerSubject = new Subject<string>();

    constructor(private messageHandlerService: MessageHandlerService,
        private session: SessionService,
        private telemetry: TelemetryService,
        private authService: AuthService,
        private translate: TranslateService) {
        this.initInvalidSessionHandler();
    }

    initInvalidSessionHandler() {
        this.invalidSessionHandlerSubject
           .pipe(throttleTime(5000))
           .subscribe((message: string) => {
               this.invalidSessionHandler(message);
           });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.handleProblems(event);
                    }
                    return event;
                }),
                catchError((err) => {
                    if(err instanceof HttpErrorResponse)
                    {
                       const handled = this.handleErrorResponse(err);
                       if(handled)
                       {
                          // Since it was handled, just pretend everything went okay
                          return of(EMPTY_RESPONSE);
                       }
                    }
                    return throwError(() => err);
                })
            );
    }


    // This should return true when no further action is necessary
    private handleErrorResponse(error: HttpErrorResponse): boolean {
        const isLoggingOut = error.url.includes(environment.apiUrl + ApiEndpoint.Logout);

        if (error.status === HttpStatusCode.Unauthorized) {
            if(!isLoggingOut)
            {
               this.invalidSessionHandlerSubject.next(error.error?.error?.message);  // error the param, maybe has a key called error, which contains our custom object that should have its own key called error, and that contains the message...yikes 
            }
            return true;
        }
        
        const isVersionRequest = error.url.includes(environment.apiUrl + ApiEndpoint.ServerVersion);
        if (isVersionRequest) {
            return true;
        }

        let msg = "Unknown/unexpected server error";
        let msgType = MessageType.ERROR;
        if ((error.status === 0 || error.status === HttpStatusCode.ServiceUnavailable || error.status === HttpStatusCode.GatewayTimeout) && !isLoggingOut) {
            msg = Constants.SYSTEM_ERROR_COMMUNICATION;
            msgType = MessageType.ALERT_TYPE_SYSTEM_MESSAGE;
        } else if (error.status === HttpStatusCode.NotFound && error.url.includes(environment.apiUrl + ApiEndpoint.AttachmentDownload)) {
            this.messageHandlerService.show(this.translate.instant('common.fileNoExist'), msgType);
            return false;
        } else if (error.status === HttpStatusCode.NotFound) {
            msg = Constants.SYSTEM_ERROR_COMMUNICATION;
            msgType = MessageType.ALERT_TYPE_SYSTEM_MESSAGE;
        } else if (!isLoggingOut) {
            msg = error.message;
            if(error.url.includes(environment.apiUrl))
            {
                this.telemetry.apiError(error.url, msg);
            }
        }

        this.messageHandlerService.show(`(${error.status}) ${msg}`, msgType);
        return false;
    }

    private handleProblems(event: HttpResponse<any>): void {
        const isSessionVerificationRequest = event.url.includes(environment.apiUrl + ApiEndpoint.VerifySession);
        if (event.status === HttpStatusCode.Unauthorized && !isSessionVerificationRequest) {
            this.invalidSessionHandlerSubject.next(event.body.message); 
            return;
        }        

        if (event.body !== null) {
            if (event.body.exception === Constants.EXCEPTION_SESSION_INVALID) {
                this.session.removeAttribute(SessionAttribute.ServerAuthToken);
                if(isSessionVerificationRequest) {
                    this.authService.logOut().subscribe();
                } else if (!event.url.includes(environment.apiUrl + ApiEndpoint.Logout)) {
                    this.invalidSessionHandlerSubject.next(event.body.message);
                }

            } else if (event.body.exception === Constants.EXCEPTION_REGISTRATION && event.url.includes(environment.apiUrl + ApiEndpoint.UserDetails)) {
                // Lookup for a user that does not exist...this is okay, don't make a big deal about it
                
            } else if (!event.url.includes(environment.apiUrl + ApiEndpoint.SendEmail)) {
                this.messageHandlerService.setExceptionData(event.body);
                if (event.body.errorTrackingCode !== null && event.body.errorTrackingCode !== undefined) {
                    this.messageHandlerService.show(MessageHandlerService.errorMessage(event.body), MessageHandlerService.errorType(event.body));
                } else if (event.body.message !== undefined && event.body.message !== null && event.body.message !== "" && event.body.message.error !== null) {

                    if (!event.body.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE) && !event.url.includes(environment.apiUrl + ApiEndpoint.Login)) {
                        this.messageHandlerService.show(event.body.message, Number(event.body.messageType) as MessageType);
                    }
                } else if (event.body.exception !== null && event.body.exception !== undefined) {

                    this.messageHandlerService.show(event.body.message !== null ? event.body.message : event.body.exception, MessageType.EXCEPTION);
                }

            }
        }
    }

    private invalidSessionHandler(message: string = Constants.SYSTEM_ERROR_SERVER_ISSUE) {
        this.messageHandlerService.destroyAllDialogs();
        
        this.messageHandlerService.show(message,
            MessageType.ERROR,
            (resp: MessageResponse): void => {
                if (resp.event === MessageEvent.OK) {
                    this.authService.logOut().subscribe();
                }
            }
        );
    }

}

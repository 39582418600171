import { Bean } from '../../bean';
import { EquipmentNotes } from '../save/equipments-notes';

export class Equipment extends Bean {
    equipmentId: number;
    equipmentName: string;
    unitId: number;
    startUpDate: Date;
    startUpResolution: string;
    shutDownDate: Date;
    shutDownResolution: string;
    equipmentTypeId: string;
    manufacturerId: string = null;
    equipmentVendorId: string;
    equipmentInfo: string;
    technology: string;
    hsProductId: string;
    capacity: string;
    equipmentStatusId: string;
    recordStatus: string;
    entryUser: string;
    entryCompany: string;
    entryDate: string;
    lastUser: string;
    lastCompany: string;
    lastDate: string;
    prevUser: string;
    prevCompany: string;
    prevDate: string;
    qcUser: string;
    qcDate: string;
    qcCompany: string;
    releaseDate: string;
    releaseUser: string;
    liveDate: string;
    amendmentDate: string;
    amendmentUser: string;
    model: string;
    noContainers:number;
    noSolarPanels:number;
    noInverters:number;
    equipmentNotes: EquipmentNotes[] = [];

    constructor(entity?: any) {
        super(entity);

        this.equipmentId = entity?.equipmentId ?? null;
        this.equipmentName = entity?.equipmentName ?? null;
        this.unitId = entity?.unitId ?? null;
        this.startUpDate = entity?.startUpDate ?? null;
        this.startUpResolution = entity?.startUpResolution ?? null;
        this.shutDownDate = entity?.shutDownDate ?? null;
        this.shutDownResolution = entity?.shutDownResolution ?? null;
        this.equipmentTypeId = entity?.equipmentTypeId ?? null;
        this.manufacturerId = entity?.manufacturerId ?? null;
        this.equipmentVendorId = entity?.equipmentVendorId ?? null;
        this.equipmentInfo = entity?.equipmentInfo ?? null;
        this.technology = entity?.technology ?? null;
        this.hsProductId = entity?.hsProductId ?? null;
        this.capacity = entity?.capacity ?? null;
        this.equipmentStatusId = entity?.equipmentStatusId ?? null;
        this.recordStatus = entity?.recordStatus ?? null;
        this.entryUser = entity?.entryUser ?? null;
        this.entryCompany = entity?.entryCompany ?? null;
        this.entryDate = entity?.entryDate ?? null;
        this.lastUser = entity?.lastUser ?? null;
        this.lastCompany = entity?.lastCompany ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.prevUser = entity?.prevUser ?? null;
        this.prevCompany = entity?.prevCompany ?? null;
        this.prevDate = entity?.prevDate ?? null;
        this.qcUser = entity?.qcUser ?? null;
        this.qcDate = entity?.qcDate ?? null;
        this.qcCompany = entity?.qcCompany ?? null;
        this.releaseDate = entity?.releaseDate ?? null;
        this.releaseUser = entity?.releaseUser ?? null;
        this.liveDate = entity?.liveDate ?? null;
        this.amendmentDate = entity?.amendmentDate ?? null;
        this.amendmentUser = entity?.amendmentUser ?? null;
        this.model = entity?.model ?? null;
        this.noContainers = entity?.noContainers ?? null;
        this.noSolarPanels = entity?.noSolarPanels ?? null;
        this.noInverters = entity?.noInverters ?? null;
        this.equipmentNotes = entity?.equipmentNotes ?? [];
    }
}

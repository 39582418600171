import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Third Party
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Component Base
import { BaseModalComponent } from '../base-modals.component';
import { Contact } from '../../../core/models/contact';
import { CompanyStatusValue, ContactStatusValue, EntityPreferenceSufix, HexaColor, RecordStatusValue } from 'src/app/core/models/constants';
import { ContactStatus } from 'src/app/core/models/enumerations/contact-status';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { MessageEvent, MessageResponse, MessageType } from 'src/app/components/messages/message-handler/message-handler.component';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/core/services/contact.service';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-related-entities',
    templateUrl: './related-entities.component.html',
    styleUrls: ['./related-entities.component.css']
})

export class RelatedEntitiesComponent extends BaseModalComponent implements OnInit {
    @ViewChild('tr') rowSelected: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    contact: Array<Contact> = [];
    contactID: number;
    cols: any = [] = [];
    selectedRow: any;
    disableButton: boolean = true;
    entityName: string;
    recordStatusDesc: string;
    companyStatusDesc: string;
    readonly trashStatus = 'Trash';
    readonly incompleteStatus = 'Incomplete';
    readonly suspendedUnresolved = 'Suspended / Unresolved';
    readonly closed = 'Closed';
    contactStatuses = { DEAD: 'Dead', TRASH: 'Trash', MOVED: 'Moved', RETIRED: 'Retired'};

    constructor(
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public messageHandlerService: MessageHandlerService,
        public translate: TranslateService,
        public preferencesService: PreferencesSectionService,
        public contactService: ContactService,) {
        super(activeModal, config, preferencesService)
    }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.contact = this.config.data.contact;
        this.entityName = this.config.data.entityName;
        this.contactID = this.config.data.contact[0].ContactID;
        this.cols = [
            { field: 'EntityRelation', header: 'Entity', isDate: false, style: 'width: 7%' },
            { field: 'EntityId', header: 'Entity ID', isDate: false, style: 'width: 8%' },
            { field: 'Entity_Name', header: 'Entity Name', isDate: false, style: 'width: 15%' },
            { field: 'EntityCity', header: 'City', isDate: false, style: 'width: 10%' },
            { field: 'EntityState', header: 'State', isDate: false, style: 'width: 7%' },
            { field: 'MailPostalCode', header: 'Portal Code', isDate: false, style: 'width: 10%' },
            { field: 'EntityAddressV1', header: 'Address', isDate: false, style: 'width: 14%' },
            { field: 'ContactTitleDesc', header: 'Title', isDate: false, style: 'width: 10%' }, 
            { field: 'Status', header: 'Contact Status', isDate: false, style: 'width: 11%' },
            { field: 'QcDateObj', header: 'QC Date', isDate: true, style: 'width: 8%' }];
    }

    onRowDblclick(event: any) {
        this.selectAndCloseModal(event);
    }

    onClickRow(event: any) {
        this.disableButton = true;
        this.selectedRow = event;

        if(!this.isDisabledRow(event)) {
            this.disableButton = false;
        }
    }

    addRowSelected() {
        this.selectAndCloseModal(this.selectedRow);
    }

    selectAndCloseModal(row: any) {
        const confirmFunction = (resp: MessageResponse): void => {
            if (resp.event === MessageEvent.YES) {
                this.activeModal.close(row);
            } else if (resp.event === MessageEvent.NO) {
                return;
            }
        }   

        if(!this.isDisabledRow(row)) {
            if(StringUtils.equalsIgnoreCase(row.Status, ContactStatus.CURRENT)) {
                this.activeModal.close(row);
            }
            else if(StringUtils.equalsIgnoreCase(row.Status, ContactStatus.RETIRED) || StringUtils.equalsIgnoreCase(row.Status,ContactStatus.MOVED)) {
                this.messageHandlerService.show(this.translate.instant('contact.contactRelated.confirmSelectContact', { status: row.Status }), MessageType.CONFIRM, confirmFunction);
                return;
            }
        }
    }

    getColumnColor(row: Contact) {
        let defaultColor = HexaColor.black;
        if (this.isDisabledRow(row)) {
            defaultColor = HexaColor.gray;
        } else if(row.ContactOptedOut) {
            defaultColor = HexaColor.red;
        }
        return defaultColor;
    }

    isDisabledRow(row: Contact) {
        return this.contactService.getDisabledRow(row, this.entityName);
    }

    getColumnTitle(row: Contact) {
        const contactStatus = row?.Status?.toUpperCase() ?? row?.ContactStatus?.toUpperCase();
        let companyRelatedMsg = '';
        let defaultTitle = '';

        if (!StringUtils.equalsIgnoreCase(contactStatus, ContactStatus.CURRENT)) {
            defaultTitle = this.translate.instant('contact.contactModal.noSelectionContactStatus', { status: this.contactStatuses[contactStatus]});
            return defaultTitle;
        }

        if(row.isAddressMissing()) {
            defaultTitle = this.translate.instant('contact.contactModal.noSelectionMissingAddress');
            return defaultTitle;
        }

        if(StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.Closed)) {
            this.companyStatusDesc =  this.closed;       
        } else if(StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.SuspendedUnresolved)) {
            this.companyStatusDesc = this.suspendedUnresolved;
        }

        if(StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Trash)) {
            this.recordStatusDesc =  this.trashStatus;  
        } else if(StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Incomplete)) {
            this.recordStatusDesc = this.incompleteStatus;
        }
        if (StringUtils.equalsIgnoreCase(row.EntityRecordStatus, RecordStatusValue.Incomplete) && !StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.Closed)
        && !StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.SuspendedUnresolved)){
            companyRelatedMsg = this.translate.instant('contact.contactModal.contactAssociated', { letter: 'an'});
        } else {
            companyRelatedMsg = this.translate.instant('contact.contactModal.contactAssociated', { letter: 'a'});
        }
        
        if((StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.Closed) || StringUtils.equalsIgnoreCase(row.CompanyStatus,CompanyStatusValue.SuspendedUnresolved)) 
        && (!StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Trash) && !StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Incomplete))){
            defaultTitle = companyRelatedMsg.replace('{{status}}', this.companyStatusDesc);
        }
        else if ((!StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.Closed) && !StringUtils.equalsIgnoreCase(row.CompanyStatus, CompanyStatusValue.SuspendedUnresolved)) 
                && (StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Trash) || StringUtils.equalsIgnoreCase(row.RecordStatus, RecordStatusValue.Incomplete))) {
                    defaultTitle = companyRelatedMsg.replace('{{status}}', this.recordStatusDesc);
        }

        return defaultTitle;
    }
}

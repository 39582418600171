import { Bean } from "../../bean";
import { OfflineEventsDrivesDisplay } from "./offline-events-drives-display";
import { OfflineEventsBoilersDisplay } from "./offline-events-boilers-display";
import { EntityName } from "../../enumerations/entity-name";
import { OfflineEventsDisplay } from "./offline-event-display";
import { MVTOperations } from "src/app/core/mvt-operations";
import { OperationType } from "../../enumerations/operation-type";
import { OfflineEventsUnitsDisplay } from "./offline-events-units-display";

export class OfflineEventEquipment extends Bean {
    selected:boolean;
    equipmentId: number;
    equipmentName: string;
    equipmentType: string;
    equipmentTypeId: number;
    unitType: string;
    startupDate: number;
    equipmentStatus: string;
    qcDate: number;
    releaseDate: number;
    entityType: string;
    manufacturer: string;
    unitId: number;
    unitName: string;
    categoryId: string;
    categoryName: string;
    offlineEventDrive: OfflineEventsDrivesDisplay;
    offlineEventBoiler: OfflineEventsBoilersDisplay;

    constructor(
        equipmentId: number,
        equipmentName: string,
        equipmentType: string,
        equipmentTypeId: number,
        unitType: string,
        startupDate: number,
        equipmentStatus: string,
        qcDate: number,
        releaseDate: number,
        entityType: string,
        manufacturer: string,
        unitId: number,
        unitName: string,
        categoryId: string,
        categoryName: string,
        offlineEventDrive: OfflineEventsDrivesDisplay,
        offlineEventBoiler: OfflineEventsBoilersDisplay,
        selected: boolean
    ) {
        super();
        this.equipmentId = equipmentId;
        this.equipmentName = equipmentName;
        this.equipmentType = equipmentType;
        this.equipmentTypeId = equipmentTypeId;
        this.unitType = unitType;
        this.startupDate = startupDate;
        this.equipmentStatus = equipmentStatus;
        this.qcDate = qcDate;
        this.releaseDate = releaseDate;
        this.entityType = entityType;
        this.manufacturer = manufacturer;
        this.unitId = unitId;
        this.unitName = unitName;
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.offlineEventDrive = offlineEventDrive;
        this.offlineEventBoiler = offlineEventBoiler;
    }

    static BuildOfflineEventEquipment(entity: Array<any> , entityType: string): Array<OfflineEventEquipment> {
        return entity.map(item => OfflineEventEquipment.CreateInstance(item, entityType));
    }

    private static CreateInstance(equipment: any, entityType: string): OfflineEventEquipment {
        const {
            EQUIPMENT_ID,
            EQUIPMENT_NAME,
            TYPE_NAME,
            TYPE_ID,
            EQUIPMENT_STATUS_DESC,
            EQUIPMENT_TYPE_DESC,
            STARTUP_DATE,
            QC_DATE,
            RELEASE_DATE,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME,
            CATEGORY_ID,
            CATEGORY_NAME
        } = equipment;

    
        return new OfflineEventEquipment(
            EQUIPMENT_ID || equipment.BOILER_ID || equipment.TURBINE_ID,
            EQUIPMENT_NAME || equipment.TURBINE_NAME || equipment.NAME || equipment.BOILER_NAME,
            EQUIPMENT_TYPE_DESC || equipment.TURBINE_TYPE_DESC || equipment.TYPE_DESCRIPTION || equipment.BOILER_TYPE_DESC,
            TYPE_ID,
            TYPE_NAME || equipment.TYPE_DESCRIPTION || equipment.BOILER_TYPE || equipment.TYPE,
            STARTUP_DATE,
            EQUIPMENT_STATUS_DESC,
            QC_DATE,
            RELEASE_DATE,
            entityType,
            MANUFACTURER_NAME,
            UNIT_ID,
            UNIT_NAME,
            CATEGORY_ID,
            CATEGORY_NAME,
            null,
            null,
            null
        );
    }

    public getSelected(): boolean {
       return !!(this.offlineEventBoiler ?? this.offlineEventDrive);
    }

    public setEquipments(value: boolean, offlineEventsDisplay: OfflineEventsDisplay, unitId: string): void {
        if (value) {
            if (this.entityType === EntityName.BOILER) {
                this.handleBoiler(offlineEventsDisplay, unitId);
            } else if (this.entityType === EntityName.TURBINE) {
                this.handleTurbine(offlineEventsDisplay, unitId);
            }
        } else {
            this.removeEquipmentFromOfflineEvent(offlineEventsDisplay);
        }
    }
    
    private handleBoiler(offlineEventsDisplay: OfflineEventsDisplay, unitId: string): void {
        const boilerArray = offlineEventsDisplay.offlineEventsBoilersDisplay;
        const equipmentId = this.equipmentId.toString();
        const unitData = this.getUnitData(unitId, offlineEventsDisplay);
        this.findAndUpdateOrInsert(unitData, boilerArray, 'boilerId', equipmentId, this.categoryId, this.createNewBoilerObject.bind(this));
     }
    
    private handleTurbine(offlineEventsDisplay: OfflineEventsDisplay, unitId: string): void {
        const driveArray = offlineEventsDisplay.offlineEventsDrivesDisplay;
        const equipmentId = this.equipmentId.toString();
        const unitData = this.getUnitData(unitId, offlineEventsDisplay);
        this.findAndUpdateOrInsert(unitData, driveArray, 'driveId', equipmentId, this.categoryId, this.createNewTurbineObject.bind(this));
    }

    getUnitData(unitId: string, offlineEventsDisplay: OfflineEventsDisplay): OfflineEventsUnitsDisplay | null {
        for (const unit of offlineEventsDisplay.offlineEventsUnitsDisplay) {
            if (unit.unitId === unitId) {
                return unit;
            }
        }
        return null;
    }
    
    private findAndUpdateOrInsert(unitData:OfflineEventsUnitsDisplay, entityArray: any[], entityIdPropName: string, idToFind: string, categoryId: string, createNewObject: (unitData: OfflineEventsUnitsDisplay, entityObject: any, categoryId: string,  entitiesArray: any[]) => any): void {
        for (let entity of entityArray) {
            if (entity[entityIdPropName] === idToFind) {
                entity.setOperation((entity.getOperation() === OperationType.DELETE) ? OperationType.UPDATE : entity.getOperation());
                entity.offlineEventCategoryId = categoryId;
                return entity;
            }
        }
        const newEntity = createNewObject(unitData, idToFind, categoryId, entityArray);

        if(newEntity instanceof  OfflineEventsBoilersDisplay){
            this.offlineEventBoiler = newEntity;
        } else if(newEntity instanceof  OfflineEventsDrivesDisplay) {
            this.offlineEventDrive = newEntity;
        }

        entityArray.push(newEntity);
    }
    
    private createNewBoilerObject(unitData: OfflineEventsUnitsDisplay, equipmentId: number, categoryId: string, entitiesArray: any[]): OfflineEventsBoilersDisplay {
        return OfflineEventsBoilersDisplay.CreateInstance({
            offlineEventId: unitData.offlineEventId,
            boilerId: equipmentId,
            startDate: unitData.startDate,
            endDate: unitData.endDate,
            lastStartDate: unitData.lastStartDate,
            lastEndDate: unitData.lastEndDate,
            derate: unitData.derate,
            offlineEventCategoryId: categoryId,
            operation: OperationType.INSERT,
            mvOrder: MVTOperations.getLastMvOrder(entitiesArray) + 1,
        });
    }
    
    private createNewTurbineObject(unitData: OfflineEventsUnitsDisplay, equipmentId: number, categoryId: string, entitiesArray: any[]): OfflineEventsDrivesDisplay {
        return OfflineEventsDrivesDisplay.CreateInstance({
            offlineEventId: unitData.offlineEventId,
            driveId: equipmentId,
            startDate: unitData.startDate,
            lastStartDate: unitData.lastStartDate,
            lastEndDate: unitData.lastEndDate,
            endDate: unitData.endDate,
            derate: unitData.derate,
            offlineEventCategoryId: categoryId,
            operation: OperationType.INSERT,
            mvOrder: MVTOperations.getLastMvOrder(entitiesArray) + 1
        });
    }
    
    private removeEquipmentFromOfflineEvent(offlineEventsDisplay: OfflineEventsDisplay): void {
        if (this.entityType === EntityName.BOILER) {
            MVTOperations.removeItem(offlineEventsDisplay.offlineEventsBoilersDisplay, 'boilerId', this.equipmentId.toString());
        } else if (this.entityType === EntityName.TURBINE) {
            MVTOperations.removeItem(offlineEventsDisplay.offlineEventsDrivesDisplay, 'driveId', this.equipmentId.toString());
        }
    }
    

}
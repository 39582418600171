import { Component, ViewChild, OnDestroy, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AsideComponent } from 'src/app/shared/aside/aside.component';
import { HeaderService } from 'src/app/shared/header/header.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services/shared.service';
import { AsideService } from 'src/app/shared/aside/aside.service';

@Component({
	selector: 'app-admin-layout',
	templateUrl: './admin-layout.component.html',
	styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements AfterViewInit, OnDestroy {

	public offsetClass: string;
	isDash: boolean = false;
	private menuChanged: Subscription;
	@ViewChild('appRightSideElement', { static: false }) appRightSideElement: ElementRef;
	@ViewChild('aside', { static: false }) aside: AsideComponent;

	constructor(public headerService: HeaderService,
		private sharedService: SharedService,
		private asideService: AsideService,
		private changeDetector: ChangeDetectorRef) { }

	ngAfterViewInit(): void {
		this.sharedService.appRightSideElement = this.appRightSideElement?.nativeElement;
		this.isDash = false;
		this.menuChanged = this.asideService.menuChanged$.subscribe(menuData => {
			this.isDash = menuData.name === 'home';
			this.changeDetector.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this.menuChanged?.unsubscribe();
	}

}

import { Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CellContainerService {
    private static readonly STORAGE_KEY = 'cellContainerData';

    private dialogRefs: DynamicDialogRef[] = [];
    
    private readonly dataSubject = new BehaviorSubject<any[]>(this.getStoredData());
    readonly dataChange$ = this.dataSubject.asObservable();

    constructor() {
        window.addEventListener('storage', (event) => {
            if (event.key === CellContainerService.STORAGE_KEY) {
                const updatedData = event.newValue ? JSON.parse(event.newValue) : [];
                this.dataSubject.next(updatedData);
            }
        });
    }

    setData(data: any): void {
        localStorage.setItem(CellContainerService.STORAGE_KEY, JSON.stringify(data));
        this.dataSubject.next(data);
    }

    getStoredData(): any {
        const storedData = localStorage.getItem(CellContainerService.STORAGE_KEY);
        return storedData ? JSON.parse(storedData) : null;
    }

    clearData(): void {
        localStorage.removeItem(CellContainerService.STORAGE_KEY);
        this.dataSubject.next([]);
    }

    registerDialog(dialogRef: DynamicDialogRef): void {
        this.dialogRefs.push(dialogRef);
    }

    closeAllDialogs(): void {
        this.dialogRefs.forEach((dialogRef) => {
            if (dialogRef) {
                dialogRef.close();
            }
        });
        this.dialogRefs = [];
    }

}

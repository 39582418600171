import { ChangeDetectionStrategy, Component, Input, OnInit, OnChanges, ViewChild, AfterViewInit, ViewChildren, QueryList, SimpleChanges} from '@angular/core';
import { IContact} from 'src/app/core/interfaces/icontacts';
import { Constants, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { EditableColumn, Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/core/services/shared.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { FocusableEvent, FocusableEventType } from 'src/app/core/directives/focusable-table.directive';
import { ContactComponent } from '../contact.component';
import { Preference } from 'src/app/core/models/common';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-slotted-table',
    templateUrl: './slotted-table.component.html',
    styleUrls: ['./slotted-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlottedTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('acContactsSlottedTable') acContactsSlottedTable: Table;
    @ViewChildren(EditableColumn) private slottedEditableColumns: QueryList<EditableColumn>;
    rowsPerPageOptions = Constants.ROWS_PER_PAGE_OPTIONS;
    
    slottedCols: any = [] = [];

    @Input() contactComponent: ContactComponent;
    @Input() acContactsSlotted: Array<IContact> = [];
    @Input() useSlottedBehavior?: boolean = false;
    @Input() disabledButton: boolean = false;
    @Input() baseIndex: number;
    @Input() hideSort: boolean = false;
    rowsPerPage: number = 5;
    tableKey: string;
    @Input() preferences: Array<Preference> = [];
    @Input() entityName: string;
    

    selectContactStatus: any[] = [];
    slottedFocusManager = new Subject<FocusableEvent>();

    constructor(
        private translate: TranslateService,
        public headerService: HeaderService,
        public sharedService: SharedService,
        private preferencesService: PreferencesSectionService
    ) { }

    ngOnInit(): void {
        this.initSlottedCols();
        this.selectContactStatus = this.contactComponent.selectContactStatus;
        this.tableKey = this.preferencesService.getPreferenceResourceName(this.entityName, EntityPreferenceSufix.SlottedContact);
    }

    onPageChange(event): void {
        if(this.tableKey) {
            this.preferencesService.updatePreferenceOnPageChange(this.tableKey, String(event.rows));
        }
    }

    ngOnChanges(changes: SimpleChanges){
        if(changes.preferences && this.tableKey && this.preferences) {
            this.rowsPerPage = this.preferencesService.getPaginatorPreference(this.preferences, this.tableKey, this.rowsPerPage);
        }
    }

    ngAfterViewInit() {
        this.slottedFocusManager.next(new FocusableEvent(FocusableEventType.ADD_EDITABLE_COLUMNS, this.slottedEditableColumns));
    }

    initSlottedCols() {
        this.slottedCols = [];
        const editable = this.contactComponent.editable;
        const getColumnHeaderStyle = this.contactComponent.getColumnHeaderStyle;
        const styles = getColumnHeaderStyle(8, 14, 10, 10, 12, 12, 10, 12, 6, 4, 2);

        this.slottedCols.push({ field: 'contactId', header: this.translate.instant('contact.common.contactId'), editable: editable, style: styles.contactId });
        this.slottedCols.push({ field: 'contactTitle', header: this.translate.instant('contact.common.contactFunction'), editable: false, style: styles.contactTitle });
        this.slottedCols.push({ field: 'firstName', header: this.translate.instant('contact.contactGrid.firstName'), editable: false, style: styles.firstName });
        this.slottedCols.push({ field: 'lastName', header: this.translate.instant('contact.contactGrid.lastName'), editable: false, style: styles.lastName });
        this.slottedCols.push({ field: 'contactTitleDesc', header: this.translate.instant('contact.common.actualTitle'), editable: false, style: styles.contactTitleDesc });
        this.slottedCols.push({ field: 'companyName', header: this.translate.instant('contact.common.company'), editable: false, style: styles.companyName });
        this.slottedCols.push({ field: 'contactStatus', header: this.translate.instant('common.status'), editable: editable, style: styles.contactStatus });
        this.slottedCols.push({ field: 'contactQcDate', header: this.translate.instant('common.qcDate'), editable: editable, style: styles.contactQcDate });
        this.slottedCols.push({ field: 'contactOnsiteBool', header: this.translate.instant('contact.contactGrid.contactOnSite'), editable: editable, style: styles.contactOnsiteBool });
        this.slottedCols.push({ field: 'contactSaleFlagBool', header: this.translate.instant('contact.contactGrid.contactSell'), editable: editable, style: styles.contactSaleFlagBool });
        this.slottedCols.push({ field: 'rollbackButton', header: '', editable: editable, style: styles.contactActionButton });
    }

    onRowclick(contact: any) {
        this.contactComponent.additionalInfo = contact;
        this.contactComponent.buildPhoneAndAltPhone(contact);
        this.contactComponent.contactDisplay = contact.contactDisplay;
    }

    onEditInit(event: any) {
        this.contactComponent.onEditInit(event);
    }

    onEditComplete(event: any) {
        this.contactComponent.onEditComplete(event);
    }

    dgContactsPool_doubleClick(iContact: IContact) {
        this.contactComponent.dgContactsPool_doubleClick(iContact);
    }

    onChangeContactStatusInSlotted(row: IContact): void {
        this.contactComponent.forceOnEditComplete('slotted');

        if (this.useSlottedBehavior
            && row.contactStatus !== 'CURRENT'
            && row.contactStatus !== ''
            && row.contactStatus !== null) {
            this.contactComponent.moveToPool(row);
        }
    }

    forceOnEditComplete(table: 'pool' | 'slotted') {
        this.forceOnEditComplete(table);
    }

    onChangeContactOnsite(row: IContact) {
        this.contactComponent.onChangeContactOnsite(row);
    }

    onChangeContactSell(row: IContact) {
        this.contactComponent.onChangeContactSell(row);
    }


    moveToPool(contact: IContact) {
        this.contactComponent.moveToPool(contact);
    }

    functionalTitleById(titleId: string): string {
        return this.contactComponent.functionalTitleById(titleId);
        //return this.contactComponent.functionalTitlesIndexed[Number(titleId)] ?? Constants.MESSAGE_ASSIGN_A_FUNCTION;
    }

    clickToNativeElement() {
        this.acContactsSlottedTable.el.nativeElement.click();
    }

    changeSelectionOnArrowKey(event: any, entityArray:any[], hasHeader:boolean = true) {
        this.contactComponent.changeSelectionOnArrowKey(event, entityArray, hasHeader);
    }

}

import { Address } from "./address";
import { AssetsPathType } from "./enumerations/assets-path-type";
import { OffshoreAddress } from "./offshoreAddress";

export class AssetsPath {
    assetPathType: AssetsPathType;
    plantId: number;
    plantName: string;
    onshoreAddress: Address;
    offshoreAddress: OffshoreAddress;
    latitude: number;
    longitude: number;
    diameter: number;
    offshore: boolean;

    public constructor(
        assetPathType: AssetsPathType,
        plantId: number,
        plantName: string,
        offshore: boolean,
        onshoreAddress: Address,
        offshoreAddress: OffshoreAddress,
        latitude: number,
        longitude: number,
        diameter: number
    ) {
        this.assetPathType = assetPathType;
        this.plantId = plantId;
        this.plantName = plantName;
        this.onshoreAddress = onshoreAddress;
        this.offshoreAddress = offshoreAddress;
        this.latitude = latitude;
        this.longitude = longitude;
        this.diameter = diameter;
        this.offshore = offshore;
    }

}

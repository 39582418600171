import { IAuditEntityData } from "src/app/core/interfaces/iaudit-entity-data";
import { ContactsDisplay } from "../../contact/display/contacts-display";
import { EntityName } from "../../enumerations/entity-name";
import { OperationType } from "../../enumerations/operation-type";
import { PlantsLTSA } from "../save/plants-ltsa";
import { PlantsLTSAContactInfoDisplay } from "./plants-ltsa-contact-info-display";
import { PlantsLTSANotesDisplay } from "./plants-ltsa-notes-display";
import { PlantsLTSASubTypesDisplay } from "./plants-ltsa-sub-types-display";
import { PlantsLTSATradesDisplay } from "./plants-ltsa-trades-display";
import { PlantsLTSATypesDisplay } from "./plants-ltsa-types-display";
import { LTSAEvergreen } from "../../constants";

export class PlantsLTSADisplay extends PlantsLTSA {
    plantName: string;
    companyName: string;
    plantOwner: string;
    mailAddressV1: string;
    mailAddressV2: string;
    mailCountry: string;
    mailCountryName: string;
    mailState: string;
    mailStateName: string;
    mailCity: string;
    mailPostalCode: string;
    phoneCc: string;
    phoneNo: string;
    phoneExt: string;
    lastUserEmailAddress: string;
    entryUserEmailAddress: string;
    previousUserEmailAddress: string;
    qcUserEmailAddress: string;
    releaseUserEmailAddress: string;
    amendmentUserEmailAddress: string;
    mailCountryId: number;
    mailStateId: number;
    mailCityId: number;

    evergreenBool: boolean;
    preferredContractorBool: boolean;

    plantsLtsaContactInfoDisplay: Array<PlantsLTSAContactInfoDisplay>;
    plantsLtsaNotesDisplay: Array<PlantsLTSANotesDisplay>;
    plantsLtsaTypesDisplay: Array<PlantsLTSATypesDisplay>;
    plantsLtsaSubTypesDisplay: Array<PlantsLTSASubTypesDisplay>;
    plantsLtsaTradesDisplay: Array<PlantsLTSATradesDisplay>;
    contactsDisplays: Array<ContactsDisplay>;

    private constructor(entity?: any) {
        super(entity);

        this.plantName = entity?.plantName ?? null;
        this.companyName = entity?.companyName ?? null;
        this.plantOwner = entity?.plantOwner ?? null;
        this.mailAddressV1 = entity?.mailAddressV1 ?? null;
        this.mailAddressV2 = entity?.mailAddressV2 ?? null;
        this.mailCountry = entity?.mailCountry ?? null;
        this.mailCountryName = entity?.mailCountryName ?? null;
        this.mailState = entity?.mailState ?? null;
        this.mailStateName = entity?.mailStateName ?? null;
        this.mailCity = entity?.mailCity ?? null;
        this.mailPostalCode = entity?.mailPostalCode ?? null;
        this.phoneCc = entity?.phoneCc ?? null;
        this.phoneNo = entity?.phoneNo ?? null;
        this.phoneExt = entity?.phoneExt ?? null;
        this.lastUserEmailAddress = entity?.lastUserEmailAddress ?? null;
        this.entryUserEmailAddress = entity?.entryUserEmailAddress ?? null;
        this.previousUserEmailAddress = entity?.previousUserEmailAddress ?? null;
        this.qcUserEmailAddress = entity?.qcUserEmailAddress ?? null;
        this.releaseUserEmailAddress = entity?.releaseUserEmailAddress ?? null;
        this.amendmentUserEmailAddress = entity?.amendmentUserEmailAddress ?? null;
        this.mailCountryId = entity?.mailCountryId ?? null;
        this.mailStateId = entity?.mailStateId ?? null;
        this.mailCityId = entity?.mailCityId ?? null;

        this.preferredContractorBool = Number(this.preferredContractor) === 1;
        this.evergreenBool = Number(this.contractLength) === LTSAEvergreen.Code;

        this.plantsLtsaContactInfoDisplay = entity?.plantsLtsaContactInfoDisplay ?? [];
        this.plantsLtsaNotesDisplay = entity?.plantsLtsaNotesDisplay ?? [];
        this.plantsLtsaTypesDisplay = entity?.plantsLtsaTypesDisplay ?? [];
        this.plantsLtsaSubTypesDisplay = entity?.plantsLtsaSubTypesDisplay ?? [];
        this.plantsLtsaTradesDisplay = entity?.plantsLtsaTradesDisplay ?? [];
        this.contactsDisplays = entity?.contactsDisplays ?? [];
    }

    static BuildPlantLTSADisplay(entity: any): PlantsLTSADisplay {
        return new PlantsLTSADisplay(entity);
    }

    static BuildNewPlantLTSA(response: any): PlantsLTSADisplay {
        return new PlantsLTSADisplay({
            plantLtsaId: response[0].PLANT_LTSA_ID,
            operation: OperationType.INSERT,
            intDataDepValState: OperationType.INSERT
        });
    }

    get auditEntityBean(): IAuditEntityData {
        return {
            lastUser: this.lastUser,
            entryUser: this.entryUser,
            prevUser: this.prevUser,
            qcUser: this.qcUser,
            lastDate: this.lastDate,
            entryDate: this.entryDate,
            prevDate: this.prevDate,
            qcDate: this.qcDate,
            releaseUser: this.releaseUser,
            releaseDate: this.releaseDate,
            amendmentUser: this.amendmentUser,
            amendmentDate: this.amendmentDate,
            lastUserEmailAddress: this.lastUserEmailAddress,
            entryUserEmailAddress: this.entryUserEmailAddress,
            previousUserEmailAddress: this.previousUserEmailAddress,
            qcUserEmailAddress: this.qcUserEmailAddress,
            releaseUserEmailAddress: this.releaseUserEmailAddress,
            amendmentUserEmailAddress: this.amendmentUserEmailAddress,
            lastUpdateStatus: '',
            entityName: EntityName.PLANT_LTSA,
            entityId: this.plantLtsaId
        };
    }

    prepareSaveCollection() {
        this.plantsLtsaContactInfo = this.plantsLtsaContactInfoDisplay;
        this.plantsLtsaNotes = this.plantsLtsaNotesDisplay;
        this.plantsLtsaTypes = this.plantsLtsaTypesDisplay;
        this.plantsLtsaSubTypes = this.plantsLtsaSubTypesDisplay;
        this.plantsLtsaTrades = this.plantsLtsaTradesDisplay;
        this.contacts = this.contactsDisplays;
    }


}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AreaSectionService } from 'src/app/area-section/area-section.service';
import { IAreaSearchParams } from 'src/app/core/interfaces/search/iarea-search-params';
import { Constants, EntityPreferenceSufix } from 'src/app/core/models/constants';
import { CountryWaterBody, OffshoreField, WaterBody } from 'src/app/core/models/offshoreAddress';
import { AreaSearch } from 'src/app/core/models/search/area-search';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { Country, RecordStatus, State } from '../../../core/models/common';
import { AddressService } from '../../address-form/address.service';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { OffshoreAddressService } from '../../offshore-address-form/offshore-address.service';
import { BaseModalComponent } from '../base-modals.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';

@Component({
    selector: 'app-search-area-modal',
    templateUrl: './search-area-modal.component.html',
    styleUrls: ['./search-area-modal.component.scss']
})
export class SearchAreaComponent extends BaseModalComponent implements OnInit {
    @ViewChild('inputAreaName') inputAreaName: ElementRef;
    tableKey = EntityPreferenceSufix.Entity;
    entitiesArray: Array<AreaSearch> = [];
    additionalInfo: AreaSearch;

    recordStatuses: Array<RecordStatus> = [];
    physCountries: Array<Country> = [];
    physStates: Array<State> = [];
    waterBodies: Array<WaterBody> = [];
    fields: Array<OffshoreField> = [];
    isMainAccess: boolean = false;
    restrictedOnOffshore: boolean = false;
    restrictedOnOffshoreValue: string;


    selected: any;
    enabledNewRecordButton: boolean = false;
    colsOnshore: any[] = [];
    colsOffshore: any[] = [];
    changeClass: boolean = false;
    changeCard: boolean = false;

    searchParams: IAreaSearchParams = {
        areaName: '',
        physCity: '',
        physState: null,
        physCountry: null,
        plantId: '',
        offshore: '0',
        fieldName: '',
        waterBody: null,
        recordStatus: null,
        recordedSearch: false
    }

    constructor(
        private areaService: AreaSectionService,
        private addressService: AddressService,
        private offshoreAddressService: OffshoreAddressService,
        private messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sharedService: SharedService,
        public dialogService: DialogService,
        public translate: TranslateService,
        public preferencesService: PreferencesSectionService,
        private entityCommonService: EntityCommonService,
        public loadWheelService: LoadWheelService
    ) { super(activeModal, config, preferencesService); }

    ngOnInit() {
        this.initPaginatorPreferences();
        this.colsOnshore = [
            { field: 'areaId', header: this.translate.instant('area.searchArea.areaTableAreaId'), width: '9%', isDate: false },
            { field: 'areaName', header: this.translate.instant('area.searchArea.areaTableAreaName'), width: '18%', isDate: false },
            { field: 'physStateName', header: this.translate.instant('area.searchArea.areaTableState'), width: '8%', isDate: false },
            { field: 'physCountryName', header: this.translate.instant('area.searchArea.areaTableCountry'), width: '8%', isDate: false },
            { field: 'plantName', header: this.translate.instant('area.searchArea.areaTablePlantName'), width: '15%', isDate: false },
            { field: 'companyName', header: this.translate.instant('area.searchArea.areaTableCompanyName'), width: '15%', isDate: false }
        ]

        this.colsOffshore = [
            { field: 'areaId', header: this.translate.instant('area.searchArea.areaTableAreaId'), width: '9%', isDate: false },
            { field: 'areaName', header: this.translate.instant('area.searchArea.areaTableAreaName'), width: '18%', isDate: false },
            { field: 'waterbodyName', header: this.translate.instant('area.searchArea.areaTableWaterBody'), width: '10%', isDate: false },
            { field: 'fieldName', header: this.translate.instant('area.searchArea.areaTableFieldName'), width: '9%', isDate: false },
            { field: 'plantName', header: this.translate.instant('area.searchArea.areaTablePlantName'), width: '15%', isDate: false },
            { field: 'companyName', header: this.translate.instant('area.searchArea.areaTableCompanyName'), width: '15%', isDate: false }
        ]

        this.loadList();
        this.isMainAccess = this.config.data?.isMainAccess !== undefined ? this.config.data?.isMainAccess : false;
        this.clear();

        if (this.config.data?.restrictedOnOffshore) {
            this.restrictedOnOffshore = true;
            this.restrictedOnOffshoreValue = this.config.data?.offshore ? '1' : '0';
        }
        this.searchEntityByParamsRecording();
        if (this.searchParams.areaName != null && this.searchParams.areaName !== '') {
            setTimeout(() => this.inputAreaName.nativeElement.select(), 0);
        }else if(this.config.data?.plantId){
            this.searchParams.plantId = this.config.data?.plantId;
            this.searchEntities(this.areaService.searchParams);
        }
        this.loadCountries();
        this.loadWaterBodies();

        setTimeout(() => this.inputAreaName.nativeElement.focus(), 10);
    }

    onOffshoreChange() {
        this.clearFields(false);
        this.loadCountries();
        setTimeout(() => this.inputAreaName.nativeElement.focus(), 1);
    }

    search() {
        this.searchEntities(this.searchParams);
    }

    private searchEntityByParamsRecording() {
        this.searchParams = this.areaService.searchParams;
        if (this.restrictedOnOffshore) {
            this.searchParams.offshore = this.restrictedOnOffshoreValue;
        }
        if (this.searchParams.recordedSearch) {
            if (!this.isEmptyObjectProperty(this.areaService.searchParams)) {
                this.searchEntities(this.areaService.searchParams);
            }
        }
        if (this.searchParams.physCountry !== '' && this.searchParams.physCountry !== null) {
            this.loadStates(this.searchParams.physCountry);
        }
    }

    private isEmptyObjectProperty(object: IAreaSearchParams): boolean {
        const { offshore, recordedSearch, ...search } = object;
        return Object.values(search).every((x: Object) => (x === null || x === '' ||
            x === 0 || Object.values(x).length === 0
            || x === '0'));
    }

    private searchEntities(params: IAreaSearchParams) {
        let wheel: SpinnerProcess = this.loadWheelService.showWheel(this.translate.instant("searching.areas"));
        if (this.restrictedOnOffshore) {
            params.offshore = this.restrictedOnOffshoreValue;
        }
        this.areaService.searchArea(params, wheel)
            .subscribe({
                next: (areas: AreaSearch[]) => {
                    if(areas === null) {
                        areas = [];
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = false;
                    } else if (areas.length === 0) {
                        this.messageHandler.showAlertAndReturnFocus(this.translate.instant("common.searchNotFound"));
                        this.additionalInfo = null;
                        params.recordedSearch = false;
                        this.enabledNewRecordButton = true;
                    } else {
                        this.additionalInfo = areas[0];
                        this.focusFirstElement();
                        params.recordedSearch = true;
                        this.enabledNewRecordButton = true;
                    }
                    this.entitiesArray = areas;
                    this.loadWheelService.hideWheel(wheel);


                    this.resetTable();
                },
                error: (error) => {
                    this.loadWheelService.hideWheel(wheel);
                    params.recordedSearch = false;
                    if (error.message.endsWith(Constants.MAX_ROWS_LIMITATION_MESSAGE)) {
                        this.messageHandler.showAlertAndReturnFocus(error.message);
                        this.entitiesArray = [];
                        this.additionalInfo = null;
                        this.enabledNewRecordButton = false;
                    } else {
                        console.warn(error);
                    }
                }
            });
    }

    onRowSelect(event: any) {
        this.additionalInfo = event;
    }

    onRowDblclick(event: any) {
        if (this.config.data?.ownership) {
            const objOwnership = this.config.data?.createInstance(event);
            this.activeModal.close(objOwnership);
        } else {
            this.activeModal.close(event);
        }
    }

    changeSelectionOnArrowKey(event: any, entityArray: any[], hasHeader: boolean = true) {
        TableUtils.changeSelectionOnArrowKey(this, "additionalInfo", event, entityArray, hasHeader);
    }

    onChangePhysCountry() {
        if (this.searchParams.offshore === '0') {
            // Onshore, then we load the states by country.
            if (this.searchParams.physCountry !== '' && this.searchParams.physCountry !== null) {
                this.loadStates(this.searchParams.physCountry);
            } else {
                this.physStates = [];
            }
            this.searchParams.physState = null;
        } else {
            this.searchParams.waterBody = null;
        }
    }

    onChangePhysWaterBody() {
        this.searchParams.fieldName = '';
    }

    clear() {
        this.clearFields(true);
    }

    private clearFields(clearAll: boolean) {
        this.searchParams.areaName = '';
        this.searchParams.physCity = '';
        this.searchParams.physState = null;
        this.searchParams.physCountry = null;
        this.searchParams.plantId = '';
        this.searchParams.fieldName = '';
        this.searchParams.waterBody = null;
        this.searchParams.recordStatus = null;
        this.entitiesArray = [];
        this.physStates = [];
        this.enabledNewRecordButton = false;
        this.additionalInfo = null;
        this.changeClass = true;

        if (clearAll) {
            setTimeout(() => this.inputAreaName.nativeElement.focus(), 1);
        }
    }

    private loadList() {
        this.loadRecordStatus();
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => this.recordStatuses = [...record.filter(item => item.RecordStatusID !== 'P')]);
    }

    private loadCountries() {
        if (this.searchParams.offshore === '0') {
            this.loadOnshoreCountries();
        } else {
            this.loadOffshoreCountries();
        }
    }

    private loadOnshoreCountries() {
        this.addressService.getCountries()
            .subscribe((countries: Country[]) => {
                this.physCountries = [...countries];
            });
    }

    private loadOffshoreCountries() {
        this.offshoreAddressService.getCountriesWaterBodyById(true, '')
            .subscribe((countries: CountryWaterBody[]) => {
                const mappedCountries: Country[] = countries.map(it => {
                    return new Country(
                        it.cId,
                        it.countryId,
                        it.countryName,
                        0,
                        it.countryId + ' - ' + it.countryName);
                });

                this.physCountries = [...mappedCountries];
            });
    }

    private loadStates(countryId: string) {
        this.addressService.getStateByCountry(countryId, '', true)
            .subscribe((states: State[]) => this.physStates = [...states]);
    }

    private loadWaterBodies() {
        this.offshoreAddressService.getWaterBodies(null)
            .subscribe((waterBodies: WaterBody[]) => this.waterBodies = [...waterBodies]);
    }

    newRecord() {
        this.entityCommonService.sendNewRecordEvent();
        this.activeModal.close();
    }

    customSort(event: SortEvent) {
        this.sharedService.customSort(event);
    }

}


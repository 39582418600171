import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'amount'
})

export class AmountPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {}
    transform(value: number | string): string {
        return value!==null ? this.decimalPipe.transform(String(value).replace(/,/g, '')) : '';
    }
}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
// Modal Dialog
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Service
import { SharedService } from 'src/app/core/services/shared.service';

// Models
import { TranslateService } from '@ngx-translate/core';
import { SortEvent } from 'primeng/api';
import { Constants, EntityPreferenceSufix, RecordStatusValue } from 'src/app/core/models/constants';
import { SpinnerProcess } from 'src/app/core/models/spinner-process';
import { Industry, OfflineEvent, RecordStatus, SicCode, UnitTypes } from '../../../core/models/common';
import { LoadWheelService } from '../../load-wheel/load-wheel.service';
import { MessageHandlerService } from '../../messages/message-handler/message-handler.service';
import { BaseModalComponent } from '../base-modals.component';
import { TableUtils } from 'src/app/core/utils/table-utils';
import { OfflineEventSearch } from 'src/app/core/models/search/offline-event-search';
import { OfflineEventSearchParams } from 'src/app/core/interfaces/search/ioffline-event-search-params';
import { StringUtils } from 'src/app/core/utils/string-utils';
import { DatePipe } from '@angular/common';
import { OperationType } from 'src/app/core/models/enumerations/operation-type';
import { ProjectsUnitIdsDisplay } from 'src/app/core/models/project/display/projects-unit-ids-display';
import { ProjectsOfflineEventsDisplay } from 'src/app/core/models/project/display/projects-offline-events-display';
import { EntityCommonService } from 'src/app/core/services/entity-common.service';
import { OfflineEventSectionService } from 'src/app/offline-event-section/offline-event-section.service';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { PreferencesSectionService } from 'src/app/preferences-section/preferences-section.service';
import { InputModalField, InputModalFieldContainer, InputModalFieldEditableType, SearchEntityModalComponent } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.component';
import { SearchEntityModalService } from 'src/app/shared/components/base/search-entity-modal/search-entity-modal.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityUtilsService } from 'src/app/core/utils/entity-utils.service';

@Component({
    selector: 'app-search-offline-event-modal',
    templateUrl: '../../../shared/components/base/search-entity-modal/search-entity-modal.component.html'
})

export class SearchModalOfflineEventComponent extends SearchEntityModalComponent implements OnInit {

    unitsIds: ProjectsUnitIdsDisplay[];
    preExistingProjectsOfflineEventsDisplay: ProjectsOfflineEventsDisplay[];

    constructor(
        protected messageHandler: MessageHandlerService,
        public activeModal: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public preferencesService: PreferencesSectionService,
        protected sharedService: SharedService,
        protected dialogService: DialogService,
        protected translate: TranslateService,
        protected offlineEventService: OfflineEventSectionService,
        protected loadWheelService: LoadWheelService,
        protected entityCommonService: EntityCommonService,
        protected entityUtilsService: EntityUtilsService,
        protected datePipe: DatePipe,
        protected searchEntityModalService: SearchEntityModalService) {
        super(messageHandler, activeModal, config, preferencesService, translate, loadWheelService, entityCommonService, entityUtilsService, searchEntityModalService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.entityTitle = 'offlineEvent.offlineEvent';
        this.entityTitlePlural = 'offlineEvent.offlineEvents';
        this.isMultiSelect = !this.isMainAccess;
        this.unitsIds = this.config.data.unitsIds;
        this.preExistingProjectsOfflineEventsDisplay = this.config.data.entitiesArray;

        this.loadList();
    }

    protected override getInputModalFieldContainer(): InputModalFieldContainer[] {
        let inputsModalFieldContainer: InputModalFieldContainer[] = [];

        if(this.isMainAccess) {
            let inputsModalField: InputModalField[] = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitName', displayName: 'project.searchOfflineEvent.unitName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'ownerName', displayName: 'project.searchOfflineEvent.ownerName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitId', displayName: 'project.searchOfflineEvent.unitId', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantName', displayName: 'project.searchOfflineEvent.plantName', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'assetId', displayName: 'project.searchOfflineEvent.assetId', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'assetName', displayName: 'project.searchOfflineEvent.assetName', editableType: InputModalFieldEditableType.textField }));
    
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
    
            inputsModalField = [];
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'unitType', displayName: 'project.searchOfflineEvent.unitType', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'unitTypeId', dropdownLabel: 'unitTypeDesc' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'sicCode', displayName: 'project.searchOfflineEvent.sicCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'sicCode', dropdownLabel: 'sicCodeDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'industryCode', displayName: 'project.searchOfflineEvent.industryCode', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'industryCode', dropdownLabel: 'industryDescription' }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'plantId', displayName: 'project.searchOfflineEvent.plantId', editableType: InputModalFieldEditableType.textField }));
            inputsModalField.push(InputModalField.CreateInstance({ entityPropName: 'recordStatus', displayName: 'project.searchOfflineEvent.recordStatus', editableType: InputModalFieldEditableType.dropdownField, dropdownValue: 'RecordStatusID', dropdownLabel: 'RecordStatusDescription' }));
    
            inputsModalFieldContainer.push(InputModalFieldContainer.CreateInstance({ inputFieldsArray: inputsModalField }));
        }

        return inputsModalFieldContainer;
    }

    protected override getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];

        columnsInfo.push({ entityPropName: 'eventID', columnHeader: 'project.searchOfflineEvent.eventId', widthPercentage: 12 });
        columnsInfo.push({ entityPropName: 'unitType', columnHeader: 'project.searchOfflineEvent.unitType', widthPercentage: 15 });
        columnsInfo.push({ entityPropName: 'eventStatusDesc', columnHeader: 'project.searchOfflineEvent.eventStatus', widthPercentage: 15 });
        columnsInfo.push({ entityPropName: this.isMainAccess ? 'largeEventName' : 'eventName', columnHeader: 'project.searchOfflineEvent.eventName', widthPercentage: 34 });
        columnsInfo.push({ entityPropName: 'eventStartDate', displayValueFunc: this.eventStartDisplay, columnHeader: 'project.searchOfflineEvent.eventStartDate', widthPercentage: 11 });
        columnsInfo.push({ entityPropName: 'eventDuration', columnHeader: 'project.searchOfflineEvent.duration', widthPercentage: 7 });

        return columnsInfo;
    }

    protected override getAdditionalInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ entityPropName: this.isMainAccess ? 'largeEventName' : 'eventName', columnHeader: 'project.searchOfflineEvent.eventName' });
        columnsInfo.push({ displayValueFunc: this.displaySicCodeColumn, columnHeader: 'project.searchOfflineEvent.sicCode' });
        columnsInfo.push({ entityPropName: 'ownerName', columnHeader: 'project.searchOfflineEvent.ownerName' });
        columnsInfo.push({ entityPropName: 'primaryFuel', columnHeader: 'project.searchOfflineEvent.primaryFuel' });
        columnsInfo.push({ entityPropName: 'operatorName', columnHeader: 'project.searchOfflineEvent.operatorName' });
        columnsInfo.push({ entityPropName: 'powerUsage', columnHeader: 'project.searchOfflineEvent.powerUsage' });
        columnsInfo.push({ entityPropName: 'eventStartDate', columnHeader: 'project.searchOfflineEvent.eventStartDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        columnsInfo.push({ entityPropName: 'eventEndDate', columnHeader: 'project.searchOfflineEvent.eventEndDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        columnsInfo.push({ entityPropName: 'recordStatus', columnHeader: 'project.searchOfflineEvent.recordStatus' });
        columnsInfo.push({ entityPropName: 'qcDate', columnHeader: 'project.searchOfflineEvent.qcDate', editableType: ColumnEntityInfoEditableType.nonEditableDateField });
        columnsInfo.push({ entityPropName: 'eventComments', columnHeader: 'project.searchOfflineEvent.eventComments' });

        return columnsInfo;
    }

    displaySicCodeColumn(implementedComponent: SearchModalOfflineEventComponent, rowData: OfflineEventSearch): string {
        return rowData.sicCodeCompositeName();
    }

    eventStartDisplay(implementedComponent: SearchModalOfflineEventComponent, rowData: OfflineEvent): string {
        return StringUtils.toStringNeverNull(implementedComponent.datePipe.transform(rowData.eventStartDate, 'dd-MMM-y'));
    }

    protected override searchEntitiesDelegate(params: HttpParams, wheel: SpinnerProcess): Observable<any> {
        return this.offlineEventService.searchOfflineEvents(params, wheel);
    }

    protected override getHttpParamsFromInputsModalField(): HttpParams {
        let params: HttpParams = super.getHttpParamsFromInputsModalField();
        if (!this.isMainAccess) {
            params = params.set('unitsIds', this.getUnitIds());
            params = params.set('offlineEventsIds', this.getOfflineEventsIds());
            params = params.set('searchOfflineEventsIds', true);
        } else {
            params = params.set('unitsIds', '');
            params = params.set('offlineEventsIds', '');
            params = params.set('searchOfflineEventsIds', false);
        }
        return params;
    }

    getUnitIds(): string {
        const filteredUnits = this.unitsIds.filter(unit => unit.getOperation() !== OperationType.DELETE);
        const unitsIdsCollected = filteredUnits.map(unit => unit.unitId).join(',');
        return unitsIdsCollected;
    }

    getOfflineEventsIds(): string {
        const filteredEntities = this.preExistingProjectsOfflineEventsDisplay.filter(entity => entity.getOperation() !== OperationType.DELETE && entity.offlineEventId > 0);
        const offlineEventsIdsCollected = filteredEntities.map(entity => StringUtils.toStringNeverNull(entity.offlineEventId)).join(',');
        return offlineEventsIdsCollected;
    }

    private loadList() {
        this.loadUnitType();
        this.loadUnitSicCode();
        this.loadRecordStatus();
        this.loadIndustry();
    }

    private loadUnitType() {
        this.sharedService.getUnitType()
            .subscribe((unitTypes: UnitTypes[]) => {
                this.searchInputModalField('unitType').dropdownOptionsArray = unitTypes;
            });
    }

    private loadUnitSicCode() {
        this.sharedService.getUnitsSicCodes()
            .subscribe((sicCodes: SicCode[]) => {
                this.searchInputModalField('sicCode').dropdownOptionsArray = sicCodes;
            });
    }

    private loadRecordStatus() {
        this.sharedService.getRecordStatus()
            .subscribe((record: RecordStatus[]) => {
                const recordStatuses = record.filter(item => item.RecordStatusID !== RecordStatusValue.Pending);
                this.searchInputModalField('recordStatus').dropdownOptionsArray = recordStatuses;
            });
    }

    private loadIndustry() {
        this.sharedService.getAllIndustryCodes()
            .subscribe((industry: Industry[]) => {
                this.searchInputModalField('industryCode').dropdownOptionsArray = industry;
            });
    }

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ColumnEntityInfo, ColumnEntityInfoEditableType, MVTEntityAssociatorComponent } from 'src/app/shared/components/base/mvt-entity-associator/mvt-entity-associator.component';
import { UnitFuelType } from 'src/app/core/models/unit';

@Component({
    selector: 'unit-level',
    templateUrl: './unit-level.component.html',
    styleUrls: ['./unit-level.component.scss']
})
export class UnitLevelComponent implements OnInit {
    
    @ViewChild('mvtEntityAssociatorComponent', { static: false }) mvtEntityAssociatorComponent: MVTEntityAssociatorComponent; 

    @Input() entitiesArray: Array<UnitFuelType> = [];
    @Input() parentEntityId: number;
    @Input() editable: boolean = false;
    @Input() disabledButton: boolean = false;
    @Input() industryCode: number;
    @Input() baseIndex: number;
    @Output() onChanges: EventEmitter<Array<UnitFuelType>> = new EventEmitter();

    constructor(
        public translate: TranslateService,
        public dialogService: DialogService
    ) {
    }

    ngOnInit(): void {        
    }

    getColumnsInfo(): ColumnEntityInfo[] {
        let columnsInfo: ColumnEntityInfo[] = [];
        columnsInfo.push({ isEntityId: true, entityPropName: 'id', columnHeader: 'levelFuelType.id', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage: 20 });
        columnsInfo.push({entityPropName:'description', columnHeader:'levelFuelType.desc', editableType: ColumnEntityInfoEditableType.nonEditableField, widthPercentage:25});
        
        return columnsInfo;
    }

    onEntityChanges(event: any) {
        this.onChanges.emit(event.entitiesArray);
    }

}

import { Bean } from "../../bean";

export class PlantsOutputInfo extends Bean {
    mvId: number;
    plantId: number;
    outputActualCapacity: string;
    outputDesignCapacity: string;
    outputHsProduct: string;
    outputMultiplier: string;
    outputProduct: string;
    outputProductMeasure: string;
    outputTimeMeasure: string;
    outputUnitMeasure: string;
    mvOrder: number;
    intdatadepamendstate: string;
    isInsert: boolean;
    lastDate: string;
    capacityQcDate: string;
    capacityQcUser: string;

    protected constructor(entity?: any) {
        super(entity);

        this.mvId = entity?.mvId ?? null;
        this.plantId = entity?.plantId ?? null;
        this.outputActualCapacity = entity?.outputActualCapacity ?? null;
        this.outputDesignCapacity = entity?.outputDesignCapacity ?? null;
        this.outputHsProduct = entity?.outputHsProduct ?? null;
        this.outputMultiplier = entity?.outputMultiplier ?? null;
        this.outputProduct = entity?.outputProduct ?? null;
        this.outputProductMeasure = entity?.outputProductMeasure ?? null;
        this.outputTimeMeasure = entity?.outputTimeMeasure ?? null;
        this.outputUnitMeasure = entity?.outputUnitMeasure ?? null;
        this.mvOrder = entity?.mvOrder ?? null;
        this.intdatadepamendstate = entity?.intdatadepamendstate ?? null;
        this.isInsert = entity?.isInsert ?? null;
        this.lastDate = entity?.lastDate ?? null;
        this.capacityQcDate = entity?.capacityQcDate ?? null;
        this.capacityQcUser = entity?.capacityQcUser ?? null;
    }

}

<div class="grid">
    <div class="col-4 mt-2">
        <button
            class="btn-row w-full max-w-7rem"
            type="button"
            [tabindex]="baseIndex"
            [disabled]="disabledAddButton"
            (click)="addRow()">{{'add_comment'| translate}}</button>
    </div>

    <div class="col-8 text-right text-xs mt-2">
        <label class="font-bold text-xs mt-1">{{'filter_notes'|translate}}:</label>
        <input pInputText
            #filterNote
            [tabindex]="baseIndex + 1"
            type="text"
            class="form__input-field-style ml-1"
            (input)="filterOnInput($event)"/>
    </div>
</div>

<div class="mt-2">
    <p-table #notesDt
		focusable
        [focusManager]="notesFocusManager"
    	[value]="notesFiltered()" [columns]="cols"
        (onEditInit)="onEditInit($event)"
        (onEditComplete)="onEditComplete($event)"
        [resizableColumns]="true" styleClass="p-datatable-gridlines"
        [reorderableColumns]="true"
        [tabindex]="baseIndex + 2"
        [globalFilterFields]="['createUserName','comments']">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [style]="col.style" pReorderableColumn pResizableColumn>
                    {{col.header}}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns" [style]="col.style">
            </colgroup>
        </ng-template>

        <ng-template pTemplate="body" let-columns="columns" let-index="rowIndex" let-rowData>

            <tr>
                <td *ngFor="let col of columns" [pEditableColumn]="rowData" [pEditableColumnField]="col.field" [pEditableColumnRowIndex]="index"
                class="{{col.field}} px-2" [style.white-space]="col.wSpace || 'normal'" [style]="col.style">

                    <div *ngIf="col.field == 'createDate'">
                        {{ rowData[col.field] |  date: 'dd-MMM-y' }}
                    </div>

                    <div *ngIf="col.field == 'createUserName'">
                        {{ rowData[col.field] }}
                    </div>

                    <p-cellEditor *ngIf="col.editable">
                        <ng-template pTemplate="input">
                            <textarea type="text"
                                #comments
                                maxlength="maxCommentLength"
                                appendTo="body"
                                maxlength="2000"
                                pInputTextarea
                                class="comment-cont text-xs"
                                *ngIf="col.field == 'comments'"
                                [disabled]="disableCellEditor || (!hasIndustryManagerRole && !isMyNote(rowData))"
                                [(ngModel)]="rowData[col.field]"
                                (focusout)="onFocusOut(col, rowData)"
                                (keydown)="onKeyDown($event)"
                                (focus)="onFocus(col)"
                                >
                            </textarea>
                        </ng-template>

                        <ng-template pTemplate="output" *ngIf="col.field == 'comments'" style="display: block">
                            <span style="white-space: pre-line">
                                {{ rowData[col.field] }}
                            </span>
                        </ng-template>
                    </p-cellEditor>

                    <div *ngIf="col.field == 'notesSaleFlagBool' && showSell" class="p-field-checkbox p-field-checkbox-grid text-center">
                        <p-checkbox
                            [(ngModel)]="rowData[col.field]"
                            value="rowData[col.field]"
                            (ngModelChange)="onModelChangeNotesSaleFlag($event, rowData)"
                            (onChange)="onChangeNotesSaleFlag($event, rowData)"
                            [disabled]="validateDisableSellCheckbox(rowData.comments)"
                            binary="true">
                        </p-checkbox>
                    </div>

                    <div class="text-center" *ngIf="col.delete && !disableCellEditor &&
                                (hasIndustryManagerRole || isMyNote(rowData))">
                        <button pButton pRipple type="button" icon="pi pi-trash"
                            class="p-button-text icon-container" (click)="deleteNote(rowData)" (keydown.enter)="deleteNote(rowData)" tabindex="-1">
                        </button>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length + 1">
                    <div class="table-msg mt-1 msg-add-comments">
                        <span>{{'info_add_comments'| translate}}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

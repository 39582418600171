import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";
import { Constants } from "./constants";


export class DriveSearch {
    constructor(
        public driveId: string,
        public driveName: string,
        public driveType: string,
        public plantName: string,
        public driveCategoryId: string,
        public driveCategory: string,
        public manufacturerId: string,
        public manufacturer: string,
        public model: string,
        public sicCodeId: string,
        public sicCodeName: string,
        public sicCode: string,
        public recordStatus: string,
        public ownerName: string,
        public areaName: string,
        public unitName: string,
        public unitId: string,
        public designCap: string,
        public qcDate: string
        
  
    ) { }
}

@Injectable({
    providedIn: 'root',
})
export class DriveSearchAdapter implements Adapter<DriveSearch> {
    adapt(item: any): DriveSearch {
        return new DriveSearch(
            item.TURBINE_ID,
            item.NAME,
            item.TYPE,
            item.PLANT_NAME,
            item.DRIVE_CATEGORY_ID,
            item.CATEGORY_DESC,
            item.MANUFACTURER_ID,
            item.MANUFACTURER_NAME,
            item.MODEL,
            item.SIC_CODE,
            item.SIC_CODE_DESC,
            item.SIC_CODE ? `${item.SIC_CODE} - ${item.SIC_CODE_DESC}` : '',
            item.RECORD_STATUS_DESC,
            item.OWNER_NAME,
            item.AREA_NAME,
            item.UNIT_NAME,
            item.UNIT_ID,
            item.DESIGN_ELECTRICAL_OUTPUT,
            item.QC_DATE
        );
    }
}

export class DriveType {
    typeId: string;
    typeShortDesc: string;
    typeDesc: string;

    private constructor(
        typeId: string,
        typeShortDesc: string,
        typeDesc: string
    ) {
        this.typeId = typeId;
        this.typeShortDesc = typeShortDesc;
        this.typeDesc = typeDesc;
    }

    static BuildDriveType(entity: Array<any>): Array<DriveType> {
        return entity.map(item => DriveType.CreateInstance(item));
    }

    private static CreateInstance(record: any): DriveType {
        return new DriveType(
            record.TYPE_ID, 
            record.TYPE_DESCRIPTION, 
            `${record.TYPE_ID} - ${record.TYPE_DESCRIPTION}`);
    }
}

export class DriveCategory {
    categoryId: string;
    categoryShortDesc: string;
    categoryDesc: string;
    inactive: boolean;

    private constructor(
        categoryId: string,
        categoryShortDesc: string,
        categoryDesc: string,
        inactive: boolean
    ) {
        this.categoryId = categoryId;
        this.categoryShortDesc = categoryShortDesc;
        this.categoryDesc = categoryDesc;
        this.inactive = inactive;
    }

    static BuildDriveCategory(entity: Array<any>): Array<DriveCategory> {
        return entity.map(item => DriveCategory.CreateInstance(item));
    }

    private static CreateInstance(record: any): DriveCategory {
        return new DriveCategory(
            record.CATEGORY_ID, 
            record.CATEGORY_DESC, 
            `${record.CATEGORY_ID} - ${record.CATEGORY_DESC}`,
            true);
    }
}

export class DrivenEquipmentType {
    compressor: string;
    pump: string;
    typeId: string;
    typeDesc: string;

    private constructor(
        compressor: string,
        pump: string,
        typeId: string,
        typeDesc: string
    ) {
        this.compressor = compressor;
        this.pump = pump;
        this.typeId = typeId;
        this.typeDesc = typeDesc;
    }

    static BuildDrivenEquipmentType(entity: Array<any>): Array<DrivenEquipmentType> {
        return entity.map(item => DrivenEquipmentType.CreateInstance(item));
    }

    private static CreateInstance(record: any): DrivenEquipmentType {
        return new DrivenEquipmentType(
            record.COMPRESSOR,
            record.PUMP, 
            record.DRIVEN_EQUIPMENT_TYPE_ID, 
            record.DRIVEN_EQUIPMENT_TYPE_DESC
            );
    }
}




import { Dropdown } from 'primeng/dropdown';

const originalOnKeydown = Dropdown.prototype.onKeyDown;

Dropdown.prototype.onKeyDown = function (event: KeyboardEvent, search: boolean) {
    if (event.key === 'Tab') {
        const dropdownElement = event.target as HTMLElement;
        const pDropdownElement = getPDropdownElement(dropdownElement);
        const pTableAncestor = dropdownElement.closest('p-table');

        if (pTableAncestor) {
            this.hide();
            
            if(pTableAncestor.hasAttribute('focusable') || pTableAncestor.hasAttribute('focusablemvt')) {
                event.preventDefault();
                event.stopImmediatePropagation();

                const targetElement = event.target as HTMLElement;
                if (targetElement && targetElement.parentElement) {
                    const keyboardEvent = new KeyboardEvent('keydown', {
                        key: event.key,
                        shiftKey: event.shiftKey,
                        bubbles: true
                    });
                    targetElement.parentElement.dispatchEvent(keyboardEvent);
                }
            }

            return;
        } else if(pDropdownElement != null && pDropdownElement.hasAttribute('applyDropdownPatch')) {
            this.hide();
            return;
        }
    }
    originalOnKeydown.apply(this, [event, search]);
};

const getPDropdownElement = function (element: HTMLElement): HTMLElement {
    if(element == null) {
        return null;
    }

    if (element.tagName === 'P-DROPDOWN') {
        return element;
    }
    return getPDropdownElement(element.parentElement);
}

import { Component, ElementRef, EventEmitter, OnInit, Output, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { delayUpTo } from 'src/app/core/utils/custom-rxjs-operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Constants } from 'src/app/core/models/constants';
import { EntityName } from 'src/app/core/models/enumerations/entity-name';
import { Role } from 'src/app/core/models/enumerations/role';
import { SaveType } from 'src/app/core/models/enumerations/save-type';
import { UpdateType } from 'src/app/core/models/enumerations/update-type';
import { MessageType } from '../message-handler/message-handler.component';
import { MessageHandlerService } from '../message-handler/message-handler.service';
import { MessagesService } from '../messages.service';
import { SaveDialogService } from './save-dialog.service';

export interface SaveEvent {
    updateType: number;
    saveType: number;
}

@Component({
    selector: 'app-save-dialog',
    templateUrl: './save-dialog.component.html',
    styleUrls: ['./save-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConfirmationService, MessageService]
})
export class SaveDialogComponent implements OnInit, OnDestroy {
    @Output() saveHandler: EventEmitter<SaveEvent> = new EventEmitter();
    private saveDialogChanged: Subscription;

    title: string;
    entityId: string;
    entityName: string;
    entityStatus: string;
    saveType: number;
    updateType: number;
    amendState: number = 0;
    lastDate: string;
    qcDate: string;
    releaseDate: string;
    ammendDate: string;
    contactsSlottedEmpty: boolean = false;

    displayDialog: boolean = false;
    isHigherPrecedence: boolean;
    allowMajor: boolean = false;
    hasMajorUpdateCheckbox: boolean = false;
    majorUpdateSelected: boolean = false;
    requestReQc: boolean = false;
    wipButtonEnabled: boolean = false;
    updateButtonEnabled: boolean = false;
    qcButtonEnabled: boolean = false;
    justSaveButtonEnabled: boolean = false;
    amendButtonEnabled: boolean = false;
    cancelButtonEnabled: boolean = false;

    @ViewChild('defaultAction') defaultAction: ElementRef;

    readonly ENTITY_OFFLINE: string = EntityName.OFFLINE_EVENT;
    readonly ENTITY_PLANT: string = EntityName.PLANT;

    constructor(
        private messageService: MessagesService,
        private messageHandler: MessageHandlerService,
        private authService: AuthService,
        private translateService: TranslateService,
        private saveDialogService: SaveDialogService) {}

    ngOnInit(): void {
      
        this.messageService.initSaveSubjectDialog();
        this.saveDialogChanged = this.messageService.saveDialogChanged$.subscribe(saveData => {

            // Reset button states before anything is shown, they'll get selectively enabled later 
            this.wipButtonEnabled = false;
            this.updateButtonEnabled = false;
            this.qcButtonEnabled = false;
            this.justSaveButtonEnabled = false;
            this.amendButtonEnabled = false;
            this.cancelButtonEnabled = false;

            if(saveData !== null) {
                this.displayDialog = true;

                this.title = saveData?.title;
                this.entityId = String(saveData?.entityId);
                this.qcDate = saveData?.qcDate;
                this.lastDate = saveData?.lastDate;
                this.ammendDate = saveData?.amendmentDate;
                this.releaseDate = saveData?.releaseDate;
                this.amendState = saveData?.amendState;
                this.updateType = saveData?.updateType;
                this.requestReQc = saveData?.requesReQc;
                this.entityName = saveData?.entityName;
                this.contactsSlottedEmpty = saveData?.contactsSlottedEmpty;
                this.entityStatus = saveData?.entityStatus;
                this.allowMajor = saveData?.allowMajor;
                this.hasMajorUpdateCheckbox = saveData?.hasMajorUpdateCheckbox;
                this.majorUpdateSelected = saveData?.majorUpdateSelected;

                this.checkHigherPrecedence();
            }
        });
    }

    ngOnDestroy(): void {
        this.saveDialogChanged?.unsubscribe();
    }

    private checkHigherPrecedence() {
        if(this.entityId && this.entityId !== 'undefined') {
            this.saveDialogService.checkHigherPrecedence(this.entityId, this.entityName, this.updateType, this.entityStatus).pipe(
               delayUpTo(Constants.DIALOG_BUTTON_DELAY_MS_SHORT) // This is analogous to the delay of general dialogs (see: MessageHandler), but only kicks in when the higher precedence call returns quicker
            ).subscribe((data: any)=>{

                this.cancelButtonEnabled = true;
                setTimeout(() => { 
                   if (this.defaultAction && this.defaultAction.nativeElement) {
                       this.defaultAction.nativeElement.focus();
                   }
                });
               
                const higherPrecedenceResp = data?.result;
                if(higherPrecedenceResp !== null) {
                    this.applySaveTypeButtonEnableRules(higherPrecedenceResp === 'yes');
                } else {
                    //TODO Call to alertManager, methods errorHandler in flex code!
                    //this.messageService.openMessageInfo('Information', );
                    alert('AlertManager.errorHandler!');
                }
            });
        }
    }

    private applySaveTypeButtonEnableRules(higherPrecedence: boolean) {
        if(this.entityName === EntityName.OFFLINE_EVENT) {
            higherPrecedence = false;
            //this.enable = false;
        } else {
            this.isHigherPrecedence = higherPrecedence;
        }

        let existingRecordState: string= null;
        if (this.updateType == 0) {
            if (this.amendState == 1) { // Check if it's an Amendment
                existingRecordState = "amend";
            } else if (this.lastDate == null && this.releaseDate == null) { // Check if it Does Not Exist
                existingRecordState = "none";
            } else if (this.lastDate != null && this.releaseDate == null) { // Check if it's a Newly Created WIP
                existingRecordState = "nwip";
            } else if (this.lastDate != null && this.releaseDate != null) {
                if (this.lastDate > this.releaseDate) { // Check if it's an Updated WIP
                    existingRecordState = "uwip";
                } else {
                    existingRecordState = "eidle"; // It's an Existing Idle
                }
            }
        } else if (this.updateType == 1) {
            existingRecordState = "update";
        } else if (this.updateType == 2 || this.updateType == 3 || this.updateType == 4) { // Consider QC Minor (2), QC Major (3), and QC New (4) as simply QC
            existingRecordState = "qc";
        }

        this.authService.isUserInRole(Role.ALLOW_AMENDMENTS).subscribe(allowAmendments => {           
            switch(existingRecordState) {
               case 'none': {
                  this.wipButtonEnabled = true;
                  this.updateButtonEnabled = false;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = false;
                  break;
               }
               case 'nwip': {
                  this.wipButtonEnabled = true;
                  this.updateButtonEnabled = false;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = false;
                  break;
               }
               case 'eidle': {
                  this.wipButtonEnabled = true;
                  this.updateButtonEnabled = true;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = allowAmendments;
                  break;
               }
               case 'uwip': {
                  this.wipButtonEnabled = true;
                  this.updateButtonEnabled = true;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = false;
                  break;
               }
               case 'update': {
                  this.wipButtonEnabled = false;
                  this.updateButtonEnabled = true;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = false;
                  break;
               }
               case 'qc': {
                  this.wipButtonEnabled = false;
                  this.updateButtonEnabled = false;
                  this.qcButtonEnabled = this.requestReQc && (!this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected));
                  this.justSaveButtonEnabled = true;
                  this.amendButtonEnabled = false;
                  break;
               }
               case 'amend': {
                  this.wipButtonEnabled = false;
                  this.updateButtonEnabled = true;
                  this.qcButtonEnabled = !this.isHigherPrecedence || (this.allowMajor && this.hasMajorUpdateCheckbox && this.majorUpdateSelected);
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = allowAmendments;
                  break;
               }
               default: {
                  this.wipButtonEnabled = false;
                  this.updateButtonEnabled = false;
                  this.qcButtonEnabled = false;
                  this.justSaveButtonEnabled = false;
                  this.amendButtonEnabled = false;
                  break;
               }
            }
        });

    }

    wip() {
        const sEvent: SaveEvent = {
            saveType: SaveType.WORK_IN_PROGRESS,
            updateType: UpdateType.IDLE_WIP_AMEND,
        }

        this.saveHandler.emit(sEvent);
        this.cancel();
    }

    update() {
        if(this.contactsSlottedEmpty) {
          this.showRequiredContactSlottedMessage();
        } else {
            const sEvent: SaveEvent = {
                saveType: SaveType.UPDATE,
                updateType: UpdateType.UPDATE
            }

            this.saveHandler.emit(sEvent);
        }
        this.cancel();
    }

    qc() {
        if(this.contactsSlottedEmpty) {
          this.showRequiredContactSlottedMessage();
        } else {
            let lUpdateType = null;
            if(this.releaseDate === null) {
                lUpdateType = UpdateType.QC_NEW;
            } else if(this.hasMajorUpdateCheckbox && !this.majorUpdateSelected) {
                lUpdateType = UpdateType.QC_MINOR;
            } else {
                lUpdateType = UpdateType.QC_MAJOR;
            }
            const sEvent: SaveEvent = {
                saveType: SaveType.TYPE_QC,
                updateType: lUpdateType
            }

            this.saveHandler.emit(sEvent);
        }
        this.cancel();
    }

    justSave() {
        if(this.contactsSlottedEmpty) {
          this.showRequiredContactSlottedMessage();
        } else {
            const sEvent: SaveEvent = {
                saveType: SaveType.JUST_SAVE,
                updateType: this.updateType
            }

            this.saveHandler.emit(sEvent);
        }
        this.cancel();
    }

    ammend() {
        if(this.contactsSlottedEmpty) {
          this.showRequiredContactSlottedMessage();
        } else {
            const sEvent: SaveEvent = {
                saveType: SaveType.AMENDMENT,
                updateType: UpdateType.IDLE_WIP_AMEND
            }

            this.saveHandler.emit(sEvent);
        }
        this.cancel();
    }

    cancel() {
        this.displayDialog = false;
    }

    showRequiredContactSlottedMessage(): void {
        this.messageHandler.show(this.translateService.instant('plant.validations.requiredContact'), MessageType.WARNING);
    }
}
